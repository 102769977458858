import React, { useEffect, useState, Fragment } from 'react';
import './style.css';
import './responsive.css';
import useEngagementReportTabs from '../reports/EngagementReportTabs';
import useChecklist from '../checklist/useChecklist';
import useHash from '../../hooks/useHash';
import StatusReview from '../commons/StatusReview';
import Skeleton from 'react-loading-skeleton';
import useIsTenant from '../../hooks/useIsTenant';
import useWistiaEmbedPlayer from '../../hooks/useWistiaEmbedPlayer';
import { useTenantContext } from '../../contexts/TenantContext';
import MenuAccordion from '../commons/MenuAccordion';
import { Col, Modal, Row } from 'react-bootstrap';
import TextOverflowTooltip from '../commons/TextOverflowTooltip';
import { useClientPortalContext } from '../../contexts/clientPortalContext';

const Visited = JSON.parse(localStorage.getItem('menu_visited') || '{}');

const ModuleItem = ({ data, active, onClick, contactId }) => {
  const key = `${data.key}-${contactId}`;
  return (
    <>
      <Row
        onClick={() => {
          if (!Visited[key]) {
            Visited[key] = {
              date: Date.now(),
            };
          }
          localStorage.setItem('menu_visited', JSON.stringify(Visited));
          onClick(data);
        }}
        className={`cursor-pointer accordion-sub-menu align-items-center px-4_1 py-2 nav-link item-filter ${
          active ? 'text-primary active bg-primary-soft fw-600' : ''
        }`}
      >
        <Col className="px-2_1">
          <p className="d-flex align-items-center py-0 px-0 my-0 gap-2">
            <span className="font-weight-medium font-size-sm2 mb-0">
              <TextOverflowTooltip
                text={data?.name}
                textStyle="font-weight-medium text-truncate font-size-sm2 mb-0"
              />
            </span>

            <StatusReview
              show={!Visited[key] || data.type !== 'Report' ? '' : 'hide'}
              text={
                data.type === 'Report'
                  ? 'New'
                  : data?.progress?.clientProgress === 100
                  ? 'Completed'
                  : 'Review'
              }
              icon={
                data?.progress?.clientProgress === 100 ? 'check_circle' : 'info'
              }
              styleClass={
                data?.progress?.clientProgress === 100
                  ? 'bg-soft-success text-success'
                  : 'bg-orange-5 text-orange'
              }
              fontSize="fs-10"
              iconSize="fs-8"
            />
          </p>
        </Col>
      </Row>
    </>
  );
};

const MobileMenuDrawer = ({ sidebarOptions }) => {
  const [showMenuBar, setShowMenuBar] = useState(false);

  return (
    <Fragment>
      <div
        className="d-xl-none cursor-pointer nav-link position-absolute bg-white z-index-10 d-block-sm w-100"
        onClick={() => setShowMenuBar((prevOpen) => !prevOpen)}
      >
        <div className="d-flex gap-1 align-items-center">
          <span className="material-symbols-rounded font-size-xl">menu</span>
          <p className="mb-0 font-weight-bold">Menu</p>
        </div>
      </div>
      <Modal
        show={showMenuBar}
        onHide={() => setShowMenuBar(false)}
        size="lg"
        aria-labelledby="menu-bar"
        backdrop="static"
        keyboard={false}
        className="right"
      >
        <Modal.Header closeButton className="p-2 py-3">
          <Modal.Title id="activity_logs">
            <div className="d-flex align-items-center justify-content-between">
              <h3 className="mb-0">Menu</h3>
            </div>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="p-2 border-top">
          <MenuAccordion
            options={sidebarOptions}
            onMenuItemClick={setShowMenuBar}
            toggle={() => setShowMenuBar(false)}
          />
        </Modal.Body>
      </Modal>
    </Fragment>
  );
};

const PortalModuleNavigation = () => {
  const {
    organization,
    organizationId,
    contactId,
    selectedMenu,
    setSelectedMenu,
  } = useClientPortalContext();

  const { enabledReports } = useEngagementReportTabs({
    organizationId: organization?.id || organizationId,
  });
  const { tenant } = useTenantContext();
  const { hash, updateHash } = useHash();
  const { Checklist = [], loading } = useChecklist(selectedMenu);
  const { isForwardBank } = useIsTenant();
  const { WistiaEmbedModal, shouldShowModal, setShowModal } =
    useWistiaEmbedPlayer('y1kfd18hrc', false, true);

  const handleSelectMenu = (menu) => {
    updateHash(menu.key);
  };
  const getSelectedMenuByHash = () => {
    // if the hash found in reports module or in checklist then get that
    return (
      enabledReports?.find((rpt) => rpt.key === hash) ||
      Checklist?.find((check) => check.key === hash) ||
      Checklist[0] ||
      {}
    );
  };
  useEffect(() => {
    let menu = getSelectedMenuByHash();
    if (hash || menu) {
      try {
        if (Object.entries(menu).length === 0) {
          menu = Checklist?.length ? Checklist[0] : enabledReports[0];
        }
      } catch (e) {
        console.log('e', e);
      }
      setSelectedMenu(menu);
      // if there are checklist assigned and is forward bank and the video is not closed once
      if (
        Checklist?.length &&
        (isForwardBank || tenant?.name === 'Forward Bank') && // thats a temp solution just forward bank
        !shouldShowModal()
      ) {
        setShowModal(true);
      }
    }
  }, [hash, Checklist]);

  const renderChecklistItems = () => {
    return (
      <>
        {loading ? (
          <div className="pt-2 px-3">
            <Skeleton count={3} height="5" className="mb-3" />
          </div>
        ) : (
          <>
            {Checklist?.length > 0 ? (
              <>
                {Checklist?.map((checklist) => (
                  <ModuleItem
                    key={checklist?.checklistId}
                    data={checklist}
                    active={
                      selectedMenu?.checklistId === checklist?.checklistId
                    }
                    onClick={() => handleSelectMenu(checklist)}
                    contactId={contactId}
                  />
                ))}
              </>
            ) : (
              <p className="mb-0 text-center p-3 text-gray-search">
                No Checklists
              </p>
            )}
          </>
        )}
      </>
    );
  };
  const renderReportItems = () => {
    return (
      <>
        {enabledReports?.length > 0 ? (
          <>
            {enabledReports?.map((rpt) => (
              <ModuleItem
                key={rpt.name}
                data={{ ...rpt, type: 'Report', isVisited: false }}
                active={selectedMenu?.name === rpt?.name}
                onClick={() => handleSelectMenu({ ...rpt, type: 'Report' })}
              />
            ))}
          </>
        ) : (
          'no data'
        )}
      </>
    );
  };

  const sidebarOptions = [
    ...(!loading && Checklist?.length > 0
      ? [
          {
            id: 1,
            label: 'Checklists',
            icon: 'checklist',
            component: renderChecklistItems(),
          },
        ]
      : []),
    ...(enabledReports?.length > 0
      ? [
          {
            id: 2,
            label: 'Reports',
            icon: 'lab_profile',
            component: renderReportItems(),
          },
        ]
      : []),
  ];

  return (
    <>
      <WistiaEmbedModal />
      <MobileMenuDrawer sidebarOptions={sidebarOptions} />
      <div className="custom-layout-sidebar overflow-x-hidden d-block-xl">
        <div className="d-flex flex-column gap-3 p-3 transparent-scroll-track">
          {loading ? (
            <div className="pt-2 px-3">
              <Skeleton count={3} height="5" className="mb-3" />
            </div>
          ) : null}
          <MenuAccordion options={sidebarOptions} trackActiveKeys />
        </div>
      </div>
    </>
  );
};

export default PortalModuleNavigation;
