import React, { useCallback, useEffect, useMemo, useState } from 'react';
import RightSlidingDrawer from '../../modal/RightSlidingDrawer';
import AlertWrapper from '../../Alert/AlertWrapper';
import Alert from '../../Alert/Alert';
import { Card, CardBody, CardFooter, CardHeader } from 'reactstrap';
import MaterialIcon from '../../commons/MaterialIcon';
import {
  isModuleAllowed,
  overflowing,
  scrollToBottomChat,
} from '../../../utils/Utils';
import AskAnything from '../AskAnything';
import { InputDisplayType } from '../constants';
import { useIdaContext } from '../../../contexts/IdaContext';
import { ListGroup, ListGroupItem } from 'react-bootstrap';
import useFetchChatQuestions from '../hooks/useFetchChatQuestions';
import Conversation from '../chats/Conversation';
import useBackTop from '../../../hooks/useBackTop';
import IdfTooltip from '../../idfComponents/idfTooltip';
import { useHistory, useLocation } from 'react-router-dom';
import LucideIcon from '../../commons/LucideIcon';
import Greetings from '../Greetings';
import Disclaimer from '../Disclaimer';
import { useNavBarShortKey } from '../../../hooks/useNavBarShortKey';
import useHash from '../../../hooks/useHash';
import { useTenantContext } from '../../../contexts/TenantContext';
import { PermissionsConstants } from '../../../utils/permissions.constants';

const Prompts = () => {
  const { setPrompt } = useIdaContext();
  const PromptList = [
    {
      id: 1,
      key: 'email',
      icon: 'Mail',
      title: 'Write a professional email',
      description: 'Get help drafting emails',
    },
    {
      id: 2,
      key: 'summarize',
      icon: 'FileText',
      title: 'Summarize attached file',
      description: 'Get quick document summaries',
    },
    {
      id: 3,
      key: 'explain',
      icon: 'CircleHelp',
      title: 'Explain this content',
      description: 'Get detailed explanations',
    },
  ];

  return (
    <ListGroup className="px-4 rounded-lg">
      {PromptList.map((prompt, index) => (
        <ListGroupItem
          key={prompt.id}
          onClick={() => setPrompt(prompt.title)}
          className={`${index === 0 ? 'rounded-top-lg' : ''} ${
            index === PromptList.length - 1 ? 'rounded-bottom-lg' : ''
          } d-flex gap-2 align-items-center border-white p-3 bg-primary-soft bg-hover-soft-gray cursor-pointer`}
        >
          <div className="p-2 bg-white rounded-lg d-flex align-items-center justify-content-center">
            <LucideIcon icon={prompt.icon} />
          </div>
          <div className="d-flex flex-column">
            <h5 className="mb-0">{prompt.title}</h5>
            <p className="mb-0 fs-7">{prompt.description}</p>
          </div>
        </ListGroupItem>
      ))}
    </ListGroup>
  );
};

const AskIdaModal = ({ show, setShow, customPrompt, setCustomPrompt }) => {
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const { BackTop } = useBackTop('chatBox', { bottom: 100, right: 20 });
  const router = useHistory();
  const {
    selectedChat,
    selectedModalChat,
    setSelectedModalChat,
    setNewQuestion,
    setSelectedChat,
    newQuestion,
    setPrompt,
    setMask,
    setTrigger,
  } = useIdaContext();
  const { questions, setQuestions } = useFetchChatQuestions(
    Object.keys(selectedModalChat || {}).length === 1
      ? null
      : selectedChat?.chatId
  );

  const handleClose = () => {
    setShow(false);
    setSelectedModalChat({});
    setSelectedChat({});
    setQuestions([]);
    setNewQuestion({});
    setPrompt('');
    setMask('');
    overflowing();
  };

  useEffect(() => {
    if (Object.keys(selectedModalChat || {}).length) {
      scrollToBottomChat('chatBox');
    }
  }, [selectedModalChat]);

  useEffect(() => {
    // if new question is added, add right away in chat window
    if (Object.keys(newQuestion || {}).length) {
      setQuestions((prevState) => [
        // Remove temp question before adding the new one
        ...prevState.filter(
          (q) => q.chatQuestionId !== newQuestion.chatQuestionId
        ),
        newQuestion,
      ]);
      scrollToBottomChat('chatBox');
    }
  }, [newQuestion]);

  useEffect(() => {
    scrollToBottomChat('chatBox');
  }, [questions]);

  useEffect(() => {
    if (customPrompt) {
      setTrigger(customPrompt.text);
      setCustomPrompt('');
    }
  }, [customPrompt]);
  return (
    <RightSlidingDrawer
      open={show}
      containerWidth={450}
      modalClass="bg-white"
      toggleDrawer={handleClose}
    >
      <AlertWrapper className="alert-position z-lg">
        <Alert
          message={errorMessage}
          setMessage={setErrorMessage}
          color="danger"
        />
        <Alert
          message={successMessage}
          setMessage={setSuccessMessage}
          color="success"
        />
      </AlertWrapper>
      <Card className="p-0 h-100 shadow-none border-0" style={{ width: 450 }}>
        <CardHeader className="justify-content-between bg-gray-5 gap-2 align-items-center">
          <div className="d-flex flex-column align-items-start">
            <h3 className="mb-0 d-flex align-items-center gap-2 text-capitalize">
              <MaterialIcon icon="auto_awesome" clazz="position-relative" />
              <span className="position-relative">Ida</span>
            </h3>
          </div>
          <div className="d-flex align-items-center gap-2">
            {Object.keys(selectedModalChat || {}).length === 1 ? (
              <>
                <IdfTooltip text="Clear Chat" placement="bottom">
                  <span
                    onClick={() => {
                      setSelectedModalChat({});
                      setSelectedChat({});
                      setQuestions([]);
                      setPrompt('');
                      setNewQuestion({});
                    }}
                  >
                    <a className="icon-hover-bg cursor-pointer">
                      <MaterialIcon icon="refresh" size="fs-5" rounded />
                    </a>
                  </span>
                </IdfTooltip>
                <IdfTooltip text="Expand Window" placement="bottom">
                  <span
                    onClick={() => {
                      router.push(`/ida#chats/${selectedChat.chatId}`);
                      handleClose();
                    }}
                  >
                    <a className="icon-hover-bg cursor-pointer">
                      <MaterialIcon icon="expand_content" size="fs-5" rounded />
                    </a>
                  </span>
                </IdfTooltip>
              </>
            ) : null}
            {!selectedModalChat?.transactionId ? (
              <>
                <IdfTooltip text="Show All Chats" placement="bottom">
                  <span
                    onClick={() => {
                      router.push(`/ida#history`);
                      handleClose();
                    }}
                  >
                    <a className="icon-hover-bg cursor-pointer">
                      <MaterialIcon icon="history" size="fs-5" rounded />
                    </a>
                  </span>
                </IdfTooltip>
              </>
            ) : null}
            <a
              className="icon-hover-bg cursor-pointer"
              onClick={(e) => {
                e.preventDefault();
                handleClose();
              }}
            >
              <MaterialIcon icon="close" size="fs-5" rounded />
            </a>
          </div>
        </CardHeader>
        <CardBody
          id="chatBox"
          className="h-100 smooth-scroll px-3 py-0 ask-ida-modal overflow-y-auto"
        >
          {Object.keys(selectedModalChat || {}).length === 1 ||
          newQuestion.feTransactionId ? (
            <Conversation
              questions={questions}
              latestQuestionRef={null}
              fromAskIdaModal={true}
            />
          ) : (
            <>
              <div className="d-flex mt-4 px-4 pb-3 flex-column">
                <Greetings margin="mb-0" />
              </div>
              <Prompts />
            </>
          )}
        </CardBody>
        <CardFooter className="pb-4 position-relative">
          <BackTop />
          <AskAnything
            displayType={InputDisplayType.AskIdaModal}
            placeholder={questions.length ? 'Ask follow-up' : 'Ask Ida...'}
          />
          <Disclaimer
            icon={false}
            style="position-absolute bottom-0 abs-center fs-8 w-100 text-center"
          />
        </CardFooter>
      </Card>
    </RightSlidingDrawer>
  );
};

const useAskIdaModal = () => {
  const [showModal, setShowModal] = useState(false);
  const [customPrompt, setCustomPrompt] = useState('');
  const location = useLocation();
  const { tenant } = useTenantContext();
  const isIdaAllowed = isModuleAllowed(
    tenant?.modules,
    PermissionsConstants.Ida.Ida
  );
  const { hash, updateHash } = useHash();
  useNavBarShortKey(['command', 'k'], () => {
    if (isIdaAllowed) {
      if (!location.pathname.includes('/ida')) {
        setShowModal(true);
      } else if (hash.includes('knowledge') || hash.includes('history')) {
        updateHash('chat');
      }
    }
  });

  const AskIdaModalCallback = useCallback(() => {
    return (
      <AskIdaModal
        show={showModal}
        setShow={setShowModal}
        customPrompt={customPrompt}
        setCustomPrompt={setCustomPrompt}
      />
    );
  }, [showModal, setShowModal, customPrompt]);

  const AskIdaIconCallback = useCallback(() => {
    return (
      <a
        className="cursor-pointer mr-2 icon-hover-bg"
        onClick={(e) => {
          e.preventDefault();
          setShowModal(!showModal);
        }}
        title="Ask Ida (⌘K / Ctrl+K)"
      >
        <MaterialIcon icon="auto_awesome" />
      </a>
    );
  }, [showModal, setShowModal]);

  return useMemo(
    () => ({
      customPrompt,
      setCustomPrompt,
      setShowModal,
      AskIdaModal: AskIdaModalCallback,
      AskIdaIcon: AskIdaIconCallback,
    }),
    [customPrompt, setShowModal, AskIdaModalCallback, AskIdaIconCallback]
  );
};

export default useAskIdaModal;
