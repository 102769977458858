import React, { useState } from 'react';
import Table from '../../../components/GenericTable';
import ButtonFilterDropdown from '../../../components/commons/ButtonFilterDropdown';
import LucideIcon from '../../../components/commons/LucideIcon';
import {
  columns,
  contentFilterOptions,
  metricData,
  monthOptions,
  rawData,
} from './ConversationData';
import { MetricCard } from '../../../components/chat/MetricCard';
import ButtonIcon from '../../../components/commons/ButtonIcon';

const FeedbackCell = ({ feedback, percentage }) => {
  return (
    <div className={`feedback-cell ${feedback}`}>
      <LucideIcon
        icon={percentage >= 50 ? 'ThumbsUp' : 'ThumbsDown'}
        color={percentage >= 50 ? 'green' : 'red'}
        size={16}
      />
      <div className="feedback-bar">
        <div
          className="feedback-bar-segment green"
          style={{ flexBasis: `${percentage}%` }}
        />
        <div
          className="feedback-bar-segment red"
          style={{ flexBasis: `${100 - percentage}%` }}
        />
      </div>
    </div>
  );
};
const ConversationLog = () => {
  const [filterMonth, setFilterMonth] = useState('2025-03');
  const [contentFilter, setContentFilter] = useState('all');
  const [sortingOrder, setSortingOrder] = useState(['tenant', 'ASC']);

  const formatDataForTable = (data) => {
    return data.map(
      ({
        id,
        date,
        tenant,
        feedback,
        conversation,
        knowledge,
        percentage,
      }) => ({
        id,
        dataRow: [
          { key: 'date', component: date },
          { key: 'tenant', component: tenant },
          {
            key: 'feedback',
            component: (
              <FeedbackCell feedback={feedback} percentage={percentage} />
            ),
          },

          { key: 'conversation', component: conversation },
          { key: 'knowledge', component: knowledge },
        ],
      })
    );
  };

  const tableData = formatDataForTable(rawData);

  const handleMonthChange = (e, option) => {
    setFilterMonth(option.key);
  };

  const handleContentFilterChange = (e, option) => {
    setContentFilter(option.key);
  };

  const handleSortingTable = (sorting) => {
    setSortingOrder([sorting.name.orderBy, sorting.name.clicked]);
  };

  return (
    <div className="p-0">
      <header className="d-flex px-3 pt-3 mb-3 align-items-center justify-content-between">
        <h1>Ida Conversation Log</h1>
        <div className="d-flex align-items-center gap-2">
          <ButtonFilterDropdown
            buttonText="Month"
            icon="calendar_today"
            options={monthOptions}
            handleFilterSelect={handleMonthChange}
            filterOptionSelected={monthOptions.find(
              (option) => option.key === filterMonth
            )}
            btnToggleStyle="btn-sm"
          />
          <ButtonFilterDropdown
            buttonText="Content Filter"
            icon="filter_list"
            options={contentFilterOptions}
            handleFilterSelect={handleContentFilterChange}
            filterOptionSelected={contentFilterOptions.find(
              (option) => option.key === contentFilter
            )}
            btnToggleStyle="btn-sm"
          />
          <ButtonIcon icon="download" classnames="btn-sm" label="Export" />
        </div>
      </header>
      <div className="d-flex px-3 align-items-center gap-3 mb-3">
        {metricData.map((metric, index) => (
          <MetricCard
            key={index}
            iconName={metric.iconName}
            title={metric.title}
            value={metric.value}
            classType={metric.class}
          />
        ))}
      </div>
      <div>
        <Table
          columns={columns}
          data={tableData}
          title="tenant-log"
          usePagination={true}
          dataInDB={true}
          sortingTable={handleSortingTable}
          sortingOrder={sortingOrder}
          headClass="bg-gray-table-head border-top"
          emptyDataText="No tenant data found"
          emptyDataIcon="manage_search"
          exportToCSV={false}
          tableData={rawData}
          fileName="tenant-log-export"
        />
      </div>
    </div>
  );
};

export default ConversationLog;
