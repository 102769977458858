import MaterialIcon from '../../commons/MaterialIcon';
import { Col, Row } from 'reactstrap';
import {
  cleanAccountName,
  getAccountType,
  getFraudServicesConfig,
} from './account.structure.constants';
import React from 'react';

const LockIcon = ({ config, whenPrinting }) => {
  return (
    <div
      style={{
        background: `var(${config.bg})`,
      }}
      className={`d-flex right-0 top-0 mt-2 mr-3 position-absolute align-items-center justify-content-center p-1 rounded-circle`}
    >
      <MaterialIcon
        style={{
          top: !whenPrinting ? 0 : -1,
        }}
        icon={config.icon}
        rounded
        clazz="text-white position-relative"
        size="fs-5"
      />
    </div>
  );
};

const AccountCard = ({ accounts, whenPrinting }) => {
  return (
    <Row className="px-2 flex-column gap-3 mt-0 pt-0">
      {accounts?.map((account) => {
        const config = getFraudServicesConfig(account);
        return (
          <Col key={account.id} md={12} sm={12} className="px-2 py-0">
            <div
              style={{
                borderColor: `var(${config.color})`,
                borderRadius: 'var(--rpt-widget-border-radius)',
              }}
              className={`border-2 p-0 h-100`}
            >
              <div className={`card-body ${whenPrinting ? 'p-2' : ''}`}>
                <div className="d-flex align-items-center gap-1">
                  <LockIcon config={config} whenPrinting={whenPrinting} />
                  <div>
                    <h6
                      className={`mb-1 ${
                        whenPrinting ? 'fs-7' : 'font-size-md'
                      }`}
                    >
                      {cleanAccountName(account)}
                    </h6>
                    <p
                      className={`d-inline-flex text-black font-weight-normal align-items-center mb-2 gap-1 ${
                        whenPrinting ? 'fs-11' : 'fs-8'
                      }`}
                    >
                      {account.accountType
                        ? getAccountType(account.accountType)?.title || ''
                        : null}{' '}
                      <span
                        className={`font-italic font-weight-semi-bold mb-0 ${
                          whenPrinting ? 'fs-11' : 'fs-8'
                        }`}
                      >
                        {config.text}
                      </span>
                    </p>
                  </div>
                </div>

                {account.fraudServices ? (
                  <p className="mb-0 d-flex w-100">
                    <MaterialIcon
                      icon="account_balance_wallet"
                      rounded
                      clazz={`text-gray-dark`}
                      size={whenPrinting ? 'fs-7' : 'fs-6'}
                    />
                    <span
                      className={`pl-1 text-nowrap font-weight-semi-bold text-gray-dark ${
                        whenPrinting ? 'fs-11' : 'fs-9'
                      }`}
                    >
                      Fraud Services:
                    </span>
                    <span className={`ml-1 ${whenPrinting ? 'fs-11' : 'fs-9'}`}>
                      <span>{account.fraudServices}</span>
                    </span>
                  </p>
                ) : null}

                {account.productServices ? (
                  <p className="mb-0 d-flex w-100">
                    <MaterialIcon
                      icon="devices"
                      rounded
                      clazz={`text-gray-dark`}
                      size={whenPrinting ? 'fs-7' : 'fs-6'}
                    />
                    <span
                      className={`pl-1 font-weight-semi-bold text-gray-dark ${
                        whenPrinting ? 'fs-11' : 'fs-9'
                      }`}
                    >
                      Services:
                    </span>
                    <span className={`ml-1 ${whenPrinting ? 'fs-11' : 'fs-9'}`}>
                      <span>{account.productServices}</span>
                    </span>
                  </p>
                ) : null}
              </div>
            </div>
          </Col>
        );
      })}
    </Row>
  );
};

export default AccountCard;
