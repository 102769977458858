import BaseRequestService from './baseRequest.service';

import authHeader from './auth-header';
import { createBlobObject } from '../utils/Utils';
import staticChatHistory from './staticChatsFromArchive.json';
const API_URL = process.env.REACT_APP_API_URL + '/api';

class ChatService extends BaseRequestService {
  async getChats(page = 1, limit = 10, title = '') {
    const params = { page, limit };

    if (title.length >= 3) {
      params.title = title;
    }

    const response = await this.get(
      `/chats`,
      {
        headers: authHeader(),
        params,
      },
      { fullResponse: true }
    );

    return response?.data;
  }

  async getChat(chatId) {
    const response = await this.get(
      `/chats/${chatId}`,
      {
        headers: authHeader(),
      },
      { fullResponse: true }
    );

    return response?.data;
  }

  async createChat(data) {
    return this.post(`/chats`, data, {
      headers: authHeader(),
    });
  }

  async updateChat(chatId, data) {
    return this.put(`/chats/${chatId}`, data, {
      headers: authHeader(),
    });
  }

  async createChatWithFile(payload, files) {
    const formData = new FormData();
    formData.append(
      'useAllAccessibleArchives',
      payload.useAllAccessibleArchives
    );
    formData.append('question[type]', payload.question.type);
    formData.append('question[text]', payload.question.text);

    for (const file of files) {
      const formBlob = await createBlobObject(file);
      formData.append('file', formBlob, file.name);
    }

    return this.post(`/chats`, formData, {
      headers: authHeader(),
    });
  }

  async createChatQuestion(chatId, data) {
    return this.post(`/chats/${chatId}/questions`, data, {
      headers: authHeader(),
    });
  }

  async createChatQuestionStream(chatId, data) {
    return fetch(`${API_URL}/chats/${chatId}/questions`, {
      method: 'POST',
      headers: { ...authHeader(), 'Content-Type': 'application/json' },
      body: JSON.stringify(data),
    });
  }

  async getChatQuestions(chatId, page = 1, limit = 10) {
    const response = await this.get(
      `/chats/${chatId}/questions`,
      {
        headers: authHeader(),
        params: { page, limit },
      },
      { fullResponse: true }
    );

    return response?.data;
  }

  async deleteChat(chatId) {
    return this.delete(`/chats/${chatId}`, {
      headers: authHeader(),
    });
  }

  async addFileToChat(chatId, files) {
    const formData = new FormData();
    for (const file of files) {
      const formBlob = await createBlobObject(file);
      formData.append('file', formBlob, file.name);
    }
    return this.post(`/chats/${chatId}/files`, formData, {
      headers: authHeader(),
      'Content-Type': 'multipart/form-data',
    });
  }

  async deleteChatFile(chatFileId) {
    return this.delete(`/chatFiles/${chatFileId}`, {
      headers: authHeader(),
    });
  }

  // archive is Knowledge base
  async addChatToArchive(chatId, archiveId) {
    return this.post(
      `/chats/${chatId}/archives`,
      {
        archiveId,
      },
      {
        headers: authHeader(),
      }
    );
  }

  async getChatsFromArchiveId(archiveId, page = 1, limit = 10) {
    // const response = await this.get(
    //   `/chats/${archiveId}/chats`,
    //   {
    //     headers: authHeader(),
    //     params: { page, limit },
    //   },
    //   { fullResponse: true }
    // );
    // return response?.data;
    return staticChatHistory;
  }

  async getChatFiles(chatId, page = 1, limit = 10) {
    const response = await this.get(
      `/chats/${chatId}/files`,
      {
        headers: authHeader(),
        params: { page, limit },
      },
      { fullResponse: true }
    );

    return response?.data;
  }
}
export default new ChatService();
