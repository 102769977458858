import axios from './axiosInstance';
import authHeader from './auth-header';
import BaseRequestService from './baseRequest.service';

class CubeService extends BaseRequestService {
  loadCustomAnalytics({
    type,
    users,
    lessonType,
    startDate,
    endDate,
    limit,
    teams,
    orderBy,
    assignments,
    userIds,
    teamIds,
    lessonIds,
    categoryIds,
  }) {
    return axios
      .post(
        `/analytics`,
        {
          type,
          users,
          startDate,
          endDate,
          limit,
          teams,
          assignments,
          orderBy,
          lessonType,
          userIds,
          teamIds,
          lessonIds,
          categoryIds,
        },
        {
          headers: authHeader(),
        }
      )
      .then((response) => response.data);
  }
}

export const cubeService = new CubeService();
