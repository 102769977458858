import axios from './axiosInstance';
import authHeader from './auth-header';
import BaseRequestService from './baseRequest.service';

class ContactService extends BaseRequestService {
  async getContactById(id) {
    return await this.get(`/contacts/${id}`, {
      headers: authHeader(),
    });
  }

  async updateContact(id, data) {
    return await this.put(`/contacts/${id}`, data, {
      headers: authHeader(),
    });
  }

  async linkOrganization(contact_id, organization_id) {
    return await this.put(
      `/contacts/${contact_id}/organization/${organization_id}/link`,
      {},
      {
        headers: authHeader(),
      }
    );
  }

  async getContactsByOrganizationId(queryFilter, { page = 1, limit = 5 }) {
    const { organizationId, search } = queryFilter || {};

    const params = {
      search,
      page,
      limit,
    };

    return await this.get(`/organizations/${organizationId}/contacts`, {
      headers: authHeader(),
      params,
    });
  }

  async getContacts(queryFilter, { page = 1, limit = 10 }) {
    const { filter, ...restProps } = queryFilter || {};

    const params = {
      ...restProps,
      ...filter,
      page,
      limit,
    };

    return await this.get(
      `/contacts`,
      {
        params,
        headers: authHeader(),
      },
      { fullResponse: true }
    );
  }

  async createContact(data) {
    return await this.post(
      `/contacts`,
      data,
      {
        headers: authHeader(),
      },
      { fullResponse: true, errorsRedirect: true }
    );
  }

  async deleteContacts(contactsIds) {
    return await this.delete(`/contacts`, {
      headers: authHeader(),
      params: {
        ids: contactsIds.join(','),
      },
    });
  }

  async getFollowers(contact_id, { page = 1, limit = 5 }) {
    return await this.get(`/contacts/${contact_id}/followers`, {
      headers: authHeader(),
      params: {
        page,
        limit,
      },
    });
  }

  async checkFollowing(contact_id, user_id) {
    const result = await this.get(
      `/contacts/${contact_id}/followers/${user_id}`,
      {
        headers: authHeader(),
      }
    );
    return result.isFollower;
  }

  async stopFollowing(contact_id, user_id) {
    return await this.delete(`/contacts/${contact_id}/followers/${user_id}`, {
      headers: authHeader(),
    });
  }

  async startFollowing(contact_id, user_id) {
    return await this.post(
      `/contacts/${contact_id}/followers/${user_id}`,
      {},
      {
        headers: authHeader(),
      }
    );
  }

  async getOwners(contact_id, { page = 1, limit = 5 }) {
    return await this.get(`/contacts/${contact_id}/owners`, {
      headers: authHeader(),
      params: {
        page,
        limit,
      },
    });
  }

  async addOwner(contact_id, user_id) {
    return await this.post(
      `/contacts/${contact_id}/owners/${user_id}`,
      {},
      {
        headers: authHeader(),
      }
    );
  }

  removeOwner(contact_id, user_id) {
    return axios.delete(`/contacts/${contact_id}/owners/${user_id}`, {
      headers: authHeader(),
    });
  }

  getFieldByContact(contact_id, { page = 1, limit = 10 }) {
    return axios
      .get(`/contacts/${contact_id}/fields`, {
        headers: authHeader(),
        params: {
          page,
          limit,
        },
      })
      .then((response) => response.data)
      .catch((err) => {
        console.log(err);
      });
  }

  updateCustomField(fieldId, data) {
    return this.put(`/contacts/${fieldId}/fields`, data, {
      headers: authHeader(),
    });
  }

  getCustomField(contactId, { page, limit }) {
    const params = {
      page,
      limit,
    };
    return axios.get(`/contacts/${contactId}/fields`, {
      params,
      headers: authHeader(),
    });
  }

  deleteCustomField(contactId, fieldId) {
    return this.delete(`/contacts/${contactId}/fields/${fieldId}`, {
      headers: authHeader(),
    })
      .then((response) => {
        return response.data;
      })
      .catch((error) => error);
  }

  impersonate(contactId) {
    return this.post(
      `/contacts/${contactId}/impersonate`,
      {},
      {
        headers: authHeader(),
      }
    );
  }
}

export default new ContactService();
