import { useEffect, useRef } from 'react';

const TableRowHover = ({
  children,
  bgColor,
  actionStyles = 'border shadow-lg',
  zIndex = 'z-index-99',
  onClick = () => {},
}) => {
  const actionRef = useRef();
  const actionBtnRef = useRef();
  const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);

  function setCoverRowWidths() {
    const rows = document.querySelectorAll(
      '.stickyColumn tbody tr, .stickyColumn1 tbody tr'
    );
    rows.forEach((row) => {
      if (row) {
        row.classList.add('tr-hover-action');
        row.style.width = `${row.offsetWidth}px`;
        row.style.height = `${row.offsetHeight}px`;
      }
    });
  }
  useEffect(() => {
    if (actionRef?.current && isSafari) {
      setCoverRowWidths();
    }
  }, []);
  return (
    <>
      {children ? (
        <div
          onClick={onClick}
          ref={actionRef}
          className={`${
            isSafari ? 'position-relative' : 'position-absolute h-100'
          } ${zIndex} w-100 text-right bg-hover-gray-opacity hover-actions top-0 left-0 cursor-pointer`}
        >
          <div
            ref={actionBtnRef}
            className={`d-inline-flex ${
              isSafari
                ? 'position-absolute z-index-250 action-items'
                : 'position-sticky px-2 action-items z-index-250 bg-gray-200'
            } ${
              !isSafari ? actionStyles : ''
            } right-0 h-100 align-items-center`}
          >
            {children}
          </div>
        </div>
      ) : (
        <div
          onClick={onClick}
          ref={actionRef}
          className={`${
            isSafari ? 'position-relative' : 'h-100 position-absolute'
          } w-100 text-right hover-actions top-0 left-0 ${zIndex} ${bgColor}`}
        />
      )}
    </>
  );
};

export default TableRowHover;
