import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { Link } from 'react-router-dom';

import Avatar from '../../Avatar';
import { SECONDARY_OWNER_LABEL } from '../../../utils/constants';

const AvatarListItem = ({
  profile,
  property,
  i,
  tooltipText,
  mainOwner,
  spacing,
  width,
  height,
  users,
  isClickable,
  allowDelete,
  defaultSize,
  sizeIcon,
  ...props
}) => {
  return (
    <OverlayTrigger
      key={`${profile[property]?.id || profile.id} ${i}`}
      placement="bottom"
      overlay={
        <Tooltip
          id={`tooltip-${profile[property]?.id || profile.id}`}
          className={`tooltip-profile font-weight-bold`}
          style={{ zIndex: 999999 }}
        >
          {tooltipText(profile)}
        </Tooltip>
      }
    >
      <div
        onClick={() => {
          props.onClick && props.onClick(profile);
        }}
        className="d-flex justify-content-center secondary-owners align-items-center position-relative rounded-circle border-2 border-white"
        style={{
          marginLeft: mainOwner ? `${spacing}px` : i === 0 ? 0 : `${spacing}px`,
          zIndex: profile.id === mainOwner?.id ? 10 : users.length - i, // Ensure mainOwner is on top
          width,
          height,
        }}
      >
        {isClickable || allowDelete ? (
          <span className="d-flex w-100 h-100">
            <Avatar
              user={profile[property] || profile}
              defaultSize={defaultSize}
              sizeIcon={sizeIcon}
              classModifiers="w-100 h-100"
            />
          </span>
        ) : (
          <Link className="d-flex cursor-default w-100 h-100">
            <Avatar
              user={profile[property] || profile}
              defaultSize={defaultSize}
              sizeIcon={sizeIcon}
              classModifiers="w-100 h-100"
            />
          </Link>
        )}
      </div>
    </OverlayTrigger>
  );
};
const IdfListAvatars = ({
  property,
  users = [],
  setUsers,
  maxUsers = 5,
  defaultSize,
  sizeIcon,
  allowDelete = false,
  isClickable = true,
  mainOwner,
  spacing = -8,
  width = 32,
  height = 32,
  animateOnHover,
  ...props
}) => {
  const tooltipText = (profile) => {
    if (profile.external) {
      return <p>{`${profile.email}`}</p>;
    }

    const user = property ? profile[property] : profile;
    return (
      <>
        <p>
          {user?.first_name} {user?.last_name}
        </p>
        <p>{SECONDARY_OWNER_LABEL}</p>
      </>
    );
  };

  if (!users?.length) {
    return;
  }

  return (
    <>
      {users?.map((profile, i) => (
        <>
          {animateOnHover ? (
            <div
              key={i}
              className={`${
                i < maxUsers ? '' : 'd-hide avatar-hidden'
              } avatar-item`}
            >
              <AvatarListItem
                profile={profile}
                property={property}
                users={users}
                setUsers={setUsers}
                maxUsers={maxUsers}
                defaultSize={defaultSize}
                sizeIcon={sizeIcon}
                allowDelete={allowDelete}
                isClickable={isClickable}
                mainOwner={mainOwner}
                spacing={spacing}
                width={width}
                height={height}
                tooltipText={tooltipText}
                animateOnHover={animateOnHover}
                {...props}
              />
            </div>
          ) : (
            <>
              {i < maxUsers ? (
                <AvatarListItem
                  profile={profile}
                  property={property}
                  users={users}
                  setUsers={setUsers}
                  maxUsers={maxUsers}
                  defaultSize={defaultSize}
                  sizeIcon={sizeIcon}
                  allowDelete={allowDelete}
                  isClickable={isClickable}
                  mainOwner={mainOwner}
                  spacing={spacing}
                  width={width}
                  height={height}
                  tooltipText={tooltipText}
                  animateOnHover={animateOnHover}
                  {...props}
                />
              ) : null}
            </>
          )}
        </>
      ))}
    </>
  );
};

export default IdfListAvatars;
