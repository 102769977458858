import { useEffect, useState } from 'react';
import { cubeService } from '../../../services';
import NoDataFound from '../../../components/commons/NoDataFound';
import Loading from '../../../components/Loading';
import InsightStatItem from '../InsightStatItem';
import { numbersWithComma } from '../../../utils/Utils';

const AssignmentStats = {
  totalAssignments: 'Number of Assignments',
  assignmentCompleted: 'Assignments - Completed',
  assignmentInProgress: 'Assignments - In Progress',
  assignmentNotStarted: 'Assignments - Not Started',
  assignmentOverDue: 'Assignments - Overdue',
};

const TooltipTitlesGet = {
  'Number of Assignments': 'Total Number of Assignments',
  'Assignments - Completed': 'Completed Assignments',
  'Assignments - In Progress': 'Completed some but not all of the assignmenT',
  'Assignments - Not Started': 'Not Started Assignments',
  'Assignments - Overdue': 'Assignments not completed by the due date',
};

const priorityOrder = [
  'AssignmentCompleted',
  'AssignmentInProgress',
  'AssignmentNotStarted',
  'AssignmentOverDue',
  'uniqueTotalAssignments',
];

export const UserTranscriptProgress = ({ userFilters = [] }) => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const filterData = userFilters?.length > 0 && userFilters[0]?.values;
  const filters = userFilters.filter((item) => item?.values);

  const getData = async () => {
    setLoading(true);
    const results = await cubeService.loadCustomAnalytics({
      type: 'AssignmentUserStats',
      users: filters[0]?.values,
    });

    // Map API keys to default stat names
    const mappedData = results?.map((item) =>
      Object.fromEntries(
        Object.entries(item).filter(([key]) => key !== 'totalAssignments') // Hide totalAssignments
      )
    );

    setData(mappedData);
    setLoading(false);
  };

  useEffect(() => {
    getData();
  }, [filterData]);

  return (
    <div className={`d-grid gap-4 ${loading ? 'grid-col-1' : 'grid-col-4'}`}>
      {loading ? (
        <Loading />
      ) : data?.length > 0 ? (
        Object.entries(data[0])
          .sort(
            ([keyA], [keyB]) =>
              priorityOrder.indexOf(keyA) - priorityOrder.indexOf(keyB)
          )
          .map(([key, value]) => {
            const statValue = numbersWithComma(value);
            return (
              <InsightStatItem
                key={key}
                value={key}
                hideAverage
                heading={AssignmentStats[key]}
                tooltipData={TooltipTitlesGet}
                stat={statValue || 'N/A'}
              />
            );
          })
      ) : (
        <NoDataFound title="Data no found" />
      )}
    </div>
  );
};
