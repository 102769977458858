import LucideIcon from '../../commons/LucideIcon';
import { Card } from 'react-bootstrap';
import React from 'react';

const StatCardHeader = ({ item }) => {
  return (
    <Card.Header className="p-3">
      <div className="d-flex align-items-center gap-1">
        <div
          style={{ width: 28, height: 28 }}
          className="d-flex align-items-center justify-content-center rounded-circle bg-primary-soft"
        >
          <LucideIcon icon={item.icon} clazz="text-primary" size="18" />
        </div>
        <h5 className="mb-0 font-weight-semi-bold">{item.title}</h5>
      </div>
    </Card.Header>
  );
};

export default StatCardHeader;
