import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { Card, CardBody, CardFooter, CardHeader } from 'reactstrap';

import {
  DATE_FORMAT,
  checkDueDate,
  setDateFormat,
  getTomorrowDate,
  DATE_FORMAT_TIME,
} from '../../../../utils/Utils';
import Stats from './ChecklistsStats';
import GenericTable from '../../../../components/GenericTable';
import { ReportSkeletonLoader } from '../../ReportSkeletonLoader';
import checklistService from '../../../../services/checklist.service';
import MaterialIcon from '../../../../components/commons/MaterialIcon';
import {
  statusText,
  checklistsReportColumns,
  checklistsReportColumnsToExport,
} from '../../../../utils/checklist.constants';
import OwnerAvatar from '../../../../components/ActivitiesTable/OwnerAvatar';
import ChecklistStatus from '../../../../components/checklist/ChecklistStatus';
import DatePickerTraining from '../../../../components/dealsBreakdown/DatePickerTraining';
import ReportSearchOptionDropdown from '../../../../components/commons/ReportSearchOptionDropdown';
import { paginationDefault } from '../../../../utils/constants';
import Pagination from '../../../../components/Pagination';
import {
  changePaginationLimit,
  changePaginationPage,
} from '../../../Deals/contacts/utils';
import IdfTooltip from '../../../../components/idfComponents/idfTooltip';

const ChecklistsReport = () => {
  const [order, setOrder] = useState();
  const [show, setShow] = useState(false);
  const [loading, setLoading] = useState(false);
  const [checklists, setChecklists] = useState([]);
  const [checklistIds, setChecklistIds] = useState([]);
  const [orgChecklists, setOrgChecklists] = useState([]);
  const [selectedChecklistsKeys, setSelectedChecklistsKeys] = useState([]);

  const [pagination, setPagination] = useState(paginationDefault);
  const [paginationPage, setPaginationPage] = useState(paginationDefault);

  const [dateRange, setDateRange] = useState({
    startDate: new Date(1970, 0, 1),
    endDate: getTomorrowDate(),
  });

  const mapOrganizationChecklist = (item, i) => {
    return {
      id: i,
      dataRow: [
        {
          key: 'COMPANY NAME',
          component: <span className="fw-bold">{item?.companyName}</span>,
        },
        {
          key: 'CHECKLIST NAME',
          component: item?.checklistTitle,
        },
        {
          key: 'OWNER',
          component: item?.ownerFirstName ? (
            <OwnerAvatar
              isMultiple={true}
              item={{
                external: false,
                first_name: item?.ownerFirstName,
                last_name: item?.ownerLastName,
              }}
            />
          ) : (
            '--'
          ),
        },
        {
          key: 'STATUS',
          component: (
            <ChecklistStatus
              item={{
                status: statusText[item?.status] || 'Pending',
                statusText: statusText[item?.status] || 'Not Started',
              }}
            />
          ),
        },
        {
          key: 'LAST UPDATE',
          component: moment(item?.updatedAt).format(DATE_FORMAT),
        },
        {
          key: 'DUE DATE',
          bgColor:
            item?.status !== 'completed' &&
            checkDueDate(item?.initialDueDate)?.length
              ? 'bg-red-soft'
              : '',
          component: (
            <span
              className={`${
                item?.status !== 'completed'
                  ? checkDueDate(item?.initialDueDate)
                  : ''
              }`}
            >
              {item?.status !== 'completed' &&
                checkDueDate(item?.initialDueDate)?.length && (
                  <span className="position-relative">
                    <MaterialIcon icon="flag" rounded filled clazz="text-red" />
                  </span>
                )}
              {item?.initialDueDate
                ? setDateFormat(item?.initialDueDate, 'MM/DD/YYYY')
                : '--'}
            </span>
          ),
        },
        {
          key: 'COMPLETED AT',
          component:
            item?.status === 'completed'
              ? setDateFormat(item?.updatedAt, 'MM/DD/YYYY')
              : '--',
        },
      ],
    };
  };

  const getOrganizationChecklists = async () => {
    setLoading(true);
    const payload = {
      page: paginationPage.page,
      limit: paginationPage.limit,
      order,
      'initialDueDateRange[start]': moment(dateRange?.startDate).format(
        DATE_FORMAT_TIME
      ),
      'initialDueDateRange[end]': moment(dateRange?.endDate).format(
        DATE_FORMAT_TIME
      ),
    };

    if (checklistIds?.length) {
      payload.checklistId = checklistIds;
    }

    const { data } = await checklistService.getOrgCheckLists(payload);

    const mappedData = data?.data?.map((orgChk) => {
      const owner = orgChk?.organizationChecklistOwners?.[0]?.user;
      const lastProgress =
        orgChk?.organizationChecklistProgresses?.[
          orgChk?.organizationChecklistProgresses?.length - 1 || 0
        ];

      return {
        companyName: orgChk?.organization?.name,
        checklistTitle: orgChk?.checklist?.title,
        ownerFirstName: owner?.first_name,
        ownerLastName: owner?.last_name,
        ownerName: owner?.name,
        status: lastProgress?.status,
        lastUpdate: lastProgress?.updatedAt,
        initialDueDate: orgChk?.initialDueDate,
        completedAt:
          lastProgress?.status === 'completed' ? lastProgress?.updatedAt : '--',
      };
    });

    setPagination(data?.pagination);
    setOrgChecklists(mappedData);
    setLoading(false);
  };

  const handleSort = (key) => {
    setOrder([[key?.name?.orderBy, key?.name?.clicked.toLowerCase()]]);
    toggleModify();
  };

  const toggleModify = () => {
    setPaginationPage((prev) => ({ ...prev, page: 1 }));
  };

  useEffect(() => {
    getOrganizationChecklists();
  }, [paginationPage]);

  const getChecklists = async () => {
    const { data } = await checklistService.getCheckLists({
      page: 1,
      limit: 100,
      status: 'active',
    });
    setChecklists(data.data);
  };

  const getStatsProps = () => {
    const stats = orgChecklists.reduce(
      (prev, curr) => {
        if (!curr) return prev;

        const { initialDueDate: idd, status = null } = curr;
        if (!status) return prev;

        const overdueDate = moment(idd).isBefore(moment());
        const currStatus = status;

        if (overdueDate && currStatus !== 'completed') {
          prev.overdue = (prev.overdue ?? 0) + 1;
        }
        prev[currStatus] = (prev[currStatus] ?? 0) + 1;
        return prev;
      },
      { completed: 0, inProgress: 0, pending: 0, overdue: 0 }
    );

    return { ...stats, totalCount: orgChecklists.length };
  };

  useEffect(() => {
    getChecklists();
  }, []);

  return (
    <Card className="h-100 border-0 shadow-0">
      <CardHeader>
        <div className="d-flex justify-content-between align-items-center w-100">
          <IdfTooltip text="Checklists Report" placement="bottom">
            <h4 className="card-title text-hover-primary mb-0 text-truncate">
              Checklists Report
            </h4>
          </IdfTooltip>
          <div className="d-flex align-items-center mr-6 pr-9 gap-2">
            <DatePickerTraining
              range={dateRange}
              setRange={setDateRange}
              toggle={toggleModify}
              btnToggleStyle="mx-0 w-250"
            />
            <ReportSearchOptionDropdown
              search
              show={show}
              data={checklists}
              setShow={setShow}
              showCheckAll={false}
              label={`Checklists`}
              setQueryFilters={() => {}}
              scrollable="pr-3 text-left"
              handleOptionSelected={() => {}}
              maxLength={22}
              customStyle={{ width: '250px' }}
              customKey={['title', 'checklistId']}
              selectedData={selectedChecklistsKeys}
              handleAllSelect={() => {
                setChecklistIds('');
                toggleModify();
              }}
              setSelectedData={setSelectedChecklistsKeys}
              handleOnDone={(items) => {
                setChecklistIds(items);
                toggleModify();
              }}
              controlledTitle={
                checklists
                  ?.filter((i) => checklistIds?.includes(i.id))
                  .map((i) => i.title)
                  .join(',') || ''
              }
            />
          </div>
        </div>
      </CardHeader>
      <CardBody className="p-0 flex-1 overflow-y-auto">
        <div className="px-2 pt-3">
          <Stats {...{ ...getStatsProps(), isLoading: loading }} />
        </div>
        {loading ? (
          <div className="w-100 d-block p-1">
            <ReportSkeletonLoader rows={15} height={15} />
          </div>
        ) : (
          <GenericTable
            checkbox={false}
            exportToCSV={true}
            usePagination={false}
            sortingTable={handleSort}
            tableData={orgChecklists}
            headClass="bg-gray-table-head border-top"
            fileName="Checklist Report"
            noDataInDbValidation={true}
            columns={checklistsReportColumns}
            columnsToExport={checklistsReportColumnsToExport}
            data={orgChecklists.map(mapOrganizationChecklist)}
          />
        )}
      </CardBody>
      <CardFooter className="border-top bg-gray-table-head p-2 border-bottom-0 border-left-0 border-right-0 rounded-0">
        <div className="d-flex align-items-center justify-content-end">
          <Pagination
            showPerPage
            paginationInfo={pagination}
            onPageChange={(newPage) => {
              changePaginationPage(newPage, setPaginationPage);
            }}
            onLimitChange={(perPage) => {
              changePaginationLimit(perPage, setPaginationPage);
            }}
          />
        </div>
      </CardFooter>
    </Card>
  );
};

export default ChecklistsReport;
