import RightPanelModal from '../../../components/modal/RightPanelModal';
import Write from '../../../pages/Write';
import { PROSPECT_RIGHT_PANEL_WIDTH } from '../../../utils/Utils';
import TopicIcon from '../../commons/TopicIcon';

const WriteAIPanel = ({ newsstandModal, setNewsstandModal, profileInfo }) => {
  return (
    <RightPanelModal
      showModal={newsstandModal}
      setShowModal={setNewsstandModal}
      profileInfo={profileInfo}
      containerWidth={PROSPECT_RIGHT_PANEL_WIDTH}
      Title={
        <div className="d-flex py-2 gap-2 align-items-center">
          <div className="nav-item scroll-item">
            <div className={`nav-icon p-1 merchant cursor-pointer`}>
              <TopicIcon
                icon="draw"
                filled={true}
                iconBg="merchant"
                iconSize="fs-5"
              />
            </div>
          </div>
          <h4 className="mb-0">Write</h4>
        </div>
      }
    >
      <Write layout="vertical" customProspect={profileInfo} />
    </RightPanelModal>
  );
};

export default WriteAIPanel;
