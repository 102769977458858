import React, { useState, useEffect, useContext, Fragment } from 'react';
import { useHistory } from 'react-router-dom';
import * as Sentry from '@sentry/react';

import UserService from '../services/user.service';
import Avatar from './Avatar';
import { useProfileContext } from '../contexts/profileContext';
import { ToggleMenuContext } from '../contexts/toogleMenuContext';
import HelpCenter from './helpCenter/HelpCenter';
import { Col, Row } from 'react-bootstrap';
import { TenantContext } from '../contexts/TenantContext';
import avatarService from '../services/avatar.service';
import { GlobalSearch } from './search/GlobalSearch';
import NavbarFilters from './navBarFilters/NavbarFilters';
import constants from '../components/search/GlobalSearch.constants.json';
import { useModuleContext } from '../contexts/moduleContext';
import { isModuleAllowed, isPermissionAllowed } from '../utils/Utils';
import SidebarIcon from './sidebar/SidebarIcon';
import MaterialIcon from './commons/MaterialIcon';
import ProfilePage from '../pages/ProfilePage';

export default function Header() {
  const { tenant } = useContext(TenantContext);
  const history = useHistory();
  const [dropdownOpenDesktop, setDropdownOpenDesktop] = useState(false);

  const [profile, setProfile] = useState({});
  const { profileInfo, setProfileInfo } = useProfileContext();
  const { setOpen } = useContext(ToggleMenuContext);

  const toggleDesktop = () => setDropdownOpenDesktop((prevState) => !prevState);

  const [tenantLogo, setTenantLogo] = useState(null);

  const { moduleMap } = useModuleContext();
  const newOptionsList = [...constants.optionsList].filter((item) =>
    isPermissionAllowed(item.permission.collection, item.permission.action)
  );
  useEffect(() => {
    const keyToPluralMap = {
      organizations: 'organization',
      contacts: 'contact',
      deals: 'deal',
      task: 'task',
      event: 'event',
      call: 'call',
      products: 'product',
    };

    newOptionsList.map((obj) => {
      const pluralKey = keyToPluralMap[obj.key];
      if (pluralKey && moduleMap[pluralKey]) {
        return (obj.name = moduleMap[pluralKey].plural);
      }
      return obj;
    });
  }, [newOptionsList]);

  useEffect(() => {
    if (tenant.status === 'disabled') {
      history.push('/403');
    }
    const getAvatarLogo = async () => {
      let logo = null;

      if (tenant?.logo) {
        logo = await avatarService.getAvatarMemo(tenant.logo, true);
      }
      setTenantLogo(logo);
    };

    getAvatarLogo();
  }, [tenant]);

  useEffect(() => {
    UserService.getUserInfo()
      .then((profileResult) => {
        setProfile(profileResult);
        setProfileInfo(profileResult);
        // to be on a safe side.
        try {
          Sentry.setUser({
            id: profileResult.id,
            email: profileResult.email,
            name: profileResult.name,
          });
        } catch (e) {
          console.log(e);
        }
      })
      .catch((err) => console.error(err));
  }, []);

  useEffect(() => {
    setProfile((prev) => ({
      ...prev,
      ...profileInfo,
    }));
  }, [profileInfo]);

  useEffect(() => {
    const screen = window.screen;

    if (screen.width < 1200) setOpen(false);
  }, [history.location, setOpen]);

  return (
    <>
      <ProfilePage
        show={dropdownOpenDesktop}
        setShow={setDropdownOpenDesktop}
      />
      <header
        id="headerDesktop"
        className="header desktop d-none d-md-block navbar navbar-expand-lg z-index-100 navbar-fixed navbar-height navbar-flush border-bottom pr-0 navbar-container"
      >
        <div className="navbar-nav-wrap py-1">
          <div
            className="mr-2 d-xl-none"
            onClick={() => setOpen((prevOpen) => setOpen(!prevOpen))}
          >
            <span className="material-symbols-rounded text-primary font-size-3xl ">
              menu
            </span>
          </div>
          <div className="navbar-brand-wrapper">
            <a className="navbar-brand" href="/" aria-label="Identifee">
              <img
                className="navbar-brand-logo"
                style={{ height: '40px' }}
                src={`${tenant?.settings?.light_theme_logo || tenantLogo?.url}`}
                alt={`${tenant.name} Logo`}
              />
              <img
                className="navbar-brand-logo-mini"
                src={`${
                  tenant.settings?.light_theme_logo ||
                  tenant.logo ||
                  '/img/icon.svg'
                }`}
                alt={`${tenant.name} Logo`}
              />
            </a>
          </div>
          <div className="pl-0 breadcrumb-desktop header-logo">
            <Row>
              <Col>
                <SidebarIcon />
              </Col>
            </Row>
          </div>
          <div className="col justify-content-between d-flex px-0">
            {!profileInfo?.role?.admin_access && (
              <Fragment>
                {moduleMap.organization !== undefined && (
                  <div className="d-flex align-items-center justify-content-between w-100">
                    <GlobalSearch data={newOptionsList} />
                    <NavbarFilters moduleMap={moduleMap} />
                  </div>
                )}
              </Fragment>
            )}
          </div>
          <div className="d-flex align-items-center col-auto pr-1">
            <HelpCenter />
            {isModuleAllowed(tenant.modules, 'Settings') && (
              <section
                className="ml-auto mr-1 cursor-pointer btn btn-icon btn-icon-sm icon-hover-bg"
                onClick={() => history.push('/settings/users')}
              >
                <MaterialIcon
                  icon="settings"
                  size="fs-3"
                  clazz="cursor-pointer"
                />
              </section>
            )}
            <div className="navbar-nav-wrap-content-right avatar-mobile">
              <ul className="navbar-nav align-items-center flex-row">
                <li className="nav-item" onClick={toggleDesktop}>
                  <div className="bg-transparent border-0 p-2">
                    <Avatar
                      user={profile}
                      active={false}
                      sizeIcon="avatar-dark"
                      classModifiers="bg-opacity-hover"
                    />
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </header>
      <header
        id="headerMobile"
        className="header mobile navbar d-md-none navbar-expand-lg z-index-100 navbar-fixed navbar-height navbar-flush border-bottom pr-0 navbar-container"
      >
        <div className="topheader d-flex justify-content-between align-items-center">
          <div
            className="mr-2 d-xl-none"
            onClick={() => setOpen((prevOpen) => setOpen(!prevOpen))}
          >
            <span className="material-symbols-rounded text-primary font-size-3xl ">
              menu
            </span>
          </div>

          <div className="topheader-right d-flex justify-content-between w-100 align-items-center">
            <div className="navbar-brand-wrapper">
              <a className="navbar-brand" href="/" aria-label="Identifee">
                <img
                  className="navbar-brand-logo"
                  style={{ height: '40px' }}
                  src={`${
                    tenant?.settings?.light_theme_logo || tenantLogo?.url
                  }`}
                  alt={`${tenant.name} Logo`}
                />
                <img
                  className="navbar-brand-logo-mini"
                  src={`${
                    tenant.settings?.light_theme_logo ||
                    tenant.logo ||
                    '/img/icon.svg'
                  }`}
                  alt={`${tenant.name} Logo`}
                />
              </a>
            </div>
            <div className="d-flex align-items-center col-auto pr-1">
              <HelpCenter />
              <div className="navbar-nav-wrap-content-right avatar-mobile">
                <ul className="navbar-nav align-items-center flex-row">
                  <li className="nav-item" onClick={toggleDesktop}>
                    <div className="bg-transparent border-0 p-2">
                      <Avatar
                        user={profile}
                        active={false}
                        sizeIcon="avatar-dark"
                      />
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>

        {!profileInfo?.role?.admin_access && (
          <Fragment>
            {moduleMap.organization !== undefined && (
              <div className="serach-wrp d-flex align-items-center justify-content-center py-1 px-2 gap-3 w-100">
                <GlobalSearch data={newOptionsList} />
                <NavbarFilters moduleMap={moduleMap} />
              </div>
            )}
          </Fragment>
        )}
      </header>
    </>
  );
}
