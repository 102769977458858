import {
  RIGHT_PANEL_WIDTH,
  addressify,
  isToFixedNoRound,
} from '../../../utils/Utils';
import { CardBody } from 'reactstrap';
import MaterialIcon from '../../../components/commons/MaterialIcon';
import RightPanelModal from '../../../components/modal/RightPanelModal';
import routes from '../../../utils/routes.json';
import { useHistory } from 'react-router-dom';
import stringConstants from '../../../utils/stringConstants.json';
import TooltipComponent from '../../../components/lesson/Tooltip';
import Avatar from '../../../components/Avatar';
import React from 'react';
import LinkWrapper from '../../../components/modal/LinkWrapper';

const PipelineCompanyModal = ({ showModal, setShowModal, deal }) => {
  const { organization } = deal;
  const history = useHistory();
  const constants = stringConstants.deals.contacts.profile;

  return (
    <>
      <RightPanelModal
        showModal={showModal}
        setShowModal={setShowModal}
        showOverlay={true}
        containerBgColor={'pb-0'}
        containerWidth={RIGHT_PANEL_WIDTH}
        containerPosition={'position-fixed'}
        headerBgColor="bg-gray-5 px-3"
        cardHeaderClass="width-full"
        Title={
          <div className="d-flex justify-content-between align-items-center width-full">
            <div className="d-flex py-2 gap-2 align-items-center text-capitalize">
              <div className="d-flex align-items-center justify-content-center">
                <label
                  className="avatar avatar-md avatar-circle border-solid border-width-2 border-primary-soft avatar-uploader"
                  htmlFor="avatarUploader"
                >
                  <Avatar
                    user={organization}
                    classModifiers="max-wh bg-primary-soft text-primary"
                    sizeIcon="font-size-2xl"
                    type={organization?.name ? 'organization' : 'contact'}
                  />
                </label>
              </div>
              <div className="d-flex flex-column">
                <h3 className="mb-0">{organization?.name}</h3>
              </div>
            </div>

            <button
              className="btn btn-sm d-flex bg-gray-dark-5 align-items-center mr-2 icon-hover-bg spacing"
              onClick={() =>
                history.push(
                  `${routes.companies}/${deal?.contact_organization_id}/organization/profile`
                )
              }
            >
              <TooltipComponent title="View Company">
                <span className="d-flex">
                  <MaterialIcon icon="open_in_new" size="fs-6" />
                </span>
              </TooltipComponent>
            </button>
          </div>
        }
      >
        <CardBody className="scrollable-body">
          <div className="p-3">
            <h4>Basic Info</h4>
            <div className="d-flex flex-column gap-2">
              <div className="d-flex gap-3 align-items-center">
                <MaterialIcon icon="phone_in_talk" clazz="text-muted" />
                <span className="font-weight-medium">
                  {organization?.phone_office || '--'}
                </span>
              </div>
              <div className="d-flex gap-3 align-items-center">
                <MaterialIcon icon="location_on" clazz="text-muted" />
                <span className="font-weight-medium">
                  {organization ? addressify(organization) : '--'}
                </span>
              </div>
            </div>
            <div className="d-flex flex-column gap-2 mt-4 max-w-350">
              <h4>Other Info</h4>
              <div className="d-flex justify-content-between">
                <span className="text-muted flex-1">
                  {constants.naicsCodeLabel}
                </span>
                <span className="font-weight-medium flex-1">
                  {organization?.naics_code || '--'}
                </span>
              </div>
              <div className="d-flex justify-content-between">
                <span className="text-muted flex-1">
                  {constants.employeesLabel}
                </span>
                <span className="font-weight-medium flex-1">
                  {organization?.employees || '--'}
                </span>
              </div>
              <div className="d-flex justify-content-between">
                <span className="text-muted flex-1">
                  {constants.totalRevenue}
                </span>
                <span className="font-weight-medium flex-1">
                  {organization?.annual_revenue
                    ? isToFixedNoRound(organization?.annual_revenue, 2)
                    : '--'}
                </span>
              </div>
              <div className="d-flex justify-content-between">
                <span className="text-muted flex-1">Total Revenue</span>
                <span className="font-weight-medium flex-1">
                  {organization?.total_revenue
                    ? isToFixedNoRound(organization?.total_revenue, 2)
                    : '--'}
                </span>
              </div>
              <div className="d-flex justify-content-between">
                <span className="text-muted flex-1">
                  {constants.industryLabel}
                </span>
                <span className="font-weight-medium flex-1">
                  {organization?.industry || '--'}
                </span>
              </div>
              <div className="d-flex justify-content-between">
                <span className="text-muted flex-1">
                  {constants.websiteLabel}
                </span>
                <span className="font-weight-medium flex-1">
                  {organization?.website ? (
                    <LinkWrapper
                      href={
                        organization?.website?.includes('http')
                          ? organization?.website
                          : 'https://' + organization?.website
                      }
                    >
                      <div className="d-flex align-items-center gap-1">
                        {organization?.website}
                        <MaterialIcon icon="open_in_new" size="fs-6" />
                      </div>
                    </LinkWrapper>
                  ) : (
                    '--'
                  )}
                </span>
              </div>
            </div>
          </div>
        </CardBody>
      </RightPanelModal>
    </>
  );
};

export default PipelineCompanyModal;
