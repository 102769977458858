import React, { useCallback, useEffect, useState } from 'react';
import { DragDropContext, Droppable } from 'react-beautiful-dnd';

import './styles.css';
import { colorsDeals } from './constasts/deals';
import Columns from './Column';
import dealService from '../../services/deal.service';
import { Col } from 'react-bootstrap';
import { isPermissionAllowed, isToFixedNoRound } from '../../utils/Utils';
import { usePipelineBoardContext } from '../../contexts/PipelineBoardContext';
import TooltipComponent from '../lesson/Tooltip';
import NoDataFound from '../commons/NoDataFound';
import ButtonIcon from '../commons/ButtonIcon';
import routes from '../../utils/routes.json';
import { Link } from 'react-router-dom';
import { useModuleContext } from '../../contexts/moduleContext';
import {
  CLOSED_WON,
  LOST,
  WON,
} from '../../views/Deals/pipelines/Pipeline.constants';
import MaterialIcon from '../commons/MaterialIcon';
import TextOverflowTooltip from '../commons/TextOverflowTooltip';
import useConfettiEffect from '../commons/ConfettiEffect';

const BoardFooter = ({ show, refresh, viewType, selectedPipeline }) => {
  const [items, setItems] = useState([]);

  useEffect(() => {
    (async () => {
      if (viewType === 'column' && selectedPipeline?.id) {
        const { data } = await dealService.getStatusSummary(
          selectedPipeline?.id
        );
        // sorting by won status, so that it shows at first in view
        setItems(data.reverse());
      }
    })();
  }, [refresh, selectedPipeline]);

  const FooterCard = ({ item }) => {
    const { moduleMap } = useModuleContext();
    const ICONS = {
      [WON]: 'thumb_up',
      [LOST]: 'thumb_down',
    };
    const COLORS = {
      [WON]: 'text-success',
      [LOST]: 'text-red',
    };
    return (
      <>
        <div className="d-flex gap-2 align-items-center">
          <div>
            <MaterialIcon
              icon={ICONS[item.resolution] || 'delete'}
              clazz={COLORS[item.resolution]}
            />
          </div>
          <div>
            <p
              className={`mb-0 fs-7 text-capitalize font-weight-bold ${
                COLORS[item.resolution]
              }`}
            >
              {item.resolution}
            </p>
            <p className="mb-0 fs-8">
              {isToFixedNoRound(item.amount, 2)} • {item.count}{' '}
              {moduleMap.deal.plural.toLowerCase()}
            </p>
          </div>
        </div>
      </>
    );
  };

  return (
    <div
      className={`position-fixed board-footer ease animate slideInUp bg-transparent w-100 left-0 ${
        show ? 'd-block bottom-0 z-index-100' : 'hide bottom-0 -z-index-2'
      }`}
    >
      <div className="p-1 bg-white shadow-lg rounded">
        <div className="d-flex rounded w-100 justify-content-between">
          {items?.map((item, index) => (
            <Droppable droppableId={item.tenant_deal_stage_id} key={index}>
              {(provided, snapshot) => (
                <div
                  ref={provided.innerRef}
                  {...provided.droppableProps}
                  className={`border board-footer-item ${
                    item.resolution
                  } py-2 px-4 mb-0 rounded ${
                    snapshot.isDraggingOver
                      ? ` deal-types ${item.resolution}`
                      : ''
                  }`}
                  role="alert"
                >
                  <FooterCard item={item} />
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          ))}
        </div>
      </div>
    </div>
  );
};

const BoardHeader = ({ deals, handleAddDeal, edit }) => {
  const { stages } = usePipelineBoardContext();
  const { moduleMap } = useModuleContext();

  const AddYourFirstDealStage = () => {
    return (
      <>
        {isPermissionAllowed('pipelines', 'create') && (
          <div className="text-center">
            <Link to={routes.pipelinesAndStages}>
              <a>
                <ButtonIcon
                  icon="add"
                  label="Add Stage"
                  classnames="btn-sm my-2"
                />
              </a>
            </Link>
          </div>
        )}
      </>
    );
  };
  return (
    <>
      {stages?.length > 0 ? (
        <div className="d-flex">
          <div
            id="divPipelineBoard"
            className={`parent-board-header mb-2 d-flex flex-grow-1 border-0 gap-2 mx-0 w-100 top-0 ${
              edit
                ? 'pipeline-board-edit overflow-y-hidden overflow-x-auto'
                : ''
            }`}
          >
            {[...stages]
              ?.sort((a, b) => {
                const order = { 'Closed Lost': 2, 'Closed Won': 1 };

                if (
                  order[a.name] !== undefined ||
                  order[b.name] !== undefined
                ) {
                  return (order[a.name] ?? 0) - (order[b.name] ?? 0);
                }

                return a?.rank?.localeCompare(b?.rank);
              })
              ?.map((stage, index) => {
                const state =
                  Object.hasOwn(deals, stage?.name) && deals[stage?.name];
                const title = stage?.name || '';
                const { header, pagination } = state;

                return (
                  <Col
                    key={index}
                    className={`p-2 pr-0 deal-col position-relative bg-white board-header rounded ${
                      edit ? 'deal-edit-header' : 'w-100'
                    } ${title === 'Closed Won' && 'board-header-won'}  ${
                      title === 'Closed Lost' && 'board-header-lost'
                    }`}
                  >
                    <div className="d-flex align-items-center justify-content-between">
                      <div className="m-0 px-1">
                        <h5 className="mb-1 font-weight-bold text-capitalize">
                          <TextOverflowTooltip
                            textStyle="font-weight-bold text-capitalize"
                            text={title}
                          />
                        </h5>
                        <div className="m-0 pr-1">
                          <div className={'d-flex align-item-center fs-8'}>
                            <span
                              className="text-truncate font-weight-semi-bold"
                              style={{ maxWidth: 80 }}
                            >
                              {stage?.probability === 100 ? (
                                <TooltipComponent
                                  title={`Total Stage Value: ${isToFixedNoRound(
                                    header?.total_amount,
                                    2
                                  )}`}
                                >
                                  <span>
                                    {isToFixedNoRound(header?.total_amount, 2)}
                                  </span>
                                </TooltipComponent>
                              ) : (
                                <TooltipComponent
                                  title={`Total Stage Value: ${isToFixedNoRound(
                                    header?.total_amount,
                                    2
                                  )}`}
                                >
                                  <span>
                                    {isToFixedNoRound(header?.total_amount, 2)}
                                  </span>
                                </TooltipComponent>
                              )}
                            </span>
                            <span className="text-muted mx-2">•</span>
                            <span className="text-nowrap">
                              {pagination?.count}{' '}
                              {moduleMap.deal.plural.toLowerCase()}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Col>
                );
              })}
          </div>
        </div>
      ) : (
        <NoDataFound
          title={`No ${
            moduleMap.deal === undefined ? '' : moduleMap.deal.plural
          } stages available`}
          description={<AddYourFirstDealStage />}
          icon="view_week"
          containerStyle="text-gray-900 my-6 py-6"
        />
      )}
    </>
  );
};

const Board = ({
  onGetDeals,
  setNotification,
  listDeals = {},
  onClick,
  editPipeline,
  refreshBoard,
  onAddDeal,
  viewType,
  selectedPipeline,
  DealStats,
}) => {
  const [itemPicked, setItemPicked] = useState(false);
  const [pickedItem, setPickedItem] = useState({});
  const [boardColumns, setBoardColumns] = useState({ ...listDeals });
  const [refreshBoardFooter, setRefreshBoardFooter] = useState(1);
  const { stages } = usePipelineBoardContext();
  const { toggleConfetti, ConfettiEffect } = useConfettiEffect();

  const getStageById = (tenantDealStageId) => {
    return stages?.find((st) => st.id === tenantDealStageId);
  };
  const getStageByName = (name) => {
    const [dealFound] = Object.entries(listDeals || {})
      .filter(([type, value]) => {
        return type === name;
      })
      .map(([type, value = {}]) => {
        return {
          id: value.stageId,
          name: type,
          stagePosition: value.stagePosition,
          pipelineStageStatusId: value?.pipelineStageStatusId,
        };
      });

    return dealFound || '';
  };

  useEffect(() => {
    setBoardColumns({ ...listDeals });
  }, [listDeals]);

  useEffect(() => {
    const columns = document.getElementsByClassName('sticky-bottom');
    const parent = document.getElementsByClassName('parent-column')[0];
    if (columns?.length) {
      for (const column of columns) column.style.height = 'auto';
      for (const column of columns) {
        if (column.clientHeight <= parent.clientHeight) {
          column.style.height = `${parent.clientHeight}px`;
        }
      }
    }
  }, [listDeals]);

  const reorderBetweenRow = (currentStatus, startIndex, endIndex) => {
    const state = listDeals[currentStatus]?.items;

    if (state) {
      const [removed] = state?.splice(startIndex, 1);
      state?.splice(endIndex, 0, removed);
    }
  };

  const reorderBetweenColumn = (
    currentStatus,
    secondStatus,
    startIndex,
    endIndex
  ) => {
    const origin = listDeals[currentStatus]?.items;
    const destiny = listDeals[secondStatus]?.items;

    if (origin && destiny) {
      const [removed] = origin?.splice(startIndex, 1);
      destiny?.splice(endIndex, 0, removed);
    }
  };

  const extractIdAndName = (template) => {
    const regex = /id-(.*?)-name-(.*)/;

    const match = template.match(regex);
    if (match) {
      const id = match[1];
      const name = match[2];

      return { id, name };
    } else {
      // Return null or handle the case when the template format is invalid
      return null;
    }
  };
  const onUpdateStage = async (draggableId, destination, source) => {
    if (
      destination.droppableId === source.droppableId &&
      destination.index === source.index
    ) {
      return;
    }

    const wonColumn =
      destination.droppableId === 'Closed Won' &&
      destination.droppableId !== source.droppableId;

    const lostColumn =
      destination.droppableId === 'Closed Lost' &&
      destination.droppableId !== source.droppableId;

    const parseTemplate = extractIdAndName(draggableId);
    const id = parseTemplate?.id;

    // Moving a deal to Won must now provide the tenant_deal_stage_id
    const tenantDealStageId = destination.stageId;

    const destinationTenantDealStageId = destination.stageId;

    const data = {
      // tenant_deal_stage_id: stageId,
      tenant_deal_stage_id: tenantDealStageId,
      update_deal:
        destination.droppableId === source.droppableId
          ? [
              {
                type: destination.droppableId,
                tenant_deal_stage_id: destinationTenantDealStageId,
                position: destination.index,
                origin: source.index,
                limit: listDeals[destination.droppableId].pagination.count,
              },
            ]
          : [
              {
                type: source.droppableId,
                tenant_deal_stage_id: source.stageId,
                position: source.index,
                limit: listDeals[source.droppableId].pagination.count,
              },
              {
                type: destination.droppableId,
                tenant_deal_stage_id: destinationTenantDealStageId,
                position: listDeals[destination.droppableId].pagination.count,
                destination: true,
                limit: listDeals[destination.droppableId].pagination.count,
              },
            ],
    };

    await dealService.updateDealPosition(id, data);

    if (wonColumn) {
      toggleConfetti();
      setNotification('success', 'Pipeline moved to won stage.');
    } else if (lostColumn) {
      setNotification('success', 'Pipeline moved to lost stage.');
    } else {
      setNotification('success', `${parseTemplate?.name} saved.`);
    }
  };

  const onDragEnd = async (result) => {
    let { destination, source, draggableId } = result;
    if (
      destination?.droppableId === source?.droppableId &&
      destination?.index === source?.index
    ) {
      setItemPicked(false);
      setPickedItem({});
      return;
    }
    if (!destination) destination = source;

    setItemPicked(false);
    setPickedItem({});

    if (!destination) return;

    const sourceName = getStageByName(source.droppableId); // here droppableId is name of stage
    const destinationName = getStageByName(destination.droppableId);
    source = { ...source, stageId: sourceName.id };
    destination = { ...destination, stageId: destinationName.id };

    const wonColumn =
      destination.droppableId === 'Closed Won' &&
      destination.droppableId !== source.droppableId;

    const lostColumn =
      destination.droppableId === 'Closed Lost' &&
      destination.droppableId !== source.droppableId;

    // if (!destination.stageId || wonColumn || lostColumn) {
    if (!destination.stageId) {
      const { droppableId } = destination;
      const updatedDroppableId = wonColumn
        ? WON
        : lostColumn
        ? LOST
        : droppableId;
      // this part is when cards are moved between won/lost/delete stages in footer
      const dealId = extractIdAndName(draggableId)?.id;

      if (updatedDroppableId === 'delete') {
        await dealService.deleteDeal(dealId);

        setNotification('success', 'Pipeline removed successfully.');
      } else {
        // this else statement will now remove as new won/lost stage added in pipelineStageStatuses API
        await dealService.updateDeal(dealId, {
          tenant_deal_stage_id: updatedDroppableId,
        });

        const droppedStage = getStageById(updatedDroppableId);
        if (droppedStage?.name === CLOSED_WON) {
          toggleConfetti();
          setNotification('success', 'Pipeline moved to won stage.');
        } else {
          setNotification('success', 'Pipeline moved to lost stage.');
        }
      }
      // when drag released in board footer update board
      refreshBoard({
        sourceId: sourceName.id,
        destinationId: destination?.droppableId,
      });

      // also update footer won/lost/delete component to show updated count
      setRefreshBoardFooter((prevState) => prevState + 1);
    } else {
      // this part when cards are moved between stages
      if (destination.droppableId !== source.droppableId) {
        reorderBetweenColumn(
          source.droppableId,
          destination.droppableId,
          source.index,
          destination.index
        );

        await onUpdateStage(draggableId, destination, source);

        // when drag released in board footer update board
        refreshBoard({
          sourceId: sourceName.id,
          destinationId: destinationName.id,
        });

        // also update footer won/lost/delete component to show updated count
        setRefreshBoardFooter((prevState) => prevState + 1);
      } else {
        reorderBetweenRow(source.droppableId, source.index, destination.index);
        await onUpdateStage(draggableId, destination, source);

        // when drag released in board footer update board
        refreshBoard({
          sourceId: sourceName.id,
          destinationId: sourceName.id,
        });

        // also update footer won/lost/delete component to show updated count
        setRefreshBoardFooter((prevState) => prevState + 1);
      }
    }
  };

  // using useCallback is optional
  const onBeforeCapture = useCallback((event) => {
    setPickedItem(event);
    setItemPicked(!itemPicked);
  }, []);
  const onBeforeDragStart = useCallback(() => {}, []);
  const onDragStart = useCallback(() => {}, []);
  const onDragUpdate = useCallback(() => {}, []);

  return (
    <>
      <ConfettiEffect />
      <div className="position-relative w-100 pr-3">
        <div className="m-0 pl-3 pipelines-board flex-nowrap overflow-y-hidden overflow-x-auto">
          <BoardHeader
            deals={{ ...boardColumns }}
            handleAddDeal={(stage) =>
              onAddDeal({ ...stage, title: stage.name })
            }
            edit={editPipeline}
          />
          {!editPipeline && (
            <div className="d-flex flex-row parent-column gap-2 mx-0">
              <DragDropContext
                onDragEnd={onDragEnd}
                onBeforeCapture={onBeforeCapture}
                onBeforeDragStart={onBeforeDragStart}
                onDragStart={onDragStart}
                onDragUpdate={onDragUpdate}
              >
                {[...stages]
                  ?.sort((a, b) => {
                    const order = { 'Closed Lost': 2, 'Closed Won': 1 };
                    return (order[a.name] ?? 0) - (order[b.name] ?? 0);
                  })
                  ?.map((stage, index) => {
                    const type = stage.name;
                    const value = listDeals[type] || {};
                    return (
                      <Columns
                        id={stage.id}
                        key={stage}
                        title={type}
                        color={colorsDeals[index]}
                        value={{ ...value, deal_type: type }}
                        onGetDeals={onGetDeals}
                        setNotification={setNotification}
                        listDeals={listDeals}
                        onClick={onClick}
                        onAddDeal={() => onAddDeal({ ...value, title: type })}
                        pickedItem={pickedItem}
                      />
                    );
                  })}
                <BoardFooter
                  show={itemPicked}
                  viewType={viewType}
                  refresh={refreshBoardFooter}
                  selectedPipeline={selectedPipeline}
                />
              </DragDropContext>
            </div>
          )}
        </div>
      </div>
      <div className="border-top bg-gray-table-head">
        <div className="d-flex py-1">
          <ul className="px-3 mb-0 flex-fill">
            <DealStats />
          </ul>
          <div className="py-1 ml-auto" />
        </div>
      </div>
    </>
  );
};

export default Board;
