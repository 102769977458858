import axios from './axiosInstance';
import authHeader from './auth-header';

class FilesService {
  uploadFile(body) {
    return axios
      .post(`/files`, body, {
        headers: {
          ...authHeader(),
        },
      })
      .then((response) => response.data);
  }

  removeFile(id) {
    return axios
      .delete(`/files/${id}`, { headers: authHeader() })
      .then((res) => {
        if (res?.status === 404) {
          return res;
        }

        return res?.data;
      });
  }

  renameFile(id, name) {
    return axios
      .put(
        `/files/${id}`,
        { name },
        {
          headers: {
            ...authHeader(),
          },
        }
      )
      .then((response) => response.data);
  }

  getFile(id) {
    return axios
      .get(`/files/${id}`, { headers: authHeader() })
      .then((res) => res.data);
  }

  removeFileById(id) {
    return axios
      .delete(`/files/${id}`, { headers: authHeader() })
      .then((res) => res.data);
  }
}

export default new FilesService();
