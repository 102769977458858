/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import { Card, CardBody, CardFooter, CardHeader } from 'reactstrap';

import {
  setDateFormat,
  getTomorrowDate,
  FormattedValue,
} from '../../../../utils/Utils';
import GenericTable from '../../../../components/GenericTable';
import { ReportSkeletonLoader } from '../../ReportSkeletonLoader';
import DatePickerTraining from '../../../../components/dealsBreakdown/DatePickerTraining';
import ReportSearchOptionDropdown from '../../../../components/commons/ReportSearchOptionDropdown';
import OpportunitiesStats from './OpportunitiesStats';
import { monthlySalesPerformanceColumns } from '../../../../utils/opportunities.constants';
import pipelineService from '../../../../services/pipeline.services';
import userService from '../../../../services/user.service';
import Pagination from '../../../../components/Pagination';
import { paginationDefault } from '../../../../utils/constants';
import {
  changePaginationLimit,
  changePaginationPage,
} from '../../../Deals/contacts/utils';
import IdfTooltip from '../../../../components/idfComponents/idfTooltip';
import { StatItemColors } from '../../../../components/reports/reports.constants';
import TableFooterStats from '../../../../components/TableFooterStats';

const StatsData = [
  {
    id: 'TotalSales',
    icon: 'DollarSign',
    color: StatItemColors.financial,
    iconColor: 'text-rpt-green',
    label: 'Total Sales',
    value: '44581700',
    count: 44581700,
    rounded: true,
    type: 'currency',
  },
  {
    id: 'NoOfDeals',
    icon: 'Layers',
    color: StatItemColors.volume,
    iconColor: 'text-rpt-blue',
    label: 'No of Deals',
    value: '746',
    count: 746,
    type: 'number',
  },
  {
    id: 'WonDeals',
    icon: 'Award',
    color: StatItemColors.achievement,
    iconColor: 'rpt-orange-box-heading',
    label: 'Won Deals',
    value: '293',
    count: 293,
    type: 'number',
  },
  {
    id: 'PercentageWon',
    icon: 'Percent',
    color: StatItemColors.performance,
    iconColor: 'text-rpt-green',
    label: '% Won',
    value: '39.27',
    count: 39.27,
    type: 'percentage',
  },
];

const TableData = [
  {
    id: 1,
    date: '2025-02-01T00:00:00.000Z',
    sales: 107100,
    no_of_deal: 4,
    won_deals: 3,
    won_deals_percentage: 75,
  },
  {
    id: 2,
    date: '2025-01-01T00:00:00.000Z',
    sales: 174000,
    no_of_deal: 7,
    won_deals: 4,
    won_deals_percentage: 57.14,
  },
  {
    id: 3,
    date: '2024-12-01T00:00:00.000Z',
    sales: 243000,
    no_of_deal: 5,
    won_deals: 2,
    won_deals_percentage: 40,
  },
  {
    id: 4,
    date: '2024-11-01T00:00:00.000Z',
    sales: 201500,
    no_of_deal: 3,
    won_deals: 1,
    won_deals_percentage: 33.33,
  },
  {
    id: 5,
    date: '2024-10-01T00:00:00.000Z',
    sales: 6299800,
    no_of_deal: 87,
    won_deals: 42,
    won_deals_percentage: 48.28,
  },
  {
    id: 6,
    date: '2024-09-01T00:00:00.000Z',
    sales: 7573100,
    no_of_deal: 126,
    won_deals: 68,
    won_deals_percentage: 53.97,
  },
  {
    id: 7,
    date: '2024-08-01T00:00:00.000Z',
    sales: 5652100,
    no_of_deal: 94,
    won_deals: 37,
    won_deals_percentage: 39.36,
  },
  {
    id: 8,
    date: '2024-07-01T00:00:00.000Z',
    sales: 3672300,
    no_of_deal: 69,
    won_deals: 31,
    won_deals_percentage: 44.93,
  },
  {
    id: 9,
    date: '2024-06-01T00:00:00.000Z',
    sales: 2414300,
    no_of_deal: 56,
    won_deals: 22,
    won_deals_percentage: 39.29,
  },
  {
    id: 10,
    date: '2024-05-01T00:00:00.000Z',
    sales: 4232900,
    no_of_deal: 67,
    won_deals: 29,
    won_deals_percentage: 43.28,
  },
  {
    id: 11,
    date: '2024-04-01T00:00:00.000Z',
    sales: 4006500,
    no_of_deal: 59,
    won_deals: 24,
    won_deals_percentage: 40.68,
  },
  {
    id: 12,
    date: '2024-03-01T00:00:00.000Z',
    sales: 4366800,
    no_of_deal: 74,
    won_deals: 30,
    won_deals_percentage: 40.54,
  },
];

const MonthlySalesPerformanceSummary = () => {
  const [data, setData] = useState(TableData);
  const [loading, setLoading] = useState(false);
  const [order, setOrder] = useState();
  const [pagination, setPagination] = useState(paginationDefault);
  const [paginationPage, setPaginationPage] = useState(paginationDefault);

  const [show, setShow] = useState(false);
  const [pipelines, setPipelines] = useState([]);
  const [pipelineIds, setPipelinesIds] = useState([]);
  const [selectedPipelinesKeys, setSelectedPipelinesKeys] = useState([]);

  const [showUsers, setShowUsers] = useState(false);
  const [usersList, setUsersList] = useState([]);
  const [usersIds, setUserssIds] = useState([]);
  const [selectedUsersKeys, setSelectedUsersKeys] = useState([]);

  const [dateRange, setDateRange] = useState({
    startDate: new Date(1970, 0, 1),
    endDate: getTomorrowDate(),
  });

  const mapMonthlySalesPerformance = [
    ...data?.map((item) => ({
      ...item,
      dataRow: [
        {
          key: 'date',
          component: <span>{setDateFormat(item?.date, 'MMM YYYY')}</span>,
        },
        {
          key: 'sales',
          component: <FormattedValue value={item?.sales} type="currency" />,
        },
        {
          key: 'no_of_deal',
          component: <FormattedValue value={item?.no_of_deal} />,
        },
        {
          key: 'won_deals',
          component: <FormattedValue value={item?.won_deals} />,
        },
        {
          key: 'won_deals_percentage',
          component: (
            <FormattedValue
              value={item?.won_deals_percentage}
              type="percentage"
            />
          ),
        },
      ],
    })),
  ];

  const handleSort = (key) => {
    // setOrder([[key?.name?.orderBy, key?.name?.clicked.toLowerCase()]]);
  };

  const getPipelines = async () => {
    const response = await pipelineService.getPipelines(1, 100);
    setPipelines(response?.data);
  };

  const getUsers = async (limit = 100) => {
    const response = await userService.getUsers(
      { status: 'active' },
      { page: 1, limit }
    );

    setUsersList(response?.data?.users);
  };

  useEffect(() => {
    getPipelines();
    getUsers();
  }, []);

  return (
    <Card className="h-100 border-0 shadow-0">
      <CardHeader>
        <div className="d-flex justify-content-between align-items-center w-100">
          <IdfTooltip
            text="Monthly Sales Performance Summary"
            placement="bottom"
          >
            <h4 className="card-title text-hover-primary mb-0 text-truncate">
              Monthly Sales Performance Summary
            </h4>
          </IdfTooltip>
          <div className="d-flex align-items-center mr-6 pr-9 gap-2">
            <DatePickerTraining
              range={dateRange}
              setRange={setDateRange}
              disablePastDate
              btnToggleStyle="mx-0 w-250"
            />
            <ReportSearchOptionDropdown
              search
              maxLength={22}
              show={show}
              data={pipelines}
              setShow={setShow}
              showCheckAll={false}
              label={`Pipelines`}
              setQueryFilters={() => {}}
              scrollable="pr-3 text-left"
              handleOptionSelected={() => {}}
              customStyle={{ width: '250px' }}
              customKey={['name', 'id']}
              selectedData={selectedPipelinesKeys}
              handleAllSelect={() => setPipelinesIds('')}
              setSelectedData={setSelectedPipelinesKeys}
              handleOnDone={(items) => setPipelinesIds(items)}
              controlledTitle={
                pipelines
                  ?.filter((i) => pipelineIds?.includes(i.id))
                  .map((i) => i.name)
                  .join(',') || ''
              }
            />
            <ReportSearchOptionDropdown
              search
              maxLength={22}
              show={showUsers}
              data={usersList}
              setShow={setShowUsers}
              showCheckAll={false}
              label={`Users`}
              setQueryFilters={() => {}}
              scrollable="pr-3 text-left"
              handleOptionSelected={() => {}}
              customStyle={{ width: '220px' }}
              customKey={['name', 'id']}
              selectedData={selectedUsersKeys}
              handleAllSelect={() => setUserssIds('')}
              setSelectedData={setSelectedUsersKeys}
              handleOnDone={(items) => setUserssIds(items)}
              controlledTitle={
                usersList
                  ?.filter((i) => usersIds?.includes(i.id))
                  .map((i) => i.name)
                  .join(',') || ''
              }
            />
          </div>
        </div>
      </CardHeader>
      <CardBody className="p-0 flex-1 overflow-y-auto">
        <div className="px-2 pt-3">
          <OpportunitiesStats stats={StatsData} isLoading={loading} />
        </div>
        {loading ? (
          <div className="w-100 d-block p-1">
            <ReportSkeletonLoader rows={15} height={15} />
          </div>
        ) : (
          <GenericTable
            checkbox={false}
            exportToCSV={true}
            usePagination={false}
            sortingTable={handleSort}
            tableData={data}
            headClass="bg-gray-table-head border-top"
            fileName="Monthly Sales Performance Summary"
            noDataInDbValidation={true}
            columns={monthlySalesPerformanceColumns}
            columnsToExport={monthlySalesPerformanceColumns}
            data={mapMonthlySalesPerformance}
          />
        )}
      </CardBody>
      <CardFooter className="border-top bg-gray-table-head p-2 border-bottom-0 border-left-0 border-right-0 rounded-0">
        <div className="d-flex align-items-center justify-content-between">
          <ul className="px-3 mb-0 flex-fill">
            <TableFooterStats stats={StatsData} />
          </ul>
          <Pagination
            showPerPage
            paginationInfo={pagination}
            onPageChange={(newPage) => {
              changePaginationPage(newPage, setPaginationPage);
            }}
            onLimitChange={(perPage) => {
              changePaginationLimit(perPage, setPaginationPage);
            }}
          />
        </div>
      </CardFooter>
    </Card>
  );
};

export default MonthlySalesPerformanceSummary;
