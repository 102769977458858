import authHeader from './auth-header';
import BaseRequestService from './baseRequest.service';

const defaultModel = 'claude-3-5-sonnet-latest';
class AnthropicService extends BaseRequestService {
  async createMessage(data, model = defaultModel, cancelToken) {
    const response = await this.post(
      `/providers/anthropic/messages`,
      {
        model,
        messages: [{ content: data.prompt }],
        max_tokens: data.max_tokens_to_sample,
      },
      {
        headers: authHeader(),
        cancelToken,
      },
      { fullResponse: true }
    );
    return response?.data;
  }
}
export default new AnthropicService();
