import { useEffect, useState } from 'react';
import ChatService from '../../../services/chat.service';

const useFetchChatQuestions = (id, limit = 100) => {
  const [questions, setQuestions] = useState([]);
  const [pagination, setPagination] = useState({});
  const [loading, setLoading] = useState(false);

  const fetchChatQuestions = async (newId) => {
    try {
      setLoading(true);
      const { data, pagination: chatPagination } =
        await ChatService.getChatQuestions(newId || id, 1, limit);
      // should come from backend??
      data.sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt));
      setQuestions(data);
      setPagination(chatPagination);
    } catch (e) {
      console.log(e);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    id && fetchChatQuestions();
  }, [id]);

  return {
    questions,
    setQuestions,
    loading,
    setLoading,
    pagination,
    setPagination,
    fetchChatQuestions,
  };
};

export default useFetchChatQuestions;
