import authHeader from './auth-header';
import BaseRequestService from './baseRequest.service';

class OwnerServices extends BaseRequestService {
  async getOwners({ type, id, page, limit }) {
    return await this.get(`/${type}/${id}/owners`, {
      headers: authHeader(),
      params: {
        page,
        limit,
      },
    });
  }

  async addOwner({ type, id, user_id }) {
    return await this.post(
      `/${type}/${id}/owners/${user_id}`,
      {},
      {
        headers: authHeader(),
      }
    );
  }
}

export default new OwnerServices();
