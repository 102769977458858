import React, { Fragment } from 'react';
import LucideIcon from './LucideIcon';
import NewKindOfSearchDropdown from './NewKindOfSearchDropdown';
import InputValidation from './InputValidation';

const propertyItems = [
  { id: 1, name: 'Touched Records' },
  { id: 2, name: 'Untouched Records' },
  { id: 3, name: 'Record Action' },
  { id: 4, name: 'Related Record Action' },
  { id: 5, name: 'Latest Email Status' },
  { id: 6, name: 'Activities' },
  { id: 7, name: 'Notes' },
  { id: 8, name: 'Pipelines' },
  { id: 9, name: 'Pipeline Amount' },
  { id: 10, name: 'Pipeline Stage' },
  { id: 11, name: 'Pipeline Closing Date' },
  { id: 12, name: 'Pipeline Record Owner' },
  { id: 13, name: 'Created By' },
  { id: 14, name: 'Created Time' },
  { id: 15, name: 'Date' },
  { id: 16, name: 'Description' },
  { id: 17, name: 'Email' },
  { id: 18, name: 'Email Opt Out' },
  { id: 19, name: 'First Name' },
  { id: 20, name: 'Great Phone' },
  { id: 21, name: 'Home Phone' },
  { id: 22, name: 'Last Activity Time' },
  { id: 23, name: 'Last Name' },
  { id: 24, name: 'Lead Source' },
  { id: 25, name: 'Mailing City' },
  { id: 26, name: 'Mailing Country' },
  { id: 27, name: 'Mailing State' },
  { id: 28, name: 'Mailing Street' },
  { id: 29, name: 'Mailing Zip' },
  { id: 30, name: 'Mobile' },
  { id: 31, name: 'Modified By' },
  { id: 32, name: 'Modified Time' },
  { id: 33, name: 'Other Phone' },
  { id: 34, name: 'People Name' },
];

const dropdownOptions = [
  { id: 'is', name: 'Is' },
  { id: 'isnt', name: "Isn't" },
  { id: 'contains', name: 'Contains' },
  { id: 'doesnt_contains', name: "Doesn't Contains" },
  { id: 'starts_with', name: 'Starts With' },
  { id: 'ends_with', name: 'Ends With' },
  { id: 'is_empty', name: 'Is Empty' },
];

const pipelinesOptions = [
  { id: 1, name: 'Is All Pipelines' },
  { id: 2, name: 'Is' },
  { id: 3, name: "Isn't" },
];

const timeframeOptions = [
  { id: 'in_the_last', name: 'In the Last' },
  { id: 'today', name: 'Today' },
  { id: 'this_week', name: 'This Week' },
  { id: 'this_month', name: 'This Month' },
  { id: 'this_year', name: 'This Year' },
  { id: 'last_30_days', name: 'In the Last 30 Days' },
  { id: 'last_60_days', name: 'In the Last 60 Days' },
];

const timeUnitOptions = [
  { id: 'days', name: 'Days' },
  { id: 'weeks', name: 'Weeks' },
  { id: 'months', name: 'Months' },
];

const userSystemOptions = [
  { id: 'user_and_system', name: 'User & System' },
  { id: 'user_or_system', name: 'User Or System' },
  { id: 'user', name: 'User' },
  { id: 'system', name: 'System' },
  { id: 'user_only', name: 'User Only' },
  { id: 'system_only', name: 'System Only' },
];

const AdvancedFilters = ({
  selectedFilters,
  setSelectedFilters,
  closeFilters,
  label = 'Advanced filters',
  showAdvancedFilters,
}) => {
  const clearFilter = (id) => {
    setSelectedFilters((prevFilters) =>
      prevFilters?.filter((filter) => filter.id !== id)
    );
  };

  const selectFilter = (item) => {
    setSelectedFilters((prev) => {
      if (!prev.some((selectedItem) => selectedItem.id === item.id)) {
        return [
          ...prev,
          {
            ...item,
            searchTerm: '',
            selectedCondition: dropdownOptions[0],
            selectedPipelineOption: pipelinesOptions[0],
            selectedUserSystemOption: userSystemOptions[0],
            selectedTimeUnitOption: timeUnitOptions[0],
            selectedTimeframeOptions: timeframeOptions[0],
          },
        ];
      }
      return prev;
    });
  };

  const selectChildFilter = (item, filterKey, selected) => {
    setSelectedFilters((prev) =>
      prev.map((filter) =>
        filter.id === item.id ? { ...filter, [filterKey]: selected } : filter
      )
    );
  };

  const handleSearchTermChange = (item, value) => {
    setSelectedFilters((prev) =>
      prev.map((filter) =>
        filter.id === item.id ? { ...filter, searchTerm: value } : filter
      )
    );
  };

  return (
    <div
      style={{
        left: showAdvancedFilters ? 0 : -400,
      }}
      className={`d-flex w-100 transition-all z-index-250 flex-column gap-3 position-absolute transparent-scroll-track bg-white h-100`}
    >
      <div className="d-flex flex-column h-100">
        <div
          onClick={closeFilters}
          className="px-3 pt-3 d-flex gap-1 align-items-center cursor-pointer"
        >
          <LucideIcon
            icon="ChevronLeft"
            clazz="icon-hover-bg"
            style={{ width: '20px', height: '20px' }}
          />
          <h5 className="mb-0">{label}</h5>
        </div>
        <div className="flex-grow-1 transparent-scroll-track">
          <div className="d-flex flex-column">
            <div className="p-3 border-bottom">
              <NewKindOfSearchDropdown
                preData={propertyItems}
                placeholder="Choose a property"
                optionsClass=""
                showAvatar={false}
                showEmail={false}
                readOnly={true}
                onInputSearch={null}
                showAvatarButton={false}
                currentSelected={selectedFilters}
                multiple={true}
                onItemSelect={selectFilter}
              />
            </div>

            {selectedFilters?.map((item, index) => (
              <div
                key={index}
                className="p-3 border-bottom position-relative advanced-filter-item bg-primary-soft-hover"
              >
                <div
                  className="position-absolute"
                  style={{ top: '13px', right: '16px' }}
                >
                  <a
                    className="js-hs-unfold-invoker btn bg-gray-dark-5 icon-hover-bg btn-icon clear-btn"
                    style={{ width: '22px', height: '22px' }}
                    onClick={(e) => clearFilter(item.id)}
                  >
                    <i className="material-symbols-rounded fs-6">clear</i>
                  </a>
                </div>

                <p className="mb-1 font-weight-semi-bold fs-7">{item.name}</p>

                <div className="d-flex flex-wrap gap-2 mt-2">
                  <Fragment>
                    {item.id === 1 ||
                    item.id === 2 ||
                    item.id === 3 ||
                    item.id === 4 ? (
                      <>
                        <div className="d-flex align-items-center gap-1">
                          <span className="fs-7">By</span>
                          <NewKindOfSearchDropdown
                            preData={userSystemOptions}
                            placeholder="Choose option"
                            pillButton="py-1 px-2"
                            menuClass="min-w-170"
                            optionsClass=""
                            showAvatar={false}
                            showEmail={false}
                            readOnly={true}
                            onInputSearch={null}
                            showAvatarButton={false}
                            currentSelected={item.selectedUserSystemOption}
                            multiple={true}
                            onItemSelect={(selected) =>
                              selectChildFilter(
                                item,
                                'selectedUserSystemOption',
                                selected
                              )
                            }
                          />
                        </div>

                        <NewKindOfSearchDropdown
                          preData={timeUnitOptions}
                          placeholder="Choose option"
                          pillButton="py-1 px-2"
                          menuClass="min-w-100"
                          optionsClass=""
                          showAvatar={false}
                          showEmail={false}
                          readOnly={true}
                          disabled={false}
                          onInputSearch={null}
                          showSearchField={false}
                          showAvatarButton={false}
                          currentSelected={item.selectedTimeUnitOption}
                          multiple={false}
                          onItemSelect={(selected) =>
                            selectChildFilter(
                              item,
                              'selectedTimeUnitOption',
                              selected
                            )
                          }
                        />
                      </>
                    ) : (
                      ''
                    )}
                  </Fragment>

                  <NewKindOfSearchDropdown
                    preData={dropdownOptions}
                    placeholder="Choose option"
                    pillButton="py-1 px-2"
                    menuClass="min-w-170"
                    optionsClass=""
                    showAvatar={false}
                    showEmail={false}
                    readOnly={true}
                    onInputSearch={null}
                    showAvatarButton={false}
                    currentSelected={item.selectedCondition}
                    multiple={true}
                    onItemSelect={(selected) =>
                      selectChildFilter(item, 'selectedCondition', selected)
                    }
                  />

                  <InputValidation
                    name="search"
                    type="input"
                    wrapperClass=""
                    classNames="px-2 py-1"
                    style={{ height: '33px' }}
                    placeholder="Search here"
                    validationConfig={{
                      required: false,
                      inline: false,
                    }}
                    errors={{}}
                    register={() => {}}
                    value={item.searchTerm || ''}
                    onChange={(e) =>
                      handleSearchTermChange(item, e.target.value)
                    }
                  />

                  <Fragment>
                    {item.id === 8 ||
                    item.id === 9 ||
                    item.id === 10 ||
                    item.id === 11 ||
                    item.id === 12 ? (
                      <div className="d-flex align-items-center gap-1">
                        <span className="fs-7">And Pipeline</span>
                        <NewKindOfSearchDropdown
                          preData={pipelinesOptions}
                          placeholder="Choose option"
                          pillButton="py-1 px-2"
                          menuClass="min-w-170"
                          optionsClass=""
                          showAvatar={false}
                          showEmail={false}
                          readOnly={true}
                          onInputSearch={null}
                          showAvatarButton={false}
                          currentSelected={item.selectedPipelineOption}
                          multiple={true}
                          onItemSelect={(selected) =>
                            selectChildFilter(
                              item,
                              'selectedPipelineOption',
                              selected
                            )
                          }
                        />
                      </div>
                    ) : (
                      ''
                    )}
                  </Fragment>

                  <Fragment>
                    {item.id === 17 ||
                    item.id === 18 ||
                    item.id === 19 ||
                    item.id === 20 ||
                    item.id === 21 ||
                    item.id === 22 ||
                    item.id === 23 ||
                    item.id === 24 ? (
                      <NewKindOfSearchDropdown
                        preData={timeframeOptions}
                        placeholder="Choose option"
                        pillButton="py-1 px-2"
                        menuClass="min-w-170"
                        optionsClass=""
                        showAvatar={false}
                        showEmail={false}
                        readOnly={true}
                        onInputSearch={null}
                        showAvatarButton={false}
                        currentSelected={item.selectedTimeframeOptions}
                        multiple={true}
                        onItemSelect={(selected) =>
                          selectChildFilter(
                            item,
                            'selectedTimeframeOptions',
                            selected
                          )
                        }
                      />
                    ) : (
                      ''
                    )}
                  </Fragment>
                </div>
              </div>
            ))}
          </div>
        </div>
        <div className="p-3 d-flex gap-2 border-top justify-content-end align-items-center">
          <button
            className="btn btn-white btn-sm"
            data-dismiss="modal"
            onClick={closeFilters}
          >
            Close
          </button>

          <button
            type="button"
            className="btn btn-sm btn-primary d-flex justify-content-center min-w-100"
            onClick={closeFilters}
          >
            <span>Apply Filters</span>
          </button>
        </div>
      </div>
    </div>
  );
};

export default AdvancedFilters;
