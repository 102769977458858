import authHeader from './auth-header';
import BaseRequestService from './baseRequest.service';

class ReportService extends BaseRequestService {
  updateReport(reportId, data, cancelToken) {
    return this.put(`/reports/${reportId}`, data, {
      headers: authHeader(),
      cancelToken,
    });
  }

  createReport(data, cancelToken) {
    return this.post(`/reports`, data, {
      headers: authHeader(),
      cancelToken,
    });
  }

  getReport(reportId) {
    return this.get(`/reports/${reportId}`, {
      headers: authHeader(),
    });
  }

  getReports(filter) {
    return this.get(`/reports`, {
      headers: authHeader(),
      params: filter,
    });
  }

  deleteReport(reportId) {
    return this.delete(`/reports/${reportId}`, {
      headers: authHeader(),
    });
  }

  getActiveFileExtractions(reportId) {
    return this.get(`/reports/${reportId}/extractions/active`, {
      headers: authHeader(),
    });
  }

  getReportsAggregateData(requestPayload, cancelToken) {
    return this.post(`/reports/aggregate/data`, requestPayload, {
      headers: authHeader(),
      cancelToken,
    });
  }
}

export default new ReportService();
