import React from 'react';
import LucideIcon from './LucideIcon';
import { filledIconsList, IconSizes, IconsMaterialToLucide } from './IconUtils';

// generic component to show material/google icons wherever we want to use
const MaterialIcon = ({
  icon,
  clazz,
  filled,
  symbols,
  rounded = true,
  twoTone,
  size = 'fs-5',
  isLucide = true,
  strokeWidth,
  customStroke,
  ...rest
}) => {
  if (isLucide && !IconsMaterialToLucide[icon]) {
    console.info(`=-= Icon "${icon}" does not exist in mapping.`);
    return null;
  }

  if (isLucide && !IconSizes[size]) {
    console.info(
      `=-= Size ${size} for Icon "${icon}" does not exist in mapping`
    );
    return null;
  }

  const filledStyle =
    filled && isLucide
      ? {
          ...rest?.style,
          fill: filledIconsList?.includes(icon)
            ? 'currentColor'
            : 'hsl(var(--primaryColorHsl), 100%, 85%)',
        }
      : { ...rest?.style };

  return (
    <>
      {isLucide ? (
        <LucideIcon
          icon={IconsMaterialToLucide[icon]}
          size={IconSizes[size]}
          clazz={clazz}
          strokeWidth={strokeWidth}
          {...(customStroke ? { stroke: customStroke } : {})}
          {...filledStyle}
        />
      ) : (
        <>
          {rounded ? (
            <i
              {...rest}
              className={`material-symbols-rounded ${size} ${
                filled === true ? 'filled' : ''
              } ${clazz}`}
            >
              {icon}
            </i>
          ) : (
            <i
              {...rest}
              className={`${
                filled
                  ? `material-symbols-outlined ${size}`
                  : symbols
                  ? `material-symbols-outlined ${size}`
                  : twoTone
                  ? 'material-icons-two-tone'
                  : `material-symbols-rounded ${size}`
              } ${clazz}`}
            >
              {icon}
            </i>
          )}
        </>
      )}
    </>
  );
};

export default MaterialIcon;
