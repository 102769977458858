import React from 'react';
import IdfTooltip from '../idfComponents/idfTooltip';
import LucideIcon from '../commons/LucideIcon';

const DisclaimerText = 'Ida is AI-powered. Review responses and sources.';
const Disclaimer = ({
  icon,
  style = 'fs-7 position-absolute bottom-0 abs-center',
}) => {
  return (
    <div className={`text-gray ${style}`}>
      {icon ? (
        <IdfTooltip text={DisclaimerText} placement="left">
          <div className="d-flex align-items-center gap-1">
            <LucideIcon icon="Info" size={12} />
            Disclaimer
          </div>
        </IdfTooltip>
      ) : (
        DisclaimerText
      )}
    </div>
  );
};

export default Disclaimer;
