import React from 'react';
import { Bar } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  BarElement,
  CategoryScale,
  LinearScale,
  Tooltip,
  Legend,
} from 'chart.js';

ChartJS.register(BarElement, CategoryScale, LinearScale, Tooltip, Legend);

const OverviewBarChart = () => {
  const salesColor = '#397DF4';
  const unitsColor = '#15B77D';

  const salesData = [600000, 450000, 300000, 200000, 150000];
  const unitsData = [5000, 3500, 4000, 2500, 1800];

  const data = {
    labels: ['Product A', 'Product B', 'Product C', 'Product D', 'Product E'],
    datasets: [
      {
        label: 'Sales ($)',
        data: salesData,
        backgroundColor: salesColor,
        yAxisID: 'y',
      },
      {
        label: 'Units Sold',
        data: unitsData,
        backgroundColor: unitsColor,
        yAxisID: 'y1',
      },
    ],
  };

  const options = {
    responsive: true,
    scales: {
      y: {
        beginAtZero: true,
        ticks: {
          color: salesColor,
        },
        grid: {
          drawTicks: false,
          drawBorder: false,
        },
      },
      y1: {
        beginAtZero: true,
        position: 'right',
        ticks: {
          color: unitsColor,
          stepSize: 1500,
        },
        grid: {
          drawOnChartArea: false,
        },
      },
      x: {
        grid: {
          drawBorder: false,
        },
      },
    },
    plugins: {
      legend: {
        position: 'bottom',
      },
      datalabels: {
        display: false,
      },
      tooltip: {
        enabled: true,
        callbacks: {
          label: function (tooltipItem) {
            return `${tooltipItem.dataset.label}: ${tooltipItem.raw}`;
          },
        },
      },
    },
  };

  return (
    <div className="w-100 d-flex justify-content-center align-items-center">
      <Bar data={data} options={options} />
    </div>
  );
};

export default OverviewBarChart;
