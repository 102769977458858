import { useEffect, useState, useCallback } from 'react';
import ArchiveService from '../../../services/archive.service';
import { useDebounce } from '../../../hooks/useDebounce';

const useFetchArchiveFiles = (archiveId, limit = 10) => {
  const [files, setFiles] = useState([]);
  const [searchValue, setSearchValue] = useState('');
  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState('');
  const [pagination, setPagination] = useState({
    page: 1,
    totalPages: 1,
    count: 0,
  });
  const [loading, setLoading] = useState(false);
  const [hasMore, setHasMore] = useState(true);

  const debouncedSearch = useDebounce(searchValue, 500);

  const fetchArchiveFiles = useCallback(
    async (page = 1) => {
      if (!archiveId) return;

      try {
        setLoading(true);
        const { data, pagination: archPagination } =
          await ArchiveService.getArchiveFiles(
            archiveId,
            page,
            limit,
            debouncedSearchTerm
          );
        setFiles(data);
        setPagination({
          page,
          totalPages: archPagination.totalPages,
          count: archPagination.count,
        });
        setHasMore(page < archPagination.totalPages);
      } catch (e) {
        console.error('Error fetching archive files:', e);
      } finally {
        setLoading(false);
      }
    },
    [archiveId, limit, debouncedSearchTerm]
  );

  const fetchNextArchiveFiles = () => {
    if (!hasMore) return;
    if (pagination.page < pagination.totalPages) {
      fetchArchiveFiles(pagination.page + 1);
    }
  };

  const goToPage = (page) => {
    fetchArchiveFiles(page);
  };

  useEffect(() => {
    if (archiveId) {
      fetchArchiveFiles(1);
    }
  }, [fetchArchiveFiles]);

  useEffect(() => {
    // only search if term is 3 or more characters or empty
    if (debouncedSearch.length >= 3 || debouncedSearch === '') {
      setDebouncedSearchTerm(debouncedSearch);
    }
  }, [debouncedSearch, fetchArchiveFiles]);

  return {
    files,
    setFiles,
    loading,
    pagination,
    setPagination,
    fetchArchiveFiles,
    fetchNextArchiveFiles,
    hasMore,
    goToPage,
    searchValue,
    setSearchValue,
    debouncedSearchTerm,
  };
};

export default useFetchArchiveFiles;
