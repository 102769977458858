import axios from './axiosInstance';
import authHeader from './auth-header';
import BaseRequestService from './baseRequest.service';

class FieldService extends BaseRequestService {
  getOptions() {
    return axios
      .get(`/fields/options`, {
        headers: authHeader(),
      })
      .then((response) => response)
      .catch((err) => {
        console.log(err);
      });
  }

  getFields(type, opts = {}, page = 1, limit = 100) {
    let { hidden = false, ...rest } = opts;
    if (hidden === 'any') {
      hidden = undefined;
    }

    return this.get(`/fields`, {
      params: {
        type,
        page,
        limit,
        order: ['order', 'asc'],
        hidden,
        ...rest,
      },
      headers: authHeader(),
    });
  }

  createField(data) {
    return this.post(`/fields`, data, {
      headers: authHeader(),
    });
  }

  updateField(fieldId, data) {
    return this.put(`/fields/${fieldId}`, data, {
      headers: authHeader(),
    });
  }

  createDefaultFields(type) {
    return this.post(
      `/fields/default`,
      { type },
      {
        headers: authHeader(),
      }
    );
  }

  deleteField(fieldId) {
    return this.delete(`/fields/${fieldId}`, {
      headers: authHeader(),
    });
  }

  createQuickPrefFields(type, fields) {
    return this.post(
      `/fields/preference`,
      { type, fields },
      {
        headers: authHeader(),
      }
    );
  }

  getSubOrganizationFields(subOrgId, page = 1, limit = 10) {
    return this.get(`/subOrganizations/${subOrgId}/fields`, {
      headers: authHeader(),
      params: {
        page,
        limit,
      },
    });
  }
}

export default new FieldService();
