import React, { useState, useEffect } from 'react';
import MaterialIcon from '../../../commons/MaterialIcon';
import TooltipComponent from '../../../lesson/Tooltip';
import { contactMethodsList } from '../constants';

const ContactMethodType = ({
  data = [],
  setData,
  keyType,
  keyFilter,
  typeKeyType,
  typeKeyFilter,
  onEnter,
}) => {
  const [refresh, setRefresh] = useState(0);
  const [selected, setSelected] = useState('OR');
  const [selectedMethods, setSelectedMethods] = useState([]);

  const updateContactType = (type) => {
    if (selected !== type) {
      const payload = {
        ...data,
        [typeKeyType]: {
          ...data[typeKeyType],
          [typeKeyFilter]: type,
        },
      };
      setSelected(type);
      setData({ type: 'set', payload });
      setRefresh((prevState) => prevState + 1);
    }
  };

  useEffect(() => {
    onEnter();
  }, [refresh]);

  const handleCheckboxChange = (method) => {
    setSelectedMethods((prevSelected) => {
      const updatedSelected = prevSelected.includes(method)
        ? prevSelected.filter((item) => item !== method)
        : [...prevSelected, method];

      // Call updateContactFilter with the updated list of selected methods
      updateContactFilter(updatedSelected);

      return updatedSelected;
    });
  };

  const updateContactFilter = (filters) => {
    const payload = {
      ...data,
      [keyType]: {
        [keyFilter]: filters,
      },
    };
    setData({ type: 'set', payload });
    setRefresh((prevState) => prevState + 1);
  };

  useEffect(() => {
    if (data[keyType] && data[keyType][keyFilter]) {
      setSelectedMethods(data[keyType][keyFilter]);
    }
  }, [data, keyType, keyFilter]);

  return (
    <>
      <div className="d-flex align-items-center gap-2 mb-3">
        <div className="w-100 prospecting-pills d-flex align-items-center rounded-pill border">
          <a
            style={{ width: 100 }}
            onClick={(e) => {
              e.preventDefault();
              updateContactType('OR');
            }}
            className={`${
              selected === 'OR' && 'pill-active text-white fw-bold'
            } px-2 text-center fs-7 d-flex align-items-center justify-content-center cursor-pointer rounded-pill`}
          >
            Or
          </a>
          <a
            style={{ width: 100 }}
            onClick={(e) => {
              e.preventDefault();
              updateContactType('AND');
            }}
            className={`${
              selected === 'AND' && 'pill-active text-white fw-bold'
            } px-2 text-center fs-7 d-flex align-items-center justify-content-center cursor-pointer rounded-pill`}
          >
            And
          </a>
        </div>

        <TooltipComponent
          title={
            selected === 'OR'
              ? 'Any of the checked criteria'
              : 'All checked criteria'
          }
          placement="bottom-end"
        >
          <span className="d-flex">
            <MaterialIcon
              icon="help_outline"
              size="fs-5"
              rounded
              clazz="text-icon cursor-default"
            />
          </span>
        </TooltipComponent>
      </div>

      <div className="d-flex flex-column gap-1 pl-4">
        {contactMethodsList.map((item) => (
          <div
            className="d-flex align-items-center position-relative"
            key={item.key}
          >
            <input
              type="checkbox"
              id={item.key}
              checked={selectedMethods?.includes(item.value)}
              onChange={() => handleCheckboxChange(item.value)}
              className="form-check-input cursor-pointer my-0"
            />
            <label htmlFor={item.key} className="my-0">
              {item.value}
            </label>
            {item.tooltipText && (
              <TooltipComponent title={item.tooltipText} placement="right">
                <span className="d-flex pl-2">
                  <MaterialIcon
                    icon="help_outline"
                    size="fs-5"
                    rounded
                    clazz="text-icon cursor-default"
                  />
                </span>
              </TooltipComponent>
            )}
          </div>
        ))}
      </div>
    </>
  );
};

export default ContactMethodType;
