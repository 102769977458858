import { Helmet } from 'react-helmet-async';
import { useLocation } from 'react-router-dom';
import React, { useEffect, useState } from 'react';

import Header from '../components/header';
import Sidebar from './sidebar/Sidebar';
import Breadcrumb, { getTitleBreadcrumb } from '../utils/Breadcrumb';
import { useTenantContext } from '../contexts/TenantContext';
import FullScreenSpinner from './FullScreeenSpinner';
import useTenantTheme from '../hooks/useTenantTheme';
import { isDisplayWelcomeScreen } from '../utils/Utils';
import { useModuleContext } from '../contexts/moduleContext';
import Alert from './Alert/Alert';
import AlertWrapper from './Alert/AlertWrapper';
import axios from 'axios';
import Settings from '../views/settings/Settings';
import useLoginAlert from '../hooks/useLoginAlert';
import useAskIdaModal from './chat/modals/AskIda';

const routesForSettings = [
  '/settings/profile',
  '/settings/branding',
  '/settings/users',
  '/settings/notifications',
  '/settings/products',
  '/settings/learn',
  '/settings/stages',
  '/settings/fields',
  '/settings/checklist-fields',
  '/settings/integrations',
  '/settings/bulk-import',
  '/settings/users/:id',
  '/settings/users/roles/:id',
];

const isMatchingRoute = (route, pathname) => {
  const pattern = new RegExp(`^${route.replace(/:[^/]+/g, '([^/]+)')}$`);
  return pattern.test(pathname);
};

const Layout = ({ children, isSplitView }) => {
  const [mounted, setMounted] = useState(false);
  const location = useLocation();
  const [showChangeLogAlert] = useState(false);
  const { LoginAlert } = useLoginAlert(showChangeLogAlert);
  const isSettingsRoute = routesForSettings.some((route) =>
    isMatchingRoute(route, location.pathname)
  );
  const { tenant } = useTenantContext();
  const { moduleMap } = useModuleContext();
  const isWelcomeScreen =
    location?.pathname?.includes('welcome') ||
    isDisplayWelcomeScreen(tenant?.modules);
  const [errorMessage, setErrorMessage] = useState('');
  useTenantTheme();
  const { AskIdaModal } = useAskIdaModal();
  useEffect(() => {
    tenant?.id && !mounted && setMounted(true);
  }, [tenant]);

  // Axios interceptors to response
  axios.interceptors.response.use(
    (res) => res,
    (err) => {
      if (err?.response?.status === 403) {
        setErrorMessage(
          'You don&apos;t have permission to access or update this, please contact administrator.'
        );
      }
      throw err;
    }
  );
  if (mounted) {
    return (
      <>
        <AlertWrapper>
          <Alert
            color="danger"
            message={errorMessage}
            setMessage={setErrorMessage}
          />
        </AlertWrapper>
        <Helmet>
          <title>{getTitleBreadcrumb(location.pathname)}</title>
        </Helmet>

        <AskIdaModal />

        <div className="has-navbar-vertical-aside navbar-vertical-aside-show-xl footer-offset">
          {!isWelcomeScreen ? (
            <>
              {showChangeLogAlert && <LoginAlert />}
              <Header />
              <Sidebar />
            </>
          ) : null}
          {isSplitView ? (
            <main
              id="content"
              role="main"
              className="main splitted-content-main"
            >
              {children}
            </main>
          ) : (
            <main className={`main ${isWelcomeScreen ? 'p-0' : ''}`}>
              {isSettingsRoute ? (
                <div className={`settings-layout p-0`}>
                  <Settings>{children}</Settings>
                </div>
              ) : (
                <div className={`content container-fluid p-0`}>
                  <div className="pl-0 breadcrumb-mobile">
                    {moduleMap.organization && (
                      <Breadcrumb moduleMap={moduleMap} />
                    )}
                  </div>
                  {children}
                </div>
              )}
            </main>
          )}
        </div>
      </>
    );
  } else {
    return <FullScreenSpinner />;
  }
};

export default Layout;
