import { useEffect, useState } from 'react';
import { animate, motion } from 'framer-motion';

const delimiter = ' '; // or " " to split by word

export const AnimatedText = ({ text, typingSpeed = 25 }) => {
  const [displayedText, setDisplayedText] = useState('');
  const [, setIsTypingComplete] = useState(false);

  useEffect(() => {
    let index = 0;
    const typingInterval = setInterval(() => {
      if (index < text?.length) {
        setDisplayedText((prev) => prev + text[index++]);
      } else {
        clearInterval(typingInterval);
        setIsTypingComplete(true);
      }
    }, typingSpeed);
    return () => clearInterval(typingInterval);
  }, [text, typingSpeed]);

  return (
    <>
      {displayedText.split('').map((char, i) => (
        <motion.span
          key={i}
          initial={{ opacity: 0, x: -5 }}
          animate={{ opacity: 1, x: 0 }}
          transition={{ duration: 0.08, delay: 0.005 }}
        >
          {char}
        </motion.span>
      ))}
    </>
  );
};

// took this from here: https://buildui.com/recipes/use-animated-text
export const useAnimatedText = (text) => {
  const [cursor, setCursor] = useState(0);
  const [startingCursor, setStartingCursor] = useState(0);
  const [prevText, setPrevText] = useState(text);

  if (prevText !== text) {
    setPrevText(text);
    setStartingCursor(text.startsWith(prevText) ? cursor : 0);
  }

  useEffect(() => {
    const controls = animate(startingCursor, text.split(delimiter).length, {
      duration: 0.8,
      ease: 'easeOut',
      onUpdate(latest) {
        setCursor(Math.floor(latest));
      },
    });

    return () => controls.stop();
  }, [startingCursor, text]);

  return text.split(delimiter).slice(0, cursor).join(delimiter);
};

export default { useAnimatedText, AnimatedText };
