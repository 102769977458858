import React, { useContext, useEffect, useState } from 'react';
import { format } from 'date-fns';
import { TenantContext } from '../../../contexts/TenantContext';
import { isModuleAllowed, isPermissionAllowed } from '../../../utils/Utils';
import ReviewMyAssignmentsBanner from '../../../components/Assignments/ReviewMyAssignmentsBanner';
import Greetings from '../../../components/chat/Greetings';
import IDASection from './IDASection';
import useIsTenant from '../../../hooks/useIsTenant';

const Time = () => {
  const [time, setTime] = useState(new Date());

  useEffect(() => {
    const intervalId = setInterval(() => {
      setTime(new Date());
    }, 1000); // update every second
    return () => clearInterval(intervalId);
  }, []);

  return (
    <>
      <div className="flex flex-column time-now">
        <p className="fs-7 text-truncate font-weight-medium mb-0">
          {format(time, 'hh:mm a')}
        </p>
      </div>
    </>
  );
};

const Header = () => {
  const { tenant } = useContext(TenantContext);
  const { isDemo } = useIsTenant();
  const isMyLearnAssignmentsAllowed =
    isModuleAllowed(tenant.modules, 'learns_assignments') &&
    isPermissionAllowed('lessons', 'view');

  return (
    <div className="page-header bg-white border-bottom position-relative organization-header pt-3 pb-2 px-4 mb-0">
      {isMyLearnAssignmentsAllowed && <ReviewMyAssignmentsBanner />}
      <div
        className="row mx-auto"
        style={{ maxWidth: 'var(--home-page-width)' }}
      >
        <div className="col-sm px-0 mb-2 mb-sm-0">
          <p className="fs-7 mb-0 d-flex align-items-center gap-2 font-weight-semi-bold pt-1">
            {format(new Date(), 'E, MMM d')}
            <span className="text-muted-light">•</span>
            <Time />
          </p>
          <div className="d-flex gap-1 py-2 align-items-center flex-wrap">
            <div className="d-flex flex-column">
              <Greetings margin="mb-2" />
              {!isDemo ? <IDASection /> : null}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Header;
