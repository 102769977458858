import React, { useState } from 'react';
import { isModuleAllowed } from '../../../utils/Utils';
import { useTenantContext } from '../../../contexts/TenantContext';
import MaterialIcon from '../../../components/commons/MaterialIcon';
import { PermissionsConstants } from '../../../utils/permissions.constants';
import OrganizationsModal from '../other/OrganizationsModal';
import { PdfFileNames } from '../../reports/reports.helper.functions';
import { ReportTileStyles, ReportTypes } from '../../reports/reports.constants';
import useIsTenant from '../../../hooks/useIsTenant';

const ReportTile = ({ tab, onSelect, itemWidth }) => {
  const reportStyle = ReportTileStyles[tab.key];
  return (
    <a
      key={tab.id}
      className={`report-tile text-center scroll-item cursor-pointer font-size-sm2`}
      onClick={() => onSelect(tab)}
      style={{ minWidth: itemWidth }}
    >
      <div
        className={`${
          reportStyle?.styleClass || tab.styleClass
        } report-tile-icon`}
      >
        <MaterialIcon icon={reportStyle?.icon || tab.icon} size="fs-4" />
      </div>

      <p className="mb-0 font-size-sm2 font-weight-semi-bold text-nowrap text-center text-gray-900 cursor-pointer pt-2">
        {tab.name?.split(' ')[0]}
        <br />
        <span>{tab.name?.split(' ').slice(1).join(' ')} </span>
      </p>
    </a>
  );
};

const ReportTileDemo = ({ tab, onSelect }) => {
  const reportStyle = ReportTileStyles[tab.key];

  return (
    <div
      className="text-center mb-2 cursor-pointer"
      onClick={() => onSelect(tab)}
    >
      <div
        style={{ borderRadius: 8, height: 60, width: 60 }}
        className={`d-flex scroll-item mx-auto align-items-center border border-width-2 justify-content-center mb-2 ${
          reportStyle?.styleClass || tab.styleClass
        } bg-white`}
      >
        <MaterialIcon
          icon={reportStyle?.icon || tab.icon}
          size="fs-4"
          className={reportStyle?.styleClass || tab.styleClass}
        />
      </div>

      <div className="text-center">
        <p
          className="mb-0 font-weight-medium fs-7"
          dangerouslySetInnerHTML={{
            __html: tab.name.split(' ').join('<br />'),
          }}
        />
      </div>
    </div>
  );
};

export default function EngagementReports() {
  const { tenant } = useTenantContext();
  const { isDemo } = useIsTenant();
  const [selectedReport, setSelectedReport] = useState({});
  const [showModal, setShowModal] = useState(false);

  const ReportTabsList = [
    {
      id: 1,
      name: PdfFileNames[ReportTypes.Treasury],
      key: ReportTypes.Treasury,
      permission: PermissionsConstants.ModulesReportNames.Treasury,
      isEnabled: isModuleAllowed(
        tenant?.modules,
        PermissionsConstants.ModulesReportNames.Treasury
      ),
    },
    {
      id: 2,
      name: PdfFileNames[ReportTypes.WorkingCapital],
      key: ReportTypes.WorkingCapital,
      permission: PermissionsConstants.ModulesReportNames.WorkingCapital,
      isEnabled: isModuleAllowed(
        tenant?.modules,
        PermissionsConstants.ModulesReportNames.WorkingCapital
      ),
    },
    {
      id: 3,
      name: PdfFileNames[ReportTypes.Fraud],
      permission: PermissionsConstants.ModulesReportNames.Fraud,
      isEnabled: isModuleAllowed(
        tenant?.modules,
        PermissionsConstants.ModulesReportNames.Fraud
      ),
      key: ReportTypes.Fraud,
    },
    {
      id: 4,
      name: PdfFileNames[ReportTypes.Merchant],
      key: ReportTypes.Merchant,
      permission: PermissionsConstants.ModulesReportNames.Merchant,
      isEnabled: isModuleAllowed(
        tenant?.modules,
        PermissionsConstants.ModulesReportNames.Merchant
      ),
    },
    {
      id: 5,
      name: PdfFileNames[ReportTypes.AccountStructure],
      key: ReportTypes.AccountStructure,
      permission: PermissionsConstants.ModulesReportNames.AccountStructure,
      isEnabled: isModuleAllowed(
        tenant?.modules,
        PermissionsConstants.ModulesReportNames.AccountStructure
      ),
    },
    {
      id: 6,
      name: PdfFileNames[ReportTypes.CommercialCard],
      key: ReportTypes.CommercialCard,
      permission: PermissionsConstants.ModulesReportNames.CommercialCard,
      isEnabled: isModuleAllowed(
        tenant?.modules,
        PermissionsConstants.ModulesReportNames.CommercialCard
      ),
    },
  ];

  const tabs = ReportTabsList?.filter((tb) => tb.isEnabled);
  tabs.sort((a, b) => a.name.localeCompare(b.name));

  const handleSelectReport = (report) => {
    if (report) {
      setSelectedReport(report);
      setShowModal(true);
    }
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setSelectedReport({});
  };

  const isReportsAllowed = isModuleAllowed(
    tenant.modules,
    Object.values(PermissionsConstants.Reports)
  );

  const hasEngagementReports = tabs.some((tb) =>
    isModuleAllowed(tenant?.modules, tb.permission)
  );

  const shouldRender = hasEngagementReports && isReportsAllowed;
  if (!shouldRender) return <div>&nbsp;</div>;

  return (
    <>
      {isDemo ? (
        <h5 className="mt-3 mb-0 px-3 mb-0 font-weight-semi-bold">
          Engagement
        </h5>
      ) : null}
      <OrganizationsModal
        show={showModal}
        handleClose={handleCloseModal}
        report={selectedReport}
      />
      <div className={isDemo ? 'p-3' : 'p-0'}>
        <div
          className={`d-flex ${
            isDemo
              ? 'justify-content-around gap-2 flex-wrap'
              : 'gap-6 scroll-items justify-content-center align-items-start mx-auto'
          }`}
        >
          {tabs.map(
            (tab) =>
              isModuleAllowed(tenant?.modules, tab.permission) && (
                <div key={tab.id}>
                  <>
                    {isDemo ? (
                      <ReportTileDemo tab={tab} onSelect={handleSelectReport} />
                    ) : (
                      <ReportTile tab={tab} onSelect={handleSelectReport} />
                    )}
                  </>
                </div>
              )
          )}
        </div>
      </div>
    </>
  );
}
