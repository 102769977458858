import { Fragment, useEffect, useRef, useState } from 'react';
import useOutsideClickDropDown from '../../hooks/useOutsideClickDropDown';
import { Input } from 'reactstrap';
import IconTextLoader from '../loaders/IconText';
import ItemAvatar from '../ItemAvatar';
import Avatar from '../Avatar';
import LucideIcon from './LucideIcon';

const NewKindOfSearchDropdown = ({
  cache = {},
  cacheKey,
  currentSelected,
  dataReadKey = 'name',
  dataReadId = 'id',
  onItemSelect,
  fieldState = {},
  validationConfig = {},
  errorClass,
  dataService,
  dataServiceApi,
  preData = [],
  usePreData = false,
  readOnly = false,
  disabled = false,
  placeholder,
  onInputSearch,
  optionsClass,
  menuClass = 'w-100',
  showEmail,
  showAvatar,
  showIcon,
  type = 'item',
  createItem,
  pillButton = 'py-2 px-3',
  showAvatarButton,
  multiple = false,
  showSearchField = true,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedItem, setSelectedItem] = useState(
    currentSelected || (multiple ? [] : {})
  );
  const [dropdownDirection, setDropdownDirection] = useState('below');

  const menuRef = useRef();
  const buttonRef = useRef();
  const searchRef = useRef();

  useOutsideClickDropDown(menuRef, isOpen, setIsOpen);

  useEffect(() => {
    setSelectedItem(currentSelected || (multiple ? [] : {}));
  }, [currentSelected]);

  useEffect(() => {
    if (isOpen) {
      searchRef?.current?.focus();
      determineDropdownPosition();
      if (preData?.length || usePreData) {
        setData(preData);
      } else {
        fetchData();
      }
    } else {
      setSearchTerm('');
    }
  }, [isOpen, preData, usePreData]);

  const fetchData = async () => {
    if (disabled) return;
    if (cache[cacheKey]) {
      setData(cache[cacheKey]);
      return;
    }
    setIsLoading(true);
    try {
      const response = await dataService[dataServiceApi]({});
      const { data: apiData } = response || {};
      cache[cacheKey] = apiData;
      setData(apiData);
    } catch (error) {
      console.error('Error fetching roles:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const determineDropdownPosition = () => {
    const buttonRect = buttonRef.current.getBoundingClientRect();
    const viewportHeight = window.innerHeight;

    const spaceBelow = viewportHeight - buttonRect.bottom;
    const spaceAbove = buttonRect.top;

    // if there is less space below open dropdown above the field
    if (spaceBelow < 300 && spaceAbove > spaceBelow) {
      setDropdownDirection('above');
    } else {
      setDropdownDirection('below');
    }
  };

  const filteredData = data?.filter((item) =>
    item[dataReadKey]?.toLowerCase().includes(searchTerm?.toLowerCase())
  );

  const handleItemClick = (e, item) => {
    e.preventDefault();
    e.stopPropagation();
    setSelectedItem(item);
    setIsOpen(false);
    onItemSelect(item);
  };

  const renderData = () => {
    if (isLoading) {
      return (
        <div className="p-3">
          <IconTextLoader count={3} />
        </div>
      );
    }
    if (filteredData.length > 0) {
      return (
        <Fragment>
          {filteredData?.map((item) => (
            <div
              key={item[dataReadId]}
              onClick={(e) => handleItemClick(e, item)}
              className={`py-2 fs-7 bg-hover-gray text-black btn-outline-primary px-3 ${
                multiple
                  ? Array.isArray(selectedItem) &&
                    selectedItem?.some(
                      (selected) => selected?.id === item[dataReadId]
                    )
                    ? 'bg-primary-soft'
                    : ''
                  : item[dataReadId] === selectedItem?.id
                  ? 'bg-primary-soft'
                  : ''
              }`}
            >
              <div className="w-100 d-flex align-items-center gap-2">
                {showAvatar && (
                  <ItemAvatar>
                    <Avatar
                      user={item}
                      type={type === 'item' ? 'contact' : type}
                    />
                  </ItemAvatar>
                )}
                {showIcon && <LucideIcon icon={item.icon} />}
                <div className="item-user mx-0 w-100 col pl-0">
                  {item[dataReadKey]}
                  {showEmail &&
                    (item.email_work || item.email_other || item.email) && (
                      <p className="mb-0 fs-8 text-lowercase font-weight-normal">
                        {item.email_work || item.email_other || item.email}
                      </p>
                    )}
                </div>
              </div>
            </div>
          ))}
        </Fragment>
      );
    }

    if (createItem && searchTerm && !filteredData.length) return;
    return (
      <p className="mb-0 text-center w-100 p-2 text-muted">No options found</p>
    );
  };

  useEffect(() => {
    if (onInputSearch && !disabled) {
      onInputSearch({ target: { value: searchTerm } });
    }
  }, [searchTerm]);

  const handleToggleDropdown = () => {
    if (disabled) return;
    setIsOpen((prevState) => !prevState);
  };

  const onHandleChange = (e) => {
    if (disabled) return;
    setSearchTerm(e.target.value);
  };

  const handleNewCreate = () => {
    createItem(searchTerm);
  };

  return (
    <div
      className={`position-relative ${disabled ? '' : 'cursor-pointer'}`}
      onClick={handleToggleDropdown}
    >
      <div
        ref={buttonRef}
        className={`border w-100 d-flex justify-content-between align-items-center z-index-2 bg-white rounded
        ${errorClass} ${
          validationConfig?.required
            ? 'border-left-4 border-left-danger rounded'
            : ''
        } ${
          fieldState?.invalid && !fieldState?.error?.ref?.value
            ? 'border rounded border-danger'
            : ''
        } ${pillButton}`}
        style={{ color: readOnly && !selectedItem[dataReadKey] && '#999ead' }}
      >
        <div className="d-flex align-items-center gap-1 pr-1">
          {showAvatarButton && selectedItem[dataReadKey] ? (
            <Avatar user={selectedItem} defaultSize="xs" />
          ) : null}

          <span className="selected-content">
            {multiple && selectedItem?.length > 0
              ? `${selectedItem?.length} Selected`
              : readOnly && !selectedItem[dataReadKey]
              ? placeholder
              : selectedItem[dataReadKey]}
          </span>
        </div>
        <LucideIcon icon="ChevronDown" clazz="ml-auto" />
      </div>
      {isOpen && !disabled && (
        <div
          ref={menuRef}
          className={`position-absolute ${
            !showSearchField ? 'py-0' : 'py-2'
          } cursor-pointer shadow border rounded z-index-2 bg-white ${menuClass} ${optionsClass}`}
          style={{
            maxHeight: searchTerm ? 'auto' : 300,
            [dropdownDirection === 'below' ? 'top' : 'bottom']: '100%',
          }}
        >
          {showSearchField ? (
            <div className="mt-2 position-relative px-3">
              <Input
                innerRef={searchRef}
                value={searchTerm}
                onChange={onHandleChange}
                onClick={(e) => e.stopPropagation()}
              />
              <LucideIcon
                icon="Search"
                clazz="position-absolute"
                style={{ right: 25, top: 12 }}
              />
            </div>
          ) : null}
          <div
            className={`${
              !showSearchField ? 'mt-0' : 'mt-2'
            } d-flex flex-column border-top overflow-y-auto gap-1`}
            style={{ maxHeight: 200 }}
          >
            {renderData()}
            {createItem && searchTerm && (
              <div
                onClick={handleNewCreate}
                className={`py-2 fs-7 bg-hover-gray text-secondary btn-outline-primary px-3`}
              >
                {`Add a new ${type}: ${searchTerm}`}
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default NewKindOfSearchDropdown;
