import { FormControl } from 'react-bootstrap';
import React from 'react';
import ValidationErrorText from './ValidationErrorText';

const isNotEmptyOrSpaces = (value) => value.trim() !== '';

// generic component with validation logic with react-hook-form, will be using this or customize it more as we move forward
// right now, using it in create category modal
const InputValidation = ({
  id,
  name,
  type,
  placeholder,
  value,
  register,
  validationConfig,
  errors,
  errorMessage,
  fieldType,
  classNames,
  errorDisplay,
  iconExtraClass,
  disabled,
  wrapperClass = 'w-100',
  ...rest
}) => {
  return (
    <div className={`position-relative ${wrapperClass}`}>
      <FormControl
        id={id}
        type={fieldType}
        as={type}
        placeholder={`${placeholder} ${validationConfig.inline ? '*' : ''}`}
        value={value}
        name={name}
        disabled={disabled}
        {...rest}
        className={`${classNames} ${
          rest.required || validationConfig.borderLeft
            ? 'border-left-4 border-left-danger'
            : ''
        } ${errors[name] ? 'border-danger' : ''}`}
        {...register(name, {
          ...validationConfig,
          validate: validationConfig?.validateSpaces
            ? {
                notEmptyOrSpaces: isNotEmptyOrSpaces,
              }
            : null,
        })}
      />
      {errors[name]?.type === 'required' && (
        <ValidationErrorText
          text={errors[name]?.message || `${placeholder} is required.`}
          extraClass={errorDisplay}
          iconExtraClass={iconExtraClass}
        />
      )}
      {errors[name]?.type === 'maxLength' && (
        <ValidationErrorText
          text={errors[name]?.message}
          extraClass={errorDisplay}
          iconExtraClass={iconExtraClass}
        />
      )}
      {errors[name]?.type === 'pattern' && (
        <ValidationErrorText
          text={errors[name]?.message || errorMessage}
          extraClass={errorDisplay}
          iconExtraClass={iconExtraClass}
        />
      )}
      {errors[name]?.type === 'notEmptyOrSpaces' && (
        <ValidationErrorText
          text="Field cannot be empty or contain only spaces."
          extraClass={errorDisplay}
          iconExtraClass={iconExtraClass}
        />
      )}
    </div>
  );
};

export default InputValidation;
