import axios from './axiosInstance';
import authHeader from './auth-header';
import BaseRequestService from './baseRequest.service';

class CheckListService extends BaseRequestService {
  async getCheckLists({ page = 1, limit = 10, search, ...rest }) {
    const params = {
      page,
      limit,
      search,
      ...rest,
    };

    return await axios.get(`/checklists`, {
      params,
      headers: authHeader(),
    });
  }

  async getOrgCheckLists({ page = 1, limit = 10, ...rest }) {
    const params = {
      page,
      limit,
      ...rest,
    };

    return await axios.get(`/organizationChecklists`, {
      params,
      headers: authHeader(),
    });
  }

  async getCheckListItemsById(id, { page = 1, limit = 20, type }) {
    const params = {
      page,
      limit,
      type,
    };
    const response = await axios.get(
      `/checklists/${id}/items`,
      {
        params,
        headers: authHeader(),
      },
      {}
    );

    return response;
  }

  async getCheckListsById(id) {
    return await axios.get(`/checklists/${id}`, {
      headers: authHeader(),
    });
  }

  async getOrgCheckList(id, { page = 1, limit = 10, ...rest }) {
    const params = {
      organizationId: id,
      page,
      limit,
      ...rest,
    };
    const response = await axios.get(
      `/organizationChecklists`,
      {
        params,
        headers: authHeader(),
      },
      {}
    );

    return response;
  }

  async getOrgCheckListsById(id) {
    return await axios.get(`/organizationChecklists/${id}`, {
      headers: authHeader(),
    });
  }

  async createCheckLists(data) {
    return await axios.post(`/checklists`, data, {
      headers: authHeader(),
    });
  }

  async updateCheckLists(data, id) {
    return await axios.put(`/checklists/${id}`, data, {
      headers: authHeader(),
    });
  }

  async getCheckListsItemId(id) {
    return await axios.get(`/checklistItems/${id}`, {
      headers: authHeader(),
    });
  }

  async getChecklistByContact(id, { page = 1, limit = 10 }) {
    const params = {
      contactId: id,
      page,
      limit,
    };
    return await axios.get(`/organizationChecklistContacts`, {
      params,
      headers: authHeader(),
    });
  }

  async createCheckListItem(data) {
    return await axios.post(`/checklistItems`, data, {
      headers: authHeader(),
    });
  }

  async addOrgChecklist(data) {
    return await axios.post(`/organizationChecklists`, data, {
      headers: authHeader(),
    });
  }

  async checklistOrgDelete(id) {
    return await axios.delete(`/organizationChecklists/${id}`, {
      headers: authHeader(),
    });
  }

  async addContactChecklist(data) {
    return await axios.post(`/organizationChecklistContacts`, data, {
      headers: authHeader(),
    });
  }

  async updateCheckListItem(id, data) {
    return await axios.put(`/checklistItems/${id}`, data, {
      headers: authHeader(),
    });
  }

  async deleteCheckList(id) {
    return axios.delete(`/checklists/${id}`, {
      headers: authHeader(),
    });
  }

  async deleteOrganizationCheckList(id) {
    return axios.delete(`/organizationChecklists/${id}`, {
      headers: authHeader(),
    });
  }

  async deleteCheckListContact(id) {
    return axios.delete(`/organizationChecklistContacts/${id}`, {
      headers: authHeader(),
    });
  }

  async deleteCheckListItem(id) {
    return axios.delete(`/checklistItems/${id}`, {
      headers: authHeader(),
    });
  }

  async getOrgCheckListProgress({
    limit = 10,
    page = 1,
    organizationChecklistId,
  }) {
    const params = {
      limit,
      page,
      organizationChecklistId,
    };
    return await axios.get(`/organizationChecklistProgress`, {
      params,
      headers: authHeader(),
    });
  }

  getChecklistAggregateStatus(data) {
    return axios.post(
      `/organizationChecklistProgress/aggregate/status/byDueDate`,
      data,
      {
        headers: authHeader(),
      }
    );
  }

  async createOrgCheckListItemProgress(id, data) {
    return await axios.put(`/organizationChecklistItemProgress/${id}`, data, {
      headers: authHeader(),
    });
  }

  async ownerAttachWithChecklist(id, user_id, data) {
    return await axios.post(
      `/organizationChecklists/${id}/owners/${user_id}`,
      data,
      {
        headers: authHeader(),
      }
    );
  }

  async createOrgCheckListFile(id, data) {
    return await axios.post(`/organizationChecklists/${id}/files`, data, {
      headers: authHeader(),
    });
  }

  async getChecklistOwners({ organizationChecklistId, limit, page }) {
    const params = {
      limit,
      page,
      organizationChecklistId,
    };
    return await axios.get(
      `/organizationChecklists/${organizationChecklistId}/owners`,
      {
        params,
        headers: authHeader(),
      }
    );
  }

  async deleteCheckListOrgFile(id) {
    return axios.delete(`/organizationChecklistFiles/${id}`, {
      headers: authHeader(),
    });
  }

  async getCheckListFiles(id, { page = 1, limit = 20, type }) {
    const params = {
      page,
      limit,
      type,
      organizationChecklistId: id,
    };
    return await axios.get(`/organizationChecklistFile`, {
      params,
      headers: authHeader(),
    });
  }

  async getOrgCheckListItemProgress({
    limit = 10,
    page = 1,
    organizationChecklistProgressId,
  }) {
    const params = {
      limit,
      page,
      organizationChecklistProgressId,
    };
    return await axios.get(`/organizationChecklistItemProgress`, {
      params,
      headers: authHeader(),
    });
  }

  async getOrganizationChecklistContacts({
    limit = 10,
    page = 1,
    organizationChecklistId,
  }) {
    const params = {
      limit,
      page,
      organizationChecklistId,
    };
    return await axios.get(`/organizationChecklistContacts`, {
      params,
      headers: authHeader(),
    });
  }
}

export default new CheckListService();
