/* eslint-disable no-use-before-define */
import { Card, CardBody, CardFooter, CardHeader } from 'reactstrap';
import { useEffect, useState } from 'react';
import GenericTable from '../../../../components/GenericTable';
import { paginationDefault } from '../../../../utils/constants';
import Loading from '../../../../components/Loading';
import IdfTooltip from '../../../../components/idfComponents/idfTooltip';
import Pagination from '../../../../components/Pagination';
import {
  changePaginationLimit,
  changePaginationPage,
} from '../../../Deals/contacts/utils';
import { TrainingAssignmentReportsColumns } from '../../../../utils/learn.constants';
import NoDataFound from '../../../../components/commons/NoDataFound';
import useFetchAssignmentReports from '../../../../hooks/fetch/useFetchAssignmentReports';
import ReportSearchOptionDropdown from '../../../../components/commons/ReportSearchOptionDropdown';
import useFetchUsers from '../../../../hooks/fetch/useFetchUsers';
import useFetchTeams from '../../../../hooks/fetch/useFetchTeams';
import useSearchOptionDropdown from '../../../../hooks/fetch/useSearchOptionDropdown';

export const TrainingAssignmentReports = () => {
  const [showCSVBtn, setShowCSVBtn] = useState(false);
  const [pagination, setPagination] = useState(paginationDefault);

  const {
    users,
    searchQueryUsers,
    setSearchQueryUsers,
    handleOnDoneUsers,
    handleAllSelectUsers,
    queryFilterUsers,
  } = useFetchUsers({ callback: toggleModify });
  const {
    teams,
    searchQueryTeam,
    setSearchQueryTeam,
    handleAllSelect,
    handleOnDoneTeams,
    queryFilterTeams,
  } = useFetchTeams({ callback: toggleModify });

  const {
    assignmentReports,
    paginationAssignmentReports,
    setPaginationAssignmentReports,
    isLoadingFetchAssignmentReports,
  } = useFetchAssignmentReports({
    userIds: queryFilterUsers?.filters,
    teamIds: queryFilterTeams?.filters,
  });

  function toggleModify() {
    setPaginationAssignmentReports((prev) => ({ ...prev, page: 1 }));
  }

  const {
    selectedItem: selectedItemTeam,
    setSelectedItem: setSelectedItemTeam,
    show: showTeams,
    setShow: setShowTeams,
  } = useSearchOptionDropdown();

  const {
    selectedItem: selectedItemUser,
    setSelectedItem: setSelectedItemUser,
    show: showUsers,
    setShow: setShowUsers,
  } = useSearchOptionDropdown();

  useEffect(() => {
    setShowCSVBtn(!!assignmentReports?.length);
    setPagination(paginationAssignmentReports);
  }, [assignmentReports]);

  const rows = assignmentReports?.map((result, index) => {
    return {
      id: index,
      dataRow: [
        {
          key: 'assignment',
          component: <span className="fw-bold">{result?.title}</span>,
        },
        {
          key: 'lessonCount',
          component: result?.totalLessons,
        },
        {
          key: 'date',
          component: result?.dueAt,
        },
        {
          key: 'team',
          component: result?.teamName,
        },
        {
          key: 'user',
          component: result?.userName,
        },
        {
          key: 'not_started',
          component: `${result?.notStartedPercentage}`,
        },
        {
          key: 'progress',
          component: `${result?.inProgressPercentage}`,
        },
        {
          key: 'completed',
          component: `${result?.completedPercentage}`,
        },
        {
          key: 'overdue',
          component: result?.overduePercentage,
        },
      ],
    };
  });

  const updatedRows = assignmentReports?.map((item) => {
    return {
      assignmentName: item?.title,
      lessonCount: item?.totalLessons,
      dueDate: item?.dueAt,
      team: item?.teamName,
      user: item?.userName,
      notStarted: item?.notStartedPercentage,
      inProgress: item?.inProgressPercentage,
      completed: item?.completedPercentage,
      overdue: item?.overduePercentage,
    };
  });

  return (
    <>
      <Card className="h-100 border-0 shadow-0">
        <CardHeader>
          <div className="d-flex justify-content-between align-items-center w-100">
            <IdfTooltip text="Assignment Statistics" placement="bottom">
              <h4 className="card-title text-hover-primary mb-0 text-truncate">
                Assignment Statistics
              </h4>
            </IdfTooltip>
            <div
              className={`d-flex align-items-center gap-2 ${
                showCSVBtn ? 'mr-6 pr-9' : ''
              }`}
            >
              <ReportSearchOptionDropdown
                selectedData={selectedItemTeam}
                data={teams}
                show={showTeams}
                label={`Team`}
                scrollable="pr-3 text-left"
                setShow={setShowTeams}
                showCheckAll={false}
                setSelectedData={setSelectedItemTeam}
                search={true}
                maxLength={22}
                customKey={['name', 'id']}
                handleAllSelect={handleAllSelect}
                customStyle={{ width: '250px' }}
                setQueryFilters={() => {}}
                searchQuery={searchQueryTeam}
                setSearchQuery={setSearchQueryTeam}
                handleOnDone={(items) => {
                  handleOnDoneTeams(items);
                  toggleModify();
                }}
                controlledTitle={
                  teams
                    ?.filter((i) => queryFilterTeams?.filters?.includes(i.id))
                    .map((i) => i.name)
                    .join(',') || ''
                }
              />
              <ReportSearchOptionDropdown
                selectedData={selectedItemUser}
                data={users}
                show={showUsers}
                label={`User`}
                scrollable="pr-3 text-left"
                setShow={setShowUsers}
                showCheckAll={false}
                setSelectedData={setSelectedItemUser}
                search={true}
                maxLength={22}
                customKey={['name', 'id']}
                handleAllSelect={handleAllSelectUsers}
                customStyle={{ width: '250px' }}
                setQueryFilters={() => {}}
                searchQuery={searchQueryUsers}
                setSearchQuery={setSearchQueryUsers}
                handleOnDone={(items) => {
                  handleOnDoneUsers(items);
                  toggleModify();
                }}
                controlledTitle={
                  users
                    ?.filter((i) => queryFilterUsers?.filters?.includes(i.id))
                    .map((i) => i.name)
                    .join(',') || ''
                }
              />
            </div>
          </div>
        </CardHeader>

        <CardBody className="p-0 flex-1 overflow-y-auto">
          {isLoadingFetchAssignmentReports ? (
            <div className="py-5 text-center w-100">
              <Loading />
            </div>
          ) : (
            <div>
              {assignmentReports?.length === 0 ? (
                <NoDataFound
                  icon="manage_search"
                  title="No data found"
                  containerStyle="text-gray-search my-6 py-6"
                  titleStyle="font-normal font-size-sm2 text-gray-search"
                />
              ) : (
                <>
                  <GenericTable
                    checkbox={false}
                    data={rows}
                    exportToCSV={showCSVBtn}
                    tableData={updatedRows}
                    fileName="AssignmentStatistics"
                    columns={TrainingAssignmentReportsColumns}
                    usePagination={false}
                    noDataInDbValidation={true}
                  />
                </>
              )}
            </div>
          )}
        </CardBody>

        <CardFooter className="border-top bg-gray-table-head p-2 border-bottom-0 border-left-0 border-right-0 rounded-0">
          <div className="d-flex align-items-center justify-content-end">
            <Pagination
              showPerPage
              showPages={false}
              paginationInfo={pagination}
              onPageChange={(newPage) => {
                changePaginationPage(newPage, setPaginationAssignmentReports);
              }}
              onLimitChange={(perPage) => {
                changePaginationLimit(perPage, setPaginationAssignmentReports);
              }}
            />
          </div>
        </CardFooter>
      </Card>
    </>
  );
};
