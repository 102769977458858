import authHeader from './auth-header';
import BaseRequestService from './baseRequest.service';

const formatRangeFilter = (filter, key, prependKey) => {
  if (Object.hasOwn(filter, key)) {
    const keyAndPrependKey = prependKey ? `${prependKey}${key}` : key;
    const rangeFilter = {
      [`${keyAndPrependKey}[start]`]: filter[key].start,
      [`${keyAndPrependKey}[end]`]: filter[key].end,
    };
    delete filter[key];
    return rangeFilter;
  }
  return {};
};

class PipelineService extends BaseRequestService {
  async getPipelines(page = 1, limit = 20) {
    const response = await this.get(
      `/pipelines`,
      {
        headers: authHeader(),
        params: { page, limit },
      },
      { fullResponse: true }
    );

    return response?.data;
  }

  getPipelineTeam(pipelineId) {
    return this.get(`/pipelines/${pipelineId}/teams`, {
      headers: authHeader(),
    });
  }

  getPipelineSummary(pipelineId) {
    return this.get(`/pipelines/${pipelineId}/summary`, {
      headers: authHeader(),
    });
  }

  getPipelineStageStatuses(page = 1, limit = 10) {
    return this.get(`/pipelineStageStatuses`, {
      headers: authHeader(),
      params: { page, limit },
    });
  }

  setPipelineTeam(teamIds) {
    return this.post(`/pipelineTeams`, teamIds, {
      headers: authHeader(),
    });
  }

  deletePipelineTeam(pipelineId, teamId) {
    return this.delete(`/pipelines/${pipelineId}/team/${teamId}`, {
      headers: authHeader(),
    });
  }

  createPipeline(data) {
    return this.post(`/pipelines`, data, {
      headers: authHeader(),
    });
  }

  updatePipeline(pipelineId, data) {
    return this.put(`/pipelines/${pipelineId}`, data, {
      headers: authHeader(),
    });
  }

  deletePipeline(pipelineId, transferId) {
    return this.delete(`/pipelines/${pipelineId}`, {
      headers: authHeader(),
      params: { transferId },
    });
  }

  setDefaultPipeline(pipelineId) {
    return this.put(
      `/pipelines/${pipelineId}/default`,
      {},
      {
        headers: authHeader(),
      }
    );
  }

  getPipelineDeals(pipelineId, page = 1, limit = 10, filter = {}, order) {
    let updatedFilter = { ...filter };
    const amountRangeFilter = formatRangeFilter(updatedFilter, 'amountRange');
    const probabilityRangeFilter = formatRangeFilter(
      updatedFilter,
      'probabilityRange',
      'pipelineStage.'
    );
    updatedFilter = {
      ...updatedFilter,
      ...amountRangeFilter,
      ...probabilityRangeFilter,
    };
    return this.get(`/pipelines/${pipelineId}/deals`, {
      headers: authHeader(),
      params: {
        page,
        limit,
        ...updatedFilter,
        order,
      },
    });
  }

  getPipelineAverageByDealAmount(self, pipelineId) {
    return this.post(
      `/pipelines/aggregate/average/byDealAmount`,
      {
        self,
        pipelineId,
      },
      {
        headers: authHeader(),
      }
    );
  }

  getPipelineAverageByCompletionDate(self, pipelineId, completionDateRange) {
    return this.post(
      `/pipelines/aggregate/average/byCompletionDate`,
      {
        self,
        pipelineId,
        completionDateRange,
      },
      {
        headers: authHeader(),
      }
    );
  }

  getPipelineAverageByNoActivity(self, pipelineId, noActivityDateRange) {
    return this.post(
      `/pipelines/aggregate/average/byNoActivity`,
      {
        self,
        pipelineId,
        noActivityDateRange,
      },
      {
        headers: authHeader(),
      }
    );
  }

  getPipelineAverageByCompletedStatus(self, pipelineId, completionDateRange) {
    return this.post(
      `/pipelines/aggregate/average/byCompletedStatus`,
      {
        self,
        pipelineId,
        completionDateRange,
      },
      {
        headers: authHeader(),
      }
    );
  }

  getPipelinesPerformance(payload) {
    return this.post(`/pipelines/aggregate/performance`, payload, {
      headers: authHeader(),
    });
  }
}

export default new PipelineService();
