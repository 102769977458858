import React from 'react';
import useHash from '../../../hooks/useHash';
import IdfTooltip from '../../idfComponents/idfTooltip';
import LucideIcon from '../../commons/LucideIcon';

const ContentLayout = ({ heading, search, children }) => {
  const { updateHash } = useHash();
  return (
    <div className="d-flex h-100 flex-column">
      <div className="border-bottom p-3">
        <div className="position-relative">
          <IdfTooltip text="Go back to Home">
            <button
              className="btn btn-sm d-flex position-absolute abs-center-y align-items-center icon-hover-bg p-0"
              onClick={() => updateHash('')}
            >
              <LucideIcon size={20} icon="MoveLeft" />
            </button>
          </IdfTooltip>
          <div className="chat-home-window-size d-flex justify-content-between mx-auto">
            <h3
              className={`mb-0 d-flex align-items-center gap-1 ${
                heading.title === 'Reports' ? 'justify-content-center' : ''
              }`}
            >
              <LucideIcon size={18} icon={heading.icon} />
              {heading.title}
            </h3>
            {search}
          </div>
        </div>
      </div>
      <div
        id="chatContentLayout"
        className="px-3 py-1 h-100 flex-1 overflow-y-auto"
      >
        {children}
      </div>
    </div>
  );
};

export default ContentLayout;
