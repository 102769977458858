import React, { useState } from 'react';
import AnimatedTabs from '../components/commons/AnimatedTabs';
import IdaContent from '../views/Ida/IdaContent';
import PageTitle from '../components/commons/PageTitle';
import { TabContent, TabPane } from 'reactstrap';
import IdaAnalytics from '../views/Ida/IdaAnalytics';

const Ida = () => {
  const [activeTab, setActiveTab] = useState(1);

  const tabsData = [
    {
      tabId: 1,
      title: 'Content',
      component: <IdaContent />,
    },
    {
      tabId: 2,
      title: 'Analytics',
      component: <IdaAnalytics />,
    },
  ];

  const toggle = (tab) => {
    if (activeTab !== tab.tabId) {
      setActiveTab(tab.tabId);
    }
  };

  return (
    <>
      <PageTitle page={tabsData?.find((t) => t.tabId === activeTab)?.title} />
      <AnimatedTabs
        tabsData={tabsData}
        activeTab={activeTab}
        tabClasses="px-3"
        toggle={toggle}
        permissionCheck={true}
        borderClasses="bg-white border-bottom"
      />
      <TabContent className="bg-white">
        <TabPane className="position-relative">
          {tabsData.find((item) => item.tabId === activeTab)?.component}
        </TabPane>
      </TabContent>
    </>
  );
};

export default Ida;
