import React, { useCallback, useEffect, useMemo, useState } from 'react';
import {
  checkIfNaicsOrReportDateEmpty,
  cleanDataWidgets,
  formatCurrencyField,
  getRandomUUID,
  NAICS_STORAGE_KEY,
  overflowing,
} from '../../../utils/Utils';
import SimpleModalCreation from '../../../components/modal/SimpleModalCreation';
import {
  getCycleDate,
  updateWidgetBySection,
} from '../../../components/reports/reports.helper.functions';
import {
  MerchantReportV2Sections,
  ReportFormErrorFields,
  ReportTypes,
} from '../../../components/reports/reports.constants';
import _ from 'lodash';
import {
  Card,
  CardBody,
  Col,
  FormGroup,
  Label,
  ListGroup,
  Row,
} from 'reactstrap';
import SicNaicsAutoComplete from '../../../components/prospecting/v2/common/SicNaicsAutoComplete';
import ReactDatepicker from '../../../components/inputs/ReactDatpicker';
import useSicNaicsChangeDetect from '../../useSicNaicsChangeDetect';
import { Accordion, FormCheck, InputGroup } from 'react-bootstrap';
import CurrencyInput from 'react-currency-input-field';
import ReportService from '../../../services/report.service';
import ReportAccordionToggle from '../../../components/reports/ReportAccordionToggle';
import NoDataFound from '../../../components/commons/NoDataFound';
import IconHeadingBlock from '../../../components/reportbuilder/blocks/IconHeadingBlock';
import WidgetWithActionButtons from '../../../components/reportbuilder/widgets/WidgetWithActionButtons';
import {
  APWData,
  FBCWData,
  RefundAndChargebacksAmountProcessedData,
  WidgetTypes,
} from '../../../components/reportbuilder/constants/widgetsConstants';
import ReportSectionIncludedLabel from '../../../components/reports/ReportSectionIncludedLabel';
import useWidgetsLibrary from '../../../components/reportbuilder/widgets/useWidgetsLibrary';
import Alert from '../../../components/Alert/Alert';
import AlertWrapper from '../../../components/Alert/AlertWrapper';
import ValidationErrorText from '../../../components/commons/ValidationErrorText';
import ButtonIcon from '../../../components/commons/ButtonIcon';
import useAddNewBankContactModal from '../../../components/reports/account-structure/modals/AddNewBankContact';
import BankContact from '../../../components/reports/account-structure/BankContact';
import moment from 'moment/moment';
import ReportModalTitle from '../../../components/reports/ReportModalTitle';

const WidgetsBySection = ({
  icon,
  widgets,
  section,
  onAddWidget,
  isMultiple,
  selectedTenant,
  onDeleteWidget,
  setSelectedWidget,
  setShowWidgetsLibrary,
}) => {
  const newWidgets = isMultiple || widgets[section];
  return (
    <>
      <div className="text-left">
        <br />
        <IconHeadingBlock
          heading={_.startCase(section)}
          icon={icon}
          showIcon={false}
          containerStyle="gap-1 justify-content-between px-3 mb-0"
          reportType={ReportTypes.CommercialCard}
        />
      </div>
      <ListGroup className="list-group-no-gutters mt-0 list-group-flush">
        {newWidgets?.map((widget, index) => (
          <WidgetWithActionButtons
            key={index}
            widget={widget}
            section={section}
            onAddWidget={onAddWidget}
            onDeleteWidget={onDeleteWidget}
            setSelectedWidget={setSelectedWidget}
            setShowWidgetsLibrary={setShowWidgetsLibrary}
            selectedTenant={selectedTenant}
            actionButtons={{}}
          />
        ))}
      </ListGroup>
    </>
  );
};
const GenerateMerchantServicesReportModal = ({
  show,
  setShow,
  organization,
  modalWidgets,
  selectedReport,
  modalReportPages,
  handleGenerateReport,
  getOrganization,
}) => {
  const [errorFields, setErrorFields] = useState(ReportFormErrorFields);
  const [generating, setGenerating] = useState(false);
  const clonedReport = _.cloneDeep({
    ...selectedReport?.main,
    ...selectedReport?.individual,
    reportDate: getCycleDate(selectedReport?.individual?.reportDate),
  });
  const [reportForm, setReportForm] = useState(clonedReport);
  const { setShowModal, setCompany, NaicsModal } = useSicNaicsChangeDetect(
    selectedReport,
    organization,
    getOrganization
  );
  const [errorMessage, setErrorMessage] = useState('');
  const [currentAccordionKey, setCurrentAccordionKey] = useState(
    MerchantReportV2Sections.CardPaymentAcceptanceOverview
  );
  const [widgets, setWidgets] = useState(modalWidgets);
  const [reportPages, setReportPages] = useState(modalReportPages);
  const contacts =
    selectedReport?.main?.aggregate?.contacts ||
    selectedReport?.individual?.contacts ||
    [];
  const [reportContacts, setReportContacts] = useState(contacts);
  const [selectedBankContact] = useState(contacts?.length ? contacts[0] : {});

  const handleBankContacts = (newBankContacts) => {
    setReportContacts(newBankContacts);
    const newReport = {
      ...reportForm,
      contacts: newBankContacts,
    };

    setReportForm(newReport);
    const newWidgets = updateWidgetBySection(
      widgets,
      MerchantReportV2Sections.YourDedicatedTeam,
      WidgetTypes.TCW,
      newReport,
      { ...newReport }
    );
    setWidgets(newWidgets);
  };
  const {
    setShowModal: setShowAddNewTeamContactModal,
    AddNewBankContactModal,
  } = useAddNewBankContactModal(
    selectedBankContact,
    reportContacts,
    handleBankContacts
  );
  const {
    setShowModal: setShowWidgetsLibrary,
    WidgetsLibraryModal,
    setSelectedWidget,
  } = useWidgetsLibrary();
  const handleAccordionClick = (eventKey) => {
    setCurrentAccordionKey(eventKey);
  };

  const updateWidgets = (
    newReportData,
    widgetType,
    widgetConfigData,
    widgetSection
  ) => {
    const newWidgets = updateWidgetBySection(
      widgets,
      widgetSection,
      widgetType,
      newReportData,
      widgetConfigData
    );
    setWidgets(newWidgets);
  };

  const createOrUpdateReport = async () => {
    try {
      if (
        checkIfNaicsOrReportDateEmpty(reportForm, errorFields, setErrorFields)
      ) {
        return;
      }
      setGenerating(true);

      const cleanWidgets = cleanDataWidgets(widgets);

      let newCoreData = selectedReport?.main?.coreData;
      const mainReportId =
        selectedReport?.main?.reportId || selectedReport?.main?.key;
      const individualReportId =
        selectedReport?.individual?.reportId || selectedReport?.individual?.key;

      const newReportForm = { ...reportForm };

      delete newReportForm.aggregate;
      delete newReportForm.isAuto;
      delete newReportForm.reportPages;
      delete newReportForm.coreData;
      delete newReportForm.valueN;
      delete newReportForm.reportDate;

      newCoreData = newCoreData.map((core) =>
        core.feId === individualReportId
          ? {
              ...newReportForm,
              feId: individualReportId,
              reportDate: core.Month,
              Month: core.Month,
              prettyDate: moment(core.Month?.split('T')[0]).format('MMMM YYYY'),
              contacts: reportContacts,
            }
          : { ...core }
      );
      // update individual in its place in above coreData

      const reportMapped = {
        aggregate: selectedReport?.main?.aggregate,
        coreData: newCoreData,
      };

      const savingObject = {
        name: reportForm.companyName || reportForm.name,
        date: selectedReport?.main?.aggregate?.monthRange?.start,
        type: ReportTypes.Merchant,
        manualInput: {
          ...reportMapped,
          valueN: reportForm.valueN,
          valueNaicsSic: reportForm.valueNaicsSic,
          industry: reportForm.industry,
          name: reportForm.companyName || reportForm.name,
          companyName: reportForm.companyName || reportForm.name,
          widgets: cleanWidgets,
          reportPages,
          isAuto: true,
        },
      };
      /// when any individual report values are updated
      // update the same record in coreData array by individual feId
      // recalculate aggregate
      // update individual in its place in above coreData
      const reportObject = await ReportService.updateReport(
        mainReportId,
        savingObject
      );
      setShow(false);
      handleGenerateReport(
        reportObject,
        cleanWidgets,
        reportPages,
        reportContacts,
        {
          ...reportForm,
          feId: individualReportId,
          key: individualReportId,
          reportId: individualReportId,
          Month: new Date(selectedReport?.individual?.reportDate).toISOString(),
          reportDate: new Date(
            selectedReport?.individual?.reportDate
          ).toISOString(),
          prettyDate: moment(
            new Date(selectedReport?.individual?.reportDate)
          ).format('MMMM YYYY'),
          contacts: reportContacts,
        }
      );
    } catch (e) {
      console.log(e);
    } finally {
      setGenerating(false);
    }
  };

  const handleInputChange = (e, key, widgetTypes, defaultWidgetData, page) => {
    const { value } = e.target;
    const newReport = {
      ...reportForm,
      [key]: value,
    };
    const updateConfigAndData = defaultWidgetData;
    const widgetConfigData =
      widgetTypes[0] === WidgetTypes.FBCW ||
      page === MerchantReportV2Sections.RefundsAndChargebacks
        ? { ...updateConfigAndData, reportObject: newReport }
        : { ...updateConfigAndData, data: newReport };
    updateWidgets(newReport, widgetTypes, widgetConfigData, page);
    setReportForm(newReport);
  };

  const detectNaicsChangeAndRefreshOrganizationProfile = () => {
    if (reportForm?.valueNaicsSic !== organization?.naics_code) {
      getOrganization && getOrganization();
    }
  };

  const handleReportPageToggle = (section) => {
    const updatedReportPages = {
      ...reportPages,
      [section]: {
        ...reportPages[section],
        enabled: !Object.hasOwn(reportPages, section)
          ? true
          : !reportPages[section].enabled,
      },
    };

    // we need to make sure at-least 1 page is active, so if user toggles all off and trying to toggle last off make sure to avoid it.
    const remainingEnabledSections = Object.entries(updatedReportPages).filter(
      ([key, value]) => value.enabled && key !== section
    ).length;

    if (!remainingEnabledSections) {
      setErrorMessage('At least one section must be enabled.');
      return;
    }
    setReportPages(updatedReportPages);
  };

  const onAddWidget = (e, newWidget, oldWidget) => {
    const sectionWidgets = modalWidgets[oldWidget.section];
    let updatedWidgets = [];
    if (oldWidget.type === WidgetTypes.BLANK) {
      sectionWidgets[oldWidget.widgetConfig.index] = {
        ...newWidget,
        id: getRandomUUID(),
      };
      updatedWidgets = [...sectionWidgets];
    } else {
      updatedWidgets = [...sectionWidgets].map((wg) =>
        wg.widgetConfig.heading === oldWidget.widgetConfig.heading
          ? { ...newWidget, id: getRandomUUID() }
          : { ...wg }
      );
    }
    setWidgets({ ...modalWidgets, [oldWidget.section]: updatedWidgets });
    setShowWidgetsLibrary(false);
  };

  const onDeleteWidget = (oldWidget) => {
    const sectionWidgets = modalWidgets[oldWidget.section];
    const updatedWidgets = [...sectionWidgets].map((wg, index) =>
      wg.widgetConfig.heading === oldWidget.widgetConfig.heading
        ? {
            action: { onAdd: () => setShowWidgetsLibrary(true) },
            widgetConfig: {
              index,
            },
            type: WidgetTypes.BLANK,
          }
        : { ...wg }
    );
    setWidgets({ ...modalWidgets, [oldWidget.section]: updatedWidgets });
  };

  const AddTeamContactButton = () => {
    return (
      <ButtonIcon
        icon="add"
        label="Team Contact"
        classnames="btn-xs my-2"
        onclick={() => setShowAddNewTeamContactModal(true)}
      />
    );
  };

  useEffect(() => {
    if (show) {
      const contactFound = selectedReport?.main?.coreData?.find(
        (s) => s?.contacts?.length > 0
      );
      if (contactFound) {
        setReportContacts(contactFound.contacts);
      }
      setReportPages(modalReportPages);
    }
  }, [show]);

  const handleDeleteBankContact = (bankContact) => {
    const newContacts = reportContacts?.filter(
      (bc) => bc.id !== bankContact.id
    );
    setReportContacts(newContacts);
    handleBankContacts(newContacts);
  };

  const isComericaFeesWidgetEnabled = () => {
    const key = 'BankFeesPercentage';
    if (modalWidgets && modalWidgets[MerchantReportV2Sections.FeeBreakdown]) {
      const mfWidget = modalWidgets[MerchantReportV2Sections.FeeBreakdown][0];
      const newFeePercentageCardsData = [
        ...mfWidget?.widgetConfig?.items[0]?.list,
      ];

      return newFeePercentageCardsData.find((fd) => fd.key === key);
    }
    return { enabled: true };
  };
  const handleComericaBankFeesToggle = (e) => {
    const key = 'BankFeesPercentage';
    const mfWidget = modalWidgets[MerchantReportV2Sections.FeeBreakdown][0];
    const newFeePercentageCardsData = [
      ...mfWidget?.widgetConfig?.items[0]?.list,
    ];

    newFeePercentageCardsData.forEach((cd) => {
      if (cd.key === key) {
        cd.enabled = e.target.checked;
      }
    });
    const newWidgetConfig = {
      ...FBCWData,
      items: [
        {
          ...FBCWData.items[0],
          list: newFeePercentageCardsData,
        },
      ],
    };
    modalWidgets[MerchantReportV2Sections.FeeBreakdown][0].widgetConfig = {
      ...newWidgetConfig,
      data: reportForm,
    };

    updateWidgets(
      reportForm,
      [WidgetTypes.FBCW],
      newWidgetConfig,
      MerchantReportV2Sections.FeeBreakdown
    );
  };

  const handleFreeFormTextChange = (e) => {
    const newReport = {
      ...reportForm,
      furtherRecommendationsText: e.target.value,
    };

    setReportForm(newReport);
    const newWidgets = updateWidgetBySection(
      widgets,
      MerchantReportV2Sections.Recommendations,
      [WidgetTypes.FREE],
      newReport,
      { ...newReport }
    );
    setWidgets(newWidgets);
  };

  return (
    <>
      <AlertWrapper className="alert-position">
        <Alert
          color="danger"
          message={errorMessage}
          setMessage={setErrorMessage}
        />
      </AlertWrapper>
      <NaicsModal />
      <WidgetsLibraryModal />
      <AddNewBankContactModal />
      <SimpleModalCreation
        modalTitle={<ReportModalTitle type={ReportTypes.Merchant} />}
        open={show}
        saveButton="Save Report"
        isLoading={generating}
        deleteButton={null}
        handleSubmit={() => {
          overflowing();
          createOrUpdateReport();
          detectNaicsChangeAndRefreshOrganizationProfile();
        }}
        bodyClassName="p-0 overflow-x-hidden"
        customModal="modal-fit"
        scrollable={true}
        onHandleCloseModal={() => {
          overflowing();
          setShow(false);
        }}
        onClick={() => document.dispatchEvent(new MouseEvent('click'))}
      >
        <Row>
          <Col md={5}>
            <div className="pl-3 py-3">
              <FormGroup>
                <Label for="title">Company Name</Label>
                <input
                  name="name"
                  type="text"
                  value={reportForm.companyName}
                  placeholder="Enter Company Name"
                  onChange={(e) => {
                    setReportForm({
                      ...reportForm,
                      companyName: e.target.value,
                    });
                    setErrorFields({
                      ...errorFields,
                      name: {
                        ...errorFields.name,
                        isShow: !e.target.value,
                      },
                    });
                  }}
                  className={`form-control mx-0 mb-0 border-left-4 border-left-danger ${
                    errorFields.name.isShow ? 'border-danger' : ''
                  }`}
                />
                {errorFields.name.isShow && (
                  <ValidationErrorText text={errorFields.name.message} />
                )}
              </FormGroup>
              <FormGroup>
                <Label for="title">NAICS</Label>
                <SicNaicsAutoComplete
                  data={reportForm}
                  setData={setReportForm}
                  placeholder="Enter a NAICS code"
                  customKey="valueN"
                  showFieldError={errorFields.naics?.isShow}
                  setErrorMessage={setErrorMessage}
                  callKey={NAICS_STORAGE_KEY}
                  callType="getNaicsCodes"
                  isDisabled={!!organization.naics_code}
                  onSelect={(item, naicsSicOnly, naicsTitle) => {
                    setReportForm({
                      ...reportForm,
                      valueN: item ? [item] : [],
                      valueNaicsSic: naicsSicOnly,
                      industry: naicsTitle,
                    });
                    setErrorFields({
                      ...errorFields,
                      naics: {
                        ...errorFields.naics,
                        isShow: !naicsSicOnly,
                      },
                    });
                    if (naicsSicOnly) {
                      if (naicsSicOnly !== organization.naics_code) {
                        setShowModal(true);
                        setCompany({
                          ...organization,
                          naics_code: naicsSicOnly,
                          industry: naicsTitle,
                        });
                      }
                    }
                  }}
                />
                {errorFields.naics?.isShow && (
                  <ValidationErrorText text={errorFields.naics.message} />
                )}
              </FormGroup>
              <FormGroup className="date-wrapper">
                <Label for="title">Report Date</Label>
                <ReactDatepicker
                  id={'rptDate'}
                  name={'reportDate'}
                  disabled={true}
                  todayButton="Today"
                  value={reportForm.reportDate}
                  autoComplete="off"
                  className={`form-control mx-0 mb-0 border-left-4 border-left-danger ${
                    errorFields.reportDate?.isShow ? 'border-danger' : ''
                  }`}
                  placeholder="Select Report Date"
                  format="MMMM yyyy"
                  onChange={(date) => {
                    setReportForm({
                      ...reportForm,
                      reportDate: date,
                    });
                    setErrorFields({
                      ...errorFields,
                      reportDate: {
                        ...errorFields.reportDate,
                        isShow: !date,
                      },
                    });
                  }}
                  showMonthYearPicker
                  showFullMonthYearPicker
                />
                {errorFields.reportDate?.isShow && (
                  <ValidationErrorText text={errorFields.reportDate.message} />
                )}
              </FormGroup>
              <FormGroup>
                <Accordion activeKey={currentAccordionKey}>
                  <Card>
                    <ReportAccordionToggle
                      eventKey={
                        MerchantReportV2Sections.CardPaymentAcceptanceOverview
                      }
                      callback={handleAccordionClick}
                      setCurrentAccordionKey={setCurrentAccordionKey}
                    >
                      {MerchantReportV2Sections.CardPaymentAcceptanceOverview}
                      <ReportSectionIncludedLabel
                        section={
                          MerchantReportV2Sections.CardPaymentAcceptanceOverview
                        }
                        reportPages={reportPages}
                        handleReportPageToggle={handleReportPageToggle}
                      />
                    </ReportAccordionToggle>
                    <Accordion.Collapse
                      eventKey={
                        MerchantReportV2Sections.CardPaymentAcceptanceOverview
                      }
                    >
                      <CardBody>
                        <FormGroup>
                          <Label for="TotalGrossVolume">
                            Total dollars processed
                          </Label>
                          <InputGroup className="align-items-center">
                            <InputGroup.Prepend>
                              <InputGroup.Text>$</InputGroup.Text>
                            </InputGroup.Prepend>
                            <CurrencyInput
                              name="TotalGrossVolume"
                              placeholder="0"
                              value={
                                formatCurrencyField(
                                  reportForm.TotalGrossVolume?.toFixed(2)
                                ) || ''
                              }
                              className="form-control"
                              onValueChange={(value, name, values) => {
                                handleInputChange(
                                  { target: { value: values.float, name } },
                                  name,
                                  [WidgetTypes.APW],
                                  APWData,
                                  MerchantReportV2Sections.CardPaymentAcceptanceOverview
                                );
                              }}
                            />
                          </InputGroup>
                        </FormGroup>
                        <FormGroup>
                          <Label for="TotalTransactions">
                            Total transactions
                          </Label>
                          <InputGroup className="align-items-center">
                            <InputGroup.Prepend>
                              <InputGroup.Text>#</InputGroup.Text>
                            </InputGroup.Prepend>
                            <CurrencyInput
                              name="TotalTransactions"
                              placeholder="0"
                              value={
                                formatCurrencyField(
                                  reportForm.TotalTransactions
                                ) || ''
                              }
                              className="form-control"
                              onValueChange={(value, name, values) => {
                                handleInputChange(
                                  { target: { value: values.float, name } },
                                  name,
                                  [WidgetTypes.APW],
                                  APWData,
                                  MerchantReportV2Sections.CardPaymentAcceptanceOverview
                                );
                              }}
                            />
                          </InputGroup>
                        </FormGroup>
                        <FormGroup>
                          <Label for="TotalFees">Total fees</Label>
                          <InputGroup className="align-items-center">
                            <InputGroup.Prepend>
                              <InputGroup.Text>$</InputGroup.Text>
                            </InputGroup.Prepend>
                            <CurrencyInput
                              name="TotalFees"
                              placeholder="0"
                              value={
                                formatCurrencyField(
                                  reportForm.TotalFees?.toFixed(2)
                                ) || ''
                              }
                              className="form-control"
                              onValueChange={(value, name, values) => {
                                handleInputChange(
                                  { target: { value: values.float, name } },
                                  name,
                                  [WidgetTypes.APW],
                                  APWData,
                                  MerchantReportV2Sections.CardPaymentAcceptanceOverview
                                );
                              }}
                            />
                          </InputGroup>
                        </FormGroup>
                        <FormGroup>
                          <Label for="AverageTransactions">
                            Average transaction
                          </Label>
                          <InputGroup className="align-items-center">
                            <InputGroup.Prepend>
                              <InputGroup.Text>$</InputGroup.Text>
                            </InputGroup.Prepend>
                            <CurrencyInput
                              name="AverageTransactions"
                              placeholder="0"
                              value={
                                formatCurrencyField(
                                  reportForm.AverageTransactions
                                ) || ''
                              }
                              className="form-control"
                              onValueChange={(value, name, values) => {
                                handleInputChange(
                                  { target: { value: values.float, name } },
                                  name,
                                  [WidgetTypes.APW],
                                  APWData,
                                  MerchantReportV2Sections.CardPaymentAcceptanceOverview
                                );
                              }}
                            />
                          </InputGroup>
                        </FormGroup>
                        <FormGroup>
                          <Label for="AverageFee">Average fee</Label>
                          <InputGroup className="align-items-center">
                            <InputGroup.Prepend>
                              <InputGroup.Text>$</InputGroup.Text>
                            </InputGroup.Prepend>
                            <CurrencyInput
                              name="AverageFee"
                              placeholder="0"
                              value={
                                formatCurrencyField(
                                  reportForm.AverageFee?.toFixed(2)
                                ) || ''
                              }
                              className="form-control"
                              onValueChange={(value, name, values) => {
                                handleInputChange(
                                  { target: { value: values.float, name } },
                                  name,
                                  [WidgetTypes.APW],
                                  APWData,
                                  MerchantReportV2Sections.CardPaymentAcceptanceOverview
                                );
                              }}
                            />
                          </InputGroup>
                        </FormGroup>
                        <FormGroup>
                          <Label for="AuthorizationApprovalPercentage">
                            Authorization to approval percentage
                          </Label>
                          <InputGroup className="align-items-center">
                            <CurrencyInput
                              name="AuthorizationApprovalPercentage"
                              placeholder="0.00"
                              decimalsLimit={2}
                              decimalScale={2}
                              value={
                                reportForm?.AuthorizationApprovalPercentage
                              }
                              className="form-control"
                              onValueChange={(value, name, values) => {
                                handleInputChange(
                                  { target: { value: values.float, name } },
                                  name,
                                  [WidgetTypes.APW],
                                  APWData,
                                  MerchantReportV2Sections.CardPaymentAcceptanceOverview
                                );
                              }}
                            />
                            <InputGroup.Append>
                              <InputGroup.Text>%</InputGroup.Text>
                            </InputGroup.Append>
                          </InputGroup>
                        </FormGroup>
                        <FormGroup>
                          <Label for="EffectiveRate">Effective rate</Label>
                          <InputGroup className="align-items-center">
                            <CurrencyInput
                              name="EffectiveRate"
                              placeholder="0.00"
                              decimalsLimit={2}
                              decimalScale={2}
                              value={reportForm?.EffectiveRate}
                              className="form-control"
                              onValueChange={(value, name, values) => {
                                handleInputChange(
                                  { target: { value: values.float, name } },
                                  name,
                                  [WidgetTypes.APW],
                                  APWData,
                                  MerchantReportV2Sections.CardPaymentAcceptanceOverview
                                );
                              }}
                            />
                            <InputGroup.Append>
                              <InputGroup.Text>%</InputGroup.Text>
                            </InputGroup.Append>
                          </InputGroup>
                        </FormGroup>
                      </CardBody>
                    </Accordion.Collapse>
                  </Card>
                </Accordion>
                <Accordion activeKey={currentAccordionKey}>
                  <Card>
                    <ReportAccordionToggle
                      eventKey={MerchantReportV2Sections.FeeBreakdown}
                      callback={handleAccordionClick}
                      setCurrentAccordionKey={setCurrentAccordionKey}
                    >
                      {MerchantReportV2Sections.FeeBreakdown}
                      <ReportSectionIncludedLabel
                        section={MerchantReportV2Sections.FeeBreakdown}
                        reportPages={reportPages}
                        handleReportPageToggle={handleReportPageToggle}
                      />
                    </ReportAccordionToggle>
                    <Accordion.Collapse
                      eventKey={MerchantReportV2Sections.FeeBreakdown}
                    >
                      <CardBody>
                        <FormGroup>
                          <Label for="InterchangePercentage">
                            Interchange fees
                          </Label>
                          <InputGroup className="align-items-center">
                            <CurrencyInput
                              name="InterchangePercentage"
                              placeholder="0.00"
                              decimalsLimit={2}
                              decimalScale={2}
                              value={reportForm?.InterchangePercentage}
                              className="form-control"
                              onValueChange={(value, name, values) => {
                                handleInputChange(
                                  { target: { value: values.float, name } },
                                  name,
                                  [WidgetTypes.FBCW],
                                  FBCWData,
                                  MerchantReportV2Sections.FeeBreakdown
                                );
                              }}
                            />
                            <InputGroup.Append>
                              <InputGroup.Text>%</InputGroup.Text>
                            </InputGroup.Append>
                          </InputGroup>
                        </FormGroup>
                        <FormGroup>
                          <Label for="NetworkFeesPercentage">
                            Network fees
                          </Label>
                          <InputGroup className="align-items-center">
                            <CurrencyInput
                              name="NetworkFeesPercentage"
                              placeholder="0.00"
                              decimalsLimit={2}
                              decimalScale={2}
                              value={reportForm?.NetworkFeesPercentage}
                              className="form-control"
                              onValueChange={(value, name, values) => {
                                handleInputChange(
                                  { target: { value: values.float, name } },
                                  name,
                                  [WidgetTypes.FBCW],
                                  FBCWData,
                                  MerchantReportV2Sections.FeeBreakdown
                                );
                              }}
                            />
                            <InputGroup.Append>
                              <InputGroup.Text>%</InputGroup.Text>
                            </InputGroup.Append>
                          </InputGroup>
                        </FormGroup>
                        <FormGroup className="position-relative">
                          <Label for="BankFeesPercentage">
                            Comerica bank fees
                          </Label>
                          <InputGroup className="align-items-center">
                            <CurrencyInput
                              name="BankFeesPercentage"
                              placeholder="0.00"
                              decimalsLimit={2}
                              decimalScale={2}
                              value={reportForm?.BankFeesPercentage}
                              className="form-control"
                              onValueChange={(value, name, values) => {
                                handleInputChange(
                                  { target: { value: values.float, name } },
                                  name,
                                  [WidgetTypes.FBCW],
                                  FBCWData,
                                  MerchantReportV2Sections.FeeBreakdown
                                );
                              }}
                            />
                            <InputGroup.Append>
                              <InputGroup.Text>%</InputGroup.Text>
                            </InputGroup.Append>
                          </InputGroup>
                          <div className="position-absolute right-0 top-0">
                            <FormCheck
                              type="switch"
                              custom={true}
                              label="Enabled"
                              checked={isComericaFeesWidgetEnabled()?.enabled}
                              id="toggleSVBFees"
                              name="toggleSVBFees"
                              onChange={handleComericaBankFeesToggle}
                            />
                          </div>
                        </FormGroup>
                      </CardBody>
                    </Accordion.Collapse>
                  </Card>
                </Accordion>
                <Accordion activeKey={currentAccordionKey}>
                  <Card>
                    <ReportAccordionToggle
                      eventKey={MerchantReportV2Sections.RefundsAndChargebacks}
                      callback={handleAccordionClick}
                      setCurrentAccordionKey={setCurrentAccordionKey}
                    >
                      {MerchantReportV2Sections.RefundsAndChargebacks}
                      <ReportSectionIncludedLabel
                        section={MerchantReportV2Sections.RefundsAndChargebacks}
                        reportPages={reportPages}
                        handleReportPageToggle={handleReportPageToggle}
                      />
                    </ReportAccordionToggle>
                    <Accordion.Collapse
                      eventKey={MerchantReportV2Sections.RefundsAndChargebacks}
                    >
                      <CardBody>
                        <FormGroup>
                          <Label for="ChargebackPercentage">
                            Chargeback Ratio
                          </Label>
                          <InputGroup className="align-items-center">
                            <CurrencyInput
                              name="ChargebackPercentage"
                              placeholder="0.00"
                              decimalsLimit={2}
                              decimalScale={2}
                              value={reportForm?.ChargebackPercentage}
                              className="form-control"
                              onValueChange={(value, name, values) => {
                                handleInputChange(
                                  { target: { value: values.float, name } },
                                  name,
                                  [WidgetTypes.APW],
                                  RefundAndChargebacksAmountProcessedData,
                                  MerchantReportV2Sections.RefundsAndChargebacks
                                );
                              }}
                            />
                            <InputGroup.Append>
                              <InputGroup.Text>%</InputGroup.Text>
                            </InputGroup.Append>
                          </InputGroup>
                        </FormGroup>
                        <FormGroup>
                          <Label for="NetSales"># of Chargebacks</Label>
                          <InputGroup className="align-items-center">
                            <InputGroup.Prepend>
                              <InputGroup.Text>#</InputGroup.Text>
                            </InputGroup.Prepend>
                            <CurrencyInput
                              name="ChargebackCount"
                              placeholder="0"
                              value={
                                formatCurrencyField(
                                  reportForm.ChargebackCount
                                ) || ''
                              }
                              className="form-control"
                              onValueChange={(value, name, values) => {
                                handleInputChange(
                                  { target: { value: values.float, name } },
                                  name,
                                  [WidgetTypes.APW],
                                  RefundAndChargebacksAmountProcessedData,
                                  MerchantReportV2Sections.RefundsAndChargebacks
                                );
                              }}
                            />
                          </InputGroup>
                        </FormGroup>
                        <FormGroup>
                          <Label for="ChargebackDollars">Chargebacks ($)</Label>
                          <InputGroup className="align-items-center">
                            <InputGroup.Prepend>
                              <InputGroup.Text>$</InputGroup.Text>
                            </InputGroup.Prepend>
                            <CurrencyInput
                              name="ChargebackDollars"
                              placeholder="0"
                              value={
                                formatCurrencyField(
                                  reportForm.ChargebackDollars?.toFixed(2)
                                ) || ''
                              }
                              className="form-control"
                              onValueChange={(value, name, values) => {
                                handleInputChange(
                                  { target: { value: values.float, name } },
                                  name,
                                  [WidgetTypes.APW],
                                  RefundAndChargebacksAmountProcessedData,
                                  MerchantReportV2Sections.RefundsAndChargebacks
                                );
                              }}
                            />
                          </InputGroup>
                        </FormGroup>
                        <FormGroup>
                          <Label for="AverageChargeback">
                            Average Chargeback ($)
                          </Label>
                          <InputGroup className="align-items-center">
                            <InputGroup.Prepend>
                              <InputGroup.Text>$</InputGroup.Text>
                            </InputGroup.Prepend>
                            <CurrencyInput
                              name="AverageChargeback"
                              placeholder="0"
                              value={
                                formatCurrencyField(
                                  reportForm.AverageChargeback?.toFixed(2)
                                ) || ''
                              }
                              className="form-control"
                              onValueChange={(value, name, values) => {
                                handleInputChange(
                                  { target: { value: values.float, name } },
                                  name,
                                  [WidgetTypes.APW],
                                  RefundAndChargebacksAmountProcessedData,
                                  MerchantReportV2Sections.RefundsAndChargebacks
                                );
                              }}
                            />
                          </InputGroup>
                        </FormGroup>
                      </CardBody>
                    </Accordion.Collapse>
                  </Card>
                </Accordion>
                <Accordion activeKey={currentAccordionKey}>
                  <Card>
                    <ReportAccordionToggle
                      eventKey={MerchantReportV2Sections.Recommendations}
                      callback={handleAccordionClick}
                      setCurrentAccordionKey={setCurrentAccordionKey}
                    >
                      {MerchantReportV2Sections.Recommendations}
                      <ReportSectionIncludedLabel
                        section={MerchantReportV2Sections.Recommendations}
                        reportPages={reportPages}
                        handleReportPageToggle={handleReportPageToggle}
                      />
                    </ReportAccordionToggle>
                    <Accordion.Collapse
                      eventKey={MerchantReportV2Sections.Recommendations}
                    >
                      <CardBody>
                        <FormGroup>
                          <Label for="furtherRecommendationsText">
                            Add More Recommendations
                          </Label>
                          <textarea
                            value={reportForm?.furtherRecommendationsText}
                            className="form-control"
                            onChange={handleFreeFormTextChange}
                            rows={4}
                          />
                        </FormGroup>
                      </CardBody>
                    </Accordion.Collapse>
                  </Card>
                </Accordion>
                <Accordion activeKey={currentAccordionKey}>
                  <Card>
                    <ReportAccordionToggle
                      eventKey={MerchantReportV2Sections.YourDedicatedTeam}
                      callback={handleAccordionClick}
                      setCurrentAccordionKey={setCurrentAccordionKey}
                    >
                      {MerchantReportV2Sections.YourDedicatedTeam}
                      <ReportSectionIncludedLabel
                        section={MerchantReportV2Sections.YourDedicatedTeam}
                        reportPages={reportPages}
                        handleReportPageToggle={handleReportPageToggle}
                      />
                    </ReportAccordionToggle>
                    <Accordion.Collapse
                      eventKey={MerchantReportV2Sections.YourDedicatedTeam}
                    >
                      <CardBody>
                        <FormGroup>
                          {reportContacts?.length > 0 ? (
                            <>
                              <div className="text-right">
                                <AddTeamContactButton />
                              </div>
                              {reportContacts.map((acnt) => (
                                <BankContact
                                  key={acnt.id}
                                  acnt={acnt}
                                  handleDeleteBankContact={() =>
                                    handleDeleteBankContact(acnt)
                                  }
                                  showEmailContact={false}
                                  hideEdit={true}
                                />
                              ))}
                            </>
                          ) : (
                            <NoDataFound
                              title="No team contacts."
                              description={<AddTeamContactButton />}
                              icon="people"
                              containerStyle="text-gray-900"
                            />
                          )}
                        </FormGroup>
                      </CardBody>
                    </Accordion.Collapse>
                  </Card>
                </Accordion>
                <Accordion activeKey={currentAccordionKey}>
                  <Card>
                    <ReportAccordionToggle
                      eventKey={MerchantReportV2Sections.Disclaimer}
                      callback={handleAccordionClick}
                      dontExpand={true}
                      setCurrentAccordionKey={setCurrentAccordionKey}
                    >
                      {MerchantReportV2Sections.Disclaimer}
                      <ReportSectionIncludedLabel
                        section={MerchantReportV2Sections.Disclaimer}
                        reportPages={reportPages}
                        handleReportPageToggle={handleReportPageToggle}
                      />
                    </ReportAccordionToggle>
                  </Card>
                </Accordion>
              </FormGroup>
            </div>
          </Col>
          <Col
            md={7}
            className={`bg-white treasury-modal position-relative overflow-x-hidden pb-3 pl-0 ${
              currentAccordionKey === null
                ? 'd-flex align-items-center justify-content-center'
                : ''
            }`}
          >
            {currentAccordionKey === null && (
              <NoDataFound
                title="No page selected"
                description="Please expand a page menu from left to edit values"
                icon="analytics"
                containerStyle="text-gray-900"
              />
            )}
            <div className="py-2 px-3">
              <div>
                {currentAccordionKey ===
                  MerchantReportV2Sections.CardPaymentAcceptanceOverview && (
                  <WidgetsBySection
                    icon="price_check"
                    widgets={widgets}
                    section={
                      MerchantReportV2Sections.CardPaymentAcceptanceOverview
                    }
                    onAddWidget={onAddWidget}
                    reportPages={reportPages}
                    onDeleteWidget={onDeleteWidget}
                    setSelectedWidget={setSelectedWidget}
                    setShowWidgetsLibrary={setShowWidgetsLibrary}
                    handleReportPageToggle={handleReportPageToggle}
                  />
                )}
                {currentAccordionKey ===
                  MerchantReportV2Sections.FeeBreakdown && (
                  <WidgetsBySection
                    icon="price_check"
                    widgets={widgets}
                    section={MerchantReportV2Sections.FeeBreakdown}
                    onAddWidget={onAddWidget}
                    reportPages={reportPages}
                    onDeleteWidget={onDeleteWidget}
                    setSelectedWidget={setSelectedWidget}
                    setShowWidgetsLibrary={setShowWidgetsLibrary}
                    handleReportPageToggle={handleReportPageToggle}
                  />
                )}
                {currentAccordionKey ===
                  MerchantReportV2Sections.RefundsAndChargebacks && (
                  <WidgetsBySection
                    icon="price_check"
                    widgets={widgets}
                    section={MerchantReportV2Sections.RefundsAndChargebacks}
                    onAddWidget={onAddWidget}
                    reportPages={reportPages}
                    onDeleteWidget={onDeleteWidget}
                    setSelectedWidget={setSelectedWidget}
                    setShowWidgetsLibrary={setShowWidgetsLibrary}
                    handleReportPageToggle={handleReportPageToggle}
                  />
                )}
                {currentAccordionKey ===
                  MerchantReportV2Sections.Recommendations && (
                  <WidgetsBySection
                    icon="price_check"
                    widgets={widgets}
                    section={MerchantReportV2Sections.Recommendations}
                    onAddWidget={onAddWidget}
                    reportPages={reportPages}
                    onDeleteWidget={onDeleteWidget}
                    setSelectedWidget={setSelectedWidget}
                    setShowWidgetsLibrary={setShowWidgetsLibrary}
                    handleReportPageToggle={handleReportPageToggle}
                  />
                )}
                {currentAccordionKey ===
                  MerchantReportV2Sections.YourDedicatedTeam && (
                  <WidgetsBySection
                    icon="price_check"
                    widgets={widgets}
                    section={MerchantReportV2Sections.YourDedicatedTeam}
                    onAddWidget={onAddWidget}
                    reportPages={reportPages}
                    onDeleteWidget={onDeleteWidget}
                    setSelectedWidget={setSelectedWidget}
                    setShowWidgetsLibrary={setShowWidgetsLibrary}
                    handleReportPageToggle={handleReportPageToggle}
                  />
                )}
                {currentAccordionKey ===
                  MerchantReportV2Sections.Disclaimer && (
                  <WidgetsBySection
                    icon="price_check"
                    widgets={widgets}
                    section={MerchantReportV2Sections.Disclaimer}
                    onAddWidget={onAddWidget}
                    reportPages={reportPages}
                    onDeleteWidget={onDeleteWidget}
                    setSelectedWidget={setSelectedWidget}
                    setShowWidgetsLibrary={setShowWidgetsLibrary}
                    handleReportPageToggle={handleReportPageToggle}
                  />
                )}
              </div>
            </div>
          </Col>
        </Row>
      </SimpleModalCreation>
    </>
  );
};

const useGenerateMerchantServicesReportModal = (
  report,
  organization,
  modalReportPages,
  modalWidgets,
  handleGenerateReport,
  getOrganization
) => {
  const [showModal, setShowModal] = useState(false);
  const [selectedReport, setSelectedReport] = useState({});
  const [widgets, setWidgets] = useState({});
  const [reportPages, setReportPages] = useState({});

  const GenerateMerchantServicesReportModalCallback = useCallback(() => {
    return (
      <GenerateMerchantServicesReportModal
        show={showModal}
        setShow={setShowModal}
        selectedReport={selectedReport}
        setSelectedReport={setSelectedReport}
        organization={organization}
        modalReportPages={reportPages}
        modalWidgets={widgets}
        handleGenerateReport={handleGenerateReport}
        getOrganization={getOrganization}
      />
    );
  }, [showModal, setShowModal, selectedReport, setSelectedReport]);

  return useMemo(
    () => ({
      setShowModal,
      setSelectedReport,
      setModalWidgets: setWidgets,
      setModalReportPages: setReportPages,
      GenerateMerchantServicesReportModal:
        GenerateMerchantServicesReportModalCallback,
    }),
    [setShowModal, GenerateMerchantServicesReportModalCallback]
  );
};

export default useGenerateMerchantServicesReportModal;
