/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';
import { Accordion, Row, Col } from 'react-bootstrap';

import { ReportSkeletonLoader } from './ReportSkeletonLoader';
import NoDataFound from '../../components/commons/NoDataFound';
import { useTenantContext } from '../../contexts/TenantContext';
import { isMatchInCommaSeparated, scrollToTop } from '../../utils/Utils';
import ChecklistsReport from './reportRenders/checklist/ChecklistsReport';
import ChecklistsOverview from './reportRenders/checklist/ChecklistsOverview';
import TextOverflowTooltip from '../../components/commons/TextOverflowTooltip';
import TrainingLessonStatistics from './reportRenders/training/TrainingLessonStatistics';
import TrainingTopLessons from './reportRenders/training/TrainingTopLessons';
import TrainingLessonTranscript from './reportRenders/training/TrainingLessonTranscript';
import TrainingLeaderboard from './reportRenders/training/leaderboard/TrainingLeaderboard';
import { TrainingAssignmentDetails } from './reportRenders/training/TrainingAssignmentDetails';
import TrainingLessonDetails from './reportRenders/training/lessonDetails/TrainingLessonDetails';
import TrainingUserTranscript from './reportRenders/training/userTranscript/TrainingUserTranscript';
import { TrainingAssignmentStatistics } from './reportRenders/training/TrainingAssignmentStatistics';
import { TrainingAssignmentTeamSnapshot } from './reportRenders/training/TrainingAssignmentTeamSnapshot';
import {
  NO_REPORTS_AVAILABLE,
  NO_REPORT_SELECTED,
} from '../../utils/constants';
import Overview from '../../components/chat/reports/Overview';
import ConversationalLog from '../../components/chat/reports/ConversationalLog';
import LucideIcon from '../../components/commons/LucideIcon';
import MonthlySalesPerformanceSummary from './reportRenders/pipelines/MonthlySalesPerformanceSummary';
import IndividualSalesOverview from './reportRenders/pipelines/IndividualSalesOverview';
import ProductSalesSnapshot from './reportRenders/pipelines/ProductSalesSnapshot';
import PipelinePerformanceOverview from './reportRenders/pipelines/PipelinePerformanceOverview';
import OpportunitiesPerformanceOverview from './reportRenders/pipelines/OpportunitiesPerformanceOverview';
import useIsTenant from '../../hooks/useIsTenant';
import { useModuleContext } from '../../contexts/moduleContext';
import { TrainingAssignmentReports } from './reportRenders/training/TrainingAssignmentReports';

const { isDemo } = useIsTenant();

const InsightReports = () => {
  const [reports, setReports] = useState([]);
  const [selectedSubReport, setSelectedSubReport] = useState({});
  const [loading] = useState(false);
  const { tenant } = useTenantContext();
  const { moduleMap } = useModuleContext();

  const REPORTS = [
    {
      id: 'Checklists',
      label: 'Checklists',
      icon: 'ListChecks',
      subReports: [
        {
          id: 'ChecklistsOverview',
          name: 'Checklists Overview',
        },
        {
          id: 'ChecklistsReport',
          name: 'Checklists Report',
        },
      ],
    },
    ...(isDemo
      ? [
          {
            id: 'Opportunities',
            label: moduleMap.deal.plural,
            icon: 'CircleDollarSign',
            subReports: [
              {
                id: 'OpportunitiesPerformanceOverview',
                name: 'Performance Overview',
              },
              {
                id: 'MonthlySalesPerformanceSummary',
                name: 'Monthly Sales Performance Summary',
              },
              {
                id: 'IndividualSalesOverview',
                name: 'Individual Sales Overview',
              },
              {
                id: 'ProductSalesSnapshot',
                name: 'Product Sales Snapshot',
              },
              {
                id: 'PipelinePerformanceOverview',
                name: 'Pipeline Performance Overview',
              },
            ],
          },
        ]
      : [
          {
            id: 'Opportunities',
            label: moduleMap.deal.plural,
            icon: 'CircleDollarSign',
            subReports: [
              {
                id: 'ProductSalesSnapshot',
                name: 'Product Sales Snapshot',
              },
              {
                id: 'IndividualSalesOverview',
                name: 'Individual Sales Overview',
              },
            ],
          },
        ]),
    {
      id: 'Training',
      label: 'Learn',
      icon: 'GraduationCap',
      subReports: [
        {
          id: 'TrainingLeaderboard',
          name: 'Leaderboard: Lessons Completed',
        },
        {
          id: 'TrainingAssignmentTeamSnapshot',
          name: 'Assignments Team Snapshot',
        },
        {
          id: 'TrainingAssignmentStatistics',
          name: 'Assignment Statistics',
        },
        {
          id: 'TrainingAssignmentDetails',
          name: 'Assignment Details',
        },
        {
          id: 'TrainingAssignmentReports',
          name: 'Assignment Report',
        },
        {
          id: 'TrainingLessonDetails',
          name: 'Lesson Details',
        },
        {
          id: 'TrainingLessonStatistics',
          name: 'Lesson Statistics',
        },
        {
          id: 'TrainingUserTranscript',
          name: 'User Transcript',
        },
        {
          id: 'TrainingTopLessons',
          name: 'Top Lessons',
        },
        {
          id: 'TrainingLessonTranscript',
          name: 'Lesson Transcript',
        },
      ],
    },
    ...(isDemo
      ? [
          {
            id: 'ida',
            label: 'Ida',
            icon: 'Sparkles',
            subReports: [
              {
                id: 'Overview',
                name: 'Overview',
              },
              {
                id: 'ConversationalLog',
                name: 'Conversational Log',
              },
            ],
          },
        ]
      : []),
  ];

  const [selectedReport, setSelectedReport] = useState(REPORTS[0]);

  useEffect(() => {
    if (!tenant) {
      return setReports([]);
    }
    if (tenant.modules === '*') {
      return setReports(REPORTS);
    }

    const availableReports = REPORTS.filter(({ id }) => {
      return isMatchInCommaSeparated(tenant.modules, `reporting_${id}`);
    });

    setReports(availableReports);
  }, [tenant]);

  const handleSubReportSelection = (subReport) => {
    setSelectedSubReport(subReport);
    scrollToTop();
  };

  const renderCollapseItems = (report) => {
    if (!report?.subReports?.length) return null;
    return (
      <>
        {report.subReports.map((subReport) => (
          <Row
            key={subReport.id}
            onClick={() => handleSubReportSelection(subReport)}
            className={`cursor-pointer accordion-sub-menu align-items-center px-4_1 py-2 nav-link item-filter ${
              selectedSubReport?.id === subReport?.id
                ? 'text-primary active bg-primary-soft fw-600'
                : ''
            }`}
          >
            <Col className="px-2_1">
              <p className="d-flex align-items-center py-0 px-0 my-0 gap-2">
                <span className="font-weight-medium font-size-sm2 mb-0">
                  <TextOverflowTooltip
                    textStyle="font-weight-medium text-truncate font-size-sm2 mb-0"
                    text={subReport?.name}
                    maxLength={28}
                  />
                </span>
              </p>
            </Col>
          </Row>
        ))}
      </>
    );
  };

  const userDashboardComponents = {
    TrainingLeaderboard: (props) => <TrainingLeaderboard {...props} />,
    TrainingAssignmentTeamSnapshot: (props) => (
      <TrainingAssignmentTeamSnapshot {...props} />
    ),
    TrainingAssignmentStatistics: (props) => (
      <TrainingAssignmentStatistics {...props} />
    ),
    TrainingAssignmentDetails: (props) => (
      <TrainingAssignmentDetails {...props} />
    ),
    TrainingAssignmentReports: (props) => (
      <TrainingAssignmentReports {...props} />
    ),
    TrainingLessonDetails: (props) => <TrainingLessonDetails {...props} />,
    TrainingLessonStatistics: (props) => (
      <TrainingLessonStatistics {...props} />
    ),
    TrainingUserTranscript: (props) => <TrainingUserTranscript {...props} />,
    TrainingTopLessons: (props) => <TrainingTopLessons {...props} />,
    TrainingLessonTranscript: (props) => (
      <TrainingLessonTranscript {...props} />
    ),
    ChecklistsOverview: (props) => <ChecklistsOverview {...props} />,
    ChecklistsReport: (props) => <ChecklistsReport {...props} />,
    Overview: (props) => <Overview {...props} />,
    ConversationalLog: (props) => <ConversationalLog {...props} />,
    OpportunitiesPerformanceOverview: (props) => (
      <OpportunitiesPerformanceOverview {...props} />
    ),
    MonthlySalesPerformanceSummary: (props) => (
      <MonthlySalesPerformanceSummary {...props} />
    ),
    IndividualSalesOverview: (props) => <IndividualSalesOverview {...props} />,
    ProductSalesSnapshot: (props) => <ProductSalesSnapshot {...props} />,
    PipelinePerformanceOverview: (props) => (
      <PipelinePerformanceOverview {...props} />
    ),
  };

  return (
    <div className="overflow-x-hidden">
      <div className="mt-0 custom-layout overflow-x-hidden">
        <div className="custom-layout-sidebar overflow-x-hidden">
          <div className="d-flex overflow-y-auto overflow-x-hidden flex-column p-3">
            {loading ? (
              <ReportSkeletonLoader rows={5} height={30} />
            ) : (
              <ul className="list-unstyled mb-0 transparent-scroll-track">
                {reports.map((report, index) => {
                  return (
                    <li key={index} className="accordion-wrapper">
                      <Accordion
                        defaultActiveKey={reports?.[0]?.id || String(index)}
                        activeKey={selectedReport?.id}
                      >
                        <Accordion.Toggle
                          onClick={() => setSelectedReport(report)}
                          eventKey={report.id}
                          className={`${
                            selectedReport?.id === report.id
                              ? 'bg-accordion-active rounded-top rounded-bottom-0'
                              : 'bg-white'
                          } accordion-toggle nav-link px-3 py-2 border-bottom cursor-pointer font-size-sm2 font-weight-medium text-black text-capitalize`}
                        >
                          <div className="d-flex align-items-center">
                            <LucideIcon icon={report.icon} size={18} />
                            <span className="ml-2 text-capitalize py-0">
                              {report.label}
                            </span>

                            <span className="ml-auto">
                              <LucideIcon
                                icon={
                                  selectedReport?.id === report.id
                                    ? 'ChevronUp'
                                    : 'ChevronDown'
                                }
                              />
                            </span>
                          </div>
                        </Accordion.Toggle>
                        <Accordion.Collapse
                          eventKey={report.id}
                          className="accordion-content-wrapper"
                        >
                          <div className="py-0 overflow-x-hidden">
                            {
                              <>
                                {loading ? (
                                  <ReportSkeletonLoader rows={15} />
                                ) : (
                                  renderCollapseItems(report) || (
                                    <NoDataFound
                                      title={
                                        <div className="text-gray-search font-normal font-size-sm2">
                                          {NO_REPORTS_AVAILABLE}
                                        </div>
                                      }
                                      useLucidIcon
                                      icon="ChartColumn"
                                      iconSize="22"
                                      containerStyle="w-100 text-muted h-100"
                                    />
                                  )
                                )}
                              </>
                            }
                          </div>
                        </Accordion.Collapse>
                      </Accordion>
                    </li>
                  );
                })}
              </ul>
            )}
          </div>
        </div>
        <div className="custom-layout-content p-0 pl-0 bg-white flex-fill position-relative right-panel-setting">
          {Object.keys(selectedSubReport)?.length > 0 ? (
            userDashboardComponents[selectedSubReport?.id]({
              dashboard: selectedSubReport,
              selectedComponent: selectedSubReport,
              insightName: selectedSubReport.name,
            })
          ) : (
            <NoDataFound
              title={<div className="text-muted">{NO_REPORT_SELECTED}</div>}
              useLucidIcon
              icon="ChartColumn"
              iconSize="52"
              containerStyle="w-100 height-300 text-muted"
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default InsightReports;
