import React from 'react';
import { TabContent, TabPane } from 'reactstrap';
import Organizations from '../views/Deals/contacts/Organizations';

const TabTitle = ({ icon, title }) => (
  <div className="d-flex align-items-center tab-title">
    <span className="material-symbols-rounded fs-5 m-1 ">{icon}</span>
    <span>{title}</span>
  </div>
);

const OrganizationsPage = ({
  selectedOwnership,
  setSelectedOwnership,
  stats,
  isViewMore,
  setIsViewMore,
  setShowAdvancedFilters,
}) => {
  const tabsData = {
    title: <TabTitle title="Organizations" />,
    component: (
      <Organizations
        selectedOwnership={selectedOwnership}
        setSelectedOwnership={setSelectedOwnership}
        stats={stats}
        isViewMore={isViewMore}
        setIsViewMore={setIsViewMore}
        setShowAdvancedFilters={setShowAdvancedFilters}
      />
    ),
    tabId: 1,
  };

  return (
    <TabContent className="h-100">
      <TabPane className="position-relative h-100">
        {tabsData.component}
      </TabPane>
    </TabContent>
  );
};

export default OrganizationsPage;
