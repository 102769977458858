import { Card, CardBody } from 'reactstrap';
import React from 'react';
import WidgetSourceBlock from '../../WidgetSourceBlock';
import MaterialIcon from '../../../commons/MaterialIcon';
import {
  getDifferenceWidgetVisibility,
  isToFixedNoRound,
  parseCurrency,
} from '../../../../utils/Utils';
import TheDifferenceWidget from '../TheDifferenceWidget';

const PaperToElectronicOpportunityWidget = ({
  widgetConfig,
  whenPrinting,
  selectedTenant,
}) => {
  return (
    <>
      {!getDifferenceWidgetVisibility(widgetConfig) ? null : (
        <Card
          className={`report-widget peo-widget ${
            !getDifferenceWidgetVisibility(widgetConfig) ? 'disabled' : ''
          }`}
        >
          <CardBody className="d-flex align-items-center justify-content-center">
            <div className="flex-fill">
              <h5 className="text-left mb-1 d-flex align-items-center gap-1">
                <TheDifferenceWidget selectedTenant={selectedTenant} />
              </h5>
              <div className="px-2">
                <div className={`position-relative mb-2 py-3 rpt-green-box`}>
                  <div>
                    <div
                      className={`d-flex align-items-center mb-2 justify-content-center gap-1`}
                    >
                      <div className="d-flex align-items-center justify-content-center border border-green rounded-circle p-1">
                        <MaterialIcon
                          icon="paid"
                          size="fs-6"
                          clazz="font-weight-medium text-rpt-green"
                        />
                      </div>
                      <h1 className={`text-green-rpt font-weight-bold mb-0`}>
                        {isToFixedNoRound(
                          parseCurrency(widgetConfig?.data?.opportunity),
                          0
                        )?.replace('$', '')}
                      </h1>
                    </div>
                    <p className="fs-9 mb-0 text-rpt-heading text-center font-weight-normal">
                      Estimated annual value of moving <b>30%</b> of checks to
                      electronic payments.
                    </p>
                  </div>
                </div>
                <div className="d-flex align-items-center text-rpt-heading justify-content-center gap-1">
                  <span className="fs-10">
                    Moving to electronic payments will increase working capital
                    efficiency and reduce risk and expense.
                  </span>
                </div>
                {widgetConfig?.source && (
                  <WidgetSourceBlock text={widgetConfig.source} />
                )}
              </div>
            </div>
          </CardBody>
        </Card>
      )}
    </>
  );
};

export default PaperToElectronicOpportunityWidget;
