import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { overflowing } from '../../../utils/Utils';
import SimpleModalCreation from '../../modal/SimpleModalCreation';
import { useForm } from 'react-hook-form';
import Alert from '../../Alert/Alert';
import AlertWrapper from '../../Alert/AlertWrapper';
import ChatService from '../../../services/chat.service';
import { FormGroup } from 'react-bootstrap';
import useFocusInput from '../../commons/useFocusInput';
import InputValidation from '../../commons/InputValidation';

const RenameChatModal = ({
  show,
  setShow,
  chat,
  onSuccess,
  setErrorMessage,
  setSuccessMessage,
}) => {
  const {
    register,
    setValue,
    clearErrors,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: { title: '' },
  });
  const [saving, setSaving] = useState(false);
  const [data, setData] = useState({ title: '' });
  const inputRef = useRef(null);

  const handleClose = () => {
    setShow(false);
    overflowing();
  };
  const handleOnChange = (e) => {
    const { name, value } = e.target;
    setData({
      ...data,
      [name]: value,
    });
    setValue(name, value);
    clearErrors(name);
  };

  const onSubmit = async () => {
    try {
      setSaving(true);
      const updatedChat = { chatId: chat.chatId, title: data.title };
      await ChatService.updateChat(chat.chatId, { title: updatedChat.title });
      setSuccessMessage('Chat Updated');
      onSuccess(updatedChat);
      handleClose();
    } catch (error) {
      setErrorMessage(error.message);
    } finally {
      setSaving(false);
    }
  };

  useEffect(() => {
    if (show) {
      setData({ title: chat.title });
    }
  }, [show, chat.title]);

  useFocusInput(inputRef, [show, data]);

  return (
    <SimpleModalCreation
      modalHeaderClasses="flex-fill"
      modalTitle="Rename this chat"
      saveButtonStyle="btn btn-primary btn-sm d-flex align-items-center"
      open={show}
      editFields={true}
      isLoading={saving}
      footerStyle="border-0"
      handleSubmit={handleSubmit(onSubmit)}
      onHandleCloseModal={handleClose}
    >
      <div className="d-flex flex-column gap-3">
        <FormGroup>
          <InputValidation
            name="title"
            ref={inputRef}
            itemRef={inputRef}
            id="title"
            type="input"
            placeholder="Title"
            value={data.title || ''}
            errorDisplay="mb-0"
            autoFocus
            classNames="mr-2"
            validationConfig={{
              required: 'Title is required.',
              validateSpaces: true,
              inline: false,
              borderLeft: true,
              onChange: handleOnChange,
            }}
            errors={errors}
            register={register}
          />
        </FormGroup>
      </div>
    </SimpleModalCreation>
  );
};

const useRenameChatModal = (onSuccess = () => {}) => {
  const [showModal, setShowModal] = useState(false);
  const [chat, setChat] = useState({});
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const RenameChatModalCallback = useCallback(() => {
    return (
      <>
        <AlertWrapper>
          {successMessage && (
            <Alert
              color="success"
              message={successMessage}
              setMessage={setSuccessMessage}
            />
          )}
          {errorMessage && (
            <Alert
              color="danger"
              message={errorMessage}
              setMessage={setErrorMessage}
            />
          )}
        </AlertWrapper>
        <RenameChatModal
          show={showModal}
          setShow={setShowModal}
          chat={chat}
          onSuccess={onSuccess}
          setSuccessMessage={setSuccessMessage}
          setErrorMessage={setErrorMessage}
        />
      </>
    );
  }, [
    showModal,
    chat,
    successMessage,
    errorMessage,
    setSuccessMessage,
    setErrorMessage,
    onSuccess,
  ]);

  return useMemo(
    () => ({
      setChat,
      setShowModal,
      RenameChatModal: RenameChatModalCallback,
    }),
    [setShowModal, RenameChatModalCallback]
  );
};

export default useRenameChatModal;
