import React, { useContext, useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import { TabsContext } from '../contexts/tabsContext';
import ProspectSearch from './Prospects-rocket';
import PageTitle from '../components/commons/PageTitle';
import useUrlSearchParams from '../hooks/useUrlSearchParams';
import { useTenantContext } from '../contexts/TenantContext';
import { isModuleAllowed } from '../utils/Utils';
import { PermissionsConstants } from '../utils/permissions.constants';
import { ProspectTypes } from '../components/prospecting/v2/constants';

const TAB_KEYS = {
  organization: 1,
  people: 2,
  domain: 3,
};
const ProspectingTabs = {
  Prospects: 1,
  Write: 2,
  Summarizer: 3,
};

const CompanyTab = {
  tabId: TAB_KEYS.organization,
  id: TAB_KEYS.organization,
  title: 'Companies',
  label: 'Companies',
  byModule: PermissionsConstants.Resources.ProspectingCompany,
};

const PeopleTab = {
  id: TAB_KEYS.people,
  tabId: TAB_KEYS.people,
  title: 'People',
  label: 'People',
  byModule: PermissionsConstants.Resources.ProspectingPeople,
};

const ProspectTabs = {
  [TAB_KEYS.people]: ProspectTypes.people,
  [TAB_KEYS.organization]: ProspectTypes.company,
};
const Resrcs = () => {
  const params = useUrlSearchParams();
  const history = useHistory();
  const viewType = params?.get('viewType');
  const location = useLocation();
  const { tenant } = useTenantContext();
  const [activeTab, setActiveTab] = useState(ProspectingTabs.Prospects);
  const tabsData = [CompanyTab, PeopleTab];
  const { activatedTab, setActivatedTab } = useContext(TabsContext);
  const [, setProspect] = useState({});
  const updatedTabs = tabsData?.filter((item) => {
    return isModuleAllowed(tenant?.modules, item?.byModule);
  });
  const isCompanyAllowed = isModuleAllowed(
    tenant?.modules,
    PermissionsConstants.Resources.ProspectingCompany
  );

  const isPeopleAllowed = isModuleAllowed(
    tenant?.modules,
    PermissionsConstants.Resources.ProspectingPeople
  );
  const [activeChildTab, setActiveChildTab] = useState(
    isCompanyAllowed
      ? TAB_KEYS.organization
      : isPeopleAllowed
      ? TAB_KEYS.people
      : ''
  );

  const updateCurrentTabInUrl = (tabId) => {
    const searchParams = new URLSearchParams(location.search);
    searchParams.set('tab', ProspectTabs[tabId]);
    return searchParams.toString();
  };
  useEffect(() => {
    if (activatedTab && activatedTab[location.pathname]) {
      setActiveTab(ProspectingTabs.Prospects);
    }
    return () => {
      setActiveTab(ProspectingTabs.Prospects);
      setActivatedTab({
        [location.pathname]: ProspectingTabs.Prospects,
      });
      setProspect({});
    };
  }, [viewType]);

  const toggle = (tab, selectedProspect) => {
    setProspect(selectedProspect);
    if (activeTab !== tab.tabId) {
      setActiveTab(tab.tabId);
      history.replace({
        pathname: location.pathname,
        search: updateCurrentTabInUrl(tab.tabId),
      });
      setActivatedTab({
        [location.pathname]: tab.tabId,
      });
    }
  };

  const toggleTab = (tab) => {
    if (activeChildTab !== tab.tabId) {
      setActiveChildTab(tab.tabId);
      history.replace({
        pathname: location.pathname,
        search: updateCurrentTabInUrl(tab.tabId),
      });
      setActivatedTab({
        [location.pathname]: tab.tabId,
      });
    }
  };

  return (
    <>
      <PageTitle page="Prospecting" />
      <ProspectSearch
        switchTab={toggle}
        activeTab={activeChildTab}
        setActiveTab={setActiveChildTab}
        tabsData={updatedTabs}
        toggleTab={toggleTab}
      />
    </>
  );
};

export default Resrcs;
