import React from 'react';
import LucideIcon from './commons/LucideIcon';
import { useTenantContext } from '../contexts/TenantContext';
import BrandLogoIcon from './sidebar/BrandLogoIcon';

const FallbackComponent = ({ resetError }) => {
  const { tenant } = useTenantContext();
  return (
    <div
      style={{ height: '100vh' }}
      className="d-flex p-5 align-items-center overflow-hidden justify-content-center"
    >
      <div
        className="card shadow-0 bg-transparent border-0"
        style={{ maxWidth: 650 }}
      >
        <div className="card-body d-flex flex-column gap-3 align-items-center">
          <div className="d-flex flex-column align-items-center gap-0">
            <BrandLogoIcon tenant={tenant} width="100%" />
            <h1 className="text-black mt-4 mb-0 font-size-em">
              Something went wrong
            </h1>
            <p className="text-black-50 text-center mt-3 mb-4 mb-0">
              Please try refreshing your browser or report to your{' '}
              {tenant?.name}
              <br />
              administrator if problem remains.
            </p>
          </div>
          <button
            className="btn d-flex align-items-center gap-1 btn-primary"
            onClick={resetError}
          >
            <LucideIcon icon="RefreshCcw" /> Refresh Browser
          </button>
        </div>
      </div>
    </div>
  );
};

export default FallbackComponent;
