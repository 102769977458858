import React, { useCallback, useEffect, useMemo, useState } from 'react';
import {
  checkIfNaicsOrReportDateEmpty,
  cleanDataWidgets,
  formatCurrencyField,
  getRandomUUID,
  NAICS_STORAGE_KEY,
  overflowing,
} from '../../../utils/Utils';
import SimpleModalCreation from '../../../components/modal/SimpleModalCreation';
import {
  getCycleDate,
  updateWidgetBySection,
} from '../../../components/reports/reports.helper.functions';
import {
  ReportFormErrorFields,
  ReportTypes,
  SvbReportSections,
} from '../../../components/reports/reports.constants';
import _ from 'lodash';
import {
  Card,
  CardBody,
  Col,
  FormGroup,
  Label,
  ListGroup,
  Row,
} from 'reactstrap';
import SicNaicsAutoComplete from '../../../components/prospecting/v2/common/SicNaicsAutoComplete';
import ReactDatepicker from '../../../components/inputs/ReactDatpicker';
import useSicNaicsChangeDetect from '../../useSicNaicsChangeDetect';
import { Accordion, FormCheck, InputGroup } from 'react-bootstrap';
import CurrencyInput from 'react-currency-input-field';
// eslint-disable-next-line no-unused-vars
import ReportService from '../../../services/report.service';
import ReportAccordionToggle from '../../../components/reports/ReportAccordionToggle';
import NoDataFound from '../../../components/commons/NoDataFound';
import IconHeadingBlock from '../../../components/reportbuilder/blocks/IconHeadingBlock';
import WidgetWithActionButtons from '../../../components/reportbuilder/widgets/WidgetWithActionButtons';
import {
  CCSWData,
  FCWData,
  ISWData,
  MFWData,
  WidgetTypes,
} from '../../../components/reportbuilder/constants/widgetsConstants';
import ReportSectionIncludedLabel from '../../../components/reports/ReportSectionIncludedLabel';
import useWidgetsLibrary from '../../../components/reportbuilder/widgets/useWidgetsLibrary';
import Alert from '../../../components/Alert/Alert';
import AlertWrapper from '../../../components/Alert/AlertWrapper';
import ValidationErrorText from '../../../components/commons/ValidationErrorText';
import moment from 'moment/moment';
import ReportModalTitle from '../../../components/reports/ReportModalTitle';

const WidgetsBySection = ({
  icon,
  widgets,
  section,
  onAddWidget,
  isMultiple,
  selectedTenant,
  onDeleteWidget,
  setSelectedWidget,
  setShowWidgetsLibrary,
}) => {
  const newWidgets = isMultiple || widgets[section];
  return (
    <>
      <div className="text-left">
        <br />
        <IconHeadingBlock
          heading={_.startCase(section)}
          icon={icon}
          showIcon={false}
          containerStyle="gap-1 justify-content-between px-3 mb-0"
          reportType={ReportTypes.SvbBank}
        />
      </div>
      <ListGroup className="list-group-no-gutters mt-0 list-group-flush">
        {newWidgets?.map((widget, index) => (
          <WidgetWithActionButtons
            key={index}
            widget={widget}
            section={section}
            onAddWidget={onAddWidget}
            onDeleteWidget={onDeleteWidget}
            setSelectedWidget={setSelectedWidget}
            setShowWidgetsLibrary={setShowWidgetsLibrary}
            selectedTenant={selectedTenant}
            actionButtons={{
              remove: { text: 'Remove', icon: 'Trash2' },
              add: { text: 'Replace', icon: 'RefreshCcw' },
            }}
          />
        ))}
      </ListGroup>
    </>
  );
};
const GenerateSvbMerchantReportModal = ({
  show,
  setShow,
  organization,
  modalWidgets,
  selectedReport,
  modalReportPages,
  handleGenerateReport,
  getOrganization,
}) => {
  const [errorFields, setErrorFields] = useState(ReportFormErrorFields);
  const [generating, setGenerating] = useState(false);
  const clonedReport = _.cloneDeep({
    ...selectedReport?.main,
    ...selectedReport?.individual,
    reportDate: getCycleDate(selectedReport?.individual?.reportDate),
  });
  const [reportForm, setReportForm] = useState(clonedReport);
  const { setShowModal, setCompany, NaicsModal } = useSicNaicsChangeDetect(
    selectedReport,
    organization,
    getOrganization
  );
  const [errorMessage, setErrorMessage] = useState('');
  const [currentAccordionKey, setCurrentAccordionKey] = useState(
    SvbReportSections.ControllableCostSavings
  );
  const [widgets, setWidgets] = useState(modalWidgets);
  const [reportPages, setReportPages] = useState(modalReportPages);
  const contacts =
    selectedReport?.main?.aggregate?.contacts ||
    selectedReport?.individual?.contacts ||
    [];
  const [reportContacts, setReportContacts] = useState(contacts);

  const {
    setShowModal: setShowWidgetsLibrary,
    WidgetsLibraryModal,
    setSelectedWidget,
  } = useWidgetsLibrary();
  const handleAccordionClick = (eventKey) => {
    setCurrentAccordionKey(eventKey);
  };

  const updateWidgets = (
    newReportData,
    widgetType,
    widgetConfigData,
    widgetSection
  ) => {
    const newWidgets = updateWidgetBySection(
      widgets,
      widgetSection,
      widgetType,
      newReportData,
      widgetConfigData
    );
    setWidgets(newWidgets);
  };

  const createOrUpdateReport = async () => {
    try {
      if (
        checkIfNaicsOrReportDateEmpty(reportForm, errorFields, setErrorFields)
      ) {
        return;
      }
      setGenerating(true);

      const cleanWidgets = cleanDataWidgets(widgets);

      let newCoreData = selectedReport?.main?.coreData;
      const mainReportId =
        selectedReport?.main?.reportId || selectedReport?.main?.key;
      const individualReportId =
        selectedReport?.individual?.reportId || selectedReport?.individual?.key;

      const newReportForm = { ...reportForm };

      delete newReportForm.aggregate;
      delete newReportForm.isAuto;
      delete newReportForm.reportPages;
      delete newReportForm.coreData;
      delete newReportForm.valueN;
      delete newReportForm.reportDate;

      newCoreData = newCoreData.map((core) =>
        core.feId === individualReportId
          ? {
              ...newReportForm,
              feId: individualReportId,
              reportDate: core.Month,
              Month: core.Month,
              prettyDate: moment(core.Month?.split('T')[0]).format('MMMM YYYY'),
              contacts: reportContacts,
            }
          : { ...core }
      );
      const reportMapped = {
        aggregate: selectedReport?.main?.aggregate,
        coreData: newCoreData,
      };

      const savingObject = {
        name: reportForm.companyName || reportForm.name,
        date: selectedReport?.main?.aggregate?.monthRange?.end,
        type: ReportTypes.Merchant,
        manualInput: {
          ...reportMapped,
          valueN: reportForm.valueN,
          valueNaicsSic: reportForm.valueNaicsSic,
          industry: reportForm.industry,
          name: reportForm.companyName || reportForm.name,
          companyName: reportForm.companyName || reportForm.name,
          widgets: cleanWidgets,
          reportPages,
          isAuto: true,
        },
      };
      /// when any individual report values are updated
      // update the same record in coreData array by individual feId
      // recalculate aggregate
      // update individual in its place in above coreData
      const reportObject = await ReportService.updateReport(
        mainReportId,
        savingObject
      );
      setShow(false);
      handleGenerateReport(
        reportObject,
        cleanWidgets,
        reportPages,
        reportContacts,
        {
          ...reportForm,
          feId: individualReportId,
          key: individualReportId,
          reportId: individualReportId,
          Month: new Date(selectedReport?.individual?.reportDate).toISOString(),
          reportDate: new Date(
            selectedReport?.individual?.reportDate
          ).toISOString(),
          prettyDate: moment(
            new Date(selectedReport?.individual?.reportDate)
          ).format('MMMM YYYY'),
          contacts: reportContacts,
        }
      );
    } catch (e) {
      console.log(e);
    } finally {
      setGenerating(false);
    }
  };

  const isSvbFeesWidgetEnabled = () => {
    const key = 'bankFeesAsAPercentOfTotalFees';
    if (modalWidgets && modalWidgets[SvbReportSections.MerchantFees]) {
      const mfWidget = modalWidgets[SvbReportSections.MerchantFees][0];
      const newFeePercentageCardsData = [
        ...mfWidget?.widgetConfig?.feePercentageCardsData,
      ];

      return newFeePercentageCardsData.find((fd) => fd.key === key);
    }
    return { enabled: true };
  };
  const handleSvbBankFeesToggle = (e) => {
    const key = 'bankFeesAsAPercentOfTotalFees';
    const mfWidget = modalWidgets[SvbReportSections.MerchantFees][0];
    const newFeePercentageCardsData = [
      ...mfWidget?.widgetConfig?.feePercentageCardsData,
    ];

    newFeePercentageCardsData.forEach((cd) => {
      if (cd.key === key) {
        cd.enabled = e.target.checked;
      }
    });
    const newWidgetConfig = {
      ...MFWData,
      feePercentageCardsData: newFeePercentageCardsData,
    };
    modalWidgets[SvbReportSections.MerchantFees][0].widgetConfig = {
      ...newWidgetConfig,
      data: reportForm,
    };

    updateWidgets(
      reportForm,
      [WidgetTypes.MFW],
      newWidgetConfig,
      SvbReportSections.MerchantFees
    );
  };

  const handleInputChange = (e, key, widgetTypes, defaultWidgetData, page) => {
    const { value } = e.target;
    const newReport = {
      ...reportForm,
      [key]: value,
    };
    const updateConfigAndData = defaultWidgetData;
    const widgetConfigData =
      widgetTypes[0] === WidgetTypes.ISW ||
      page === SvbReportSections.InterchangeSavings
        ? { ...updateConfigAndData, reportObject: newReport }
        : { ...updateConfigAndData, data: newReport };
    updateWidgets(newReport, widgetTypes, widgetConfigData, page);
    setReportForm(newReport);
  };

  const detectNaicsChangeAndRefreshOrganizationProfile = () => {
    if (reportForm?.valueNaicsSic !== organization?.naics_code) {
      getOrganization && getOrganization();
    }
  };

  const handleReportPageToggle = (section) => {
    const updatedReportPages = {
      ...reportPages,
      [section]: {
        ...reportPages[section],
        enabled: !Object.hasOwn(reportPages, section)
          ? true
          : !reportPages[section].enabled,
      },
    };

    // we need to make sure at-least 1 page is active, so if user toggles all off and trying to toggle last off make sure to avoid it.
    const remainingEnabledSections = Object.entries(updatedReportPages).filter(
      ([key, value]) => value.enabled && key !== section
    ).length;

    if (!remainingEnabledSections) {
      setErrorMessage('At least one section must be enabled.');
      return;
    }
    setReportPages(updatedReportPages);
  };

  const onAddWidget = (e, newWidget, oldWidget) => {
    const sectionWidgets = modalWidgets[oldWidget.section];
    let updatedWidgets = [];
    if (oldWidget.type === WidgetTypes.BLANK) {
      sectionWidgets[oldWidget.widgetConfig.index] = {
        ...newWidget,
        id: getRandomUUID(),
      };
      updatedWidgets = [...sectionWidgets];
    } else {
      updatedWidgets = [...sectionWidgets].map((wg) =>
        wg.widgetConfig.heading === oldWidget.widgetConfig.heading
          ? { ...newWidget, id: getRandomUUID() }
          : { ...wg }
      );
    }
    setWidgets({ ...modalWidgets, [oldWidget.section]: updatedWidgets });
    setShowWidgetsLibrary(false);
  };

  const onDeleteWidget = (oldWidget) => {
    const sectionWidgets = modalWidgets[oldWidget.section];
    const updatedWidgets = [...sectionWidgets].map((wg, index) =>
      wg.widgetConfig.heading === oldWidget.widgetConfig.heading
        ? {
            action: { onAdd: () => setShowWidgetsLibrary(true) },
            widgetConfig: {
              index,
            },
            type: WidgetTypes.BLANK,
          }
        : { ...wg }
    );
    setWidgets({ ...modalWidgets, [oldWidget.section]: updatedWidgets });
  };

  useEffect(() => {
    if (show) {
      const contactFound = selectedReport?.main?.coreData?.find(
        (s) => s?.contacts?.length > 0
      );
      if (contactFound) {
        setReportContacts(contactFound.contacts);
      }
      setReportPages(modalReportPages);
    }
  }, [show]);

  return (
    <>
      <AlertWrapper className="alert-position">
        <Alert
          color="danger"
          message={errorMessage}
          setMessage={setErrorMessage}
        />
      </AlertWrapper>
      <NaicsModal />
      <WidgetsLibraryModal />
      <SimpleModalCreation
        modalTitle={<ReportModalTitle type={ReportTypes.SvbBank} />}
        open={show}
        saveButton="Save Report"
        isLoading={generating}
        deleteButton={null}
        handleSubmit={() => {
          overflowing();
          createOrUpdateReport();
          detectNaicsChangeAndRefreshOrganizationProfile();
        }}
        bodyClassName="p-0 overflow-x-hidden"
        customModal="modal-fit"
        scrollable={true}
        onHandleCloseModal={() => {
          overflowing();
          setShow(false);
        }}
        onClick={() => document.dispatchEvent(new MouseEvent('click'))}
      >
        <Row>
          <Col md={5}>
            <div className="pl-3 py-3">
              <FormGroup>
                <Label for="title">Company Name</Label>
                <input
                  name="name"
                  type="text"
                  value={reportForm.companyName}
                  placeholder="Enter Company Name"
                  onChange={(e) => {
                    setReportForm({
                      ...reportForm,
                      companyName: e.target.value,
                    });
                    setErrorFields({
                      ...errorFields,
                      name: {
                        ...errorFields.name,
                        isShow: !e.target.value,
                      },
                    });
                  }}
                  className={`form-control mx-0 mb-0 border-left-4 border-left-danger ${
                    errorFields.name.isShow ? 'border-danger' : ''
                  }`}
                />
                {errorFields.name.isShow && (
                  <ValidationErrorText text={errorFields.name.message} />
                )}
              </FormGroup>
              <FormGroup>
                <Label for="title">NAICS</Label>
                <SicNaicsAutoComplete
                  data={reportForm}
                  setData={setReportForm}
                  placeholder="Enter a NAICS code"
                  customKey="valueN"
                  showFieldError={errorFields.naics?.isShow}
                  setErrorMessage={setErrorMessage}
                  callKey={NAICS_STORAGE_KEY}
                  callType="getNaicsCodes"
                  isDisabled={!!organization.naics_code}
                  onSelect={(item, naicsSicOnly, naicsTitle) => {
                    setReportForm({
                      ...reportForm,
                      valueN: item ? [item] : [],
                      valueNaicsSic: naicsSicOnly,
                      industry: naicsTitle,
                    });
                    setErrorFields({
                      ...errorFields,
                      naics: {
                        ...errorFields.naics,
                        isShow: !naicsSicOnly,
                      },
                    });
                    if (naicsSicOnly) {
                      if (naicsSicOnly !== organization.naics_code) {
                        setShowModal(true);
                        setCompany({
                          ...organization,
                          naics_code: naicsSicOnly,
                          industry: naicsTitle,
                        });
                      }
                    }
                  }}
                />
                {errorFields.naics?.isShow && (
                  <ValidationErrorText text={errorFields.naics.message} />
                )}
              </FormGroup>
              <FormGroup className="date-wrapper">
                <Label for="title">Report Date</Label>
                <ReactDatepicker
                  id={'rptDate'}
                  name={'reportDate'}
                  disabled={true}
                  todayButton="Today"
                  value={reportForm.reportDate}
                  autoComplete="off"
                  className={`form-control mx-0 mb-0 border-left-4 border-left-danger ${
                    errorFields.reportDate?.isShow ? 'border-danger' : ''
                  }`}
                  placeholder="Select Report Date"
                  format="MMMM yyyy"
                  onChange={(date) => {
                    setReportForm({
                      ...reportForm,
                      reportDate: date,
                    });
                    setErrorFields({
                      ...errorFields,
                      reportDate: {
                        ...errorFields.reportDate,
                        isShow: !date,
                      },
                    });
                  }}
                  showMonthYearPicker
                  showFullMonthYearPicker
                />
                {errorFields.reportDate?.isShow && (
                  <ValidationErrorText text={errorFields.reportDate.message} />
                )}
              </FormGroup>
              <FormGroup>
                <Accordion activeKey={currentAccordionKey}>
                  <Card>
                    <ReportAccordionToggle
                      eventKey={SvbReportSections.ControllableCostSavings}
                      callback={handleAccordionClick}
                      setCurrentAccordionKey={setCurrentAccordionKey}
                    >
                      {SvbReportSections.ControllableCostSavings}
                      <ReportSectionIncludedLabel
                        section={SvbReportSections.ControllableCostSavings}
                        reportPages={reportPages}
                        handleReportPageToggle={handleReportPageToggle}
                      />
                    </ReportAccordionToggle>
                    <Accordion.Collapse
                      eventKey={SvbReportSections.ControllableCostSavings}
                    >
                      <CardBody>
                        <FormGroup>
                          <Label for="totalTransactions">
                            Total Transactions
                          </Label>
                          <InputGroup className="align-items-center">
                            <InputGroup.Prepend>
                              <InputGroup.Text>$</InputGroup.Text>
                            </InputGroup.Prepend>
                            <CurrencyInput
                              name="totalTransactions"
                              placeholder="0"
                              value={
                                formatCurrencyField(
                                  reportForm.totalTransactions?.toFixed(2)
                                ) || ''
                              }
                              className="form-control"
                              onValueChange={(value, name, values) => {
                                handleInputChange(
                                  { target: { value: values.float, name } },
                                  name,
                                  [WidgetTypes.CCSW],
                                  CCSWData,
                                  SvbReportSections.ControllableCostSavings
                                );
                              }}
                            />
                          </InputGroup>
                        </FormGroup>
                        <FormGroup>
                          <Label for="averageTransaction">
                            Average Transactions
                          </Label>
                          <InputGroup className="align-items-center">
                            <InputGroup.Prepend>
                              <InputGroup.Text>$</InputGroup.Text>
                            </InputGroup.Prepend>
                            <CurrencyInput
                              name="averageTransaction"
                              placeholder="0"
                              value={
                                formatCurrencyField(
                                  reportForm.averageTransaction
                                ) || ''
                              }
                              className="form-control"
                              onValueChange={(value, name, values) => {
                                handleInputChange(
                                  { target: { value: values.float, name } },
                                  name,
                                  [WidgetTypes.CCSW],
                                  CCSWData,
                                  SvbReportSections.ControllableCostSavings
                                );
                              }}
                            />
                          </InputGroup>
                        </FormGroup>
                        <FormGroup>
                          <Label for="totalDollarsProcessed">
                            Total Dollars Processed
                          </Label>
                          <InputGroup className="align-items-center">
                            <InputGroup.Prepend>
                              <InputGroup.Text>$</InputGroup.Text>
                            </InputGroup.Prepend>
                            <CurrencyInput
                              name="totalDollarsProcessed"
                              placeholder="0"
                              value={
                                formatCurrencyField(
                                  reportForm.totalDollarsProcessed?.toFixed(2)
                                ) || ''
                              }
                              className="form-control"
                              onValueChange={(value, name, values) => {
                                handleInputChange(
                                  { target: { value: values.float, name } },
                                  name,
                                  [WidgetTypes.CCSW],
                                  CCSWData,
                                  SvbReportSections.ControllableCostSavings
                                );
                              }}
                            />
                          </InputGroup>
                        </FormGroup>
                        <FormGroup>
                          <Label for="averageFee">Average Fee</Label>
                          <InputGroup className="align-items-center">
                            <InputGroup.Prepend>
                              <InputGroup.Text>$</InputGroup.Text>
                            </InputGroup.Prepend>
                            <CurrencyInput
                              name="averageFee"
                              placeholder="0"
                              value={
                                formatCurrencyField(reportForm.averageFee) || ''
                              }
                              className="form-control"
                              onValueChange={(value, name, values) => {
                                handleInputChange(
                                  { target: { value: values.float, name } },
                                  name,
                                  [WidgetTypes.CCSW],
                                  CCSWData,
                                  SvbReportSections.ControllableCostSavings
                                );
                              }}
                            />
                          </InputGroup>
                        </FormGroup>
                        <FormGroup>
                          <Label for="totalFees">Total Fees</Label>
                          <InputGroup className="align-items-center">
                            <InputGroup.Prepend>
                              <InputGroup.Text>$</InputGroup.Text>
                            </InputGroup.Prepend>
                            <CurrencyInput
                              name="totalFees"
                              placeholder="0"
                              value={
                                formatCurrencyField(
                                  reportForm.totalFees?.toFixed(2)
                                ) || ''
                              }
                              className="form-control"
                              onValueChange={(value, name, values) => {
                                handleInputChange(
                                  { target: { value: values.float, name } },
                                  name,
                                  [WidgetTypes.CCSW],
                                  CCSWData,
                                  SvbReportSections.ControllableCostSavings
                                );
                              }}
                            />
                          </InputGroup>
                        </FormGroup>
                        <FormGroup>
                          <Label for="refundPercentage">
                            Refund Percentage (Transaction)
                          </Label>
                          <InputGroup className="align-items-center">
                            <CurrencyInput
                              name="refundPercentage"
                              placeholder="0.00"
                              decimalsLimit={2}
                              decimalScale={2}
                              value={reportForm?.refundPercentage}
                              className="form-control"
                              onValueChange={(value, name, values) => {
                                handleInputChange(
                                  { target: { value: values.float, name } },
                                  name,
                                  [WidgetTypes.CCSW],
                                  CCSWData,
                                  SvbReportSections.ControllableCostSavings
                                );
                              }}
                            />
                            <InputGroup.Append>
                              <InputGroup.Text>%</InputGroup.Text>
                            </InputGroup.Append>
                          </InputGroup>
                        </FormGroup>
                        <FormGroup>
                          <Label for="authorizationToApprovalPercentage">
                            Authorization to Approval Rate
                          </Label>
                          <InputGroup className="align-items-center">
                            <CurrencyInput
                              name="authorizationToApprovalPercentage"
                              placeholder="0.00"
                              decimalsLimit={2}
                              decimalScale={2}
                              value={
                                reportForm?.authorizationToApprovalPercentage
                              }
                              className="form-control"
                              onValueChange={(value, name, values) => {
                                handleInputChange(
                                  { target: { value: values.float, name } },
                                  name,
                                  [WidgetTypes.CCSW],
                                  CCSWData,
                                  SvbReportSections.ControllableCostSavings
                                );
                              }}
                            />
                            <InputGroup.Append>
                              <InputGroup.Text>%</InputGroup.Text>
                            </InputGroup.Append>
                          </InputGroup>
                        </FormGroup>
                        <FormGroup>
                          <Label for="effectiveRate">Effective rate</Label>
                          <InputGroup className="align-items-center">
                            <CurrencyInput
                              name="effectiveRate"
                              placeholder="0.00"
                              decimalsLimit={2}
                              decimalScale={2}
                              value={reportForm?.effectiveRate}
                              className="form-control"
                              onValueChange={(value, name, values) => {
                                handleInputChange(
                                  { target: { value: values.float, name } },
                                  name,
                                  [WidgetTypes.CCSW],
                                  CCSWData,
                                  SvbReportSections.ControllableCostSavings
                                );
                              }}
                            />
                            <InputGroup.Append>
                              <InputGroup.Text>%</InputGroup.Text>
                            </InputGroup.Append>
                          </InputGroup>
                        </FormGroup>
                        <FormGroup>
                          <Label for="ccisDollarsProcessedAtHigherRate">
                            Savings Opportunity (Higher Rate)
                          </Label>
                          <InputGroup className="align-items-center">
                            <InputGroup.Prepend>
                              <InputGroup.Text>$</InputGroup.Text>
                            </InputGroup.Prepend>
                            <CurrencyInput
                              name="ccisDollarsProcessedAtHigherRate"
                              placeholder="0"
                              value={
                                formatCurrencyField(
                                  reportForm.ccisDollarsProcessedAtHigherRate?.toFixed(
                                    2
                                  )
                                ) || ''
                              }
                              className="form-control"
                              onValueChange={(value, name, values) => {
                                handleInputChange(
                                  { target: { value: values.float, name } },
                                  name,
                                  [WidgetTypes.CCSW],
                                  CCSWData,
                                  SvbReportSections.ControllableCostSavings
                                );
                              }}
                            />
                          </InputGroup>
                        </FormGroup>
                        <FormGroup>
                          <Label for="totalDollarsProcessed">
                            Savings Opportunity (Total Dollars Processed)
                          </Label>
                          <InputGroup className="align-items-center">
                            <InputGroup.Prepend>
                              <InputGroup.Text>$</InputGroup.Text>
                            </InputGroup.Prepend>
                            <CurrencyInput
                              name="totalDollarsProcessed"
                              placeholder="0"
                              value={
                                formatCurrencyField(
                                  reportForm.totalDollarsProcessed?.toFixed(2)
                                ) || ''
                              }
                              className="form-control"
                              onValueChange={(value, name, values) => {
                                handleInputChange(
                                  { target: { value: values.float, name } },
                                  name,
                                  [WidgetTypes.CCSW],
                                  CCSWData,
                                  SvbReportSections.ControllableCostSavings
                                );
                              }}
                            />
                          </InputGroup>
                        </FormGroup>
                        <FormGroup>
                          <Label for="ccisDollars">
                            Savings Opportunity (Optional Commercial Card
                            savings)
                          </Label>
                          <InputGroup className="align-items-center">
                            <InputGroup.Prepend>
                              <InputGroup.Text>$</InputGroup.Text>
                            </InputGroup.Prepend>
                            <CurrencyInput
                              name="ccisDollars"
                              placeholder="0"
                              value={
                                formatCurrencyField(
                                  reportForm.ccisDollars?.toFixed(2)
                                ) || ''
                              }
                              className="form-control"
                              onValueChange={(value, name, values) => {
                                handleInputChange(
                                  { target: { value: values.float, name } },
                                  name,
                                  [WidgetTypes.CCSW],
                                  CCSWData,
                                  SvbReportSections.ControllableCostSavings
                                );
                              }}
                            />
                          </InputGroup>
                        </FormGroup>
                      </CardBody>
                    </Accordion.Collapse>
                  </Card>
                </Accordion>
                <Accordion activeKey={currentAccordionKey}>
                  <Card>
                    <ReportAccordionToggle
                      eventKey={SvbReportSections.MerchantFees}
                      callback={handleAccordionClick}
                      setCurrentAccordionKey={setCurrentAccordionKey}
                    >
                      {SvbReportSections.MerchantFees}
                      <ReportSectionIncludedLabel
                        section={SvbReportSections.MerchantFees}
                        reportPages={reportPages}
                        handleReportPageToggle={handleReportPageToggle}
                      />
                    </ReportAccordionToggle>
                    <Accordion.Collapse
                      eventKey={SvbReportSections.MerchantFees}
                    >
                      <CardBody>
                        <FormGroup>
                          <Label for="nonBankFeesPercentage">
                            Merchant processing fees
                          </Label>
                          <InputGroup className="align-items-center">
                            <CurrencyInput
                              name="nonBankFeesPercentage"
                              placeholder="0.00"
                              decimalsLimit={2}
                              decimalScale={2}
                              value={Math.round(
                                (reportForm?.nonBankFeesPercentage /
                                  reportForm.totalFees) *
                                  100
                              )}
                              className="form-control"
                              onValueChange={(value, name, values) => {
                                handleInputChange(
                                  { target: { value: values.float, name } },
                                  name,
                                  [WidgetTypes.MFW],
                                  MFWData,
                                  SvbReportSections.MerchantFees
                                );
                              }}
                            />
                            <InputGroup.Append>
                              <InputGroup.Text>%</InputGroup.Text>
                            </InputGroup.Append>
                          </InputGroup>
                        </FormGroup>
                        <FormGroup>
                          <Label for="interchangeFeesAsAPercentOfTotalFees">
                            Interchange fees
                          </Label>
                          <InputGroup className="align-items-center">
                            <CurrencyInput
                              name="interchangeFeesAsAPercentOfTotalFees"
                              placeholder="0.00"
                              decimalsLimit={2}
                              decimalScale={2}
                              value={
                                reportForm?.interchangeFeesAsAPercentOfTotalFees
                              }
                              className="form-control"
                              onValueChange={(value, name, values) => {
                                handleInputChange(
                                  { target: { value: values.float, name } },
                                  name,
                                  [WidgetTypes.MFW],
                                  MFWData,
                                  SvbReportSections.MerchantFees
                                );
                              }}
                            />
                            <InputGroup.Append>
                              <InputGroup.Text>%</InputGroup.Text>
                            </InputGroup.Append>
                          </InputGroup>
                        </FormGroup>
                        <FormGroup>
                          <Label for="networkFeesAsAPercentOfTotalFees">
                            Network fees
                          </Label>
                          <InputGroup className="align-items-center">
                            <CurrencyInput
                              name="networkFeesAsAPercentOfTotalFees"
                              placeholder="0.00"
                              decimalsLimit={2}
                              decimalScale={2}
                              value={
                                reportForm?.networkFeesAsAPercentOfTotalFees
                              }
                              className="form-control"
                              onValueChange={(value, name, values) => {
                                handleInputChange(
                                  { target: { value: values.float, name } },
                                  name,
                                  [WidgetTypes.MFW],
                                  MFWData,
                                  SvbReportSections.MerchantFees
                                );
                              }}
                            />
                            <InputGroup.Append>
                              <InputGroup.Text>%</InputGroup.Text>
                            </InputGroup.Append>
                          </InputGroup>
                        </FormGroup>
                        <FormGroup className="position-relative">
                          <Label for="bankFeesAsAPercentOfTotalFees">
                            SVB bank fees
                          </Label>
                          <InputGroup className="align-items-center">
                            <CurrencyInput
                              name="bankFeesAsAPercentOfTotalFees"
                              placeholder="0.00"
                              decimalsLimit={2}
                              decimalScale={2}
                              value={reportForm?.bankFeesAsAPercentOfTotalFees}
                              className="form-control"
                              onValueChange={(value, name, values) => {
                                handleInputChange(
                                  { target: { value: values.float, name } },
                                  name,
                                  [WidgetTypes.MFW],
                                  MFWData,
                                  SvbReportSections.MerchantFees
                                );
                              }}
                            />
                            <InputGroup.Append>
                              <InputGroup.Text>%</InputGroup.Text>
                            </InputGroup.Append>
                          </InputGroup>
                          <div className="position-absolute right-0 top-0">
                            <FormCheck
                              type="switch"
                              custom={true}
                              label="Enabled"
                              checked={isSvbFeesWidgetEnabled()?.enabled}
                              id="toggleSVBFees"
                              name="toggleSVBFees"
                              onChange={handleSvbBankFeesToggle}
                            />
                          </div>
                        </FormGroup>
                      </CardBody>
                    </Accordion.Collapse>
                  </Card>
                </Accordion>
                <Accordion activeKey={currentAccordionKey}>
                  <Card>
                    <ReportAccordionToggle
                      eventKey={SvbReportSections.InterchangeSavings}
                      callback={handleAccordionClick}
                      setCurrentAccordionKey={setCurrentAccordionKey}
                    >
                      {SvbReportSections.InterchangeSavings}
                      <ReportSectionIncludedLabel
                        section={SvbReportSections.InterchangeSavings}
                        reportPages={reportPages}
                        handleReportPageToggle={handleReportPageToggle}
                      />
                    </ReportAccordionToggle>
                    <Accordion.Collapse
                      eventKey={SvbReportSections.InterchangeSavings}
                    >
                      <CardBody>
                        <FormGroup>
                          <Label for="ccisDollars">CCIS Dollars</Label>
                          <InputGroup className="align-items-center">
                            <InputGroup.Prepend>
                              <InputGroup.Text>$</InputGroup.Text>
                            </InputGroup.Prepend>
                            <CurrencyInput
                              name="ccisDollars"
                              placeholder="0"
                              value={
                                formatCurrencyField(
                                  reportForm.ccisDollars?.toFixed(2)
                                ) || ''
                              }
                              className="form-control"
                              onValueChange={(value, name, values) => {
                                handleInputChange(
                                  { target: { value: values.float, name } },
                                  name,
                                  [WidgetTypes.ISW],
                                  ISWData,
                                  SvbReportSections.InterchangeSavings
                                );
                              }}
                            />
                          </InputGroup>
                        </FormGroup>
                        <FormGroup>
                          <Label for="ccisCount">CCIS Transactions</Label>
                          <InputGroup className="align-items-center">
                            <InputGroup.Prepend>
                              <InputGroup.Text>#</InputGroup.Text>
                            </InputGroup.Prepend>
                            <CurrencyInput
                              name="ccisCount"
                              placeholder="0"
                              value={
                                formatCurrencyField(reportForm.ccisCount) || ''
                              }
                              className="form-control"
                              onValueChange={(value, name, values) => {
                                handleInputChange(
                                  { target: { value: values.float, name } },
                                  name,
                                  [WidgetTypes.ISW],
                                  ISWData,
                                  SvbReportSections.InterchangeSavings
                                );
                              }}
                            />
                          </InputGroup>
                        </FormGroup>
                      </CardBody>
                    </Accordion.Collapse>
                  </Card>
                </Accordion>
                <Accordion activeKey={currentAccordionKey}>
                  <Card>
                    <ReportAccordionToggle
                      eventKey={SvbReportSections.Chargebacks}
                      callback={handleAccordionClick}
                      setCurrentAccordionKey={setCurrentAccordionKey}
                    >
                      {SvbReportSections.Chargebacks}
                      <ReportSectionIncludedLabel
                        section={SvbReportSections.Chargebacks}
                        reportPages={reportPages}
                        handleReportPageToggle={handleReportPageToggle}
                      />
                    </ReportAccordionToggle>
                    <Accordion.Collapse
                      eventKey={SvbReportSections.Chargebacks}
                    >
                      <CardBody>
                        <FormGroup>
                          <Label for="visaChargebackPercentage">
                            Visa Chargeback Percentage
                          </Label>
                          <InputGroup className="align-items-center">
                            <CurrencyInput
                              name="visaChargebackPercentage"
                              placeholder="0.00"
                              decimalsLimit={3}
                              decimalScale={3}
                              value={reportForm?.visaChargebackPercentage}
                              className="form-control"
                              onValueChange={(value, name, values) => {
                                handleInputChange(
                                  { target: { value: values.float, name } },
                                  name,
                                  [WidgetTypes.FCW],
                                  FCWData,
                                  SvbReportSections.Chargebacks
                                );
                              }}
                            />
                            <InputGroup.Append>
                              <InputGroup.Text>%</InputGroup.Text>
                            </InputGroup.Append>
                          </InputGroup>
                        </FormGroup>
                        <FormGroup>
                          <Label for="mastercardChargebackPercentage">
                            Mastercard Chargeback Percentage
                          </Label>
                          <InputGroup className="align-items-center">
                            <CurrencyInput
                              name="mastercardChargebackPercentage"
                              placeholder="0.00"
                              decimalsLimit={3}
                              decimalScale={3}
                              value={reportForm?.mastercardChargebackPercentage}
                              className="form-control"
                              onValueChange={(value, name, values) => {
                                handleInputChange(
                                  { target: { value: values.float, name } },
                                  name,
                                  [WidgetTypes.FCW],
                                  FCWData,
                                  SvbReportSections.Chargebacks
                                );
                              }}
                            />
                            <InputGroup.Append>
                              <InputGroup.Text>%</InputGroup.Text>
                            </InputGroup.Append>
                          </InputGroup>
                        </FormGroup>
                        <FormGroup>
                          <Label for="chargebackDollars">
                            Chargeback Dollars
                          </Label>
                          <InputGroup className="align-items-center">
                            <InputGroup.Prepend>
                              <InputGroup.Text>$</InputGroup.Text>
                            </InputGroup.Prepend>
                            <CurrencyInput
                              name="chargebackDollars"
                              placeholder="0"
                              value={
                                formatCurrencyField(
                                  reportForm.chargebackDollars?.toFixed(2)
                                ) || ''
                              }
                              className="form-control"
                              onValueChange={(value, name, values) => {
                                handleInputChange(
                                  { target: { value: values.float, name } },
                                  name,
                                  [WidgetTypes.FCW],
                                  FCWData,
                                  SvbReportSections.Chargebacks
                                );
                              }}
                            />
                          </InputGroup>
                        </FormGroup>
                        <FormGroup>
                          <Label for="averageChargeback">
                            Average Chargeback
                          </Label>
                          <InputGroup className="align-items-center">
                            <InputGroup.Prepend>
                              <InputGroup.Text>$</InputGroup.Text>
                            </InputGroup.Prepend>
                            <CurrencyInput
                              name="averageChargeback"
                              placeholder="0"
                              value={
                                formatCurrencyField(
                                  reportForm.averageChargeback?.toFixed(2)
                                ) || ''
                              }
                              className="form-control"
                              onValueChange={(value, name, values) => {
                                handleInputChange(
                                  { target: { value: values.float, name } },
                                  name,
                                  [WidgetTypes.FCW],
                                  FCWData,
                                  SvbReportSections.Chargebacks
                                );
                              }}
                            />
                          </InputGroup>
                        </FormGroup>
                      </CardBody>
                    </Accordion.Collapse>
                  </Card>
                </Accordion>
                <Accordion activeKey={currentAccordionKey}>
                  <Card>
                    <ReportAccordionToggle
                      eventKey={SvbReportSections.Glossary}
                      callback={handleAccordionClick}
                      dontExpand={true}
                      setCurrentAccordionKey={setCurrentAccordionKey}
                    >
                      {SvbReportSections.Glossary}
                      <ReportSectionIncludedLabel
                        section={SvbReportSections.Glossary}
                        reportPages={reportPages}
                        handleReportPageToggle={handleReportPageToggle}
                      />
                    </ReportAccordionToggle>
                  </Card>
                </Accordion>
              </FormGroup>
            </div>
          </Col>
          <Col
            md={7}
            className={`bg-white treasury-modal position-relative overflow-x-hidden pb-3 pl-0 ${
              currentAccordionKey === null
                ? 'd-flex align-items-center justify-content-center'
                : ''
            }`}
          >
            {currentAccordionKey === null && (
              <NoDataFound
                title="No page selected"
                description="Please expand a page menu from left to edit values"
                icon="analytics"
                containerStyle="text-gray-900"
              />
            )}
            <div className="py-2 px-3">
              <div>
                {currentAccordionKey ===
                  SvbReportSections.ControllableCostSavings && (
                  <WidgetsBySection
                    icon="price_check"
                    widgets={widgets}
                    section={SvbReportSections.ControllableCostSavings}
                    onAddWidget={onAddWidget}
                    reportPages={reportPages}
                    onDeleteWidget={onDeleteWidget}
                    setSelectedWidget={setSelectedWidget}
                    setShowWidgetsLibrary={setShowWidgetsLibrary}
                    handleReportPageToggle={handleReportPageToggle}
                  />
                )}
                {currentAccordionKey === SvbReportSections.MerchantFees && (
                  <WidgetsBySection
                    icon="price_check"
                    widgets={widgets}
                    section={SvbReportSections.MerchantFees}
                    onAddWidget={onAddWidget}
                    reportPages={reportPages}
                    onDeleteWidget={onDeleteWidget}
                    setSelectedWidget={setSelectedWidget}
                    setShowWidgetsLibrary={setShowWidgetsLibrary}
                    handleReportPageToggle={handleReportPageToggle}
                  />
                )}
                {currentAccordionKey ===
                  SvbReportSections.InterchangeSavings && (
                  <WidgetsBySection
                    icon="price_check"
                    widgets={widgets}
                    section={SvbReportSections.InterchangeSavings}
                    onAddWidget={onAddWidget}
                    reportPages={reportPages}
                    onDeleteWidget={onDeleteWidget}
                    setSelectedWidget={setSelectedWidget}
                    setShowWidgetsLibrary={setShowWidgetsLibrary}
                    handleReportPageToggle={handleReportPageToggle}
                  />
                )}
                {currentAccordionKey === SvbReportSections.Chargebacks && (
                  <WidgetsBySection
                    icon="price_check"
                    widgets={widgets}
                    section={SvbReportSections.Chargebacks}
                    onAddWidget={onAddWidget}
                    reportPages={reportPages}
                    onDeleteWidget={onDeleteWidget}
                    setSelectedWidget={setSelectedWidget}
                    setShowWidgetsLibrary={setShowWidgetsLibrary}
                    handleReportPageToggle={handleReportPageToggle}
                  />
                )}
                {currentAccordionKey === SvbReportSections.Glossary && (
                  <WidgetsBySection
                    icon="price_check"
                    widgets={widgets}
                    section={SvbReportSections.Glossary}
                    onAddWidget={onAddWidget}
                    reportPages={reportPages}
                    onDeleteWidget={onDeleteWidget}
                    setSelectedWidget={setSelectedWidget}
                    setShowWidgetsLibrary={setShowWidgetsLibrary}
                    handleReportPageToggle={handleReportPageToggle}
                  />
                )}
              </div>
            </div>
          </Col>
        </Row>
      </SimpleModalCreation>
    </>
  );
};

const useGenerateSvbMerchantReportModal = (
  report,
  organization,
  modalReportPages,
  modalWidgets,
  handleGenerateReport,
  getOrganization
) => {
  const [showModal, setShowModal] = useState(false);
  const [selectedReport, setSelectedReport] = useState({});
  const [widgets, setWidgets] = useState({});
  const [reportPages, setReportPages] = useState({});

  const GenerateSvbMerchantReportModalCallback = useCallback(() => {
    return (
      <GenerateSvbMerchantReportModal
        show={showModal}
        setShow={setShowModal}
        selectedReport={selectedReport}
        setSelectedReport={setSelectedReport}
        organization={organization}
        modalReportPages={reportPages}
        modalWidgets={widgets}
        handleGenerateReport={handleGenerateReport}
        getOrganization={getOrganization}
      />
    );
  }, [showModal, setShowModal, selectedReport, setSelectedReport]);

  return useMemo(
    () => ({
      setShowModal,
      setSelectedReport,
      setModalWidgets: setWidgets,
      setModalReportPages: setReportPages,
      GenerateSvbMerchantReportModal: GenerateSvbMerchantReportModalCallback,
    }),
    [setShowModal, GenerateSvbMerchantReportModalCallback]
  );
};

export default useGenerateSvbMerchantReportModal;
