import { useEffect, useState } from 'react';
import { cubeService } from '../../../services';
import Loading from '../../../components/Loading';
import InsightStatItem from '../InsightStatItem';
import NoDataFound from '../../../components/commons/NoDataFound';

const AssignmentStats = {
  AssignmentCompleted: 'Completed',
  AssignmentInProgress: 'In Progress',
  AssignmentNotStarted: 'Not Started',
  AssignmentOverDue: 'Overdue',
  totalLessonCount: 'Lessons',
  uniqueTotalAssignments: 'Number of Assignments',
  peopleAssigned: 'Users Assigned',
};

const priorityOrder = [
  'AssignmentCompleted',
  'assignmentCompleted',
  'AssignmentInProgress',
  'assignmentInProgress',
  'AssignmentNotStarted',
  'assignmentNotStarted',
  'AssignmentOverDue',
  'assignmentOverDue',
  'uniqueTotalAssignments',
  'peopleAssigned',
  'totalLessonCount',
];

export const AssignmentSnapshotWidget = ({
  assignmentStatsNames = {},
  queryFilter = [],
  param = 'teams',
  type = 'AssignmentStats',
  assignmentStatsTooltip = {},
}) => {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);

  const getData = async () => {
    setLoading(true);
    const results = await cubeService.loadCustomAnalytics({
      type,
      ...(queryFilter?.length > 0 ? { [param]: queryFilter } : {}),
    });

    setData(results);
    setLoading(false);
  };

  useEffect(() => {
    if (queryFilter?.length > 0) {
      getData();
    }
  }, [queryFilter]);

  useEffect(() => {
    getData();
  }, []);

  const totalCompleted = Math.round(
    (data[0]?.AssignmentCompleted * 100) / data[0]?.totalAssignments
  );
  const totalDue = Math.round(
    (data[0]?.AssignmentOverDue * 100) / data[0]?.totalAssignments
  );
  const totalNotStarted = Math.round(
    (data[0]?.AssignmentNotStarted * 100) / data[0]?.totalAssignments
  );
  const totalInProgress = Math.round(
    (data[0]?.AssignmentInProgress * 100) / data[0]?.totalAssignments
  );

  return (
    <>
      <div className="px-3 my-4">
        <div
          className={`d-grid gap-4 ${loading ? 'grid-col-1' : 'grid-col-4'}`}
        >
          {loading ? (
            <Loading />
          ) : data?.length > 0 ? (
            (() => {
              // filter the entries
              const filteredEntries = Object.entries(data[0]).filter(
                ([key]) => {
                  return !(
                    (type === 'AssignmentDetailStats' &&
                      key === 'totalAssignments') ||
                    (type === 'AssignmentStats' &&
                      (key === 'totalAssignments' ||
                        key === 'uniqueTotalAssignments'))
                  );
                }
              );

              // sort by priority, and keep others at the end
              filteredEntries.sort(([keyA], [keyB]) => {
                const indexA = priorityOrder.indexOf(keyA);
                const indexB = priorityOrder.indexOf(keyB);
                return (
                  (indexA === -1 ? Infinity : indexA) -
                  (indexB === -1 ? Infinity : indexB)
                );
              });

              return filteredEntries.map(([key, value]) => (
                <InsightStatItem
                  key={key}
                  value={key}
                  hideAverage
                  tooltipTitle={assignmentStatsTooltip[key]}
                  tooltipData={assignmentStatsTooltip}
                  heading={
                    Object.keys(assignmentStatsNames)?.length > 0
                      ? assignmentStatsNames[key]
                      : AssignmentStats[key]
                  }
                  stat={
                    key === 'AssignmentCompleted'
                      ? totalCompleted
                      : key === 'AssignmentOverDue'
                      ? totalDue
                      : key === 'AssignmentInProgress'
                      ? totalInProgress
                      : key === 'AssignmentNotStarted'
                      ? totalNotStarted
                      : value || 0
                  }
                  subtext={
                    key === 'AssignmentCompleted' ||
                    key === 'AssignmentOverDue' ||
                    key === 'AssignmentInProgress' ||
                    key === 'AssignmentNotStarted'
                      ? '%'
                      : ''
                  }
                />
              ));
            })()
          ) : (
            <NoDataFound title="Data not found" />
          )}
        </div>
      </div>
    </>
  );
};
