import React, { useEffect, useReducer, useState } from 'react';
import { Card, CardBody } from 'reactstrap';
import IDABotImage from '../../../assets/svg/ai-advisor/ida.svg';
import SalesCoachIcon from '../../../assets/svg/ai-advisor/business-coach.svg';
import ProfessionalCoachIcon from '../../../assets/svg/ai-advisor/professional-coach.svg';
import IndustryCoachIcon from '../../../assets/svg/ai-advisor/industry-coach.svg';
import { RetellWebClient } from 'retell-client-js-sdk';

import { Image } from 'react-bootstrap';
import ButtonIcon from '../../../components/commons/ButtonIcon';
import SubHeading from '../../../components/subheading';

const RetelAIAgents = {
  sales: 'sales',
  industry: 'industry-consultant',
  professional: 'professional-development',
};

const Coaches = [
  {
    id: 'sales',
    icon: SalesCoachIcon,
    name: 'Business Sales Coach',
    styles: 'sales',
    isSelected: true,
    agentId: RetelAIAgents.sales,
  },
  {
    id: 'professional-development',
    icon: ProfessionalCoachIcon,
    name: 'Professional Development Coach',
    styles: 'professional',
    isSelected: false,
    agentId: RetelAIAgents.professional,
  },
  {
    id: 'industry-consultant',
    icon: IndustryCoachIcon,
    name: 'Industry Consultant Coach',
    styles: 'industry',
    isSelected: false,
    agentId: RetelAIAgents.industry,
  },
];

const webClient = new RetellWebClient();

const Coach = ({ coach, handleSelect }) => {
  return (
    <a
      href=""
      className={`d-flex align-items-center text-left coach-tile gap-1 p-3 rounded ${
        coach.styles
      } ${coach.isSelected ? 'selected' : ''}`}
      onClick={(e) => {
        e.preventDefault();
        handleSelect(coach);
      }}
    >
      <Image src={coach.icon} />
      <p className="mb-0 font-size-sm2 font-weight-medium">{coach.name}</p>
    </a>
  );
};
const AIAdvisor = () => {
  const [loading, setLoading] = useState(false);
  const [advisor, updateAdvisor] = useReducer(
    (prev, next) => {
      return { ...prev, ...next };
    },
    {
      isCalling: false,
      coaches: Coaches,
      coach: Coaches[0], // default
    }
  );

  // Initialize the SDK
  useEffect(() => {
    // Setup event listeners
    webClient.on('call_started', () => {
      console.log('call_started');
    });

    webClient.on('audio', () => {
      console.log('There is audio');
    });

    webClient.on('call_ended', () => {
      console.log('call_ended');
      updateAdvisor({ isCalling: false }); // Update button to "Start" when conversation ends
    });

    webClient.on('error', (error) => {
      console.error('An error occurred:', error);
      updateAdvisor({ isCalling: false }); // Update button to "Start" in case of error
    });

    webClient.on('update', (update) => {
      // Print live transcript as needed
      console.log('update', update);
    });
  }, [advisor.coach]);

  const handleTalk = async () => {
    if (advisor.isCalling) {
      webClient.stopCall();
      updateAdvisor({ isCalling: false });
    } else {
      try {
        setLoading(true);
        const webCall = await createWebCall(advisor.coach.agentId);
        if (webCall.accessToken) {
          webClient
            .startCall({
              accessToken: webCall.accessToken,
            })
            .catch(console.error);
          updateAdvisor({ isCalling: true }); // Update button to "Stop" when conversation starts
        }
      } catch (e) {
        console.error(e);
      } finally {
        setLoading(false);
      }
    }
  };

  const handleCoachSelect = (coach) => {
    const updatedCoaches = [...advisor.coaches].map((ch) => ({
      ...ch,
      isSelected: coach.id === ch.id,
    }));

    if (advisor.isCalling) {
      webClient.stopCall();
    }
    updateAdvisor({
      isCalling: false,
      coach,
      coaches: updatedCoaches,
    });
  };

  const createWebCall = async (agent) => {
    try {
      // Replace with your server url
      const response = await fetch(
        `${process.env.REACT_APP_IDA_API_URL}/retell/create-web-call`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            agent,
          }),
        }
      );

      if (!response.ok) {
        throw new Error(`Error: ${response.status}`);
      }

      const { data } = await response.json();
      return data;
    } catch (err) {
      console.log(err);
      throw new Error(err);
    }
  };

  return (
    <>
      <div className={`px-3 position-relative page-header-divider`}>
        <SubHeading title="Ask AI Advisor" headingStyle="m-0 p-0" />
      </div>

      <div className="px-3">
        <div
          className="mx-xs-2 m-md-auto m-lg-auto mb-2"
          style={{ maxWidth: 850 }}
        >
          <Card className="mx-sm-2 card-0">
            <CardBody className="p-5 text-center">
              <div className="py-4 text-center my-4">
                <div
                  className="position-relative m-auto mb-3"
                  style={{ maxWidth: 120 }}
                >
                  <Image src={IDABotImage} width={110} />
                  <span
                    className="position-absolute top-0 font-size-2p5em"
                    style={{ right: -20 }}
                  >
                    💬
                  </span>
                </div>
                <ButtonIcon
                  icon={advisor.isCalling ? 'stop' : 'mic'}
                  onclick={handleTalk}
                  label={advisor.isCalling ? 'Stop' : 'Talk'}
                  color="danger"
                  loading={loading}
                  classnames={`btn-sm ${loading ? 'px-3' : 'pl-3 pr-4'}  ${
                    advisor.coach.styles
                  } selected`}
                />
              </div>

              <div className="ai-advisor d-flex align-items-center justify-content-center gap-2">
                {advisor.coaches.map((coach) => (
                  <Coach
                    key={coach.id}
                    coach={coach}
                    handleSelect={(coach) => handleCoachSelect(coach)}
                  />
                ))}
              </div>
            </CardBody>
          </Card>
        </div>
      </div>
    </>
  );
};

export default AIAdvisor;
