import BaseRequestService from './baseRequest.service';

import authHeader from './auth-header';
import { createBlobObject } from '../utils/Utils';

const API_URL = process.env.REACT_APP_API_URL + '/api';

class ArchiveService extends BaseRequestService {
  async getArchives(options = {}) {
    const { page = 1, limit = 10 } = options;
    const params = {
      page,
      limit,
    };

    if (!options.title) {
      delete options.title;
    }
    const response = await this.get(
      `/archives`,
      {
        headers: authHeader(),
        params: { ...options, ...params },
      },
      { fullResponse: true }
    );

    return response?.data;
  }

  async getArchive(archiveId) {
    const response = await this.get(
      `/archives/${archiveId}`,
      {
        headers: authHeader(),
      },
      { fullResponse: true }
    );

    return response?.data;
  }

  async getArchiveSubscriptions(archiveId) {
    const params = { page: 1, limit: 1 };
    const response = await this.get(
      `/archives/${archiveId}/subscriptions`,
      {
        headers: authHeader(),
        params,
      },
      { fullResponse: true }
    );

    return response?.data;
  }

  async createArchiveTransaction(data) {
    return fetch(`${API_URL}/archives`, {
      method: 'POST',
      body: JSON.stringify(data),
      headers: { ...authHeader(), 'Content-Type': 'application/json' },
    });
  }

  async createArchive(data) {
    return this.post(`/archives`, data, {
      headers: authHeader(),
    });
  }

  async createOrder(data) {
    return this.post(`/orders`, data, {
      headers: authHeader(),
    });
  }

  async updateArchive(archiveId, data) {
    return this.put(`/archives/${archiveId}`, data, {
      headers: authHeader(),
    });
  }

  async deleteArchive(archiveId) {
    return this.delete(`/archives/${archiveId}`, {
      headers: authHeader(),
    });
  }

  async deleteArchiveFile(archiveFileId) {
    return this.delete(`/archiveFiles/${archiveFileId}`, {
      headers: authHeader(),
    });
  }

  async addFileToArchive(archiveId, files, onUploadProgress) {
    for (const file of files) {
      const formData = new FormData();
      const formBlob = await createBlobObject(file);
      formData.append('file', formBlob, file.name);
      const fileKey = `${file.name}_${file.lastModified}`;
      await this.post(`/archives/${archiveId}/files`, formData, {
        headers: {
          ...authHeader(),
          'Content-Type': 'multipart/form-data',
        },
        onUploadProgress: (progressEvent) => {
          if (onUploadProgress) {
            const percentCompleted = Math.round(
              (progressEvent.loaded * 100) / progressEvent.total
            );
            onUploadProgress(percentCompleted, fileKey);
          }
        },
      });
    }
  }

  async getArchiveFiles(archiveId, page = 1, limit = 10, filename = '') {
    const params = { page, limit };
    if (filename.trim()) {
      params['file.name'] = filename.trim(); // Use the correct key format
    }
    const response = await this.get(
      `/archives/${archiveId}/files`,
      {
        headers: authHeader(),
        params,
      },
      { fullResponse: true }
    );

    return response?.data;
  }
}
export default new ArchiveService();
