import ContentLayout from './layout/ContentLayout';
import moment from 'moment';
import Avatar from '../Avatar';
import { MenuItemsKeysAndIcons } from './constants';
import useHash from '../../hooks/useHash';
import IdfTooltip from '../idfComponents/idfTooltip';
import useFetchArchives from './hooks/useFetchArchives';
import React, { useEffect, useState } from 'react';
import userService from '../../services/user.service';
import useCreateKnowledgeModal from './modals/CreateKnowledge';
import ArchiveItemSkeleton from './loaders/ArchiveItemSkeleton';
import TextOverflowTooltip from '../commons/TextOverflowTooltip';
import LucideIcon from '../commons/LucideIcon';
import Search from '../manageUsers/Search';
import useIsTenant from '../../hooks/useIsTenant';
import ChecklistStatus from '../checklist/ChecklistStatus';
const { isDemo } = useIsTenant();
const sectionVisible = isDemo ? '' : 'd-none';

const ArchiveItemOwner = ({ archive }) => {
  const [loading, setLoading] = useState(true);
  const [owner, setOwner] = useState({});
  const fetchArchiveOwner = async () => {
    try {
      const { user } = await userService.getUserAccount(
        archive.createdByAccountId
      );
      const name = user?.name || `${user?.first_name} ${user?.last_name || ''}`;
      setOwner({ ...user, name });
    } catch (e) {
      console.log(e);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    archive?.createdByAccountId && fetchArchiveOwner();
  }, []);
  return (
    <>
      {loading ? (
        <></>
      ) : (
        <IdfTooltip text={`${owner?.first_name} ${owner?.last_name}`}>
          <span className="ml-auto">
            <Avatar user={owner} defaultSize="xs" />
          </span>
        </IdfTooltip>
      )}
    </>
  );
};
const ArchiveItem = ({ know, type = 'add', icon = 'Plus', onClick }) => {
  const isNewItem = type === 'add'; // mean create new knowledge item
  return (
    <div
      className={`d-flex flex-column rounded p-2 transition-all knowledge-item ${
        know.isPremium ? 'knowledge-item-premium' : 'cursor-pointer'
      }`}
      key={know.id}
      onClick={onClick}
    >
      <div className="d-flex align-items-start position-relative flex-1">
        {know.icon && !know.static ? (
          <span
            dangerouslySetInnerHTML={{ __html: `&#x${know.icon};` }}
            className="font-size-2xl position-absolute"
            style={{ top: -7 }}
          />
        ) : (
          <>
            {know.isPremium ? (
              <div className="d-flex justify-content-between w-100">
                <div
                  className={`rounded-circle bg-yellow p-1_2 bg-opacity-hover d-flex align-items-center`}
                >
                  <LucideIcon icon={icon} size={18} clazz="text-black" />
                </div>
                <div style={{ height: 20 }}>
                  <ChecklistStatus item={{ status: 'Premium' }} />
                </div>
              </div>
            ) : (
              <div
                className={`${
                  type === 'add'
                    ? 'bg-primary text-white'
                    : 'bg-beige text-black-50'
                } rounded-circle p-1_2 bg-opacity-hover d-flex align-items-center`}
              >
                <LucideIcon icon={icon} size={18} />
              </div>
            )}
          </>
        )}
        {!isNewItem && !know.isPremium && <ArchiveItemOwner archive={know} />}
      </div>
      <div className={`flex-1 px-2 align-content-end`}>
        <h6 className="mb-1">
          {know.isPremium ? (
            <TextOverflowTooltip
              textStyle="font-weight-medium font-size-sm mb-0"
              lineClamp={2}
              text={know.title}
              maxLength={30}
            />
          ) : (
            <TextOverflowTooltip
              textStyle="font-weight-medium font-size-sm text-truncate mb-0"
              text={know.title}
              maxLength={30}
            />
          )}
        </h6>
        {isNewItem ? (
          <div className="d-flex align-items-center fs-8 font-weight-medium text-black-50">
            Upload images, text & PDFs.
          </div>
        ) : (
          <div className="d-flex align-items-center fs-8 gap-1 text-black-50">
            {know.isPrivate && <LucideIcon icon="Lock" size={14} />}
            <IdfTooltip
              text={moment(know.updatedAt).format('MMMM DD, YYYY [at] hh:mm A')}
              placement="top"
            >
              <div className="d-flex align-items-center font-weight-medium gap-1">
                <LucideIcon icon="History" size={14} />
                {moment(know.updatedAt).fromNow()}
              </div>
            </IdfTooltip>
          </div>
        )}
      </div>
    </div>
  );
};

const Knowledge = () => {
  const { CreateKnowledgeModal, setShowModal: setShowCreateKnowledgeModal } =
    useCreateKnowledgeModal();
  const { updateHash } = useHash();
  const {
    archives,
    loading,
    premiumArchives,
    loadingPremiumArchives,
    searchValue,
    setSearchValue,
  } = useFetchArchives(true, 10, { hasPlanSubscriptionAssignment: true });
  const MyContentItems = [
    {
      id: 2,
      icon: 'Bookmark',
      type: 'bookmark',
      title: 'Bookmarks',
      static: true,
      isPrivate: true,
      isFavorite: false,
      updatedAt: '2024-11-02T14:30:00.000Z',
      onClick: () => {},
    },
  ];

  return (
    <ContentLayout
      heading={MenuItemsKeysAndIcons.Knowledge}
      search={
        <Search
          classnames="p-0 col-sm-6 position-relative"
          searchPlaceholder={'Search your Knowledge'}
          loading={loading}
          searchValue={searchValue}
          onHandleChange={(e) => setSearchValue(e.target.value)}
        />
      }
    >
      <CreateKnowledgeModal />
      <div className="chat-home-window-size mx-auto">
        <div className="d-flex align-items-center justify-content-between pr-3">
          <h4 className="font-bold text-lg my-4">My Knowledge</h4>
        </div>
        <div className="d-flex flex-wrap gap-4">
          <ArchiveItem
            know={{ id: 'AddNew', title: 'Create Knowledge' }}
            onClick={() => setShowCreateKnowledgeModal(true)}
          />
          {loading ? (
            <ArchiveItemSkeleton count={3} />
          ) : (
            <>
              {archives
                .concat(sectionVisible ? [] : MyContentItems)
                .map((archive) => (
                  <ArchiveItem
                    key={archive.archiveId}
                    know={archive}
                    icon={archive.type === 'bookmark' ? archive.icon : 'Files'}
                    type="created"
                    onClick={() =>
                      updateHash(`#knowledge/${archive.archiveId}`)
                    }
                  />
                ))}
            </>
          )}
        </div>
        {loadingPremiumArchives ? (
          <h4 className="font-bold d-flex align-items-center gap-4 text-lg my-4">
            <ArchiveItemSkeleton count={3} />
          </h4>
        ) : (
          <>
            {premiumArchives.length > 0 ? (
              <div>
                <h4 className="font-bold text-lg my-4">Premium Content</h4>
                <div className="d-flex flex-wrap gap-3 pb-6">
                  {premiumArchives.map((archive) => (
                    <ArchiveItem
                      key={archive.archiveId}
                      know={archive}
                      icon="Award"
                      type="created"
                      onClick={() => {}}
                    />
                  ))}
                </div>
              </div>
            ) : null}
          </>
        )}
      </div>
    </ContentLayout>
  );
};

export default Knowledge;
