import React, { useEffect, useState } from 'react';
import IdfTooltip from '../idfComponents/idfTooltip';
import { Dropdown } from 'react-bootstrap';
import ButtonIcon from '../commons/ButtonIcon';
import LucideIcon from '../commons/LucideIcon';
import TooltipComponent from '../lesson/Tooltip';
import TextOverflowTooltip from '../commons/TextOverflowTooltip';
import { getIconByMimeType, maxFileLimit } from './constants';
import { Spinner } from 'reactstrap';
import MaterialIcon from '../commons/MaterialIcon';

const FileAvatarGroup = ({ files, maxVisible = 2, spacing = -8 }) => {
  const total = files.length;
  const visibleFiles = files.slice(0, maxVisible);
  const remainingCount = total > maxVisible ? total - maxVisible : 0;
  const getPaddingRight = () => {
    if (total === 1) return 0;
    if (total === 2) return 30;
    return 60;
  };
  return (
    <div
      className="position-relative d-flex align-items-center"
      style={{
        marginRight: '30px',
        paddingRight: getPaddingRight(),
      }}
    >
      {visibleFiles.map((file, index) => (
        <div
          key={index}
          className="position-relative rounded-circle bg-white border-2 border-white"
          style={{
            marginLeft: index === 0 ? 0 : `${spacing}px`,
            zIndex: 1,
            width: 32,
            height: 32,
          }}
        >
          {file.type.startsWith('image/') ? (
            <img
              src={URL.createObjectURL(file)}
              // alt={file.name}
              className="w-100 h-100 object-cover rounded-circle"
            />
          ) : (
            <div
              className="d-flex align-items-center justify-content-center rounded-circle w-100 h-100"
              style={{
                background: getIconByMimeType(file.type).color,
              }}
            >
              <LucideIcon
                icon={getIconByMimeType(file.type).icon}
                color="#fff"
                size={16}
              />
            </div>
          )}
        </div>
      ))}

      {remainingCount > 0 && (
        <div
          className="position-relative d-flex align-items-center justify-content-center rounded-circle bg-primary-soft text-gray-800 font-weight-medium fs-7 border-width-2 border-solid border-white"
          style={{
            marginLeft: `${spacing}px`,
            zIndex: 2,
            width: 32,
            height: 32,
          }}
        >
          <span className="text-gray-800 font-medium text-xs d-flex align-items-center justify-content-center">
            +{remainingCount}
          </span>
        </div>
      )}
      <div
        className="fixed justify-content-center"
        style={{
          gap: `90px`,
        }}
      >
        <ButtonIcon
          color=""
          classnames="btn-sm icon-hover-bg p-0 font-size-sm border-2"
          icon="Paperclip"
          iconSize={16}
          useLucidIcon
          label=""
        />
      </div>
    </div>
  );
};

const FileAvatar = ({ group, files }) => {
  return (
    <span className="d-flex">
      <div className="d-flex align-items-center justify-content-center avatar avatar-circle border-0 bg-transparent">
        {group ? (
          <FileAvatarGroup files={files} />
        ) : (
          <>
            {files.length && files[0].type.startsWith('image/') ? (
              <div
                className="rounded-circle border overflow-hidden"
                style={{ width: 22, height: 22 }}
              >
                <img
                  src={URL.createObjectURL(files[0])}
                  alt={files[0].name}
                  className="w-100 h-100"
                  style={{ objectFit: 'contain' }}
                />
              </div>
            ) : (
              <>
                <div className="files-images">
                  <div className="files-icon">
                    <LucideIcon icon="File" size={18} color="#7f8c8d" />
                  </div>
                </div>
              </>
            )}
          </>
        )}
      </div>
    </span>
  );
};

function FilesDropdown({
  files,
  showControls = true,
  group, // converting avatar group style in toggle btn
  handleAttachClick,
  handleRemoveFile,
  triggerCameraClick,
  isAskIdaModal,
  fileProgress,
}) {
  const [show, setShow] = useState(false);

  useEffect(() => {
    if (files?.length === 0) {
      setShow(false);
    }
    if (!showControls && files.length > 0) {
      setShow(true);
    }
  }, [files]);

  return (
    <Dropdown
      drop="down"
      show={show}
      className="files-dropdown"
      onToggle={setShow}
    >
      <Dropdown.Toggle
        className={`w-100 p-0 bg-hover-white border-0 ${
          !showControls ? 'opacity-0 hide' : ''
        }`}
        variant="outline-link"
        id="dropdown-autoclose-true"
      >
        {isAskIdaModal ? (
          <FileAvatar group={group} files={files} />
        ) : (
          <IdfTooltip
            text={`Attach images, text or PDFs. ${
              maxFileLimit - files.length !== 0
                ? `${maxFileLimit - files.length} left.`
                : ''
            }`}
          >
            <span>
              <FileAvatar group={group} files={files} />
            </span>
          </IdfTooltip>
        )}
      </Dropdown.Toggle>

      <Dropdown.Menu
        id="dropdown-menu-files"
        className={`border border-1 py-0 px-2 overflow-auto ${
          !showControls && files.length > 0 ? 'files-dropdown-align-top' : ''
        }`}
        style={{ width: 400 }}
      >
        <div className="border-bottom d-flex align-items-center justify-content-between py-2">
          <h5 className="mb-0">
            {!showControls ? 'Uploads' : 'Attached Files'}
          </h5>
          <div
            className={`${
              showControls ? 'd-flex' : 'hide'
            } align-items-center gap-1 `}
          >
            <ButtonIcon
              color="white"
              classnames="btn-xs border-0"
              icon="Camera"
              iconSize={16}
              useLucidIcon
              label="Screenshot"
              disabled={files.length >= maxFileLimit}
              onClick={(e) => {
                triggerCameraClick(e);
                setShow(false);
              }}
            />
            <ButtonIcon
              color="white"
              classnames="btn-xs  border-0"
              icon="Plus"
              iconSize={16}
              useLucidIcon
              label="Add"
              disabled={files.length >= maxFileLimit}
              onClick={() => {
                handleAttachClick();
                setShow(false);
              }}
            />
            <ButtonIcon
              color="white"
              classnames="btn-xs border-0"
              icon="Trash2"
              iconSize={15}
              useLucidIcon
              label="Clear"
              onClick={() => {
                handleRemoveFile();
                setShow(false);
              }}
            />
          </div>
        </div>
        <div className="overflow-y-auto max-h-300">
          {files?.map((file, index) => (
            <div
              key={index}
              className={`px-1 py-2 d-flex align-items-center w-100 justify-content-between ${
                index !== files.length - 1 ? 'border-bottom' : ''
              }`}
            >
              <div className="d-flex gap-1 flex-fill align-items-center">
                {file.type.startsWith('image/') ? (
                  <div
                    className="rounded border img-border overflow-hidden"
                    style={{ width: 40, height: 40 }}
                  >
                    <img
                      src={URL.createObjectURL(file)}
                      alt={file.name}
                      className="w-100 h-100 object-cover"
                    />
                  </div>
                ) : (
                  <>
                    <div className="files-images">
                      <div
                        style={{ width: 40, height: 40 }}
                        className="bg-gray-300 d-flex justify-content-center align-items-center rounded"
                      >
                        <LucideIcon
                          icon={getIconByMimeType(file.type).icon}
                          size={22}
                          color={getIconByMimeType(file.type).color}
                        />
                      </div>
                    </div>
                  </>
                )}
                <TextOverflowTooltip
                  maxLength={30}
                  textStyle="text-muted font-size-sm font-weight-medium"
                  text={file.name}
                />
              </div>
              {showControls ? (
                <TooltipComponent title="Remove File">
                  <a
                    className="icon-hover-bg cursor-pointer"
                    onClick={(e) => {
                      e.preventDefault();
                      handleRemoveFile(index);
                    }}
                  >
                    <LucideIcon icon="X" />
                  </a>
                </TooltipComponent>
              ) : (
                <div className="d-flex align-items-center gap-1">
                  {fileProgress[`${file.name}_${file.lastModified}`] !== 100 ? (
                    <>
                      <Spinner className="spinner-grow-xs" />
                      <span className="fs-7">
                        {fileProgress[`${file.name}_${file.lastModified}`]
                          ? `${
                              fileProgress[
                                `${file.name}_${file.lastModified}`
                              ] || 0
                            }%`
                          : `0%`}
                      </span>
                    </>
                  ) : (
                    <MaterialIcon
                      icon="check_circle"
                      isLucide={false}
                      filled
                      clazz="text-green"
                    />
                  )}
                </div>
              )}
            </div>
          ))}
        </div>
      </Dropdown.Menu>
    </Dropdown>
  );
}

export default FilesDropdown;
