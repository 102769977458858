import { useState, useEffect } from 'react';
import TransitionGroup from 'react-transition-group/TransitionGroup';
import Collapse from '@mui/material/Collapse';
import { FILE_SIZE_LIMIT, getFileSize } from '../../../utils/Utils';
import stringConstants from '../../../utils/stringConstants.json';
import {
  checkAndAllowFileUpload,
  VALID_FILE_FORMATS,
} from '../../../utils/constants';
import feedService from '../../../services/feed.service';
import ActivityFile from '../../peopleProfile/contentFeed/ActivityFile';
import UploadFileModal from '../../modal/UploadFileModal';
import MaterialIcon from '../../commons/MaterialIcon';
import { Spinner } from 'reactstrap';

const constants = stringConstants.modals.uploadFileModal;

const FilePreview = ({ file, deleteFile }) => {
  const [fileInfo, setFileInfo] = useState({
    name: '',
    size: '',
  });

  useEffect(() => {
    setFileInfo((prev) => ({
      ...prev,
      name: file.name,
      size: getFileSize(file.size),
    }));
  }, [file]);

  return (
    <div className="js-dropzone dropzone-custom custom-file-boxed dz-clickable dz-started">
      <div className="col h-100 px-1 mb-2 dz-processing dz-success dz-complete">
        <div className="dz-preview dz-file-preview border shadow">
          <div
            className="d-flex justify-content-end rounded-circle z-index-99 shadow-lg bg-gray-300 text-gray-500 dz-close-icon position-absolute"
            style={{ top: -17, right: -17 }}
          >
            <a
              href=""
              onClick={(e) => deleteFile(e)}
              className="icon-hover-bg btn btn-link"
            >
              <MaterialIcon icon="close" />
            </a>
          </div>
          <div className="d-flex justify-content-between align-items-center">
            <div className="dz-details media">
              <span className="dz-file-initials text-capitalize">
                {fileInfo.name[0]}
              </span>
              <div className="media-body dz-file-wrapper">
                <h6 className="dz-filename">
                  <span className="dz-title">{fileInfo.name}</span>
                </h6>
                <div className="dz-size">
                  <strong>{fileInfo.size}</strong>
                </div>
              </div>
            </div>
            <Spinner className="spinner-grow-sm2" />
          </div>
        </div>
      </div>
    </div>
  );
};

const IdfUploadFiles = ({
  fileInput,
  deleteFile,
  setErrorMessage,
  setFileInput,
  setIsLoading,
  handleSubmit,
  loading,
  activityId,
  publicPage,
  organizationId,
  openFilesModal,
  setOpenFilesModal,
}) => {
  const [recentFiles, setRecentFiles] = useState([]);
  const [openUploadModal, setOpenUploadModal] = useState(false);

  useEffect(() => {
    if (publicPage) getRecentFiles();
  }, []);

  const getRecentFiles = () => {
    feedService
      .getFiles({ organizationId, activityId }, { limit: 5 })
      .then((res) => {
        setRecentFiles(res.files);
      })
      .catch(() => {
        setErrorMessage(constants.profile.getFileError);
      });
  };

  const onFileChange = (event) => {
    const file = event?.target?.files[0];

    const fileSize = file.size;
    const errors = [];

    // if file exceeds limit
    if (fileSize > FILE_SIZE_LIMIT) {
      errors.push(
        `File size exceeds ${getFileSize(FILE_SIZE_LIMIT, true)} limit.`
      );
    }
    // if file type not allowed
    if (!checkAndAllowFileUpload(file)) {
      errors.push(
        `Invalid file format. Upload file in these ${VALID_FILE_FORMATS.join(
          ','
        )} format.`
      );
    }

    if (errors.length) {
      return setErrorMessage(errors.join('\n'));
    }

    if (!file?.type) {
      const extensionIndex = file.name.indexOf('.');
      const extension = file.name.slice(extensionIndex + 1);

      const newFile = new Blob([file], {
        type: extension,
      });

      newFile.name = file.name.slice(0, extensionIndex);
      newFile.lastModifiedDate = file.lastModifiedDate;

      return setFileInput(newFile);
    }

    if (checkAndAllowFileUpload(file)) {
      return setFileInput(file);
    }
  };

  const onSubmit = () => {
    setIsLoading(true);
    handleSubmit(fileInput, setIsLoading, getRecentFiles);
  };

  useEffect(() => {
    fileInput && onSubmit();
  }, [fileInput]);

  return (
    <div className="position-relative">
      {fileInput ? (
        <>
          <FilePreview file={fileInput} deleteFile={deleteFile} />
        </>
      ) : (
        <>
          <div
            id="file"
            className="js-dropzone border-dashed-gray bg-gray-200 dropzone-custom rounded p-2 my-2"
          >
            <div
              className="media m-auto d-flex align-items-center justify-content-center"
              style={{ height: 40 }}
            >
              <h5 className="mb-0 d-flex font-weight-normal fs-7 text-icon align-items-center justify-content-center">
                <MaterialIcon
                  icon={`upload_file`}
                  size="fs-4"
                  clazz="font-weight-normal mr-2 text-icon"
                />
                {constants.dragAndDrop}
                {' or'}&nbsp;
                <a className="font-weight-semi-bold decoration-underline">
                  {constants.browseFiles}
                </a>
                &nbsp;
                {'for a file to upload'}
              </h5>
            </div>
          </div>

          <input
            className="file-input-drag"
            type="file"
            name="file"
            onChange={onFileChange}
            accept={VALID_FILE_FORMATS.join(',')}
            value={fileInput}
            id="file"
          />
        </>
      )}

      {publicPage && (
        <UploadFileModal
          setShowModal={setOpenUploadModal}
          showModal={openUploadModal}
          handleSubmit={handleSubmit}
          setErrorMessage={setErrorMessage}
          publicPage={publicPage}
        />
      )}

      {publicPage && (
        <>
          <ul className="list-group list-group-flush list-group-no-gutters my-3">
            <TransitionGroup appear={true}>
              {recentFiles?.map((file) => (
                <Collapse key={file.id}>
                  <ActivityFile
                    key={file.file_id}
                    file={file}
                    openFilesModal={openFilesModal}
                    publicPage={publicPage}
                  />
                </Collapse>
              ))}
            </TransitionGroup>
          </ul>

          {recentFiles?.length > 4 && (
            <button
              className="btn btn-white btn-block btn-sm"
              onClick={() => {
                setOpenFilesModal(true);
              }}
            >
              {constants.profile.viewAllLabel}
            </button>
          )}
        </>
      )}
    </div>
  );
};

export default IdfUploadFiles;
