import axios from './axiosInstance';
import authHeader from './auth-header';

class SearchService {
  async getSearchResults(searchQuery) {
    const { s, resource = 'all', type = 'all' } = searchQuery || {};

    const params = {
      s,
      resource,
      type,
    };

    return axios
      .get(`/search`, {
        params,
        headers: authHeader(),
      })
      .then((response) => response);
  }
}

export default new SearchService();
