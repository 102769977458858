import { Card, CardBody, CardFooter, CardHeader } from 'reactstrap';
import { useEffect, useState } from 'react';
import GenericTable from '../../../../components/GenericTable';
import { cubeService } from '../../../../services';
import { paginationDefault } from '../../../../utils/constants';
import DatePickerTraining from '../../../../components/dealsBreakdown/DatePickerTraining';
import Loading from '../../../../components/Loading';
import { AssignmentSnapshotWidget } from '../AssignmentSnapshotWidget';
import assignmentService from '../../../../services/assignment.service';
import { getTomorrowDate } from '../../../../utils/Utils';
import IdfTooltip from '../../../../components/idfComponents/idfTooltip';
import Pagination from '../../../../components/Pagination';
import {
  changePaginationLimit,
  changePaginationPage,
} from '../../../Deals/contacts/utils';
import { TrainingAssignmentStatisticsColumns } from '../../../../utils/learn.constants';
import NoDataFound from '../../../../components/commons/NoDataFound';

const AssignmentStatsNames = {
  AssignmentCompleted: 'Completed',
  AssignmentInProgress: 'In Progress',
  AssignmentNotStarted: 'Not Started',
  AssignmentOverDue: 'Overdue',
  peopleAssigned: 'Users Assigned',
  totalLessonCount: 'Lessons',
};

const assignmentStatsTooltip = {
  Completed: 'Users who have completed the assignment',
  'In Progress': 'Users who have completed some but not all of the assignment',
  'Not Started': 'Users who have not started the assignment',
  Overdue: 'Users who have not completed this assignment by the due date.',
  'Users Assigned': '# of users required to complete the assignment',
  Lessons: 'total # of lessons included in the assignment',
};

export const TrainingAssignmentStatistics = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [showCSVBtn, setShowCSVBtn] = useState(false);
  const [allAssignments, setAllAssignments] = useState([]);
  const [dateRange, setDateRange] = useState({
    startDate: new Date(1970, 0, 1),
    endDate: getTomorrowDate(),
  });

  const [pagination, setPagination] = useState(paginationDefault);
  const [paginationPage, setPaginationPage] = useState(paginationDefault);

  useEffect(() => {
    (async () => {
      const { data } = await assignmentService.getAssignments({
        page: 1,
        limit: 1000,
      });
      const updatedData = data?.map((item) => ({
        ...item,
        name: item?.title,
      }));
      setAllAssignments(updatedData);
    })();
  }, []);

  const getData = async () => {
    setLoading(true);
    const results = await cubeService.loadCustomAnalytics({
      type: 'AssignmentProgress',
      limit: paginationPage?.limit,
      page: paginationPage?.page,
      startDate: dateRange?.startDate,
      endDate: dateRange?.endDate,
    });
    setShowCSVBtn(results?.length > 0);
    setData(results);
    setLoading(false);
    setPagination(paginationPage); // Todo:- will update with response pagination when API's will change
  };

  useEffect(() => {
    getData();
  }, [paginationPage]);

  const rows = data?.map((result, index) => {
    return {
      id: index,
      dataRow: [
        {
          key: 'assignment',
          component: <span className="fw-bold">{result?.assignment}</span>,
        },
        {
          key: 'lessonCount',
          component: result?.totalLessonCount,
        },
        {
          key: 'date',
          component: result?.dueDate,
        },

        {
          key: 'assigned',
          component: `${result?.peopleAssigned} people`,
        },
        {
          key: 'completed',
          component: `${result?.assignmentCompleted}`,
        },
        {
          key: 'progress',
          component: `${result?.assignmentInProgress}`,
        },
        {
          key: 'not_started',
          component: `${result?.assignmentNotStarted}`,
        },
        {
          key: 'overdue',
          component: result?.assignmentOverDue,
        },
      ],
    };
  });

  const updatedRows = data?.map((item) => {
    return {
      assignmentName: item?.assignment,
      dueDate: item?.dueDate,
      peopleAssigned: item?.peopleAssigned,
      completed: item?.assignmentCompleted,
      inProgress: item?.assignmentInProgress,
      notStarted: item?.assignmentNotStarted,
      overdue: item?.assignmentOverDue,
    };
  });

  const toggleModify = () => {
    setPaginationPage((prev) => ({ ...prev, page: 1 }));
  };

  return (
    <>
      <Card className="h-100 border-0 shadow-0">
        <CardHeader>
          <div className="d-flex justify-content-between align-items-center w-100">
            <IdfTooltip text="Assignment Statistics" placement="bottom">
              <h4 className="card-title text-hover-primary mb-0 text-truncate">
                Assignment Statistics
              </h4>
            </IdfTooltip>
            <div
              className={`d-flex align-items-center gap-2 ${
                showCSVBtn ? 'mr-6 pr-9' : ''
              }`}
            >
              <DatePickerTraining
                range={dateRange}
                setRange={setDateRange}
                toggle={toggleModify}
                btnToggleStyle="mx-0 w-250"
              />
            </div>
          </div>
        </CardHeader>

        <CardBody className="p-0 flex-1 overflow-y-auto">
          {loading ? (
            <div className="py-5 text-center w-100">
              <Loading />
            </div>
          ) : (
            <div>
              {data?.length === 0 ? (
                <NoDataFound
                  icon="manage_search"
                  title="No data found"
                  containerStyle="text-gray-search my-6 py-6"
                  titleStyle="font-normal font-size-sm2 text-gray-search"
                />
              ) : (
                <>
                  <AssignmentSnapshotWidget
                    allSelectedData={allAssignments}
                    param="assignments"
                    assignmentStatsTooltip={assignmentStatsTooltip}
                    assignmentStatsNames={AssignmentStatsNames}
                  />

                  <GenericTable
                    checkbox={false}
                    data={rows}
                    exportToCSV={showCSVBtn}
                    tableData={updatedRows}
                    fileName="AssignmentStatistics"
                    columns={TrainingAssignmentStatisticsColumns}
                    usePagination={false}
                    noDataInDbValidation={true}
                  />
                </>
              )}
            </div>
          )}
        </CardBody>

        <CardFooter className="border-top bg-gray-table-head p-2 border-bottom-0 border-left-0 border-right-0 rounded-0">
          <div className="d-flex align-items-center justify-content-end">
            <Pagination
              showPerPage
              showPages={false}
              paginationInfo={pagination}
              onPageChange={(newPage) => {
                changePaginationPage(newPage, setPaginationPage);
              }}
              onLimitChange={(perPage) => {
                changePaginationLimit(perPage, setPaginationPage);
              }}
            />
          </div>
        </CardFooter>
      </Card>
    </>
  );
};
