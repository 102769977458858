import React from 'react';
import PageTitle from '../../commons/PageTitle';
import Table from '../../GenericTable';

import StatCardList from '../shared/StatCardList';
import {
  clientTierColumns,
  clientTierData,
  clientTierRows,
  metricCardsData,
  overdueTasksColumns,
  overdueTasksData,
  overdueTasksRows,
  relationshipManagersColumns,
  relationshipManagersData,
  relationshipManagersRows,
  upcomingEventsColumns,
  upcomingEventsData,
  upcomingEventsRows,
} from './activities-data';
import usePagination from '../../../hooks/usePagination';
import TableWithPagination from './PaginationTable';

const ActivitiesHome = () => {
  const relationshipManagersPagination = usePagination(
    relationshipManagersRows
  );
  return (
    <div className="activities-container">
      <PageTitle page="Activities" />

      <div className="dashboard-container">
        <div className="d-grid grid-col-3 gap-3 mb-3">
          {metricCardsData.map((item, index) => (
            <StatCardList key={index} item={item} />
          ))}
        </div>

        <TableWithPagination
          title="Activities by Relationship Manager"
          icon="Users"
          data={relationshipManagersData}
          paginatedData={relationshipManagersPagination.getPaginatedData(
            relationshipManagersRows
          )}
          columns={relationshipManagersColumns}
          pagination={relationshipManagersPagination}
          emptyDataText="No relationship manager data available"
        />

        <div className="mb-3">
          <StatCardList
            item={{
              icon: 'AlarmClock',
              title: 'Overdue Tasks',
            }}
          >
            <div className="d-flex flex-column">
              <div className="table-min-height">
                <div className="table-responsive-md datatable-custom">
                  <div
                    id="datatable_wrapper"
                    className="dataTables_wrapper no-footer h-auto"
                  >
                    <Table
                      data={overdueTasksRows}
                      columns={overdueTasksColumns}
                      dataInDB={overdueTasksData.length}
                      showLoading={false}
                      emptyDataText="No overdue tasks"
                      headClass="bg-gray-table-head"
                    />
                  </div>
                </div>
              </div>
            </div>
          </StatCardList>
        </div>
        <div className="mb-3">
          <StatCardList
            item={{
              icon: 'CalendarClock',
              title: 'Upcoming Calls & Events',
            }}
          >
            <div className="d-flex flex-column">
              <div className="table-min-height">
                <div className="table-responsive-md datatable-custom">
                  <div
                    id="datatable_wrapper"
                    className="dataTables_wrapper no-footer h-auto"
                  >
                    <Table
                      data={upcomingEventsRows}
                      columns={upcomingEventsColumns}
                      dataInDB={upcomingEventsData.length}
                      showLoading={false}
                      emptyDataText="No upcoming events"
                      headClass="bg-gray-table-head"
                    />
                  </div>
                </div>
              </div>
            </div>
          </StatCardList>
        </div>
        <div className="mb-0">
          <StatCardList
            item={{
              icon: 'ChartBar',
              title: 'Activity Completion by Client Tier',
            }}
          >
            <div className="d-flex flex-column">
              <div className="table-min-height">
                <div className="table-responsive-md datatable-custom">
                  <div
                    id="datatable_wrapper"
                    className="dataTables_wrapper no-footer h-auto"
                  >
                    <Table
                      data={clientTierRows}
                      columns={clientTierColumns}
                      dataInDB={clientTierData.length}
                      showLoading={false}
                      emptyDataText="No client tier data available"
                      headClass="bg-gray-table-head"
                    />
                  </div>
                </div>
              </div>
            </div>
          </StatCardList>
        </div>
      </div>
    </div>
  );
};

export default ActivitiesHome;
