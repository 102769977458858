import React, { useEffect, useState } from 'react';
import { Card, CardBody, CardFooter, CardHeader } from 'reactstrap';
import { paginationDefault } from '../../../../utils/constants';
import LessonStatistics from '../LessonStatistics';
import { cubeService } from '../../../../services';
import DatePickerTraining from '../../../../components/dealsBreakdown/DatePickerTraining';
import NoDataFound from '../../../../components/commons/NoDataFound';
import { getTomorrowDate } from '../../../../utils/Utils';
import IdfTooltip from '../../../../components/idfComponents/idfTooltip';
import Pagination from '../../../../components/Pagination';
import {
  changePaginationLimit,
  changePaginationPage,
} from '../../../Deals/contacts/utils';
import TableSkeleton from '../../../../components/commons/TableSkeleton';

const TrainingLessonStatistics = ({ dashboard, selectedComponent = {} }) => {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);

  const [dateRange, setDateRange] = useState({
    startDate: new Date(1970, 0, 1),
    endDate: getTomorrowDate(),
  });

  const [pagination, setPagination] = useState(paginationDefault);
  const [paginationPage, setPaginationPage] = useState(paginationDefault);

  const getLessonsStats = async () => {
    setLoading(true);
    try {
      const results = await cubeService.loadCustomAnalytics({
        type: 'LessonStatistics',
        limit: paginationPage?.limit,
        page: paginationPage?.page,
        startDate: dateRange?.startDate,
        endDate: dateRange?.endDate,
      });
      setData(results);
      setPagination(paginationPage); // Todo:- will update with response pagination when API's will change
    } catch (e) {
      console.log(e);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getLessonsStats();
  }, [selectedComponent, paginationPage]);

  const toggleModify = () => {
    setPaginationPage((prev) => ({ ...prev, page: 1 }));
  };

  const loader = () => {
    if (loading) return <TableSkeleton cols={6} rows={10} />;
  };

  return (
    <Card className="h-100 border-0 shadow-0">
      <CardHeader>
        <div className="d-flex justify-content-between align-items-center w-100">
          <IdfTooltip text={dashboard?.name} placement="bottom">
            <h4 className="card-title text-hover-primary mb-0 text-truncate">
              {dashboard?.name}
            </h4>
          </IdfTooltip>

          <div
            className={`d-flex align-items-center gap-2 ${
              data?.length ? 'mr-6 pr-9' : ''
            }`}
          >
            <DatePickerTraining
              range={dateRange}
              setRange={setDateRange}
              toggle={toggleModify}
              btnToggleStyle="mx-0 w-250"
            />
          </div>
        </div>
      </CardHeader>

      <CardBody className="p-0 flex-1 overflow-y-auto">
        {loading ? (
          loader()
        ) : (
          <div>
            {data?.length === 0 ? (
              <NoDataFound
                icon="manage_search"
                title="No data found"
                containerStyle="text-gray-search my-6 py-6"
                titleStyle="font-normal font-size-sm2 text-gray-search"
              />
            ) : (
              <LessonStatistics results={data} />
            )}
          </div>
        )}
      </CardBody>

      <CardFooter className="border-top bg-gray-table-head p-2 border-bottom-0 border-left-0 border-right-0 rounded-0">
        <div className="d-flex align-items-center justify-content-end">
          <Pagination
            showPerPage
            showPages={false}
            paginationInfo={pagination}
            onPageChange={(newPage) => {
              changePaginationPage(newPage, setPaginationPage);
            }}
            onLimitChange={(perPage) => {
              changePaginationLimit(perPage, setPaginationPage);
            }}
          />
        </div>
      </CardFooter>
    </Card>
  );
};

export default TrainingLessonStatistics;
