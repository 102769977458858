import MaterialIcon from '../../commons/MaterialIcon';

export const MerchantsAlert = ({ data, startDownload }) => {
  return (
    <div
      className={`d-flex gap-3 p-3 align-items-center report-widget border-2 rpt-rounded rpt-blue-box ${data?.classnames}`}
    >
      {data?.icon && (
        <span className="p-2 bg-white rounded-lg">
          <MaterialIcon
            icon={data?.icon}
            size={startDownload ? 'font-size-4em' : 'font-size-2xl'}
            clazz={`bg-white rounded-lg ${data?.color}`}
          />
        </span>
      )}

      <p className={`mb-0 px-1 font-size-sm2 ${data?.textClass}`}>
        {data?.desc}
      </p>
    </div>
  );
};
