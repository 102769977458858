import MaterialIcon from '../../commons/MaterialIcon';
import {
  addressify,
  numbersWithComma,
  roundNumbers,
} from '../../../utils/Utils';
import ProfilePicOrFallbackAvatar from '../../commons/ProfilePicOrFallbackAvatar';
import ViewMoreLess from '../../commons/ViewMoreLess';
import React from 'react';
import LinkWrapper from '../../modal/LinkWrapper';

const pretty = (value) => {
  if (!value) {
    return 'N/A';
  }

  return value;
};

const LookupOrganizationItem = ({
  label,
  text,
  icon,
  isLink,
  isMaterialIcon = true,
}) => {
  return (
    <>
      {text !== 'N/A' && (
        <div className="m-0 p-0 lead fs-7 d-flex align-items-center py-1">
          <div className="p-1 bg-gray-300 rounded-circle mr-1 text-icon">
            <MaterialIcon icon={icon} size="font-size-sm" clazz="d-flex" />
          </div>
          <span>
            <span className="font-weight-semi-bold mr-1">{label}:</span>
            <>
              {isLink ? (
                <LinkWrapper href={`https://${text}`}>{text}</LinkWrapper>
              ) : (
                <span>{text}</span>
              )}{' '}
            </>
          </span>
        </div>
      )}
    </>
  );
};

const RocketReachOrganizationCard = ({ prospect, showDescription = true }) => {
  return (
    <>
      <div className="d-flex align-items-center">
        <div className="mr-2">
          <ProfilePicOrFallbackAvatar
            prospect={prospect}
            style={{ width: 70, height: 70 }}
          />
        </div>
        <div>
          <h4 className="prospect-typography-h4 mb-0 text-left">
            {pretty(prospect.name)}
          </h4>
        </div>
      </div>

      <div className="text-left fs-7 mt-3">
        <LookupOrganizationItem
          label="Website"
          text={pretty(prospect.domain)}
          icon="language"
          isLink={true}
        />
        {prospect.ticker && (
          <LookupOrganizationItem
            label="Ticker"
            text={prospect.ticker}
            icon="area_chart"
            isMaterialIcon={true}
          />
        )}
        {prospect.revenue && (
          <LookupOrganizationItem
            label="Revenue"
            text={`$${pretty(roundNumbers(prospect.revenue, 'long', 2))}`}
            icon="monetization_on"
          />
        )}
        {prospect.employees && (
          <LookupOrganizationItem
            label="Employees"
            text={numbersWithComma(prospect.employees)}
            icon="people"
          />
        )}
        <LookupOrganizationItem
          label="Founded"
          text={pretty(prospect.founded || prospect.year_founded)}
          icon="flag"
        />
        <LookupOrganizationItem
          label="Address"
          text={addressify(prospect, 'company')}
          icon="location_on"
        />
        <LookupOrganizationItem
          label="Phone"
          text={pretty(prospect.phone)}
          icon="phone"
        />
        <LookupOrganizationItem
          label="Fax"
          text={pretty(prospect.fax)}
          icon="fax"
        />
        <LookupOrganizationItem
          label="SIC"
          text={pretty(prospect.sic)}
          icon="merge_type"
        />
        <LookupOrganizationItem
          label="NAICS"
          text={pretty(prospect.naics)}
          icon="numbers"
        />
        {showDescription && prospect?.description && (
          <div className="m-0 p-0 py-1">
            <MaterialIcon
              icon="text"
              size="fs-7"
              clazz="p-1 bg-gray-300 rounded-circle mr-1 text-black"
            />
            <span>
              <span className="font-weight-semi-bold">Description:</span>{' '}
              <ViewMoreLess text={prospect?.description} limit={150} />
            </span>
          </div>
        )}
      </div>
    </>
  );
};

export default RocketReachOrganizationCard;
