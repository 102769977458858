import React, { useState, useEffect } from 'react';
import { Badge, Spinner } from 'reactstrap';
import { useHistory } from 'react-router-dom';

import Avatar from '../Avatar';
import Alert from '../Alert/Alert';
import AlertWrapper from '../Alert/AlertWrapper';
import Heading from '../heading';
import userService from '../../services/user.service';
import contactService from '../../services/contact.service';
import stringConstants from '../../utils/stringConstants.json';
import routes from '../../utils/routes.json';
import IdfOwnersHeader from '../idfComponents/idfAdditionalOwners/IdfOwnersHeader';
import ModalAvatar from '../modal/ModalAvatar';
import filesService from '../../services/files.service';
import { useProfileContext } from '../../contexts/profileContext';
import {
  base64ToBlob,
  isPermissionAllowed,
  isValidUrl,
} from '../../utils/Utils';
import DeleteConfirmationModal from '../modal/DeleteConfirmationModal';
import PageTitle from '../commons/PageTitle';
import ContactCompanyDeleteMenu from '../commons/ContactCompanyDeleteMenu';
import MaterialIcon from '../commons/MaterialIcon';
import TooltipComponent from '../lesson/Tooltip';
import TextOverflowTooltip from '../commons/TextOverflowTooltip';
import AvatarHoverImage from '../AvatarHoverImage';

const globalStrings = stringConstants.global.avatar;
const organizationConstants = stringConstants.deals.organizations;
const contactConstants = stringConstants.deals.contacts;

const Header = ({
  moduleMap,
  contactId,
  data,
  refreshOwners,
  setRefresOwners,
  setOpenCloneModal,
  isPrincipalOwner,
  openCompanyModal,
  setEditModal,
}) => {
  const [openModal, setOpenModal] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [userInfo, setUserInfo] = useState({});
  const [loading, setLoading] = useState(false);
  const [openModalAvatar, setOpenModalAvatar] = useState(false);
  const [fullname, setFullName] = useState('Contact Profile');
  const history = useHistory();

  useEffect(() => {
    setFullName(
      userInfo.first_name
        ? userInfo.first_name +
            ' ' +
            (userInfo.last_name ? userInfo.last_name : ' ')
        : `${moduleMap} Profile`
    );
  }, [userInfo]);

  useEffect(() => {
    setUserInfo(data);
  }, [data]);

  const handleDelete = async () => {
    try {
      const response = await contactService.deleteContacts([userInfo.id]);
      if (response[0].result === 'success') {
        setTimeout(() => {
          history.push(`${routes.caccounts}#contacts`);
        }, 1000);
      }
    } catch (error) {
      setErrorMessage(error.message);
    }
  };

  const updateContactAvatar = (avatarId) => {
    contactService
      .updateContact(userInfo.id, { avatar: avatarId })
      .then(() => {
        setSuccessMessage(
          avatarId
            ? `Contact ${globalStrings.uploadSuccess}`
            : `Contact ${globalStrings.removedSuccess}`
        );
        setOpenModalAvatar(false);
      })
      .catch(() => {
        setErrorMessage(globalStrings.uploadError);
      });
  };

  const onHandleSaveAvatar = async ({ src, name }) => {
    setLoading(true);
    // onUploadAvatar
    const form = new FormData();
    form.append('file', await base64ToBlob(src), name);
    const avatarResult = await userService.uploadAvatar(form).catch((_) => {
      setErrorMessage(globalStrings.uploadError);
    });

    const result = avatarResult?.data;

    setUserInfo((prev) => ({
      ...prev,
      avatar: result.data.id,
      avatarSrc: src,
    }));

    if (result?.data?.id) {
      await updateContactAvatar(result.data.id);
      setLoading(false);
    }
  };

  const removeFile = async () => {
    if (userInfo?.avatar && isValidUrl(userInfo?.avatar)) {
      updateContactAvatar(null);
      setUserInfo((prev) => ({
        ...prev,
        avatar: null,
        avatarSrc: null,
      }));
    } else {
      setLoading(true);
      filesService
        .removeFileById(userInfo.avatar)
        .then(() => {
          updateContactAvatar(null);
          setUserInfo((prev) => ({
            ...prev,
            avatar: null,
            avatarSrc: null,
          }));
          setLoading(false);
        })
        .catch(() => {
          setErrorMessage(globalStrings.uploadError);
        });
    }
  };

  // All States
  const { profileInfo } = useProfileContext();
  const [openOwnerModal, setOpenOwnerModal] = useState(false);

  const [isFollowing, setIsFollowing] = useState(false);
  const [isOwner, setIsOwner] = useState(true);
  const [followersInfo, setFollowersInfo] = useState([]);
  const [userId, setUserId] = useState();
  const [openAddFollower] = useState(false);
  const [spinner, setSpinner] = useState(false);

  const [ownerInfo, setOwnerInfo] = useState({});

  useEffect(() => {
    onGetOwners();
  }, []);

  useEffect(() => {
    if (userId) {
      checkFollower();
    }
  }, [userId, followersInfo]);

  useEffect(() => {
    if (!openAddFollower) onGetOwners();
  }, [isFollowing, openAddFollower]);

  useEffect(() => {
    getUserId();
  }, [profileInfo]);

  // All function
  const getUserId = () => {
    const id = profileInfo?.id;
    if (id) {
      setUserId(id);
    }
  };

  const getOwners = async (pagination) => {
    return await contactService
      .getOwners(contactId, pagination)
      .catch((err) => console.log(err));
  };

  const onGetOwners = async (pagination = { page: 1, limit: 10 }) => {
    const requests = [];
    requests.push(getOwners(pagination));
    const resp = await Promise.all(requests);
    const owners = resp[0];

    const { data } = owners || {};

    const ownersList = data.map((ow) => ({
      ...ow,
      user: { ...ow.user, type: 'owner' },
    }));
    setFollowersInfo([...ownersList]);
  };

  const stopFollowing = async () => {
    setSpinner(true);
    await contactService
      .stopFollowing(contactId, userId)
      .catch(() =>
        setErrorMessage('There is something wrong in Stop Following!')
      );
    await onGetOwners();
    setSpinner(false);
  };

  const startFollowing = async () => {
    setSpinner(true);
    await contactService
      .startFollowing(contactId, userId)
      .catch(() =>
        setErrorMessage('There is something wrong in Start Following!')
      );
    await onGetOwners();
    setSpinner(false);
  };

  const checkFollower = async () => {
    const result = await contactService.checkFollowing(contactId, userId);
    const IsOwner =
      followersInfo.filter((follower) => {
        return follower.user_id === userId && follower.user.type !== 'follower';
      }).length > 0;

    setIsOwner(IsOwner);
    setIsFollowing(result);
  };

  const setDeleteOwner = (profile) => {
    setOwnerInfo(profile.user);
    setOpenOwnerModal(true);
  };

  const deleteOwner = async () => {
    try {
      await contactService.removeOwner(contactId, ownerInfo.id);
      setRefresOwners(true);
    } catch (error) {
      setErrorMessage(error.message);
    }
  };

  const heading = `Delete ${moduleMap.contact?.singular}`;
  const description = `Are you sure you want to delete this ${moduleMap.contact?.singular}?`;

  return (
    <div className="page-header contact-header p-3 mb-0">
      <PageTitle page={fullname} pageModule="Contacts" />
      <AlertWrapper>
        <Alert
          message={errorMessage}
          setMessage={setErrorMessage}
          color="danger"
        />
        <Alert
          message={successMessage}
          setMessage={setSuccessMessage}
          color="success"
        />
      </AlertWrapper>

      <DeleteConfirmationModal
        showModal={openModal}
        setShowModal={setOpenModal}
        event={handleDelete}
        heading={heading}
        description={description}
        extraBody={
          <p className="mt-2 mb-0">{contactConstants.delete.confirm}</p>
        }
      />

      <DeleteConfirmationModal
        showModal={openOwnerModal}
        setShowModal={setOpenOwnerModal}
        handleCloseModal={() => {
          setOwnerInfo({});
        }}
        event={deleteOwner}
        heading={organizationConstants.ownerDelete.heading}
        description={organizationConstants.ownerDelete.confirm}
        positiveBtnText={organizationConstants.ownerDelete.yesDelete}
      />

      <ModalAvatar
        open={openModalAvatar}
        onHandleClose={() => setOpenModalAvatar(false)}
        userInfo={userInfo}
        onRemove={removeFile}
        loading={loading}
        onSaveAvatar={onHandleSaveAvatar}
        type="contact"
      />

      <div className="row">
        <div className="col-sm mb-2 mb-sm-0">
          <Heading useBc title={fullname} showGreeting={false} />
          <div className="d-flex gap-1 align-items-center">
            <button
              className="btn btn-sm d-flex align-items-center icon-hover-bg p-0"
              onClick={() => history.push(`${routes.caccounts}#contacts`)}
            >
              <MaterialIcon icon="keyboard_backspace" size="fs-3" />
            </button>

            {isPermissionAllowed('contacts', 'edit') ? (
              <AvatarHoverImage
                type="contact"
                data={userInfo}
                size="lg"
                setOpenModalAvatar={setOpenModalAvatar}
              />
            ) : (
              <label
                className="avatar avatar-lg avatar-circle border-solid border-width-2 border-primary-soft avatar-uploader"
                htmlFor="avatarUploader"
              >
                <Avatar
                  classModifiers="max-wh bg-primary-soft text-primary"
                  sizeIcon="font-size-lg"
                  user={userInfo}
                />
              </label>
            )}

            <div className="d-flex flex-column ml-2">
              <div className="d-flex gap-3 align-items-center">
                <TextOverflowTooltip
                  maxLength={33}
                  text={fullname}
                  capitalizeText={false}
                  textStyle="page-header-title fs-18 text-truncate font-weight-semi-bold mb-0"
                />

                {userInfo.is_customer ? (
                  <>
                    <Badge
                      color={`success`}
                      style={{ fontSize: '11px' }}
                      className="text-uppercase"
                    >
                      {
                        stringConstants.deals.organizations.profile
                          .customerTitle
                      }
                    </Badge>
                  </>
                ) : (
                  <>
                    <Badge
                      id={userInfo?.label?.id}
                      style={{
                        fontSize: '11px',
                        backgroundColor: `${userInfo?.label?.color}`,
                      }}
                      className="text-uppercase"
                    >
                      {userInfo?.label?.name}
                    </Badge>
                  </>
                )}

                <IdfOwnersHeader
                  className="mx-0"
                  mainOwner={data?.assigned_user}
                  service={contactService}
                  onClick={setDeleteOwner}
                  serviceId={data?.id}
                  maxOwners={20}
                  refreshOwners={refreshOwners}
                  setRefresOwners={setRefresOwners}
                  isprincipalowner={isPrincipalOwner}
                  animateOnHover={true}
                  headerType={{ collection: 'contacts', action: 'edit' }}
                />

                {isOwner ? (
                  <>
                    <div className="d-flex align-items-center">
                      <TooltipComponent title="You can't follow your own contact">
                        <button disabled className="btn follow-tag ml-0">
                          Follow
                        </button>
                      </TooltipComponent>
                    </div>
                  </>
                ) : isFollowing ? (
                  <>
                    <div className="d-flex align-items-center">
                      <button
                        className={`btn follow-tag ml-0 ${
                          spinner === true && 'disable-btn'
                        }`}
                        onClick={stopFollowing}
                      >
                        <span className="following-tag">Following</span>
                        <span className="unfollow-tag">Unfollow</span>
                      </button>
                    </div>
                    {spinner === true && (
                      <Spinner color="black" size="sm" className="m-1" />
                    )}
                  </>
                ) : (
                  <>
                    {isPermissionAllowed('contacts', 'create') && (
                      <>
                        <TooltipComponent title="Follow this contact to get notified of any updates">
                          <button
                            className={`follow-tag btn ml-0 d-inline-flex align-items-center ${
                              spinner === true && 'disable-btn'
                            }`}
                            onClick={startFollowing}
                          >
                            Follow
                          </button>
                        </TooltipComponent>
                        {spinner === true && (
                          <Spinner color="black" size="sm" className="m-1" />
                        )}
                      </>
                    )}
                  </>
                )}
              </div>

              {data?.organization && (
                <div className="d-flex mt-2">
                  <a onClick={openCompanyModal} className="cursor-pointer">
                    {data?.organization?.name}
                  </a>
                </div>
              )}
            </div>

            <div className="client-share col-lg d-flex gap-3">
              {isPermissionAllowed('contacts', 'edit') && (
                <a
                  className="icon-hover-bg-soft-primary cursor-pointer border p-2"
                  onClick={() => setEditModal(true)}
                >
                  <MaterialIcon icon="edit" />
                </a>
              )}

              <ContactCompanyDeleteMenu
                isPrincipalOwner={data?.assigned_user_id === profileInfo?.id}
                permission={{
                  collection: 'contacts',
                  action: 'delete',
                }}
                setOpenModal={setOpenModal}
                setOpenCloneModal={setOpenCloneModal}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Header;
