import { isLucidIcon } from '../../../commons/IconDropdownSearch';
import MaterialIcon from '../../../commons/MaterialIcon';
import React from 'react';

const CutoutIcon = ({ whenPrinting, top, textConfig, iconConfig }) => {
  return (
    <div
      className={`d-flex px-2 align-items-center bg-rpt-icon justify-content-center position-absolute left-0 ${
        whenPrinting ? 'ml-2 gap-1' : 'ml-3 gap-2'
      }`}
      style={{
        top,
        background: 'linear-gradient(to bottom, #fff, #fff 100%)',
      }}
    >
      <span
        className={`rounded-circle d-flex align-items-center justify-content-center ${iconConfig.bg} ${iconConfig.round}`}
      >
        <>
          {iconConfig?.filled ? (
            <MaterialIcon
              icon={iconConfig?.icon || 'calendar_clock'}
              symbols
              isLucide={isLucidIcon(iconConfig?.icon || 'calendar_clock')}
              rounded={iconConfig?.rounded}
              filled
              clazz={iconConfig.fg}
              size={iconConfig.iconSize}
            />
          ) : (
            <MaterialIcon
              icon={iconConfig?.icon || 'calendar_clock'}
              rounded={iconConfig?.rounded}
              symbols
              isLucide={isLucidIcon(iconConfig?.icon || 'calendar_clock')}
              clazz={iconConfig.fg}
              size={iconConfig.iconSize}
            />
          )}
        </>
      </span>
      <p
        className={`mb-0 text-left font-weight-semi-bold ${textConfig.size}`}
        dangerouslySetInnerHTML={{ __html: textConfig.text }}
      />
    </div>
  );
};
const CutoutWidget = ({
  widgetConfig,
  cutoutConfig = {},
  whenPrinting,
  children,
  printingClasses = 'pt-3 mt-4',
}) => {
  return (
    <div
      style={{
        gap: 2,
      }}
      className={`d-flex border-2 position-relative align-items-center justify-content-center ${
        cutoutConfig.bg
      } ${whenPrinting ? printingClasses : 'pt-4 mt-6'}`}
    >
      {whenPrinting ? (
        <CutoutIcon
          top={-23}
          iconConfig={{
            round: 'p-2',
            size: 'fs-2',
            ...cutoutConfig.iconConfig,
          }}
          textConfig={{ size: 'fs-8', ...cutoutConfig }}
          whenPrinting={true}
        />
      ) : (
        <CutoutIcon
          top={-24}
          iconConfig={{
            round: 'p-2',
            iconSize: 'fs-2',
            ...cutoutConfig.iconConfig,
          }}
          textConfig={{ size: 'fs-8', ...cutoutConfig }}
        />
      )}
      {children}
    </div>
  );
};

export default CutoutWidget;
