import React from 'react';
import { Spinner } from 'reactstrap';
import TooltipComponent from '../lesson/Tooltip';
import MaterialIcon from './MaterialIcon';
import LucideIcon from './LucideIcon';

const ButtonIcon = ({
  onclick,
  label = 'Default',
  icon = '',
  useLucidIcon = false,
  loading = false,
  loadingText = '',
  color = 'primary',
  classnames = '',
  iconClass,
  iconSize = 'fs-5',
  tooltip,
  tooltipTextFont,
  symbols = false,
  rounded = false,
  filled = false,
  disabled = false,
  centered = 'justify-content-center',
  ...restProps
}) => {
  const ChargeLoad = () => {
    if (loading)
      return (
        <>
          {loadingText ? (
            <div className="d-flex align-items-center justify-content-center gap-1">
              <Spinner className="spinner-grow-xs" />
              <span>{loadingText}</span>
            </div>
          ) : (
            <Spinner className="spinner-grow-xs" />
          )}
        </>
      );

    return (
      <>
        <div className={`d-flex align-items-center ${centered} w-100`}>
          {icon && (
            <>
              {useLucidIcon ? (
                <LucideIcon
                  icon={icon}
                  clazz={`${iconClass} ${label ? 'mr-1' : ''}`}
                  size={iconSize}
                />
              ) : (
                <MaterialIcon
                  icon={icon}
                  clazz={`${iconClass} ${label ? 'mr-1' : ''}`}
                  filled={filled}
                  size={iconSize}
                  isLucide={false}
                  rounded={rounded}
                  symbols={symbols}
                />
              )}
            </>
          )}
          <span className="btn-icon-label">{tooltip ? '' : label}</span>
          {tooltip && (
            <TooltipComponent tooltipTextFont={tooltipTextFont} title={tooltip}>
              <span>{tooltip ? label : ''}</span>
            </TooltipComponent>
          )}
        </div>
      </>
    );
  };
  const ButtonLabels = ['Save', 'Update', 'Create'];
  const btnClass = `btn btn-${color} ${classnames} font-weight-medium button-icon`;
  const btnClassMinWidth = ButtonLabels.includes(label)
    ? `${btnClass} min-w-100`
    : btnClass || '';
  return (
    <button
      className={btnClassMinWidth}
      data-toggle="modal"
      onClick={!disabled && onclick}
      disabled={disabled}
      {...restProps}
    >
      <ChargeLoad />
    </button>
  );
};

export default ButtonIcon;
