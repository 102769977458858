import MaterialIcon from './MaterialIcon';
import React from 'react';

const MaterialCheckbox = ({ checked }) => {
  return (
    <a className="icon-hover-bg cursor-pointer">
      <MaterialIcon
        icon={checked ? 'check_box' : 'check_box_outline_blank'}
        rounded
        filled
        isLucide={false}
        clazz={`${checked ? 'text-primary' : 'text-gray-search'} fs-4 rounded`}
      />
    </a>
  );
};

export default MaterialCheckbox;
