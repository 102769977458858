import { useHistory } from 'react-router';
import React, { useContext, useState } from 'react';
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Row,
  TabContent,
  TabPane,
} from 'reactstrap';
import MaterialIcon from '../../../components/commons/MaterialIcon';
import {
  addressify,
  MaskingPromptKeys,
  numbersWithComma,
  overflowing,
  roundNumbers,
} from '../../../utils/Utils';
import ButtonIcon from '../../../components/commons/ButtonIcon';
import RocketReachSocialLinks from '../../../components/organizationProfile/overview/RocketReachSocialLinks';
import { Image } from 'react-bootstrap';
import routes from '../../../utils/routes.json';
import NoDataFound from '../../../components/commons/NoDataFound';
import ProfilePicOrFallbackAvatar from '../../commons/ProfilePicOrFallbackAvatar';
import ViewMoreLess from '../../commons/ViewMoreLess';
import { TabsContext } from '../../../contexts/tabsContext';
import PageTitle from '../../commons/PageTitle';
import AnimatedTabs from '../../commons/AnimatedTabs';
import { ProspectTypes } from './constants';
import LinkWrapper from '../../modal/LinkWrapper';
import useAskIdaModal from '../../chat/modals/AskIda';
import { useIdaContext } from '../../../contexts/IdaContext';

const TABS = {
  CompanyInformation: 1,
  SwotAnalysis: 2,
  Competitors: 3,
};

const OrgItemRow = ({ children }) => {
  return <Row className="my-1">{children}</Row>;
};

const OrgItemWithIcon = ({ label, icon, isCustomIcon }) => {
  return (
    <p className="m-0 p-0 lead fs-7 d-flex gap-1 align-items-center font-weight-semi-bold py-1">
      {isCustomIcon || <Image src={icon} style={{ width: 22, height: 22 }} />}
      <span>{label}</span>
    </p>
  );
};

const NoCompanyDetailsFound = ({ fromDomainMenu, gobackToResources }) => {
  const Description = () => {
    return (
      <div className="text-center">
        <p>
          We couldn&apos;t find details of the company please click below to
        </p>
        <ButtonIcon
          icon="west"
          label="Go back to resources"
          classnames="btn-sm"
          color="primary"
          onclick={gobackToResources}
        />
      </div>
    );
  };
  const Title = () => {
    return <div className="text-gray-search">No company details found</div>;
  };

  const TitleDomain = () => {
    return <div className="text-gray-search">Let&apos;s start searching!</div>;
  };

  const DescriptionDomain = () => {
    return (
      <>
        Get started by putting a domain name or for a more refined search, use
        the <MaterialIcon icon="filter_alt" /> filters to the left!
      </>
    );
  };
  return (
    <Card className="position-relative col-lg-12">
      <CardBody>
        {fromDomainMenu ? (
          <NoDataFound
            icon="language"
            title={<TitleDomain />}
            description={<DescriptionDomain />}
            containerStyle={'text-gray-search py-6 my-6'}
          />
        ) : (
          <NoDataFound
            icon="language"
            title={<Title />}
            description={<Description />}
            containerStyle={'text-gray-search py-6 my-6'}
          />
        )}
      </CardBody>
    </Card>
  );
};

const CompanyInformationTab = ({
  company,
  redirectToProspectsSearch,
  socialLinks,
}) => {
  const { AskIdaModal, setShowModal, setCustomPrompt } = useAskIdaModal();
  const { setNewQuestion, setSelectedChat, setSelectedModalChat, setMask } =
    useIdaContext();
  return (
    <>
      <AskIdaModal />
      <CardHeader
        tag="h1"
        className="justify-content-center flex-column py-4 m-auto align-items-center w-60"
      >
        <div className="d-flex align-items-center gap-1 py-2">
          <ProfilePicOrFallbackAvatar
            prospect={company}
            style={{ width: 70, height: 70 }}
          />
          <h1>
            <p className="mb-0">{company.name}</p>
            <RocketReachSocialLinks links={socialLinks} />
          </h1>
        </div>
      </CardHeader>
      <CardBody>
        <div className="justify-content-center">
          {company?.description && (
            <ViewMoreLess text={company?.description} limit={900} />
          )}
        </div>
        <div className="d-flex gap-2 justify-content-center align-items-center mt-3 py-2 mb-4">
          <ButtonIcon
            icon="search"
            color="primary"
            label="Search Employees"
            onclick={redirectToProspectsSearch}
            classnames="font-weight-semi-bold px-6"
          />
          {company?.ticker && (
            <ButtonIcon
              icon="grid_view"
              label="SWOT Analysis"
              className="btn-outline-primary rounded font-weight-semi-bold py-2 px-6 bg-white"
              onclick={() => {
                setNewQuestion({});
                setSelectedChat({});
                setSelectedModalChat({});
                const queryAI = `Write a SWOT analysis for company name: "${
                  company.name
                }" and stock symbol: "${company.ticker.trim()}", include a company overview summary and TOWS Matrix. Make the headings bold.`;
                setCustomPrompt({
                  text: queryAI,
                });
                setMask(MaskingPromptKeys.SwotAnalysis);
                setShowModal(true);
              }}
              style={{ borderWidth: '1px' }}
            />
          )}
        </div>
        {company.domain ? (
          <OrgItemRow>
            <Col md={2}>
              <OrgItemWithIcon
                isCustomIcon={
                  <span className="px-2 bg-gray-300 rounded-full">
                    <MaterialIcon
                      icon="language"
                      size="font-size-sm"
                      clazz="text-black"
                    />
                  </span>
                }
                label="Website"
              />
            </Col>
            <Col md={10}>
              <p className="mb-0 fs-7">
                <LinkWrapper href={`https://${company.domain}`}>
                  {company.domain}
                </LinkWrapper>
              </p>
            </Col>
          </OrgItemRow>
        ) : null}
        {company.ticker ? (
          <OrgItemRow>
            <Col md={2}>
              <OrgItemWithIcon
                isCustomIcon={
                  <span className="px-2 bg-gray-300 rounded-full">
                    <MaterialIcon
                      icon="area_chart"
                      size="font-size-sm"
                      clazz="text-black"
                    />
                  </span>
                }
                label="Ticker"
              />
            </Col>
            <Col md={10}>
              <p className="mb-0 fs-7">{company.ticker?.trim()}</p>
            </Col>
          </OrgItemRow>
        ) : null}
        {company.revenue ? (
          <OrgItemRow>
            <Col md={2}>
              <OrgItemWithIcon
                isCustomIcon={
                  <span className="px-2 bg-gray-300 rounded-full">
                    <MaterialIcon
                      icon="monetization_on"
                      size="font-size-sm"
                      clazz="text-black"
                    />
                  </span>
                }
                label="Revenue"
              />
            </Col>
            <Col md={10}>
              <p className="mb-0 fs-7">
                ${roundNumbers(company.revenue, 'long', 2)}
              </p>
            </Col>
          </OrgItemRow>
        ) : null}
        {company.employees ? (
          <OrgItemRow>
            <Col md={2}>
              <OrgItemWithIcon
                isCustomIcon={
                  <span className="px-2 bg-gray-300 rounded-full">
                    <MaterialIcon
                      icon="people"
                      size="font-size-sm"
                      clazz="text-black"
                    />
                  </span>
                }
                label="Employees"
              />
            </Col>
            <Col md={10}>
              <p className="mb-0 fs-7 text-capitalize">
                {numbersWithComma(company.employees)}
              </p>
            </Col>
          </OrgItemRow>
        ) : null}
        {(company.founded || company.year_founded) && (
          <OrgItemRow>
            <Col md={2}>
              <OrgItemWithIcon
                isCustomIcon={
                  <span className="px-2 bg-gray-300 rounded-full">
                    <MaterialIcon
                      icon="flag"
                      size="font-size-sm"
                      clazz="text-black"
                    />
                  </span>
                }
                label="Founded"
              />
            </Col>
            <Col md={10}>
              <p className="mb-0 fs-7 text-capitalize">
                {company.founded || company.year_founded}
              </p>
            </Col>
          </OrgItemRow>
        )}
        <OrgItemRow>
          <Col md={2}>
            <OrgItemWithIcon
              isCustomIcon={
                <span className="px-2 bg-gray-300 rounded-full">
                  <MaterialIcon
                    icon="edit_location"
                    size="font-size-sm"
                    clazz="text-black"
                  />
                </span>
              }
              label="Address"
            />
          </Col>
          <Col md={10}>
            <p className="mb-0 fs-7 text-capitalize">
              {addressify(company, 'company')}
            </p>
          </Col>
        </OrgItemRow>
        {company.phone && (
          <OrgItemRow>
            <Col md={2}>
              <OrgItemWithIcon
                isCustomIcon={
                  <span className="px-2 bg-gray-300 rounded-full">
                    <MaterialIcon
                      icon="phone"
                      size="font-size-sm"
                      clazz="text-black"
                    />
                  </span>
                }
                label="Phone"
              />
            </Col>
            <Col md={10}>
              <p className="mb-0 fs-7 text-capitalize">{company.phone}</p>
            </Col>
          </OrgItemRow>
        )}
        {company.fax && (
          <OrgItemRow>
            <Col md={2}>
              <OrgItemWithIcon
                isCustomIcon={
                  <span className="px-2 bg-gray-300 rounded-full">
                    <MaterialIcon
                      icon="fax"
                      size="font-size-sm"
                      clazz="text-black"
                    />
                  </span>
                }
                label="Fax"
              />
            </Col>
            <Col md={10}>
              <p className="mb-0 fs-7 text-capitalize">{company.fax}</p>
            </Col>
          </OrgItemRow>
        )}
        {company.techStack?.length > 0 ? (
          <OrgItemRow>
            <Col md={2}>
              <OrgItemWithIcon
                isCustomIcon={
                  <span className="px-2 bg-gray-300 rounded-full">
                    <MaterialIcon
                      icon="apps"
                      size="font-size-sm"
                      clazz="text-black"
                    />
                  </span>
                }
                label="Technologies"
              />
            </Col>
            <Col md={10}>
              <p className="mb-0 fs-7">
                <ViewMoreLess
                  text={company.techStack.join(', ')}
                  byWords={15}
                />
              </p>
            </Col>
          </OrgItemRow>
        ) : null}
        {company.industries && (
          <OrgItemRow>
            <Col md={2}>
              <OrgItemWithIcon
                isCustomIcon={
                  <span className="px-2 bg-gray-300 rounded-full">
                    <MaterialIcon
                      icon="category"
                      size="font-size-sm"
                      clazz="text-black"
                    />
                  </span>
                }
                label="Category"
              />
            </Col>
            <Col md={10}>
              <p className="mb-0 fs-7 text-capitalize">
                {company.industries.join(', ')}
              </p>
            </Col>
          </OrgItemRow>
        )}
        {company.competitors?.length > 0 ? (
          <OrgItemRow>
            <Col md={2}>
              <OrgItemWithIcon
                isCustomIcon={
                  <span className="px-2 bg-gray-300 rounded-full">
                    <MaterialIcon
                      icon="equalizer"
                      size="font-size-sm"
                      clazz="text-black"
                    />
                  </span>
                }
                label="Competitors"
              />
            </Col>
            <Col md={10}>
              <p className="mb-0 fs-7 text-capitalize">
                <ViewMoreLess
                  text={company.competitors.join(', ')}
                  byWords={15}
                />
              </p>
            </Col>
          </OrgItemRow>
        ) : null}
        {company.sic && (
          <OrgItemRow>
            <Col md={2}>
              <OrgItemWithIcon
                isCustomIcon={
                  <span className="px-2 bg-gray-300 rounded-full">
                    <MaterialIcon
                      icon="merge_type"
                      size="font-size-sm"
                      clazz="text-black"
                    />
                  </span>
                }
                label="SIC"
              />
            </Col>
            <Col md={10}>
              <p className="mb-0 fs-7 text-capitalize">
                {company.sic || 'N/A'}
              </p>
            </Col>
          </OrgItemRow>
        )}
        {company.naics && (
          <OrgItemRow>
            <Col md={2}>
              <OrgItemWithIcon
                isCustomIcon={
                  <span className="px-2 bg-gray-300 rounded-full">
                    <MaterialIcon
                      icon="numbers"
                      size="font-size-sm"
                      clazz="text-black"
                    />
                  </span>
                }
                label="NAICS"
              />
            </Col>
            <Col md={10}>
              <p className="mb-0 fs-7 text-capitalize">
                {company.naics || 'N/A'}
              </p>
            </Col>
          </OrgItemRow>
        )}
      </CardBody>
    </>
  );
};

const RRCompanyDetails = ({
  company,
  socialLinks,
  allowBack,
  companyDetailPage,
  setCompanyDetailPage,
  refreshCompany,
}) => {
  const swot = companyDetailPage.swot;
  const tab = companyDetailPage.tab;
  const history = useHistory();
  const gobackToResources = () => {
    setCompanyDetailPage((prevState) => ({
      ...prevState,
      show: false,
      tab,
    }));
  };

  const redirectToProspectsSearch = () => {
    overflowing();
    gobackToResources();
    history.push(
      `${routes.resources}?id=${company.id}&current_employer=${company.name}&tab=${ProspectTypes.people}`
    );
  };

  const tabsData = [
    {
      title: 'Company Information',
      component: (
        <CompanyInformationTab
          company={company}
          socialLinks={socialLinks}
          redirectToProspectsSearch={redirectToProspectsSearch}
        />
      ),
      tabId: TABS.CompanyInformation,
    },
  ];

  const [activeTabId, setActiveTabId] = useState(
    swot === true ? TABS.SwotAnalysis : TABS.CompanyInformation
  );
  const { setActivatedTab } = useContext(TabsContext);

  const toggle = (tabId) => {
    if (activeTabId !== tabId) {
      setActiveTabId(tabId);

      setActivatedTab({
        [location.pathname]: tabId,
      });
    }
  };

  return (
    <>
      <PageTitle page={tabsData.find((t) => t.tabId === activeTabId)?.title} />
      <div className="position-relative">
        {company?.name || swot === true ? (
          <div className="position-relative">
            <div className="border-bottom w-100 pt-0">
              <div className="position-relative">
                <button
                  className="ml-1 p-0 btn btn-sm d-flex align-items-center icon-hover-bg position-absolute top-8 left-4"
                  onClick={gobackToResources}
                >
                  <MaterialIcon icon="keyboard_backspace" size="fs-6" />
                </button>
                <AnimatedTabs
                  tabsData={tabsData}
                  activeTab={activeTabId}
                  toggle={(tab) => toggle(tab.tabId)}
                  tabClasses="link-active-wrapper nav-sm-down-break"
                  containerClasses="ml-6"
                />
              </div>
            </div>

            <TabContent className="col-9 d-flex mx-auto justify-content-center">
              <TabPane className="mt-1 w-100">
                {tabsData.find((item) => item.tabId === activeTabId)?.component}
              </TabPane>
            </TabContent>
          </div>
        ) : (
          <NoCompanyDetailsFound
            fromDomainMenu={!allowBack}
            gobackToResources={gobackToResources}
          />
        )}
      </div>
    </>
  );
};

export default RRCompanyDetails;
