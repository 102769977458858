import AccountStructureWidgetWrapper from '../pages/AccountStructureWidgetWrapper';
import MaterialIcon from '../../../commons/MaterialIcon';
import React from 'react';
import { calculateMonthDataUsage } from '../../reports.helper.functions';
import {
  getYearFromMonth,
  parseCurrency,
  roundOrShowAll,
} from '../../../../utils/Utils';
import { usePagesContext } from '../../../../contexts/pagesContext';
import {
  AccountStructureTabMap,
  FilterNotPrimary,
  generateOneYearBalanceRecords,
} from '../account.structure.constants';

const getMonthByValue = (arr, value) => {
  return arr?.length
    ? arr.find((v) => parseFloat(v.value) === parseFloat(value)) || 0
    : 0;
};

const ExpenseGrid = ({ data, whenPrinting }) => {
  return (
    <div
      style={{ gap: 10 }}
      className={`d-flex mt-2 align-items-center justify-content-center`}
    >
      {data.map((item) => (
        <div
          key={item.heading}
          style={{
            width: 150,
          }}
          className={`flex-fill ${
            item.isChecked ? 'text-white' : 'text-primary'
          } ${
            whenPrinting ? 'px-3' : 'px-0'
          } gap-1 h-100 position-relative align-items-center fti-item insight-item`}
        >
          <div
            className={`d-flex align-items-start mx-auto flex-column py-2 px-0 gap-1`}
            style={{ width: 150 }}
          >
            <span
              className={`rounded-circle p-2 d-flex align-items-center bg-primary-soft`}
            >
              <MaterialIcon rounded icon={item.icon} clazz={`font-size-2xl`} />
            </span>
            <p
              style={{ maxWidth: item.width }}
              className={`mb-0 ${whenPrinting ? 'sub-text' : 'fs-9'} ${
                item.isChecked ? 'font-weight-medium' : 'text-gray-500'
              }`}
            >
              <h6
                className={`mt-1 d-flex align-items-center gap-1 text-rpt-heading font-weight-bold ${
                  whenPrinting ? 'fs-9' : 'fs-6'
                } mb-0`}
              >
                <span>
                  {item.percentage
                    ? `${
                        typeof item.value === 'string'
                          ? parseFloat(item.value || '0.00')?.toFixed(2)
                          : item?.value || '0.00'
                      }%`
                    : item.isCurrency
                    ? `${roundOrShowAll(parseCurrency(item.value), 999999)}`
                    : item.value}
                </span>
                <span>{item.title}</span>
              </h6>
              <p
                className={`mb-0 ${
                  whenPrinting ? 'fs-12' : 'fs-10 text-nowrap'
                }`}
              >
                {item.description}
              </p>
            </p>
          </div>
        </div>
      ))}
    </div>
  );
};
const BalanceTrendsWidget = ({ report, whenPrinting, accountStructure }) => {
  const { pageContext } = usePagesContext();
  const jsonKey = AccountStructureTabMap.ReportDetails.key;
  const AccountStructureReport = pageContext?.AccountStructureReport || {
    balances: generateOneYearBalanceRecords(),
  };
  const { balances } = Object.entries(AccountStructureReport[jsonKey] || {})
    .length
    ? AccountStructureReport[jsonKey]
    : { balances: generateOneYearBalanceRecords() };
  const lineLimit = Math.max(...balances.map((mb) => parseFloat(mb.value)));
  const accounts = AccountStructureReport[jsonKey]?.accounts;
  const loanOverview = {
    lineLimit,
    monthData: balances,
  };
  const newReport = report || { loanOverview };
  const usage = calculateMonthDataUsage(newReport);
  const peakMonth = getMonthByValue(
    newReport?.loanOverview?.monthData,
    usage.peakUtilization
  );
  const lowestMonth = getMonthByValue(
    newReport?.loanOverview?.monthData,
    usage.lowUtilization
  );
  const expenses = [
    {
      icon: 'account_balance',
      isCurrency: false,
      value: accounts?.filter(FilterNotPrimary).length,
      title: 'Accounts',
      description: 'Balances Reflected',
    },
    {
      icon: 'calendar_today',
      value: usage.peakUtilization,
      isCurrency: true,
      title: peakMonth
        ? peakMonth.fullMonth?.slice(0, 3) + ' ' + getYearFromMonth(peakMonth)
        : '',
      description: 'Highest Average Collected Balance',
    },
    {
      icon: 'calendar_today',
      value: usage.lowUtilization,
      title: lowestMonth
        ? lowestMonth?.fullMonth?.slice(0, 3) +
          ' ' +
          getYearFromMonth(peakMonth)
        : '',
      isCurrency: true,
      description: 'Lowest Average Collected Balance',
    },
  ];
  return (
    <AccountStructureWidgetWrapper>
      <h5 className="mb-1">Insights</h5>
      <ExpenseGrid data={expenses} whenPrinting={whenPrinting} />
    </AccountStructureWidgetWrapper>
  );
};

export default BalanceTrendsWidget;
