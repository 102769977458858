import React from 'react';
import PageTitle from '../../commons/PageTitle';
import { DATE_FORMAT_MONTH_V2, setDateFormat } from '../../../utils/Utils';
import Table from '../../GenericTable';
import TextOverflowTooltip from '../../commons/TextOverflowTooltip';
import TableRowHover from '../../commons/TableRowHover';
import StatCardList from '../shared/StatCardList';
import StatusBadge from '../shared/StatusBadge';

const Data = [
  {
    title: 'YTD T.M. Sales Performance',
    icon: 'TrendingUp',
    metrics: [
      { label: 'YTD T.M Revenue Booked', value: '253842', type: 'currency' },
      { label: 'YTD Sales Goal', value: '300000', type: 'currency' },
      { label: 'YTD Achievement Rate', value: '84.6', type: 'percentage' },
    ],
    progress: {
      percentage: '84.6',
      variant: 'success',
      labels: ['Q1 Progress (Jan-Mar)', '$46,158 to reach goal'],
    },
  },
  {
    title: 'YTD Deposit Acquisition',
    icon: 'PiggyBank',
    metrics: [
      { label: 'YTD New Deposits Booked', value: '2489948', type: 'currency' },
      { label: 'YTD Deposit Goal', value: '5000000', type: 'currency' },
      { label: 'YTD Achievement Rate', value: '49.8', type: 'percentage' },
    ],
    progress: {
      percentage: '49.8',
      variant: 'warning',
      labels: ['Q1 Progress (Jan-Mar)', '$2.51M remaining'],
    },
  },
  {
    title: 'Client Segmentation Status',
    icon: 'ChartPie',
    metrics: [
      { label: 'Total Assigned Clients', value: '94', type: 'number' },
      {
        label: 'Clients Needing Tier Assignment',
        value: '44',
        type: 'number',
      },
      { label: 'Client Tiering Status', value: '53.2', type: 'percentage' },
    ],
    progress: {
      percentage: '53.2',
      variant: 'warning',
      labels: ['Progress to Date', '44 clients pending'],
    },
  },
  {
    title: 'YTD Calling Activity',
    icon: 'Users',
    metrics: [
      { label: 'Completed Calls', value: '48', type: 'number' },
      {
        label: 'YTD Calling Goal',
        value: '100',
        type: 'number',
      },
      { label: 'YTD Achievement Rate', value: '48', type: 'percentage' },
    ],
    progress: {
      percentage: '53.2',
      variant: 'warning',
      labels: ['Progress to Date', '52 calls pending'],
    },
  },
];

const tableColumns = [
  {
    key: 'name',
    component: <span>Company Name</span>,
  },
  {
    key: 'suggested_activity',
    component: <span>Suggested Activity</span>,
  },
  {
    key: 'priority',
    component: <span>Priority</span>,
  },
  {
    key: 'date_modified',
    component: <span>Last Updated</span>,
  },
];

const rowData = [
  {
    name: 'Meridian Manufacturing Group',
    suggestedActivity: 'Pipeline Follow Up',
    priority: 'High',
    lastUpdated: 'Mar 14, 2025',
  },
  {
    name: 'Coastal Logistics Partners',
    suggestedActivity: 'Positive Pay Lead',
    priority: 'High',
    lastUpdated: 'Mar 13, 2025',
  },
  {
    name: 'Heritage Properties & Development',
    suggestedActivity: 'Initiate Touchpoint',
    priority: 'Medium',
    lastUpdated: 'Mar 12, 2025',
  },
  {
    name: 'Summit Healthcare Systems',
    suggestedActivity: 'Integrated Payables Lead',
    priority: 'High',
    lastUpdated: 'Mar 11, 2025',
  },
  {
    name: 'Evergreen Industrial Supply',
    suggestedActivity: 'Pipeline Follow Up',
    priority: 'Medium',
    lastUpdated: 'Mar 10, 2025',
  },
  {
    name: 'Atlantic Shipping & Transport',
    suggestedActivity: 'Initiate Touchpoint',
    priority: 'Medium',
    lastUpdated: 'Mar 9, 2025',
  },
  {
    name: 'Pioneer Energy Solutions',
    suggestedActivity: 'Initiate Touchpoint',
    priority: 'Low',
    lastUpdated: 'Mar 8, 2025',
  },
  {
    name: 'Riverfront Restaurant Group',
    suggestedActivity: 'Complete Customer Tiering',
    priority: 'High',
    lastUpdated: 'Mar 7, 2025',
  },
  {
    name: 'Cornerstone Construction LLC',
    suggestedActivity: 'Annual Review Meeting',
    priority: 'Medium',
    lastUpdated: 'Mar 6, 2025',
  },
  {
    name: 'Metro Medical Supplies',
    suggestedActivity: 'Cross-sell Treasury Services',
    priority: 'Low',
    lastUpdated: 'Mar 5, 2025',
  },
];

const OverviewHome = () => {
  const rows = rowData?.map((item) => {
    return {
      ...item,
      dataRow: [
        {
          key: 'name',
          classes: 'px-0',
          component: (
            <div className="text-black text-truncate fw-bold d-flex align-items-center gap-2">
              <TextOverflowTooltip
                maxLength={100}
                textStyle="fw-bold text-truncate font-size-sm2 mb-0 z-index-100"
                text={item.name}
              />
            </div>
          ),
        },
        {
          key: 'suggestedActivity',
          label: '',
          component: (
            <>
              <span>{item?.suggestedActivity || '--'}</span>
            </>
          ),
        },
        {
          key: 'priority',
          label: '',
          component: <>{item?.priority ? <StatusBadge item={item} /> : '--'}</>,
        },
        {
          key: 'date_modified',
          label: '',
          component: (
            <>
              <TableRowHover zIndex="z-index-5" />
              <span>
                {item?.lastUpdated ? (
                  <p className="d-flex align-items-center mb-0 gap-1">
                    <span>
                      {setDateFormat(item?.lastUpdated, DATE_FORMAT_MONTH_V2)}
                    </span>
                  </p>
                ) : (
                  '--'
                )}
              </span>
            </>
          ),
        },
      ],
    };
  });

  return (
    <div>
      <PageTitle page="Overview" />
      <div className="d-grid grid-col-2 gap-3 mb-3">
        {Data?.map((item) => (
          <StatCardList key={item.id} item={item} />
        ))}
      </div>
      <StatCardList
        item={{
          icon: 'SquareCheck',
          title: 'Priority Client Actions',
        }}
      >
        <div className="d-flex flex-column">
          <div className="table-min-height">
            <div className="table-responsive-md datatable-custom">
              <div
                id="datatable_wrapper"
                className="dataTables_wrapper no-footer h-auto"
              >
                <Table
                  data={rows}
                  columns={tableColumns}
                  dataInDB={rowData?.length}
                  showLoading={false}
                  emptyDataText="No recent client actions"
                  headClass="bg-gray-table-head"
                />
              </div>
            </div>
          </div>
        </div>
      </StatCardList>
    </div>
  );
};

export default OverviewHome;
