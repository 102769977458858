import { Card } from 'react-bootstrap';
import { numberWithCommas, isToFixedNoRound } from '../../../utils/Utils';
import TopicIcon from '../../commons/TopicIcon';

export const CardTransactions = ({ item, startDownload }) => {
  return (
    <>
      <div>
        <Card className="report-widget">
          <Card.Body className="d-flex align-items-start flex-column">
            <TopicIcon
              icon={item?.icon}
              iconBg="bg-primary-soft"
              iconStyle={{ width: 42, height: 42 }}
              iconClasses="text-primary"
            />
            <div className={`${startDownload ? '' : ''} mt-3`}>
              <h4 className={`mb-0`}>
                {item?.key === 'total_transactions'
                  ? numberWithCommas(item?.total)
                  : isToFixedNoRound(Math.abs(item?.total))}
              </h4>
              <p className={`mb-0 font-size-sm2`}>{item?.title}</p>
            </div>
          </Card.Body>
        </Card>
      </div>
    </>
  );
};
