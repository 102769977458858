import { useRef, useState } from 'react';
import { Dropdown } from 'react-bootstrap';
import Picker from '@emoji-mart/react';
import useOutsideClickDropDown from '../../hooks/useOutsideClickDropDown';

const EmojiPickerDropdown = ({ children, setEmoji }) => {
  const [show, setShow] = useState(false);
  const dropdownRef = useRef(null);
  useOutsideClickDropDown(dropdownRef, show, setShow);
  const handleEmojiSelect = (emoji) => {
    setEmoji(emoji);
    setShow(false);
  };
  return (
    <Dropdown ref={dropdownRef} onToggle={setShow} show={show}>
      <Dropdown.Toggle variant="primary" as="a" className="no-caret">
        {children}
      </Dropdown.Toggle>
      <Dropdown.Menu className="p-0">
        <div onClick={(e) => e.stopPropagation()}>
          <Picker theme="light" onEmojiSelect={handleEmojiSelect} />
        </div>
      </Dropdown.Menu>
    </Dropdown>
  );
};
export default EmojiPickerDropdown;
