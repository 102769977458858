import MaterialIcon from '../../../commons/MaterialIcon';

const FuturePlansWidget = ({ widgetConfig, whenPrintig }) => {
  return (
    <div
      className="d-flex flex-column p-3 gap-0 widget-border-2"
      style={{
        borderRadius: 'var(--rpt-widget-border-radius)',
        background: '#ffffff',
      }}
    >
      {widgetConfig.map((item, index) => {
        return (
          <div
            key={index}
            className="d-flex flex-column justify-content-center"
          >
            <div
              className="d-flex flex-column card report-widget no-border border-primary-soft justify-content-start"
              style={{
                borderRadius: 'var(--rpt-widget-border-radius)',
              }}
            >
              <div className="d-flex card-body gap-1 align-items-center">
                <div
                  className="d-flex align-items-center bg-primary-soft rounded-circle justify-content-center"
                  style={{ width: '44px', height: '44px' }}
                >
                  <MaterialIcon
                    icon={item.icon}
                    size="fs-2"
                    clazz="text-primary"
                  />
                </div>
                <h5 className="mb-0">{item.title}</h5>
              </div>
              <p className="text-align-left card-body py-0 font-weight-medium font-size-sm2">
                {item.description}
              </p>
            </div>
            {index < widgetConfig.length - 1 && (
              <div className="text-center">
                <MaterialIcon
                  icon="arrow_drop_down"
                  clazz="text-primary"
                  size="font-size-2em"
                />
              </div>
            )}
          </div>
        );
      })}
    </div>
  );
};

export default FuturePlansWidget;
