import React from 'react';

const ColorBorder = ({ color }) => {
  return (
    <div
      className="h-100"
      style={{
        position: 'absolute',
        left: '0px',
        width: '3px',
        top: 0,
        borderLeftWidth: 3,
        background: color,
        borderTopLeftRadius: '0.75rem',
        borderBottomLeftRadius: '0.75rem',
      }}
    />
  );
};

export default ColorBorder;
