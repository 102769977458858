import AddNewButton from './AddNewButton';
import SectionWrapper from './SectionWrapper';
import React, { useEffect, useState } from 'react';
import NoDataFoundTitle from '../../fields/NoDataFoundTitle';
import NoDataFound from '../../commons/NoDataFound';
import useDeleteModal from './modals/useDeleteModal';
import useAddNewBankContactModal from './modals/AddNewBankContact';
import { usePagesContext } from '../../../contexts/pagesContext';
import { AccountStructureTabMap } from './account.structure.constants';
import BankContact from './BankContact';

const BankContacts = ({ report, setReport }) => {
  const { pageContext, setPageContext } = usePagesContext();
  const jsonKey = AccountStructureTabMap.ReportDetails.key;
  const [bankContacts, setBankContacts] = useState(
    pageContext?.AccountStructureReportModal[jsonKey]?.bankContacts || []
  );
  const [selectedBankContact, setSelectedBankContact] = useState({});
  const {
    DeleteModal,
    setData,
    setShowModal: setShowDeleteModal,
  } = useDeleteModal({
    description: 'Are you sure you want to delete this Bank contact?',
    successMsg: 'Bank contact deleted.',
    deleteCallback: async (bankContact) => {
      const newBankContacts = [...bankContacts].filter(
        (f) => f.id !== bankContact.id
      );
      setBankContacts(newBankContacts);
    },
  });
  const handleEditBankContact = (authUser) => {
    setSelectedBankContact(authUser);
    setShowModal(true);
  };
  const handleDeleteBankContact = (bankContact) => {
    setData({
      ...bankContact,
      title: bankContact.name,
    });
    setShowDeleteModal(true);
  };

  const { setShowModal, AddNewBankContactModal } = useAddNewBankContactModal(
    selectedBankContact,
    bankContacts,
    setBankContacts
  );
  useEffect(() => {
    setPageContext({
      ...pageContext,
      AccountStructureReportModal: {
        ...pageContext.AccountStructureReportModal,
        [jsonKey]: {
          ...pageContext.AccountStructureReportModal[jsonKey],
          bankContacts,
        },
      },
    });
  }, [bankContacts]);
  return (
    <div className="w-100">
      <AddNewBankContactModal />
      <DeleteModal />
      <div className="d-flex mb-2 justify-content-end align-items-center">
        <AddNewButton
          label="Add Contact"
          onClick={() => {
            setSelectedBankContact({});
            setShowModal(true);
          }}
        />
      </div>
      <SectionWrapper columns={['Contact Name', 'Contact Details']}>
        {bankContacts.length ? (
          <>
            {bankContacts.map((acnt) => (
              <BankContact
                key={acnt.id}
                acnt={acnt}
                handleDeleteBankContact={handleDeleteBankContact}
                handleEditBankContact={handleEditBankContact}
              />
            ))}
          </>
        ) : (
          <NoDataFound
            icon="groups"
            iconRounded={true}
            iconStyle="font-size-2em"
            containerStyle="text-gray-search mt-2 pt-2 mb-1"
            title={
              <NoDataFoundTitle clazz="fs-7 mb-0" str={`No bank contacts`} />
            }
          />
        )}
      </SectionWrapper>
    </div>
  );
};
export default BankContacts;
