import { useIdaContext } from '../../../contexts/IdaContext';
import React, { useEffect, useRef, useState } from 'react';
import Markdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import useFetchTransactionStream from '../hooks/useFetchTransactionStream';
import { useAnimatedText } from '../../../hooks/useAnimatedText';
import { AiLoader as AILoaderThread } from '../AskAnything';
import LucideIcon from '../../commons/LucideIcon';
import TrioLoader from '../loaders/TrioLoader';
import { InputDisplayType, QuestionTypes } from '../constants';
import ChatService from '../../../services/chat.service';
import useHash from '../../../hooks/useHash';
import useReportHeaderFooter from '../../../hooks/useReportHeaderFooter';
import MarkdownIt from 'markdown-it';
import { getRandomUUID, MaskingPrompts } from '../../../utils/Utils';
import { jsPDF } from 'jspdf';

const containsMarkdown = (text) => {
  const markdownPattern =
    /(\*\*|__|\*|_|`|#{1,6} |\[.+?]\(.+?\)|!\[.*?]\(.+?\)|[-+*]\s|^\d+\.\s|```|>|~{3,})/gm;
  return markdownPattern.test(text);
};

const AILoaderAnswer = () => {
  return (
    <div>
      <div className="position-relative d-flex align-items-center gap-1">
        <div className="d-flex align-items-center justify-content-center gap-1">
          <TrioLoader />
          <span className="font-weight-medium">Thinking</span>
        </div>
      </div>
    </div>
  );
};

const QuestionAnswerWrapper = ({ children, className }) => {
  return (
    <div className={`my-4 question-wrapper position-relative ${className}`}>
      {children}
    </div>
  );
};
const Question = ({ question }) => {
  const questionText = question?.mask
    ? MaskingPrompts[question.mask]
    : question.text;
  return (
    <QuestionAnswerWrapper>
      <div className="d-flex align-items-start flex-row-reverse gap-2">
        <p
          className="p-2_2 bg-beige-light message-bubble fs-7_1 mb-0"
          style={{ borderBottomRightRadius: 0 }}
        >
          {questionText}
        </p>
      </div>
    </QuestionAnswerWrapper>
  );
};

const TransactionStream = ({
  transaction,
  onChunkReceived,
  onComplete,
  onChatMetaReceived,
}) => {
  const { loading, transaction: tran } = useFetchTransactionStream(
    transaction.transactionId,
    onChunkReceived,
    onComplete,
    onChatMetaReceived
  );

  return <>{loading ? <AILoaderAnswer /> : <>{tran?.result?.chatId}</>}</>;
};

const convertApiResponseToFE = (chatQuestionId, payload, apiResponse) => {
  return {
    chatQuestionId,
    type: QuestionTypes.Home,
    text: payload.question.text,
    transactionId: apiResponse.transactionId,
    chatId: apiResponse.input.chat.chatId,
    title: apiResponse.input.chat.title,
    chatAnswers: [
      {
        type: QuestionTypes.Transaction,
        transactionId: apiResponse.transactionId,
      },
    ],
  };
};
const createNewChat = async (payload, chatQuestionId) => {
  const question = await ChatService.createChat(payload);
  return convertApiResponseToFE(chatQuestionId, payload, question);
};

const Answer = ({ question, answer, children }) => {
  const {
    setHistoryNewChat,
    setSelectedChat,
    setSelectedModalChat,
    setNewQuestion,
  } = useIdaContext();
  const { updateHash } = useHash();
  const [answerReady, setAnswerReady] = useState('');
  const [newAnswer, setNewAnswer] = useState({});
  const [loader, setLoader] = useState(false);
  const text = question?.animated
    ? useAnimatedText(answerReady || answer.text)
    : useAnimatedText(answerReady);

  const createChat = async () => {
    try {
      setLoader(true);
      setHistoryNewChat({
        title: 'New Chat',
        chatId: getRandomUUID(),
      });
      let newChat;
      const payload = question.payload;
      if (question.files.length) {
        const newChatResponse = await ChatService.createChatWithFile(
          payload,
          question.files
        );
        newChat = convertApiResponseToFE(
          question.chatQuestionId,
          payload,
          newChatResponse
        );
      } else {
        newChat = await createNewChat(payload);
      }

      setNewAnswer(newChat);
      setSelectedChat(newChat);
      if (answer?.triggerFrom === InputDisplayType.AskIdaModal) {
        setSelectedModalChat({ transactionId: newChat.transactionId });
        const newQuestion = {
          chatQuestionId: question.chatQuestionId,
          type: QuestionTypes.Home,
          mask: question.mask,
          text: payload.question.text,
          transactionId: newChat.transactionId,
          chatAnswers: [
            {
              type: QuestionTypes.Transaction,
              transactionId: newChat.transactionId,
            },
          ],
        };
        setNewQuestion(newQuestion);
      } else {
        updateHash(`#chats/${newChat.chatId}/new`);
      }
      setAnswerReady('');
    } catch (e) {
      console.log(e);
    } finally {
      setLoader(false);
    }
  };

  useEffect(() => {
    answer?.triggerFrom && createChat();
    return () => {
      setNewAnswer({});
    };
  }, [answer]);

  return (
    <QuestionAnswerWrapper>
      <div className="d-flex align-items-start gap-2">
        {loader && <AILoaderAnswer />}
        <p className="fs-7_1 markdown-body rounded p-0 pb-4 mb-0">
          {(answer?.transactionId || newAnswer?.transactionId) &&
          !answerReady ? (
            <TransactionStream
              transaction={Object.keys(newAnswer).length ? newAnswer : answer}
              onChunkReceived={(chunkedText) => {
                setAnswerReady(chunkedText);
              }}
              onChatMetaReceived={(chatMeta) => {
                setHistoryNewChat({
                  title: chatMeta.title,
                  chatId: chatMeta.chatId,
                });
              }}
            />
          ) : (
            <Markdown remarkPlugins={[remarkGfm]}>
              {text || answer.text}
            </Markdown>
          )}
          {(text || answer.text) && (
            <Actions question={question} answer={text || answer.text} />
          )}
        </p>
      </div>
    </QuestionAnswerWrapper>
  );
};

const removeMarkdown = (markdownText) => {
  const md = new MarkdownIt();
  const htmlContent = md.render(markdownText);
  return htmlContent.replace(/<\/?[^>]+(>|$)/g, '');
};
const Actions = ({ question, answer }) => {
  const [startDownload, setStartDownload] = useState(false);
  const [copied, setCopied] = useState(false);
  const { reportFooterImage } = useReportHeaderFooter();

  const handleCopy = () => {
    navigator.clipboard.writeText(removeMarkdown(answer)).then(() => {
      setCopied(true);
      setTimeout(() => setCopied(false), 2000);
    });
  };

  const handleExportPDF = async () => {
    setStartDownload(true);

    // Add a short delay to allow UI to update
    await new Promise((resolve) => setTimeout(resolve, 1000));

    // Initialize PDF document
    // eslint-disable-next-line new-cap
    const doc = new jsPDF({
      orientation: 'portrait',
      unit: 'mm',
      format: 'a4',
    });

    // Initialize markdown-it
    const md = new MarkdownIt();

    // Page setup
    const pageWidth = doc.internal.pageSize.getWidth();
    const pageHeight = doc.internal.pageSize.getHeight();
    const leftMargin = 20;
    const rightMargin = 20;
    const topMargin = 20;
    const maxTextWidth = pageWidth - leftMargin - rightMargin;

    // Colors for styling
    const colors = {
      primary: [0, 0, 0],
      text: [0, 0, 0],
      header: [0, 0, 0],
    };

    // Font sizes
    const fontSizes = {
      title: 13,
      h1: 18,
      h2: 16,
      h3: 14,
      h4: 12,
      normal: 11,
      small: 9,
    };

    // Line heights
    const lineHeights = {
      title: 7,
      heading: 5,
      paragraph: 7,
      list: 7,
      code: 7,
      gap: 5,
    };

    /**
     * Adds logo to the document and returns the Y position after the logo
     */
    const addLogo = () => {
      const logoUrl = reportFooterImage;
      if (logoUrl) {
        const img = document.querySelector('.tenant-logo');
        if (img) {
          const originalWidth = img.naturalWidth;
          const originalHeight = img.naturalHeight;
          const maxLogoWidth = 40;
          const scaleFactor = maxLogoWidth / originalWidth;
          const newWidth = originalWidth * scaleFactor;
          const newHeight = originalHeight * scaleFactor;

          doc.addImage(
            logoUrl,
            'PNG',
            leftMargin,
            topMargin,
            newWidth,
            newHeight
          );
          return newHeight + 10; // Return space taken by logo
        }
      }
      return 0;
    };

    /**
     * Checks if we need a new page and adds one if necessary
     */
    const checkNewPage = (yPos, minHeight = 30) => {
      if (yPos > pageHeight - minHeight) {
        doc.addPage();
        const logoOffset = addLogo();
        return topMargin + logoOffset;
      }
      return yPos;
    };

    /**
     * Process and render a text segment with proper formatting
     * This function handles a chunk of text with consistent formatting
     */
    const renderTextSegment = (
      textSegment,
      currentX,
      currentY,
      maxWidth,
      fontFamily = 'helvetica',
      fontStyle = 'normal',
      fontSize = fontSizes.normal
    ) => {
      // Set the font style for this segment
      doc.setFont(fontFamily, fontStyle);
      doc.setFontSize(fontSize);

      const words = textSegment.split(' ');
      let line = '';
      let newY = currentY;
      let newX = currentX;

      for (let i = 0; i < words.length; i++) {
        const word = words[i];
        const testLine = line ? line + ' ' + word : word;
        const metrics = doc.getTextDimensions(testLine);

        // If adding this word exceeds the width, we need to print the current line
        if (metrics.w > maxWidth - (currentX - leftMargin)) {
          // Print the current line without the new word
          newY = checkNewPage(newY);
          doc.text(line, newX, newY);

          // Start a new line with the current word
          line = word;
          newY += lineHeights.paragraph;
          newX = leftMargin; // Reset X to left margin for new line
        } else {
          // Add the word to the current line
          line = testLine;
        }
      }

      // Print any remaining text
      if (line) {
        newY = checkNewPage(newY);
        doc.text(line, newX, newY);

        // Calculate new X position based on the width of the current line
        const metrics = doc.getTextDimensions(line);
        newX = newX + metrics.w + doc.getTextWidth(' '); // Add space after the segment
      }

      return { x: newX, y: newY };
    };

    /**
     * Process inline tokens with formatting (bold, italic, etc.)
     * Works for any content that has children tokens (paragraphs, list items, etc.)
     * This version ensures text flows continuously without unnecessary line breaks
     */
    const processInlineContent = (
      contentToken,
      startY,
      prefix = '',
      isHeading = false,
      headingLevel = 0
    ) => {
      let currentPos = { x: leftMargin, y: startY };

      // Get heading font size if this is a heading
      let fontSize = fontSizes.normal;
      if (isHeading) {
        if (headingLevel === 1) fontSize = fontSizes.h1;
        else if (headingLevel === 2) fontSize = fontSizes.h2;
        else if (headingLevel === 3) fontSize = fontSizes.h3;
        else if (headingLevel >= 4) fontSize = fontSizes.h4;
      }

      // If there are children tokens with formatting
      if (contentToken.children && contentToken.children.length > 0) {
        // Add prefix if provided (for lists)
        if (prefix) {
          doc.setFont('helvetica', 'normal');
          doc.setFontSize(fontSize);
          doc.text(prefix, currentPos.x, currentPos.y);
          currentPos.x += doc.getTextWidth(prefix);
        }

        // Create an array to track formatting spans
        const formattedSpans = [];
        const currentFormat = { bold: isHeading, italic: false }; // Start with bold if it's a heading

        // Process all child tokens to identify formatting spans
        for (let j = 0; j < contentToken.children.length; j++) {
          const child = contentToken.children[j];

          if (child.type === 'text') {
            // Add this text segment with current formatting
            formattedSpans.push({
              text: child.content,
              bold: currentFormat.bold,
              italic: currentFormat.italic,
            });
          } else if (child.type === 'strong_open') {
            currentFormat.bold = true;
          } else if (child.type === 'strong_close') {
            currentFormat.bold = !!isHeading; // Keep bold for headings
          } else if (child.type === 'em_open') {
            currentFormat.italic = true;
          } else if (child.type === 'em_close') {
            currentFormat.italic = false;
          }
        }

        // Now render each formatted span
        for (const span of formattedSpans) {
          let fontStyle = 'normal';
          if (span.bold && span.italic) fontStyle = 'bolditalic';
          else if (span.bold) fontStyle = 'bold';
          else if (span.italic) fontStyle = 'italic';

          currentPos = renderTextSegment(
            span.text,
            currentPos.x,
            currentPos.y,
            maxTextWidth,
            'helvetica',
            fontStyle,
            fontSize
          );
        }

        // Move to the next line after the entire paragraph
        currentPos.y += isHeading ? lineHeights.heading : lineHeights.paragraph;
        currentPos.x = leftMargin;
      }
      // If there's no complex formatting, render the whole content
      else if (contentToken.content) {
        if (prefix) {
          doc.setFont('helvetica', 'normal');
          doc.setFontSize(fontSize);
          doc.text(prefix, currentPos.x, currentPos.y);
          currentPos.x += doc.getTextWidth(prefix);
        }

        currentPos = renderTextSegment(
          contentToken.content,
          currentPos.x,
          currentPos.y,
          maxTextWidth,
          'helvetica',
          isHeading ? 'bold' : 'normal',
          fontSize
        );
        currentPos.y += isHeading ? lineHeights.heading : lineHeights.paragraph;
        currentPos.x = leftMargin;
      }

      return currentPos.y;
    };

    try {
      // Initialize document
      doc.setFont('helvetica');
      let yPosition = topMargin + addLogo();

      // Parse markdown
      const isMarkdown = containsMarkdown(question.text);
      let tokens;

      if (!isMarkdown) {
        // Render title (question.text)
        doc.setFontSize(fontSizes.title);
        doc.setTextColor(...colors.header);
        doc.setFont('helvetica', 'bold');

        const splitTitle = doc.splitTextToSize(question.text, maxTextWidth);
        yPosition += 10;

        splitTitle.forEach((line) => {
          yPosition = checkNewPage(yPosition);
          doc.text(line, leftMargin, yPosition);
          yPosition += lineHeights.title;
        });

        yPosition += lineHeights.gap;

        // Reset font for content
        doc.setFontSize(fontSizes.normal);
        doc.setTextColor(...colors.text);
        doc.setFont('helvetica', 'normal');
        tokens = md.parse(answer, {});
      } else {
        const cleanedText = question.text
          .replace(/[^\S\r\n]+/g, ' ') // Remove extra spaces but keep line breaks
          .replace(/\n +/g, '\n') // Remove leading spaces after newlines
          .trim();
        const fullText = cleanedText + answer;
        tokens = md.parse(fullText, {});
      }

      // Process tokens
      let i = 0;
      while (i < tokens.length) {
        const token = tokens[i];

        // Handle headings
        if (token.type === 'heading_open') {
          const level = parseInt(token.tag.slice(1));

          // Get heading content
          const contentToken = tokens[i + 1];
          if (contentToken && contentToken.type === 'inline') {
            yPosition = checkNewPage(yPosition);

            // Process heading with special handling
            yPosition = processInlineContent(
              contentToken,
              yPosition,
              '',
              true,
              level
            );
            yPosition += lineHeights.gap;
          }

          // Skip the heading content token and heading_close token
          i += 2;
        }

        // Handle paragraphs
        else if (token.type === 'paragraph_open') {
          const contentToken = tokens[i + 1];
          if (contentToken && contentToken.type === 'inline') {
            yPosition = checkNewPage(yPosition);

            // Process paragraph content with formatting
            yPosition = processInlineContent(contentToken, yPosition);
            yPosition += lineHeights.gap;
          }

          // Skip the paragraph content token and paragraph_close token
          i += 2;
        }

        // Handle lists
        else if (
          token.type === 'bullet_list_open' ||
          token.type === 'ordered_list_open'
        ) {
          const isOrdered = token.type === 'ordered_list_open';
          let listCounter = 1;
          i++;

          // Process all list items until we reach list_close
          while (
            i < tokens.length &&
            tokens[i].type !==
              (isOrdered ? 'ordered_list_close' : 'bullet_list_close')
          ) {
            if (tokens[i].type === 'list_item_open') {
              i++;

              // Process the content of the list item
              while (
                i < tokens.length &&
                tokens[i].type !== 'list_item_close'
              ) {
                if (tokens[i].type === 'paragraph_open') {
                  i++;
                  if (tokens[i] && tokens[i].type === 'inline') {
                    const prefix = isOrdered ? `${listCounter}. ` : '• ';

                    yPosition = checkNewPage(yPosition);

                    // Process list item with formatting, including the prefix
                    yPosition = processInlineContent(
                      tokens[i],
                      yPosition,
                      prefix
                    );
                  }
                  i++; // Skip paragraph_close
                } else {
                  i++;
                }
              }

              listCounter++;
            }

            i++;
          }

          yPosition += lineHeights.gap;
        }

        // Handle code blocks
        else if (token.type === 'fence') {
          yPosition = checkNewPage(yPosition);

          doc.setFont('courier', 'normal');
          doc.setFontSize(fontSizes.normal);

          const lines = doc.splitTextToSize(token.content, maxTextWidth);
          lines.forEach((line) => {
            yPosition = checkNewPage(yPosition);
            doc.text(line, leftMargin, yPosition);
            yPosition += lineHeights.code;
          });

          // Reset font
          doc.setFont('helvetica', 'normal');
          yPosition += lineHeights.gap;
          i++;
        }

        // Handle horizontal rules
        else if (token.type === 'hr') {
          yPosition = checkNewPage(yPosition);
          doc.setDrawColor(...colors.primary);
          doc.setLineWidth(0.5);
          doc.line(leftMargin, yPosition, pageWidth - rightMargin, yPosition);
          yPosition += lineHeights.gap * 2;
          i++;
        }

        // Skip other tokens
        else {
          i++;
        }
      }

      // Add page numbers
      const totalPages = doc.internal.getNumberOfPages();
      for (let i = 1; i <= totalPages; i++) {
        doc.setPage(i);
        doc.setFontSize(fontSizes.small);
        doc.setTextColor(100);
        doc.text(`Page ${i} of ${totalPages}`, pageWidth / 2, pageHeight - 10, {
          align: 'center',
        });
      }

      setStartDownload(false);
      doc.save(`${question.text.trim().slice(0, 50)}.pdf`);
    } catch (error) {
      console.error('PDF Export Error:', error);
      setStartDownload(false);
    }
  };

  const actionItems = [
    {
      name: 'Copy',
      icon: 'Files',
      onClick: handleCopy,
      activeState: copied,
      activeText: 'Copied',
      activeIcon: 'Check',
    },
    {
      name: 'Export',
      icon: 'FileInput',
      onClick: handleExportPDF,
      activeState: startDownload,
      activeText: 'Exporting...',
    },
  ];

  return (
    <div className="d-flex bg-white action-items rounded-pill align-items-center justify-content-between">
      <div className="d-flex align-items-center font-12 gap-1">
        {actionItems.map((item) => (
          <a
            key={item.name}
            onClick={item.onClick}
            className="d-flex align-items-center px-2 py-1 text-muted bg-transparent rounded-pill transition-all bg-hover-gray gap-1 cursor-pointer"
          >
            <LucideIcon
              icon={
                item.activeState && item.activeIcon
                  ? item.activeIcon
                  : item.icon
              }
              size={14}
            />
            {item.activeState ? item.activeText : item.name}
          </a>
        ))}
      </div>
    </div>
  );
};

const Conversation = ({ questions, latestQuestionRef, fromAskIdaModal }) => {
  const chatRef = useRef(null);

  const tempQuestionRef = useRef(null);
  const [tempQuestionHeight, setTempQuestionHeight] = useState(0);

  useEffect(() => {
    if (tempQuestionRef.current) {
      setTempQuestionHeight(tempQuestionRef.current.offsetHeight);
    }
  }, [questions]);

  const heightContent = fromAskIdaModal ? '215px' : '265px';

  return (
    <div ref={chatRef} className="position-relative w-100 chat-history-thread">
      {questions.map((question, index) => (
        <div
          key={question.chatQuestionId}
          ref={index === questions.length - 1 ? latestQuestionRef : null}
        >
          <div ref={tempQuestionRef}>
            <Question question={question} />
          </div>
          <div
            className="position-relative"
            style={{
              minHeight:
                index === questions.length - 1
                  ? `calc(100vh - ${heightContent} - ${tempQuestionHeight}px)`
                  : 'unset',
            }}
          >
            {question.isTemp && !question?.answer && questions?.length > 1 && (
              <AILoaderThread type="thread_chat" />
            )}

            {question?.answer ? (
              <Answer question={question} answer={question.answer} />
            ) : (
              <>
                {question?.chatAnswers?.map((qA) => (
                  <Answer key={qA.id} question={question} answer={qA} />
                ))}
              </>
            )}
          </div>
        </div>
      ))}
    </div>
  );
};

export default Conversation;
