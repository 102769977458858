import { useState } from 'react';

const usePagination = (data, initialLimit = 5) => {
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(initialLimit);
  const [loading, setLoading] = useState(false);

  const getPaginatedData = (rawData) => {
    const startIndex = (page - 1) * limit;
    const endIndex = startIndex + limit;
    return rawData.slice(startIndex, endIndex);
  };

  const paginationInfo = (totalCount) => ({
    page,
    limit,
    totalPages: Math.ceil(totalCount / limit),
    count: totalCount,
    from: (page - 1) * limit + 1,
    to: Math.min(page * limit, totalCount),
  });

  const handlePageChange = (newPage) => {
    setLoading(true);
    setPage(newPage);
    setLoading(false);
  };

  const handleLimitChange = (newLimit) => {
    setLoading(true);
    setLimit(newLimit);
    setPage(1); // Reset to first page when changing limit
    setLoading(false);
  };

  return {
    page,
    limit,
    loading,
    getPaginatedData,
    paginationInfo,
    handlePageChange,
    handleLimitChange,
  };
};

export default usePagination;
