import React, { useState } from 'react';
import { Card } from 'react-bootstrap';
import { sortingTable } from '../../utils/sortingTable';
import Table from '../../components/GenericTable';
import LayoutHead from '../../components/commons/LayoutHead';
import MoreActions from '../../components/MoreActions';
import TableSkeleton from '../../components/commons/TableSkeleton';
import { DataFilters } from '../../components/DataFilters';
import TableFooterStats from '../../components/TableFooterStats';
import {
  changePaginationPage,
  changePaginationLimit,
} from '../Deals/contacts/utils';
import { setDateFormat } from '../../utils/Utils';
import ButtonFilterDropdown from '../../components/commons/ButtonFilterDropdown';
import StatusLabel from '../../components/commons/StatusLabel';
import LucideIcon from '../../components/commons/LucideIcon';
import DeleteConfirmationModal from '../../components/modal/DeleteConfirmationModal';
import ArchiveService from '../../services/archive.service';

const PremiumContentTable = (props) => {
  const {
    allTenants,
    stats,
    dataInDB,
    showLoading,
    pagination,
    paginationPage,
    setPaginationPage,
    setCreate,
    order,
    setOrder,
    filter,
    setFilter,
    setSuccessMessage,
    refresh,
  } = props;

  const [selectAll, setSelectAll] = useState(false);
  const [selectedData, setSelectedData] = useState([]);
  const [showTooltip, setShowTooltip] = useState(true);
  const [purchaseTypeFilter, setPurchaseTypeFilter] = useState({
    key: 0,
    name: 'All',
    type: 'All',
  });
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [componentsToDelete, setComponentsToDelete] = useState([]);
  const purchaseTypeOptions = [
    { key: 1, name: 'All', type: 'All' },
    { key: 2, name: 'Recurring', type: 'recurring' },
    { key: 3, name: 'One-time', type: 'oneTime' },
    { key: 4, name: 'Free', type: 'free' },
  ];

  const handleDeleteContent = async (item) => {
    setComponentsToDelete([item]);
    setOpenDeleteModal(true);
  };

  const getUsersCountByStatus = (users, status) => {
    if (!users || !Array.isArray(users)) return 0;
    return users.filter((user) => user.status === status).length;
  };

  const formatCurrency = (value) => {
    if (!value) return '$0.00';
    return `$${parseFloat(value).toFixed(2)}`;
  };

  // Updated columns based on the provided static data
  const columns = [
    {
      key: 'name',
      orderBy: 'name',
      component: 'Title',
    },
    {
      key: 'total-users',
      component: 'Users',
    },
    {
      key: 'active-users',
      component: 'Tenants',
    },
    {
      key: 'purchase-type',
      component: 'Purchase Type',
    },
    {
      key: 'price',
      component: 'Price',
    },
    {
      key: 'created_at',
      orderBy: 'created_at',
      component: 'Created At',
    },
    {
      key: 'updated_at',
      orderBy: 'updated_at',
      component: 'Last Modified',
    },
    {
      key: 'status',
      component: 'Status',
      classes: 'px-0',
    },
    {
      key: 'action',
      component: '',
    },
  ];

  const optionsList = [
    {
      id: 'remove',
      icon: 'delete',
      name: 'Delete',
      className: 'bg-hover-danger',
    },
  ];

  const filteredTenants = allTenants.filter((tenant) => {
    const matchesPurchaseType =
      purchaseTypeFilter.key === 0 ||
      (tenant.paymentType && tenant.paymentType.key === purchaseTypeFilter.key);
    const matchesSearch =
      !filter?.search ||
      tenant.name.toLowerCase().includes(filter?.search.toLowerCase());

    return matchesPurchaseType && matchesSearch;
  });

  const handleDeleteEvent = async () => {
    try {
      await ArchiveService.deleteArchive(componentsToDelete[0].archiveId);
      setSuccessMessage('Content Deleted.');
      refresh();
    } catch (e) {
      console.log(e);
    }
  };

  const PaymentTypes = {
    recurring: 'Recurring',
    oneTime: 'One-time',
    free: 'Free',
  };
  const data = filteredTenants?.map((content) => ({
    ...content,
    dataRow: [
      {
        key: 'name',
        component: (
          <div className="d-flex align-items-center gap-1">
            {content.icon ? (
              <span
                dangerouslySetInnerHTML={{ __html: `&#x${content.icon};` }}
                className="font-size-2xl"
              />
            ) : (
              <div
                className={`rounded-circle bg-beige p-1_2 bg-opacity-hover d-flex align-items-center`}
              >
                <LucideIcon icon="Files" size={18} clazz="text-black" />
              </div>
            )}
            <span className="p-0 pl-2 nav-link fw-bold text-truncate">
              {content.title}
            </span>
          </div>
        ),
      },
      {
        key: 'total-users',
        component: <span>{content?.users?.length || 0}</span>,
      },
      {
        key: 'active-users',
        component: (
          <span>{getUsersCountByStatus(content?.users, 'active')}</span>
        ),
      },
      {
        key: 'purchase-type',
        component: (
          <span>
            {PaymentTypes[content?.plans?.[0]?.paymentType] || 'Not specified'}
          </span>
        ),
      },
      {
        key: 'price',
        component: (
          <span>{formatCurrency(content?.plans?.[0]?.price || '0')}</span>
        ),
      },
      {
        key: 'created_at',
        component: <span>{setDateFormat(content?.createdAt)}</span>,
      },
      {
        key: 'updated_at',
        component: <span>{setDateFormat(content?.updatedAt)}</span>,
      },
      {
        key: 'status',
        component: (
          <span>
            <StatusLabel status="active" style={{ left: 3, bottom: 0 }} />
          </span>
        ),
      },
      {
        key: 'action',
        component: (
          <a className={`icon-hover-bg cursor-pointer`}>
            <MoreActions
              items={optionsList}
              onHandleRemove={() => handleDeleteContent(content)}
            />
          </a>
        ),
      },
    ],
  }));

  const sortTable = ({ name }) => {
    if (name === 'last_modified') name = 'updated_at';
    if (name === 'action') return null;
    sortingTable({ name, order, setOrder });
  };

  const handleEditModalShow = async (item) => {
    setCreate(item);
  };
  const loader = () => {
    if (showLoading) return <TableSkeleton cols={4} rows={12} />;
  };

  const handleFilterChange = (e, option) => {
    e.preventDefault();
    setPurchaseTypeFilter(option);
    // Reset pagination to first page when filter changes
    if (paginationPage.page !== 1) {
      setPaginationPage({ ...paginationPage, page: 1 });
    }
  };

  return (
    <>
      <Card className="mb-0 card-0 rounded-0">
        <Card.Header className="flex-column align-items-unset px-0">
          <div className="d-flex align-items-center px-3 w-100">
            <div className="d-flex align-items-center flex-grow-1 gap-x-4">
              <DataFilters
                filterSelected={filter}
                setFilterSelected={setFilter}
                searchPlaceholder="Search content"
                paginationPage={paginationPage}
                setPaginationPage={setPaginationPage}
              />
              <div className="mx-2 d-none">
                <ButtonFilterDropdown
                  buttonText="Purchase Type"
                  options={purchaseTypeOptions}
                  filterOptionSelected={purchaseTypeFilter}
                  handleFilterSelect={handleFilterChange}
                />
              </div>
            </div>
            <LayoutHead
              onHandleCreate={() => setCreate({})}
              buttonLabel="Add Content"
              selectedData={selectedData}
              onDelete={true}
              headingTitle=""
              dataInDB={dataInDB}
              alignTop="mb-0"
            />
          </div>
        </Card.Header>
        <Card.Body className="p-0">
          <div className="table-responsive-md">
            <div
              id="datatable_wrapper"
              className="dataTables_wrapper no-footer"
            >
              {showLoading ? (
                loader()
              ) : (
                <Table
                  stickyFooter
                  actionPadding="p-0"
                  columns={columns}
                  data={data}
                  selectedData={selectedData}
                  setSelectedData={setSelectedData}
                  selectAll={selectAll}
                  setSelectAll={setSelectAll}
                  showPerPage={true}
                  paginationInfo={pagination}
                  onPageChange={(newPage) => {
                    changePaginationPage(newPage, setPaginationPage);
                  }}
                  onLimitChange={(perPage) => {
                    changePaginationLimit(perPage, setPaginationPage);
                  }}
                  emptyDataText="No Content available yet."
                  onClick={handleEditModalShow}
                  title="Content"
                  dataInDB={dataInDB}
                  showTooltip={showTooltip}
                  setShowTooltip={setShowTooltip}
                  toggle={() => setCreate(true)}
                  sortingTable={sortTable}
                  sortingOrder={order}
                  stats={() => <TableFooterStats stats={stats} />}
                  stickyClass="ida-table-container"
                />
              )}
            </div>
          </div>
        </Card.Body>
      </Card>
      <DeleteConfirmationModal
        showModal={openDeleteModal}
        setShowModal={setOpenDeleteModal}
        setSelectedCategories={setComponentsToDelete}
        event={handleDeleteEvent}
        itemsConfirmation={componentsToDelete}
        heading="Delete Content"
        description="Are you sure you want to delete this content?"
      />
    </>
  );
};

export default PremiumContentTable;
