import React from 'react';
import Avatar from './Avatar';

const AvatarHoverImage = ({
  setOpenModalAvatar,
  data,
  type,
  size = 'lg_2',
}) => {
  return (
    <label
      htmlFor="avatar"
      className="mb-0"
      onClick={() => setOpenModalAvatar(true)}
    >
      <div className="new-edit-avatar-container">
        <Avatar
          user={data}
          defaultSize={size}
          type={type}
          classModifiers={
            type === 'contact'
              ? 'avatar-primary'
              : 'bg-primary-soft text-primary'
          }
          sizeIcon={type === 'contact' ? 'font-size-lg' : 'fs-2'}
        />
        <div className={`hover-img avatar-${size}`}>
          <i className="material-symbols-rounded fs-1 text-white">imagesmode</i>
        </div>
      </div>
    </label>
  );
};

export default AvatarHoverImage;
