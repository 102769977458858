import React from 'react';
import { TabContent, TabPane } from 'reactstrap';
import { UsersLessonReport } from './UserLessonsReport';
import AnimatedTabs from '../../../../../components/commons/AnimatedTabs';
import { TeamsLessonReport } from './TeamsLessonsReport';

const TabTitle = ({ icon, title }) => (
  <div className="d-flex align-items-center tab-title">
    <span className="material-symbols-rounded fs-5 m-1 ">{icon}</span>
    <span>{title}</span>
  </div>
);

const LessonDetailsReport = ({ data = [], activeTab, setActiveTab }) => {
  const tabsData = [
    {
      title: <TabTitle title="Team" />,
      component: <TeamsLessonReport results={data} />,
      tabId: 1,
    },
    {
      title: <TabTitle title="User" />,
      component: <UsersLessonReport results={data} />,
      tabId: 2,
    },
  ];

  const toggle = (tab) => {
    if (activeTab !== tab.tabId) {
      setActiveTab(tab.tabId);
    }
  };

  return (
    <>
      <div className="w-100">
        <AnimatedTabs
          tabsData={tabsData}
          activeTab={activeTab}
          toggle={toggle}
          tabActiveClass={'active mb-0'}
        />
      </div>
      <TabContent>
        <TabPane>
          {tabsData.find((item) => item.tabId === activeTab)?.component}
        </TabPane>
      </TabContent>
    </>
  );
};
export default LessonDetailsReport;
