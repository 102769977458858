import React, { useCallback, useEffect, useMemo, useState } from 'react';
import useFetchArchives from '../hooks/useFetchArchives';
import SimpleModalCreation from '../../modal/SimpleModalCreation';
import { overflowing } from '../../../utils/Utils';
import Skeleton from 'react-loading-skeleton';
import NoDataFound from '../../commons/NoDataFound';
import { FormCheck, ListGroup, ListGroupItem } from 'react-bootstrap';
import AnimatedTabs from '../../commons/AnimatedTabs';
import useNachaDisclaimerModal from './NachaDisclaimer';

const SelectKnowledgeSourceModal = ({
  show,
  setShow,
  selected,
  setSelected,
  onSelectAll,
}) => {
  const KnowledgeTabs = {
    Folders: {
      id: 1,
      tabId: 1,
      title: 'Folders',
      label: 'Folders',
    },
    Premium: {
      id: 2,
      tabId: 2,
      title: 'Premium',
      label: 'Premium',
    },
  };
  const {
    archives,
    fetchArchives,
    loading,
    loadingPremiumArchives,
    premiumArchives,
    fetchPremiumArchives,
  } = useFetchArchives(false, 20);

  const [checkedItems, setCheckedItems] = useState([]);
  const [allKnowledge, setAllKnowledge] = useState(false);
  const [tabs] = useState(Object.values(KnowledgeTabs));
  const [activeTab, setActiveTab] = useState(KnowledgeTabs.Folders.id);
  const handleCloseModal = () => {
    setShow(false);
    overflowing();
  };

  const {
    NachaDisclaimerModal,
    setShow: setShowNachaModal,
    setSelected: setNachaSelected,
  } = useNachaDisclaimerModal(handleCloseModal, setSelected);

  const handleSubmit = () => {
    // temp solution until BE supports
    const nachaExists = checkedItems.find((f) =>
      f.title.toLowerCase().includes('nacha')
    );
    if (nachaExists) {
      setNachaSelected(nachaExists);
      setShowNachaModal(true);
    } else {
      setSelected(checkedItems);
      handleCloseModal();
    }
  };

  useEffect(() => {
    if (show) {
      activeTab === KnowledgeTabs.Folders.id && fetchArchives();
      activeTab === KnowledgeTabs.Premium.id && fetchPremiumArchives();
      setCheckedItems(selected || []);
    }
  }, [show, activeTab]);

  const handleToggleSelectAll = (e) => {
    setAllKnowledge(e.target.checked);
    setCheckedItems([]);
    onSelectAll(e.target.checked);
  };

  const handleToggleItem = (archive) => {
    setAllKnowledge(false);
    const isSelected = checkedItems
      ?.map((arch) => arch.archiveId)
      .includes(archive.archiveId);
    if (isSelected) {
      setCheckedItems(
        checkedItems.filter((arch) => arch.archiveId !== archive.archiveId)
      );
    } else {
      setCheckedItems([...checkedItems, archive]);
    }
  };

  const toggle = (tab) => {
    if (activeTab !== tab.tabId) {
      setCheckedItems([]);
      setActiveTab(tab.tabId);
    }
  };
  return (
    <>
      <NachaDisclaimerModal />
      <SimpleModalCreation
        modalHeaderClasses="flex-fill"
        modalTitle="Select Knowledge Source"
        saveButtonStyle="btn btn-primary btn-sm d-flex align-items-center"
        bodyClassName="mb-0 h-100 overflow-y-auto py-0 px-3"
        open={show}
        handleSubmit={handleSubmit}
        saveButton="Select"
        buttonsDisabled={!archives?.length || loading}
        footerStyle="border-0"
        onHandleCloseModal={handleCloseModal}
      >
        <div className="d-flex flex-column gap-3">
          {loading ? (
            <div className="py-3 px-2">
              <Skeleton count={3} width="100%" className="mb-2" />
            </div>
          ) : archives?.length ? (
            <>
              <div>
                <AnimatedTabs
                  tabsData={tabs}
                  activeTab={activeTab}
                  tabClasses="px-0 gap-2 justify-content-center pt-0"
                  tabItemClasses="border-0 font-size-sm2 px-2 rounded text-black bg-hover-gray mb-0 py-1"
                  tabActiveClass="bg-primary-soft-darker text-primary fw-bold"
                  toggle={toggle}
                />
              </div>
              {activeTab === KnowledgeTabs.Folders.id && (
                <div className="d-flex flex-column gap-2">
                  <FormCheck
                    id="selectAllKnowledge"
                    name="selectAllKnowledge"
                    className="d-none"
                    type="checkbox"
                    custom={true}
                    label="Select All"
                    checked={allKnowledge}
                    onChange={handleToggleSelectAll}
                  />
                  <div className="max-h-300 overflow-y-auto">
                    <ListGroup>
                      {archives.map((archive) => (
                        <ListGroupItem
                          key={archive.archiveId}
                          className={`d-flex bg-hover-gray align-items-center`}
                        >
                          <FormCheck
                            id={`select ${archive.archiveId}`}
                            type="checkbox"
                            disabled={allKnowledge}
                            custom={true}
                            label={archive.title}
                            checked={
                              allKnowledge === true ||
                              checkedItems
                                ?.map((arch) => arch.archiveId)
                                .includes(archive.archiveId)
                            }
                            onChange={() => handleToggleItem(archive)}
                          />
                        </ListGroupItem>
                      ))}
                    </ListGroup>
                  </div>
                </div>
              )}
              {activeTab === KnowledgeTabs.Premium.id && (
                <div>
                  {loadingPremiumArchives ? (
                    <div>
                      <Skeleton count={3} width="100%" className="mb-2" />
                    </div>
                  ) : (
                    <div className="max-h-300 overflow-y-auto">
                      <ListGroup>
                        {premiumArchives.map((archive) => (
                          <ListGroupItem
                            key={archive.archiveId}
                            className={`d-flex bg-hover-gray align-items-center`}
                          >
                            <FormCheck
                              id={`select ${archive.archiveId}`}
                              type="checkbox"
                              disabled={allKnowledge}
                              custom={true}
                              label={archive.title}
                              checked={
                                allKnowledge === true ||
                                checkedItems
                                  ?.map((arch) => arch.archiveId)
                                  .includes(archive.archiveId)
                              }
                              onChange={() => handleToggleItem(archive)}
                            />
                          </ListGroupItem>
                        ))}
                      </ListGroup>
                    </div>
                  )}
                </div>
              )}
            </>
          ) : (
            <NoDataFound
              icon="manage_search"
              containerStyle="text-gray-search py-3"
              titleStyle="font-normal font-size-sm2 text-gray-search"
              title="No knowledge source found."
            />
          )}
        </div>
      </SimpleModalCreation>
    </>
  );
};

const useSelectKnowledgeSourceModal = (onSelectAll) => {
  const [show, setShow] = useState(false);
  const [selected, setSelected] = useState([]);

  const SelectKnowledgeSourceModalCallback = useCallback(() => {
    return (
      <SelectKnowledgeSourceModal
        setShow={setShow}
        show={show}
        selected={selected}
        setSelected={setSelected}
        onSelectAll={onSelectAll}
      />
    );
  }, [show, setShow]);

  return useMemo(
    () => ({
      setShow,
      selected,
      setSelected,
      SelectKnowledgeSourceModal: SelectKnowledgeSourceModalCallback,
    }),
    [setShow, SelectKnowledgeSourceModalCallback]
  );
};

export default useSelectKnowledgeSourceModal;
