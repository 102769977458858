import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { overflowing } from '../../../utils/Utils';
import { FormGroup, Label } from 'reactstrap';
import { Alert, InputGroup } from 'react-bootstrap';
import CurrencyInput from 'react-currency-input-field';
import AlertWrapper from '../../../components/Alert/AlertWrapper';
import SimpleModalCreation from '../../../components/modal/SimpleModalCreation';
import EmojiPickerDropdown from '../../../components/commons/EmojiPickerDropdown';
import MaterialIcon from '../../../components/commons/MaterialIcon';
import InputValidation from '../../../components/commons/InputValidation';
import Asterick from '../../../components/commons/Asterick';
import ButtonFilterDropdown from '../../../components/commons/ButtonFilterDropdown';
import ArchiveService from '../../../services/archive.service';
import useHash from '../../../hooks/useHash';

const PaymentTypes = [
  { key: 'recurring', name: 'Recurring' },
  { key: 'oneTime', name: 'One-time' },
  { key: 'free', name: 'Free' },
];

const UnitTypes = [
  { key: 'user', name: 'Per User' },
  { key: 'tenant', name: 'Per Tenant' },
];

const PaymentPeriodOptions = [
  { key: 'monthly', name: 'Monthly' },
  { key: 'yearly', name: 'Yearly' },
];

const PremiumContentModal = ({
  show,
  setShow,
  setSuccessMessage,
  setErrorMessage,
  content = {},
  handleCreateClick,
}) => {
  const [emoji, setEmoji] = useState(null);
  const defaultValues = {
    title: '',
    icon: null,
    description: '',
    disclaimer: '',
    paymentType: PaymentTypes[0],
    price: '',
    unit: 'user',
    quantity: 0,
    paymentPeriod: 'monthly',
  };

  const {
    register,
    handleSubmit,
    getFieldState,
    setValue,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues,
  });

  const { updateHash } = useHash();
  const [contentForm, setContentForm] = useState(defaultValues);
  const [, setLogo] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const handlePaymentType = (e, pType) => {
    e.preventDefault();
    setContentForm((prev) => ({
      ...prev,
      paymentType: pType,
    }));
    setValue('paymentType', pType.type);
  };

  const checkKeyDown = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
    }
  };

  const onSubmit = async () => {
    setIsLoading(true);
    try {
      const contentModelObject = {
        title: contentForm.title,
        icon: contentForm.icon || '',
        description: contentForm.description,
        plan: {
          paymentType: contentForm?.paymentType?.key || PaymentTypes[0].key,
          price: Number(contentForm.price || '0'),
          unit: contentForm.unit || 'user',
          paymentPeriod: contentForm.paymentPeriod || 'monthly',
        },
      };
      const response = await ArchiveService.createArchiveTransaction(
        contentModelObject
      );
      const data = await response.json();
      const archiveId = data?.input?.archive?.archiveId;
      if (archiveId) {
        setSuccessMessage('Content Created.');
        updateHash(`details/${archiveId}`);
        handleCreateClick({
          archiveId,
          ...contentModelObject,
        });
        setContentForm(defaultValues);
        reset(defaultValues);
        setShow(false);
        overflowing();
      } else {
        setErrorMessage('Failed to create content');
      }
    } catch (error) {
      setErrorMessage('Failed to create content');
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (show) {
      setContentForm({ ...content });
      setEmoji(content.icon);
    }
  }, [show, content]);

  const handleChange = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    setContentForm((prev) => ({
      ...prev,
      [name]: value,
    }));
    setValue(name, value);
  };

  return (
    <SimpleModalCreation
      modalTitle="Create Premium Content"
      open={show}
      saveButton="Create Content"
      isLoading={isLoading}
      buttonsDisabled={isLoading}
      deleteButton={null}
      handleSubmit={handleSubmit(onSubmit)}
      bodyClassName="p-3"
      size="lg"
      scrollable={true}
      maxHeight="80vh"
      onHandleCloseModal={() => {
        overflowing();
        setShow(false);
        setContentForm(defaultValues);
        setLogo(null);
      }}
    >
      <form onSubmit={handleSubmit(onSubmit)} onKeyDown={checkKeyDown}>
        <div className="d-flex gap-2 align-items-end">
          <FormGroup className="flex-grow-1">
            <Label htmlFor="title">
              Title
              <Asterick />
            </Label>
            <InputValidation
              id="title"
              name="title"
              type="input"
              placeholder="Title"
              value={contentForm?.title}
              validationConfig={{
                required: true,
                inline: false,
                onChange: handleChange,
                validateSpaces: true,
              }}
              errors={errors}
              register={register}
              errorDisplay="mb-0"
            />
          </FormGroup>
          <FormGroup className="ml-2 d-flex flex-column">
            <Label className="position-relative" style={{ top: -10 }}>
              Emoji
            </Label>
            <EmojiPickerDropdown
              setEmoji={({ unified }) => {
                setEmoji(unified);
                setContentForm((prev) => ({ ...prev, icon: unified }));
                setValue('icon', unified);
              }}
            >
              <a
                className="cursor-pointer fs-3 icon-hover-bg position-relative"
                style={{ top: -7 }}
              >
                {emoji ? (
                  <span
                    dangerouslySetInnerHTML={{
                      __html: `&#x${emoji};`,
                    }}
                  />
                ) : (
                  <MaterialIcon
                    icon="add"
                    isLucide={false}
                    size="fs-3"
                    clazz="bg-gray-100 rounded-circle p-2 d-flex align-items-center justify-content-center"
                  />
                )}
              </a>
            </EmojiPickerDropdown>
          </FormGroup>
        </div>
        <FormGroup>
          <Label htmlFor="description">Description</Label>
          <InputValidation
            id="description"
            name="description"
            type="textarea"
            placeholder="Description"
            value={contentForm?.description}
            validationConfig={{
              required: false,
              onChange: handleChange,
              maxLength: {
                value: 255,
                message: 'Description cannot exceed 255 characters.',
              },
            }}
            errors={errors}
            register={register}
            classNames="min-h-150"
          />
        </FormGroup>
        <FormGroup>
          <Label htmlFor="disclaimer">Content Disclaimer (Optional)</Label>
          <InputValidation
            id="disclaimer"
            name="disclaimer"
            type="textarea"
            disabled={true}
            placeholder="Disclaimer"
            value={contentForm?.disclaimer}
            validationConfig={{
              required: false,
              onChange: handleChange,
              maxLength: {
                value: 255,
                message: 'Disclaimer cannot exceed 255 characters.',
              },
            }}
            errors={errors}
            register={register}
            classNames="min-h-100px"
          />
        </FormGroup>
        <p className="text-muted fs-7 font-weight-normal mb-3">
          Note: Adding a disclaimer will display the message at the top of every
          chat messages screen.
        </p>
        <div className="mb-2">
          <div className="d-flex align-items-center flex-wrap gap-3">
            <FormGroup>
              <Label htmlFor="purchaseType">Type of Purchase</Label>
              <ButtonFilterDropdown
                menuClass="max-h-300"
                btnToggleStyle="w-200px"
                options={PaymentTypes}
                buttonText="Select"
                filterOptionSelected={contentForm?.paymentType}
                handleFilterSelect={(e, option) => {
                  handlePaymentType(e, option);
                }}
              />
            </FormGroup>
            {contentForm?.paymentType &&
              contentForm?.paymentType?.key !== 'free' && (
                <>
                  {contentForm?.paymentType?.key === 'recurring' ? (
                    <FormGroup>
                      <Label htmlFor="billingCycle">Billing Cycle</Label>
                      <ButtonFilterDropdown
                        buttonText="Select Billing Cycle"
                        hideIcon={true}
                        menuClass="text-capitalize"
                        btnToggleStyle="text-truncate w-200px btn-h-sm text-capitalize input-global-height"
                        options={PaymentPeriodOptions}
                        icon=""
                        fieldState={getFieldState('paymentPeriod')}
                        filterOptionSelected={
                          PaymentPeriodOptions?.find(
                            (option) => option.key === contentForm.paymentPeriod
                          ) || PaymentPeriodOptions[0]
                        }
                        handleFilterSelect={(e, item) => {
                          setContentForm((prev) => ({
                            ...prev,
                            paymentPeriod: item.key,
                          }));
                          setValue('paymentPeriod', item.key);
                        }}
                      />
                    </FormGroup>
                  ) : null}
                  <FormGroup>
                    <Label htmlFor="pricingModel">Pricing Model</Label>
                    <ButtonFilterDropdown
                      buttonText="Select Pricing Model"
                      hideIcon={true}
                      menuClass="text-capitalize"
                      btnToggleStyle="text-truncate w-200px btn-h-sm text-capitalize input-global-height"
                      options={UnitTypes}
                      icon=""
                      fieldState={getFieldState('unit')}
                      filterOptionSelected={
                        UnitTypes?.find(
                          (option) => option.key === contentForm.unit
                        ) || UnitTypes[0]
                      }
                      handleFilterSelect={(e, item) => {
                        setContentForm((prev) => ({
                          ...prev,
                          unit: item.key,
                        }));
                        setValue('unit', item.key);
                      }}
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label htmlFor="price">Price ($)</Label>
                    <InputGroup className="align-items-center">
                      <CurrencyInput
                        name="price"
                        placeholder="0"
                        value={contentForm.price || ''}
                        className="form-control w-100px"
                        onValueChange={(value) => {
                          setContentForm((prev) => ({
                            ...prev,
                            price: value,
                          }));
                          setValue('price', value);
                        }}
                      />
                    </InputGroup>
                  </FormGroup>
                </>
              )}
          </div>
        </div>
      </form>
    </SimpleModalCreation>
  );
};

const useCreatePremiumContentModal = (onUpdate, handleCreateClick) => {
  const [showModal, setShowModal] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [content, setContent] = useState({});

  const CreatePremiumContentModalCallback = useCallback(() => {
    return (
      <>
        <AlertWrapper className="alert-position">
          {successMessage && (
            <Alert
              color="success"
              message={successMessage}
              setMessage={setSuccessMessage}
            />
          )}
          {errorMessage && (
            <Alert
              color="danger"
              message={errorMessage}
              setMessage={setErrorMessage}
            />
          )}
        </AlertWrapper>
        <PremiumContentModal
          show={showModal}
          setShow={setShowModal}
          setSuccessMessage={setSuccessMessage}
          setErrorMessage={setErrorMessage}
          content={content}
          onUpdate={onUpdate}
          handleCreateClick={handleCreateClick}
        />
      </>
    );
  }, [
    showModal,
    setShowModal,
    successMessage,
    errorMessage,
    setSuccessMessage,
    setErrorMessage,
    content,
    onUpdate,
  ]);

  return useMemo(
    () => ({
      setShowModal,
      content,
      setContent,
      setSuccessMessage,
      setErrorMessage,
      CreatePremiumContentModal: CreatePremiumContentModalCallback,
    }),
    [
      setShowModal,
      setContent,
      setSuccessMessage,
      setErrorMessage,
      CreatePremiumContentModalCallback,
    ]
  );
};

export default useCreatePremiumContentModal;
