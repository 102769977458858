import authHeader from './auth-header';
import BaseRequestService from './baseRequest.service';

class RoleService extends BaseRequestService {
  async GetRoles(query = {}) {
    const { page = 1, limit = 1000, self = true, order, filter = {} } = query;
    return await this.get(`/roles`, {
      params: { order, page, limit, search: filter?.search, self },
      headers: authHeader(),
    });
  }

  async createRole(data) {
    return await this.post(`/roles`, data, { headers: authHeader() });
  }

  async getRoleById(roleId) {
    return await this.get(`/roles/${roleId}`, {
      headers: authHeader(),
    });
  }

  async updateRole(roleData) {
    return await this.put(`/roles/${roleData.id}`, roleData, {
      headers: authHeader(),
    });
  }

  async deleteRole(roleId, transferId) {
    return await this.delete(`/roles/${roleId}`, {
      headers: authHeader(),
      params: {
        transferId,
      },
    });
  }

  async getPermissionsByRole(roleId) {
    return await this.get(`/roles/${roleId}/permissions`, {
      headers: authHeader(),
    });
  }

  async updatePermissions(roleId, permissionsData) {
    return await this.put(`/roles/${roleId}/permissions`, permissionsData, {
      headers: authHeader(),
    });
  }
}

export default new RoleService();
