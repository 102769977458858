import SubHeading from '../../components/subheading';
import CardSkeleton from '../../components/lesson/CardSkeleton';
import React, { useContext } from 'react';
import { Card, CardBody, CardFooter, Col, Row } from 'reactstrap';
import { Link, useHistory } from 'react-router-dom';
import TopicIcon from '../../components/commons/TopicIcon';
import { sortByPinnedTopics, isModuleAllowed } from '../../utils/Utils';
import MyOrganizationCover from '../../assets/png/learn/categories/MyOrganization-Category.png';
import { TenantContext } from '../../contexts/TenantContext';
import TextOverflowTooltip from '../../components/commons/TextOverflowTooltip';
import MaterialIcon from '../../components/commons/MaterialIcon';

const myOrganization = {
  icon: 'corporate_fare',
  title: 'My Organization',
  cover: MyOrganizationCover,
};

const singleOrPlural = (total, singular, plural = null) => {
  return total === 1
    ? `${total} ${singular}`
    : `${total} ${plural || singular + 's'}`;
};

const TopicItem = ({ topic }) => {
  return (
    <Link to={topic.path}>
      <Card className="setting-item course-card lesson-card rounded-0">
        <CardBody className="p-2_1 px-3">
          <div className="d-flex flex-column h-100">
            <div className="d-flex">
              <TextOverflowTooltip
                textStyle="mt-3 mb-0 fs-6 font-weight-medium course-title"
                text={topic.title}
                lineClamp={2}
              />
            </div>
            <div className="d-flex align-items-center justify-content-between mt-auto mb-2">
              <TopicIcon
                filled={false}
                icon={topic.icon || 'category'}
                iconBg=""
                iconSize="font-size-4em"
              />
            </div>
          </div>
        </CardBody>
        <CardFooter className="p-2_1 px-3 bg-white">
          <Row className="justify-content-between align-items-center">
            <Col className="col-auto d-flex justify-content-between flex-row align-items-center w-100">
              <div className="w-100 d-flex justify-content-start gap-2">
                <div className="d-flex gap-1 align-items-center text-muted font-size-sm2">
                  {topic.totalCourses > 0 && (
                    <>
                      <MaterialIcon icon="stacks" />
                      {singleOrPlural(topic.totalCourses, 'Course')}
                    </>
                  )}
                </div>
                <div className="d-flex gap-1 align-items-center text-muted font-size-sm2">
                  {topic.totalLessons > 0 && (
                    <>
                      <MaterialIcon icon="list_alt" />
                      {singleOrPlural(topic.totalLessons, 'Lesson')}
                    </>
                  )}
                </div>
              </div>
            </Col>
          </Row>
        </CardFooter>
      </Card>
    </Link>
  );
};

const TopicItems = ({
  topics,
  heading,
  stats,
  updateFilterList,
  setSelectedFilter,
  organizationFilter,
}) => {
  const history = useHistory();
  return (
    <div className="position-relative course-card-parent">
      <div className="px-3 position-relative page-header-divider">
        <SubHeading title={heading} headingStyle="m-0 p-0" />
      </div>
      <div className="mt-4 px-3">
        <Row className="row-cols-1 pt-3 px-3_1 row-cols-sm-2 row-cols-md-3">
          {sortByPinnedTopics(topics)?.map((cat, indx) => (
            <>
              <Col
                className={`mb-3 px-3 pb-3 cursor-pointer lesson-card-resize ${
                  indx === 0 ? 'd-block' : 'd-none'
                }`}
              >
                <Card
                  className="setting-item course-card lesson-card rounded-0"
                  onClick={() => {
                    history.replace({
                      search: `?viewType=custom&ref=my-organization`,
                    });
                    setSelectedFilter(organizationFilter);
                  }}
                >
                  <CardBody className="p-2_1 px-3">
                    <div className="d-flex flex-column h-100">
                      <div className="d-flex">
                        <TextOverflowTooltip
                          textStyle="mt-3 mb-0 fs-6 font-weight-medium course-title"
                          text={myOrganization.title}
                          lineClamp={2}
                        />
                      </div>
                      <div className="d-flex align-items-center justify-content-between mt-auto mb-2">
                        <TopicIcon
                          filled={false}
                          icon={myOrganization.icon || 'category'}
                          iconBg=""
                          iconSize="font-size-3em"
                        />
                      </div>
                    </div>
                  </CardBody>
                  <CardFooter className="p-2_1 px-3 bg-white">
                    <Row className="justify-content-between align-items-center">
                      <Col className="col-auto d-flex justify-content-between flex-row align-items-center w-100">
                        <div className="w-100 d-flex justify-content-start gap-2">
                          <div className="d-flex gap-1 align-items-center text-muted font-size-sm2">
                            {stats.totalCourses > 0 && (
                              <>
                                <MaterialIcon icon="stacks" />
                                {singleOrPlural(stats.totalCourses, 'Course')}
                              </>
                            )}
                          </div>
                          <div className="d-flex gap-1 align-items-center text-muted font-size-sm2">
                            {stats.totalLessons > 0 && (
                              <>
                                <MaterialIcon icon="list_alt" />
                                {singleOrPlural(stats.totalLessons, 'Lesson')}
                              </>
                            )}
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </CardFooter>
                </Card>
              </Col>
              <Col key={indx} className="mb-3 px-3 pb-3">
                <TopicItem topic={cat} />
              </Col>
            </>
          ))}
        </Row>
      </div>
    </div>
  );
};

const Topics = ({
  loading,
  topics,
  stats,
  updateFilterList,
  setSelectedFilter,
  organizationFilter,
}) => {
  const { tenant } = useContext(TenantContext);
  return (
    <div>
      {loading && (
        <>
          <div className="px-3 position-relative page-header-divider">
            <SubHeading title="Categories" headingStyle="m-0 p-0" />
          </div>
          <div className="px-5 pt-3">
            <CardSkeleton count={3} cols="row-cols-md-3" className="p-3" />
          </div>
        </>
      )}

      {isModuleAllowed(tenant.modules, 'learns_lesson_catalog')
        ? topics?.exploreList?.length > 0 && (
            <TopicItems
              updateFilterList={updateFilterList}
              setSelectedFilter={setSelectedFilter}
              stats={stats}
              topics={topics?.exploreList}
              organizationFilter={organizationFilter}
              heading="Categories"
            />
          )
        : ''}
    </div>
  );
};

export default Topics;
