import React, { useCallback, useMemo, useState } from 'react';
import DeleteConfirmationModal from '../../../components/modal/DeleteConfirmationModal';
import ReportService from '../../../services/report.service';
import {
  getNaicsWithTitle,
  overflowing,
  splitCompanyName,
} from '../../../utils/Utils';
import {
  AccountStructureLookupFields,
  ActionTypes,
  ReportTypes,
} from '../../../components/reports/reports.constants';
import {
  getCycleDate,
  getReportName,
  MapBalanceItems,
} from '../../../components/reports/reports.helper.functions';
import useFetchSubOrganizations from '../useFetchSubOrganizations';
import {
  AccountSchematicReportSectionsWidgets,
  AccountStructureTabMap,
  AccountTypes,
  generateOneYearBalanceRecords,
} from '../../../components/reports/account-structure/account.structure.constants';
import FieldService from '../../../services/field.service';
import MetricsService from '../../../services/metrics.service';
import moment from 'moment';

const SyncAccountStructureReportModal = ({
  show,
  setShow,
  report,
  organization,
  handleGenerateReport,
  setParentModalShow,
}) => {
  const [refreshing, setRefreshing] = useState(false);
  const { fetchSubOrganizationsPromise } = useFetchSubOrganizations(
    organization,
    false,
    12
  );
  const handleConfirmDeleteReport = async () => {
    try {
      setRefreshing(true);
      const requests = [
        fetchSubOrganizationsPromise(),
        MetricsService.getOrganizationTreasuryMetricsData(organization.id),
      ];
      const [subOrgPromise, coreDataPromise] = await Promise.all(requests);
      const subOrgs = subOrgPromise.data;
      let coreDataTM = [];
      try {
        coreDataTM = coreDataPromise.data.map(MapBalanceItems);
      } catch (e) {
        console.log(e);
      }
      const reportDate = coreDataTM.length
        ? moment(coreDataTM[0].analysisDate).toDate()
        : moment().toDate();
      const oneYearBalances = generateOneYearBalanceRecords(
        reportDate.toISOString()
      );
      if (coreDataTM.length) {
        oneYearBalances.forEach((cd) => {
          const cdMonth = coreDataTM.find((d) => d.month === cd.month);
          if (cdMonth) {
            cd.value = cdMonth.value;
          }
        });
      }
      // fetch sub org fields 1 by 1
      const splittedNameAndNumber = splitCompanyName(organization.name);
      // for current org set all below fields along with operatingAccountType = AccountTypes.Operating
      const operatingAccount = {
        id: organization.id,
        accountName: splittedNameAndNumber?.name || organization.name,
        accountNumber: splittedNameAndNumber?.number || '',
        accountType: '',
        operatingAccountType: AccountTypes.Operating,
        fraudServices: '',
        productServices: '',
        status: 'complete',
      };
      const accounts = [];
      for (const suborg of subOrgs) {
        const { data } = await FieldService.getSubOrganizationFields(
          suborg.subOrganizationId
        );
        const subAccount = {
          id: suborg.subOrganizationId,
          accountName: suborg.name,
          accountNumber: '',
          accountType: '',
          operatingAccountType: AccountTypes.SubAccount,
          fraudServices: '',
          productServices: '',
          status: 'complete',
        };
        // for account card and for all sub orgs operatingAccountType = AccountTypes.SubAccount
        // id, accountName, accountNumber, accountType,
        // fraudServices (comma separated string), productServices (comma separated string)
        if (data.length) {
          data.forEach((dt) => {
            if (dt.field.key === AccountStructureLookupFields.AccountType) {
              subAccount.accountType = dt.value;
            } else if (
              dt.field.key === AccountStructureLookupFields.MaskedAccountNumber
            ) {
              subAccount.accountNumber = dt.value;
            } else if (
              dt.field.key === AccountStructureLookupFields.ProductAndServices
            ) {
              const fraudServices = dt.value
                .filter((f) => f.metadata.type === 'fraud')
                .map((f) => f.value)
                .join(', ');
              const productServices = dt.value
                .filter((f) => f.metadata.type === 'product')
                .map((f) => f.value)
                .join(', ');
              subAccount.fraudServices = fraudServices;
              subAccount.productServices = productServices;
            }
          });
        }

        accounts.push(subAccount);
      }
      accounts.unshift(operatingAccount);
      const newReport = {
        [AccountStructureTabMap.ReportInfo.key]: {
          companyName: organization.name,
          reportDate: getCycleDate(reportDate),
          valueN: getNaicsWithTitle({}, organization),
          valueNaicsSic: getNaicsWithTitle({}, organization, true),
        },
        [AccountStructureTabMap.ReportDetails.key]: {
          accounts,
          authorizedSigners: [],
          authorizedUsers: [],
          balances: oneYearBalances,
          bankContacts: [],
        },
        [AccountStructureTabMap.WorkingCapital.key]: {
          isActive: false,
          data: {},
        },
        [AccountStructureTabMap.Widgets.key]: {
          ...AccountSchematicReportSectionsWidgets,
        },
      };
      const reportCreate = {
        name: organization.name,
        date: reportDate.toISOString(),
        type: ReportTypes.AccountStructure,
        manualInput: {
          insightsData: {},
          isAuto: true,
          coreData: {
            reportDate: reportDate.toISOString(),
          },
          ...newReport,
        },
      };
      await ReportService.updateReport(report.reportId, reportCreate);
      overflowing();
      setParentModalShow(false);
      handleGenerateReport(report, ActionTypes.UPDATE, {
        ...reportCreate,
        reportId: report.reportId,
        key: report.reportId,
      });
    } catch (e) {
      console.log(e);
    } finally {
      setRefreshing(false);
    }
  };

  return (
    <DeleteConfirmationModal
      showModal={show}
      loader={refreshing}
      setShowModal={setShow}
      customEvent={handleConfirmDeleteReport}
      itemsConfirmation={[{ ...report, title: getReportName(report) }]}
      heading="Refresh Report"
      description="Are you sure you want to refresh this Report?"
      positiveBtnText="Refresh"
    />
  );
};

const useResyncAccountStructureReportModal = (
  handleGenerateReport,
  setParentModalShow
) => {
  const [showModal, setShowModal] = useState(false);
  const [report, setReport] = useState('');
  const [organization, setOrganization] = useState('');

  const SyncAccountStructureReportModalCallback = useCallback(() => {
    return (
      <SyncAccountStructureReportModal
        show={showModal}
        setShow={setShowModal}
        report={report}
        organization={organization}
        handleGenerateReport={handleGenerateReport}
        setParentModalShow={setParentModalShow}
      />
    );
  }, [
    showModal,
    setShowModal,
    report,
    setReport,
    organization,
    setOrganization,
  ]);

  return useMemo(
    () => ({
      setShowModal,
      setReport,
      setOrganization,
      SyncAccountStructureReportModal: showModal
        ? SyncAccountStructureReportModalCallback
        : () => <div />,
    }),
    [setShowModal, SyncAccountStructureReportModalCallback]
  );
};

export default useResyncAccountStructureReportModal;
