import Skeleton from 'react-loading-skeleton';
import { numbersWithComma, roundNumbers } from '../utils/Utils';

const StatItem = ({ label, count, type, classNames }) => {
  return (
    <div className={`d-flex align-items-center gap-1 ${classNames || ''}`}>
      <label className="mb-0 fs-7 font-weight-normal">{label}</label>
      <span className="text-gray-400">•</span>
      <label className="mb-0 text-black">
        <span>
          {type === 'currency' ? '$' : ''}
          {count}
          {type === 'percentage' ? '%' : ''}
        </span>
      </label>
    </div>
  );
};

const TableFooterStats = ({ stats, loading = false }) => {
  if (!stats) {
    return null;
  }
  return (
    <>
      <div className="stats">
        {loading ? (
          <ul className="w-100">
            <li className="flex-fill px-2">
              <Skeleton width={100} height={10} />
            </li>
          </ul>
        ) : (
          <ul>
            {stats
              ?.sort((a, b) => a.order - b.order)
              ?.map((item) => (
                <li key={item.id} className="font-size-sm2">
                  <StatItem
                    count={
                      item?.rounded
                        ? roundNumbers(item.count, 'short', 2)
                        : numbersWithComma(item.count)
                    }
                    type={item.type}
                    label={item.label}
                  />
                </li>
              ))}
          </ul>
        )}
      </div>
    </>
  );
};

export default TableFooterStats;
