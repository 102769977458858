import { BatchSpanProcessor } from '@opentelemetry/sdk-trace-base';
import { WebTracerProvider } from '@opentelemetry/sdk-trace-web';
import { resourceFromAttributes } from '@opentelemetry/resources';
import {
  ATTR_SERVICE_NAME,
  SemanticResourceAttributes,
} from '@opentelemetry/semantic-conventions';
import { OTLPTraceExporter } from '@opentelemetry/exporter-trace-otlp-http';

const API_URL = process.env.REACT_APP_API_URL;
const OTEL_RESOURCE_ATTRIBUTES =
  process.env.REACT_APP_OTEL_RESOURCE_ATTRIBUTES || '';

let tracer;

/**
 * Initialize OpenTelemetry for the application
 */
export const initializeTracing = ({ serviceName } = {}) => {
  const exporter = new OTLPTraceExporter({
    headers: {
      'Content-Type': 'application/json',
    },
    url: `${API_URL}/api/telemetry/trace`,
  });

  const otelResourceAttributesMap = OTEL_RESOURCE_ATTRIBUTES.split(',')
    .map((attr) => attr.split('='))
    .reduce((acc, [key, value]) => {
      if (key) {
        acc[key] = value;
      }
      return acc;
    }, {});

  const resource = resourceFromAttributes({
    ...otelResourceAttributesMap,
    [ATTR_SERVICE_NAME]: serviceName,
    [SemanticResourceAttributes.DEPLOYMENT_ENVIRONMENT]: process.env.NODE_ENV,
  });

  const provider = new WebTracerProvider({
    resource,
    spanProcessors: [new BatchSpanProcessor(exporter)],
  });
  provider.register();

  tracer = provider.getTracer(serviceName);
};

// Export a default function that initializes tracing
export default function setupOpenTelemetry(options = {}) {
  initializeTracing(options);
}

export function getTracer() {
  if (!tracer) {
    return;
  }
  return tracer;
}
