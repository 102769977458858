/* eslint-disable no-unused-vars */
import React, { useState } from 'react';
import PageTitle from '../../commons/PageTitle';
import { Card, ProgressBar } from 'react-bootstrap';
import { ActionStatuses, FormattedValue } from '../../../utils/Utils';
import Table from '../../GenericTable';
import TextOverflowTooltip from '../../commons/TextOverflowTooltip';
import TableRowHover from '../../commons/TableRowHover';
import StatCardHeader from '../shared/StatCardHeader';
import StatCardList from '../shared/StatCardList';
import StatusBadge from '../shared/StatusBadge';

const Data = [
  {
    title: 'Total Pipeline Value',
    icon: 'Banknote',
    value: '3452783',
    type: 'currency',
    label: 'Across 57 active deals',
  },
  {
    title: 'Total Deals',
    icon: 'Briefcase',
    value: '57',
    type: 'number',
    label: 'Avg value: $60,575',
  },
  {
    title: 'Avg Deal Value',
    icon: 'Calculator',
    value: '60575',
    type: 'currency',
    label: '+11.4% vs last year',
  },
  {
    title: 'Total Sales Calls YTD',
    icon: 'Phone',
    value: '1287',
    type: 'number',
    label: '102 calls this month',
  },
];

const Data2 = [
  {
    title: 'Booked Revenue vs Annual Goal',
    icon: 'Target',
    revenue: '266127',
    annualGoal: '500000',
    percentage: '72',
    variant: 'warning',
  },
  {
    title: 'Rolling Pipeline Target',
    icon: 'TrendingUp',
    revenue: '545625',
    annualGoal: '625000',
    percentage: '85',
    variant: 'success',
  },
  {
    title: 'Booked Deposit vs Annual Deposit Goal',
    icon: 'Target',
    revenue: '5000000',
    annualGoal: '10000000',
    percentage: '50',
    variant: 'success',
  },
  {
    title: 'Deposit Pipeline vs Pipeline Coverage Ratio',
    icon: 'TrendingUp',
    revenue: '3000000',
    annualGoal: '12500000',
    percentage: '24',
    variant: 'danger',
  },
];

const tableColumns0 = [
  {
    key: 'name',
    component: <span>Company Name</span>,
    classes: 'w-200px',
  },
  {
    key: 'activity',
    component: <span>Suggested Activity</span>,
  },
  {
    key: 'pusrchase_date',
    component: <span>Expected Purchase Date</span>,
  },
  {
    key: 'last_updated',
    component: <span>Last Updated</span>,
  },
];

const tableColumns = [
  {
    key: 'name',
    component: <span>Relationship Manager</span>,
    classes: 'w-200px',
  },
  {
    key: 'leads',
    component: <span>Leads</span>,
  },
  {
    key: 'revenue',
    component: <span>Potential Revenue</span>,
  },
  {
    key: 'conversion_rate',
    component: <span>Conversion Rate</span>,
  },
];

const tableColumns2 = [
  {
    key: 'name',
    component: <span>Client Tier</span>,
    classes: 'w-200px',
  },
  {
    key: 'active_deals',
    component: <span>Active Deals</span>,
  },
  {
    key: 'pipeline_value',
    component: <span>Pipeline Value</span>,
  },
  {
    key: 'close_rate',
    component: <span>Avg Close Rate</span>,
  },
];

const rowData0 = [
  {
    name: 'ABC Company',
    activity: 'Stale Dated Pipeline',
    purchase_date: '3/1/2025',
    last_updated: '3/1/2025',
  },
  {
    name: 'MotoMoto Inc',
    activity: 'Purchase Date within 30 Days',
    purchase_date: '4/1/2025',
    last_updated: '--',
  },
  {
    name: 'Johnson & Lee',
    activity: 'Purchase Date within 30 Days',
    purchase_date: '4/1/2025',
    last_updated: '--',
  },
  {
    name: '2Gym Bros',
    activity: 'Purchase Date within 30 Days',
    purchase_date: '5/1/2025',
    last_updated: '--',
  },
  {
    name: 'Vram & Vigen Inc',
    activity: 'Purchase Date within 30 Days',
    purchase_date: '5/1/2025',
    last_updated: '--',
  },
];

const rowData = [
  {
    name: 'John Smith',
    leads: 24,
    revenue: '127849',
    conversion_rate: '28.5',
    status: 'Success',
  },
  {
    name: 'Nora Jones',
    leads: 32,
    revenue: '120839',
    conversion_rate: '26.7',
    status: 'Success',
  },
  {
    name: 'Ben Lee',
    leads: 22,
    revenue: '101830',
    conversion_rate: '21.2',
    status: 'Medium',
  },
  {
    name: 'KJ Curtis',
    leads: 29,
    revenue: '98849',
    conversion_rate: '19.8',
    status: 'Medium',
  },
  {
    name: 'Mike Zhou',
    leads: 34,
    revenue: '89839',
    conversion_rate: '16.3',
    status: 'High',
  },
];

const rowData2 = [
  {
    name: 'Enterprise',
    activeDeals: 14,
    pipelineValue: '1293826',
    close_rate: '32.7',
    status: 'Success',
  },
  {
    name: 'Mid-Market',
    activeDeals: 13,
    pipelineValue: '893451',
    close_rate: '28.5',
    status: 'Success',
  },
  {
    name: 'SMB Plus',
    activeDeals: 16,
    pipelineValue: '765394',
    close_rate: '22.1',
    status: 'Medium',
  },
  {
    name: 'SMB Standard',
    activeDeals: 8,
    pipelineValue: '359879',
    close_rate: '19.3',
    status: 'Medium',
  },
  {
    name: 'Startup',
    activeDeals: 6,
    pipelineValue: '140233',
    close_rate: '14.8',
    status: 'High',
  },
];

const PipelinesHome = () => {
  const [loading, setLoading] = useState(false);

  const rows0 = rowData0?.map((item, index) => {
    return {
      ...item,
      dataRow: [
        {
          key: 'name',
          classes: 'px-0',
          component: (
            <div className="text-black text-truncate fw-bold d-flex align-items-center gap-2">
              <TextOverflowTooltip
                maxLength={25}
                textStyle="fw-bold text-truncate font-size-sm2 mb-0 z-index-100"
                text={item.name}
              />
            </div>
          ),
        },
        {
          key: 'activity',
          classes: 'px-0',
          component: (
            <div className="text-truncate d-flex align-items-center gap-2">
              <TextOverflowTooltip
                maxLength={50}
                textStyle="text-truncate font-size-sm2 mb-0 z-index-100"
                text={item.activity}
              />
            </div>
          ),
        },
        {
          key: 'purchase_date',
          classes: 'px-0',
          component: (
            <div
              className={`text-truncated-flex align-items-center gap-2 ${
                index !== 0 ? 'text-yellow' : 'text-danger'
              }`}
            >
              <TextOverflowTooltip
                maxLength={25}
                textStyle="text-truncate font-size-sm2 mb-0 z-index-100"
                text={item.purchase_date}
              />
            </div>
          ),
        },
        {
          key: 'last_updated',
          classes: 'px-0',
          component: (
            <div className="text-truncate d-flex align-items-center gap-2">
              <TextOverflowTooltip
                maxLength={25}
                textStyle="text-truncate font-size-sm2 mb-0 z-index-100"
                text={item.last_updated}
              />
            </div>
          ),
        },
      ],
    };
  });

  const rows = rowData?.map((item) => {
    return {
      ...item,
      dataRow: [
        {
          key: 'name',
          classes: 'px-0',
          component: (
            <div className="text-black text-truncate fw-bold d-flex align-items-center gap-2">
              <TextOverflowTooltip
                maxLength={25}
                textStyle="fw-bold text-truncate font-size-sm2 mb-0 z-index-100"
                text={item.name}
              />
            </div>
          ),
        },
        {
          key: 'leads',
          label: '',
          component: (
            <>
              <span>{item?.leads || 0}</span>
            </>
          ),
        },
        {
          key: 'revenue',
          label: '',
          component: <FormattedValue value={item?.revenue} type="currency" />,
        },
        {
          key: 'mom_variance',
          label: '',
          component: (
            <>
              <TableRowHover zIndex="z-index-5" />
              <span>
                {item?.conversion_rate !== undefined &&
                item?.conversion_rate !== null
                  ? (() => {
                      return (
                        <StatusBadge
                          showDot={false}
                          skipMap={true}
                          item={{
                            status: item.status,
                            priority: item.status,
                            statusText: FormattedValue({
                              value: item.conversion_rate,
                              type: 'percentage',
                            }),
                          }}
                        />
                      );
                    })()
                  : '--'}
              </span>
            </>
          ),
        },
      ],
    };
  });

  const rows2 = rowData2?.map((item) => {
    return {
      ...item,
      dataRow: [
        {
          key: 'name',
          classes: 'pl-3',
          component: (
            <div className="text-black text-truncate fw-bold d-flex align-items-center gap-2">
              <TextOverflowTooltip
                maxLength={25}
                textStyle="fw-bold text-truncate font-size-sm2 mb-0 z-index-100"
                text={item.name}
              />
            </div>
          ),
        },
        {
          key: 'active_deals',
          label: '',
          component: <span>{item?.activeDeals || 0}</span>,
        },
        {
          key: 'pipeline_value',
          label: '',
          component: (
            <FormattedValue value={item?.pipelineValue} type="currency" />
          ),
        },
        {
          key: 'close_rate',
          label: '',
          component: (
            <>
              <TableRowHover zIndex="z-index-5" />

              <span>
                {item?.close_rate !== undefined && item?.close_rate !== null
                  ? (() => {
                      return (
                        <StatusBadge
                          showDot={false}
                          skipMap={true}
                          item={{
                            status: item.status,
                            priority: item.status,
                            statusText: FormattedValue({
                              value: item?.close_rate,
                              type: 'percentage',
                            }),
                          }}
                        />
                      );
                    })()
                  : '--'}
              </span>
            </>
          ),
        },
      ],
    };
  });

  return (
    <div>
      <PageTitle page="Pipelines" />

      <div className="d-grid grid-col-4 gap-3 mb-3">
        {Data?.map((item, index) => (
          <Card key={index} className="home-card h-100">
            <StatCardHeader item={item} />
            <Card.Body className="p-3 flex-grow-1">
              <div className="d-flex flex-column gap-1 ml-2">
                <h2 className="fs-18 text-truncate font-weight-semi-bold mb-0 text-black">
                  <FormattedValue value={item?.value} type={item.type} />
                </h2>
                <p className="mb-0 fs-7 text-muted">{item.label}</p>
              </div>
            </Card.Body>
          </Card>
        ))}
      </div>

      <div className="d-grid grid-col-2 gap-3 mb-3">
        {Data2?.map((item, index) => (
          <Card key={index} className="home-card h-100">
            <StatCardHeader item={item} />
            <Card.Body className="p-3 flex-grow-1">
              <div className="mx-2 d-flex flex-column gap-3">
                <ProgressBar
                  variant={item?.variant}
                  now={item?.percentage}
                  style={{
                    height: 8,
                    borderRadius: 'var(--borderRadius)',
                  }}
                />
                <div className="d-flex align-items-center justify-content-between">
                  <p className="mb-0 fs-8 text-muted text-left">
                    Booked Revenue:{' '}
                    <FormattedValue value={item?.revenue} type="currency" />
                  </p>
                  <p className="mb-0 fs-8 text-muted text-right">
                    Annual Goal:{' '}
                    <FormattedValue value={item?.annualGoal} type="currency" />
                  </p>
                </div>
              </div>
            </Card.Body>
          </Card>
        ))}
      </div>

      <div className="mb-3 d-grid grid-col-1 gap-3">
        <StatCardList
          item={{
            icon: 'ChartLine',
            title: 'Top 5 Most Critical Suggested Pipeline Activities',
          }}
        >
          <div className="table-min-height">
            <div className="table-responsive-md datatable-custom">
              <div
                id="datatable_wrapper"
                className="dataTables_wrapper no-footer h-auto"
              >
                <Table
                  data={rows0}
                  columns={tableColumns0}
                  dataInDB={rowData0?.length}
                  showLoading={loading}
                  staticData={true}
                  emptyDataText="No leads by relationship manager"
                  headClass="bg-gray-table-head"
                />
              </div>
            </div>
          </div>
        </StatCardList>
      </div>

      <div className="d-grid grid-col-2 gap-3">
        <StatCardList
          item={{
            icon: 'Users',
            title: 'Pipeline by RM',
          }}
        >
          <div className="table-min-height">
            <div className="table-responsive-md datatable-custom">
              <div
                id="datatable_wrapper"
                className="dataTables_wrapper no-footer h-auto"
              >
                <Table
                  data={rows}
                  columns={tableColumns}
                  dataInDB={rowData?.length}
                  showLoading={loading}
                  staticData={true}
                  emptyDataText="No leads by relationship manager"
                  headClass="bg-gray-table-head"
                />
              </div>
            </div>
          </div>
        </StatCardList>

        <StatCardList
          item={{
            icon: 'Layers',
            title: 'Pipeline by Client Tier',
          }}
        >
          <div className="table-min-height">
            <div className="table-responsive-md datatable-custom">
              <div
                id="datatable_wrapper"
                className="dataTables_wrapper no-footer h-auto"
              >
                <Table
                  data={rows2}
                  columns={tableColumns2}
                  staticData={true}
                  dataInDB={rowData2?.length}
                  showLoading={loading}
                  emptyDataText="No leads by potential revenue"
                  headClass="bg-gray-table-head"
                />
              </div>
            </div>
          </div>
        </StatCardList>
      </div>
    </div>
  );
};

export default PipelinesHome;
