import React from 'react';
import { Col, Row } from 'reactstrap';
import {
  cleanAccountName,
  FilterNotPrimary,
  getOperatingAccountType,
} from '../../reports/account-structure/account.structure.constants';
import NoDataFound from '../../commons/NoDataFound';
import NoDataFoundTitle from '../../fields/NoDataFoundTitle';
import SectionWrapper from '../../reports/account-structure/SectionWrapper';
import TooltipComponent from '../../lesson/Tooltip';

const AccountType = ({ account }) => {
  const operatingAccountType = getOperatingAccountType(account);
  const title = operatingAccountType.name;
  const type = operatingAccountType.short;
  const color = operatingAccountType.color;
  return (
    <TooltipComponent title={title}>
      <span
        className={`py-1 px-2 rounded fs-11 font-weight-semi-bold ${color}`}
      >
        {type}
      </span>
    </TooltipComponent>
  );
};

const Data = [
  {
    id: '7c5c69d0-8e4c-4330-98dd-31c77054e137',
    status: 'complete',
    accountName: 'Operating Account',
    accountType: 'controlledDisbursement',
    accountNumber: '1123',
    fraudServices: 'Positive Pay, Fraud Filter, Payee Validation',
    productServices:
      'Account Reconcilement Plan, Account Analysis, Automated Clearing House, Business Deposit Capture, Business Sweep, Cash Letter, Cash Vault, Checking, Comerica Purchasing Card, Comerica TM Connect Desktop, Comerica TM Connect File Transfer, Commercial Card, Consolidated Accounting Services, Controlled Collateral Accounts, Controlled Disbursement, Correspondent Banking Services, Courier Service, Deposit Reconcilement, Electronic Bill Payment, Electronic Data Interchange, Healthcare Receivable Automation, Image Services, Information Reporting and Payments, Integrated Cash Position Manager, Integrated Payables Services, Integrated Receivables, International, Loan Management, Lockbox, Miscellaneous, Payable through Drafts, Positive Pay, Real Time Payments, Returned Items, Target Balance Account, Tax Payment Services, Political Action Committee Services, Treasury File Reporting, Treasury Information Reporting, Treasury Payments, Treasury Packages, Wire Transfers, Flat Fee, Intellect, Mid Cycle Payments, Inactive Services, Intrafi Cash Transfer',
    operatingAccountType: {
      id: 1,
      key: 'Primary',
      name: 'Operating Account',
      color: 'bg-blue text-white',
      short: 'P',
      foreColor: 'text-white',
    },
  },
  {
    id: '08103606-adea-42fd-861f-c7c1b52f3472',
    status: 'complete',
    accountName: 'Sub Account Emily',
    accountType: 'controlledDisbursement',
    accountNumber: '6787',
    fraudServices: 'Positive Pay, Fraud Filter, Payee Validation',
    productServices: 'Automated Clearing House, Business Deposit Capture',
    operatingAccountType: {
      id: 2,
      key: 'SubAccount',
      name: 'Sub-Account',
      color: 'bg-purple text-white',
      short: 'S',
      foreColor: 'text-white',
    },
  },
  {
    id: 'c8aa85cb-10bf-432f-8302-fad01008347e',
    status: 'complete',
    accountName: 'Sub Account Mark',
    accountType: 'commercialMoneyMarket',
    accountNumber: '2222',
    fraudServices: 'Positive Pay, Fraud Filter, Payee Validation',
    productServices:
      'Account Reconcilement Plan, Account Analysis, Automated Clearing House, Business Deposit Capture, Business Sweep, Cash Letter, Cash Vault, Checking, Commercial Card, Consolidated Accounting Services, Controlled Collateral Accounts, Controlled Disbursement, Correspondent Banking Services, Courier Service, Deposit Reconcilement, Electronic Bill Payment, Electronic Data Interchange, Healthcare Receivable Automation, Image Services, Information Reporting and Payments, Integrated Cash Position Manager, Integrated Payables Services, Integrated Receivables, International, Loan Management, Lockbox, Miscellaneous, Payable through Drafts, Real Time Payments, Returned Items, Target Balance Account, Tax Payment Services, Political Action Committee Services, Treasury File Reporting, Treasury Information Reporting, Treasury Payments, Treasury Packages, Wire Transfers, Flat Fee, Intellect, Mid Cycle Payments, Inactive Services, Intrafi Cash Transfer',
    operatingAccountType: {
      id: 2,
      key: 'SubAccount',
      name: 'Sub-Account',
      color: 'bg-purple text-white',
      short: 'S',
      foreColor: 'text-white',
    },
  },
  {
    id: 'b8bce562-2c15-4c81-bf2b-3b2c2001c8b7',
    status: 'complete',
    accountName: "Ruben's Account",
    accountType: 'Select Account type',
    accountNumber: '1234',
    fraudServices: '',
    productServices: '',
    operatingAccountType: {
      id: 2,
      key: 'SubAccount',
      name: 'Sub-Account',
      color: 'bg-purple text-white',
      short: 'S',
      foreColor: 'text-white',
    },
  },
  {
    id: '9cdb8b1a-71ac-4a18-baef-c3531004fdb6',
    status: 'complete',
    accountName: "Jake's Account",
    accountType: 'interBankAccounts',
    accountNumber: '3234',
    fraudServices: '',
    productServices:
      'Account Reconcilement Plan, Automated Clearing House, Business Deposit Capture, Business Sweep, Cash Vault, Checking, Consolidated Accounting Services, Controlled Collateral Accounts, Controlled Disbursement, Correspondent Banking Services, Commercial Card, Electronic Bill Payment, Electronic Data Interchange, Healthcare Receivable Automation, Deposit Reconcilement, Account Analysis, Loan Management, Miscellaneous, International, Payable through Drafts, Lockbox, Returned Items, Target Balance Account, Tax Payment Services, Real Time Payments, Treasury File Reporting, Treasury Information Reporting',
    operatingAccountType: {
      id: 2,
      key: 'SubAccount',
      name: 'Sub-Account',
      color: 'bg-purple text-white',
      short: 'S',
      foreColor: 'text-white',
    },
  },
];

const AccountHierarchy = () => {
  return (
    <div className="card mt-3 report-widget">
      <div className="card-body p-3">
        <p className="fs-7">
          Total number of accounts:{' '}
          <b>{Data.filter(FilterNotPrimary).length}</b>
        </p>
        <SectionWrapper columns={['Names', 'Last 4 Digits']}>
          {Data.length ? (
            <>
              {Data.map((acnt) => (
                <Row
                  key={acnt.id}
                  onClick={() => {}}
                  className="fs-7 bg-hover-gray-dark mx-0 py-2 px-0 cursor-pointer border-bottom border-white"
                >
                  <Col md={6} className="pl-2 align-self-center">
                    <span>
                      {cleanAccountName(acnt)} <AccountType account={acnt} />
                    </span>
                  </Col>
                  <Col md={6}>
                    <span>{acnt.accountNumber}</span>
                  </Col>
                </Row>
              ))}
            </>
          ) : (
            <NoDataFound
              icon="people"
              iconRounded={true}
              iconStyle="font-size-2em"
              containerStyle="text-gray-search mt-2 pt-2 mb-1"
              title={<NoDataFoundTitle clazz="fs-7 mb-0" str={`No accounts`} />}
            />
          )}
        </SectionWrapper>
      </div>
    </div>
  );
};

export default AccountHierarchy;
