import { isLucidIcon } from './IconDropdownSearch';
import MaterialIcon from './MaterialIcon';
import React from 'react';

const TopicIcon = ({
  icon,
  iconBg = 'bg-primary-soft',
  iconStyle,
  iconClasses,
  filled = false,
  rounded = true,
  iconSize = 'fs-5',
}) => {
  // TODO: for time being light bg and dark color is the theme so hardcoding it here, will refactor later
  return (
    <span
      className={`d-flex justify-content-center align-items-center rounded-circle ${iconBg}`}
      style={iconStyle}
    >
      <MaterialIcon
        icon={icon}
        filled={filled}
        rounded={rounded}
        size={iconSize}
        isLucide={isLucidIcon(icon)}
        clazz={`${iconClasses} text-primary`}
      />
    </span>
  );
};

export default TopicIcon;
