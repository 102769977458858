import React from 'react';
import { Image } from 'react-bootstrap';
import LinkWrapper from '../../modal/LinkWrapper';

const FeedLink = ({ isOwner, data }) => {
  return (
    <div className="card">
      <div className="card-body">
        <div className={`d-flex`}>
          <div className="text-left flex-grow-1">
            <p className="prospect-typography-h4 p-0 mb-1 text-wrap font-weight-semi-bold">
              <LinkWrapper href={data.url} className="text-dark">
                {data.title}
              </LinkWrapper>
            </p>
          </div>
          <div className="ml-3">
            <LinkWrapper href={data.url}>
              <Image
                className="cursor-pointer"
                rounded="true"
                src={`${data.image}`}
              />
            </LinkWrapper>
          </div>
        </div>
      </div>
    </div>
  );
};

FeedLink.defaultProps = {
  data: {},
};

export default FeedLink;
