import authHeader from './auth-header';
import BaseRequestService from './baseRequest.service';

class SelfAssessmentService extends BaseRequestService {
  getAssessments() {
    return this.get('/selfAssessments', {
      headers: authHeader(),
      params: {
        page: 1,
        limit: 10,
      },
    });
  }

  getSubmittedAssessmentById(assessmentId) {
    return this.get(`/selfAssessmentSubmissions/${assessmentId}`, {
      headers: authHeader(),
    });
  }

  createDefaultQuestions(data) {
    return this.post(`/selfAssessments`, data, {
      headers: authHeader(),
    });
  }

  getAssessmentQuestions(assessmentId) {
    return this.get(`/selfAssessments/${assessmentId}/questions`, {
      headers: authHeader(),
    });
  }

  submitAssessment(data, assessmentId) {
    return this.post(`/selfAssessments/${assessmentId}/submit`, data, {
      headers: authHeader(),
    });
  }

  // TODO move this into backend, security flaw
  createTinyUrl(url) {
    return this.post(
      `https://api.tinyurl.com/create`,
      {
        url,
      },
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization:
            'Bearer Atj873TfvVqsaGek5Dg30iivr1pJhVx2P0YP8cwTX175qUC3rlaNmYf7jOYK',
        },
      }
    );
  }
}

export default new SelfAssessmentService();
