import React, { useState } from 'react';
import Alert from '../Alert/Alert';
import AlertWrapper from '../Alert/AlertWrapper';
import HelpCenterModal from './HelpCenterModal';
import MaterialIcon from '../commons/MaterialIcon';

export default function HelpCenter() {
  const [modal, setModal] = useState(false);
  const [sentMessage, setSentMessage] = useState('');

  const toggle = () => setModal(!modal);

  return (
    <>
      <section
        className="ml-auto mr-1 cursor-pointer btn btn-icon btn-icon-sm icon-hover-bg"
        onClick={toggle}
      >
        <MaterialIcon icon="help" size="fs-3" clazz="cursor-pointer" />
      </section>
      <HelpCenterModal
        modal={modal}
        toggle={toggle}
        setSentMessage={setSentMessage}
      />
      <AlertWrapper>
        <Alert
          color="success"
          message={sentMessage}
          setMessage={setSentMessage}
        />
      </AlertWrapper>
    </>
  );
}
