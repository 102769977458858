import React, { useState, useEffect } from 'react';
import './siteSettings.css';
import Loading from '../Loading';
import Alert from '../Alert/Alert';
import FormColor from './FormColor';
import AlertWrapper from '../Alert/AlertWrapper';
import tenantService from '../../services/tenant.service';
import { createBlobObject } from '../../utils/Utils';
import {
  ACCESS_URL_LABEL,
  BRANDING_LABEL,
  CHOOSE_IMAGE_FILE,
  SAVE,
} from '../../utils/constants';
import ButtonIcon from '../commons/ButtonIcon';
import IdfTooltip from '../idfComponents/idfTooltip';
import { Card, FormCheck } from 'react-bootstrap';
import userService from '../../services/user.service';
import DragDropUploadFile from '../commons/DragDropUploadFile';
import { useTenantContext } from '../../contexts/TenantContext';
import MaterialIcon from '../commons/MaterialIcon';
import { AvatarLogo } from '../commons/AvatarLogo';
import { CardBody } from 'reactstrap';
import TenantNameEdit from './TenantNameEdit';
import LinkWrapper from '../modal/LinkWrapper';

const SiteSettingsForm = () => {
  const { tenant, setTenant } = useTenantContext();
  const [saving, setSaving] = useState(false);
  const [tenantInfo, setTenantInfo] = useState(undefined);
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');

  const [logo, setLogo] = useState();
  const [icon, setIcon] = useState();

  const getLogo = async (id) => {
    const response = await userService.getFile(id);
    return response?.data;
  };

  const getTenant = async () => {
    try {
      const tenant = await tenantService.getTenant();
      setTenantInfo(tenant);

      if (tenant.logo) {
        const partnerLogo = await getLogo(tenant.logo);

        if (partnerLogo) {
          setLogo({
            ...partnerLogo,
            name: partnerLogo?.filename_download,
            size: partnerLogo?.filesize,
          });
        }
      }

      if (tenant.icon) {
        const partnerIcon = await getLogo(tenant.icon);

        if (partnerIcon) {
          setIcon({
            ...partnerIcon,
            name: partnerIcon.filename_download,
            size: partnerIcon.filesize,
          });
        }
      }
    } catch (error) {
      setErrorMessage(error.message);
    }
  };

  const onChangeColor = (value) => {
    setTenantInfo({
      ...tenantInfo,
      colors: {
        ...tenantInfo?.colors,
        ...value,
        name: 'custom',
        primaryColor: value.secondaryColor,
      },
    });
  };

  const validateInfo = () => {
    const domainValidation = /^[a-zA-Z]+[a-zA-Z-_\d]*$/;
    const { domain } = tenantInfo;
    if (domain.includes('localhost')) {
      return true;
    }
    const subdomain = domain.split('.')[0];

    if (subdomain && !domainValidation.test(subdomain)) {
      setErrorMessage('Default Domain is not valid');
      return false;
    }

    return true;
  };

  const onUploadLogo = async (file) => {
    const form = new FormData();
    form.append('file', await createBlobObject(file), file.name);
    form.append('isPublic', true);
    const {
      data: {
        data: { id },
      },
    } = await userService.uploadAvatar(form);
    return id;
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    try {
      if (validateInfo()) {
        setSaving(true);

        let logoId = logo?.id || '';
        // lastModified indicates that file is dropped for upload, so upload it first
        // if there is already logo in category then avoid calling upload
        if (logo && logo?.lastModified) {
          // if there is a logo, then first upload it
          logoId = await onUploadLogo(logo);
        }

        let iconId = icon?.id || '';
        // lastModified indicates that file is dropped for upload, so upload it first
        // if there is already logo in category then avoid calling upload
        if (icon && icon?.lastModified) {
          // if there is a logo, then first upload it
          iconId = await onUploadLogo(icon);
        }

        await tenantService.updateTenant(
          {
            ...tenantInfo,
            logo: logoId,
            icon: iconId,
          },
          tenant.id
        );
        setSaving(false);
        setSuccessMessage('Branding Saved');
        window.location.reload();
      }
    } catch (error) {
      setErrorMessage(error.message);
    }
  };

  useEffect(() => {
    getTenant();
  }, []);

  const handleUseLogo = () => {
    setTenantInfo({
      ...tenantInfo,
      use_logo: !tenantInfo.use_logo,
    });
  };

  const onLoadLogo = async (event) => {
    const target = event.target.files[0];
    setTenantInfo({ ...tenantInfo, logo: '' });
    setLogo(target);
  };

  const onLoadIcon = async (event) => {
    const target = event.target.files[0];
    setTenantInfo({ ...tenantInfo, icon: '' });
    setIcon(target);
  };

  const handleCopyToClipboard = () => {
    navigator.clipboard.writeText(formattedUrl).then(
      () => {
        console.log('Copied to clipboard!');
      },
      (err) => {
        console.error('Failed to copy Url', err);
      }
    );
  };

  const updateTenant = (tenant) => {
    setTenant(tenant); // update tenant context
    setTenantInfo(tenant);
  };

  const formattedUrl = tenantInfo?.domainUrl?.startsWith('https://')
    ? tenantInfo?.domainUrl
    : `https://${tenantInfo?.domainUrl}`;

  return (
    <>
      <AlertWrapper>
        <Alert
          message={errorMessage}
          setMessage={setErrorMessage}
          color="danger"
        />
        <Alert
          message={successMessage}
          setMessage={setSuccessMessage}
          color="success"
        />
      </AlertWrapper>

      {tenantInfo === undefined ? (
        <Loading bars={true} />
      ) : (
        <div className="card shadow-none border-0 rounded-0">
          <div className="card-header w-100 py-3 border-0">
            <div className="d-flex gap-3 flex-fill w-100 align-items-center">
              <div className="d-flex align-items-center px-1 avatar avatar-lg_2 avatar-circle bg-primary-soft">
                <AvatarLogo
                  name={tenantInfo?.name}
                  avatarId={logo?.id || icon?.id}
                  imageStyle=""
                  minWidth=""
                  width=""
                  height=""
                  icon="corporate_fare"
                  iconBg="w-100"
                  iconSize="font-size-3xl"
                />
              </div>
              <div className="flex-fill">
                <TenantNameEdit
                  tenantInfo={tenantInfo}
                  updateTenant={updateTenant}
                  setSuccessMessage={setSuccessMessage}
                  setErrorMessage={setErrorMessage}
                />
              </div>
            </div>
          </div>
          <div className="card-body p-3 border-top d-flex flex-column gap-0 bg-white">
            <Card className="border-0 shadow-0">
              <CardBody className="p-3">
                <div>
                  <h4 className="card-title">{ACCESS_URL_LABEL}</h4>
                  <div className="d-flex align-items-center mt-2">
                    <div className="col-4 col-lg-3">
                      <h5 className="mb-0 text-muted font-weight-normal">
                        URL
                      </h5>
                    </div>
                    <div className="col-8 col-lg-3">
                      <div className="d-flex align-items-center gap-1">
                        <p className="mb-0 d-flex align-items-center gap-2 fs-14">
                          <LinkWrapper href={formattedUrl}>
                            {formattedUrl}
                          </LinkWrapper>
                          <IdfTooltip text="Copy to clipboard">
                            <a
                              onClick={(e) => {
                                e.preventDefault();
                                handleCopyToClipboard();
                              }}
                              className="cursor-help btn btn-icon btn-icon-sm icon-hover-bg"
                            >
                              <MaterialIcon icon="content_copy" rounded />
                            </a>
                          </IdfTooltip>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </CardBody>
            </Card>
            <Card className="border-0 shadow-0">
              <CardBody className="d-flex border-top p-3 flex-column gap-3">
                <h4 className="card-title">{BRANDING_LABEL}</h4>
                <div>
                  <div className="d-flex align-items-center">
                    <div className="col-4 col-lg-3">
                      <div className="d-inline-flex align-items-center">
                        <label
                          className={`mr-1 font-size-sm font-weight-medium mb-0`}
                        >
                          <h5 className="mb-0 text-muted font-weight-normal">
                            Icon
                          </h5>
                        </label>
                        <IdfTooltip
                          placement="bottom"
                          text="Minimum size 80 x 80. Accepted formats are JPG, JPEG, PNG, SVG"
                        >
                          <a className="cursor-help btn btn-icon btn-icon-sm icon-hover-bg">
                            <MaterialIcon icon="info" rounded />
                          </a>
                        </IdfTooltip>
                      </div>
                    </div>
                    <div className="col-8 col-lg-3">
                      <DragDropUploadFile
                        file={icon}
                        setFile={setIcon}
                        isIcon={true}
                        onLoadFile={onLoadIcon}
                        name="brandingIcon"
                        allowedFormat={['.png', '.jpeg', '.jpg']}
                        displayFormats={['.png', '.jpeg', '.jpg']}
                        preview
                        logoId={tenantInfo?.icon}
                        chooseFileText={CHOOSE_IMAGE_FILE}
                        containerHeight={60}
                        emptyContainerHeight={80}
                        showUploadIcon={false}
                      />
                    </div>
                  </div>
                  <div className="d-flex align-items-center mt-2">
                    <div className="col-4 col-lg-3">
                      <div className="d-inline-flex align-items-center">
                        <label
                          className={`mr-1 font-size-sm font-weight-medium mb-0`}
                        >
                          <h5 className="mb-0 text-muted font-weight-normal">
                            Logo
                          </h5>
                        </label>
                        <IdfTooltip
                          placement="bottom"
                          text="Minimum size 280 x 80. Accepted formats are JPG, JPEG, PNG, SVG"
                        >
                          <a className="cursor-help btn btn-icon btn-icon-sm icon-hover-bg">
                            <MaterialIcon icon="info" rounded />
                          </a>
                        </IdfTooltip>
                      </div>
                    </div>
                    <div className="col-8 col-lg-3">
                      <DragDropUploadFile
                        file={logo}
                        setFile={setLogo}
                        name="brandingLogo"
                        onLoadFile={onLoadLogo}
                        allowedFormat={['.png', '.jpeg', '.jpg']}
                        displayFormats={['.png', '.jpeg', '.jpg']}
                        preview
                        logoId={tenantInfo?.logo}
                        chooseFileText={CHOOSE_IMAGE_FILE}
                        containerHeight={85}
                        emptyContainerHeight={80}
                        showUploadIcon={false}
                      />
                    </div>
                  </div>
                </div>
                <div className="d-flex align-items-center">
                  <div className="col-4 col-lg-3">
                    <h5 className="mb-0 text-muted font-weight-normal">
                      Use Logo Instead of Icon
                    </h5>
                  </div>
                  <div className="col-8 col-lg-3">
                    <FormCheck
                      id="useLogoInsteadOfIcon"
                      type="switch"
                      custom={true}
                      name="useLogoInsteadOfIcon"
                      checked={tenantInfo?.use_logo}
                      onChange={handleUseLogo}
                    />
                  </div>
                </div>
                <div className="d-flex align-items-center">
                  <div className="col-4 col-lg-3">
                    <div className="d-inline-flex align-items-center">
                      <label
                        className={`mr-1 font-size-sm font-weight-medium mb-0`}
                      >
                        <h5 className="mb-0 text-muted font-weight-normal">
                          Accent Color
                        </h5>
                      </label>
                      <IdfTooltip
                        placement="bottom"
                        text="An accent color will be applied to all links, buttons."
                      >
                        <a className="cursor-help btn btn-icon btn-icon-sm icon-hover-bg">
                          <MaterialIcon icon="info" rounded />
                        </a>
                      </IdfTooltip>
                    </div>
                  </div>
                  <div className="col-8 col-lg-3">
                    <FormColor
                      name="secondaryColor"
                      value={tenantInfo?.colors?.secondaryColor}
                      onChange={onChangeColor}
                    />
                  </div>
                </div>
                <div className="d-flex justify-content-end">
                  <ButtonIcon
                    label={SAVE}
                    type="submit"
                    loading={saving}
                    color="primary"
                    onclick={onSubmit}
                    classnames="btn-sm"
                  />
                </div>
              </CardBody>
            </Card>
          </div>
        </div>
      )}
    </>
  );
};

export default SiteSettingsForm;
