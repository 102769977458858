import axios from 'axios';
import * as Sentry from '@sentry/react';
import { trace, context, propagation } from '@opentelemetry/api';
import { SemanticAttributes } from '@opentelemetry/semantic-conventions';
import { getTracer } from './opentelemetry';

const API_URL = process.env.REACT_APP_API_URL;

function cleanUrlPath(url) {
  let cleanPath = url.replace(
    /[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}/g,
    ':id'
  );
  cleanPath = cleanPath.replace(/(\/)\d+((\/)|$)/g, '$1:id$2');
  cleanPath = cleanPath.replace(/\?.*$/g, ''); // drop query

  return cleanPath;
}

const createRequestSpan = (url, method, data) => {
  const fullUrl = `${API_URL}/api${url}`;
  const tracer = getTracer();

  if (!tracer) {
    return;
  }
  if (url.includes('telemetry')) {
    return;
  }

  const cleanPath = cleanUrlPath(`/api${url}`);

  return tracer.startSpan(`API ${cleanPath}`, {
    attributes: {
      [SemanticAttributes.HTTP_METHOD]: method,
      [SemanticAttributes.HTTP_URL]: fullUrl,
    },
    root: true,
  });
};

const axiosInstance = axios.create({
  baseURL: `${API_URL}/api`,
});

// Request interceptor to add trace context to headers
axiosInstance.interceptors.request.use((config) => {
  const span = createRequestSpan(
    config.url,
    config.method.toUpperCase(),
    config.data
  );
  if (!span) {
    return config;
  }

  const ctx = trace.setSpan(context.active(), span);
  const carrier = {};
  propagation.inject(ctx, carrier);

  if (!config.metadata) {
    config.metadata = {};
  }
  config.metadata.span = span;
  config.metadata.carrier = carrier;

  config.headers = {
    ...config.headers,
    ...carrier,
  };

  return config;
});

axiosInstance.interceptors.response.use(
  (response) => {
    response.config.metadata?.span?.end();

    return response;
  },
  (error) => {
    const span = error.config?.metadata?.span;
    span?.end();

    if (
      error.response &&
      error.response.status &&
      error.response.status >= 500
    ) {
      const apiMethod = error.config?.method ?? 'unknown';
      const apiRoute = error.config?.url ?? 'unknown';
      const cleanPath = cleanUrlPath(apiRoute);

      Sentry.withScope((scope) => {
        scope.setFingerprint(['axios', apiMethod, cleanPath]);
        const traceparent = error.config?.metadata?.carrier?.traceparent;
        if (traceparent) {
          scope.setTag('traceparent', traceparent);
        }
        scope.setExtra('request_url', apiRoute);
        scope.setExtra('request_method', apiMethod);
        scope.setExtra('status_code', error.response?.status);
        Sentry.captureException(error);
      });
    }

    return Promise.reject(error);
  }
);

export default axiosInstance;
