import { Card, ProgressBar } from 'react-bootstrap';
import { FormattedValue } from '../../../utils/Utils';
import React from 'react';
import StatCardHeader from './StatCardHeader';

const ProgressCard = ({ item, potential }) => {
  return (
    <>
      {potential ? (
        <Card className="home-card h-100">
          <StatCardHeader item={item} />
          <Card.Body className="p-3 flex-grow-1">
            <div className="d-flex justify-content-between mx-3">
              <div className="d-flex flex-column align-items-center gap-1">
                <h2 className="fs-18 text-truncate font-weight-semi-bold mb-0 text-black">
                  {item.companies}
                </h2>
                <p className="mb-0 fs-7">Companies</p>
              </div>

              <div className="d-flex align-items-center flex-column gap-1">
                <h2 className="fs-18 text-truncate font-weight-semi-bold mb-0 text-black">
                  <FormattedValue
                    value={item?.potentialRevenue}
                    type="currency"
                  />
                </h2>
                <p className="mb-0 fs-7">Potential Revenue</p>
              </div>
            </div>
          </Card.Body>
        </Card>
      ) : (
        <Card className="home-card h-100">
          <StatCardHeader item={item} />
          <Card.Body className="p-3 flex-grow-1">
            <div className="d-flex justify-content-between mx-3">
              <div className="d-flex align-items-center flex-column gap-1">
                <h2 className="fs-18 text-truncate font-weight-semi-bold mb-0 text-black">
                  {item.totalCompanies}
                </h2>
                <p className="mb-0 fs-7">Total Companies</p>
              </div>

              <div className="d-flex align-items-center flex-column gap-1">
                <h2 className="fs-18 text-truncate font-weight-semi-bold mb-0 text-black">
                  {item.reviewsDue}
                </h2>
                <p className="mb-0 fs-7">Reviews Due</p>
              </div>
            </div>

            <div className="mt-3 mx-3 d-flex flex-column gap-2">
              <ProgressBar
                variant={item?.variant}
                now={item?.reviewsCompletion}
                style={{
                  height: 8,
                  borderRadius: 'var(--borderRadius)',
                }}
              />

              <div className="d-flex align-items-center justify-content-between">
                <p className="mb-0 fs-8 text-muted text-left">
                  Review Completion
                </p>
                <p className="mb-0 fs-8 text-muted text-right">
                  <FormattedValue
                    value={item?.reviewsCompletion}
                    type="percentage"
                  />{' '}
                  Complete
                </p>
              </div>
            </div>
          </Card.Body>
        </Card>
      )}
    </>
  );
};

export default ProgressCard;
