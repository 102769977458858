import { StatItemColors } from '../components/reports/reports.constants';

const AssignmentCompleted = {
  icon: 'Check',
  color: StatItemColors.financial,
  iconColor: 'text-rpt-green',
  text: 'Completed',
  tooltipText: 'Users who have completed the assignment',
};

const AssignmentInProgress = {
  icon: 'History',
  color: StatItemColors.achievement,
  iconColor: 'rpt-orange-box-heading',
  text: 'In Progress',
  tooltipText: 'Users who have completed some but not all of the assignment',
};

const AssignmentNotStarted = {
  icon: 'Hourglass',
  color: StatItemColors.performance,
  iconColor: 'text-rpt-gray',
  text: 'Not Started',
  tooltipText: 'Users who have not started the assignment',
};

const AssignmentOverDue = {
  icon: 'Calendar',
  color: StatItemColors.time,
  iconColor: 'rpt-red-box-heading',
  text: 'Overdue',
  tooltipText: 'Users who have not completed this assignment by the due date',
};

export const learnStyles = {
  AssignmentCompleted,
  AssignmentInProgress,
  AssignmentNotStarted,
  AssignmentOverDue,
  assignmentCompleted: AssignmentCompleted,
  assignmentInProgress: AssignmentInProgress,
  assignmentNotStarted: AssignmentNotStarted,
  assignmentOverDue: AssignmentOverDue,
  totalLessonCount: {
    icon: 'Layers',
    color: StatItemColors.danger,
    iconColor: 'text-rpt-gray',
    text: 'Lessons',
    tooltipText: 'total # of lessons included in the assignment',
  },
  peopleAssigned: {
    icon: 'Users',
    color: StatItemColors.volume,
    iconColor: 'text-rpt-blue',
    text: 'Users Assigned',
    tooltipText: '# of users required to complete the assignment',
  },
  totalAssignments: {
    icon: 'Handshake',
    color: StatItemColors.performance,
    iconColor: 'text-rpt-gray',
    text: 'Number of Assignments',
    tooltipText: 'Total Number of Assignments',
  },
  LessonsCompleted: {
    icon: 'Check',
    color: StatItemColors.financial,
    iconColor: 'text-rpt-green',
    text: 'Completed',
    tooltipText: 'Lessons completed including repeats',
  },
  LessonsUniqueCompleted: {
    icon: 'Check',
    color: StatItemColors.financial,
    iconColor: 'text-rpt-green',
    text: 'Unique Completed',
    tooltipText: 'Unique lessons completed',
  },
  LessonsAssigned: {
    icon: 'Info',
    color: StatItemColors.achievement,
    iconColor: 'rpt-orange-box-heading',
    text: 'Assigned Lessons',
    tooltipText:
      '# of lessons lessons completed that were not part of a required assignment',
  },
  LessonsTaken: {
    icon: 'Info',
    color: StatItemColors.volume,
    iconColor: 'text-rpt-blue',
    text: 'Self Study Lessons',
    tooltipText: '# of lessons completed as part of a required assignment',
  },
  Default: {
    icon: 'Info',
    color: StatItemColors.time,
    iconColor: 'text-rpt-gray',
    text: '',
    tooltipText: '',
  },
};

export const TrainingAssignmentStatisticsColumns = [
  {
    key: 'assignmentName',
    component: 'Assignment Name',
  },
  {
    key: 'lessonCount',
    component: 'Lessons',
    tooltipTitle: 'total # of lessons included in the assignment',
  },
  {
    key: 'dueDate',
    component: 'Due Date',
    tooltipTitle: 'Deadline to complete',
  },

  {
    key: 'peopleAssigned',
    component: 'Users Assigned',
    tooltipTitle: '# of users required to complete the assignment ',
  },
  {
    key: 'completed',
    component: 'Assignments - Completed',
    tooltipTitle: 'Users who have completed the assignment',
  },
  {
    key: 'inProgress',
    component: 'Assignments - In Progress',
    tooltipTitle: 'Users have completed some but not all of the assignment',
  },
  {
    key: 'notStarted',
    component: 'Assignments - Not Started',
    tooltipTitle: 'Users who have not started the assignment.',
  },
  {
    key: 'overdue',
    component: 'Assignments - Overdue',
    tooltipTitle:
      'Users who have not completed this assignment by the due date.',
  },
];

export const TrainingAssignmentReportsColumns = [
  {
    key: 'assignmentName',
    component: 'Assignment Name',
  },
  {
    key: 'lessonCount',
    component: 'Number of Lessons',
    tooltipTitle: 'total # of lessons included in the assignment',
  },
  {
    key: 'dueDate',
    component: 'Due Date',
    tooltipTitle: 'Deadline to complete',
  },
  {
    key: 'team',
    component: 'Team',
    tooltipTitle: 'Team(s) a user belongs to',
  },
  {
    key: 'user',
    component: 'User',
    tooltipTitle: 'User',
  },
  {
    key: 'notStarted',
    component: '% Not Started',
    tooltipTitle: 'Users who have not started the assignment.',
  },
  {
    key: 'inProgress',
    component: '% In Progress',
    tooltipTitle: 'Users have completed some but not all of the assignment',
  },
  {
    key: 'completed',
    component: '% Completed',
    tooltipTitle: 'Users who have completed the assignment',
  },
  {
    key: 'overdue',
    component: '% Overdue',
    tooltipTitle:
      'Users who have not completed this assignment by the due date.',
  },
];

export const LeaderBoardStatisticsColumns = [
  {
    key: 'rank',
    component: 'Rank',
    width: '5%',
  },
  {
    orderBy: '"userName"',
    key: 'users',
    component: 'User',
    width: '15.8%',
  },
  {
    orderBy: '"totalLessonsCompleted"',
    key: 'completed',
    component: 'Total Completed',
    tooltipTitle: '# of mandatory and self study lessons completed',
    width: '15.8%',
  },
  {
    orderBy: '"totalAssignedLessonsCompleted"',
    key: 'assigned',
    component: 'Completed Assigned',
    tooltipTitle: '# of assigned lessons completed',
    width: '15.8%',
  },
  {
    orderBy: '"totalSelfStudyLessonsCompleted"',
    key: 'selfDirected',
    component: 'Completed Self Study',
    tooltipTitle: '# of self study lessons completed',
    width: '15.8%',
  },
  {
    orderBy: '"userTeamName"',
    key: 'team',
    component: 'Team Name',
    width: '15.8%',
  },
];

export const LessonStatisticsColumns = [
  {
    key: 'rank',
    component: 'Rank',
    tooltipTitle: 'Sorted by number of times taken, from most to least',
    width: '5%',
  },
  {
    key: 'lesson',
    component: 'Lesson',
    width: '30%',
  },
  {
    key: 'sumOfDuration',
    component: 'Length',
    tooltipTitle: 'Lesson duration in minutes',
  },
  {
    key: 'totalAttemps',
    component: 'Completed',
    tooltipTitle: 'Total times the lesson has been completed including repeats',
  },
  {
    key: 'engagementRate',
    component: 'Engagement',
    tooltipTitle: 'Unique users who have completed the lesson',
  },
  {
    key: 'selfAssigned',
    component: 'Self Study',
    tooltipTitle:
      'Total times completed that were not part of a required assignment',
  },
  {
    key: 'assigned',
    component: 'Assigned',
    tooltipTitle: 'Total times completed as part of a required assignment',
  },
  {
    key: 'lastAttempted',
    component: 'Last Activity',
  },
];

export const TopLessonsColumns = [
  {
    orderBy: '"lessonTitle"',
    key: 'lessonTitle',
    component: 'Lesson Title',
    width: '30%',
  },
  {
    orderBy: '"lessonCategory"',
    key: 'lessonCategory',
    component: 'Lesson Category',
  },
  {
    orderBy: '"totalUsers"',
    key: 'totalUsers',
    component: 'Total Users',
    tooltipTitle: 'Users how have completed',
  },
  {
    orderBy: '"totalTimesCompleted"',
    key: 'totalTimesCompleted',
    component: 'Total Times Completed',
    tooltipTitle: 'Users can complete multiple times',
  },
];

export const LessonTranscriptColumns = [
  {
    orderBy: '"lessonName"',
    key: 'lessonName',
    component: 'Lesson Name',
    width: '30%',
  },
  {
    orderBy: '"userName"',
    key: 'userName',
    component: 'User',
  },
  {
    orderBy: '"teamName"',
    key: 'teamName',
    component: 'Team Name',
  },
  {
    orderBy: '"lessonType"',
    key: 'lessonType',
    component: 'Lesson Type',
  },
  {
    orderBy: '"dateCompleted"',
    key: 'dateCompleted',
    component: 'Due Date',
  },
  {
    orderBy: '"courseDateCompleted"',
    key: 'courseDateCompleted',
    component: 'Date Completed',
  },
];

export const LessonDetailsColumns = [
  {
    key: 'user',
    component: 'user',
    width: '5%',
  },
  {
    key: 'team',
    component: 'team',
    width: '30%',
  },
  {
    key: 'lesson',
    component: 'lesson',
    tooltipTitle: 'Lesson duration in minutes',
  },
  {
    key: 'mins',
    component: 'Length Mins',
    tooltipTitle: 'Length of the Lesson',
  },
  {
    key: 'type',
    component: 'Lesson Type',
    tooltipTitle: 'Type  of lesson - Assigned or Self Study',
  },
  {
    key: 'dueDate',
    component: 'Due Date',
    tooltipTitle: 'Date Assigned Lesson is due',
  },
  {
    key: 'completed',
    component: 'Completed',
    tooltipTitle: 'User completed the Lesson',
  },
  {
    key: 'overdue',
    component: 'Overdue',
    tooltipTitle: 'User did not complete the Lesson by the Assigned due date',
  },
  {
    key: 'inProgress',
    component: 'In Progress',
    tooltipTitle: 'User Started the Lesson',
  },
  {
    key: 'notStarted',
    component: 'Not Started',
    tooltipTitle: 'User Has not started the Lesson',
  },
  {
    key: 'timeTaken',
    component: 'Time Taken',
    tooltipTitle: '# of times the User completed the Lesson',
  },
];

export const LessonTranscriptProgress = [
  {
    key: 'Lesson.title',
    component: 'Lesson',
    width: '30%',
  },
  {
    key: 'AssignmentUser.assignmentId',
    component: 'Assigned',
    tooltipTitle: 'Lessons were part of a required assignment ',
  },
  {
    key: 'Assignment.dueAt',
    component: 'Due Date',
    tooltipTitle: 'Deadline to complete',
  },
  {
    key: 'LessonProgress.avgLessonsCompleted',
    component: 'Completed',
  },
  {
    key: 'LessonProgress.maxOfLastAttempt',
    component: 'Last Activity',
  },
  {
    key: 'LessonProgress.count',
    component: 'Times Taken',
  },
];

export const TrainingAssignmentDetailsColumns = [
  {
    key: 'users',
    component: 'Users',
    width: '25%',
  },
  {
    key: 'progress',
    component: 'Progress',
    tooltipTitle: '% of assignment complete ',
    width: '25%',
  },
  {
    key: 'team',
    component: 'Team',
    tooltipTitle: 'Team(s) a user belongs to',
    width: '25%',
  },
  {
    key: 'lastActivity',
    component: 'Last Activity',
    tooltipTitle: 'Users most recent activity on the assignment',
    width: '25%',
  },
];
