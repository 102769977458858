import MaterialIcon from './MaterialIcon';
import TooltipComponent from '../lesson/Tooltip';

const ProfileIcon = ({ prospect, defaultSize }) => {
  const innerSize = defaultSize === 'xl' ? 'lg' : 'sm';
  return (
    <TooltipComponent capitalizeText={false} title={prospect.full_name}>
      <div className={`avatar avatar-${defaultSize} avatar-circle bg-gray-300`}>
        <p className={`p-1 avatar-initials text-center avatar-icon-font-size`}>
          <span
            style={{
              background: 'var(--cbBorderColor)',
              color: 'var(--black)',
            }}
            className={`avatar-${innerSize} rounded-circle d-flex align-items-center justify-content-center text-black-50 avatar-circle`}
          >
            <MaterialIcon
              icon={prospect?.employees ? 'domain' : 'person'}
              filled={false}
              style={{ verticalAlign: '-0.1em', fontSize: '1.5rem' }}
              size="fs-4"
            />
          </span>
        </p>
      </div>
    </TooltipComponent>
  );
};

export default ProfileIcon;
