import axios from './axiosInstance';
import authHeader from './auth-header';

class AssignmentService {
  async createAssignment(data) {
    return axios
      .post(`/assignments`, data, {
        headers: authHeader(),
      })
      .then((response) => response.data);
  }

  async getAssignments(params = {}) {
    const response = await axios.get(`/assignments`, {
      headers: authHeader(),
      params,
    });

    return response.data;
  }

  async getUserAssignments(assignmentId, params = {}) {
    const response = await axios.get(`/assignments/${assignmentId}/users`, {
      headers: authHeader(),
      params,
    });

    return response.data;
  }

  async createAssignmentContent(assignmentId, data) {
    const response = await axios.put(
      `/assignments/${assignmentId}/contents`,
      data,
      {
        headers: authHeader(),
      }
    );
    return response.data;
  }

  async getAssignmentById(assignmentId, params = {}) {
    const response = await axios.get(`/assignments/${assignmentId}`, {
      headers: authHeader(),
      params,
    });

    return response.data;
  }

  async updateAssignmentById(assignmentId, data) {
    const response = await axios.put(`/assignments/${assignmentId}`, data, {
      headers: authHeader(),
    });
    return response.data;
  }

  deleteAssignment(assignmentId) {
    return axios
      .delete(`/assignments/${assignmentId}`, {
        headers: authHeader(),
      })
      .then((response) => response.data)
      .catch((e) => console.log(e));
  }

  async upsertAssignedEntries(assignmentId, data) {
    const response = await axios.put(
      `/assignments/${assignmentId}/assign`,
      data,
      {
        headers: authHeader(),
      }
    );
    return response.data;
  }

  async getTeamAssignment(assignmentId, params = {}) {
    const response = await axios.get(`/assignments/${assignmentId}/teams`, {
      headers: authHeader(),
      params,
    });

    return response.data;
  }
}

export default new AssignmentService();
