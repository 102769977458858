import authHeader from './auth-header';
import BaseRequestService from './baseRequest.service';
import { getIdfToken } from '../utils/Utils';

class NotificationService extends BaseRequestService {
  getSettings() {
    return this.get(`/notifications/settings`, {
      headers: authHeader(),
    });
  }

  addSettings(settings) {
    return this.post(
      `/notifications/settings`,
      { settings },
      {
        headers: authHeader(),
      }
    );
  }

  getNotifications(page = 1, limit = 10) {
    const user = JSON.parse(getIdfToken());
    if (user) {
      return this.get(`/auth/context/notifications`, {
        headers: authHeader(),
        params: { page, limit },
      });
    } else {
      return '{}';
    }
  }
}

export default new NotificationService();
