import { useEffect, useState } from 'react';
import GenericTable from '../../../../../components/GenericTable';
import { setDateFormat } from '../../../../../utils/Utils';
import { cubeService } from '../../../../../services';
import TableSkeleton from '../../../../../components/commons/TableSkeleton';
import { ShortDescription } from '../../../../../components/ShortDescription';
import NoDataFound from '../../../../../components/commons/NoDataFound';
import { LessonTranscriptProgress } from '../../../../../utils/learn.constants';

const UserTranscriptLessonProgress = ({
  userFilters = [],
  paginationPage,
  setPagination,
}) => {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  const filters = userFilters.filter((item) => item?.values);

  const getReports = async () => {
    setLoading(true);
    const results = await cubeService.loadCustomAnalytics({
      type: 'UserTranscript',
      limit: paginationPage?.limit,
      page: paginationPage?.page,
      users: filters[0]?.values,
    });
    setData(results);
    setLoading(false);
    setPagination(paginationPage); // Todo:- will update with response pagination when API's will change
  };

  useEffect(() => {
    getReports();
  }, [paginationPage]);

  const rows = data?.map((result, index) => {
    return {
      id: index,
      dataRow: [
        {
          key: 'name',
          component: (
            <div className="d-flex font-weight-semi-bold text-wrap text-truncate align-items-center">
              {result?.lesson}
            </div>
          ),
        },

        {
          key: 'assigned',
          component: result?.dueAt ? 'Yes' : 'No',
        },
        {
          key: 'dueAt',
          component: (
            <span className="d-flex align-items-center">
              {result?.dueAt && result?.dueAt !== null ? (
                <ShortDescription content={result?.dueAt} limit="15" />
              ) : (
                ' -- '
              )}
            </span>
          ),
        },
        {
          key: 'completed',
          component: result?.completed > 0 ? 'Yes' : 'No',
        },
        {
          key: 'lastAttempted',
          component: setDateFormat(result?.last_attempted, 'MM/DD/YYYY'),
        },
        {
          key: 'timesTaken',
          component: (
            <span>
              <span>{result?.taken}</span>
            </span>
          ),
        },
      ],
    };
  });

  const loader = () => {
    if (loading) return <TableSkeleton cols={6} rows={10} />;
  };

  return (
    <div className="mt-3">
      {loading ? (
        loader()
      ) : (
        <div>
          {data?.length === 0 ? (
            <NoDataFound
              icon="manage_search"
              title="No data found"
              containerStyle="text-gray-search my-6 py-6"
              titleStyle="font-normal font-size-sm2 text-gray-search"
            />
          ) : (
            <GenericTable
              checkbox={false}
              data={rows}
              loading={loading}
              exportToCSV={false}
              columns={LessonTranscriptProgress}
              usePagination={false}
              noDataInDbValidation={true}
            />
          )}
        </div>
      )}
    </div>
  );
};

export default UserTranscriptLessonProgress;
