import { Card, CardBody, Col, Row } from 'reactstrap';
import React from 'react';
import WidgetSourceBlock from '../../WidgetSourceBlock';
import MaterialIcon from '../../../commons/MaterialIcon';
import { TreasuryReportSections } from '../../../reports/reports.constants';
import GreenRedCheckItem from '../../blocks/GreenRedCheckItem';
import CutoutWidget from './CutoutWidget';
import { isLucidIcon } from '../../../commons/IconDropdownSearch';

const Icons = [
  'sentiment_satisfied',
  'savings',
  'local_police',
  'crisis_alert',
  'mystery',
];
const WheelOfIcons = ({ whenPrinting }) => {
  const renderIcons = () => {
    const radius = whenPrinting ? 45 : 60; // half of the circle's width/height
    const angleIncrement = (2 * Math.PI) / Icons.length;
    let currentAngle = -Math.PI / 2; // start from the top

    return Icons.map((icon, index) => {
      const iconX = Math.cos(currentAngle) * radius;
      const iconY = Math.sin(currentAngle) * radius;

      const transformStyle = {
        transform: `translate(${iconX}px, ${iconY}px)`,
      };

      currentAngle += angleIncrement;
      return (
        <div
          key={index}
          className="wheel-icon bg-primary-soft rounded-circle p-2 d-flex align-items-center justify-content-center"
          style={transformStyle}
        >
          <MaterialIcon
            icon={icon}
            clazz="text-primary"
            size={whenPrinting ? 'font-26' : 'fs-2'}
            symbols
            isLucide={isLucidIcon(icon)}
            rounded={false}
          />
        </div>
      );
    });
  };
  return (
    <>
      <div
        className="rounded-circle border-2 position-relative report-widget-inner-section-soft"
        style={{
          height: whenPrinting ? 90 : 120,
          width: whenPrinting ? 90 : 120,
          marginLeft: 30,
        }}
      >
        &nbsp;
        {renderIcons()}
      </div>
    </>
  );
};

const HoursSpentWeeklyWidget = ({ widgetConfig, whenPrinting }) => {
  return (
    <Card className="report-widget">
      <CardBody className="justify-content-center d-flex">
        <div className="flex-fill">
          <h5 className="text-left mb-1 d-flex align-items-center gap-1">
            {widgetConfig.heading}
          </h5>
          <div className="px-2 text-left">
            <p className="font-size-sm2">{widgetConfig.description}</p>
            <CutoutWidget
              widgetConfig={widgetConfig}
              whenPrinting={whenPrinting}
              printingClasses={'pt-2 mt-4'}
              cutoutConfig={{
                icon: 'data_check',
                text: `Benefits <br /> Include`,
                bg: `report-widget-inner-section-soft bg-white ${
                  whenPrinting ? 'mb-1' : 'mb-2'
                }`,
                iconConfig: {
                  fg: 'text-primary',
                  bg: 'bg-primary-soft',
                  icon: 'data_check',
                },
              }}
            >
              <Row className="p-2 align-items-center flex-fill">
                <Col className="px-0 d-flex align-items-center justify-content-center">
                  <WheelOfIcons whenPrinting={whenPrinting} />
                </Col>
                <Col
                  md={1}
                  className="align-items-center px-0 d-flex justify-content-center"
                >
                  <MaterialIcon
                    icon="double_arrow"
                    size="font-size-2xl"
                    clazz="txt-rpt-heading"
                  />
                </Col>
                <Col className="d-flex align-items-center px-0 justify-content-center">
                  <div className="d-flex flex-column">
                    {[
                      'Better Visibility',
                      'Happier Customers',
                      'Cost Savings',
                      'Improved Accuracy',
                      'Decreased Fraud',
                    ].map((entry) => (
                      <GreenRedCheckItem
                        key={entry}
                        section={TreasuryReportSections.Fraud}
                        bordered=""
                        containerStyle={whenPrinting ? 'pb-1' : 'py-2'}
                        textStyle={whenPrinting ? 'fs-11' : 'fs-8'}
                        item={[
                          entry,
                          {
                            isChecked: true,
                            key: entry,
                            value: 1,
                            itemValue: 1,
                          },
                        ]}
                      />
                    ))}
                  </div>
                </Col>
              </Row>
            </CutoutWidget>
          </div>
          {widgetConfig?.source && (
            <div className="mt-2">
              <WidgetSourceBlock text={widgetConfig.source} />
            </div>
          )}
        </div>
      </CardBody>
    </Card>
  );
};

export default HoursSpentWeeklyWidget;
