import HomeLoadingSkeleton from './HomeLoadingSkelton';
import Header from './Header';
import React from 'react';
import { useProfileContext } from '../../../contexts/profileContext';
import { Card, CardBody } from 'reactstrap';
import EngagementReports from './EngagementReports';
import useIsTenant from '../../../hooks/useIsTenant';

const HomeLayout = ({ clazz = 'px-4', children }) => {
  const { profileInfo } = useProfileContext();
  const { isDemo } = useIsTenant();
  return (
    <>
      {!profileInfo?.id ? (
        <HomeLoadingSkeleton clazz={clazz} />
      ) : (
        <div className="splitted-content-fluid position-relative container-fluid content-with-insights overflow-hidden px-0 pt-0">
          <Header />
          <div
            className={`mx-auto ${clazz} my-3`}
            style={{ maxWidth: 'var(--home-page-width)' }}
          >
            {!isDemo ? (
              <Card className="border flex-fill rounded my-3 shadow-0">
                <CardBody className="bg-white rounded">
                  <EngagementReports />
                </CardBody>
              </Card>
            ) : null}
            {children}
          </div>
        </div>
      )}
    </>
  );
};

export default HomeLayout;
