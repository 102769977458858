import { useEffect, useState } from 'react';
import { cubeService } from '../../../../../services';
import NoDataFound from '../../../../../components/commons/NoDataFound';
import Loading from '../../../../../components/Loading';
import InsightStatItem from '../../../InsightStatItem';

const AssignmentStats = {
  LessonsCompleted: 'Lessons - Total Complete',
  LessonsUniqueCompleted: 'Lessons - Unique Complete',
  LessonsTaken: 'Lessons - Self Study',
  LessonsAssigned: 'Lessons - Assigned',
};

const TooltipTitlesGet = {
  'Lessons - Total Complete': 'Lessons completed including repeats',
  'Lessons - Unique Complete': 'Unique lessons completed',
  'Lessons - Assigned':
    '# of lessons lessons completed that were not part of a required assignment',
  'Lessons - Self Study':
    '# of lessons completed as part of a required assignment',
};

const UserTranscriptStats = ({ userFilters = [] }) => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const filterData = userFilters?.length > 0 && userFilters[0]?.values;
  const filters = userFilters.filter((item) => item?.values);

  useEffect(() => {
    (async () => {
      setLoading(true);
      const results = await cubeService.loadCustomAnalytics({
        type: 'UserTranscriptTickers',
        users: filters[0]?.values,
      });
      setData(results);
      setLoading(false);
    })();
  }, [filterData]);

  const updatedData =
    data?.length > 0 &&
    Object.entries(data[0])?.reduce((acc, [key, value]) => {
      if (key === 'lessonsCompleted') {
        acc.LessonsUniqueCompleted = value;
      } else if (key === 'taken') {
        acc.LessonsCompleted = value;
        acc.LessonsTaken = value;
      } else if (key === 'taken_assigned') {
        acc.LessonsAssigned = value;
      }
      return acc;
    }, {});

  const sortedUpdatedData =
    data?.length > 0 &&
    Object.keys(AssignmentStats)?.reduce((obj, key) => {
      obj[key] = updatedData[key] || '';
      return obj;
    }, {});

  return (
    <div className={`d-grid gap-4 ${loading ? 'grid-col-1' : 'grid-col-4'}`}>
      {loading ? (
        <Loading />
      ) : data?.length > 0 ? (
        Object.entries(sortedUpdatedData).map(([key, value]) => {
          return (
            <>
              <InsightStatItem
                key={key}
                value={key}
                hideAverage
                heading={AssignmentStats[key]}
                tooltipData={TooltipTitlesGet}
                stat={
                  key === 'taken'
                    ? value - sortedUpdatedData?.LessonsAssigned
                    : value
                }
              />
            </>
          );
        })
      ) : (
        <NoDataFound title="Data no found" />
      )}
    </div>
  );
};

export default UserTranscriptStats;
