import React, { useState, useEffect, useRef } from 'react';
import TooltipComponent from '../lesson/Tooltip';

const TextOverflowTooltip = ({
  text,
  textStyle = 'font-weight-medium text-truncate fs-7_1 mb-0',
  maxLength = 22,
  capitalizeText,
  tooltip = '',
  lineClamp,
}) => {
  const [isOverflowing, setIsOverflowing] = useState(false);
  const textRef = useRef(null);

  useEffect(() => {
    if (lineClamp) {
      // Check overflow based on line clamp if lineClamp is provided
      if (textRef.current) {
        setIsOverflowing(
          textRef.current.scrollHeight > textRef.current.clientHeight
        );
      }
    } else {
      // Use maxLength to determine overflow when lineClamp is not provided
      setIsOverflowing(text?.length > maxLength);
    }
  }, [text, lineClamp, maxLength]);

  const clampedClass =
    lineClamp === 1 ? 'text-clamp-single-line' : 'text-clamp';

  const truncatedText =
    !lineClamp && text?.length > maxLength
      ? text.slice(0, maxLength) + '...'
      : text;

  if (!lineClamp && text?.length <= maxLength) {
    // If lineClamp is not provided and text length is within maxLength, show full text without tooltip
    return <span className={textStyle}>{text}</span>;
  }

  return (
    <>
      <TooltipComponent
        title={isOverflowing ? (tooltip !== '' ? tooltip : text) : ''}
        disableHoverListener={!isOverflowing}
        capitalizeText={capitalizeText}
      >
        <span
          className={`${textStyle} ${lineClamp ? clampedClass : ''}`}
          style={lineClamp ? { WebkitLineClamp: lineClamp } : {}}
          ref={lineClamp ? textRef : null}
        >
          {truncatedText}
        </span>
      </TooltipComponent>
    </>
  );
};

export default TextOverflowTooltip;
