import React, { useCallback, useEffect, useMemo, useState } from 'react';
import {
  getRandomUUID,
  overflowing,
  removeBodyScroll,
} from '../../../../utils/Utils';
import SimpleModalCreation from '../../../modal/SimpleModalCreation';
import { FormGroup, Label } from 'reactstrap';
import { FormControl } from 'react-bootstrap';
import AddNewButton from '../AddNewButton';
import Alert from '../../../Alert/Alert';
import AlertWrapper from '../../../Alert/AlertWrapper';
import ValidationErrorText from '../../../commons/ValidationErrorText';
import MaterialIcon from '../../../commons/MaterialIcon';
import { usePagesContext } from '../../../../contexts/pagesContext';
import NoDataFound from '../../../commons/NoDataFound';
import NoDataFoundTitle from '../../../fields/NoDataFoundTitle';
import SearchOptionDropdown from '../../../commons/SearchOptionDropdown';

const AddNewAuthorizedSignerModal = ({
  show,
  setShow,
  authSigners,
  selectedAuthSigner,
  setAuthSigners,
}) => {
  const [, setLoader] = useState(false);
  const newInput = { id: getRandomUUID(), value: '' };
  const [errorFields, setErrorFields] = useState({
    accountNumber: {
      isShow: false,
      message: 'Please add last 4 digits of your account',
    },
  });
  const [inputs, setInputs] = useState([]);
  const [errorMessage, setErrorMessage] = useState('');
  const defaultAuthSignerObject = {
    id: getRandomUUID(),
    accountNumber: '',
    names: [],
    status: 'complete',
  };
  const [authSignerObject, setAuthSignerObject] = useState(
    defaultAuthSignerObject
  );
  const { pageContext } = usePagesContext();
  const accounts =
    pageContext?.AccountStructureReportModal?.ReportDetails?.accounts || [];
  useEffect(() => {
    if (show) {
      removeBodyScroll();
      if (selectedAuthSigner?.id) {
        setAuthSignerObject(selectedAuthSigner);
        setInputs(
          selectedAuthSigner.names?.length
            ? selectedAuthSigner.names
            : [newInput]
        );
      } else {
        setInputs([newInput]);
      }
    }
  }, [show]);

  const handleClose = () => {
    setShow(false);
    overflowing();
  };

  const handleInputChange = (e, index) => {
    setInputs(
      [...inputs].map((inp, ind) =>
        index === ind ? { ...inp, value: e.target.value } : { ...inp }
      )
    );
  };

  const handleDelete = (e, index) => {
    const newInputs = inputs.filter((_, i) => i !== index);
    setInputs(newInputs);
  };

  const handleSave = () => {
    try {
      if (errorFields.accountNumber.isShow) {
        return;
      }
      handleClose();
      const signerExists = authSigners.some(
        (signer) => signer.id === authSignerObject.id
      );
      let updatedAuthSigners = [];
      if (!signerExists) {
        updatedAuthSigners = [
          ...authSigners,
          { ...authSignerObject, names: inputs.filter((inp) => !!inp.value) },
        ];
      } else {
        updatedAuthSigners = authSigners.map((signer) =>
          signer.id === authSignerObject.id
            ? {
                ...authSignerObject,
                names: inputs.filter((inp) => !!inp.value),
              }
            : signer
        );
      }
      setAuthSigners(updatedAuthSigners);
    } catch (e) {
      console.log(e);
    } finally {
      setLoader(false);
    }
  };

  const isNameEntered = (names) => {
    return names.every((nme) => !!nme.value);
  };

  const [selectedData, setSelectedData] = useState([]);
  const handleOptionSelect = (event) => {
    const { value } = event.target;
    setSelectedData((prevState) => {
      const isSelected = prevState.includes(value);
      const updatedSelectedData = isSelected
        ? prevState.filter((item) => item !== value && !!item)
        : [...prevState, value];
      setAuthSignerObject({
        ...authSignerObject,
        accountNumber: updatedSelectedData.join(', '),
      });
      const name = 'accountNumber';
      const error = {};
      error[name] = {
        ...errorFields[name],
        isShow: !value,
      };
      setErrorFields(error);

      return updatedSelectedData;
    });
  };

  const handleAllSelect = (all, allOrNone) => {
    setAuthSignerObject((prevAuthSignerObject) => ({
      ...prevAuthSignerObject,
      accountNumber: all ? allOrNone.join(', ') : '',
    }));
  };
  const [showAccountsDropdown, setShowAccountsDropdown] = useState(false);
  const [options] = useState(
    [...accounts].map((act) => ({
      key: act.accountNumber,
      name: `${act.accountName} ${
        act.accountNumber ? `(${act.accountNumber})` : ''
      }`,
    }))
  );

  useEffect(() => {
    if (selectedAuthSigner?.accountNumber) {
      const splitted =
        selectedAuthSigner.accountNumber.length > 0
          ? selectedAuthSigner.accountNumber.split(', ')
          : [];
      if (splitted.length) {
        setSelectedData(splitted);
      }
    }
  }, [selectedAuthSigner]);
  return (
    <SimpleModalCreation
      modalTitle="Add Authorized Signer"
      modalHeaderClasses="align-items-start"
      open={show}
      bankTeam={false}
      saveButton="Save"
      isLoading={false}
      bodyClassName="p-3"
      buttonsDisabled={
        inputs.length === 0 ||
        !authSignerObject.accountNumber ||
        !isNameEntered(inputs)
      }
      handleSubmit={handleSave}
      onHandleCloseModal={() => {
        overflowing();
        setShow(!show);
      }}
    >
      <AlertWrapper>
        <Alert
          message={errorMessage}
          setMessage={setErrorMessage}
          color="danger"
        />
      </AlertWrapper>
      <form autoComplete="off">
        <FormGroup>
          <Label>Name</Label>
          {inputs.map((inp, index) => (
            <div key={inp.id} className="d-flex align-items-center">
              <FormControl
                as="input"
                autoFocus
                value={inp.value}
                placeholder="Enter name"
                className="mb-2 flex-fill border-left-4 border-left-danger"
                onChange={(e) => handleInputChange(e, index)}
              />
              {index > 0 && (
                <a
                  onClick={(e) => handleDelete(e, index)}
                  className="icon-hover-bg cursor-pointer ml-1"
                >
                  <MaterialIcon icon="delete" />{' '}
                </a>
              )}
            </div>
          ))}
        </FormGroup>
        <AddNewButton
          label="Add Signer"
          reverse={true}
          extra="btn-sm w-100 d-flex mb-3 justify-content-center align-items-center"
          onClick={() => {
            setInputs((prev) => [...prev, newInput]);
          }}
        />
        <FormGroup>
          <Label>Account Numbers</Label>
          {accounts?.length > 0 ? (
            <>
              <SearchOptionDropdown
                dontClose
                data={options}
                show={showAccountsDropdown}
                label="Account Numbers"
                scrollable="pr-3"
                setShow={setShowAccountsDropdown}
                handleOptionSelected={handleOptionSelect}
                handleAllSelect={handleAllSelect}
                selectedData={selectedData}
                borderClasses="border-left-4 border-left-danger"
                setSelectedData={setSelectedData}
              />
              {errorFields.accountNumber.isShow && (
                <ValidationErrorText text={errorFields.accountNumber.message} />
              )}
            </>
          ) : (
            <NoDataFound
              icon="people"
              iconRounded={true}
              iconStyle="font-size-2em"
              containerStyle="text-gray-search border-dashed-gray rounded py-2 mb-1"
              title={
                <NoDataFoundTitle
                  clazz="fs-7 mb-0"
                  str={`No account numbers available`}
                />
              }
              descriptionStyle="mb-0 fs-7 font-weight-medium text-gray-search"
              description="Please add Accounts first."
            />
          )}
        </FormGroup>
      </form>
    </SimpleModalCreation>
  );
};

const useAddNewAuthorizedSignerModal = (
  selectedAuthSigner,
  authSigners,
  setAuthSigners
) => {
  const [showModal, setShowModal] = useState(false);

  const AddNewAuthorizedSignerModalCallback = useCallback(() => {
    return (
      <AddNewAuthorizedSignerModal
        show={showModal}
        setShow={setShowModal}
        selectedAuthSigner={selectedAuthSigner}
        authSigners={authSigners}
        setAuthSigners={setAuthSigners}
      />
    );
  }, [
    showModal,
    setShowModal,
    selectedAuthSigner,
    authSigners,
    setAuthSigners,
  ]);

  return useMemo(
    () => ({
      setShowModal,
      AddNewAuthorizedSignerModal: AddNewAuthorizedSignerModalCallback,
    }),
    [setShowModal, AddNewAuthorizedSignerModalCallback]
  );
};

export default useAddNewAuthorizedSignerModal;
