import Skeleton from 'react-loading-skeleton';
import { Card, CardBody, Col, Row } from 'reactstrap';
import React, { useEffect, useState } from 'react';
import MaterialIcon from '../commons/MaterialIcon';
import { IconsMaterialToLucide } from '../commons/IconUtils';

const icons = Object.keys(IconsMaterialToLucide).slice(5, 15);

const loadingTexts = [
  'Convincing AI to do my job...',
  'Processing quantum bits...',
  'Bribing the database with cookies...',
  'Loading pixels one by one...',
  'Generating witty loading messages...',
  'Reticulating splines...',
  'Warming up the flux capacitor...',
  'Coffee break for the servers...',
  'Processing your file with a smile...',
  'Charging the cloud...',
  'Unpacking digital boxes...',
  'Training our AI hamsters...',
  'Downloading more RAM...',
  'Adding sparkles to the UI...',
  'Tuning the cloud orchestra...',
  'Spinning up the hamster wheels...',
];

const ReportFunnyLoader = () => {
  const [currentMessageIndex, setCurrentMessageIndex] = useState(0);
  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentMessageIndex((prev) => (prev + 1) % loadingTexts.length);
    }, 5000);
    return () => clearInterval(interval);
  }, []);

  const currentIcon = icons[currentMessageIndex % icons.length];
  return (
    <div
      style={{ width: 300 }}
      className="position-absolute p-3 d-flex align-items-center justify-content-center abs-center-xy flex-column gap-2 bg-primary-soft border shadow-lg rounded"
    >
      <div className="d-flex align-items-center py-1 gap-1 justify-content-center">
        <div
          style={{ height: 10, width: 10 }}
          className="rounded-circle animate-pulse bg-primary"
        />
        <h6 className="mb-0 text-uppercase text-primary">
          Your request is in progress
        </h6>
      </div>
      <hr className="w-100 py-0 my-0" />
      <div className="d-flex flex-column mt-1 align-items-center justify-content-center gap-2">
        <MaterialIcon
          clazz="animate-spin-slow text-primary"
          size="fs-4"
          icon={currentIcon}
        />
        {loadingTexts[currentMessageIndex]}
      </div>
    </div>
  );
};
const ReportBlocksSkeleton = () => {
  return (
    <div className="p-3 position-relative">
      <div className="text-left animate-pulse">
        <Skeleton height={12} width={300} className="mb-1" />
        <Card className="mb-3">
          <CardBody>
            <div className="text-center">
              <div>
                <Skeleton width={300} height={10} />
              </div>
              <p>
                <Skeleton width={100} height={10} />
              </p>
              <Skeleton width="100%" height={10} count={3} />
            </div>
          </CardBody>
        </Card>
      </div>
      <div className="text-left animate-pulse">
        <Skeleton height={12} width={300} className="mb-1" />
        <Card>
          <CardBody>
            <Row className={`align-items-center position-relative py-2 mb-2`}>
              <Col className="justify-content-center">
                <Skeleton width="100%" height={10} count={3} />
              </Col>
              <Col md={3} className="position-relative">
                <div className="d-flex flex-column align-items-center justify-content-center">
                  <p className="mb-0" style={{ height: 100, width: 100 }}>
                    <Skeleton circle width={90} height={90} />{' '}
                  </p>
                </div>
              </Col>
            </Row>
          </CardBody>
        </Card>
      </div>
      <ReportFunnyLoader />
    </div>
  );
};

export default ReportBlocksSkeleton;
