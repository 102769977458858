import Table from '../../GenericTable';
import StatCardList from '../shared/StatCardList';

const TableWithPagination = ({
  title,
  icon,
  data,
  paginatedData,
  columns,
  pagination,
  emptyDataText,
}) => (
  <div className="mb-3">
    <StatCardList
      item={{
        icon,
        title,
      }}
    >
      <div className="d-flex flex-column">
        <div className="table-min-height">
          <div className="table-responsive-md datatable-custom">
            <div
              id="datatable_wrapper"
              className="dataTables_wrapper no-footer h-auto"
            >
              <Table
                paginationInfo={pagination.paginationInfo(data.length)}
                paginationLoading={pagination.loading}
                data={paginatedData}
                columns={columns}
                dataInDB={data.length}
                showLoading={false}
                emptyDataText={emptyDataText}
                usePagination={false}
                headClass="bg-gray-table-head"
                showPerPage={false}
                onPageChange={pagination.handlePageChange}
                onLimitChange={pagination.handleLimitChange}
              />
            </div>
          </div>
        </div>
      </div>
    </StatCardList>
  </div>
);

export default TableWithPagination;
