import React, { useState } from 'react';
import { MetricCard } from '../../../components/chat/MetricCard';
import ButtonFilterDropdown from '../../../components/commons/ButtonFilterDropdown';
import ButtonIcon from '../../../components/commons/ButtonIcon';

const DataContainer = ({ tenantsData, queriesData }) => {
  return (
    <div className="data-container gap-3">
      <div className="data-card border rounded">
        <div className="card-header">
          <h2 className="font-weight-semi-bold fs-6 mb-0 card-title d-flex align-items-center gap-1">
            Top Tenants by Usage
          </h2>
        </div>
        <div className="py-2 h-100 overflow-y-auto">
          <ul className="ranked-list mb-0">
            {tenantsData.map((tenant, index) => (
              <li key={index}>
                <span className="rank">{tenant.rank}</span>
                <span className="list-item">{tenant.name}</span>
              </li>
            ))}
          </ul>
        </div>
      </div>

      <div className="data-card border rounded">
        <div className="card-header">
          <h2 className="font-weight-semi-bold fs-6 mb-0 card-title d-flex align-items-center gap-1">
            Top Queries
          </h2>
        </div>
        <div className="py-2 h-100 overflow-y-auto">
          <ul className="ranked-list">
            {queriesData.map((query, index) => (
              <li key={index}>
                <span className="rank">{query.rank}</span>
                <span className="list-item">{query.text}</span>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
};

const Overview = () => {
  const metricData = [
    {
      iconName: 'Building',
      title: 'Companies (Tenants)',
      value: '128',
      class: 'companies',
    },
    {
      iconName: 'Users',
      title: 'Users',
      value: '3,433',
      class: 'users',
    },
    {
      iconName: 'MessageCircleQuestion',
      title: 'Avg. Questions per Day',
      value: '333',
      class: 'questions',
    },
    {
      iconName: 'ThumbsUp',
      title: 'Positive Feedback',
      value: '93%',
      class: 'feedback',
    },
  ];
  // Time range filter options
  const timeRangeOptions = [
    { key: '7days', name: 'Last 7 Days', icon: 'calendar_month' },
    { key: '30days', name: 'Last 30 Days', icon: 'calendar_month' },
    { key: '90days', name: 'Last 90 Days', icon: 'calendar_month' },
    { key: 'custom', name: 'Custom Range', icon: 'calendar_month' },
  ];

  // Content filter options
  const contentFilterOptions = [
    { key: 'all', name: 'All Content', icon: 'filter_alt' },
    { key: 'questions', name: 'Nacha Rulebook', icon: 'menu_book' },
    { key: 'documents', name: 'Visa', icon: 'credit_card' },
    { key: 'users', name: 'Users', icon: 'people' },
  ];

  // Static data for DataContainer component
  const tenantsData = [
    { rank: 1, name: 'Tenant Alpha Inc.' },
    { rank: 2, name: 'Beta Solutions Ltd.' },
    { rank: 3, name: 'Gamma Corp' },
    { rank: 4, name: 'Delta Enterprises' },
    { rank: 5, name: 'Epsilon Group' },
    { rank: 6, name: 'Zeta Global' },
    { rank: 7, name: 'Eta Innovations' },
    { rank: 8, name: 'Theta Systems' },
    { rank: 9, name: 'Iota Partners' },
    { rank: 10, name: 'Kappa Industries' },
  ];

  const queriesData = [
    { rank: 1, text: 'How to integrate with the API?' },
    { rank: 2, text: 'What are the pricing options?' },
    { rank: 3, text: 'How to set up user permissions?' },
    { rank: 4, text: 'What file formats are supported?' },
    { rank: 5, text: 'How to upgrade my subscription?' },
    { rank: 6, text: 'How to troubleshoot connection issues?' },
    { rank: 7, text: 'What are the system requirements?' },
    { rank: 8, text: 'How to customize the interface?' },
    { rank: 9, text: 'What security features are available?' },
    { rank: 10, text: 'How to export my data?' },
  ];

  const [selectedTimeRange, setSelectedTimeRange] = useState(
    timeRangeOptions[0]
  );
  const [selectedContentFilter, setSelectedContentFilter] = useState(
    contentFilterOptions[0]
  );

  const handleTimeRangeSelect = (e, option) => {
    e.preventDefault();
    setSelectedTimeRange(option);
  };

  const handleContentFilterSelect = (e, option) => {
    e.preventDefault();
    setSelectedContentFilter(option);
  };
  return (
    <div className="p-3">
      <header className="d-flex mb-3 align-items-center justify-content-between">
        <h1>Ida Overview Dashboard</h1>
        <div className="d-flex align-items-center gap-2">
          <ButtonFilterDropdown
            buttonText="Time Range"
            options={timeRangeOptions}
            handleFilterSelect={handleTimeRangeSelect}
            filterOptionSelected={selectedTimeRange}
            icon="calendar_month"
            btnToggleStyle="btn-sm"
          />
          <ButtonFilterDropdown
            buttonText="Content Filter"
            options={contentFilterOptions}
            handleFilterSelect={handleContentFilterSelect}
            filterOptionSelected={selectedContentFilter}
            icon="filter_alt"
            btnToggleStyle="btn-sm"
          />
          <ButtonIcon icon="download" classnames="btn-sm" label="Export" />
        </div>
      </header>
      <div className="d-flex align-items-center gap-3 mb-3">
        {metricData.map((metric, index) => (
          <MetricCard
            key={index}
            iconName={metric.iconName}
            title={metric.title}
            value={metric.value}
            classType={metric.class}
          />
        ))}
      </div>
      <DataContainer tenantsData={tenantsData} queriesData={queriesData} />
    </div>
  );
};

export default Overview;
