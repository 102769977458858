import { useEffect, useState } from 'react';
import teamsService from '../../../../services/teams.service';
import { AssignmentSnapshotWidget } from '../AssignmentSnapshotWidget';
import { NoUserSelected } from '../../components/queries/NoUserSelected';
import SingleSelectionDropdown from '../../../../components/commons/SingleSelectionDropdown';
import IdfTooltip from '../../../../components/idfComponents/idfTooltip';
import { Card, CardBody, CardHeader } from 'reactstrap';

const assignmentStatsTooltip = {
  Completed: 'Users who have completed the assignment',
  'In Progress': 'Users who have completed some but not all of the assignment',
  'Not Started': 'Users who have not started the assignment',
  Overdue: 'Users who have not completed this assignment by the due date.',
  'Users Assigned': '# of users required to complete the assignment',
  Lessons: 'total # of lessons included in the assignment',
  'Number of Assignments':
    'An assignment may consist of one lesson or a bundled set of lessons. Each assignment is created by an administrator and given a unique name and due date',
};

export const TrainingAssignmentTeamSnapshot = ({
  insightName,
  assignmentStatsNames = {},
}) => {
  const [queryFilter, setQueryFilter] = useState([]);
  const [filter, setFilter] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [show, setShow] = useState(false);
  const [teams, setTeams] = useState([]);

  const getTeam = async () => {
    const { data } = await teamsService.getTeams({
      page: 1,
      limit: 1000,
      filter: {
        search: searchQuery,
      },
    });
    setTeams(data);
  };

  useEffect(() => {
    getTeam();
  }, [searchQuery]);

  const handleOptionSelect = (item) => {
    if (Object.keys(item)?.length === 0) {
      setFilter([]);
    } else {
      setFilter([item?.id]);
    }
  };

  return (
    <>
      <Card className="h-100 border-0 shadow-0">
        <CardHeader>
          <div className="d-flex justify-content-between align-items-center w-100">
            <IdfTooltip text={insightName} placement="bottom">
              <h4 className="card-title text-hover-primary mb-0 text-truncate">
                {insightName}
              </h4>
            </IdfTooltip>

            <div className="d-flex align-items-center gap-2">
              {insightName === 'Assignments Organization Snapshot' ||
              insightName === '' ? (
                ''
              ) : (
                <SingleSelectionDropdown
                  data={teams}
                  show={show}
                  label={`Team`}
                  customStyle={{ width: '250px' }}
                  scrollable="pr-3 text-left"
                  setShow={setShow}
                  showCheckAll={false}
                  search={true}
                  maxLength={22}
                  customKey={['name', 'id']}
                  handleOptionSelected={handleOptionSelect}
                  selectedData={queryFilter}
                  setSearchQuery={setSearchQuery}
                  searchQuery={searchQuery}
                  setSelectedData={setQueryFilter}
                />
              )}
            </div>
          </div>
        </CardHeader>

        <CardBody className="p-0 flex-1 overflow-y-auto">
          {filter?.length > 0 ? (
            <AssignmentSnapshotWidget
              queryFilter={filter}
              assignmentStatsNames={assignmentStatsNames}
              allSelectedData={teams}
              assignmentStatsTooltip={assignmentStatsTooltip}
            />
          ) : (
            <NoUserSelected label="team" />
          )}
        </CardBody>
      </Card>
    </>
  );
};
