import React from 'react';
import LucideIcon from '../commons/LucideIcon';

const suggestionsList = [
  {
    icon: 'Banknote',
    text: 'Financial',
    color: '#3b82f6',
    category: 'Financial',
    suggestions: [
      'Financial tips for managing cash flow effectively',
      'Financial tips for best practices in budgeting',
      'Financial advice on investing in stocks vs bonds',
      'Financial advice on how interest rates affect your finances',
      `Financial strategies for creating a good savings plan in ${new Date().getFullYear()}`,
    ],
  },

  {
    icon: 'FilePenLine',
    text: 'Write',
    color: '#a855f7',
    category: 'Write',
    suggestions: [
      'Write tips for writing engaging blog posts',
      'Write tips for improving your writing skills',
      'Write advice on whether to self-publish or go with a publisher',
      'Write tips for writing a compelling cover letter',
      'Write recommendations for the best tools for creative writing',
    ],
  },

  {
    icon: 'ChartColumn',
    text: 'Reports',
    color: '#22c55e',
    category: 'Reports',
    suggestions: [
      'Reports tips on how to analyze market trends effectively',
      'Reports tips on best practices for data visualization',
      'Reports advice on what makes a good business report',
      'Reports advice on how to interpret financial data',
      `Reports recommendations for the top reporting tools in ${new Date().getFullYear()}`,
    ],
  },

  {
    icon: 'RefreshCcw',
    text: 'Process',
    color: '#f97316',
    category: 'Process',
    suggestions: [
      'Process tips for improving workflow efficiency',
      'Process strategies for optimizing your project management',
      'Process advice on the Lean methodology',
      'Process recommendations for creating standard operating procedures',
      'Process tips on how to automate routine tasks effectively',
    ],
  },

  {
    icon: 'Target',
    text: 'Planning',
    color: '#14b8a6',
    category: 'Planning',
    suggestions: [
      'Planning tips for a successful product launch',
      `Planning tips for effective goal setting in ${new Date().getFullYear()}`,
      'Planning advice on how to use a Gantt chart',
      'Planning strategies for creating a solid business plan',
      'Planning tips for achieving personal financial goals',
    ],
  },
];

const Suggestions = ({ onClick }) => {
  return (
    <div className="mt-4 d-flex align-items-center justify-content-center mx-auto flex-wrap gap-2">
      {suggestionsList?.map((suggestion, index) => (
        <div
          className="py-2 px-3 border ida-suggestions bg-gray-table-head transition cursor-pointer d-flex align-items-center gap-1 rounded-pill font-size-sm2"
          key={index}
          onClick={() => onClick(suggestion)}
        >
          <LucideIcon icon={suggestion.icon} size={18} clazz="text-gray" />
          <span className="text-black font-weight-medium">
            {suggestion.text}
          </span>
        </div>
      ))}
    </div>
  );
};

export default Suggestions;
