import React, { useState } from 'react';
import DeleteConfirmationModal from './DeleteConfirmationModal';

function LinkWrapper({ href, className, children }) {
  const [showModal, setShowModal] = useState(false);

  const handleClick = (e) => {
    if (href.includes('identifee.com') || href.includes('iclientportal.com')) {
      return;
    }
    e.preventDefault();
    e.stopPropagation();
    setShowModal(true);
  };

  const handleContinue = () => {
    window.open(href, '_blank', 'noopener,noreferrer');
    setShowModal(false);
  };

  const Header = () => {
    return (
      <h2 className="mb-2_1 fs-19">
        You are leaving{' '}
        <span className="text-capitalize">{window.location.host}</span>
      </h2>
    );
  };
  return (
    <>
      <a
        href={href}
        target="_blank"
        rel="noopener, noreferrer"
        onClick={handleClick}
        className={className}
      >
        {children}
      </a>
      <DeleteConfirmationModal
        showModal={showModal}
        setShowModal={setShowModal}
        setSelectedCategories={[]}
        event={handleContinue}
        itemsConfirmation={[]}
        positiveBtnText="Continue"
        setErrorMessage={() => {
          setShowModal(false);
        }}
        positiveBtnColor="primary"
        heading={<Header />}
        description="You are about to leave this secure website. We are not responsible for the privacy, security or content of external websites."
      />
    </>
  );
}

export default LinkWrapper;
