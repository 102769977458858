import React, { useEffect, useLayoutEffect, useState } from 'react';
import { Card, CardBody } from 'reactstrap';
import TopicIcon from '../commons/TopicIcon';
import ChecklistItems from './ChecklistItems';
import { ProgressBar } from 'react-bootstrap';
import { getClientPortalToken } from '../../layouts/constants';
import checklistService from '../../services/checklist.service';
import AlertWrapper from '../Alert/AlertWrapper';
import Alert from '../Alert/Alert';
import StatusReview from '../commons/StatusReview';
import moment from 'moment';
import { DATE_FORMAT } from '../../utils/Utils';
import { ChecklistFieldsTabs } from '../../utils/checklist.constants';
import MaterialIcon from '../commons/MaterialIcon';
import { FormatTextForDisplay } from '../commons/FormattedTextarea';

const containerWidth = 825;

const ProgressLabel = () => {
  return (
    <span
      style={{ top: -23 }}
      className="font-size-sm position-absolute left-0 font-weight-medium"
    >
      Checklist Progress:
    </span>
  );
};
const SuccessBar = ({ progress }) => {
  return (
    <>
      <div
        className="flex-fill position-relative"
        style={{ borderRadius: 'var(--borderRadius)' }}
      >
        <ProgressLabel />
        <ProgressBar
          variant={'success'}
          isChild={true}
          style={{
            height: 10,
            borderRadius: 'var(--borderRadius)',
          }}
          className="flex-fill bar-green"
          now={100}
        />
      </div>
      <span
        className="font-weight-medium rounded text-primary position-relative"
        style={{ top: 2 }}
      >
        <MaterialIcon
          icon="check_circle"
          clazz="text-success fs-5"
          rounded
          filled
        />
      </span>
    </>
  );
};

const InProgressBar = ({ progress }) => {
  return (
    <>
      <div
        className="flex-fill position-relative"
        style={{ borderRadius: 'var(--borderRadius)' }}
      >
        <ProgressLabel />
        <ProgressBar
          variant={'success'}
          style={{ height: 10, borderRadius: 'var(--borderRadius)' }}
          className="flex-fill"
          now={progress}
        />
      </div>
      <span className="font-weight-medium rounded text-black">{progress}%</span>
    </>
  );
};

const Stepper = ({ checklist, checklistProgress, checklistItems }) => {
  const items = checklistItems.filter(
    (chk) => chk.type === ChecklistFieldsTabs.Client
  );
  const progress = Math.floor(checklistProgress.clientProgress);
  return (
    <>
      <div
        className="position-absolute"
        style={{ right: 16, top: items?.length > 1 ? -83 : -50 }}
      >
        <StatusReview
          show={checklistProgress.clientProgress < 100 ? '' : 'hide'}
          fontSize="font-size-sm2"
          text={`Due: ${moment(
            checklist?.initialDueDate || checklistProgress?.dueDate
          ).format(DATE_FORMAT)}`}
        />
      </div>
      {items?.length > 1 ? (
        <div
          className="container my-5 m-auto"
          style={{ maxWidth: containerWidth }}
        >
          <div className="row justify-content-center">
            <div className="col-md-8">
              <div className="d-flex align-items-center align-items-center gap-1">
                {progress >= 100 ? (
                  <SuccessBar progress={progress} />
                ) : (
                  <InProgressBar progress={progress} />
                )}
              </div>
            </div>
          </div>
        </div>
      ) : null}
    </>
  );
};

const ChecklistDue = ({
  checklist,
  orgChecklistId,
  clientPortalToken,
  setRefreshMenuItem,
}) => {
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [getChecklistProgress, setChecklistProgress] = useState({});
  const [getChecklistItems, setGetChecklistItems] = useState([]);
  const [refresh, setRefresh] = useState(0);

  const getOrgCheckListProgress = async () => {
    const { data } = await checklistService.getOrgCheckListProgress({
      limit: 100,
      page: 1,
      organizationChecklistId: orgChecklistId,
    });
    const getOrgProgress = data?.data?.find((item) => {
      return orgChecklistId === item?.organizationChecklistId;
    });
    const items = await checklistService.getCheckListItemsById(
      checklist?.checklistId,
      {
        limit: 100,
        page: 1,
      }
    );
    setGetChecklistItems(items?.data?.data);
    setChecklistProgress(getOrgProgress);
    setRefreshMenuItem((prevState) => prevState + 1);
  };

  useLayoutEffect(() => {
    if (orgChecklistId || refresh > 0) {
      getOrgCheckListProgress();
    }
  }, [orgChecklistId, refresh]);
  return (
    <>
      <AlertWrapper>
        <Alert
          message={errorMessage}
          setMessage={setErrorMessage}
          color="danger"
        />
        <Alert
          message={successMessage}
          setMessage={setSuccessMessage}
          color="success"
        />
      </AlertWrapper>

      <div
        style={{ maxWidth: containerWidth }}
        className="m-auto px-3 position-relative"
      >
        <Stepper
          checklist={checklist}
          checklistProgress={getChecklistProgress}
          checklistItems={getChecklistItems}
        />
        <Card className="mb-3 text-left required-action">
          <CardBody>
            <div className="d-flex align-items-center gap-1">
              <TopicIcon
                icon="message"
                iconBg="bg-primary-soft"
                iconStyle={{ width: 42, height: 42 }}
                rounded={true}
                filled={false}
                iconClasses="font-size-em text-primary"
                iconSize="fs-3"
              />
              <h5 className="mb-0 font-weight-bold">Important Message</h5>
            </div>
            <div
              style={{ paddingLeft: 46, paddingRight: 25 }}
              className="pt-3 d-flex align-items-center justify-content-center text"
            >
              <p className="font-size-sm">
                <FormatTextForDisplay text={checklist?.message} />
              </p>
            </div>
          </CardBody>
        </Card>
        <Card className="mb-3 text-left required-action">
          <CardBody>
            <div className="d-flex align-items-center justify-content-between gap-1">
              <div className="d-flex align-items-center gap-1">
                <h5 className="mb-0 font-weight-bold">Required Items</h5>
              </div>
            </div>
            <div className="pt-3 text">
              <ChecklistItems
                clientPortal={true}
                setErrorMessage={setErrorMessage}
                clientPortalToken={clientPortalToken}
                getChecklistProgress={getChecklistProgress}
                checklist={checklist}
                setRefresh={setRefresh}
                hideInternal={checklist?.type === ChecklistFieldsTabs.Client}
              />
            </div>
          </CardBody>
        </Card>
      </div>
    </>
  );
};

// will use this to load dynamic checklists and do all the logic in this hook, right now its just static
const useChecklist = (selectedMenu) => {
  const clientPortalToken = getClientPortalToken();
  const [checkLists, setCheckLists] = useState([]);
  const [loading, setLoading] = useState(false);
  const [refreshMenuItem, setRefreshMenuItem] = useState(0);

  const getChecklistByContactId = async () => {
    try {
      setLoading(true);
      // first get contact assigned checklist
      const contactChecklistResponse =
        await checklistService.getChecklistByContact(
          clientPortalToken?.contact_id,
          {
            page: 1,
            limit: 100,
          }
        );

      const { data: contactCheckList } = contactChecklistResponse;
      const { data: dataContactChecklist } = contactCheckList;

      // Check if data exists
      if (dataContactChecklist?.length) {
        const checklistDataPromises = dataContactChecklist.map((item) =>
          checklistService.getOrgCheckListsById(item?.organizationChecklistId)
        );

        // Use Promise.allSettled to handle both fulfilled and rejected promises
        const checklistOrgDataResponse = await Promise.allSettled(
          checklistDataPromises
        );

        // Filter out fulfilled promises and handle rejected ones
        const fulfilledPromises = checklistOrgDataResponse.filter(
          (result) => result.status === 'fulfilled'
        );
        const rejectedPromises = checklistOrgDataResponse.filter(
          (result) => result.status === 'rejected'
        );
        // Process fulfilled promises
        const checklistDataRequests = fulfilledPromises.map((result) =>
          checklistService.getCheckListsById(result.value.data.checklistId)
        );
        const checklistDataResponses = await Promise.all(checklistDataRequests);

        // Handle rejected promises
        rejectedPromises.forEach((result) => {
          console.error('Error fetching checklist data:', result.reason);
          // Optionally, you can handle these errors or log them as needed
        });

        // Continue processing data as before...
        const checklistProgressReq = fulfilledPromises.map((result) =>
          checklistService.getOrgCheckListProgress({
            limit: 1,
            page: 1,
            organizationChecklistId: result.value.data.organizationChecklistId,
          })
        );

        const checklistProgressResp = await Promise.all(checklistProgressReq);
        const checklistData = checklistDataResponses.map((item, index) => {
          return {
            ...item.data,
            organizationChecklistContactId:
              dataContactChecklist[index].organizationChecklistContactId,
            organizationChecklistId:
              dataContactChecklist[index].organizationChecklistId,
            organizationId: dataContactChecklist[index]?.organizationId,
            progress: checklistProgressResp[index]?.data?.data[0],
            initialDueDate:
              fulfilledPromises[index]?.value?.data?.initialDueDate,
          };
        });
        const contactChecklistData = checklistData.map((item, index) => {
          return {
            ...item,
            component: (
              <ChecklistDue
                checklist={item}
                orgChecklistId={item.organizationChecklistId}
                clientPortalToken={clientPortalToken}
                setRefreshMenuItem={setRefreshMenuItem}
              />
            ),
            name: item?.title,
            type: 'Checklist',
            key: item?.checklistId,
            progress: item?.progress,
            dueDate: checklistProgressResp[index].data?.data[0].dueDate,
          };
        });
        setCheckLists(contactChecklistData);
        setLoading(false);
      } else {
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      console.error('Error fetching checklist data:', error);
    }
  };

  const getProgress = async () => {
    const { data } = await checklistService.getOrgCheckListProgress({
      limit: 1,
      page: 1,
      organizationChecklistId: selectedMenu?.organizationChecklistId,
    });
    const newChecklist = [...checkLists].map((chk) => ({
      ...chk,
      progress:
        chk.organizationChecklistId === selectedMenu?.organizationChecklistId
          ? data.data[0]
          : chk.progress,
    }));
    setCheckLists(newChecklist);
  };
  useEffect(() => {
    if (refreshMenuItem > 0 && selectedMenu?.key) {
      // we want to update left checklist item exact progress to change label to green if completed
      getProgress();
    }
  }, [refreshMenuItem]);
  useLayoutEffect(() => {
    if (clientPortalToken?.contact_id) {
      getChecklistByContactId();
    }
  }, []);
  return { Checklist: checkLists, loading };
};

export default useChecklist;
