/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import { paginationDefault } from '../../utils/constants';
import Alert from '../../components/Alert/Alert';
import AlertWrapper from '../../components/Alert/AlertWrapper';
import stringConstants from '../../utils/stringConstants.json';
import PremiumContentTable from './PremiumContentTable';
import staticIdaData from './staticIdaContent.json';
import useHash from '../../hooks/useHash';
import LucideIcon from '../../components/commons/LucideIcon';
import IdfTooltip from '../../components/idfComponents/idfTooltip';
import { numberWithCommas, setDateFormat } from '../../utils/Utils';
import TooltipComponent from '../../components/lesson/Tooltip';
import { getIconByMimeType } from '../../components/chat/constants';
import TextOverflowTooltip from '../../components/commons/TextOverflowTooltip';
import useCreatePremiumContentModal from './premium/PremiumContentModal';
import useFetchArchives from '../../components/chat/hooks/useFetchArchives';
import useKnowledgeContentsModal from '../../components/chat/modals/KnowledgeContents';
import useFetchArchiveFiles from '../../components/chat/hooks/useFetchArchiveFiles';

const constants = stringConstants.tenants;

const ContentView = {
  Table: 'table',
  Detail: 'details',
};

const InstructionsAndContent = ({ content = {}, openEditModal }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const { files, loading, pagination, fetchArchiveFiles } =
    useFetchArchiveFiles(content?.archiveId, 5);
  const {
    showModal,
    KnowledgeContentsModal,
    setShowModal: setShowContentsModal,
    setKnowledge: setKnowledgeContentModal,
  } = useKnowledgeContentsModal(() => {
    fetchArchiveFiles();
  });
  return (
    <>
      {showModal && <KnowledgeContentsModal />}
      <div className="bg-gray-table-head position-sticky top-0 border rounded d-flex flex-column">
        <div className={`p-3 flex-column border-bottom`}>
          <div className="d-flex align-items-center justify-content-between">
            <div className="d-flex gap-2 align-items-center">
              <LucideIcon size={22} icon="AlignLeft" />
              <h4 className="mb-0">Description</h4>
            </div>
            <a
              href=""
              onClick={(e) => {
                e.preventDefault();
                openEditModal();
              }}
              className="text-muted-100 opacity-0 font-weight-semi-bold icon-hover-bg-soft-primary rounded fs-7"
            >
              Edit
            </a>
          </div>
          <p className="font-size-sm2 pt-2 px-1 mb-0">
            {content.description || '--'}
          </p>
        </div>
        <div className={`p-3 flex-column border-bottom`}>
          <div className="d-flex align-items-center justify-content-between">
            <div className="d-flex gap-2 align-items-center">
              <LucideIcon size={22} icon="Info" />
              <h4 className="mb-0">Disclaimer</h4>
            </div>
          </div>
          <p className="font-size-sm2 pt-2 px-1 mb-0">
            {content.disclaimer ||
            content?.title?.toLowerCase().includes('nacha')
              ? 'Permission to use Nacha Operating Rules & Guidelines granted by the National Automated Clearing House Association (Nacha). Copyright © Nacha. All Rights Reserved. Nacha has not reviewed and is not responsible for the content on or generated by this website or service. IDA users should own the most current version of the official Nacha Operating Rules & Guidelines. To purchase the Nacha Operating Rules & Guidelines, go to http://Nacha.org  or contact your Payments Association.'
              : '--'}
          </p>
        </div>
        <div className="d-flex flex-column">
          <div className="d-flex align-items-center justify-content-between">
            <div className="d-flex gap-2 p-3 align-items-center">
              <LucideIcon size={20} icon="Folder" />
              <h4 className="mb-0">Sources</h4>
            </div>
          </div>
          <div className="font-size-sm2 pb-2 mb-0">
            <div
              onClick={() => setIsExpanded(!isExpanded)}
              className="px-3 pt-3 d-flex align-items-center cursor-pointer justify-content-between"
            >
              <div className="d-flex px-1 fs-7_1 align-items-center gap-1">
                <LucideIcon
                  icon={isExpanded ? 'ChevronDown' : 'ChevronRight'}
                  size={20}
                />
                <span>Files</span>
              </div>
              <div className="d-flex align-items-center gap-2">
                <span>{numberWithCommas(pagination.count)}</span>
                <div className="text-muted">
                  <TooltipComponent capitalizeText={false} title="Add Files">
                    <a
                      href=""
                      onClick={(e) => {
                        e.preventDefault();
                        setKnowledgeContentModal(
                          Object.keys(content).length > 1
                            ? content
                            : { archiveId: content.archiveId }
                        );
                        setShowContentsModal(true);
                      }}
                      className="icon-hover-bg-soft-primary cursor-pointer"
                    >
                      <LucideIcon icon="Plus" />
                    </a>
                  </TooltipComponent>
                </div>
              </div>
            </div>
            {isExpanded && (
              <div className="px-4 pt-2">
                {files.map((file) => (
                  <div
                    className="nav-link text-nowrap content-item cursor-pointer p-1 fs-7_1 d-flex gap-1 align-items-center"
                    key={file.id}
                  >
                    <div className="d-flex align-items-center">
                      <LucideIcon
                        size={18}
                        icon={getIconByMimeType(file.file.mimeType).icon}
                        color={getIconByMimeType(file.file.mimeType).color}
                      />
                    </div>
                    <TextOverflowTooltip
                      maxLength={25}
                      textStyle="fs-7_1"
                      text={file?.file?.downloadName}
                    />
                  </div>
                ))}
                {pagination?.count > 5 && (
                  <div className="pt-2">
                    <div
                      onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                      }}
                      className="py-2 px-3 nav-link bg-white border bg-hover-gray transition cursor-pointer rounded-pill d-flex"
                    >
                      <span className="fs-7 font-weight-medium text-center w-100">
                        View all
                      </span>
                    </div>
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};
const Threads = ({ threads }) => {
  return (
    <div className="px-3">
      <div className="d-flex flex-column">
        {threads?.length > 0 ? (
          <>
            {threads.map((thread, index) => (
              <div
                key={thread.id}
                className={`px-2 py-3 bg-hover-gray ${
                  index > 0 && index !== threads.length - 1
                    ? 'border-bottom'
                    : index === threads.length - 1
                    ? 'border-bottom-0'
                    : index === 0
                    ? 'border-bottom'
                    : ''
                }`}
              >
                <div className="d-flex align-items-center pb-2 justify-content-between">
                  <h5 className="mb-0">{thread.title}</h5>
                </div>
                <div className="d-flex pt-2 align-items-center justify-content-between">
                  <div className="d-flex align-items-center gap-4">
                    <div className="fs-8 d-flex gap-1 align-items-center font-weight-medium text-muted">
                      <LucideIcon icon="Landmark" size={14} />
                      <span>{thread.tenant}</span>
                    </div>
                    <div className="fs-8 d-flex gap-1 align-items-center font-weight-medium text-muted">
                      <LucideIcon icon="History" size={14} />
                      <span>{setDateFormat(thread.createdDate)}</span>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </>
        ) : (
          <div className="bg-gray-table-head rounded p-3 text-center border">
            No history found.
          </div>
        )}
      </div>
    </div>
  );
};

const IdaContent = () => {
  const [showLoading, setShowLoading] = useState(false);
  const [paginationPage, setPaginationPage] = useState(paginationDefault);
  const [pagination, setPagination] = useState(paginationDefault);
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [dataInDB, setDataInDB] = useState(false);
  const [order, setOrder] = useState([]);
  const [allTenants, setAllTenants] = useState([]);
  const [filter, setFilter] = useState({ search: '' });
  const [stats, setStats] = useState([]);
  const [viewType, setViewType] = useState(ContentView.Table);
  const { hash, updateHash } = useHash();
  const { loading, archives, fetchArchives } = useFetchArchives(false, 25);

  const handleCreateClick = (item, force) => {
    setContent(item || {}); // Reset content object for new creation
    if (Object.keys(item).length && !force) {
      updateHash(`${ContentView.Detail}/${item.archiveId}`);
      setViewType(ContentView.Detail);
    } else {
      setShowModal(true); // Show the modal
    }
  };

  const handleContentUpdate = (updatedContent) => {
    // Refresh data after update if needed
    // getContent();
  };

  const {
    setShowModal,
    content,
    setContent,
    setSuccessMessage: setModalSuccessMessage,
    setErrorMessage: setModalErrorMessage,
    CreatePremiumContentModal,
  } = useCreatePremiumContentModal(handleContentUpdate, handleCreateClick);

  useEffect(() => {
    if (successMessage) {
      setModalSuccessMessage(successMessage);
    }
    if (errorMessage) {
      setModalErrorMessage(errorMessage);
    }
  }, [successMessage, errorMessage]);

  useEffect(() => {
    if (hash.includes(ContentView.Detail)) {
      setViewType(ContentView.Detail);
    } else {
      setViewType(ContentView.Table);
      fetchArchives();
      updateHash('');
    }
  }, [hash]);

  const threads = content?.threads || staticIdaData.data[0].threads;

  return (
    <>
      <AlertWrapper className="alert-position">
        <Alert
          color="danger"
          message={errorMessage}
          setMessage={setErrorMessage}
        />
        <Alert
          color="success"
          message={successMessage}
          setMessage={setSuccessMessage}
        />
      </AlertWrapper>
      {viewType === ContentView.Table ? (
        <PremiumContentTable
          allTenants={archives}
          stats={stats}
          dataInDB={true}
          showLoading={loading}
          pagination={pagination}
          paginationPage={paginationPage}
          setPaginationPage={setPaginationPage}
          setCreate={handleCreateClick}
          order={order}
          setOrder={setOrder}
          setViewType={setViewType}
          filter={filter}
          setFilter={setFilter}
          refresh={() => {
            fetchArchives();
          }}
          setSuccessMessage={setSuccessMessage}
        />
      ) : (
        <div className="bg-white d-flex flex-column h-100">
          <div className="p-3 d-flex align-items-center gap-2 border-bottom">
            <IdfTooltip text="Go back">
              <button
                className="btn btn-sm d-flex align-items-center icon-hover-bg p-0"
                onClick={() => updateHash('')}
              >
                <LucideIcon size={20} icon="MoveLeft" />
              </button>
            </IdfTooltip>
            <div className="d-flex align-items-center gap-1">
              {content?.icon ? (
                <span
                  dangerouslySetInnerHTML={{ __html: `&#x${content.icon};` }}
                  className="font-size-xl"
                />
              ) : (
                <div
                  className={`rounded-circle bg-beige p-1_2 bg-opacity-hover d-flex align-items-center`}
                >
                  <LucideIcon icon="Files" size={18} clazz="text-black" />
                </div>
              )}
              <h4 className="p-0 pl-2 mb-0 nav-link fw-bold text-truncate">
                {content?.title}
              </h4>
            </div>
          </div>
          <div style={{ maxWidth: 1100 }} className="row flex-fill w-100 py-3">
            <div className="col-md-8">
              <Threads threads={threads} />
            </div>
            <div className="col-md-4">
              <InstructionsAndContent
                content={content}
                openEditModal={() => handleCreateClick(content, true)}
                pagination={{ count: content?.files?.length }}
              />
            </div>
          </div>
        </div>
      )}
      <CreatePremiumContentModal />
    </>
  );
};

export default IdaContent;
