import React, { useLayoutEffect, useState } from 'react';
import { Card, CardBody, Col, Row } from 'reactstrap';
import checklistService from '../services/checklist.service';
import ChecklistSection from '../components/checklist/CreateChecklist';
import { Skeleton } from '@mui/material';
import {
  FrequencyMap,
  IntervalMap,
  isPermissionAllowed,
  parseRRuleString,
} from '../utils/Utils';
import { Actions } from '../components/fields/fields.constants';
import { capitalize } from 'lodash';
import Alert from '../components/Alert/Alert';
import AlertWrapper from '../components/Alert/AlertWrapper';
import MaterialIcon from '../components/commons/MaterialIcon';
import PageTitle from '../components/commons/PageTitle';

const ChecklistCreate = ({ setChecklistCreate }) => {
  return (
    <div className="h-100 d-flex align-items-center gap-1 justify-content-center flex-wrap">
      <div>
        <div className="w-100 d-block text-center">
          <MaterialIcon
            icon="checklist_rtl"
            size="font-size-3em"
            clazz="text-primary mb-1"
          />{' '}
        </div>
        <div
          onClick={() => setChecklistCreate(true)}
          className="text-primary cursor-pointer gap-1 d-flex align-items-center justify-content-center"
        >
          <MaterialIcon icon="add" size="fs-5" clazz="text-primary" />{' '}
          <span className="border-0 link-underline px-0 text-primary">
            Start New Checklist
          </span>
        </div>
      </div>
    </div>
  );
};
const ChecklistFields = () => {
  const [checklistCreate, setChecklistCreate] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [checkLists, setCheckLists] = useState([]);
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const ChecklistMapper = (item) => {
    const recurrencePattern = parseRRuleString(item?.recurrencePattern);
    const interval = IntervalMap[recurrencePattern?.INTERVAL];
    const freq =
      FrequencyMap[recurrencePattern?.FREQ] || recurrencePattern?.FREQ;
    return {
      ...item,
      renewalInterval: {
        key: item?.renewalValue,
        name: `${item?.renewalValue} ${capitalize(item?.renewalPeriod)}s Prior`,
      },
      recurrencePattern: {
        key: interval || freq || recurrencePattern?.FREQ?.toLowerCase(),
        name:
          capitalize(interval) ||
          capitalize(freq) ||
          capitalize(recurrencePattern?.FREQ),
        recurse: item?.recurrencePattern,
      },
      status: item?.status,
    };
  };

  const loadChecklists = async () => {
    const { data } = await checklistService.getCheckLists({
      limit: 100,
      page: 1,
    });
    const updatedData = data?.data?.map(ChecklistMapper);
    setCheckLists(updatedData);
  };
  const getChecklistData = async (newOrUpdatedChecklist, type) => {
    setLoading(true);
    try {
      if (!newOrUpdatedChecklist) {
        await loadChecklists();
      } else {
        if (type === Actions.Add) {
          setCheckLists([
            ...checkLists,
            ChecklistMapper(newOrUpdatedChecklist),
          ]);
        } else if (type === Actions.Edit) {
          // edit case FE handling
          setCheckLists(
            [...checkLists].map((ch) =>
              ch.checklistId === newOrUpdatedChecklist.checklistId
                ? { ...newOrUpdatedChecklist }
                : { ...ch }
            )
          );
        } else if (type === Actions.Delete) {
          setCheckLists(
            [...checkLists].filter(
              (ch) => ch.checklistId !== newOrUpdatedChecklist.checklistId
            )
          );
          setSuccessMessage('Checklist Deleted');
        } else {
          await loadChecklists();
        }
      }
    } catch (e) {
      console.log(e);
    } finally {
      setLoading(false);
    }
  };

  useLayoutEffect(() => {
    getChecklistData();
  }, []);

  return (
    <>
      <PageTitle page="Checklists" pageModule="" />
      <AlertWrapper className="alert-position">
        <Alert
          color="success"
          message={successMessage}
          setMessage={setSuccessMessage}
        />
        <Alert
          color="danger"
          message={errorMessage}
          setMessage={setSuccessMessage}
        />
      </AlertWrapper>
      <Row className="stages-board bg-gray-5 p-3 flex-nowrap overflow-x-auto">
        {checkLists.map((item, index) => (
          <Col className={`vertical-section-chk-list pr-0`} key={index}>
            <Card className="h-100 hover-actions shadow-none">
              <ChecklistSection
                checklistData={item}
                checklist={checkLists}
                setChecklist={setCheckLists}
                setErrorMessage={setErrorMessage}
                setSuccessMessage={setSuccessMessage}
                getChecklistData={getChecklistData}
              />
            </Card>
          </Col>
        ))}
        {!isLoading && (
          <Col className={`vertical-section-chk-list`}>
            <Card className="h-100 hover-actions shadow-none">
              {checklistCreate ? (
                <ChecklistSection
                  setChecklistCreate={setChecklistCreate}
                  checklistCreate={checklistCreate}
                  getChecklistData={getChecklistData}
                  setErrorMessage={setErrorMessage}
                  setSuccessMessage={setSuccessMessage}
                />
              ) : (
                <>
                  {isPermissionAllowed('checklists', 'create') && (
                    <ChecklistCreate setChecklistCreate={setChecklistCreate} />
                  )}
                </>
              )}
            </Card>
          </Col>
        )}
        {isLoading && (
          <Col className={`vertical-section-chk-list stages-board pr-0`}>
            <Card className="h-100 hover-actions shadow-none">
              <CardBody>
                <Skeleton variant="rounded" width={'100%'} height={60} />
                <div className="mt-3">
                  <Skeleton variant="rounded" width={'100%'} height={220} />
                </div>
                <div className="mt-3">
                  <Skeleton variant="rounded" width={'100%'} height={40} />
                </div>
                <div className="mt-3">
                  <Skeleton variant="rounded" width={'100%'} height={180} />
                </div>
                <div className="mt-3">
                  <Skeleton variant="rounded" width={'100%'} height={60} />
                </div>
                <div className="mt-3 d-flex align-items-center justify-content-between">
                  <Skeleton variant="rounded" width={'25%'} height={40} />
                  <Skeleton variant="rounded" width={'25%'} height={40} />
                </div>
              </CardBody>
            </Card>
          </Col>
        )}
      </Row>
    </>
  );
};

export default ChecklistFields;
