import React, { useEffect, useState } from 'react';
import { Card, CardBody, CardFooter, CardHeader } from 'reactstrap';
import { paginationDefault } from '../../../../../utils/constants';
import UserStatisticsLesson from './LeaderboardStatistics';
import { getTomorrowDate } from '../../../../../utils/Utils';
import DatePickerTraining from '../../../../../components/dealsBreakdown/DatePickerTraining';
import teamsService from '../../../../../services/teams.service';
import ReportSearchOptionDropdown from '../../../../../components/commons/ReportSearchOptionDropdown';
import Pagination from '../../../../../components/Pagination';
import {
  changePaginationLimit,
  changePaginationPage,
} from '../../../../Deals/contacts/utils';
import IdfTooltip from '../../../../../components/idfComponents/idfTooltip';

const TrainingLeaderboard = ({ dashboard }) => {
  const [csvBtnLoad, setShowCSVBtn] = useState(false);
  const [selectedItem, setSelectedItem] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [show, setShow] = useState(false);
  const [teams, setTeams] = useState([]);
  const [queryFilter, setQueryFilter] = useState({
    filters: [],
  });

  const [dateRange, setDateRange] = useState({
    startDate: new Date(1970, 0, 1),
    endDate: getTomorrowDate(),
  });

  const [pagination, setPagination] = useState(paginationDefault);
  const [paginationPage, setPaginationPage] = useState(paginationDefault);

  const getTeam = async () => {
    const { data } = await teamsService.getTeams({
      page: 1,
      limit: 100,
      filter: { search: searchQuery, isActive: true },
    });
    setTeams(data);
  };

  useEffect(() => {
    getTeam();
  }, [searchQuery]);

  const handleAllSelect = () => {
    const allSelected = teams?.map((rpt) => rpt?.id);

    setQueryFilter({
      filters: [...allSelected],
    });
    toggleModify();
  };

  const toggleModify = () => {
    setPaginationPage((prev) => ({ ...prev, page: 1 }));
  };

  return (
    <Card className="h-100 border-0 shadow-0">
      <CardHeader>
        <div className="d-flex justify-content-between align-items-center w-100">
          <IdfTooltip text={dashboard?.name} placement="bottom">
            <h4 className="card-title text-hover-primary mb-0 text-truncate">
              {dashboard?.name}
            </h4>
          </IdfTooltip>
          <div
            className={`d-flex align-items-center gap-2 ${
              csvBtnLoad ? 'mr-6 pr-9' : ''
            }`}
          >
            <DatePickerTraining
              range={dateRange}
              setRange={setDateRange}
              toggle={toggleModify}
              btnToggleStyle="mx-0 w-250"
            />
            <ReportSearchOptionDropdown
              selectedData={selectedItem}
              data={teams}
              show={show}
              label={`Team`}
              scrollable="pr-3 text-left"
              setShow={setShow}
              showCheckAll={false}
              setSelectedData={setSelectedItem}
              search={true}
              maxLength={22}
              customKey={['name', 'id']}
              handleAllSelect={handleAllSelect}
              customStyle={{ width: '250px' }}
              setQueryFilters={() => {}}
              searchQuery={searchQuery}
              setSearchQuery={setSearchQuery}
              handleOnDone={(items) => {
                setQueryFilter({
                  filters: [...items],
                });
                toggleModify();
              }}
            />
          </div>
        </div>
      </CardHeader>

      <CardBody className="p-0 flex-1 overflow-y-auto">
        <UserStatisticsLesson
          dateRange={dateRange}
          queryFilter={queryFilter}
          setShowCSVBtn={setShowCSVBtn}
          paginationPage={paginationPage}
          setPagination={setPagination}
          toggleModify={toggleModify}
        />
      </CardBody>

      <CardFooter className="border-top bg-gray-table-head p-2 border-bottom-0 border-left-0 border-right-0 rounded-0">
        <div className="d-flex align-items-center justify-content-end">
          <Pagination
            showPerPage
            showPages={false}
            paginationInfo={pagination}
            onPageChange={(newPage) => {
              changePaginationPage(newPage, setPaginationPage);
            }}
            onLimitChange={(perPage) => {
              changePaginationLimit(perPage, setPaginationPage);
            }}
          />
        </div>
      </CardFooter>
    </Card>
  );
};

export default TrainingLeaderboard;
