import React from 'react';
import { Card, CardBody } from 'reactstrap';
import { numbersWithComma } from '../../../../utils/Utils';

const FeeChargebacksWidget = ({ widgetConfig, whenPrinting }) => {
  const isAggregateView = widgetConfig?.aggregate === true;
  const aggregateOrIndividualObject = isAggregateView
    ? widgetConfig?.data?.aggregate
    : widgetConfig?.data;

  return (
    <>
      <Card className="report-widget">
        <CardBody className="justify-content-center align-items-center d-flex">
          <div className="flex-fill w-100">
            <div className="d-flex flex-column text-left">
              <h5 className="mb-2 hide-heading">{widgetConfig.header}</h5>
              <div className="d-grid grid-col-2 mx-2 gap-x-4 gap-y-8 fs-6 font-weight-bold text-gray-search">
                {widgetConfig.cardsData?.map((item, index) => (
                  <CardBody
                    key={index}
                    className="d-flex justify-content-center widget-border-2 border-2 bg-white rpt-rounded bg-white text-center"
                  >
                    <div className="d-flex flex-column justify-content-center gap-2">
                      <span>{item.title}</span>
                      <span className="text-black font-weight-bolder fs-5">
                        {item?.format === 'percentage'
                          ? `${numbersWithComma(
                              aggregateOrIndividualObject?.[item.key],
                              {
                                maximumFractionDigits: 4,
                              }
                            )}%`
                          : item?.format === 'currency'
                          ? `$${numbersWithComma(
                              aggregateOrIndividualObject?.[item.key],
                              {
                                maximumFractionDigits: 2,
                              }
                            )}`
                          : numbersWithComma(
                              aggregateOrIndividualObject?.[item.key]
                            )}
                      </span>
                    </div>
                  </CardBody>
                ))}
              </div>
            </div>
          </div>
        </CardBody>
      </Card>
    </>
  );
};

export default FeeChargebacksWidget;
