import React, { useCallback, useEffect, useMemo, useState } from 'react';
import AlertWrapper from '../../Alert/AlertWrapper';
import Alert from '../../Alert/Alert';
import { overflowing } from '../../../utils/Utils';
import SimpleModalCreation from '../../modal/SimpleModalCreation';
import { FormGroup, Label } from 'reactstrap';
import { FormControl } from 'react-bootstrap';
import ArchiveService from '../../../services/archive.service';
import useHash from '../../../hooks/useHash';
import useIsTenant from '../../../hooks/useIsTenant';
import MaterialIcon from '../../commons/MaterialIcon';
import ButtonFilterDropdown from '../../commons/ButtonFilterDropdown';
import EmojiPickerDropdown from '../../commons/EmojiPickerDropdown';
const { isDemo } = useIsTenant();

const sectionVisible = isDemo ? '' : 'd-none';

const ModelList = [
  {
    key: 'gpt-3.5-turbo',
    name: 'GPT_3_5_TURBO',
    description: 'Powerful model for general tasks.',
  },
  {
    key: 'gpt-3.5-turbo-0125',
    name: 'GPT_3_5_TURBO_16K',
    description: 'Extended GPT-3.5 with larger context.',
  },
  {
    key: 'gpt-4',
    name: 'GPT_4',
    description: 'Advanced model for complex reasoning.',
  },
  {
    key: 'gpt-4-32k',
    name: 'GPT_4_32K',
    description: 'GPT-4 with 32K token context.',
  },
  {
    key: 'gpt-4-0125-preview',
    name: 'GPT_4_TURBO',
    description: 'Faster GPT-4 for real-time applications.',
  },
  {
    key: 'gpt-4-vision-preview',
    name: 'GPT_4_TURBO_VISION',
    description: 'GPT-4 with vision and text processing.',
  },
  {
    key: 'dall-e-3',
    name: 'GPT_DALLE_3',
    description: 'Generates images from text descriptions.',
  },
  {
    key: 'claude-instant-1',
    name: 'CLAUDE_INSTANT',
    description: 'Fast, real-time AI responses.',
  },
  {
    key: 'claude-instant-1.2',
    name: 'CLAUDE_INSTANT_1_2',
    description: 'Improved Claude Instant for better results.',
  },
  {
    key: 'claude-2',
    name: 'CLAUDE',
    description: 'Versatile AI for various tasks.',
  },
  {
    key: 'claude-2.1',
    name: 'CLAUDE_2_1',
    description: 'Enhanced Claude with better reasoning.',
  },
  {
    key: 'claude-3-sonnet-20240229',
    name: 'CLAUDE_3_SONNET',
    description: 'Claude 3 with creative text generation.',
  },
  {
    key: 'claude-3-opus-20240229',
    name: 'CLAUDE_3_OPUS',
    description: 'Claude 3 for linguistic tasks.',
  },
  {
    key: 'claude-3-5-sonnet-20240620',
    name: 'CLAUDE_3_5_SONNET',
    description: 'Updated Claude 3 with more creativity.',
  },
  {
    key: 'sonar-medium-online',
    name: 'SONAR_MEDIUM_ONLINE',
    description: 'Sonar for real-time online data analysis.',
  },
];

const CreateKnowledgeModal = ({
  show,
  setShow,
  setSuccessMessage,
  knowledge = {},
  onUpdate,
}) => {
  const { updateHash } = useHash();

  const defaultKnowledgeObject = {
    title: '',
    description: '',
    icon: '',
    isPublic: false,
  };

  const [modalKnowledge, setModalKnowledge] = useState(defaultKnowledgeObject);
  const [saving, setSaving] = useState(false);

  const handleCreateKnowledge = async () => {
    try {
      setSaving(true);
      const isUpdating = !!knowledge?.archiveId;
      const data = isUpdating
        ? await ArchiveService.updateArchive(knowledge.archiveId, {
            icon: modalKnowledge?.icon,
            title: modalKnowledge.title,
            description: modalKnowledge.description,
          })
        : await ArchiveService.createArchive({
            ...modalKnowledge,
            icon: modalKnowledge?.icon,
          });
      if (isUpdating) {
        setShow(false);
        setSuccessMessage('Knowledge Updated');
        onUpdate({
          ...knowledge,
          icon: modalKnowledge.icon,
          title: modalKnowledge.title,
          description: modalKnowledge.description,
        });
      } else {
        const archiveId = data?.input?.archive?.archiveId;
        setShow(false);
        setSuccessMessage('Knowledge Created');
        updateHash(`#knowledge/${archiveId}`);
      }
      overflowing();
    } catch (e) {
      console.log(e);
    } finally {
      setSaving(false);
    }
  };

  useEffect(() => {
    if (show) {
      setModalKnowledge({ ...knowledge });
    }
  }, [show, knowledge]);

  const onKeyEnter = (e) => {
    if (e.keyCode === 13) {
      e.preventDefault();
      handleCreateKnowledge();
    }
  };

  return (
    <SimpleModalCreation
      modalTitle={
        knowledge?.archiveId ? 'Update Knowledge' : 'Create Knowledge'
      }
      open={show}
      saveButton={knowledge?.archiveId ? 'Update' : 'Continue'}
      isLoading={saving}
      buttonsDisabled={!modalKnowledge.title}
      deleteButton={null}
      handleSubmit={handleCreateKnowledge}
      bodyClassName="p-3"
      size={sectionVisible ? 'md' : 'lg'}
      onHandleCloseModal={() => {
        overflowing();
        setShow(false);
        setModalKnowledge(defaultKnowledgeObject);
      }}
    >
      <div className="d-flex flex-column gap-2">
        <div className="d-flex gap-2 align-items-center">
          <FormGroup className="flex-1">
            <Label>Title</Label>
            <FormControl
              type="text"
              name="title"
              onKeyDown={onKeyEnter}
              value={modalKnowledge.title}
              onChange={(e) => {
                setModalKnowledge({
                  ...modalKnowledge,
                  title: e.target.value,
                });
              }}
              placeholder="Knowledge title"
            />
          </FormGroup>

          <FormGroup className="d-flex flex-column gap-1 ml-2">
            <Label>Emoji</Label>
            <EmojiPickerDropdown
              setEmoji={(emoji) => {
                setModalKnowledge({
                  ...modalKnowledge,
                  icon: emoji.unified,
                });
              }}
            >
              <a className="cursor-pointer fs-3 icon-hover-bg">
                {modalKnowledge?.icon ? (
                  <span
                    dangerouslySetInnerHTML={{
                      __html: `&#x${modalKnowledge.icon};`,
                    }}
                  />
                ) : (
                  <div className="bg-gray-100 rounded-circle p-2 d-flex align-items-center justify-content-center">
                    <MaterialIcon
                      icon="add"
                      size="fs-3"
                      clazz="rounded-circle"
                    />
                  </div>
                )}
              </a>
            </EmojiPickerDropdown>
          </FormGroup>
        </div>

        <FormGroup>
          <Label>
            Description{' '}
            <span className="font-weight-normal text-muted-100">
              (optional)
            </span>
          </Label>
          <textarea
            className="form-control"
            placeholder="Knowledge description..."
            rows={3}
            name="description"
            value={modalKnowledge.description}
            onChange={(e) => {
              setModalKnowledge({
                ...modalKnowledge,
                description: e.target.value,
              });
            }}
          />
        </FormGroup>

        <>
          {!sectionVisible ? (
            <>
              <FormGroup>
                <Label>
                  Custom Instructions{' '}
                  <span className="font-weight-normal text-muted-100">
                    (optional)
                  </span>
                </Label>
                <p className="font-size-sm2 text-muted-100">
                  Give instructions to the AI that affects every thread in this
                  space.
                </p>
                <textarea
                  className="form-control"
                  placeholder="Provide instructions for AI to follow..."
                  rows={5}
                />
              </FormGroup>
              <FormGroup>
                <Label>AI Model</Label>
                <ButtonFilterDropdown
                  menuClass="rpt-history-dd-width max-h-300"
                  options={ModelList}
                  hideIcon={true}
                  filterOptionSelected={ModelList[0]}
                  handleFilterSelect={() => {}}
                />
              </FormGroup>
            </>
          ) : null}
        </>
      </div>
    </SimpleModalCreation>
  );
};
const useCreateKnowledgeModal = (onUpdate) => {
  const [showModal, setShowModal] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [knowledge, setKnowledge] = useState({});

  const CreateKnowledgeModalCallback = useCallback(() => {
    return (
      <>
        <AlertWrapper className="alert-position">
          {successMessage && (
            <Alert
              color="success"
              message={successMessage}
              setMessage={setSuccessMessage}
            />
          )}
          {errorMessage && (
            <Alert
              color="danger"
              message={errorMessage}
              setMessage={setErrorMessage}
            />
          )}
        </AlertWrapper>

        <CreateKnowledgeModal
          show={showModal}
          setShow={setShowModal}
          setSuccessMessage={setSuccessMessage}
          setErrorMessage={setErrorMessage}
          knowledge={knowledge}
          onUpdate={onUpdate}
        />
      </>
    );
  }, [
    showModal,
    setShowModal,
    successMessage,
    errorMessage,
    setSuccessMessage,
    setErrorMessage,
  ]);

  return useMemo(
    () => ({
      setShowModal,
      setKnowledge,
      CreateKnowledgeModal: CreateKnowledgeModalCallback,
    }),
    [setShowModal, setKnowledge, CreateKnowledgeModalCallback]
  );
};

export default useCreateKnowledgeModal;
