import { useEffect, useState } from 'react';
import Avatar from '../../../../../components/Avatar';
import GenericTable from '../../../../../components/GenericTable';
import { ShortDescription } from '../../../../../components/ShortDescription';
import { cubeService } from '../../../../../services';
import TableSkeleton from '../../../../../components/commons/TableSkeleton';
import { LeaderBoardStatisticsColumns } from '../../../../../utils/learn.constants';
import NoDataFound from '../../../../../components/commons/NoDataFound';

const LeaderboardStatistics = ({
  dateRange = {},
  setShowCSVBtn = () => {},
  queryFilter,
  paginationPage,
  setPagination,
  toggleModify,
}) => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [orderBy, setOrderBy] = useState('');
  const [order, setOrder] = useState(['', 'ASC']);

  const getReports = async () => {
    setLoading(true);
    const results = await cubeService.loadCustomAnalytics({
      type: 'Leaderboard',
      orderBy: orderBy?.order,
      limit: paginationPage?.limit,
      page: paginationPage?.page,
      startDate: dateRange?.startDate,
      endDate: dateRange?.endDate,
      ...(queryFilter?.filters?.length > 0
        ? { teams: queryFilter?.filters }
        : {}),
    });
    setData(results);
    setShowCSVBtn(results?.length > 0);
    setLoading(false);
    setPagination(paginationPage); // Todo:- will update with response pagination when API's will change
  };

  useEffect(() => {
    getReports();
  }, [paginationPage]);

  const updatedRows = data?.map((item) => {
    return {
      users: item?.userName,
      completed: item?.totalLessonsCompleted,
      selfDirected: item?.totalSelfStudyLessonsCompleted,
      team: item?.userTeamName,
      assigned: item?.totalAssignedLessonsCompleted,
    };
  });

  const rows = data?.map((result, index) => {
    const user = {
      firstName: result?.userName,
      avatar: result?.avatar,
    };

    const rank = index + 1;

    return {
      id: index,
      dataRow: [
        {
          key: 'rank',
          component: (
            <div className="rank-container">
              <span className={`rank-${rank} font-weight-semi-bold`}>
                {rank}
              </span>
            </div>
          ),
        },
        {
          key: 'users',
          component: (
            <div className={`d-flex align-items-center font-weight-semi-bold`}>
              <Avatar defaultSize="xs" classModifiers="mr-2" user={user} />
              {result?.userName}
            </div>
          ),
        },
        {
          key: 'completed',
          component: (
            <div>
              <span>{result?.totalLessonsCompleted}</span>
            </div>
          ),
        },
        {
          key: 'assigned',
          component: result?.totalAssignedLessonsCompleted,
        },
        {
          key: 'selfDirected',
          component: result?.totalSelfStudyLessonsCompleted,
        },
        {
          key: 'team',
          component: (
            <span className="d-flex align-items-center">
              <ShortDescription
                limit={20}
                content={result?.userTeamName || '--'}
              />
            </span>
          ),
        },
      ],
    };
  });

  const handleSortByData = (key) => {
    if (key?.name?.key === 'selfDirected') {
      setOrderBy({
        order: `${key?.name?.orderBy} ${
          key?.name?.clicked.toLowerCase() === 'desc' ? 'asc' : 'desc'
        }`,
      });
    } else {
      setOrderBy({
        order: `${key?.name?.orderBy} ${key?.name?.clicked.toLowerCase()}`,
      });
    }
    setOrder([key?.name?.orderBy, key?.name?.clicked]);
    toggleModify();
  };

  const loader = () => {
    if (loading) return <TableSkeleton cols={6} rows={10} />;
  };

  return (
    <div>
      {loading ? (
        loader()
      ) : (
        <div>
          {data?.length === 0 ? (
            <NoDataFound
              icon="manage_search"
              title="No data found"
              containerStyle="text-gray-search my-6 py-6"
              titleStyle="font-normal font-size-sm2 text-gray-search"
            />
          ) : (
            <GenericTable
              checkbox={false}
              data={rows}
              exportToCSV={true}
              tableData={updatedRows}
              fileName="LeaderboardReport"
              columns={LeaderBoardStatisticsColumns}
              usePagination={false}
              noDataInDbValidation={true}
              sortingTable={handleSortByData}
              sortingOrder={order}
            />
          )}
        </div>
      )}
    </div>
  );
};

export default LeaderboardStatistics;
