import React, { useCallback, useMemo, useState } from 'react';
import { overflowing } from '../../../../utils/Utils';
import DeleteConfirmationModal from '../../../modal/DeleteConfirmationModal';
import AlertWrapper from '../../../Alert/AlertWrapper';
import Alert from '../../../Alert/Alert';

const DeleteModal = ({
  show,
  setShow,
  modalConfig,
  data,
  setSuccessMessage,
  setErrorMessage,
}) => {
  const handleConfirmDeleteReport = async () => {
    try {
      await modalConfig.deleteCallback(data);
      overflowing();
      setSuccessMessage(modalConfig.successMsg);
      setShow(false);
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <DeleteConfirmationModal
      showModal={show}
      setShowModal={setShow}
      event={handleConfirmDeleteReport}
      itemsConfirmation={[data]}
      heading={modalConfig.heading}
      description={modalConfig.description}
    />
  );
};

const useDeleteModal = (modalConfig) => {
  const [showModal, setShowModal] = useState(false);
  const [data, setData] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');

  const DeleteModalCallback = useCallback(() => {
    return (
      <>
        <AlertWrapper>
          <Alert
            color="danger"
            message={errorMessage}
            setMessage={setErrorMessage}
          />
          <Alert
            color="success"
            message={successMessage}
            setMessage={setSuccessMessage}
          />
        </AlertWrapper>

        <DeleteModal
          show={showModal}
          setShow={setShowModal}
          data={data}
          modalConfig={modalConfig}
          setSuccessMessage={setSuccessMessage}
          setErrorMessage={setErrorMessage}
        />
      </>
    );
  }, [showModal, setShowModal, data, setData]);

  return useMemo(
    () => ({
      setShowModal,
      setData,
      DeleteModal: DeleteModalCallback,
    }),
    [setShowModal, DeleteModalCallback]
  );
};

export default useDeleteModal;
