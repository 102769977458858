import {
  DATE_FORMAT_MONTH_V2,
  FormattedValue,
  setDateFormat,
} from '../../../utils/Utils';
import TextOverflowTooltip from '../../commons/TextOverflowTooltip';
import StatusBadge from '../shared/StatusBadge';
import React from 'react';

// Data for metric cards
export const metricCardsData = [
  {
    title: 'Total Calls YTD',
    icon: 'PhoneCall',
    metrics: [
      { label: 'Total Calls', value: '35', type: 'number' },
      { label: 'This Month', value: '11', type: 'number' },
      { label: 'Last Month', value: '6', type: 'number' },
    ],
    progress: {
      percentage: '75.3',
      variant: 'primary',
      labels: ['Q1 Progress (Jan-Mar)', '75.3% of quarterly goal'],
    },
  },
  {
    title: 'Total Events YTD',
    icon: 'Calendar',
    metrics: [
      { label: 'Total Events', value: '3', type: 'number' },
      { label: 'This Month', value: '1', type: 'number' },
      { label: 'Last Month', value: '2', type: 'number' },
    ],
    progress: {
      percentage: '82.6',
      variant: 'success',
      labels: ['Q1 Progress (Jan-Mar)', '82.6% of quarterly goal'],
    },
  },
  {
    title: 'Total Tasks YTD',
    icon: 'ClipboardCheck',
    metrics: [
      { label: 'Total Tasks', value: '132', type: 'number' },
      { label: 'Completed', value: '123', type: 'number' },
      { label: 'Pending', value: '9', type: 'number' },
    ],
    progress: {
      percentage: '93',
      variant: 'warning',
      labels: ['Completion Rate', '93% completed'],
    },
  },
];

// Data for relationship managers table
export const relationshipManagersColumns = [
  {
    key: 'manager',
    component: <span>Relationship Manager</span>,
  },
  {
    key: 'calls',
    component: <span>Calls</span>,
  },
  {
    key: 'events',
    component: <span>Events</span>,
  },
  {
    key: 'tasks',
    component: <span>Tasks Completed</span>,
  },
  {
    key: 'total',
    component: <span>Total Activities</span>,
  },
];

export const relationshipManagersData = [
  {
    manager: 'John Smith',
    calls: 287,
    events: 58,
    tasks: 743,
    total: 1088,
    priority: 'Success',
  },
  {
    manager: 'Nora Jones',
    calls: 249,
    events: 62,
    tasks: 687,
    total: 998,
    priority: 'Success',
  },
  {
    manager: 'Ben Lee',
    calls: 231,
    events: 46,
    tasks: 581,
    total: 858,
    priority: 'Medium',
  },
  {
    manager: 'KJ Curtis',
    calls: 214,
    events: 42,
    tasks: 498,
    total: 754,
    priority: 'Medium',
  },
  {
    manager: 'Mike Zo',
    calls: 193,
    events: 39,
    tasks: 366,
    total: 598,
    priority: 'High',
  },
];

// Data for overdue tasks table
export const overdueTasksColumns = [
  {
    key: 'description',
    component: <span>Task Description</span>,
  },
  {
    key: 'dueDate',
    component: <span>Due Date</span>,
  },
  {
    key: 'client',
    component: <span>Client</span>,
  },
  {
    key: 'priority',
    component: <span>Priority</span>,
  },
];

export const overdueTasksData = [
  {
    description: 'Complete Client Review',
    dueDate: 'Mar 13, 2025',
    client: 'Meridian Manufacturing Group',
    priority: 'High',
  },
  {
    description: 'Follow-up Call',
    dueDate: 'Mar 15, 2025',
    client: 'Coastal Logistics Partners',
    priority: 'High',
  },
  {
    description: 'Update Contact Info',
    dueDate: 'Mar 16, 2025',
    client: 'Summit Healthcare Systems',
    priority: 'Medium',
  },
  {
    description: 'Product Demo Preparation',
    dueDate: 'Mar 18, 2025',
    client: 'Heritage Properties & Development',
    priority: 'Low',
  },
];

// Data for upcoming calls & events table
export const upcomingEventsColumns = [
  {
    key: 'activity',
    component: <span>Activity</span>,
  },
  {
    key: 'date',
    component: <span>Date</span>,
  },
  {
    key: 'client',
    component: <span>Client</span>,
  },
  {
    key: 'event_type',
    component: <span>Type</span>,
  },
];

export const upcomingEventsData = [
  {
    activity: 'Annual Review Meeting',
    date: 'Mar 21, 2025',
    client: 'Stark Industries',
    event_type: 'Call',
  },
  {
    activity: 'Product Demo',
    date: 'Mar 22, 2025',
    client: 'Aperture Science',
    event_type: 'Call',
  },
  {
    activity: 'Banking Conference',
    date: 'Mar 24, 2025',
    client: 'Multiple Clients',
    event_type: 'Event',
  },
  {
    activity: 'Follow-up Discussion',
    date: 'Mar 25, 2025',
    client: 'Becker & Associates',
    event_type: 'Call',
  },
  {
    activity: 'Quarterly Business Review',
    date: 'Mar 28, 2025',
    client: 'Globex Corporation',
    event_type: 'Call',
  },
];

// Data for client tier completion table
export const clientTierColumns = [
  {
    key: 'tier',
    component: <span>Client Tier</span>,
  },
  {
    key: 'totalActivities',
    component: <span>Total Customers</span>,
  },
  {
    key: 'completed',
    component: <span>Completed</span>,
  },
  {
    key: 'completionRate',
    component: <span>Completion Rate</span>,
  },
  {
    key: 'effortFocus',
    component: <span>Effort Focus</span>,
  },
];

export const clientTierData = [
  {
    tier: 'Strategic',
    totalActivities: 1245,
    completed: 1142,
    completionRate: 91.7,
    effortFocus: 24.8,
    priority: 'High',
  },
  {
    tier: 'Core',
    totalActivities: 1873,
    completed: 1584,
    completionRate: 84.6,
    effortFocus: 37.3,
    priority: 'High',
  },
  {
    tier: 'Transactional',
    totalActivities: 1258,
    completed: 978,
    completionRate: 77.7,
    effortFocus: 25.1,
    priority: 'Medium',
  },
  {
    tier: 'Prospect',
    totalActivities: 647,
    completed: 413,
    completionRate: 63.8,
    effortFocus: 12.9,
    priority: 'Success',
  },
];

// data for upcoming scheduled activities
export const upcomingScheduledActivitiesColumns = [
  {
    key: 'company',
    component: <span>Company Name</span>,
  },
  {
    key: 'client_tier',
    component: <span>Client Tier</span>,
  },
  {
    key: 'date',
    component: <span>Date</span>,
  },
];

export const upcomingScheduledActivitiesData = [
  {
    company: 'ABC Company',
    client_tier: 'Strategic',
    date: 'Mar 21, 2025',
  },
  {
    company: 'MotoMoto Inc',
    client_tier: 'Strategic',
    date: 'Mar 21, 2025',
  },
  {
    company: 'Johnson & Lee',
    client_tier: 'Strategic',
    date: 'Mar 21, 2025',
  },
  {
    company: '2Gym Bros',
    client_tier: 'Strategic',
    date: 'Mar 21, 2025',
  },
  {
    company: 'Vram & Vigen Inc.',
    client_tier: 'Strategic',
    date: 'Mar 21, 2025',
  },
];

// Transform relationship managers data for table component
export const relationshipManagersRows = relationshipManagersData.map(
  (item) => ({
    ...item,
    dataRow: [
      {
        key: 'manager',
        classes: 'px-0',
        component: (
          <div className="text-black text-truncate fw-bold d-flex align-items-center gap-2">
            <TextOverflowTooltip
              maxLength={100}
              textStyle="fw-bold text-truncate font-size-sm2 mb-0 z-index-100"
              text={item.manager}
            />
          </div>
        ),
      },
      {
        key: 'calls',
        component: <span>{item.calls}</span>,
      },
      {
        key: 'events',
        component: <span>{item.events}</span>,
      },
      {
        key: 'tasks',
        component: <span>{item.tasks}</span>,
      },
      {
        key: 'total',
        component: (
          <StatusBadge
            showDot={false}
            skipMap={true}
            item={{
              status: item.priority,
              priority: item.priority,
              statusText: FormattedValue({
                value: item.total,
                type: '',
              }),
            }}
          />
        ),
      },
    ],
  })
);

// Transform overdue tasks data for table component
export const overdueTasksRows = overdueTasksData.map((item) => ({
  ...item,
  dataRow: [
    {
      key: 'description',
      classes: 'px-0',
      component: <span>{item.description}</span>,
    },
    {
      key: 'dueDate',
      component: (
        <span>{setDateFormat(item.dueDate, DATE_FORMAT_MONTH_V2)}</span>
      ),
    },
    {
      key: 'client',
      component: (
        <TextOverflowTooltip
          maxLength={100}
          textStyle="text-truncate font-size-sm2 mb-0 z-index-100"
          text={item.client}
        />
      ),
    },
    {
      key: 'priority',
      component: <StatusBadge item={{ priority: item.priority }} />,
    },
  ],
}));

// Transform upcoming events data for table component
export const upcomingEventsRows = upcomingEventsData.map((item) => ({
  ...item,
  dataRow: [
    {
      key: 'activity',
      classes: 'px-0',
      component: <span>{item.activity}</span>,
    },
    {
      key: 'date',
      component: <span>{setDateFormat(item.date, DATE_FORMAT_MONTH_V2)}</span>,
    },
    {
      key: 'client',
      component: (
        <TextOverflowTooltip
          maxLength={100}
          textStyle="text-truncate font-size-sm2 mb-0 z-index-100"
          text={item.client}
        />
      ),
    },
    {
      key: 'event_type',
      component: <span>{item.event_type}</span>,
    },
  ],
}));

// Transform client tier data for table component
export const clientTierRows = clientTierData.map((item) => ({
  ...item,
  dataRow: [
    {
      key: 'tier',
      classes: 'px-0',
      component: (
        <div className="text-black text-truncate fw-bold d-flex align-items-center gap-2">
          <TextOverflowTooltip
            maxLength={100}
            textStyle="fw-bold text-truncate font-size-sm2 mb-0 z-index-100"
            text={item.tier}
          />
        </div>
      ),
    },
    {
      key: 'totalActivities',
      component: <span>{item.totalActivities.toLocaleString()}</span>,
    },
    {
      key: 'completed',
      component: <span>{item.completed.toLocaleString()}</span>,
    },
    {
      key: 'completionRate',
      component: (
        <StatusBadge
          showDot={false}
          skipMap={true}
          item={{
            status: item.priority,
            priority: item.priority,
            statusText: FormattedValue({
              value: item.completionRate,
              type: 'percentage',
            }),
          }}
        />
      ),
    },
    {
      key: 'effortFocus',
      component: <span>{`${item.effortFocus}%`}</span>,
    },
  ],
}));

export const upcomingScheduledActivitiesRows =
  upcomingScheduledActivitiesData.map((item) => ({
    ...item,
    dataRow: [
      {
        key: 'company',
        classes: 'px-0',
        component: <span>{item.company}</span>,
      },
      {
        key: 'client_tier',
        classes: 'px-0',
        component: (
          <div className="text-black text-truncate fw-bold d-flex align-items-center gap-2">
            <TextOverflowTooltip
              maxLength={100}
              textStyle="fw-bold text-truncate font-size-sm2 mb-0 z-index-100"
              text={item.client_tier}
            />
          </div>
        ),
      },
      {
        key: 'date',
        component: (
          <span>{setDateFormat(item.date, DATE_FORMAT_MONTH_V2)}</span>
        ),
      },
    ],
  }));
