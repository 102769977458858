import axios from './axiosInstance';
import authHeader from './auth-header';
import BaseRequestService from './baseRequest.service';

class OrganizationService extends BaseRequestService {
  async getOrganizations(queryFilter, { page = 1, limit = 10 }) {
    const { filter = null, ...restProps } = queryFilter || {};

    const params = {
      ...restProps,
      ...filter,
      page,
      limit,
    };

    return await this.get(
      `/organizations`,
      {
        params,
        headers: authHeader(),
      },
      { fullResponse: true }
    );
  }

  async getOrganizationById(id) {
    const result = await this.get(`/organizations/${id}`, {
      headers: authHeader(),
    });
    return result?.organization;
  }

  async updateOrganization(id, data) {
    return await this.put(`/organizations/${id}`, data, {
      headers: authHeader(),
    });
  }

  async deleteOrganizations(organizationIds) {
    return await this.delete(`/organizations`, {
      headers: authHeader(),
      params: {
        ids: organizationIds.join(','),
      },
    });
  }

  async createOrganization(data) {
    return await this.post(
      `/organizations`,
      data,
      {
        headers: authHeader(),
      },
      { fullResponse: true }
    );
  }

  async getFollowers(organization_id, { page = 1, limit = 5 }) {
    return await this.get(`/organizations/${organization_id}/followers`, {
      headers: authHeader(),
      params: {
        page,
        limit,
      },
    });
  }

  async checkFollowing(organization_id, user_id) {
    const result = await this.get(
      `/organizations/${organization_id}/followers/${user_id}`,
      {
        headers: authHeader(),
      }
    );
    return result.isFollower;
  }

  async stopFollowing(organization_id, user_id) {
    return await this.delete(
      `/organizations/${organization_id}/followers/${user_id}`,
      {
        headers: authHeader(),
      }
    );
  }

  async startFollowing(organization_id, user_id) {
    try {
      const response = await this.post(
        `/organizations/${organization_id}/followers/${user_id}`,
        {},
        {
          headers: authHeader(),
        }
      );
      return response;
    } catch (e) {
      return e;
    }
  }

  async getOwners(organization_id, { page = 1, limit = 5 }) {
    return await this.get(`/organizations/${organization_id}/owners`, {
      headers: authHeader(),
      params: {
        page,
        limit,
      },
    });
  }

  getInsightsByOrganization(orgId) {
    return this.get(`/organizations/${orgId}/insights`, {
      headers: authHeader(),
    });
  }

  async addOwner(organization_id, user_id) {
    return await this.post(
      `/organizations/${organization_id}/owners/${user_id}`,
      {},
      {
        headers: authHeader(),
      }
    );
  }

  async removeOwner(organizationId, userId) {
    const response = await this.delete(
      `/organizations/${organizationId}/owners/${userId}`,
      {
        headers: authHeader(),
      },
      {
        fullResponse: true,
        errorsRedirect: false,
      }
    );

    if (response.status === 200) {
      const { data } = response;

      return data;
    }

    return response;
  }

  getFieldByOrganization(organization_id, { page = 1, limit = 10 }) {
    return axios
      .get(`/organizations/${organization_id}/fields`, {
        headers: authHeader(),
        params: {
          page,
          limit,
        },
      })
      .then((response) => response.data)
      .catch((err) => {
        console.log(err);
      });
  }

  getRelations(id) {
    return axios
      .get(`/organizations/${id}/related`, {
        headers: authHeader(),
      })
      .then((response) => response.data)
      .catch((err) => {
        console.log(err);
      });
  }

  async addRelation(organization_id, related_id, type) {
    return await axios.post(
      `/organizations/related`,
      { organization_id, related_id, type },
      {
        headers: authHeader(),
      }
    );
  }

  async deleteRelation(organizationId) {
    const response = await this.delete(
      `/organizations/related/${organizationId}`,
      {
        headers: authHeader(),
      },
      {
        fullResponse: true,
        errorsRedirect: false,
      }
    );

    if (response.status === 200) {
      const { data } = response;

      return data;
    }

    return response;
  }

  async getReports(organizationId, filter) {
    return this.get(
      `/organizations/${organizationId}/reports`,
      {
        params: filter,
        headers: authHeader(),
      },
      { fullResponse: true }
    );
  }

  async createManualReport(organizationId, data, cancelToken) {
    return this.post(`/organizations/${organizationId}/reports`, data, {
      headers: authHeader(),
      cancelToken,
    });
  }

  updateCustomField(fieldId, data) {
    return this.put(`/organizations/${fieldId}/fields`, data, {
      headers: authHeader(),
    });
  }

  getCustomField(contactId, { page, limit }) {
    const params = {
      page,
      limit,
    };
    return axios.get(`/organizations/${contactId}/fields`, {
      params,
      headers: authHeader(),
    });
  }

  deleteCustomField(contactId, fieldId) {
    return this.delete(`/organizations/${contactId}/fields/${fieldId}`, {
      headers: authHeader(),
    })
      .then((response) => {
        return response.data;
      })
      .catch((error) => error);
  }

  getSubOrganizations(
    parentOrganizationId,
    search = null,
    page = 1,
    limit = 5
  ) {
    return this.get(`/subOrganizations`, {
      headers: authHeader(),
      params: {
        parentOrganizationId,
        page,
        limit,
        ...(search ? { search } : {}),
      },
    });
  }

  async getSubOrganizationReports(subOrganizationId, filter) {
    return this.get(
      `/subOrganizations/${subOrganizationId}/reports`,
      {
        params: filter,
        headers: authHeader(),
      },
      { fullResponse: true }
    );
  }

  async createManualReportSubOrg(subOrganizationId, data, cancelToken) {
    return this.post(`/subOrganizations/${subOrganizationId}/reports`, data, {
      headers: authHeader(),
      cancelToken,
    });
  }
}

export default new OrganizationService();
