import { Card, ProgressBar } from 'react-bootstrap';
import { FormattedValue } from '../../../utils/Utils';
import React from 'react';
import StatCardHeader from './StatCardHeader';

const StatCardList = ({ item, children }) => {
  return (
    <Card className="home-card h-100">
      <StatCardHeader item={item} />
      <>
        {children ? (
          <Card.Body className="p-0 overflow-x-hidden">{children}</Card.Body>
        ) : (
          <Card.Body className="pt-1 pb-3 px-3 flex-grow-1">
            {item.metrics?.map((metric, index) => (
              <div
                key={metric.value}
                className={`${
                  item?.metrics?.length - 1 === index ? '' : 'border-bottom'
                } py-2_1`}
              >
                <div className="d-flex align-items-center justify-content-between">
                  <p className="mb-0 fs-7">{metric.label}</p>
                  <h5
                    className={`mb-0 ${
                      metric.type === 'percentage'
                        ? 'text-primary'
                        : 'text-black'
                    }`}
                  >
                    <FormattedValue value={metric.value} type={metric.type} />
                  </h5>
                </div>
              </div>
            ))}
            <div className="mt-2 d-flex flex-column gap-2">
              <ProgressBar
                variant={item.progress?.variant}
                now={item.progress?.percentage}
                style={{
                  height: 8,
                  borderRadius: 'var(--borderRadius)',
                }}
              />

              <div className="d-flex align-items-start justify-content-between">
                <p className="mb-0 fs-8 flex-1 text-muted text-left">
                  {item.progress?.labels[0]}
                </p>
                <p className="mb-0 fs-8 flex-1 text-muted text-right">
                  {item.progress?.labels[1]}
                </p>
              </div>
            </div>
          </Card.Body>
        )}
      </>
    </Card>
  );
};

export default StatCardList;
