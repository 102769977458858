import React from 'react';
import {
  Pagination as BPagination,
  PaginationItem,
  PaginationLink,
} from 'reactstrap';
import { v4 as uuidv4 } from 'uuid';
import SelectDefault from './commons/SelectDefault';
import MaterialIcon from './commons/MaterialIcon';
import { numbersWithComma } from '../utils/Utils';
import { paginationOptions } from '../utils/constants';
import Skeleton from 'react-loading-skeleton';

const PagesItem = ({ currentPage, page, onPageChange }) => {
  return (
    <PaginationItem key={currentPage} active={page === currentPage}>
      <PaginationLink
        onClick={() => {
          onPageChange(currentPage);
        }}
      >
        {currentPage}
      </PaginationLink>
    </PaginationItem>
  );
};

/**
 * Pagination
 * @param {paginationInfo} paginationInfo
 */
const Pagination = ({
  paginationInfo,
  onPageChange,
  numberedPagination = false,
  showPerPage = false,
  showPages = true,
  onLimitChange,
  loading = false,
}) => {
  const {
    page = 1,
    limit = 25,
    totalPages = 1,
    maxItem = 5,
  } = paginationInfo || {};

  const dividerPag = maxItem / 2;
  const fixTotalPages = Math.round(totalPages);

  const startPaginations =
    fixTotalPages > maxItem && page >= dividerPag
      ? dividerPag - 1
      : page < dividerPag
      ? 1
      : fixTotalPages;

  const startToendValidation =
    page + maxItem < fixTotalPages ? page + startPaginations : fixTotalPages;

  const endPaginationValid = page > dividerPag ? startToendValidation : maxItem;

  const endPagination =
    fixTotalPages > maxItem ? endPaginationValid : fixTotalPages;

  const totalPagesArray = [...Array(Math.round(endPagination)).keys()];

  const renderPaginationitems = () =>
    totalPagesArray?.map((item) => {
      const currentPage = item + 1;

      const dividerPag = maxItem / 2;

      if (page <= dividerPag && currentPage <= maxItem) {
        return (
          <PagesItem
            key={uuidv4()}
            currentPage={currentPage}
            page={page}
            onPageChange={onPageChange}
          />
        );
      } else if (
        page > dividerPag &&
        page <= totalPages - dividerPag &&
        currentPage > page - dividerPag &&
        currentPage < page + dividerPag
      ) {
        return (
          <PagesItem
            key={uuidv4()}
            currentPage={currentPage}
            page={page}
            onPageChange={onPageChange}
          />
        );
      } else if (
        page > totalPages - dividerPag &&
        currentPage > totalPages - maxItem
      ) {
        return (
          <PagesItem
            key={uuidv4()}
            currentPage={currentPage}
            page={page}
            onPageChange={onPageChange}
          />
        );
      }
      return [];
    });

  return (
    <>
      {loading ? (
        <div className="d-flex justify-content-end table-pagination-loading">
          <Skeleton width={120} height={10} />
        </div>
      ) : (
        <BPagination aria-label="Page navigation example">
          {showPerPage && (
            <div className="d-flex font-size-sm2 align-items-center mr-2 gap-2 position-relative">
              <label
                htmlFor="pagination-dropdown"
                className="mb-0 d-block text-nowrap flex-1 fs-7"
              >
                Records per page
              </label>
              <SelectDefault
                id="pagination-dropdown"
                value={limit}
                placeholder=""
                onChange={(e) => onLimitChange(Number(e.target.value))}
                items={paginationOptions}
                style={{ width: '70px' }}
              />
            </div>
          )}

          {showPages && (
            <div className="d-flex align-items-center fs-7">
              <PaginationItem disabled={page === 1}>
                <PaginationLink
                  onClick={() => {
                    onPageChange(page - 1);
                  }}
                >
                  {numberedPagination ? (
                    page !== 1 ? (
                      'Prev'
                    ) : null
                  ) : (
                    <MaterialIcon
                      icon="chevron_left"
                      size="fs-5"
                      clazz="d-flex align-items-center justify-content-center"
                    />
                  )}
                </PaginationLink>
              </PaginationItem>

              {numberedPagination ? (
                renderPaginationitems()
              ) : (
                <div className="d-flex align-items-center px-1 fs-7">
                  {`${page} of ${numbersWithComma(totalPages || 1)}`}
                </div>
              )}

              <PaginationItem
                disabled={page === totalPages || totalPages === 0}
              >
                <PaginationLink
                  onClick={() => {
                    onPageChange(page + 1);
                  }}
                >
                  {numberedPagination ? (
                    page < totalPages ? (
                      'Next'
                    ) : null
                  ) : (
                    <MaterialIcon
                      icon="chevron_right"
                      size="fs-5"
                      clazz="d-flex align-items-center justify-content-center"
                    />
                  )}
                </PaginationLink>
              </PaginationItem>
            </div>
          )}
        </BPagination>
      )}
    </>
  );
};

export default Pagination;
