import React, { useState } from 'react';
import Skeleton from 'react-loading-skeleton';

const ArchiveItemSkeleton = ({ count }) => {
  const [lookupCount] = useState(Array(count).fill(0));
  return (
    <>
      {lookupCount.map((_, index) => (
        <div
          className="cursor-pointer d-flex flex-column rounded p-2 knowledge-item"
          key={index}
        >
          <div className="d-flex align-items-start flex-1">
            <Skeleton circle={true} width={16} height={16} />
          </div>
          <div className="flex-1 px-2 align-content-end">
            <h6>
              <Skeleton width="100%" height={10} />{' '}
            </h6>
          </div>
        </div>
      ))}
    </>
  );
};

export default ArchiveItemSkeleton;
