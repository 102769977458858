import React, { useContext, useState } from 'react';
import { Card, CardBody } from 'reactstrap';
import { isModuleAllowed, isPermissionAllowed } from '../../../utils/Utils';
import PageTitle from '../../commons/PageTitle';
import SideBarStats from './SideBarStats';
import PillFilters from '../../commons/PillFilters';
import { TenantContext } from '../../../contexts/TenantContext';
import MostRecents from './MostRecents';

const filterStatuses = [
  {
    id: 'My',
    label: 'My Overview',
  },
  {
    id: 'All',
    label: 'Full Overview',
  },
];

const filterStatusesMostRecent = [
  {
    id: 'companies',
    label: 'Companies',
  },
  {
    id: 'contacts',
    label: 'Contacts',
  },
];

const OtherHome = () => {
  const { tenant } = useContext(TenantContext);
  const [selectedFilter, setSelectedFilter] = useState(filterStatuses[0]);
  const [selectedFilterRecent, setSelectedFilterRecent] = useState(
    filterStatusesMostRecent[0]
  );

  const isCompaniesAllowed =
    isModuleAllowed(tenant.modules, 'companies') &&
    isPermissionAllowed('organizations', 'view');

  const onFilterChange = (fil) => {
    setSelectedFilter(fil);
  };

  const onFilterChangeRecent = (fil) => {
    setSelectedFilterRecent(fil);
  };
  return (
    <>
      <PageTitle page="Home" />
      <div className="row mx-auto">
        <div className="col-lg-5 px-0">
          <Card className="shadow-0 h-100">
            <CardBody className="rounded-lg h-100 p-3">
              <div className="pb-3 d-flex justify-content-between">
                <h5 className="font-weight-semi-bold fs-6 mb-0 card-title d-flex align-items-center gap-1">
                  Overview
                </h5>
                <PillFilters
                  items={filterStatuses}
                  itemWidth={120}
                  currentFilter={selectedFilter}
                  onFilterChange={onFilterChange}
                  className="w-max-content"
                />
              </div>
              <SideBarStats selectedFilter={selectedFilter} />
            </CardBody>
          </Card>
        </div>
        <div className="col-lg-7 pr-0">
          <Card className="shadow-0 h-100">
            <CardBody className="px-0 h-100 pt-3 pb-0">
              <div className="px-3 pb-3 d-flex justify-content-between">
                <h5 className="font-weight-semi-bold fs-6 mb-0 card-title d-flex align-items-center gap-1">
                  Most Recent
                </h5>
                <PillFilters
                  items={filterStatusesMostRecent}
                  currentFilter={selectedFilterRecent}
                  onFilterChange={onFilterChangeRecent}
                  className="w-max-content"
                />
              </div>
              {isCompaniesAllowed && (
                <MostRecents
                  selectedFilter={selectedFilter}
                  selectedFilterRecent={selectedFilterRecent}
                />
              )}
            </CardBody>
          </Card>
        </div>
      </div>
    </>
  );
};

export default OtherHome;
