export const monthOptions = [
  { key: '2025-01', name: 'January 2025' },
  { key: '2025-02', name: 'February 2025' },
  { key: '2025-03', name: 'March 2025' },
];

export const contentFilterOptions = [
  { key: 'all', name: 'All Content Access' },
  { key: 'nacha_rules', name: 'Nacha Rules' },
  { key: 'api_docs', name: 'API Docs' },
  { key: 'billing_info', name: 'Billing Info' },
];

// Mock data for tenant metrics
export const metricData = [
  {
    iconName: 'MessagesSquare',
    title: 'Total Conversations',
    value: '8',
    class: 'total-conversations',
  },
  {
    iconName: 'ThumbsUp',
    title: 'Positive Feedback',
    value: '87%',
    class: 'positive-feedback-rate',
  },
  {
    iconName: 'ThumbsDown',
    title: 'Negative Feedback',
    value: '255',
    class: 'negative-feedback-count',
  },
  {
    iconName: 'ChartLine',
    title: 'Avg. Daily Conversations',
    value: '17',
    class: 'avg-daily-conversations',
  },
];

export const columns = [
  {
    key: 'date',
    component: 'Date',
    orderBy: 'date',
    classes: 'text-left',
  },
  {
    key: 'tenant',
    component: 'Tenant',
    orderBy: 'tenant',
    classes: 'text-left',
  },
  {
    key: 'feedback',
    component: 'Feedback',
    orderBy: 'feedback',
  },
  {
    key: 'conversation',
    component: 'Conversation',
    orderBy: 'conversation',
    classes: 'text-left',
  },
  {
    key: 'knowledge',
    component: 'Knowledge',
    orderBy: 'knowledge',
    classes: 'text-left',
  },
];

export const rawData = [
  {
    id: 1,
    date: '01-15-25',
    tenant: 'ABC Company',
    feedback: 'Negative',
    conversation: 'ODFI liability for unauthorized entries',
    knowledge: 'Nacha Rule Book',
    percentage: 20,
  },
  {
    id: 2,
    date: '01-15-25',
    tenant: 'XYZ Organization',
    feedback: 'Positive',
    conversation: 'Standard Entry Class (SEC) code for WEB',
    knowledge: 'Nacha Rule Book',
    percentage: 80,
  },
  {
    id: 3,
    date: '01-14-25',
    tenant: 'Innovate Inc.',
    feedback: 'Positive',
    conversation: 'Return code R07 explanation (Authorization Revoked)',
    knowledge: 'Nacha Rule Book',
    percentage: 60,
  },
  {
    id: 4,
    date: '01-14-25',
    tenant: 'Global Tech',
    feedback: 'Negative',
    conversation: 'File header record format details incorrect',
    knowledge: 'Nacha Rule Book',
    percentage: 60,
  },
  {
    id: 5,
    date: '01-13-25',
    tenant: 'Beta Solutions',
    feedback: 'Positive',
    conversation: 'RDFI responsibilities for posting entries',
    knowledge: 'Nacha Rule Book',
    percentage: 60,
  },
  {
    id: 6,
    date: '01-13-25',
    tenant: 'Acme Corp',
    feedback: 'Positive',
    conversation: 'Prenotification entry requirements',
    knowledge: 'Nacha Rule Book',
    percentage: 20,
  },
  {
    id: 7,
    date: '01-10-25',
    tenant: 'Synergy Systems',
    feedback: 'Negative',
    conversation:
      'Timeframe for returning R10 (Customer Advises Not Authorized)',
    knowledge: 'Nacha Rule Book',
    percentage: 15,
  },
  {
    id: 8,
    date: '01-10-25',
    tenant: 'Quantum Leap Co.',
    feedback: 'Positive',
    conversation: 'Difference between PPD and CCD entries',
    knowledge: 'Nacha Rule Book',
    percentage: 29,
  },
  {
    id: 9,
    date: '01-09-25',
    tenant: 'Momentum Machines',
    feedback: 'Positive',
    conversation: 'Batch control record specifications',
    knowledge: 'Nacha Rule Book',
    percentage: 70,
  },
  {
    id: 10,
    date: '01-08-25',
    tenant: 'Stellar Solutions',
    feedback: 'Negative',
    conversation: 'Dishonored return rules explanation confusing',
    knowledge: 'Nacha Rule Book',
    percentage: 60,
  },
];
