import React from 'react';
import { icons } from 'lucide-react';

export default function LucideIcon({
  filled,
  symbols,
  rounded = true,
  twoTone,
  useLucid = true,
  clazz,
  icon,
  color,
  onClick,
  size = 16,
  ...rest
}) {
  const Icon = icons[icon];

  if (!Icon) {
    console.info(`=-= Icon "${icon}" does not exist in lucide-react.`);
    return null;
  }

  return (
    <Icon
      className={clazz}
      color={color}
      size={size}
      onClick={onClick}
      {...rest}
    />
  );
}
