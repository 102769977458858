import { isLucidIcon } from '../../commons/IconDropdownSearch';
import MaterialIcon from '../../commons/MaterialIcon';
import React from 'react';

const IconHeadingBlock = ({
  icon,
  heading,
  showIcon,
  children,
  iconSize = { width: 28, height: 28 },
  iconStyle = { back: '#A3A4AF', fore: 'text-white' },
  containerStyle = 'gap-1 px-3',
}) => {
  return (
    <div className={`d-flex align-items-center ${containerStyle}`}>
      <div className="d-flex w-100 align-items-center gap-1">
        {showIcon && (
          <div
            className={`rounded-circle d-flex align-items-center justify-content-center text-center`}
            style={{ background: iconStyle.back, ...iconSize }}
          >
            <MaterialIcon
              icon={icon}
              isLucide={isLucidIcon(icon)}
              clazz={iconStyle.fore}
            />
          </div>
        )}
        <h3 className="text-center mb-0">
          {heading === 'Fee Summary' ? 'Fees' : heading}
        </h3>
        {children}
      </div>
    </div>
  );
};

export default IconHeadingBlock;
