import React from 'react';
import RocketReachViewInfoCard from './RocketReachViewInfoCard';
import RocketReachSocialLinks from './RocketReachSocialLinks';
import ProfilePicOrFallbackAvatar from '../../commons/ProfilePicOrFallbackAvatar';
import ProfileIcon from '../../commons/ProfileIcon';
import { getKeysWithData } from '../../../utils/Utils';
import { useFilterProspectContext } from '../../../contexts/filterProspectContext';
import MaterialIcon from '../../commons/MaterialIcon';
import { ProspectTypes } from '../../prospecting/v2/constants';
import TextOverflowTooltip from '../../commons/TextOverflowTooltip';
import LinkWrapper from '../../modal/LinkWrapper';

// withCompany: will show company if true
// withLocation: will show location if true
// Same component is being used from right prospect lookup under org/people profile and Find prospects table
const RocketReachPeopleCard = ({
  prospect = {},
  showSocialLinks = true,
  withCompany = true,
  withLocation = true,
  withContactInfo = false,
  withDomain = true,
  showTicker = true,
  showFoundedYear = true,
  avatarStyle = { width: 80, height: 80 },
  isCompanyProfile = false,
  containerStyle = 'align-items-center',
  chargeFilter = () => {},
  refreshView = () => {},
  setInfoLoading = () => {},
  setCompanyDetailPage,
}) => {
  const { globalFiltersCompany, setGlobalFiltersCompany } =
    useFilterProspectContext();

  const Logo = () => {
    return (
      <>
        {prospect.profile_pic || prospect.logo_url ? (
          <ProfilePicOrFallbackAvatar prospect={prospect} style={avatarStyle} />
        ) : (
          <ProfileIcon
            prospect={prospect}
            defaultSize={avatarStyle.width === 80 ? 'xl' : 'lg'}
            sizeIcon={avatarStyle.width === 80 ? 'fs-2' : 'fs-1'}
          />
        )}
      </>
    );
  };

  const handlePage = () => {
    if (isCompanyProfile) {
      setCompanyDetailPage((prevState) => ({
        ...prevState,
        show: true,
        id: prospect.id,
        swot: false,
        tab: ProspectTypes.company,
        name: encodeURI(prospect?.full_name),
        ticker: prospect.ticker?.trim(),
        ...prospect,
      }));
    }
  };

  return (
    <div className={`d-flex ${containerStyle}`}>
      <div className="mr-3" onClick={handlePage}>
        <Logo />
      </div>
      <div className="text-left d-flex flex-column justify-content-center flex-grow-1">
        <p className="prospect-typography-h4 p-0 mb-0 text-wrap mw-fix-150 font-weight-semi-bold">
          {isCompanyProfile ? (
            <>
              <a
                className="prospect-typography-h6 text-black"
                onClick={handlePage}
              >
                {prospect?.full_name}
              </a>
              {((showTicker && prospect.ticker) ||
                (showFoundedYear && prospect.founded)) && (
                <div className="d-flex align-items-center gap-1">
                  {showTicker && prospect.ticker && (
                    <span className="font-weight-semi-bold d-flex align-items-center text-black-50 font-size-xs">
                      <MaterialIcon size="fs-7" icon="area_chart" />
                      <span>{prospect.ticker?.trim()}</span>
                    </span>
                  )}
                  {showFoundedYear && prospect.founded && (
                    <span className="font-weight-medium d-flex align-items-center text-black-50 font-size-xs">
                      <MaterialIcon size="fs-7" icon="flag" filled />
                      <span>{prospect.founded}</span>
                    </span>
                  )}
                </div>
              )}
              {withDomain && (
                <LinkWrapper
                  href={`https://${prospect.email_domain}`}
                  className="d-block text-hover-blue"
                >
                  <span className="font-weight-normal text-black text-hover-blue font-size-sm2">
                    {prospect.email_domain}
                  </span>
                </LinkWrapper>
              )}
              {prospect.industry_str && (
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  className="d-block text-hover-blue"
                  onClick={(e) => {
                    e.preventDefault();
                    const industryFilter = {
                      ...globalFiltersCompany,
                      industry: { industry: [prospect.industry_str] },
                    };
                    setGlobalFiltersCompany(industryFilter);
                    chargeFilter(getKeysWithData(industryFilter));
                    refreshView((prevState) => prevState + 1);
                  }}
                >
                  <TextOverflowTooltip
                    capitalizeText={false}
                    text={prospect.industry_str}
                    textStyle="font-weight-normal text-nowrap prospect-typography-h6 text-black text-hover-blue mb-0"
                  />
                </a>
              )}
            </>
          ) : (
            <TextOverflowTooltip
              capitalizeText={false}
              text={prospect?.full_name}
              textStyle="font-weight-semi-bold prospect-typography-h6 text-black text-hover-blue mb-0"
            />
          )}
        </p>
        {prospect.title && prospect.title !== 'None' && (
          <span style={{ maxWidth: 200 }}>
            <TextOverflowTooltip
              capitalizeText={false}
              text={prospect.title}
              textStyle="prospect-typography-h6 p-0 mb-1 fs-7 text-wrap text-truncate text-gray-900"
            />
          </span>
        )}
        {withLocation && prospect.location ? (
          <p className="prospect-typography-h6 d-flex align-items-center p-0 mb-1">
            <span className="material-symbols-rounded fs-7 bg-gray-300 p-1 rounded-circle text-black mr-1">
              location_on
            </span>
            <>
              {prospect.location ? (
                <span className="fs-7">{prospect.location}</span>
              ) : (
                <span className="fs-7">
                  {prospect.city && <span>{prospect.city}</span>}
                  {prospect.state && <span>, {prospect.state}</span>}{' '}
                </span>
              )}
            </>
          </p>
        ) : (
          ''
        )}
        {withCompany &&
        prospect.employer &&
        prospect.employer !== 'None' &&
        prospect.employer.toLowerCase() !== 'undefined' ? (
          <div className="prospect-typography-h6 d-flex align-items-center p-0 m-0 text-wrap">
            <div className="p-1 bg-gray-300 d-flex rounded-circle text-black mr-1">
              <MaterialIcon icon="corporate_fare" size="fs-7" clazz="d-flex" />
            </div>
            <span className="fs-7 text-gray-900">{prospect.employer}</span>
          </div>
        ) : (
          ''
        )}
        {showSocialLinks && (
          <div className="mt-1">
            <RocketReachSocialLinks
              links={{
                linkedin: prospect?.linkedin_url,
                twitter: prospect?.twitter_url,
                facebook: prospect?.facebook_url,
                crunchbase: prospect?.links?.crunchbase,
              }}
            />
          </div>
        )}
        {withContactInfo && (
          <RocketReachViewInfoCard
            prospect={prospect}
            layout="row"
            load={withContactInfo}
            setInfoLoading={setInfoLoading}
          />
        )}
      </div>
    </div>
  );
};

export default RocketReachPeopleCard;
