import axios from './axiosInstance';
import authHeader from './auth-header';
import BaseRequestService from './baseRequest.service';

class Labels extends BaseRequestService {
  async getLabels(type) {
    return axios
      .get(`/labels`, {
        params: { type },
        headers: authHeader(),
      })
      .then((response) => response.data);
  }

  async createLabel(data) {
    return axios
      .post(`/labels`, data, {
        headers: authHeader(),
      })
      .then((response) => response.data);
  }

  async editLabel(label_id, data) {
    return this.put(
      `/labels/${label_id}`,
      data,
      {
        headers: authHeader(),
      },
      { fullResponse: false, errorsRedirect: true }
    );
  }

  async removeLabel(label_id) {
    return axios
      .delete(`/labels/${label_id}`, {
        headers: authHeader(),
      })
      .then((response) => response.data);
  }
}

export default new Labels();
