import { useEffect, useState } from 'react';
import { Card, CardBody, CardFooter, CardHeader } from 'reactstrap';
import GenericTable from '../../../../components/GenericTable';
import { cubeService } from '../../../../services';
import Loading from '../../../../components/Loading';
import assignmentService from '../../../../services/assignment.service';
import { AssignmentSnapshotWidget } from '../AssignmentSnapshotWidget';
import { paginationDefault } from '../../../../utils/constants';
import { ShortDescription } from '../../../../components/ShortDescription';
import SingleSelectionDropdown from '../../../../components/commons/SingleSelectionDropdown';
import moment from 'moment';
import { DATE_FORMAT } from '../../../../utils/Utils';
import { NoUserSelected } from '../../components/queries/NoUserSelected';
import { TrainingAssignmentDetailsColumns } from '../../../../utils/learn.constants';
import IdfTooltip from '../../../../components/idfComponents/idfTooltip';
import Pagination from '../../../../components/Pagination';
import {
  changePaginationLimit,
  changePaginationPage,
} from '../../../Deals/contacts/utils';

const AssignmentStatsNames = {
  assignmentCompleted: 'Completed',
  assignmentInProgress: 'In Progress',
  assignmentNotStarted: 'Not Started',
  assignmentOverDue: 'Overdue',
  peopleAssigned: 'Users Assigned',
  totalLessonCount: 'Number of Lessons',
};

const assignmentStatsTooltip = {
  Completed: 'Users who have completed the assignment',
  'In Progress': 'Users who have completed some but not all of the assignment',
  'Not Started': 'Users who have not started the assignment',
  Overdue: 'Users who have not completed this assignment by the due date.',
  'Users Assigned': '# of users required to complete the assignment',
  'Number of Lessons': 'total # of lessons included in the assignment',
};

export const TrainingAssignmentDetails = ({ insightName }) => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedItemAppear, setSelectedItemAppear] = useState({});
  const [showCSVBtn, setShowCSVBtn] = useState(false);
  const [show, setShow] = useState(false);
  const [selectItem, setSelectedItem] = useState({});
  const [queryFilter, setQueryFilter] = useState({ value: [] });
  const [allAssignments, setAllAssignments] = useState([]);
  const [querySearch, setQuerySearch] = useState('');

  const [pagination, setPagination] = useState(paginationDefault);
  const [paginationPage, setPaginationPage] = useState(paginationDefault);

  const getAssignment = async () => {
    const { data } = await assignmentService.getAssignments({
      page: 1,
      limit: 1000,
      search: querySearch,
    });
    const updatedData = data?.map((item) => ({
      ...item,
      name: item?.title,
    }));
    setAllAssignments(updatedData);
  };

  useEffect(() => {
    getAssignment();
  }, [querySearch]);

  const handleOptionSelect = (item) => {
    if (Object.keys(item)?.length === 0) {
      setQueryFilter({ value: [] });
      setSelectedItem({});
      setSelectedItemAppear({});
    } else {
      setQueryFilter({ value: [item?.assignmentId] });
    }
    toggleModify();
  };

  const toggleModify = () => {
    setPaginationPage((prev) => ({ ...prev, page: 1 }));
  };

  const getData = async () => {
    setLoading(true);
    const results = await cubeService.loadCustomAnalytics({
      type: 'AssignmentDetails',
      limit: paginationPage?.limit,
      page: paginationPage?.page,
      ...(queryFilter?.value?.length > 0
        ? { assignments: queryFilter?.value }
        : {}),
    });
    setData(results);

    setShowCSVBtn(results?.length > 0);
    setLoading(false);
    setPagination(paginationPage); // Todo:- will update with response pagination when API's will change
  };

  useEffect(() => {
    if (queryFilter?.value?.length > 0) {
      getData();
    }
  }, [paginationPage]);

  const sortedData = data?.sort((a, b) => {
    if (a?.assignmentprogress === null && b?.assignmentprogress === null) {
      return 0;
    }
    if (a?.assignmentprogress === null) {
      return -1;
    }
    if (b?.assignmentprogress === null) {
      return 1;
    }
    return a.assignmentprogress - b.assignmentprogress;
  });

  const rows = sortedData?.map((result, index) => {
    const name = `${result?.first_name !== null ? result?.first_name : '--'} ${
      result?.last_name !== null ? result?.last_name : ''
    }`;
    return {
      id: index,
      dataRow: [
        {
          key: 'users',
          component: <span className="fw-bold">{name}</span>,
        },
        {
          key: 'progress',
          component:
            `${
              result?.assignmentprogress !== null
                ? `${result?.assignmentprogress}%`
                : '--'
            }` || '--',
        },
        {
          key: 'team',
          component:
            result?.team !== null ? (
              <ShortDescription limit={15} content={result?.team} />
            ) : (
              '--'
            ),
        },
        {
          key: 'last activity',
          component:
            `${
              result?.date_last_attempted !== null
                ? result?.date_last_attempted
                : '--'
            }` || '--',
        },
      ],
    };
  });

  const updatedRows = sortedData?.map((item) => {
    return {
      users: `${item?.first_name !== null ? item?.first_name : '--'} ${
        item?.last_name !== null ? item?.last_name : ''
      }`,
      progress:
        item?.assignmentprogress !== null ? item?.assignmentprogress : '--',
      completed: `${
        item?.assignmentprogress !== null
          ? `${item?.assignmentprogress}%`
          : '--'
      }`,
      team: `${item?.team !== null ? item?.team : '--'}`,
      lastActivity: `${
        item?.date_last_attempted !== null ? item?.date_last_attempted : '--'
      }`,
    };
  });

  return (
    <>
      <Card className="h-100 border-0 shadow-0">
        <CardHeader>
          <div className="d-flex justify-content-between align-items-center w-100">
            <IdfTooltip text={insightName} placement="bottom">
              <h4 className="card-title text-hover-primary mb-0 text-truncate">
                {insightName}
              </h4>
            </IdfTooltip>

            <div
              className={`d-flex align-items-center gap-2 ${
                showCSVBtn ? 'mr-6 pr-9' : ''
              }`}
            >
              <SingleSelectionDropdown
                data={allAssignments}
                show={show}
                label={`Assignment${queryFilter?.length > 0 ? 's' : ''}`}
                customStyle={{ width: '250px' }}
                scrollable="pr-3 text-left"
                setShow={setShow}
                showCheckAll={false}
                search={true}
                maxLength={22}
                customKey={['title', 'assignmentId']}
                handleOptionSelected={handleOptionSelect}
                selectedData={selectItem}
                searchQuery={querySearch}
                setSearchQuery={setQuerySearch}
                setSelectedData={setSelectedItem}
                selectedItemDetailsShow={true}
                setItemDetailsAppear={setSelectedItemAppear}
              />
            </div>
          </div>
        </CardHeader>

        <>
          {queryFilter?.value?.length > 0 ? (
            loading ? (
              <Loading />
            ) : (
              <>
                <CardBody className="p-0 flex-1 overflow-y-auto">
                  <div className="d-flex flex-column">
                    {selectedItemAppear?.name && (
                      <div className="pt-3 pl-4">
                        <h4
                          className={`card-title d-flex align-items-center gap-1 text-hover-primary font-weight-medium font-size-sm2`}
                        >
                          {' '}
                          Assignment Name:{' '}
                          <span className="fw-bold font-size-sm">
                            {selectedItemAppear?.name}
                          </span>
                        </h4>
                        <h4
                          className={`card-title d-flex align-items-center gap-1 text-hover-primary font-weight-medium font-size-sm2`}
                        >
                          Due Date:
                          <span className="fw-bold font-size-sm">
                            {moment(selectedItemAppear?.dueAt).format(
                              DATE_FORMAT
                            )}
                          </span>
                        </h4>
                      </div>
                    )}

                    <AssignmentSnapshotWidget
                      insightName=""
                      queryFilter={queryFilter?.value}
                      allSelectedData={allAssignments}
                      param="assignments"
                      type="AssignmentDetailStats"
                      assignmentStatsTooltip={assignmentStatsTooltip}
                      assignmentStatsNames={AssignmentStatsNames}
                    />
                  </div>

                  <div className="mt-2">
                    <GenericTable
                      checkbox={false}
                      data={rows}
                      exportToCSV={showCSVBtn}
                      tableData={updatedRows}
                      fileName="AssignmentDetails"
                      columns={TrainingAssignmentDetailsColumns}
                      usePagination={false}
                      noDataInDbValidation={true}
                      externalValues={
                        Object.keys(selectItem)?.length > 0
                          ? [
                              { 'Assignment Name': selectItem?.name },
                              {
                                'Due Date': moment(selectItem?.dueAt).format(
                                  DATE_FORMAT
                                ),
                              },
                            ]
                          : []
                      }
                    />
                  </div>
                </CardBody>

                <CardFooter className="border-top bg-gray-table-head p-2 border-bottom-0 border-left-0 border-right-0 rounded-0">
                  <div className="d-flex align-items-center justify-content-end">
                    <Pagination
                      showPerPage
                      showPages={false}
                      paginationInfo={pagination}
                      onPageChange={(newPage) => {
                        changePaginationPage(newPage, setPaginationPage);
                      }}
                      onLimitChange={(perPage) => {
                        changePaginationLimit(perPage, setPaginationPage);
                      }}
                    />
                  </div>
                </CardFooter>
              </>
            )
          ) : (
            <NoUserSelected icon="school" label="Assignment" />
          )}
        </>
      </Card>
    </>
  );
};
