import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { overflowing } from '../../../utils/Utils';
import routes from '../../../utils/routes.json';
import SimpleModalCreation from '../../../components/modal/SimpleModalCreation';
import NewKindOfSearchDropdown from '../../../components/commons/NewKindOfSearchDropdown';
import organizationService from '../../../services/organization.service';
import useIsTenant from '../../../hooks/useIsTenant';
import { useDebounce } from '../../../hooks/useDebounce';

const OrganizationsModal = ({ show, handleClose, report }) => {
  const history = useHistory();
  const { isSynovusBank } = useIsTenant();
  const [data, setData] = useState([]);
  const [searchQuery, setSearchQuery] = useState({ search: '' });
  const [selected, setSelected] = useState();
  const [loading, setLoading] = useState(false);

  const onHandleCloseModal = () => {
    if (!loading) {
      overflowing();
      handleClose();
      setSelected();
    }
  };

  const onInputSearch = (e) => {
    const { value } = e.target || {};
    setSearchQuery({
      ...searchQuery,
      search: value,
    });
  };

  const handleSelected = (item) => {
    setSelected(item);
  };

  const getOrganizations = async () => {
    setLoading(true);

    const pagination = { page: 1, limit: 5 };
    const filter = { ...searchQuery };

    try {
      const response = await organizationService.getOrganizations(
        { ...filter },
        pagination
      );
      setData(response.data?.data);
    } catch (error) {
      console.log('getting error while fetching organizations: ', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getOrganizations();
  }, []);

  const debouncedSearchTerm = useDebounce(searchQuery.search, 500);

  useEffect(() => {
    if (debouncedSearchTerm && debouncedSearchTerm.length >= 2) {
      getOrganizations();
    }
  }, [debouncedSearchTerm, searchQuery]);

  const handleUpdate = async () => {
    if (!selected) return;

    history.push(
      isSynovusBank
        ? `${routes.insightsCompanies}/${selected.id}/organization/profile#reports/${report?.key}/new`
        : `${routes.companies}/${selected.id}/organization/profile#reports/${report?.key}/new`
    );
  };

  if (!show) return;

  return (
    <>
      <SimpleModalCreation
        modalHeaderClasses="flex-fill"
        modalTitle={`Search ${isSynovusBank ? 'Insights' : 'Companies'}`}
        saveButtonStyle="btn btn-primary btn-sm d-flex align-items-center"
        bodyClassName="p-0 m-0"
        open={show}
        footerStyle="border-0"
        saveButton="Select"
        buttonsDisabled={!selected}
        handleSubmit={handleUpdate}
        onHandleCloseModal={onHandleCloseModal}
      >
        <div className="px-20 pt-4 pb-6">
          <NewKindOfSearchDropdown
            readOnly={true}
            preData={data}
            placeholder={`Search ${isSynovusBank ? 'Insights' : 'Companies'}`}
            onItemSelect={handleSelected}
            onInputSearch={onInputSearch}
          />
        </div>
      </SimpleModalCreation>
    </>
  );
};

export default OrganizationsModal;
