import React, { useState } from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

import RightPanelModal from '../../modal/RightPanelModal';
import MaterialIcon from '../../commons/MaterialIcon';
import {
  getRRCompanyAddress,
  MaskingPromptKeys,
  PROSPECT_RIGHT_PANEL_WIDTH,
} from '../../../utils/Utils';
import PreCallPlan from './PreCallPlan';
import IdfTooltip from '../../idfComponents/idfTooltip';
import TopicIcon from '../../commons/TopicIcon';
import useAskIdaModal from '../../chat/modals/AskIda';
import prospectService from '../../../services/prospect.service';
import Alert from '../../Alert/Alert';
import AlertWrapper from '../../Alert/AlertWrapper';
import { Spinner } from 'reactstrap';
import { useIdaContext } from '../../../contexts/IdaContext';

const getLocation = (apiOrgInfo, rrOrgInfo) => {
  if (apiOrgInfo?.address_city && apiOrgInfo?.address_state) {
    return `${apiOrgInfo?.address_city}, ${apiOrgInfo?.address_state}`;
  }

  return getRRCompanyAddress(rrOrgInfo);
};
const GeneratePreCallPlanModel = ({ open, setOpen, profileInfo }) => {
  return (
    <RightPanelModal
      showModal={open}
      setShowModal={setOpen}
      containerWidth={PROSPECT_RIGHT_PANEL_WIDTH}
      Title={
        <div className="d-flex py-2 gap-2 align-items-center">
          <div className="nav-item scroll-item">
            <div className={`nav-icon p-1 commercial-card cursor-pointer`}>
              <TopicIcon
                icon="text_snippet"
                iconBg="commercial-card"
                filled={true}
                iconSize="fs-5"
              />
            </div>
          </div>
          <h4 className="mb-0 d-flex align-items-center gap-1">
            Company Snapshot{' '}
            <IdfTooltip text="Auto Generated by Identifee Digital Assistant (IDA)">
              <MaterialIcon icon="auto_awesome" />
            </IdfTooltip>{' '}
          </h4>
        </div>
      }
    >
      <PreCallPlan profileInfo={profileInfo} />
    </RightPanelModal>
  );
};

const PreCallPlanPanel = ({ profileInfo, askIda }) => {
  const [openPanel, setOpenPanel] = useState(false);
  const { AskIdaModal, setShowModal, setCustomPrompt } = useAskIdaModal();
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const { setNewQuestion, setSelectedChat, setSelectedModalChat, setMask } =
    useIdaContext();
  const getCompanyRRInfo = async () => {
    try {
      setLoading(true);
      const query = { name: [profileInfo?.name.replace(/\s*\(\d+\)$/, '')] };
      const response = await prospectService.query(
        { ...query },
        {
          page: 1,
          limit: 1,
          type: 'company',
          order_by: 'relevance',
        }
      );

      const responseData = response?.data;
      let rocketReachCompanyDetails = null;

      if (!responseData.data?.length) {
        setLoading(false);
        setErrorMessage(
          `Unfortunately, we could not gather enough information about ${profileInfo?.name} to generate snapshot.`
        );
        setShowModal(false);
        return;
      }

      if (responseData?.data?.length) {
        setShowModal(true);
        rocketReachCompanyDetails = responseData?.data[0] || {};
        const companyName =
          profileInfo?.name || rocketReachCompanyDetails?.name;
        const companyWebsite =
          profileInfo?.website ||
          rocketReachCompanyDetails?.domain ||
          rocketReachCompanyDetails?.email_domain;
        const companyLocation = getLocation(
          profileInfo,
          rocketReachCompanyDetails
        );
        const companyLocationQuery = companyLocation
          ? ` in ${companyLocation}.`
          : '';
        const companyIndustry =
          rocketReachCompanyDetails?.industry ||
          rocketReachCompanyDetails?.industry_str;
        const companyIndustryQuery = companyIndustry
          ? `Company Industry: '${companyIndustry}'`
          : '';

        const naicsQuery = profileInfo?.naics_code
          ? `NAICS: '${
              profileInfo?.naics_code || rocketReachCompanyDetails.naics
            }'`
          : '';
        const promptQuery = `
        Create a Company Snapshot for ${companyName} ${companyLocationQuery}.
        Use current data (${new Date().getFullYear()}) from their website (${companyWebsite}) and other sources.
        ${companyIndustryQuery} ${naicsQuery}
        Keep response under 500 words with bullet points for each section.
        
        **Company Overview:**
        - Summarize history, operations, revenue (if available)
        - Note geographic presence, leadership, company size
        
        **Service and Competition:**
        - List main products/services offered
        - Identify key competitors and market positioning
        - Describe target customer segments
        
        **Vision and Values:**
        - State company mission and core values
        - Note community involvement initiatives
        
        **Latest News:**
        - Highlight recent developments from company sources
        - Include relevant industry updates affecting business
        
        **Industry Trends:**
        - Outline current industry conditions and emerging trends
        - Note opportunities and challenges in their sector
        - Describe how company is positioned in the market
        
        Format all section titles in bold with a line break after each title. Please cite sources where possible.

        
        `;
        setCustomPrompt({
          text: promptQuery,
        });
        setMask(MaskingPromptKeys.CompanySnapshot);
      } else {
        setErrorMessage(
          `Unfortunately, we could not gather enough information about <b>${profileInfo?.name}</b> to generate snapshot.`
        );
      }
    } catch (e) {
      console.log(e);
    } finally {
      setLoading(false);
    }
  };

  const onLightbulbClick = async () => {
    setNewQuestion({});
    setSelectedChat({});
    setSelectedModalChat({});
    setMask('');
    document.querySelector('.show.tooltip')?.classList.remove('show');
    if (askIda) {
      setShowModal(true);
    } else {
      await getCompanyRRInfo();
    }
  };

  return (
    <>
      <AlertWrapper>
        {successMessage && (
          <Alert
            color="success"
            message={successMessage}
            setMessage={setSuccessMessage}
          />
        )}
        {errorMessage && (
          <Alert
            color="danger"
            message={errorMessage}
            setMessage={setErrorMessage}
          />
        )}
      </AlertWrapper>
      {askIda ? (
        <OverlayTrigger
          key={'askIda'}
          placement="left"
          overlay={
            <Tooltip
              id={`tooltip-niacs`}
              className={`tooltip-profile font-weight-bold`}
            >
              <p>Ask Ida</p>
            </Tooltip>
          }
        >
          <div className="nav-item scroll-item mb-2" onClick={onLightbulbClick}>
            <div
              className={`nav-icon p-1 account-structure forced cursor-pointer`}
            >
              <TopicIcon
                icon="auto_awesome"
                iconBg="account-structure forced"
                filled={false}
                iconSize="fs-5"
              />
            </div>
          </div>
        </OverlayTrigger>
      ) : (
        <OverlayTrigger
          key={'askIdaSnapshot'}
          placement="left"
          overlay={
            <Tooltip
              id={`tooltip-company`}
              className={`tooltip-profile font-weight-bold`}
            >
              <p>Company Snapshot</p>
            </Tooltip>
          }
        >
          <div className="nav-item scroll-item mb-2" onClick={onLightbulbClick}>
            <div
              className={`nav-icon p-1 commercial-card forced cursor-pointer`}
            >
              {loading ? (
                <div className="commercial-card">
                  <i>
                    <Spinner className="spinner-grow-xs" />
                  </i>
                </div>
              ) : (
                <TopicIcon
                  icon="text_snippet"
                  iconBg="commercial-card forced"
                  filled={false}
                  iconSize="fs-5"
                />
              )}
            </div>
          </div>
        </OverlayTrigger>
      )}
      {openPanel && (
        <GeneratePreCallPlanModel
          open={openPanel}
          setOpen={setOpenPanel}
          profileInfo={profileInfo}
        />
      )}
      <AskIdaModal />
    </>
  );
};

export default PreCallPlanPanel;
