export const MerchantMetricsKeys = {
  'transactions.totalGrossAmount': 'TotalGrossVolume',
  'transactions.count': 'TotalTransactions',
  'fees.totalAmount': 'TotalFees',
  'transactions.averageAmount': 'AverageTransactions',
  'fees.averageAmountPerTransaction': 'AverageFee',
  'transactions.authorized.percentageOfTotalTransactions':
    'AuthorizationApprovalPercentage',
  'fees.effectiveRate': 'EffectiveRate',
  'fees.nonBankTotalAmount': 'NonBankFees',
  'fees.component.interchange.percentageOfTotalFeeAmount':
    'InterchangePercentage',
  'fees.component.network.percentageOfTotalFeeAmount': 'NetworkFeesPercentage',
  'fees.component.bank.percentageOfTotalFeeAmount': 'BankFeesPercentage',
  'chargeBacks.percentageOfTotalTransactions': 'ChargebackPercentage',
  'transactions.totalNetAmount': 'NetSales',
  'chargeBacks.totalAmount': 'ChargebackDollars',
  'chargeBacks.averageAmount': 'AverageChargeback',
  'chargeBacks.count': 'ChargebackCount',
};

export const MerchantMetricsAggregationKeys = {
  'average.transactions.totalGrossAmount': 'TotalGrossVolume',
  'average.transactions.count': 'TotalTransactions',
  'average.fees.totalAmount': 'TotalFees',
  'average.transactions.averageAmount': 'AverageTransactions',
  'average.fees.averageAmountPerTransaction': 'AverageFee',
  'average.transactions.authorized.percentageOfTotalTransactions':
    'AuthorizationApprovalPercentage',
  'average.fees.effectiveRate': 'EffectiveRate',
  'average.fees.nonBankTotalAmount': 'NonBankFees',
  'average.fees.component.interchange.percentageOfTotalFeeAmount':
    'InterchangePercentage',
  'average.fees.component.network.percentageOfTotalFeeAmount':
    'NetworkFeesPercentage',
  'average.fees.component.bank.percentageOfTotalFeeAmount':
    'BankFeesPercentage',
  'average.chargeBacks.percentageOfTotalTransactions': 'ChargebackPercentage',
  'average.transactions.totalNetAmount': 'NetSales',
  'average.chargeBacks.totalAmount': 'ChargebackDollars',
  'average.chargeBacks.averageAmount': 'AverageChargeback',
  'average.chargeBacks.count': 'ChargebackCount',
};

export const MerchantMetricsAggregationSVBKeys = {
  'average.transactions.count': 'totalTransactions',
  'average.transactions.averageAmount': 'averageTransaction',
  'average.transactions.totalProcessedAmount': 'totalDollarsProcessed',
  'average.fees.averageAmountPerTransaction': 'averageFee',
  'average.fees.totalAmount': 'totalFees',
  'average.refunds.percentageOfTotalTransactions': 'refundPercentage',
  'average.transactions.authorized.percentageOfTotalTransactions':
    'authorizationToApprovalPercentage',
  'average.fees.effectiveRate': 'effectiveRate',
  'average.fees.nonBankTotalAmount': 'nonBankFeesPercentage',
  'average.fees.component.interchange.percentageOfTotalFeeAmount':
    'interchangeFeesAsAPercentOfTotalFees',
  'average.fees.component.network.percentageOfTotalFeeAmount':
    'networkFeesAsAPercentOfTotalFees',
  'average.fees.component.bank.percentageOfTotalFeeAmount':
    'bankFeesAsAPercentOfTotalFees',
  'average.chargeBacks.paymentNetwork.visa.percentageOfTotalTransactions':
    'visaChargebackPercentage',
  'average.chargeBacks.paymentNetwork.mastercard.percentageOfTotalTransactions':
    'mastercardChargebackPercentage',
  'average.chargeBacks.totalAmount': 'chargebackDollars',
  'average.fees.component.interchange.paymentNetwork.visa.totalAmount':
    'visaInterchangeFees',
  'average.fees.component.interchange.paymentNetwork.mastercard.totalAmount':
    'mcInterchangeFees',
  'average.fees.component.interchange.paymentNetwork.discover.totalAmount':
    'discoverInterchangeFees',
  'average.fees.component.interchange.paymentNetwork.visa.percentageOfTotalInterchangeFeeAmount':
    'visaAverageInterchangeFeePaid',
  'average.fees.component.interchange.paymentNetwork.mastercard.percentageOfTotalInterchangeFeeAmount':
    'mcAverageInterchangeFeePaid',
  'average.fees.component.interchange.paymentNetwork.discover.percentageOfTotalInterchangeFeeAmount':
    'discoverAverageInterchangeFeePaid',
  'average.ccis.count': 'ccisCount',
  'average.chargeBacks.averageAmount': 'averageChargeback',
};

export const MerchantMetricsSVBKeys = {
  'transactions.count': 'totalTransactions',
  'transactions.averageAmount': 'averageTransaction',
  'transactions.totalProcessedAmount': 'totalDollarsProcessed',
  'fees.averageAmountPerTransaction': 'averageFee',
  'fees.totalAmount': 'totalFees',
  'refunds.percentageOfTotalTransactions': 'refundPercentage',
  'transactions.authorized.percentageOfTotalTransactions':
    'authorizationToApprovalPercentage',
  'fees.effectiveRate': 'effectiveRate',
  'fees.nonBankTotalAmount': 'nonBankFeesPercentage',
  'fees.component.interchange.percentageOfTotalFeeAmount':
    'interchangeFeesAsAPercentOfTotalFees',
  'fees.component.network.percentageOfTotalFeeAmount':
    'networkFeesAsAPercentOfTotalFees',
  'fees.component.bank.percentageOfTotalFeeAmount':
    'bankFeesAsAPercentOfTotalFees',
  'chargeBacks.paymentNetwork.visa.percentageOfTotalTransactions':
    'visaChargebackPercentage',
  'chargeBacks.paymentNetwork.mastercard.percentageOfTotalTransactions':
    'mastercardChargebackPercentage',
  'chargeBacks.totalAmount': 'chargebackDollars',
  'chargeBacks.averageAmount': 'averageChargeback',
  'fees.component.interchange.paymentNetwork.visa.totalAmount':
    'visaInterchangeFees',
  'fees.component.interchange.paymentNetwork.mastercard.totalAmount':
    'mcInterchangeFees',
  'fees.component.interchange.paymentNetwork.discover.totalAmount':
    'discoverInterchangeFees',
  'fees.component.interchange.paymentNetwork.visa.percentageOfTotalInterchangeFeeAmount':
    'visaAverageInterchangeFeePaid',
  'fees.component.interchange.paymentNetwork.mastercard.percentageOfTotalInterchangeFeeAmount':
    'mcAverageInterchangeFeePaid',
  'fees.component.interchange.paymentNetwork.discover.percentageOfTotalInterchangeFeeAmount':
    'discoverAverageInterchangeFeePaid',
  'ccis.count': 'ccisCount',
};
