import { Card, CardBody, Col, Row } from 'reactstrap';
import React from 'react';
import WidgetSourceBlock from '../../WidgetSourceBlock';
import MaterialIcon from '../../../commons/MaterialIcon';
import { TreasuryReportSections } from '../../../reports/reports.constants';
import GreenRedCheckItem from '../../blocks/GreenRedCheckItem';

const Icons = [
  [
    'credit_score',
    'font-size-2xl',
    true,
    { size: 'font-size-xl', padding: 'p-3' }, // pdf download config
  ],
  [
    'price_change',
    'font-size-4em',
    true,
    { size: 'font-size-3xl', padding: 'p-3', align: 'top-47' },
  ],
  [
    'account_balance',
    'font-size-3xl',
    false,
    { size: 'font-size-2xl', padding: 'p-3' },
  ],
];
const WheelOfIcons = ({ whenPrinting }) => {
  const renderIcons = () => {
    const radius = whenPrinting ? 40 : 50; // half of the circle's width/height
    const angleIncrement = (2 * Math.PI) / Icons.length;
    let currentAngle = -Math.PI / 2; // start from the top

    return Icons.map((icon, index) => {
      const iconX = Math.cos(currentAngle) * radius;
      const iconY = Math.sin(currentAngle) * radius;

      const transformStyle = {
        transform: `translate(${iconX}px, ${iconY}px)`,
      };

      currentAngle += angleIncrement;
      return (
        <div
          key={index}
          className={`wheel-icon-lg bg-primary-soft rounded-circle p-3 d-flex align-items-center justify-content-center ${
            icon[3]?.align || ''
          }`}
          style={transformStyle}
        >
          <MaterialIcon
            icon={icon[0]}
            size={whenPrinting ? icon[3].size : icon[1]}
            clazz="text-primary"
          />
        </div>
      );
    });
  };
  return (
    <>
      <div
        className="rounded-circle position-relative border-0"
        style={{ height: 120, width: 120, marginLeft: 0 }}
      >
        &nbsp;
        {renderIcons()}
      </div>
    </>
  );
};

const ImproveVendorRelationshipElectronicPaymentsWidget = ({
  widgetConfig,
  whenPrinting,
}) => {
  return (
    <Card className="report-widget">
      <CardBody className="justify-content-center d-flex">
        <div className="flex-fill">
          <h5 className="text-left mb-1 d-flex align-items-center gap-1">
            {widgetConfig.heading}
          </h5>
          <div className="px-2 text-left">
            <p className="font-size-sm2">{widgetConfig.description}</p>
            <Row
              className={`align-items-center flex-fill ${
                whenPrinting ? 'p-2' : 'py-3 p-2'
              }`}
            >
              <Col className="px-0 d-flex align-items-center justify-content-center">
                <WheelOfIcons whenPrinting={whenPrinting} />
              </Col>
              <Col
                md={1}
                className="align-items-center px-0 d-flex justify-content-center"
              >
                <MaterialIcon
                  icon="double_arrow"
                  clazz="font-size-2xl txt-rpt-heading"
                />
              </Col>
              <Col className="d-flex align-items-center px-0 justify-content-center">
                <div className="d-flex flex-column">
                  {[
                    'Faster Receipt of Payment',
                    'Enhanced Remittance to Improve Reconciliation',
                    'More Secure Than Checks',
                  ].map((entry) => (
                    <GreenRedCheckItem
                      key={entry}
                      section={TreasuryReportSections.Fraud}
                      bordered=""
                      item={[
                        entry,
                        {
                          isChecked: true,
                          key: entry,
                          value: 1,
                          itemValue: 1,
                        },
                      ]}
                    />
                  ))}
                </div>
              </Col>
            </Row>
          </div>
          {widgetConfig?.source && (
            <div className="mt-2">
              <WidgetSourceBlock text={widgetConfig.source} />
            </div>
          )}
        </div>
      </CardBody>
    </Card>
  );
};

export default ImproveVendorRelationshipElectronicPaymentsWidget;
