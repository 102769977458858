import React, { useState } from 'react';
import { Card, CardBody, CardHeader } from 'reactstrap';
import PillFilters from '../../commons/PillFilters';
import ButtonIcon from '../../commons/ButtonIcon';
import Pagination from '../../Pagination';
import Table from '../../GenericTable';
import { sortingTable } from '../../../utils/sortingTable';
import TextOverflowTooltip from '../../commons/TextOverflowTooltip';
import { dateWithoutTZ } from '../../../utils/Utils';
import ChecklistStatus from '../../checklist/ChecklistStatus';
import useCreateCaseModal from './modals/CreateCase';

const casesFilters = [
  { id: 'all', label: 'All Cases', status: 'all' },
  { id: 'my', label: 'My Cases', status: 'my' },
];

const columns = [
  {
    key: 'ticket',
    orderBy: 'ticket',
    component: 'Ticket number',
  },
  {
    key: 'title',
    orderBy: 'title',
    component: 'Title',
    width: '160px',
  },
  {
    key: 'description',
    orderBy: '',
    component: 'Description',
    width: '160px',
  },
  {
    key: 'date',
    orderBy: 'date',
    component: 'Date',
    width: '160px',
  },
  {
    key: 'priority',
    orderBy: 'status',
    component: 'Priority',
    width: '220px',
  },
  {
    key: 'assignment',
    orderBy: '',
    component: 'Assigned to',
    width: '160px',
  },
];

const casesData = [
  {
    id: 1,
    ticket: 648373,
    title: 'Login Error',
    description: 'User is unable to login from mobile',
    date: '2025-02-03T20:15:21.761Z',
    priorityLevel: 'High',
    assignment: 'Kevin Miyamoto',
    currentStatus: 'Investigating',
    notes:
      'Issue reported on multiple devices. Logs indicate possible authentication token issue.',
    completionDate: null,
  },
  {
    id: 2,
    ticket: 857483,
    title: 'Login Error',
    description: 'User unable to log in from mobile.',
    date: '2025-01-28T00:00:00.000Z',
    priorityLevel: 'Medium',
    assignment: 'Joe Smith',
    currentStatus: 'Resolved',
    notes:
      'Fixed by updating OAuth token expiry time. User confirmed issue is resolved.',
    completionDate: '2025-02-01T12:30:00.000Z',
  },
  {
    id: 3,
    ticket: 859443,
    title: 'Changing Admin',
    description: 'User wishes to change admin on online banking.',
    date: '2025-02-22T00:00:00.000Z',
    priorityLevel: 'Low',
    assignment: 'Danita Johnson',
    currentStatus: 'Closed',
    notes:
      'User verified changes were successfully applied. No further action required.',
    completionDate: '2025-02-23T16:45:00.000Z',
  },
  {
    id: 4,
    ticket: 762389,
    title: 'Password Reset Failure',
    description: 'Reset link is not being sent to the user’s email.',
    date: '2025-03-01T12:45:10.564Z',
    priorityLevel: 'High',
    assignment: 'Alex Carter',
    currentStatus: 'In Progress',
    notes: 'SMTP server logs show intermittent failures. DevOps team notified.',
    completionDate: null,
  },
  {
    id: 5,
    ticket: 921774,
    title: 'Page Not Loading',
    description: 'Dashboard page remains blank after login.',
    date: '2025-02-15T08:30:45.231Z',
    priorityLevel: 'Critical',
    assignment: 'Linda Thompson',
    currentStatus: 'Resolved',
    notes:
      'Issue caused by missing CSS file in production build. Fixed in latest deploy.',
    completionDate: '2025-02-16T14:10:00.000Z',
  },
  {
    id: 6,
    ticket: 812543,
    title: 'Session Timeout Issue',
    description: 'User session expires too quickly after login.',
    date: '2025-03-05T14:20:30.900Z',
    priorityLevel: 'Medium',
    assignment: 'Michael Lee',
    currentStatus: 'Under Review',
    notes:
      'Possible misconfiguration in session timeout settings. Logs under analysis.',
    completionDate: null,
  },
  {
    id: 7,
    ticket: 743215,
    title: 'Email Notification Delay',
    description: 'Users receiving email notifications hours later.',
    date: '2025-02-10T16:55:19.678Z',
    priorityLevel: 'Low',
    assignment: 'Sophia Martinez',
    currentStatus: 'Resolved',
    notes:
      'Email queue processing delay fixed by optimizing cron job execution.',
    completionDate: '2025-02-12T10:20:00.000Z',
  },
  {
    id: 8,
    ticket: 675892,
    title: 'Button Not Clickable',
    description: 'Submit button does not respond after form is filled.',
    date: '2025-02-28T09:10:05.444Z',
    priorityLevel: 'High',
    assignment: 'Ryan Walker',
    currentStatus: 'Blocked',
    notes: 'Reproduced issue in staging. Awaiting UI/UX team feedback.',
    completionDate: null,
  },
  {
    id: 9,
    ticket: 934576,
    title: 'Payment Processing Issue',
    description: 'Payments getting stuck on the final confirmation step.',
    date: '2025-03-07T11:15:37.321Z',
    priorityLevel: 'Critical',
    assignment: 'Emily Davis',
    currentStatus: 'Escalated',
    notes:
      'Payment gateway logs show API timeout. Support ticket raised with provider.',
    completionDate: null,
  },
  {
    id: 10,
    ticket: 856321,
    title: 'UI Glitch on Mobile',
    description: 'Navigation bar overlaps content on small screens.',
    date: '2025-02-18T13:40:22.765Z',
    priorityLevel: 'Medium',
    assignment: 'David Hernandez',
    currentStatus: 'Fixed',
    notes: 'CSS media queries updated to resolve layout issue. Patch released.',
    completionDate: '2025-02-19T08:55:00.000Z',
  },
  {
    id: 11,
    ticket: 782649,
    title: 'File Upload Failing',
    description: 'Users unable to upload PDFs larger than 5MB.',
    date: '2025-03-03T18:25:14.892Z',
    priorityLevel: 'High',
    assignment: 'Rachel Green',
    currentStatus: 'Investigating',
    notes:
      'Possible server-side file size limit restriction. Dev team reviewing logs.',
    completionDate: null,
  },
  {
    id: 12,
    ticket: 899674,
    title: 'Dark Mode Not Working',
    description: 'Dark mode setting does not persist after page reload.',
    date: '2025-02-25T21:05:50.120Z',
    priorityLevel: 'Low',
    assignment: 'Nathan Scott',
    currentStatus: 'Resolved',
    notes: 'Local storage implementation was missing an update. Patch applied.',
    completionDate: '2025-02-26T10:15:00.000Z',
  },
];

const Cases = () => {
  const [selectedFilter, setSelectedFilter] = useState(casesFilters[0]);
  const [pagination, setPagination] = useState({ page: 1, limit: 10 });
  const [order, setOrder] = useState([]);
  const { CreateCaseModal, setShowModal, setCaseDetails } =
    useCreateCaseModal();

  const handleFilterSelect = (item) => {
    setSelectedFilter(item);
  };
  const onPaginationChange = (page) => {
    setPagination((prev) => ({ ...prev, page }));
  };
  const changePaginationPage = (newPage) => {
    setPagination((prev) => ({ ...prev, page: newPage }));
  };
  const sortTable = ({ name }) => {
    if (name === 'action') return null;
    sortingTable({ name, order, setOrder });
  };

  const data = casesData?.map((activity) => ({
    ...activity,
    dataRow: [
      {
        key: 'ticket',
        component: (
          <span className="text-truncate text-wrap fw-bold">
            {activity?.ticket || '--'}
          </span>
        ),
      },
      {
        key: 'title',
        component: (
          <div className="d-flex gap-1 align-items-center">
            <span className="text-truncate">
              <TextOverflowTooltip
                maxLength={15}
                capitalizeText={false}
                text={activity?.title}
                textStyle="mb-0"
              />
            </span>
          </div>
        ),
      },
      {
        key: 'description',
        component: (
          <span className="text-truncate">
            <TextOverflowTooltip
              maxLength={20}
              capitalizeText={false}
              text={activity?.description}
              textStyle="mb-0"
            />
          </span>
        ),
      },
      {
        key: 'date',
        component: <span>{dateWithoutTZ(activity?.date)}</span>,
      },
      {
        key: 'Priority',
        component: (
          <ChecklistStatus item={{ status: activity?.priorityLevel }} />
        ),
      },
      {
        key: 'assignment',
        component: (
          <>
            <span>{activity?.assignment || '--'}</span>
          </>
        ),
      },
    ],
  }));

  return (
    <div>
      <CreateCaseModal />
      <Card className="p-0 border-0 rounded-0 shadow-0">
        <CardHeader className="justify-content-between px-3 pt-0 btn-add-activity">
          <PillFilters
            items={casesFilters}
            currentFilter={selectedFilter}
            itemWidth={130}
            moduleName="cases"
            onFilterChange={handleFilterSelect}
          />

          <div className="d-flex align-items-center gap-2">
            <ButtonIcon
              color="outline-primary"
              onclick={() => {
                setCaseDetails(casesData[0]);
                setShowModal(true);
              }}
              classnames="btn-sm rounded-pill pr-3 font-size-sm font-weight-medium"
              icon="add"
              label="Add Case"
            />
          </div>
        </CardHeader>
        <CardBody className="p-0">
          <Card className="mb-0 card-0">
            <CardBody className="p-0">
              <div className="table-responsive-md datatable-custom">
                <div
                  id="datatable_wrapper"
                  className="dataTables_wrapper h-100 no-footer"
                >
                  <Table
                    columns={columns}
                    data={data}
                    headClass="bg-gray-table-head"
                    paginationInfo={{}}
                    onPageChange={changePaginationPage}
                    onClick={(item) => {
                      setCaseDetails(item);
                      setShowModal(true);
                    }}
                    emptyDataIcon="event_available"
                    emptyDataText="This record doesn't have any cases"
                    title="Cases"
                    dataInDB={true}
                    sortingTable={sortTable}
                    sortingOrder={order}
                  />
                </div>
              </div>
            </CardBody>
          </Card>

          <div className="px-3 pb-3">
            <Pagination
              paginationInfo={pagination}
              onPageChange={onPaginationChange}
            />
          </div>
        </CardBody>
      </Card>
    </div>
  );
};

export default Cases;
