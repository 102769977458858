import { trio } from 'ldrs';

trio.register();

const TrioLoader = ({
  size = '22',
  speed = '1.3',
  color = 'var(--secondaryColor)',
}) => {
  return (
    <>
      <l-trio size={size} speed={speed} color={color}></l-trio>
    </>
  );
};

export default TrioLoader;
