import React, { useEffect, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import MaterialIcon from '../commons/MaterialIcon';

const DropZone = ({
  width,
  height,
  onDrop,
  rounded = '1rem',
  type = 'input',
  style = {},
  enabled = true,
  showFullDrag = false,
  isAskIdaModal,
  children,
}) => {
  const [isDragOver, setIsDragOver] = useState(false);
  const modalConfig = isAskIdaModal
    ? { top: 0, right: 0, width: 450 }
    : {
        top: showFullDrag ? 1 : style.top,
        left: showFullDrag ? 1 : style.left,
        width: showFullDrag ? '100%' : width,
        height: showFullDrag ? '100%' : height || '215px',
      };

  const dragStyle =
    type === 'input'
      ? {
          ...style,
          ...modalConfig,
          position: showFullDrag ? 'fixed' : 'absolute',
          height: showFullDrag ? '100%' : height || '215px',
          border: 0,
          borderRadius: 0,
          background: 'rgba(255,255,255,0.8)',
          padding: '12px',
          zIndex: '9999',
        }
      : {
          width: '100%',
          height: '100%',
          position: 'absolute',
          background: 'rgba(255,255,255,0.8)',
          borderRadius: rounded,
          border: '2px dashed var(--primaryColor)',
          zIndex: '9999',
        };

  const handleDragOver = (event) => {
    if (!enabled) {
      return;
    }
    event.preventDefault();
    setIsDragOver(true);
  };

  const handleDragLeave = () => {
    if (!enabled) {
      return;
    }
    setIsDragOver(false);
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDrop: (files) => {
      onDrop(files);
      setIsDragOver(false);
    },
  });

  // Global drag listeners to handle drag events across the entire screen
  useEffect(() => {
    if (showFullDrag && enabled) {
      const onDragOver = (event) => {
        if (!enabled) return;
        event.preventDefault();
        setIsDragOver(true);
      };

      const onDragLeave = () => {
        setIsDragOver(false);
      };

      const onDropEvent = () => {
        setIsDragOver(false);
      };

      document.addEventListener('dragover', onDragOver);
      document.addEventListener('dragleave', onDragLeave);
      document.addEventListener('drop', onDropEvent);

      return () => {
        document.removeEventListener('dragover', onDragOver);
        document.removeEventListener('dragleave', onDragLeave);
        document.removeEventListener('drop', onDropEvent);
      };
    }
  }, [enabled, showFullDrag]);

  return (
    <section
      className="w-100"
      onDragOver={handleDragOver}
      onDragLeave={handleDragLeave}
    >
      {isDragOver && enabled && (
        <div style={dragStyle}>
          <div
            {...getRootProps({ className: 'dropzone' })}
            className="h-100 w-100 d-flex z-index-99 opacity-11 flex-column gap-2 align-items-center justify-content-center cursor-pointer"
          >
            <input {...getInputProps()} />
            <p className="d-flex d-flex flex-column transition-all mb-0 align-items-center gap-1">
              <MaterialIcon icon="description" size="font-size-2xl" rounded />
              <h3 className="mt-2 mb-0">Add anything</h3>
              <span>Drop your files here to add to the conversation</span>
            </p>
          </div>
        </div>
      )}
      {children}
    </section>
  );
};

export default DropZone;
