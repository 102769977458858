// all the pages titles that we wanna scrap in the account statement pdf
import TreasuryFrame from '../../assets/png/reports/TM-Card.svg';
import WCFrame from '../../assets/png/reports/WC-Card.svg';
import MerchantFrame from '../../assets/png/reports/MS-Card.svg';
import FraudFrame from '../../assets/png/reports/FI-Card.svg';
import AccountStructureFrame from '../../assets/png/reports/AS-Card.svg';
import useIsTenant from '../../hooks/useIsTenant';
import html2canvas from 'html2canvas';

const { isFFB } = useIsTenant();

export const Pages = {
  RelationshipSummary: {
    title: 'Relationship Summary',
    headers: [
      'Account',
      'Name',
      'Investable Balance',
      'Required Balance',
      'Excess / Deficit',
      'Total Activity',
    ],
  },
  BalanceSummary: {
    title: 'Balance Summary',
    headers: ['Title', 'Value'],
  },
  ResultsSummary: { title: 'Results Summary', headers: ['Title', 'Value'] },
  ServiceDetails: {
    title: 'Service Details',
    headers: [
      'Service Description',
      'Volume',
      'Price',
      'Total Fee',
      'Required Balance',
    ],
  },
  // for SVB
  ServiceInformation: {
    title: 'Service Information',
    headers: [
      'Service Information',
      'Volume',
      'Unit Price',
      'Service Charge',
      'Balance Pay Methd',
    ],
  },
  HistoricalSummary: {
    title: 'Historical Summary',
    headers: [
      'Month',
      'Average Ledger Balance',
      'Average Positive Collected Balance',
      'Investable Balance',
      'Excess / Deficit Balance',
      'Earnings Credit Amount',
      'Analysis Based Fees',
      'Fees Based Fees',
      'Service Charges Due',
    ],
  },
  LastTwelveMonth: {
    title: 'Last Twelve Month History',
    headers: [
      'MM/YY',
      'Average Ledger Balance',
      'Balance Supporting Services',
      'Service Balance Required',
      'Excess / Deficit Balance',
      'Earnings Allowance',
      'Analyzed Charges',
      'Ttl Fees Analyzed Stmt',
    ],
  },
};

// umhmm for comerica
export const PayablesDataPointsV2Comerica = {
  // B
  'TPA Wire': [
    'TPA Book Transfer Via Wire',
    'TPA Drawdown Wire',
    'TPA Multibank Wire',
    'TPA Outgoing Domestic Wire',
    'TPA Outgoing Intl Wire-FX-Our',
    'TPA Outgoing Intl Wire-FX-Shared',
    'TPA Outgoing Intl Wire-Out',
    'TPA Outgoing Intl Wire-Shared',
    'Automated Drawdown Wire',
    'Automated Standing Transfer',
    'Book Transfer Via Wire',
    'Domestic Outgoing Wire Non-Repet',
    'Intl Automated Standing Transfer',
    'Intl Outgoing Wire Consumer',
    'Intl Outgoing Wire-FX-Our',
    'Intl Outgoing Wire-FX-Shared',
    'Intl Outgoing Wire-Our',
    'Intl Outgoing Wire-Shared',
    'Outgoing Wire Repetitive',
    'IP / IP Web Auto Domestic Wire',
    'IP / IP Web Book Transfer Via Wire',
    'IP / IP Web Out Intl Wire-Shared',
    'IP / IP Web Auto Multibank Wire',
    'IP / IP Web Outgoing Intl Wire-Our',
    'IP / IP Web Out Intl Wire-FX-Shared',
    'IP / IP Web Out Intl Wire-FX-Our',
  ],
  // C
  'ACH Origination': [
    'Electronic Debit',
    'ACH Items Originated',
    'ACH GS Item',
    'TPA ACH Item',
    'TPA ACH GS Item',
    'ACH Items Originated Same Day',
    'IP / IP Web ACH GS Item',
    'IP / IP Web ACH Item Charge',
  ],
  // D
  'Checks Paid': [
    'Debit',
    'Controlled Disbursement Debits',
    'Positive Pay Checks',
    'IP / IP Web Check Issue Printing',
  ],
  // E
  'Integrated Payables ACH': [
    'IP Service Maintenance',
    'IP Web Service Maintenance',
    'IP Web Account Maintenance',
    'IP Web Submitted Files',
    'SWIFT FileAct Reporting (4)',
    'IP / IP Web Check Issue Printing',
    'IP / IP Web ACH Item Charge',
    'IP / IP Web ACH GS Item',
  ],
};

export const ReceivablesDataPointsV2 = {
  // G
  'Checks Paid': ['Domestic Incoming Wire', 'International Incoming Wire'],
  // H
  'Integrated Payables ACH': [
    'Electronic Credit',
    'ACH Intl Transaction Received',
    'Credit',
    'Items Deposited',
  ],
  // I
  'Positive Pay Usage': [
    'ARP Maintenance-Full Img Pos Pay',
    'Image Positive Pay Maint Wo Recon',
    'Positive Pay Maintenance',
    'Web Positive Pay Maintenance',
  ],
  // J
  'ACH Positive Pay Usage': ['ACH Positive Pay Monthly Maint'],
};

export const PaymentRisksDataPoints = {
  // G
  'Incoming Wires': ['Domestic Incoming Wire', 'International Incoming Wire'],
  // H
  'Incoming ACH/Check': [
    'Electronic Credit',
    'ACH Intl Transaction Received',
    'Items Deposited',
  ],
  // I
  'Cash Vault': ['Cash Vault Currency Deposited'],
  // J
  'Integrated Receivables': [
    'INR Monthly Maintenance',
    'INR Items',
    'INR Data Transmission',
    'INR Data Transmission With Images',
    'INR File Download',
    'INR File Download Items',
  ],
};

export const InputType = {
  Currency: 1,
  Comma: 2,
};

export const ReportTypes = {
  Treasury: 'Treasury',
  WorkingCapital: 'WorkingCapital',
  Merchant: 'Merchant',
  AccountStructure: 'AccountStructure',
  Fraud: 'Fraud',
  KnowledgeAssessment: 'KnowledgeAssessment',
  CommercialCard: 'CommercialCard',
  MerchantV2: 'MerchantV2',
  SvbBank: 'SvbBank',
};

export const ActionTypes = {
  ADD: 1,
  UPDATE: 2,
  REMOVE: 3,
  CLEAR: 4,
};

export const SVBMapping = {
  PayablesDataPoints: {
    // B
    'TPA Wire': [
      'Out WT Dom',
      'Out WT Dom Rep',
      'Out WT Dom Online',
      'Out WT Dom Online w/Email',
      'Out WT Dom w/Email',
      'Out WT Dom Rep w/Email',
    ],
    // C
    'ACH Origination': [
      'ACH Credit Originated',
      'ACH Debit Received',
      'Bill Pay Excess Transaction',
      'BPP Trans 100 Incl',
      'BPP Trans 25 Incl',
    ],
    // D
    'Checks Paid': ['Checks Paid', 'CDA Per Item'],
    // E
    'Integrated Payables ACH': [
      'Out WT Intl FX Online',
      'Out WT Intl USD',
      'Out WT Intl FX',
      'Out WT Intl USD Online',
      'Out WT Intl USD Online Email',
      'Out WT Intl USD w/Email',
    ],
  },
  EstimatedTotalPayablesPoints: {
    Payables: [
      'Out WT Dom',
      'Out WT Dom Rep',
      'Out WT Dom Online',
      'Out WT Dom Online w/Email',
      'Out WT Dom w/Email',
      'Out WT Dom Rep w/Email',
      'ACH Credit Originated',
      'ACH Debit Received',
      'Bill Pay Excess Transaction',
      'BPP Trans 100 Incl',
      'BPP Trans 25 Incl',
      'Checks Paid',
      'CDA Per Item',
      'Out WT Intl FX Online',
      'Out WT Intl USD',
      'Out WT Intl FX',
      'Out WT Intl USD Online',
      'Out WT Intl USD Online Email',
      'Out WT Intl USD w/Email',
    ],
  },
  ReceivablesDataPoints: {
    // G
    'Checks Paid': ['In Wire Transfer', 'In Wire w/Email'],
    // H
    'Integrated Payables ACH': ['ACH Credit Received', 'ACH Debit Originated'],
    // I
    'Positive Pay Usage': [
      'Checks Deposited',
      'Lockbox Automated Per Item',
      'Lockbox Non-automated per Item',
      'Mobile Deposit Item',
      'RDC Checks Deposited',
      'RLB Checks Deposited',
    ],
    // J
    'ACH Positive Pay Usage': ['Tag IR Monthly Service'],
  },
  EstimatedTotalReceivablesPoints: {
    // K
    Receivables: [
      'In Wire Transfer',
      'In Wire w/Email',
      'ACH Credit Received',
      'ACH Debit Originated',
      'Checks Deposited',
      'Lockbox Automated Per Item',
      'Lockbox Non-automated per Item',
      'Mobile Deposit Item',
      'RDC Checks Deposited',
      'RLB Checks Deposited',
    ],
  },
  PaymentRisksDataPoints: {
    'Incoming Wires': [
      'Check PosPay w/o Payee Monthly',
      'Check PosPay w/Payee Monthly',
      'Check Reverse PosPay Monthly',
      'CDA Positive Pay Account Maint',
      'CDA Positive Payee Monthly Fixed',
      'Check Block All Monthly',
      'FCS Check Block All Monthly',
    ],
    'Incoming ACH/Check': [
      'ACH PosPay Cr Monthly',
      'ACH PosPay Dr Monthly',
      'ACH Block Account Maint',
      'FCS ACH Cr Block All Monthly',
      'FCS ACH Dr Block All Monthly',
      'ACH Filter Monthly Maintenance',
    ],
  },
};

export const DemoTenantsKeys = {
  eb: 'eb',
  cb: 'cb',
  sb: 'sb',
  svb: 'svb',
  ffb: 'ffb',
};

export const DemoTenants = [
  { key: DemoTenantsKeys.eb, name: 'EB', tip: 'Excel Bank' }, // uses comerica mapping in mozilla
  { key: DemoTenantsKeys.cb, name: 'CB', tip: 'Comerica Bank' }, // uses comerica mapping in mozilla
  { key: DemoTenantsKeys.sb, name: 'SB', tip: 'Synovus Bank' }, // uses comerica mapping in mozilla
  { key: DemoTenantsKeys.svb, name: 'SVB', tip: 'Silicon Valley Bank' }, // uses svb mapping in mozilla
  { key: DemoTenantsKeys.ffb, name: 'FFB', tip: 'First Fidelity Bank' }, // uses ffb mapping in mozilla
];

export const getDemoTenantByKey = (key) =>
  DemoTenants.find((dm) => dm.key === key);

export const PDF_PAGE_HEIGHT = 892; // 8.5 / 11; // Letter paper size aspect ratio

export const PayableKeys = [
  ['Checks', 1, false], // false/true means allow editing
  ['ACH', 2, false],
  ['Wires', 3, false],
];
export const ReceivableKeys = [
  ['ACH', 1, false],
  ['Checks', 2, false],
  ['Wires', 3, false],
];

export const TreasuryReportSections = {
  Overview: 'Overview',
  Payables: 'Payables',
  Receivables: 'Receivables',
  Fraud: 'Fraud',
  FeeSummary: 'FeeSummary',
  Glossary: 'Glossary',
  Disclaimer: 'Disclaimer',
};

export const WorkingCapitalReportSections = {
  Overview: 'Opportunity',
  Payables: 'Account Payable',
  Receivables: 'Account Receivables',
  LoanOverview: 'Loan Overview',
  Glossary: 'Glossary',
  Disclaimer: 'Disclaimer',
};

export const FraudReportSections = {
  FraudWhyItMatters: 'Fraud - Why It Matters',
  WhatCanYouDo: 'What Can You Do',
  PrioritizeElectronicPayments: 'Prioritize Electronic Payments',
  Disclaimer: 'Disclaimer',
};

export const CommercialCardReportSections = {
  Highlights: 'Highlights',
  VendorAnalysisDetails: 'Vendor Analysis Details',
  Insights: 'Insights: Trends and Benefits',
  MovingForwardTogether: 'Moving Forward Together´',
  Disclaimer: 'Disclaimer',
};

export const MerchantReportV2Sections = {
  CardPaymentAcceptanceOverview: 'Card Payment Acceptance Overview',
  FeeBreakdown: 'Fee Breakdown',
  RefundsAndChargebacks: 'Refunds and Chargebacks',
  Recommendations: 'Recommendations',
  YourDedicatedTeam: 'Your Dedicated Team',
  Disclaimer: 'Disclaimer',
};

export const SvbReportSections = {
  ControllableCostSavings: 'Controllable Cost Savings',
  MerchantFees: 'How Merchant Fees Are Calculated',
  InterchangeSavings: 'Interchange Savings',
  Chargebacks: 'Chargebacks',
  Glossary: 'Disclaimer',
};

export const getVideoUrlWithTenant = (tenant, videoId) => {
  const domain = tenant.domainUrl || tenant.domain;
  if (!domain) {
    // fallback to browser url and get from it
    return `${location.protocol}//${location.host}/video/${videoId}?tenant_id=${tenant.id}`;
  }
  return `${domain}/video/${videoId}?tenant_id=${tenant.id}`;
};

export const EngagementReportConfig = {
  [ReportTypes.Treasury]: {
    PageConfig: {
      [TreasuryReportSections.Overview]: {
        page: 2,
        icon: 'account_balance',
        enabled: true,
      },
      [TreasuryReportSections.Payables]: {
        page: 3,
        icon: 'price_check',
        enabled: true,
      },
      [TreasuryReportSections.Receivables]: {
        page: 4,
        icon: 'price_check',
        enabled: true,
      },
      [TreasuryReportSections.Fraud]: {
        page: 5,
        icon: 'price_check',
        enabled: true,
      },
      [TreasuryReportSections.FeeSummary]: {
        page: 6,
        icon: 'price_check',
        enabled: true,
      },
      [TreasuryReportSections.Glossary]: {
        page: 7,
        icon: 'menu_book',
        enabled: true,
      },
      [TreasuryReportSections.Disclaimer]: {
        page: 8,
        icon: 'menu_book',
        enabled: isFFB,
      },
    },
    SectionKeys: [
      TreasuryReportSections.Overview,
      TreasuryReportSections.Payables,
      TreasuryReportSections.Receivables,
      TreasuryReportSections.Fraud,
      TreasuryReportSections.FeeSummary,
      TreasuryReportSections.Glossary,
      TreasuryReportSections.Disclaimer,
    ],
  },
  [ReportTypes.WorkingCapital]: {
    PageConfig: {
      [WorkingCapitalReportSections.LoanOverview]: {
        page: 1,
        icon: 'menu_book',
        enabled: false,
      },
      [WorkingCapitalReportSections.Overview]: {
        page: 2,
        icon: 'price_check',
        enabled: true,
      },
      [WorkingCapitalReportSections.Payables]: {
        page: 3,
        icon: 'arrow_upward',
        enabled: true,
      },
      [WorkingCapitalReportSections.Receivables]: {
        page: 4,
        icon: 'arrow_downward',
        enabled: true,
      },
      [WorkingCapitalReportSections.Glossary]: {
        page: 5,
        icon: 'menu_book',
        enabled: true,
      },
      [WorkingCapitalReportSections.Disclaimer]: {
        page: 8,
        icon: 'menu_book',
        enabled: isFFB,
      },
    },
    SectionKeys: [
      WorkingCapitalReportSections.LoanOverview,
      WorkingCapitalReportSections.Overview,
      WorkingCapitalReportSections.Payables,
      WorkingCapitalReportSections.Receivables,
      WorkingCapitalReportSections.Glossary,
      WorkingCapitalReportSections.Disclaimer,
    ],
  },
  [ReportTypes.Merchant]: {},
  [ReportTypes.MerchantV2]: {
    PageConfig: {
      [MerchantReportV2Sections.CardPaymentAcceptanceOverview]: {
        page: 1,
        icon: 'menu_book',
        enabled: true,
      },
      [MerchantReportV2Sections.FeeBreakdown]: {
        page: 2,
        icon: 'price_check',
        enabled: true,
      },
      [MerchantReportV2Sections.RefundsAndChargebacks]: {
        page: 3,
        icon: 'arrow_upward',
        enabled: true,
      },
      [MerchantReportV2Sections.Recommendations]: {
        page: 4,
        icon: 'arrow_upward',
        enabled: true,
      },
      [MerchantReportV2Sections.YourDedicatedTeam]: {
        page: 5,
        icon: 'arrow_upward',
        enabled: false,
      },
      [MerchantReportV2Sections.Disclaimer]: {
        page: 6,
        icon: 'arrow_upward',
        enabled: true,
      },
    },
    SectionKeys: [
      MerchantReportV2Sections.CardPaymentAcceptanceOverview,
      MerchantReportV2Sections.FeeBreakdown,
      MerchantReportV2Sections.RefundsAndChargebacks,
      MerchantReportV2Sections.Recommendations,
      MerchantReportV2Sections.YourDedicatedTeam,
      MerchantReportV2Sections.Disclaimer,
    ],
  },
  [ReportTypes.SvbBank]: {
    PageConfig: {
      [SvbReportSections.ControllableCostSavings]: {
        page: 1,
        icon: 'menu_book',
        enabled: true,
      },
      [SvbReportSections.MerchantFees]: {
        page: 2,
        icon: 'menu_book',
        enabled: true,
      },
      [SvbReportSections.InterchangeSavings]: {
        page: 3,
        icon: 'menu_book',
        enabled: true,
      },
      [SvbReportSections.Chargebacks]: {
        page: 4,
        icon: 'menu_book',
        enabled: true,
      },
      [SvbReportSections.Glossary]: {
        page: 4,
        icon: 'menu_book',
        enabled: true,
      },
    },
    SectionKeys: [
      SvbReportSections.ControllableCostSavings,
      SvbReportSections.MerchantFees,
      SvbReportSections.InterchangeSavings,
      SvbReportSections.Chargebacks,
      SvbReportSections.Glossary,
    ],
  },
  [ReportTypes.Fraud]: {
    PageConfig: {
      [FraudReportSections.FraudWhyItMatters]: {
        page: 1,
        icon: 'menu_book',
        enabled: true,
      },
      [FraudReportSections.WhatCanYouDo]: {
        page: 2,
        icon: 'price_check',
        enabled: true,
      },
      [FraudReportSections.PrioritizeElectronicPayments]: {
        page: 3,
        icon: 'arrow_upward',
        enabled: true,
      },
      [FraudReportSections.Disclaimer]: {
        page: 8,
        icon: 'menu_book',
        enabled: isFFB,
      },
    },
    SectionKeys: [
      FraudReportSections.FraudWhyItMatters,
      FraudReportSections.WhatCanYouDo,
      FraudReportSections.PrioritizeElectronicPayments,
      FraudReportSections.Disclaimer,
    ],
  },
  [ReportTypes.AccountStructure]: {},
  [ReportTypes.CommercialCard]: {
    PageConfig: {
      [CommercialCardReportSections.Highlights]: {
        page: 1,
        icon: 'menu_book',
        enabled: true,
      },
      [CommercialCardReportSections.VendorAnalysisDetails]: {
        page: 2,
        icon: 'price_check',
        enabled: true,
      },
      [CommercialCardReportSections.Insights]: {
        page: 3,
        icon: 'arrow_upward',
        enabled: true,
      },
      [CommercialCardReportSections.MovingForwardTogether]: {
        page: 4,
        icon: 'menu_book',
        enabled: true,
      },
      [CommercialCardReportSections.Disclaimer]: {
        page: 8,
        icon: 'menu_book',
        enabled: isFFB,
      },
    },
    SectionKeys: [
      CommercialCardReportSections.Highlights,
      CommercialCardReportSections.VendorAnalysisDetails,
      CommercialCardReportSections.Insights,
      CommercialCardReportSections.MovingForwardTogether,
      CommercialCardReportSections.Disclaimer,
    ],
  },
};

export const ReportTileStyles = {
  [ReportTypes.Treasury]: {
    frame: TreasuryFrame,
    icon: 'account_balance',
    styleClass: 'treasury',
  },
  [ReportTypes.WorkingCapital]: {
    frame: WCFrame,
    icon: 'chart_data',
    styleClass: 'working-capital',
  },
  [ReportTypes.Merchant]: {
    frame: MerchantFrame,
    icon: 'bar_chart_4_bars',
    styleClass: 'merchant',
  },
  [ReportTypes.Fraud]: {
    frame: FraudFrame,
    icon: 'gpp_maybe',
    styleClass: 'fraud',
  },
  [ReportTypes.AccountStructure]: {
    frame: AccountStructureFrame,
    icon: 'account_tree',
    styleClass: 'account-structure',
  },
  [ReportTypes.CommercialCard]: {
    frame: AccountStructureFrame,
    icon: 'currency_exchange',
    styleClass: 'commercial-card',
  },
  [ReportTypes.MerchantV2]: {
    frame: AccountStructureFrame,
    icon: 'bar_chart_4_bars',
    styleClass: 'merchant',
  },
};

export const ReportFormErrorFields = {
  name: {
    isShow: false,
    message: 'Company name is required.',
  },
  naics: {
    isShow: false,
    message: 'NAICS is required.',
  },
  reportDate: {
    isShow: false,
    message: 'Report date is required.',
  },
};

export const LEARN_OVERVIEW_CONSTANT_KEY = 'Learn Overview';

export const LatestEngagementsTableColumns = [
  {
    key: 'content',
    orderBy: 'type',
    component: <span>Content</span>,
  },
  {
    key: 'created_by',
    orderBy: 'createdAt',
    component: <span>Created By</span>,
  },
  {
    key: 'last_download',
    orderBy: 'updatedAt',
    component: <span>Last Updated</span>,
  },
  {
    key: 'actions',
    orderBy: '',
    component: <></>,
  },
];

export const ReportFileType = {
  PDF: 'pdf',
  PPT: 'ppt',
  PNG: 'png',
};

export const ReportFileTypes = [
  {
    key: ReportFileType.PDF,
    name: 'PDF',
    icon: 'description',
    isDefault: true,
    description: 'Document sharing and email attachments',
  },
  {
    key: ReportFileType.PPT,
    name: 'PPTX',
    icon: 'live_tv',
    isDefault: false,
    description: 'Microsoft PowerPoint document',
  },
  {
    key: ReportFileType.PNG,
    name: 'PNG',
    icon: 'image',
    isDefault: false,
    description: 'Graphics and image sharing',
  },
];

export const getHeightFromWidth = (width) => {
  const aspectRatio = 8.5 / 11; // Letter paper size aspect ratio
  const height = width / aspectRatio;
  return Math.round(height);
};

export const getCanvasElements = (element) => {
  return [...document.querySelectorAll(element)].map((el) =>
    html2canvas(el, {
      allowTaint: true,
      scale: Math.min(window.devicePixelRatio || 1, 2),
      useCORS: true,
      removeContainer: true,
      imageTimeout: 15000,
    })
  );
};

export const processReportLinks = (linkConfig) => {
  if (!linkConfig || typeof linkConfig !== 'object') {
    console.error('Invalid linkConfig provided:', linkConfig);
    return { links: null, Pages: {} };
  }

  const links = Object.entries(linkConfig).length === 1 ? null : linkConfig;
  const Pages = {};
  if (links?.length) {
    for (let k = 0; k < links.length; k++) {
      Pages[links[k].page] = links[k];
    }
  }
  return { links, Pages };
};

export const getPageDimensions = ($canvas) => {
  const configWidth = $canvas?.getBoundingClientRect();
  const HTML_Width = configWidth?.width || 0;
  const calculatedPageHeight = getHeightFromWidth(HTML_Width);
  const pageHeight =
    calculatedPageHeight < PDF_PAGE_HEIGHT
      ? PDF_PAGE_HEIGHT
      : calculatedPageHeight;
  return { HTML_Width, pageHeight };
};

export const generatePDF = (pdf, canvases, dimensions, linkConfig) => {
  canvases.forEach((canvas, index) => {
    const imgData = canvas.toDataURL('image/jpeg', 0.8);
    const { HTML_Width } = dimensions;

    const { links, Pages } = processReportLinks(linkConfig);
    const newLinkPage = links?.length ? Pages[index] || {} : linkConfig;
    if (newLinkPage?.url && index === newLinkPage?.page) {
      const { areaX, areaY, areaWidth, areaHeight } = newLinkPage.area;
      pdf.link(areaX, areaY, areaWidth, areaHeight, {
        url: newLinkPage.url,
        target: '_blank',
      });
    }

    pdf.addPage();
    pdf.addImage(imgData, 'JPEG', 0, 0, HTML_Width, PDF_PAGE_HEIGHT);
  });
  pdf.deletePage(1);
};

export const generateSlides = (pptx, canvases, dimensions, linkConfig) => {
  canvases.forEach((canvas, index) => {
    const imgData = canvas.toDataURL('image/jpeg', 0.8);
    const { HTML_Width, pageHeight } = dimensions;

    const slideWidthInInches = HTML_Width / 96; // convert from px to inches
    const slideHeightInInches = pageHeight / 96;

    pptx.defineLayout({
      name: 'A3',
      width: slideWidthInInches, // slide width
      height: slideHeightInInches, // slide height
    });

    pptx.layout = 'A3';
    const slide = pptx.addSlide();

    slide.addImage({
      data: imgData,
      x: 0,
      y: 0,
      w: slideWidthInInches,
      h: slideHeightInInches,
    });

    const { links, Pages } = processReportLinks(linkConfig);
    const newLinkPage = links?.length ? Pages[index] || {} : linkConfig;
    if (newLinkPage?.url && index === newLinkPage?.page) {
      const { areaX, areaY, areaWidth, areaHeight } = newLinkPage.area;
      slide.addText('', {
        x: areaX / 96,
        y: areaY / 96,
        w: areaWidth / 96,
        h: areaHeight / 96,
        hyperlink: { url: newLinkPage.url },
      });
    }
  });
};

export const generatePNG = async (zip, canvases, fileName) => {
  for (let i = 0; i < canvases.length; i++) {
    const imgData = canvases[i].toDataURL('image/png');
    const imgBlob = await fetch(imgData).then((res) => res.blob());
    zip.file(`${fileName}_${i + 1}.png`, imgBlob);
  }

  // generate the ZIP file
  const zipBlob = await zip.generateAsync({ type: 'blob' });
  const link = document.createElement('a');
  link.href = URL.createObjectURL(zipBlob);
  link.download = `${fileName}.zip`;
  link.click();
};

export const RiskColorCoverage = {
  none: {
    icon: 'close',
    color: 'text-red',
    bg: 'bg-rpt-red',
    value: 'none',
  },
  all: {
    icon: 'check',
    color: 'text-success',
    bg: 'bg-rpt-green',
    value: 'all',
  },
  partial: {
    icon: 'circle',
    color: 'text-warning',
    bg: 'bg-rpt-orange',
    value: 'partial',
  },
};
export const ColorCoverage = {
  ...RiskColorCoverage,
  0: {
    icon: 'close',
    color: 'text-red',
    bg: 'bg-rpt-red',
    value: 0,
  },
  1: {
    icon: 'check',
    color: 'text-success',
    bg: 'bg-rpt-green',
    value: 1,
  },
};

export const StatItemColors = {
  financial: '#2E8B57', // Sea Green
  volume: '#4682B4', // Steel Blue
  performance: '#8A2BE2', // Blue Violet
  time: '#E9967A', // Dark Salmon
  achievement: '#DAA520', // Golden Rod
  danger: '#e60808',
};

export const getLightColor = (hexColor) => {
  // Convert hex to RGB
  if (!hexColor) {
    return 'rgba(0, 0, 0, 0.1)';
  }
  const r = parseInt(hexColor.substring(1, 3), 16);
  const g = parseInt(hexColor.substring(3, 5), 16);
  const b = parseInt(hexColor.substring(5, 7), 16);

  // Return very light opacity version for background
  return `rgba(${r}, ${g}, ${b}, 0.1)`;
};

// these sub org fields must be looked at while populating data
export const AccountStructureLookupFields = {
  MaskedAccountNumber: 'Masked Account Number',
  AccountType: 'Account Type',
  ProductAndServices: 'Products and Services',
};
