/* eslint-disable no-unused-vars */
import React from 'react';
import { Card } from 'react-bootstrap';
import Skeleton from 'react-loading-skeleton';
import LucideIcon from '../../../../components/commons/LucideIcon';
import { FormattedValue } from '../../../../utils/Utils';
import { getLightColor } from '../../../../components/reports/reports.constants';
import ColorBorder from '../../../../components/commons/ColorBorder';

const StatusCard = ({
  index,
  stat,
  isLast,
  isFirst,
  customTitle,
  customFooter,
  customDescription,
}) => {
  return (
    <div
      className={`col-md-3
    ${isFirst ? 'pl-3' : 'pl-2'} ${isLast ? 'pr-3' : 'pr-2'}
  `}
      key={index}
    >
      <Card
        className="position-relative shadow-none"
        style={{ borderRadius: '0.75rem !important' }}
      >
        <Card.Body style={{ borderRadius: '0.75rem' }}>
          <ColorBorder color={stat?.color} />
          <div className="d-flex align-items-center justify-content-between">
            <h5 className="text-gray-800" style={{ flexGrow: 1 }}>
              {customTitle || stat?.label}
            </h5>
            <div
              className="p-2 rounded-circle d-flex align-items-center"
              style={{ background: getLightColor(stat?.color) }}
            >
              <LucideIcon icon={stat?.icon} color={stat?.color} size="22" />
            </div>
          </div>

          <p
            className="font-size-225em mb-0 font-weight-bold"
            style={{ color: stat?.color }}
          >
            {customDescription || (
              <FormattedValue value={stat?.value} type={stat?.type} />
            )}
          </p>

          {customFooter || stat?.customFooter ? (
            <span className="text-muted font-weight-normal font-size-sm2">
              {stat?.customFooter}
            </span>
          ) : (
            ''
          )}
        </Card.Body>
      </Card>
    </div>
  );
};

const OpportunitiesStats = ({ stats, isLoading, className }) => {
  const last = Object.entries(stats).length - 1;

  if (isLoading) {
    return (
      <div className={`row mx-0 mb-3 ${className}`}>
        {[0, 1, 2, 3].map((index) => (
          <StatusCard
            key={index}
            icon={<></>}
            index={index}
            isFirst={index === 0}
            isLast={index === last}
            customTitle={<Skeleton />}
            customFooter={<Skeleton />}
            customDescription={<Skeleton />}
          />
        ))}
      </div>
    );
  }

  return (
    <div className={`row mx-0 mb-3 ${className}`}>
      {stats?.map((stat, index) => (
        <StatusCard
          key={index}
          index={index}
          stat={stat}
          isFirst={index === 0}
          isLast={index === last}
        />
      ))}
    </div>
  );
};
export default OpportunitiesStats;
