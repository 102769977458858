import { Col, Row } from 'reactstrap';
import MaterialIcon from '../../commons/MaterialIcon';
import SectionWrapper from './SectionWrapper';
import AddNewButton from './AddNewButton';
import useAddNewAccountModal from './modals/AddNewAccount';
import NoDataFoundTitle from '../../fields/NoDataFoundTitle';
import NoDataFound from '../../commons/NoDataFound';
import React, { useEffect, useState } from 'react';
import ActionButtons from './tabs/ActionButtons';
import useDeleteModal from './modals/useDeleteModal';
import { usePagesContext } from '../../../contexts/pagesContext';
import {
  AccountStructureTabMap,
  cleanAccountName,
  FilterNotPrimary,
  getOperatingAccountType,
} from './account.structure.constants';
import TooltipComponent from '../../lesson/Tooltip';

const AccountStatus = ({ status }) => {
  const Statuses = {
    pending: { icon: 'error', color: 'orange' },
    complete: { icon: 'check_circle', color: 'green' },
  };

  const newStatus = !status ? 'complete' : status;
  return (
    <div
      className="d-flex fs-8 align-items-center p-1 bg-white shadow justify-content-center gap-1 rounded-pill"
      style={{ color: Statuses[newStatus].color, width: 100 }}
    >
      <MaterialIcon
        icon={Statuses[newStatus].icon}
        filled
        size="fs-5"
        customStroke="white"
      />
      <span className="text-capitalize font-weight-medium">{newStatus}</span>
    </div>
  );
};

const AccountType = ({ account }) => {
  const operatingAccountType = getOperatingAccountType(account);
  const title = operatingAccountType.name;
  const type = operatingAccountType.short;
  const color = operatingAccountType.color;
  return (
    <TooltipComponent title={title}>
      <span
        className={`py-1 px-2 rounded fs-11 font-weight-semi-bold ${color}`}
      >
        {type}
      </span>
    </TooltipComponent>
  );
};
const Accounts = ({ report, setReport }) => {
  const { pageContext, setPageContext } = usePagesContext();
  const jsonKey = AccountStructureTabMap.ReportDetails.key;
  const [accounts, setAccounts] = useState(
    pageContext?.AccountStructureReportModal[jsonKey]?.accounts || []
  );
  const [selectedAccount, setSelectedAccount] = useState({});
  const { AddNewAccountModal, setShowModal } = useAddNewAccountModal(
    selectedAccount,
    accounts,
    setAccounts
  );
  const handleEditAccount = (account) => {
    setSelectedAccount(account);
    setShowModal(true);
  };
  const {
    DeleteModal,
    setData,
    setShowModal: setShowDeleteModal,
  } = useDeleteModal({
    description: 'Are you sure you want to delete this Account?',
    successMsg: 'Account deleted.',
    deleteCallback: async (accnt) => {
      const newAccounts = [...accounts].filter((f) => f.id !== accnt.id);
      setAccounts(newAccounts);
    },
  });
  const handleDeleteAccount = (e, accnt) => {
    e.stopPropagation();
    setData({
      ...accnt,
      title: accnt.accountName,
    });
    setShowDeleteModal(true);
  };

  useEffect(() => {
    setPageContext({
      ...pageContext,
      AccountStructureReportModal: {
        ...pageContext.AccountStructureReportModal,
        [jsonKey]: {
          ...pageContext.AccountStructureReportModal[jsonKey],
          accounts,
        },
      },
    });
  }, [accounts]);
  return (
    <div className="w-100">
      <DeleteModal />
      <AddNewAccountModal />
      <div className="d-flex mb-2 justify-content-between align-items-center">
        <p className="mb-0 fs-7">
          Total number of accounts:{' '}
          <b>{accounts.filter(FilterNotPrimary).length}</b>
        </p>
        <AddNewButton
          label="New Account"
          onClick={() => {
            setSelectedAccount({});
            setShowModal(true);
          }}
        />
      </div>
      <SectionWrapper columns={['Names', 'Last 4 Digits']}>
        {accounts.length ? (
          <>
            {accounts.map((acnt) => (
              <Row
                key={acnt.id}
                onClick={() => handleEditAccount(acnt)}
                className="fs-7 bg-hover-gray-dark mx-0 py-2 px-0 cursor-pointer border-bottom border-white"
              >
                <Col md={6} className="pl-2 align-self-center">
                  <span>
                    {cleanAccountName(acnt)} <AccountType account={acnt} />
                  </span>
                </Col>
                <Col md={6}>
                  <div className="d-flex align-items-center justify-content-between">
                    <span>{acnt.accountNumber}</span>
                    <div className="d-flex align-items-center gap-1">
                      <AccountStatus status={acnt.status} />
                      <ActionButtons
                        onDelete={(e) => handleDeleteAccount(e, acnt)}
                      />
                    </div>
                  </div>
                </Col>
              </Row>
            ))}
          </>
        ) : (
          <NoDataFound
            icon="people"
            iconRounded={true}
            iconStyle="font-size-2em"
            containerStyle="text-gray-search mt-2 pt-2 mb-1"
            title={<NoDataFoundTitle clazz="fs-7 mb-0" str={`No accounts`} />}
          />
        )}
      </SectionWrapper>
    </div>
  );
};

export default Accounts;
