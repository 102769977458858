import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { overflowing } from '../../../utils/Utils';
import RightSlidingDrawer from '../../modal/RightSlidingDrawer';
import { Card, CardBody, CardHeader } from 'reactstrap';
import { getIconByMimeType, MimeFileTypes } from '../constants';
import Skeleton from 'react-loading-skeleton';
import NoDataFound from '../../commons/NoDataFound';
import ChatService from '../../../services/chat.service';
import LucideIcon from '../../commons/LucideIcon';
import TooltipComponent from '../../lesson/Tooltip';
import AlertWrapper from '../../Alert/AlertWrapper';
import Alert from '../../Alert/Alert';
import DeleteConfirmationModal from '../../modal/DeleteConfirmationModal';
import TextOverflowTooltip from '../../commons/TextOverflowTooltip';

const Loader = ({ count = 3 }) => {
  return (
    <>
      {Array.from({ length: count }).map((_, index) => (
        <div key={index} className="chat-uploaded-files border mb-3">
          <div className="d-flex p-2 align-items-center gap-2">
            <Skeleton variant="circular" width={40} height={40} />
            <div className="d-flex flex-column">
              <Skeleton variant="text" width={120} height={12} />
              <Skeleton variant="text" width={80} height={12} />
            </div>
          </div>
        </div>
      ))}
    </>
  );
};

const FileItem = ({ item, onRemoveFile }) => {
  const fileType = MimeFileTypes[item?.file?.mimeType] || 'File';

  return (
    <div className="chat-uploaded-files border">
      <div className="d-flex align-items-center w-100 p-2 justify-content-between">
        <div className="d-flex gap-1 flex-fill align-items-center">
          <div className="files-icon">
            <LucideIcon
              icon={getIconByMimeType(item.file.mimeType).icon}
              size={22}
              color={getIconByMimeType(item.file.mimeType).color}
            />
          </div>
          <div className="files-info">
            <TextOverflowTooltip
              maxLength={35}
              textStyle="text-black font-weight-medium font-size-sm"
              text={item?.file?.downloadName}
            />
            <span className="fs-7 text-black-50">{fileType}</span>
          </div>
        </div>
        <TooltipComponent title="Delete">
          <a
            className="icon-hover-bg cursor-pointer"
            onClick={(e) => {
              e.preventDefault();
              onRemoveFile(item);
            }}
          >
            <LucideIcon icon="Trash2" />
          </a>
        </TooltipComponent>
      </div>
    </div>
  );
};

const FilesModal = ({ show, setShow, chatId }) => {
  const [loading, setLoading] = useState(true);
  const [files, setFiles] = useState([]);
  const [componentsToDelete, setComponentsToDelete] = useState([]);
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const getChatFiles = async () => {
    try {
      const { data } = await ChatService.getChatFiles(chatId);
      setFiles(data);
    } catch (e) {
      console.log(e);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (chatId && show) {
      getChatFiles();
    }
  }, [chatId, show]);

  const handleClose = () => {
    setShow(false);
    overflowing();
  };

  const removeFile = (item) => {
    setComponentsToDelete([item]);
    setOpenDeleteModal(true);
  };

  const handleDeleteContent = async () => {
    try {
      await ChatService.deleteChatFile(componentsToDelete[0].chatFileId);
      getChatFiles();
      setSuccessMessage('File Deleted');
      setOpenDeleteModal(false);
    } catch (e) {
      console.log(e);
    } finally {
      setOpenDeleteModal(false);
    }
  };
  return (
    <>
      <AlertWrapper>
        {successMessage && (
          <Alert
            color="success"
            message={successMessage}
            setMessage={setSuccessMessage}
          />
        )}
        {errorMessage && (
          <Alert
            color="danger"
            message={errorMessage}
            setMessage={setErrorMessage}
          />
        )}
      </AlertWrapper>
      <DeleteConfirmationModal
        showModal={openDeleteModal}
        setShowModal={setOpenDeleteModal}
        setSelectedCategories={setComponentsToDelete}
        event={handleDeleteContent}
        itemsConfirmation={componentsToDelete}
        heading="Delete File"
        description="Are you sure you want to delete this file?"
      />
      <RightSlidingDrawer
        open={show}
        containerWidth={450}
        modalClass="bg-white"
        toggleDrawer={handleClose}
      >
        <Card className="p-0 h-100 shadow-none border-0" style={{ width: 450 }}>
          <CardHeader className="justify-content-between gap-2 align-items-center">
            <div className="d-flex flex-column align-items-start">
              <h3 className="mb-0 d-flex align-items-start gap-2 text-capitalize">
                <LucideIcon
                  icon="Files"
                  size={18}
                  clazz="position-relative"
                  style={{ top: 3 }}
                />

                <span className="position-relative">Files</span>
              </h3>
            </div>
            <a
              className="icon-hover-bg cursor-pointer p-0"
              onClick={(e) => {
                e.preventDefault();
                handleClose();
              }}
            >
              <LucideIcon icon="X" clazz="rounded-circle p-0" />
            </a>
          </CardHeader>
          <CardBody
            id="chatBoxFiles"
            className="h-100 p-3 overflow-y-auto chat-files-modal"
          >
            {loading ? (
              <Loader />
            ) : (
              <>
                {files?.length ? (
                  <div className="d-flex flex-column gap-3">
                    {files?.map((item) => (
                      <FileItem
                        key={item.fileId}
                        item={item}
                        onRemoveFile={removeFile}
                      />
                    ))}
                  </div>
                ) : (
                  <NoDataFound
                    icon="file_copy"
                    iconStyle="text-gray-search font-size-4em"
                    description="No files found"
                    descriptionStyle="text-gray-search"
                  />
                )}
              </>
            )}
          </CardBody>
        </Card>
      </RightSlidingDrawer>
    </>
  );
};

const useChatFilesModal = () => {
  const [showModal, setShowModal] = useState(false);
  const [chatId, setChatId] = useState('');

  const FilesModalCallback = useCallback(() => {
    return (
      <FilesModal show={showModal} setShow={setShowModal} chatId={chatId} />
    );
  }, [showModal, setShowModal, chatId, setChatId]);

  return useMemo(
    () => ({
      setShowModal,
      setChatId,
      FilesModal: FilesModalCallback,
    }),
    [setShowModal, FilesModalCallback]
  );
};

export default useChatFilesModal;
