import React, { createContext, useContext, useEffect, useState } from 'react';

export const TenantContext = createContext({
  tenant: {},
  setTenant: () => null,
  count: 0,
  setCount: () => null,
});

export const TenantProvider = (props) => {
  const [tenant, setTenant] = useState(null);
  const [count, setCount] = useState(0);
  const [isLoadingTenantInfo, setIsLoadingTenantInfo] = useState(true);

  const getTenantInfo = async () => {
    try {
      setIsLoadingTenantInfo(true);
      const baseUrl = `${process.env.REACT_APP_API_URL}/api/env`;
      const params = new URLSearchParams(window.location.search);
      const tenantId = params.get('tenant_id');
      const url = new URL(baseUrl);
      if (tenantId) {
        url.searchParams.append('tenantId', tenantId);
      }
      const response = await fetch(url, {
        headers: new Headers({
          host: window.location.hostname,
        }),
      });

      if (!response.ok) {
        throw new Error(`Error fetching tenant: ${response.status}`);
      }

      const data = await response.json();

      if (!Object.keys(data).length) {
        throw new Error('No tenant data found');
      }

      setTenant(data);
      setCount((prevState) => prevState + 1);
    } catch (error) {
      console.error('Error fetching tenant info:', error);
    } finally {
      setIsLoadingTenantInfo(false);
    }
  };
  useEffect(() => {
    getTenantInfo();
  }, []);

  return (
    <TenantContext.Provider
      value={{ tenant, setTenant, count, setCount, isLoadingTenantInfo }}
    >
      {props.children}
    </TenantContext.Provider>
  );
};

export const useTenantContext = () => {
  return useContext(TenantContext);
};
