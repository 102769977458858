import React, { useState } from 'react';
import PageTitle from '../../commons/PageTitle';
import { FormattedValue } from '../../../utils/Utils';
import TableSkeleton from '../../commons/TableSkeleton';
import Table from '../../GenericTable';
import TextOverflowTooltip from '../../commons/TextOverflowTooltip';
import TableRowHover from '../../commons/TableRowHover';
import StatCardList from '../shared/StatCardList';
import ProgressCard from '../shared/ProgressCard';

const Data = [
  {
    title: 'Integrated Payables Leads',
    icon: 'FileCheck',
    companies: 14,
    potentialRevenue: '349875',
    progress: '70',
    total: '500000',
    variant: 'primary',
  },
  {
    title: 'Integrated Receivables Leads',
    icon: 'Inbox',
    companies: 9,
    potentialRevenue: '298452',
    progress: '59.7',
    total: '500000',
    variant: 'success',
  },
  {
    title: 'Real-Time Payment Leads',
    icon: 'Bolt',
    companies: 11,
    potentialRevenue: '92748',
    progress: '37.1',
    total: '250000',
    variant: 'warning',
  },
  {
    title: 'Positive Pay Leads',
    icon: 'ShieldCheck',
    companies: 36,
    potentialRevenue: '112375',
    progress: '44.9',
    total: '250000',
    variant: 'primary',
  },
  {
    title: 'Commercial Card Leads',
    icon: 'CreditCard',
    companies: 24,
    potentialRevenue: '1427650',
    progress: '95.2',
    total: '1500000',
    variant: 'success',
  },
  {
    title: 'Merchant Services Leads',
    icon: 'ShoppingBag',
    companies: 32,
    potentialRevenue: '283925',
    progress: '56.8',
    total: '500000',
    variant: 'warning',
  },
];

const tableColumns = [
  {
    key: 'name',
    component: <span>Relationship Manager</span>,
    classes: 'w-200px',
  },
  {
    key: 'leads',
    component: <span>Leads</span>,
  },
  {
    key: 'revenue',
    component: <span>Potential Revenue</span>,
  },
];

const tableColumns2 = [
  {
    key: 'name',
    component: <span>Company Name</span>,
    classes: 'w-200px',
  },
  {
    key: 'revenue',
    component: <span>Potential Revenue</span>,
  },
  {
    key: 'product_interest',
    component: <span>Product Interest</span>,
  },
];

const rowData = [
  {
    name: 'John Smith',
    leads: 24,
    revenue: '127849',
    conversion_rate: '28.5',
    status: 'Success',
  },
  {
    name: 'Nora Jones',
    leads: 32,
    revenue: '120839',
    conversion_rate: '26.7',
    status: 'Success',
  },
  {
    name: 'Ben Lee',
    leads: 22,
    revenue: '101830',
    conversion_rate: '21.2',
    status: 'Medium',
  },
  {
    name: 'KJ Curtis',
    leads: 29,
    revenue: '98849',
    conversion_rate: '19.8',
    status: 'Medium',
  },
  {
    name: 'Mike Zhou',
    leads: 34,
    revenue: '89839',
    conversion_rate: '16.3',
    status: 'High',
  },
];

const rowData2 = [
  {
    name: 'Meridian Manufacturing Group',
    revenue: '230829',
    interest: 'Commercial Card',
  },
  {
    name: 'K&K Consulting',
    revenue: '98829',
    interest: 'Integrated Payables',
  },
  {
    name: 'North Valley Studios',
    revenue: '88029',
    interest: 'Merchant Services',
  },
  {
    name: 'Zeno Technologies Inc',
    revenue: '85948',
    interest: 'Real-Time Payments',
  },
  {
    name: 'Premium Logistics LLC',
    revenue: '77382',
    interest: 'Integrated Receivables',
  },
];

const LeadsHome = () => {
  const [loading] = useState(false);

  const rows = rowData?.map((item) => {
    return {
      ...item,
      dataRow: [
        {
          key: 'name',
          classes: 'px-0',
          component: (
            <div className="text-black text-truncate fw-bold d-flex align-items-center gap-2">
              <TextOverflowTooltip
                maxLength={25}
                textStyle="fw-bold text-truncate font-size-sm2 mb-0 z-index-100"
                text={item.name}
              />
            </div>
          ),
        },
        {
          key: 'leads',
          label: '',
          component: (
            <>
              <span>{item?.leads || 0}</span>
            </>
          ),
        },
        {
          key: 'revenue',
          label: '',
          component: (
            <>
              <TableRowHover zIndex="z-index-5" />
              <FormattedValue value={item?.revenue} type="currency" />
            </>
          ),
        },
      ],
    };
  });

  const rows2 = rowData2?.map((item) => {
    return {
      ...item,
      dataRow: [
        {
          key: 'name',
          classes: 'px-0',
          component: (
            <div className="text-black text-truncate fw-bold d-flex align-items-center gap-2">
              <TextOverflowTooltip
                maxLength={20}
                textStyle="fw-bold text-truncate font-size-sm2 mb-0 z-index-100"
                text={item.name}
              />
            </div>
          ),
        },
        {
          key: 'revenue',
          label: '',
          component: <FormattedValue value={item?.revenue} type="currency" />,
        },
        {
          key: 'product_interest',
          label: '',
          component: (
            <>
              <TableRowHover zIndex="z-index-5" />
              <span>{item?.interest || '--'}</span>
            </>
          ),
        },
      ],
    };
  });

  return (
    <div>
      <PageTitle page="Leads" />

      <div className="d-grid grid-col-3 gap-3 mb-3">
        {Data?.map((item, index) => (
          <ProgressCard item={item} potential={true} key={index} />
        ))}
      </div>

      <div className="d-grid grid-col-2 gap-3">
        <StatCardList
          item={{ icon: 'UserCheck', title: 'Leads by Relationship Manager' }}
        >
          <div className="d-flex flex-column">
            {loading ? (
              <TableSkeleton cols={4} rows={5} />
            ) : (
              <div className="table-min-height">
                <div className="table-responsive-md datatable-custom">
                  <div
                    id="datatable_wrapper"
                    className="dataTables_wrapper no-footer h-auto"
                  >
                    <Table
                      data={rows}
                      columns={tableColumns}
                      dataInDB={rowData?.length}
                      showLoading={loading}
                      staticData
                      emptyDataText="No leads by relationship manager"
                      headClass="bg-gray-table-head"
                    />
                  </div>
                </div>
              </div>
            )}
          </div>
        </StatCardList>
        <StatCardList
          item={{ icon: 'ChartBar', title: 'Top Leads by Potential Revenue' }}
        >
          <div className="d-flex flex-column">
            {loading ? (
              <TableSkeleton cols={4} rows={5} />
            ) : (
              <div className="table-min-height">
                <div className="table-responsive-md datatable-custom">
                  <div
                    id="datatable_wrapper"
                    className="dataTables_wrapper no-footer h-auto"
                  >
                    <Table
                      data={rows2}
                      columns={tableColumns2}
                      dataInDB={rowData2?.length}
                      staticData
                      showLoading={loading}
                      emptyDataText="No leads by potential revenue"
                      headClass="bg-gray-table-head"
                    />
                  </div>
                </div>
              </div>
            )}
          </div>
        </StatCardList>
      </div>
    </div>
  );
};

export default LeadsHome;
