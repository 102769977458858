import {
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Spinner,
} from 'reactstrap';
import { overflowing, removeBodyScroll } from '../../utils/Utils';

const SimpleModal = ({
  children,
  modalTitle,
  open,
  handleSubmit,
  buttonsDisabled,
  onHandleCloseModal,
  isLoading,
  customModal,
  buttonLabel,
  modalBodyClass,
  allowCloseOutside = true,
  close,
  footerBorder,
  showFooter = true,
  customFooter,
  ...rest
}) => {
  return (
    <Modal
      onOpened={() => {
        removeBodyScroll();
      }}
      onClosed={() => {
        overflowing();
      }}
      fade={false}
      isOpen={open}
      {...rest}
      className={customModal}
      toggle={allowCloseOutside ? onHandleCloseModal : null}
    >
      <ModalHeader tag="h3" className="p-3" toggle={onHandleCloseModal}>
        {modalTitle}
      </ModalHeader>
      <ModalBody
        className={`${
          modalTitle ? 'border-top' : ''
        } mb-0 p-3 ${modalBodyClass}`}
      >
        {children}
      </ModalBody>

      {showFooter && (
        <>
          {customFooter || (
            <ModalFooter className={`px-3 ${!footerBorder && 'border-0'}`}>
              <>
                <button
                  className="btn btn-white btn-sm"
                  onClick={onHandleCloseModal}
                  disabled={isLoading}
                >
                  Cancel
                </button>
                {handleSubmit && (
                  <button
                    type="button"
                    className="btn btn-primary btn-sm min-w-100 d-flex align-items-center justify-content-center"
                    onClick={handleSubmit}
                    disabled={isLoading || buttonsDisabled}
                  >
                    {isLoading ? (
                      <Spinner className="spinner-grow-xs" />
                    ) : (
                      <span>{buttonLabel}</span>
                    )}
                  </button>
                )}
              </>
            </ModalFooter>
          )}
        </>
      )}
    </Modal>
  );
};

export default SimpleModal;
