import {
  MenuItemsKeysAndIcons,
  MessageTypes,
  StaticHistory,
} from '../constants';
import ThreadLayout from '../layout/ThreadLayout';
import React from 'react';
import Skeleton from 'react-loading-skeleton';
const QuestionAnswerWrapperLoader = ({ children, className }) => {
  return (
    <div className={`my-4 question-wrapper position-relative ${className}`}>
      {children}
    </div>
  );
};
const QuestionLoader = () => {
  return (
    <QuestionAnswerWrapperLoader>
      <div className="d-flex align-items-center flex-row-reverse gap-2">
        <p
          className="font-size-sm2 message-bubble p-0 mb-0"
          style={{ borderBottomRightRadius: 0 }}
        >
          <Skeleton width={200} height={30} />
        </p>
      </div>
    </QuestionAnswerWrapperLoader>
  );
};
const AnswerLoader = () => {
  return (
    <QuestionAnswerWrapperLoader className="hover-actions">
      <div className="d-flex align-items-start gap-2">
        <p className="font-size-sm2 rounded p-0 mb-0">
          <Skeleton width={200} height={30} />
        </p>
      </div>
    </QuestionAnswerWrapperLoader>
  );
};
const ChatHistoryLoader = () => {
  const loaders = StaticHistory[1].chatAnswers;
  return (
    <div className="position-relative">
      {loaders.map((qA) => (
        <>
          {qA.type === MessageTypes.User ? (
            <QuestionLoader question={qA} />
          ) : (
            <AnswerLoader answer={qA} />
          )}
        </>
      ))}
    </div>
  );
};
const ThreadLayoutSkeleton = () => {
  return (
    <ThreadLayout
      ownerAndDate={<Skeleton width={100} height={10} />}
      heading={{
        ...MenuItemsKeysAndIcons.Thread,
        title: `Loading...`,
      }}
      controls={<Skeleton width={80} height={20} />}
    >
      <div className="w-100 pt-3 justify-content-center pb-1">
        <div className="chat-home-window-size mx-auto">
          <ChatHistoryLoader />
        </div>
      </div>
      <div className="chat-home-window-size mx-auto px-0 py-2 bg-white bottom-15 z-index-99 position-sticky">
        <Skeleton width="100%" height={50} style={{ borderRadius: '50rem' }} />
      </div>
    </ThreadLayout>
  );
};

export default ThreadLayoutSkeleton;
