/* eslint-disable no-unused-vars */
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { Card, CardBody, CardHeader, CardFooter } from 'reactstrap';

import { getTomorrowDate, FormattedValue } from '../../../../utils/Utils';
import GenericTable from '../../../../components/GenericTable';
import { ReportSkeletonLoader } from '../../ReportSkeletonLoader';
import DatePickerTraining from '../../../../components/dealsBreakdown/DatePickerTraining';
import ReportSearchOptionDropdown from '../../../../components/commons/ReportSearchOptionDropdown';
import { pipelinePerformanceSummaryColumns } from '../../../../utils/opportunities.constants';
import pipelineService from '../../../../services/pipeline.services';
import IdfTooltip from '../../../../components/idfComponents/idfTooltip';
import { StatItemColors } from '../../../../components/reports/reports.constants';
import useIsTenant from '../../../../hooks/useIsTenant';
import {
  changePaginationLimit,
  changePaginationPage,
} from '../../../Deals/contacts/utils';
import { paginationDefault } from '../../../../utils/constants';
import TableFooterStats from '../../../../components/TableFooterStats';
import Pagination from '../../../../components/Pagination';

const { isDemo } = useIsTenant();

const PipelinePerformanceOverview = () => {
  const [pipelinePerformance, setPipelinePerformance] = useState([]);
  const [loading, setLoading] = useState(false);
  const [pagination, setPagination] = useState(paginationDefault);
  const [paginationPage, setPaginationPage] = useState(paginationDefault);

  const [orderBy, setOrderBy] = useState('');
  const [order, setOrder] = useState(['', 'ASC']);

  const [show, setShow] = useState(false);
  const [pipelines, setPipelines] = useState([]);
  const [pipelineIds, setPipelinesIds] = useState([]);
  const [selectedPipelinesKeys, setSelectedPipelinesKeys] = useState([]);

  const [dateRange, setDateRange] = useState({
    startDate: new Date(1970, 0, 1),
    endDate: getTomorrowDate(),
  });

  const StatsDataFooter = [
    {
      id: 'total_value',
      icon: 'DollarSign',
      color: StatItemColors.financial,
      iconColor: 'text-rpt-green',
      label: 'Pipeline Value',
      value: '28100000',
      count: 28100000,
      rounded: true,
      type: 'currency',
    },
    {
      id: 'total_deals',
      icon: 'Layers',
      color: StatItemColors.volume,
      iconColor: 'text-rpt-blue',
      label: 'Total Deals',
      value: '1504',
      count: 1504,
      rounded: true,
      type: 'number',
    },
    {
      id: 'win_rate_percentage',
      icon: 'Percent',
      color: StatItemColors.performance,
      iconColor: 'text-rpt-green',
      label: 'Avg. Win Rate',
      value: '57.4',
      count: 57.4,
      type: 'percentage',
    },
    {
      id: 'average_cycle_time',
      icon: 'Clock4',
      color: StatItemColors.time,
      iconColor: 'rpt-orange-box-heading',
      label: 'Avg. Cycle Time',
      value: '22',
      count: 22,
      type: 'number',
    },
  ];

  const mapMonthlySalesPerformance = [
    ...pipelinePerformance?.map((item) => ({
      ...item,
      dataRow: [
        {
          key: 'pipelineName',
          component: <span>{item?.pipelineName}</span>,
        },
        {
          key: 'totalAmount',
          component: (
            <FormattedValue value={item?.totalAmount} type="currency" />
          ),
        },
        {
          key: 'totalDeals',
          component: <FormattedValue value={item?.totalDeals} />,
        },
        {
          key: 'winRate',
          component: <FormattedValue value={item?.winRate} type="percentage" />,
        },
        {
          key: 'averageCompletionTime',
          component: <FormattedValue value={item?.averageCompletionTime} />,
        },
      ],
    })),
  ];

  const getPipelines = async () => {
    const response = await pipelineService.getPipelines(1, 100);
    if (response?.data?.length) {
      setPipelines(response.data);
      setPipelinesIds(response.data.map((pipeline) => pipeline.id));
    }
  };

  const getPipelinePerformance = async () => {
    setLoading(true);
    const payload = {
      self: false,
      dateRange: {
        start: dateRange?.startDate ? moment(dateRange.startDate) : null,
        end: dateRange?.endDate ? moment(dateRange.endDate) : null,
      },
      orderBy: orderBy?.order,
    };
    console.log('payload', payload);

    if (pipelineIds?.length) {
      payload.pipelineId = pipelineIds;

      const response = await pipelineService.getPipelinesPerformance(payload);
      setPipelinePerformance(response);
    }
    setLoading(false);
  };

  const handleSortByData = (key) => {
    setOrderBy({
      order: `${key?.name?.orderBy} ${key?.name?.clicked.toLowerCase()}`,
    });
    setOrder([key?.name?.orderBy, key?.name?.clicked]);
  };

  useEffect(() => {
    getPipelines();
  }, []);

  useEffect(() => {
    getPipelinePerformance();
  }, [pipelineIds, order]);

  const toggleModify = () => {
    getPipelinePerformance();
  };

  return (
    <Card className="h-100 border-0 shadow-0">
      <CardHeader>
        <div className="d-flex justify-content-between align-items-center w-100">
          <IdfTooltip text="Pipeline Performance Overview" placement="bottom">
            <h4 className="card-title text-hover-primary mb-0 text-truncate">
              Pipeline Performance Overview
            </h4>
          </IdfTooltip>

          <div className="d-flex align-items-center mr-6 pr-9 gap-2">
            <DatePickerTraining
              range={dateRange}
              setRange={setDateRange}
              toggle={toggleModify}
              disablePastDate
              btnToggleStyle="mx-0 w-250"
            />
            {isDemo && (
              <ReportSearchOptionDropdown
                search
                maxLength={22}
                show={show}
                data={pipelines}
                setShow={setShow}
                showCheckAll={false}
                label={`Pipelines`}
                setQueryFilters={() => {}}
                scrollable="pr-3 text-left"
                handleOptionSelected={() => {}}
                customStyle={{ width: '250px' }}
                customKey={['name', 'id']}
                selectedData={selectedPipelinesKeys}
                handleAllSelect={() =>
                  setPipelinesIds(pipelines.map((pipeline) => pipeline.id))
                }
                setSelectedData={setSelectedPipelinesKeys}
                handleOnDone={(items) => {
                  setPipelinesIds(items);
                }}
                controlledTitle={
                  pipelines
                    ?.filter((i) => pipelineIds?.includes(i.id))
                    .map((i) => i.name)
                    .join(',') || ''
                }
              />
            )}
          </div>
        </div>
      </CardHeader>
      <CardBody className="p-0 flex-1 overflow-y-auto">
        {loading ? (
          <div className="w-100 d-block p-1">
            <ReportSkeletonLoader rows={15} height={15} />
          </div>
        ) : (
          <GenericTable
            checkbox={false}
            exportToCSV={true}
            usePagination={false}
            tableData={pipelinePerformance}
            headClass="bg-gray-table-head border-top"
            fileName="Pipeline Performance Overview"
            noDataInDbValidation={true}
            columns={pipelinePerformanceSummaryColumns}
            columnsToExport={pipelinePerformanceSummaryColumns}
            data={mapMonthlySalesPerformance}
            sortingTable={handleSortByData}
            sortingOrder={order}
          />
        )}
      </CardBody>
      <CardFooter className="border-top bg-gray-table-head p-2 border-bottom-0 border-left-0 border-right-0 rounded-0">
        <div className="d-flex align-items-center justify-content-between">
          <ul className="px-3 mb-0 flex-fill">
            {isDemo && <TableFooterStats stats={StatsDataFooter} />}
          </ul>
          <Pagination
            showPerPage
            paginationInfo={pagination}
            onPageChange={(newPage) => {
              changePaginationPage(newPage, setPaginationPage);
            }}
            onLimitChange={(perPage) => {
              changePaginationLimit(perPage, setPaginationPage);
            }}
          />
        </div>
      </CardFooter>
    </Card>
  );
};

export default PipelinePerformanceOverview;
