import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router';
import Header from '../../../components/organizationProfile/Header';
import AddContent from '../../../components/peopleProfile/AddContent';
import organizationService from '../../../services/organization.service';
import RelatedOrg from '../../../components/organizationProfile/relatedOrgs/RelatedOrg';
import Overview from '../../../components/organizationProfile/overview/Organization';
import RightBar from '../../../components/organizationProfile/overview/RightBar';
import AlertWrapper from '../../../components/Alert/AlertWrapper';
import Alert from '../../../components/Alert/Alert';
import stringConstants from '../../../utils/stringConstants.json';
import userService from '../../../services/user.service';
import fieldService from '../../../services/field.service';
import { useModuleContext } from '../../../contexts/moduleContext';
import {
  DetailTabs,
  getAccountsChildPermissions,
  isModuleAllowed,
  isPermissionAllowed,
} from '../../../utils/Utils';
import { useTenantContext } from '../../../contexts/TenantContext';
import AddOrganization from '../../../components/organizations/AddOrganization';
import Breadcrumb from '../../../utils/Breadcrumb';
import { LoadingDetailSkeleton } from '../../../components/LoadingDetailComponent';
import feedService from '../../../services/feed.service';
import activityService from '../../../services/activity.service';
import { PermissionsConstants } from '../../../utils/permissions.constants';
import checklistService from '../../../services/checklist.service';
import dealService from '../../../services/deal.service';
import contactService from '../../../services/contact.service';
import EditOrganization from '../../../components/organizationProfile/overview/EditOrganization';
import EditNaicsModal from '../../../components/organizations/EditNaicsModal';
import EditOrganizationInfo from '../../../components/organizations/EditOrganizationInfo';
import MenuAccordion from '../../../components/commons/MenuAccordion';
import useIsTenant from '../../../hooks/useIsTenant';
import StatusBadge from '../../../components/home/shared/StatusBadge';

const TABS = DetailTabs;

const Profile = ({ children }) => {
  const constants = stringConstants.deals.contacts.profile;
  const history = useHistory();
  const { organizationId, activityId } = useParams();
  const [showContactsModal, setShowContactsModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [profileInfo, setProfileInfo] = useState({});
  const [refreshRecentFiles, setRefreshRecentFiles] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [refreshOwners, setRefresOwners] = useState(false);
  const [activityIdOpen, setActivityIdOpen] = useState(activityId);
  const [organization, setOrganization] = useState({});
  const [refreshTabCounts, setRefreshTabCounts] = useState(0);

  const [getUser, setGetUser] = useState({});
  const [me, setMe] = useState(null);
  const [openCloneModal, setOpenCloneModal] = useState(false);
  const [editModal, setEditModal] = useState(false);
  const [editNaicsModal, setEditNaicsModal] = useState(false);
  const [editInfoModal, setEditInfoModal] = useState(false);
  const [, setOpenList] = useState(false);
  const { moduleMap } = useModuleContext();
  const { tenant } = useTenantContext();
  const { isDemo } = useIsTenant();
  const [tabCounts, setTabCounts] = useState({
    [TABS.Data]: 0,
    [TABS.Timeline]: 0,
    [TABS.Notes]: 0,
    [TABS.Checklist]: 0,
    [TABS.Activities]: 0,
    [TABS.Files]: 0,
    [TABS.Opportunities]: 0,
    [TABS.Contacts]: 0,
  });
  const isPrincipalOwner =
    me && profileInfo
      ? me?.role?.admin_access ||
        me?.role?.owner_access ||
        profileInfo?.assigned_user_id === me?.id
      : false;

  const loadCounts = async () => {
    const updatedType = getAccountsChildPermissions(
      tenant.modules,
      'companies'
    );
    const queryParams = {
      page: 1,
      limit: 1,
      self: true,
    };

    const orgTabs = [
      {
        tabId: TABS.Data,
        module: Object.values(PermissionsConstants.Reports),
        count: 0,
        countApiCall: () =>
          organizationService.getReports(organizationId, queryParams),
      },
      {
        tabId: TABS.Timeline,
        module: `${updatedType}_timeline`,
        count: 0,
        countApiCall: () => Promise.resolve({ pagination: { count: 0 } }),
      },
      {
        tabId: TABS.Notes,
        module: `${updatedType}_notes`,
        count: 0,
        countApiCall: () =>
          feedService.getNote(
            { contactId: null, organizationId, dealId: null },
            queryParams
          ),
      },
      {
        tabId: TABS.Checklist,
        module: PermissionsConstants.Checklist.checklist,
        count: 0,
        countApiCall: () =>
          checklistService.getOrgCheckLists({ ...queryParams, organizationId }),
      },
      {
        tabId: TABS.Activities,
        module: `activities_company_activities`,
        count: 0,
        countApiCall: () =>
          activityService.getActivity(
            { contactId: null, organizationId, dealId: null },
            queryParams
          ),
      },
      {
        tabId: TABS.Files,
        module: `${updatedType}_files`,
        count: 0,
        countApiCall: () =>
          feedService.getFiles(
            { contactId: null, organizationId, dealId: null },
            queryParams
          ),
      },
      {
        tabId: TABS.Opportunities,
        module: `pipelines`,
        count: 0,
        countApiCall: () =>
          dealService.getDeals(
            {
              contact_person_id: null,
              contact_organization_id: organizationId,
              dealId: null,
            },
            queryParams
          ),
      },
      {
        tabId: TABS.Contacts,
        module: 'contacts',
        count: 0,
        countApiCall: () =>
          contactService.getContactsByOrganizationId(
            { organizationId },
            queryParams
          ),
      },
    ];
    const enabledTabs = orgTabs?.filter((item) => {
      return isModuleAllowed(tenant?.modules, item?.module);
    });

    const countCalls = enabledTabs.map((cl) => cl.countApiCall());
    const responses = await Promise.allSettled(countCalls);
    const Counts = {};
    enabledTabs.forEach((tb, index) => {
      if (responses[index].status === 'fulfilled') {
        Counts[tb.tabId] =
          responses[index].value?.pagination?.count ||
          responses[index].value?.data?.pagination?.count;
      } else {
        Counts[tb.tabId] = 0;
        console.error(
          `Error in API call for tab ${tb.tabId}:`,
          responses[index].reason
        );
      }
    });
    setTabCounts(Counts);
  };

  useEffect(() => {
    getCurrentUser();
    loadCounts();
  }, [profileInfo]);

  useEffect(() => {
    refreshTabCounts > 0 && loadCounts();
  }, [refreshTabCounts]);

  useEffect(() => {
    if (refreshOwners) {
      setRefresOwners(false);
    }
  }, [refreshOwners]);

  useEffect(() => {
    if (organizationId) {
      getProfileInfo();
      getOrganizationById();
    }
  }, [organizationId]);

  const getCurrentUser = async () => {
    const me = await userService
      .getUserInfo()
      .catch((err) => console.error(err));

    setMe(me);
  };

  const goToHome = () => {
    history.push('/');
  };
  const permissionCheck = {
    collection: 'organizations',
    action: 'edit',
  };
  const getOrganizationById = async () => {
    setIsLoading(true);
    const organizationObj = await organizationService.getOrganizationById(
      organizationId
    );
    setOrganization(organizationObj);
  };

  const getProfileInfo = async (message) => {
    try {
      if (message) {
        setActivityIdOpen(''); // After activity update to not open activity modal
        switch (message) {
          case constants.contactUpdated:
            setSuccessMessage(constants.contactUpdated);

            break;
          case constants.errorContactUpdated:
            setErrorMessage(constants.errorContactUpdated);
            break;
          default:
            setSuccessMessage(message);
        }
      }
      const { data } = await fieldService.getFields('organization', {
        usedField: true,
      });
      const {
        data: { data: customFields },
      } = await organizationService.getCustomField(organizationId, {
        page: 1,
        limit: 50,
      });
      let customValues = {};
      Promise.all([
        organizationService.getOrganizationById(organizationId),
        organizationService.getFieldByOrganization(organizationId, {}),
      ])
        .then(([result, response]) => {
          if (!result) {
            goToHome();
          }
          const fields = response?.data?.sort((a, b) => {
            return a.field.order - b.field.order;
          });
          data.forEach((field) => {
            if (field.isCustom) {
              customFields.forEach((item) => {
                if (
                  field.key === item.field.key &&
                  field.field_type !== 'DATE'
                ) {
                  customValues = {
                    ...customValues,
                    [field.key?.toLowerCase().replace(/\s+/g, '')]:
                      field.field_type === 'CURRENCY'
                        ? item.value.substring(1)
                        : item.value,
                  };
                } else if (
                  field.key === item.field.key &&
                  field.field_type === 'DATE'
                ) {
                  customValues = {
                    ...customValues,
                    [field.key?.toLowerCase().replace(/\s+/g, '')]: new Date(
                      item.value
                    ),
                  };
                }
              });
            }
          });
          setProfileInfo({ ...result, ...customValues, fields });
        })
        .catch(() => {
          goToHome();
        });
    } catch (e) {
      console.log(e);
    } finally {
      setIsLoading(false);
    }
  };
  const getUserById = async () => {
    const data = await userService.getUserById(profileInfo?.assigned_user_id);
    setGetUser(data);
  };

  useEffect(() => {
    profileInfo?.assigned_user_id && getUserById();
  }, [profileInfo]);

  useEffect(() => {
    if (refreshRecentFiles) {
      getProfileInfo();
      setRefreshRecentFiles(false);
    }
  }, [refreshRecentFiles]);

  const updateLabel = (label) => {
    if (label.id === profileInfo?.label?.id) {
      setProfileInfo({ ...profileInfo, label });
    }
  };

  const isIdaAllowed = isModuleAllowed(
    tenant?.modules,
    PermissionsConstants.Ida.Ida
  );
  const isCompanySnapshotAllowed = isModuleAllowed(
    tenant?.modules,
    'accounts_companies_sidebar'
  );
  const isProspectLookupAllowed =
    isPermissionAllowed('prospects', 'view') &&
    isModuleAllowed(tenant?.modules, 'prospecting_prospect_lookup_sidebar');

  const isSidebarAllowed =
    isIdaAllowed || isCompanySnapshotAllowed || isProspectLookupAllowed;

  const renderKeyTakeaways = () => {
    const companyName = profileInfo?.name;
    const naicsCode = profileInfo?.naics_code;
    const industry = profileInfo?.industry;

    return (
      <div className="bg-white rounded">
        <div className="p-3">
          <ul className="d-flex flex-column gap-2 takeaways-list">
            <li className="fs-7 bullet-item">
              {`Your last meeting with ${companyName} was on March 12, 2025,
              discussing their seasonal working capital needs for Q2 inventory
              buildup. `}
              <StatusBadge
                showDot={false}
                skipMap={true}
                item={{
                  status: 'Info',
                  priority: 'Info',
                  statusText: 'Follow-up Date',
                }}
              />
            </li>
            <li className="fs-7 bullet-item">
              {`${companyName} has increased their ACH payment volume by 27%
              year-over-year, processing $1.2M in the last 30 days with 43%
              still using paper checks.`}
            </li>
            <li className="fs-7 bullet-item">
              {`Their $500k line of credit renewal is upcoming in 45 days. Average
              daily balance has been 62% utilized over the past 90 days. `}
              <StatusBadge
                showDot={false}
                skipMap={true}
                item={{
                  status: 'In Progress',
                  priority: 'info',
                  statusText: 'Priority',
                }}
              />
            </li>
            <li className="fs-7 bullet-item">
              {`Based on NAICS code ${naicsCode} (${industry}),
              similar clients have adopted AR automation and lockbox services
              with 34% higher efficiencies.`}
            </li>
            <li className="fs-7 bullet-item">
              <span className="font-weight-semi-bold">Personal Insight:</span>{' '}
              {`CFO Michael Roberts' daughter started college at Stanford this
              year; consider sending a congratulatory note.`}
            </li>
          </ul>
        </div>
      </div>
    );
  };

  const sidebarOptions = [
    {
      id: 1,
      label: 'Key Takeaways',
      icon: 'auto_awesome',
      iconClass: 'text-purple',
      component: renderKeyTakeaways(),
    },
  ];

  return (
    <>
      {isLoading ? (
        <div className="splitted-content-fluid bg-white position-relative container-fluid overflow-hidden">
          <LoadingDetailSkeleton />
        </div>
      ) : (
        <>
          {children}
          <div
            className={`splitted-content-fluid position-relative container-fluid overflow-hidden bg-white p-0 ${
              isSidebarAllowed ? 'content-with-insights' : ''
            }`}
          >
            <div className="pl-0 breadcrumb-mobile">
              {moduleMap.organization && <Breadcrumb moduleMap={moduleMap} />}
            </div>
            <AlertWrapper>
              <Alert
                color="success"
                message={successMessage}
                setMessage={setSuccessMessage}
              />
              <Alert
                message={errorMessage}
                setMessage={setErrorMessage}
                color="danger"
              />
            </AlertWrapper>
            {moduleMap.contact && (
              <Header
                me={me}
                service={organizationService}
                withFollowers={organizationId}
                mainOwner={getUser}
                organization={organization}
                data={profileInfo}
                setOpenCloneModal={setOpenCloneModal}
                setEditModal={setEditModal}
                contact={moduleMap.contact.singular}
                moduleMap={moduleMap.organization.singular}
                refreshOwners={refreshOwners}
                setRefresOwners={setRefresOwners}
                isPrincipalOwner={isPrincipalOwner}
                getProfileInfo={getProfileInfo}
                organizationId={organizationId}
                showContactsModal={showContactsModal}
                setShowContactsModal={setShowContactsModal}
                setEditNaicsModal={setEditNaicsModal}
                setEditInfoModal={setEditInfoModal}
              />
            )}
            <hr className="my-0" />
            <div className="row">
              <div className="col-lg-4 py-3_1 pr-0 companydetail-tab-aside custom-scrollbar">
                {isDemo && (
                  <div className="d-flex flex-column gap-3 px-3 pb-2">
                    <MenuAccordion
                      options={sidebarOptions}
                      defaultCollapsedIds={[1]}
                      specificWrapperClassByIds={[1]}
                      specificWrapperClass="gradient-border"
                      className="detail-page-takeaways list-unstyled mb-0"
                    />
                  </div>
                )}

                <Overview
                  data={profileInfo}
                  me={me}
                  labelType="organization"
                  setProfileInfo={setProfileInfo}
                  getProfileInfo={getProfileInfo}
                  isPrincipalOwner={isPrincipalOwner}
                  updateLabel={updateLabel}
                  permissionCheck={permissionCheck}
                />

                <div className="d-none">
                  {isModuleAllowed(
                    tenant?.modules,
                    getAccountsChildPermissions(
                      tenant?.modules,
                      'companies_related_companies'
                    )
                  ) &&
                    isPermissionAllowed(permissionCheck?.collection, 'view') &&
                    moduleMap.organization && (
                      <RelatedOrg
                        moduleMap={moduleMap.organization.singular}
                        organizationId={organizationId}
                        getProfileInfo={getProfileInfo}
                        isPrincipalOwner={isPrincipalOwner}
                        mainOwner={profileInfo?.assigned_user}
                      />
                    )}
                </div>
              </div>

              <div className="col-lg-8 pl-0 profile-col-right border-left">
                <div className="companydetail-tabs">
                  {moduleMap.task && (
                    <AddContent
                      moduleMap={moduleMap}
                      dataType="organization"
                      type="companies"
                      activityType="company"
                      organizationId={organizationId}
                      getProfileInfo={getProfileInfo}
                      setProfileInfo={profileInfo}
                      dataSection
                      organization={organization}
                      contactIs={'organization'}
                      setRefreshTabCounts={setRefreshTabCounts}
                      fromOrganization={true}
                      refreshRecentFiles={refreshRecentFiles}
                      setRefreshRecentFiles={setRefreshRecentFiles}
                      isPrincipalOwner={isPrincipalOwner}
                      me={me}
                      openActivityId={activityIdOpen}
                      tabCounts={tabCounts}
                    />
                  )}
                </div>
              </div>
            </div>

            {isSidebarAllowed && (
              <RightBar
                profileInfo={profileInfo}
                isLoading={isLoading}
                isPeople={false}
                moduleName="accounts_companies_sidebar"
              />
            )}

            {openCloneModal && (
              <AddOrganization
                moduleMap={moduleMap.organization.singular}
                openOrganization={openCloneModal}
                setOpenOrganization={setOpenCloneModal}
                setOpenList={setOpenList}
                organizationData={profileInfo}
                errorMessage={errorMessage}
                setErrorMessage={setErrorMessage}
                successMessage={successMessage}
                setSuccessMessage={setSuccessMessage}
                me={me}
              />
            )}

            {editModal && (
              <EditOrganization
                moduleMap={moduleMap.organization.singular}
                setEditModal={setEditModal}
                me={me}
                data={profileInfo}
                labelType="organization"
                setProfileInfo={setProfileInfo}
                getProfileInfo={getProfileInfo}
                isPrincipalOwner={isPrincipalOwner}
                updateLabel={updateLabel}
                permissionCheck={permissionCheck}
              />
            )}

            <EditNaicsModal
              show={editNaicsModal}
              setShow={setEditNaicsModal}
              profileInfo={profileInfo}
              getProfileInfo={getProfileInfo}
              setSuccessMessage={setSuccessMessage}
              isRequired={false}
            />

            {editInfoModal && (
              <EditOrganizationInfo
                show={editInfoModal}
                setShow={setEditInfoModal}
                profileInfo={profileInfo}
                mainOwner={getUser}
                getProfileInfo={getProfileInfo}
                setRefresOwners={setRefresOwners}
                setSuccessMessage={setSuccessMessage}
                setErrorMessage={setErrorMessage}
              />
            )}
          </div>
        </>
      )}
    </>
  );
};

export default Profile;
