import React, { useState } from 'react';
import AIWriter from 'react-aiwriter';
import Markdown from 'react-markdown';
import remarkGfm from 'remark-gfm';

const TypeWriter = ({ text, speed, onFinish }) => {
  const [animate, setAnimate] = useState(true);

  const handleFinish = () => {
    setAnimate(false);
    onFinish && onFinish();
  };

  return (
    <div className="markdown-body">
      {animate ? (
        <AIWriter delay={speed} onEnd={handleFinish} onFinish={handleFinish}>
          <Markdown remarkPlugins={[remarkGfm]}>{text}</Markdown>
        </AIWriter>
      ) : (
        <Markdown remarkPlugins={[remarkGfm]}>{text}</Markdown>
      )}
    </div>
  );
};

export default TypeWriter;
