import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import AlertWrapper from '../../Alert/AlertWrapper';
import Alert from '../../Alert/Alert';
import {
  AttachFilesText,
  AttachMaxFilesAllowedMsg,
  getFileSize,
  isModuleAllowed,
  overflowing,
  setDateFormat,
} from '../../../utils/Utils';
import SimpleModalCreation from '../../modal/SimpleModalCreation';
import ButtonIcon from '../../commons/ButtonIcon';
import Search from '../../manageUsers/Search';
import Table from '../../GenericTable';
import useFetchArchiveFiles from '../hooks/useFetchArchiveFiles';
import TooltipComponent from '../../lesson/Tooltip';
import DropZone from '../DropZone';
import { getIconByMimeType, maxFileLimit, validTypes } from '../constants';
import ArchiveService from '../../../services/archive.service';
import DeleteConfirmationModal from '../../modal/DeleteConfirmationModal';
import { Spinner } from 'react-bootstrap';
import LucideIcon from '../../commons/LucideIcon';
import Pagination from '../../Pagination';
import FilesDropdown from '../FilesDropdown';
import TextOverflowTooltip from '../../commons/TextOverflowTooltip';
import { useTenantContext } from '../../../contexts/TenantContext';
import { PermissionsConstants } from '../../../utils/permissions.constants';

const KnowledgeContentsModal = ({ show, setShow, knowledge, onUpload }) => {
  const { tenant } = useTenantContext();
  const isFileAttachmentsAllowed = isModuleAllowed(
    tenant?.modules,
    PermissionsConstants.Ida.FileAttachment
  );

  const fileInputRef = useRef(null);
  const [uploading, setUploading] = useState(false);
  const [componentsToDelete, setComponentsToDelete] = useState([]);
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const {
    files: archiveFiles,
    fetchArchiveFiles,
    loading,
    pagination,
    setPagination,
    goToPage,
    setSearchValue,
  } = useFetchArchiveFiles(knowledge?.archiveId);
  const [files, setFiles] = useState([]);
  const handleDrop = (acceptedFiles) => {
    const newImages = acceptedFiles.filter((file) =>
      validTypes.includes(file.type)
    );

    if (acceptedFiles.length > maxFileLimit) {
      return setErrorMessage(AttachMaxFilesAllowedMsg);
    }

    setFiles((prevImages) => [...prevImages, ...newImages]);
    const invalidFiles = acceptedFiles.filter(
      (file) => !validTypes.includes(file.type)
    );
    if (invalidFiles.length > 0) {
      setErrorMessage(
        `${invalidFiles
          .map((f) => f.type)
          .join(', ')} file(s) are not supported.`
      );
    }
  };
  const handleFileUpload = (event) => {
    const uploadedFiles = Array.from(event.target.files);
    if (files.length < 5) {
      setFiles((prevFiles) => [...prevFiles, ...uploadedFiles]);
    }
  };

  const removeContent = (item) => {
    setComponentsToDelete([item]);
    setOpenDeleteModal(true);
  };
  const columns = [
    {
      key: 'name',
      component: 'Name',
      classes: 'px-3',
    },
    {
      key: 'type',
      component: 'Type',
    },
    {
      key: 'uploaded',
      component: 'Uploaded',
    },
    {
      key: 'size',
      component: 'Size',
    },
    {
      key: 'action',
      component: '',
    },
  ];
  const rows = archiveFiles?.map((file) => ({
    id: file.archiveFileId,
    type: 'pdf',
    name: file.file.downloadName,
    dataRow: [
      {
        key: 'name',
        bgColor: 'px-3',
        component: (
          <span className="d-flex align-items-center gap-1">
            <LucideIcon
              size={18}
              icon={getIconByMimeType(file.file.mimeType).icon}
              color={getIconByMimeType(file.file.mimeType).color}
            />
            <TextOverflowTooltip
              maxLength={30}
              text={file?.file?.downloadName}
            />
          </span>
        ),
      },
      {
        key: 'type',
        bgColor: 'px-0',
        component: <span>{getIconByMimeType(file.file.mimeType).label}</span>,
      },
      {
        key: 'uploaded',
        component: <span>{setDateFormat(file.updatedAt)}</span>,
      },
      {
        key: 'size',
        component: <span>{getFileSize(file?.file?.size)}</span>,
      },
      {
        key: 'action',
        component: (
          <TooltipComponent title="Delete">
            <a
              className="icon-hover-bg cursor-pointer"
              onClick={(e) => {
                e.preventDefault();
                removeContent(file);
              }}
            >
              <LucideIcon icon="Trash2" clazz="d-flex" />
            </a>
          </TooltipComponent>
        ),
      },
    ],
  }));
  const [fileProgress, setFileProgress] = useState({});

  const uploadFile = async () => {
    try {
      setUploading(true);
      await ArchiveService.addFileToArchive(
        knowledge.archiveId,
        files,
        (percentCompleted, fileKey) => {
          setFileProgress((prevProgress) => ({
            ...prevProgress,
            [fileKey]: percentCompleted,
          }));
        }
      );
      setSuccessMessage(
        'File Uploaded. It may take a minute to appear in list.'
      );
      fetchArchiveFiles();
      onUpload();
      setFiles([]);
      setFileProgress({});
    } catch (e) {
      console.log(e);
    } finally {
      setUploading(false);
    }
  };
  useEffect(() => {
    if (files.length) {
      if (files.length > maxFileLimit) {
        setFiles([]);
        return setErrorMessage(AttachMaxFilesAllowedMsg);
      }
      uploadFile();
    }
  }, [files]);

  const handleDeleteContent = async () => {
    try {
      await ArchiveService.deleteArchiveFile(
        componentsToDelete[0].archiveFileId
      );
      fetchArchiveFiles();
      setSuccessMessage('File Deleted');
      setOpenDeleteModal(false);
      onUpload();
    } catch (e) {
      console.log(e);
    } finally {
      setOpenDeleteModal(false);
    }
  };
  const onPaginationChange = (newPage) => {
    setPagination((prev) => ({ ...prev, page: newPage }));
    goToPage(newPage);
  };
  const onLimitChange = (perPage) => {
    setPagination((prev) => ({ ...prev, limit: perPage }));
  };

  return (
    <>
      <AlertWrapper>
        {successMessage && (
          <Alert
            color="success"
            message={successMessage}
            time={8000}
            setMessage={setSuccessMessage}
          />
        )}
        {errorMessage && (
          <Alert
            color="danger"
            message={errorMessage}
            setMessage={setErrorMessage}
          />
        )}
      </AlertWrapper>
      <DeleteConfirmationModal
        showModal={openDeleteModal}
        setShowModal={setOpenDeleteModal}
        setSelectedCategories={setComponentsToDelete}
        event={handleDeleteContent}
        itemsConfirmation={componentsToDelete}
        heading="Delete File"
        description="Are you sure you want to delete this file?"
      />
      <SimpleModalCreation
        modalTitle="Sources"
        open={show}
        isLoading={false}
        deleteButton="yup"
        customFooterLeftArea={
          <Pagination
            showPerPage={false}
            paginationInfo={pagination}
            onPageChange={onPaginationChange}
            onLimitChange={onLimitChange}
          />
        }
        size="lg"
        bodyClassName="p-0"
        scrollable={true}
        onHandleCloseModal={() => {
          overflowing();
          setShow(false);
        }}
      >
        <DropZone type="input" onDrop={handleDrop} showFullDrag>
          <div className="d-flex align-items-center pt-3 pb-4 position-relative pr-3 justify-content-between">
            <Search
              onHandleChange={(e) => setSearchValue(e.target.value)}
              classnames="col-sm-5"
              searchPlaceholder={'Search sources'}
            />
            <div className="d-flex ml-auto align-items-center gap-1">
              {uploading && (
                <div className="d-flex">
                  <FilesDropdown
                    showControls={false}
                    files={files}
                    fileProgress={fileProgress}
                  />
                  <div className="d-flex border gap-1 btn btn-sm p-2 rounded btn-outline-primary text-primary align-items-center">
                    <Spinner animation="grow" className="spinner-grow-xs" />
                    <span>Uploading...</span>
                  </div>
                </div>
              )}

              {isFileAttachmentsAllowed ? (
                <>
                  <input
                    type="file"
                    ref={fileInputRef}
                    multiple
                    accept={validTypes.join(',')}
                    style={{ display: 'none' }}
                    onChange={handleFileUpload}
                  />
                  <ButtonIcon
                    useLucidIcon
                    color="outline-primary"
                    icon="RefreshCcw"
                    iconSize={14}
                    label=""
                    disabled={uploading}
                    onclick={() => {
                      fetchArchiveFiles();
                    }}
                    classnames="ml-auto"
                  />
                  <ButtonIcon
                    useLucidIcon
                    icon="Plus"
                    iconSize={14}
                    label="Add Files"
                    disabled={uploading}
                    onclick={() => {
                      fileInputRef.current.click();
                    }}
                    classnames="btn-sm ml-auto"
                  />
                </>
              ) : (
                ''
              )}
            </div>
            {isFileAttachmentsAllowed ? (
              <p
                dangerouslySetInnerHTML={{ __html: AttachFilesText }}
                className="mb-0 position-absolute right-0 pr-3 fs-8"
                style={{ bottom: 3 }}
              />
            ) : null}
          </div>
          <div
            style={{ minHeight: 300, maxHeight: 500 }}
            className="overflow-y-auto"
          >
            <Table
              columns={columns}
              actionPadding="h-100"
              data={rows}
              paginationInfo={{}}
              onPageChange={() => {}}
              emptyDataText="No sources found."
              emptyDataIcon="folder"
              title="files"
              headClass="bg-gray-table-head border-top"
              tableLoading={loading}
              usePagination={false}
              dataInDB={true}
              onClick={() => {}}
            />
          </div>
        </DropZone>
      </SimpleModalCreation>
    </>
  );
};
const useKnowledgeContentsModal = (onUpload) => {
  const [showModal, setShowModal] = useState(false);
  const [knowledge, setKnowledge] = useState({});
  const KnowledgeContentsModalCallback = useCallback(() => {
    return (
      <KnowledgeContentsModal
        show={showModal}
        setShow={setShowModal}
        knowledge={knowledge}
        onUpload={onUpload}
      />
    );
  }, [showModal, setShowModal]);

  return useMemo(
    () => ({
      showModal,
      setShowModal,
      setKnowledge,
      KnowledgeContentsModal: showModal
        ? KnowledgeContentsModalCallback
        : () => <div />,
    }),
    [setShowModal, setKnowledge, KnowledgeContentsModalCallback]
  );
};

export default useKnowledgeContentsModal;
