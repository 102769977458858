import { Card } from 'react-bootstrap';
import Table from '../NewGenericTable';
import React, { useEffect, useState } from 'react';
import checklistService from '../../services/checklist.service';
import {
  DATE_FORMAT,
  checkDueDate,
  getYesterdayDate,
  isPermissionAllowed,
} from '../../utils/Utils';
import moment from 'moment';
import ViewChecklist from '../fields/modals/ViewChecklist';
import { paginationDefault } from '../../utils/constants';
import AlertWrapper from '../Alert/AlertWrapper';
import Alert from '../Alert/Alert';
import { ChecklistStatuses } from '../../utils/checklist.constants';
import MaterialIcon from '../commons/MaterialIcon';
import Skeleton from 'react-loading-skeleton';
import OwnerAvatar from '../ActivitiesTable/OwnerAvatar';
import { sortingTable } from '../../utils/sortingTable';
import { useProfileContext } from '../../contexts/profileContext';
import ChecklistStatus from './ChecklistStatus';
import Avatar from '../Avatar';
import Pagination from '../../components/Pagination';
import DeleteConfirmationModal from '../modal/DeleteConfirmationModal';
import IdfTooltip from '../idfComponents/idfTooltip';

const ChecklistOwner = ({ checklist }) => {
  const [loader, setLoader] = useState(false);
  const [checklistOwner, setChecklistOwner] = useState([]);
  const getChecklistOwner = async () => {
    try {
      setLoader(true);
      const { data } = await checklistService.getChecklistOwners({
        organizationChecklistId: checklist?.organizationChecklistId,
        limit: 1,
        page: 1,
      });
      setChecklistOwner(data?.data || []);
    } catch (e) {
      console.log(e);
    } finally {
      setLoader(false);
    }
  };

  useEffect(() => {
    getChecklistOwner();
  }, []);

  return (
    <>
      {loader ? (
        <Skeleton width={100} height={10} />
      ) : (
        <>
          {checklistOwner ? (
            checklistOwner.map((owner, index) => (
              <OwnerAvatar key={owner} item={owner?.user} isMultiple={true} />
            ))
          ) : (
            <h5 className="ml-1 mb-0"> -- </h5>
          )}
        </>
      )}
    </>
  );
};

const ActivitiesChecklist = ({
  activeTabId,
  refresh,
  setCheckListCount,
  isChecklistFilterCheck,
  checklistFilter,
  setIsChecklistFilterCheck,
  selectedOwner,
  refreshCalendar,
  isFilterCheck,
  ownershipFilter,
  ChecklistStats,
}) => {
  const { profileInfo } = useProfileContext();
  const [openView, setOpenView] = useState(false);
  const [row, setRow] = useState({});
  const [pagination, setPagination] = useState(paginationDefault);
  const [dataInDB, setDataInDB] = useState(false);
  const [checkLists, setCheckLists] = useState([]);
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [showLoading, setShowLoading] = useState(true);
  const [order, setOrder] = useState([['initialDueDate', 'DESC']]);
  const yesterdayDate = getYesterdayDate();
  const [componentsToDelete, setComponentsToDelete] = useState([]);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);

  const getOwnerFilter = () => {
    if (selectedOwner?.type) {
      if (selectedOwner.type === 'me') {
        return {
          ownerId: profileInfo?.id,
        };
      } else if (selectedOwner.type === 'other') {
        return {
          ownerId: selectedOwner?.data?.id,
        };
      } else {
        return {};
      }
    }
    return {};
  };

  const getDateFilters = () => {
    const filters = isChecklistFilterCheck?.filters || [];
    if (filters.length) {
      return isChecklistFilterCheck.filters.reduce((acc, item) => {
        acc[item.key] = item.value;
        return acc;
      }, {});
    }
    return {};
  };

  const getChecklistData = async () => {
    setShowLoading(true);
    const filters = isChecklistFilterCheck?.filters
      ? isChecklistFilterCheck.filters.reduce(
          (obj, item) => {
            obj[item.key] = item.value;
            return obj;
          },
          { ...getOwnerFilter() }
        )
      : { ...getOwnerFilter() };

    const dateFilters = getDateFilters();
    try {
      const { data } = await checklistService.getOrgCheckLists({
        page: pagination?.page,
        limit: pagination?.limit,
        ...dateFilters,
        ...filters,
        ...(ownershipFilter?.filter || {}),
        ...(activeTabId !== 'all' &&
        activeTabId !== '' &&
        activeTabId !== 'overdue'
          ? {
              'organizationChecklistProgress[status]': activeTabId,
            }
          : activeTabId === 'overdue'
          ? {
              'organizationChecklistProgress[status]': [
                'inProgress',
                'pending',
              ],
              'initialDueDateRange[end]': yesterdayDate,
            }
          : ''),
        ...(checklistFilter?.checklistId
          ? { checklistId: checklistFilter?.checklistId }
          : ''),
        order,
      });
      const checklistData = {
        data: data?.data,
        pagination: data?.pagination,
      };
      setPagination(checklistData?.pagination);
      setCheckListCount(checklistData?.pagination);
      setDataInDB(Boolean(data?.pagination?.totalPages));
      setCheckLists(checklistData?.data);
    } catch (error) {
      console.log('Error fetching checklists:', error);
    } finally {
      setShowLoading(false);
    }
  };
  useEffect(() => {
    if (activeTabId || refresh > 0) {
      profileInfo?.id && getChecklistData();
    }
  }, [
    activeTabId,
    refresh,
    order,
    pagination?.page,
    pagination?.limit,
    isChecklistFilterCheck?.filters,
    checklistFilter,
    selectedOwner,
    isFilterCheck,
    ownershipFilter,
    profileInfo,
  ]);

  const handleRowClick = (data) => {
    setRow(data);
    setOpenView(true);
  };

  const changePaginationPage = (newPage) => {
    setPagination((prev) => ({ ...prev, page: newPage }));
  };

  const changePaginationLimit = (perPage) => {
    setPagination((prev) => ({ ...prev, limit: perPage }));
  };

  const sortTable = ({ name }) => {
    if (name === 'action') return null;
    sortingTable({ name, order, setOrder: (val) => setOrder([val]) }, true);
  };

  const removeItem = (item) => {
    setComponentsToDelete([item]);
    setOpenDeleteModal(true);
  };

  const handleOrgChecklistDelete = async () => {
    try {
      await checklistService.deleteOrganizationCheckList(
        componentsToDelete[0]?.organizationChecklistId
      );
      setSuccessMessage('Checklist Deleted');
      getChecklistData();
      setOpenDeleteModal(false);
    } catch (e) {
      console.log(e);
    } finally {
      setOpenDeleteModal(false);
    }
  };

  const CompanyLogoRenderer = (row) => {
    const item = row.data;
    return (
      <div
        onClick={() => handleRowClick(item)}
        className="d-flex gap-1 align-items-center"
      >
        <Avatar
          user={item?.organization}
          classModifiers="bg-primary-soft text-primary min-w-25"
          sizeIcon="fs-6"
          defaultSize="xs"
          type={'organization'}
        />
        <p className="font-weight-medium text-truncate fs-7_1 mb-0">
          {item?.organization?.name}
        </p>
      </div>
    );
  };

  const AssignedChecklistRenderer = (row) => {
    const item = row.data;
    return (
      <div onClick={() => handleRowClick(item)} className="text-black pl-2">
        {item?.checklist?.title || '--'}
      </div>
    );
  };

  const ChecklistOwnerRenderer = (row) => {
    const item = row.data;
    return (
      <div
        onClick={() => handleRowClick(item)}
        className="position-relative z-index-99 h-100 d-flex align-items-center"
      >
        <ChecklistOwner checklist={item} />
      </div>
    );
  };

  const ChecklistStatusRenderer = (row) => {
    const item = row.data;
    return (
      <div onClick={() => handleRowClick(item)}>
        <ChecklistStatus
          item={{
            status: item?.organizationChecklistProgresses?.every(
              (progress) => progress?.progress === 100
            )
              ? ChecklistStatuses.Completed.text
              : item?.organizationChecklistProgresses?.every(
                  (progress) =>
                    progress?.progress > 0 &&
                    item?.organizationChecklistProgresses?.every(
                      (progress) => progress?.progress < 100
                    )
                )
              ? ChecklistStatuses.InProgress.text
              : item?.organizationChecklistProgresses?.every(
                  (progress) => progress?.progress === 0
                )
              ? ChecklistStatuses.NotStarted.text
              : '',
          }}
        />
      </div>
    );
  };

  const CompletedAtRenderer = (row) => {
    const item = row.data;
    return (
      <div onClick={() => handleRowClick(item)} className="text-black">
        {item?.organizationChecklistProgresses?.every(
          (progress) => progress?.progress === 100
        )
          ? moment(
              item?.organizationChecklistProgresses[0]?.completedAt
            ).format(DATE_FORMAT)
          : '--'}
      </div>
    );
  };

  const DueDateRenderer = (row) => {
    const item = row.data;
    return (
      <div
        onClick={() => handleRowClick(item)}
        className={`${
          !item?.organizationChecklistProgresses[0]?.completedAt &&
          checkDueDate(item?.initialDueDate) === 'text-red'
            ? 'text-red'
            : ''
        }`}
      >
        {!item?.organizationChecklistProgresses[0]?.completedAt &&
        checkDueDate(item?.initialDueDate) ? (
          <span className="position-relative">
            <MaterialIcon
              icon="flag"
              rounded
              filled
              size="fs-flag-icon"
              clazz="text-red"
            />
          </span>
        ) : null}{' '}
        {moment(item?.initialDueDate).format(DATE_FORMAT)}
      </div>
    );
  };

  const ActionsRenderer = (row) => {
    const item = row.data;
    return (
      <div className="position-relative d-inline-flex pt-2 align-items-center">
        {item?.organizationChecklistId &&
          isPermissionAllowed('checklists', 'delete') && (
            <IdfTooltip text="Delete">
              <a
                className="d-flex rounded-circle align-items-center text-gray p-1 bg-hover-gray cursor-pointer"
                onClick={(e) => {
                  e.preventDefault();
                  removeItem(item);
                }}
              >
                <MaterialIcon icon="delete" size="fs-20" clazz="d-flex" />
              </a>
            </IdfTooltip>
          )}
      </div>
    );
  };

  const columnDefs = [
    {
      field: 'company_name',
      orderBy: 'organization.name',
      headerName: 'Company Name',
      pinned: 'left',
      lockPosition: 'left',
      cellRenderer: CompanyLogoRenderer,
    },
    {
      field: 'checklist_name',
      orderBy: 'checklist.title',
      headerName: 'Assigned Checklist',
      cellRenderer: AssignedChecklistRenderer,
    },
    {
      field: 'owner',
      orderBy: '',
      headerName: 'Owner',
      cellRenderer: ChecklistOwnerRenderer,
    },
    {
      field: 'status',
      headerName: 'Status',
      orderBy: 'organizationChecklistProgresses.status',
      cellRenderer: ChecklistStatusRenderer,
    },
    {
      field: 'completed_at',
      headerName: 'Completed At',
      orderBy: 'organizationChecklistProgresses.completedAt',
      cellRenderer: CompletedAtRenderer,
    },
    {
      field: 'due_date',
      orderBy: 'initialDueDate',
      headerName: 'Due Date',
      cellRenderer: DueDateRenderer,
      cellClass: (params) => {
        return !params.data?.organizationChecklistProgresses[0]?.completedAt &&
          checkDueDate(params.data?.initialDueDate)
          ? 'bg-red-soft'
          : '';
      },
    },
    {
      field: 'action',
      headerName: 'Action',
      cellRenderer: ActionsRenderer,
    },
  ];

  return (
    <>
      <AlertWrapper>
        <Alert
          message={errorMessage}
          setMessage={setErrorMessage}
          color="danger"
        />
        <Alert
          message={successMessage}
          setMessage={setSuccessMessage}
          color="success"
        />
      </AlertWrapper>
      <div className="flex-1">
        <Card className="mb-0 card-0">
          <Card.Body className="p-0">
            <div className="table-responsive-md datatable-custom">
              <Table
                stickyColumn
                columns={columnDefs}
                tableId="checklists"
                actionPadding="h-100"
                data={checkLists}
                paginationInfo={{}}
                emptyDataText="This record doesn't have any checklists"
                emptyDataIcon="checklist"
                title="Checklist"
                headClass="bg-gray-table-head border-top"
                onPageChange={changePaginationPage}
                dataInDB={dataInDB}
                sortingTable={sortTable}
                sortingOrder={order}
                permission={{ collection: 'checklists' }}
                usePagination={true}
                tableLoading={showLoading}
                showLoading={showLoading}
              />
            </div>
          </Card.Body>
        </Card>
      </div>
      <div className="border-top bg-gray-table-head">
        <div className="d-flex py-1">
          <ul className="px-3 mb-0 flex-fill">
            <ChecklistStats
              parentFilter={isChecklistFilterCheck}
              selectedOwner={selectedOwner}
              isFilterCheck={isFilterCheck}
            />
          </ul>
          <div className="py-1 ml-auto">
            <Pagination
              showPerPage
              paginationInfo={pagination}
              onPageChange={changePaginationPage}
              onLimitChange={changePaginationLimit}
            />
          </div>
        </div>
      </div>
      {isPermissionAllowed('checklists', 'view') && (
        <ViewChecklist
          openModal={openView}
          setOpenModal={setOpenView}
          setIsChecklistFilterCheck={setIsChecklistFilterCheck}
          isChecklistFilterCheck={isChecklistFilterCheck}
          setSuccessMessage={setSuccessMessage}
          setErrorMessage={setErrorMessage}
          checklist={row}
          activityChecklist={true}
          getChecklistData={getChecklistData}
          organization={{ name: row?.name }}
          readonly={true}
        />
      )}

      <DeleteConfirmationModal
        showModal={openDeleteModal}
        setShowModal={setOpenDeleteModal}
        setSelectedCategories={setComponentsToDelete}
        event={handleOrgChecklistDelete}
        itemsConfirmation={componentsToDelete}
        heading={
          componentsToDelete?.length > 1
            ? 'Delete Checklists'
            : 'Delete Checklist'
        }
        description={
          componentsToDelete?.length > 1
            ? 'Are you sure you want to delete all the selected checklists?'
            : 'Are you sure you want to delete this checklist?'
        }
      />
    </>
  );
};
export default ActivitiesChecklist;
