export const opportunitiesReportsPages = [
  {
    key: '30',
    name: '30',
    filter: '',
  },
  {
    key: '50',
    name: '50',
    filter: '',
  },
  {
    key: '100',
    name: '100',
    filter: '',
  },
  {
    key: '300',
    name: '300',
    filter: '',
  },
  {
    key: '500',
    name: '500',
    filter: '',
  },
];

export const opportunitiesPerformanceSummaryColumns = [
  {
    key: 'name',
    component: 'Name',
    orderBy: 'name',
  },
  {
    key: 'sales',
    component: 'Sales',
    orderBy: 'sales',
  },
  {
    key: 'total_deals',
    component: 'Deals',
    orderBy: 'total_deals',
  },
  {
    key: 'win_rate',
    component: 'Win Rate %',
    orderBy: 'win_rate',
  },
];

export const monthlySalesPerformanceColumns = [
  {
    key: 'date',
    component: 'Month',
    orderBy: 'date',
    width: '250px',
  },
  {
    key: 'sales',
    component: 'Total Sales',
    orderBy: 'sales',
  },
  {
    key: 'no_of_deal',
    component: 'No of Deals',
    orderBy: 'no_of_deal',
  },
  {
    key: 'won_deals',
    component: 'Won Deals',
    orderBy: 'won_deals',
  },
  {
    key: 'won_deals_percentage',
    component: '% Won',
    orderBy: 'won_deals_percentage',
  },
];

export const individualSalesOverviewColumns = [
  {
    key: 'sales_person',
    component: 'Sales Person',
    orderBy: 'sales_person',
  },
  {
    key: 'sales',
    component: 'Sales',
    orderBy: 'sales',
  },
  {
    key: 'no_of_deal',
    component: 'No of Deals',
    orderBy: 'no_of_deal',
  },
  {
    key: 'won_deals',
    component: 'Won Deals',
    orderBy: 'won_deals',
  },
  {
    key: 'won_deals_percentage',
    component: 'Won Rate %',
    orderBy: 'won_deals_percentage',
  },
  {
    key: 'average_deal_value',
    component: 'Average Deal value',
    orderBy: 'average_deal_value',
  },
];

export const productSalesSnapshotColumns = [
  {
    key: 'name',
    component: 'Product',
    orderBy: 'name',
  },
  {
    key: 'totalAmount',
    component: 'Total Sales',
    orderBy: 'totalAmount',
  },
  {
    key: 'totalQuantity',
    component: 'Units Sold',
    orderBy: 'totalQuantity',
  },
  {
    key: 'averageAmount',
    component: 'Average Sale Price',
    orderBy: 'averageAmount',
  },
];

export const pipelinePerformanceSummaryColumns = [
  {
    key: 'pipelineName',
    component: 'Pipeline',
    orderBy: '"pipelineName"',
  },
  {
    key: 'totalAmount',
    component: 'Total Value',
    orderBy: '"totalAmount"',
  },
  {
    key: 'totalDeals',
    component: 'Total Deals',
    orderBy: '"totalDeals"',
  },
  {
    key: 'winRate',
    component: 'Win Rate %',
    orderBy: '"winRate"',
  },
  {
    key: 'averageCompletionTime',
    component: 'Average Cycle Time (Days)',
    orderBy: '"averageCompletionTime"',
  },
];
