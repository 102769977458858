import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { getRandomUUID, overflowing } from '../../../../utils/Utils';
import SimpleModalCreation from '../../../modal/SimpleModalCreation';
import useAccountStructureTabs from '../ReportTabs';
import {
  AccountStructureTabs,
  isAccountStructureTabFormValidated,
} from '../account.structure.constants';
import {
  ActionTypes,
  ReportFormErrorFields,
  ReportTypes,
} from '../../reports.constants';
import { usePagesContext } from '../../../../contexts/pagesContext';
import OrganizationService from '../../../../services/organization.service';
import ReportService from '../../../../services/report.service';
import useDeleteFraudReportModal from '../../../../hooks/reports/modals/useDeleteFraudReportModal';
import Alert from '../../../Alert/Alert';
import AlertWrapper from '../../../Alert/AlertWrapper';
import useLocalAccountStructureReport from '../useLocalAccountStructureReport';

const DRAFT_MODE = 'DRAFT';
const GenerateAccountStructureReportModal = ({
  show,
  setShow,
  organization,
  report,
  setReport,
  onGenerate,
}) => {
  const saveAndContinueText = 'Save & Continue';
  const [buttonText, setButtonText] = useState(saveAndContinueText);
  const [errorFields, setErrorFields] = useState(ReportFormErrorFields);
  const [loading, setLoading] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const { pageContext, setPageContext } = usePagesContext();
  const [errorMessage, setErrorMessage] = useState('');
  const {
    saveReport: saveLocalStorageReport,
    getReport: getLocalStorageReport,
    clearReport,
  } = useLocalAccountStructureReport();

  const handleSaveOrContinue = async () => {
    const currentIndex = AccountStructureTabs.findIndex(
      (tab) => tab.id === selectedTab.id
    );
    const isLastTab = currentIndex === AccountStructureTabs.length - 1;

    const formValidated = isAccountStructureTabFormValidated(
      selectedTab.id,
      pageContext.AccountStructureReportModal[selectedTab.key]
    );
    if (!formValidated.validated) {
      setErrorMessage(formValidated.message);
      return;
    }

    let reportObject = null;
    if (!isLastTab) {
      // save report
      if (!pageContext.AccountStructureReportModal?.id) {
        try {
          const draftReport = {
            ...pageContext,
            AccountStructureReportModal: {
              ...pageContext.AccountStructureReportModal,
              id: getRandomUUID(),
              mode: DRAFT_MODE,
            },
          };
          setPageContext(draftReport);
          saveLocalStorageReport(draftReport);
        } catch (e) {
          console.log(e);
        }
      } else {
        try {
          const localReport =
            getLocalStorageReport()?.AccountStructureReportModal;
          if (
            Object.keys(localReport).length &&
            localReport?.id &&
            localReport?.mode === DRAFT_MODE
          ) {
            const updatedReport = {
              ...pageContext,
              AccountStructureReportModal: {
                ...pageContext.AccountStructureReportModal,
                id: localReport?.id,
                mode: DRAFT_MODE,
              },
            };
            saveLocalStorageReport(updatedReport);
            setPageContext(updatedReport);
          }
        } catch (e) {
          console.log(e);
        } finally {
          setLoading(false);
        }
      }
      const nextTab = AccountStructureTabs[currentIndex + 1];
      setSelectedTab(nextTab);
    } else {
      try {
        setLoading(true);
        if (
          pageContext.AccountStructureReportModal?.id &&
          pageContext.AccountStructureReportModal.mode !== DRAFT_MODE
        ) {
          reportObject = await ReportService.updateReport(
            pageContext.AccountStructureReportModal?.id,
            {
              name: pageContext.AccountStructureReportModal.ReportInfo
                .companyName,
              date: pageContext.AccountStructureReportModal.ReportInfo
                .reportDate,
              type: ReportTypes.AccountStructure,
              manualInput: {
                ...pageContext.AccountStructureReportModal,
                FundsFlow: pageContext.AccountStructureReportModal.FundsFlow,
              },
            }
          );
        } else {
          delete pageContext.AccountStructureReportModal.id;
          delete pageContext.AccountStructureReportModal.mode;
          reportObject = await OrganizationService.createManualReport(
            organization.id,
            {
              name: pageContext.AccountStructureReportModal.ReportInfo
                .companyName,
              date: pageContext.AccountStructureReportModal.ReportInfo
                .reportDate,
              type: ReportTypes.AccountStructure,
              manualInput: {
                ...pageContext.AccountStructureReportModal,
                FundsFlow: pageContext.AccountStructureReportModal.FundsFlow,
              },
            }
          );
        }
        onGenerate(
          reportObject,
          pageContext.AccountStructureReportModal?.id
            ? ActionTypes.UPDATE
            : ActionTypes.ADD
        );
        overflowing();
        setShow(!show);
      } catch (e) {
        console.log(e);
      } finally {
        setLoading(false);
      }
    }
  };

  const { Tabs, selectedTab, setSelectedTab } =
    useAccountStructureTabs(setErrorMessage);

  useEffect(() => {
    const isLastTab = selectedTab.id === AccountStructureTabs.at(-1).id;
    setButtonText(isLastTab ? 'Save' : saveAndContinueText);
  }, [show, selectedTab]);

  const onHandleClose = async () => {
    if (
      !pageContext.AccountStructureReportModal?.id ||
      pageContext.AccountStructureReportModal.mode === DRAFT_MODE
    ) {
      if (pageContext.AccountStructureReportModal?.id) {
        try {
          setDeleteLoading(true);
          clearReport();
        } catch (e) {
          console.log(e);
        } finally {
          setDeleteLoading(false);
        }
      }
    }
    overflowing();
    setShow(!show);
  };
  const {
    DeleteFraudReportModal,
    setReport: setReportToDelete,
    setShowModal: setShowDeleteModal,
  } = useDeleteFraudReportModal(onGenerate, setShow);

  return (
    <>
      <AlertWrapper>
        <Alert
          message={errorMessage}
          setMessage={setErrorMessage}
          color="danger"
        />
      </AlertWrapper>
      <DeleteFraudReportModal />
      <SimpleModalCreation
        modalTitle={<Tabs />}
        modalHeaderClasses="align-items-start"
        open={show}
        bankTeam={false}
        backdrop="static"
        keyboard={false}
        saveButton={buttonText}
        bodyClassName="p-3 bg-gray-200 account-structure-report-modal"
        isLoading={loading}
        cancelLoading={deleteLoading}
        handleSubmit={handleSaveOrContinue}
        size="lg"
        deleteButton={
          pageContext?.AccountStructureReportModal?.id &&
          pageContext?.AccountStructureReportModal?.mode !== DRAFT_MODE
            ? {
                icon: '',
                label: 'Delete Report',
                show: true,
                loading: false,
                onClick: () => {
                  setReportToDelete({
                    key: pageContext?.AccountStructureReportModal?.id,
                    id: pageContext?.AccountStructureReportModal?.id,
                    ...pageContext?.AccountStructureReportModal.ReportInfo,
                  });
                  setShowDeleteModal(true);
                },
              }
            : null
        }
        onHandleCloseModal={onHandleClose}
      >
        {selectedTab.component(
          report,
          setReport,
          errorFields,
          setErrorFields,
          organization
        )}
      </SimpleModalCreation>
    </>
  );
};

const useGenerateAccountStructureReportModal = (onGenerate) => {
  const [showModal, setShowModal] = useState(false);
  const [organization, setModalOrganization] = useState({});
  const [report, setReport] = useState({});
  const GenerateAccountStructureReportModalCallback = useCallback(() => {
    return (
      <GenerateAccountStructureReportModal
        show={showModal}
        setShow={setShowModal}
        report={report}
        setReport={setReport}
        organization={organization}
        onGenerate={onGenerate}
      />
    );
  }, [showModal, setShowModal, organization, setModalOrganization]);

  return useMemo(
    () => ({
      setShowModal,
      setModalOrganization,
      setSelectedReport: setReport,
      GenerateAccountStructureReportModal: showModal
        ? GenerateAccountStructureReportModalCallback
        : () => <div />,
    }),
    [setShowModal, GenerateAccountStructureReportModalCallback]
  );
};

export default useGenerateAccountStructureReportModal;
