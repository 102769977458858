import React from 'react';
import ChecklistStatus from '../../checklist/ChecklistStatus';
import { ChecklistStatuses } from '../../../utils/checklist.constants';

const StatusMap = {
  Low: {
    ...ChecklistStatuses.NotStarted,
    status: ChecklistStatuses.NotStarted.text,
    statusText: 'Low',
  },
  High: {
    ...ChecklistStatuses.Deleted,
    status: ChecklistStatuses.Deleted.text,
    statusText: 'High',
  },
  Medium: {
    ...ChecklistStatuses.InProgress,
    status: ChecklistStatuses.InProgress.text,
    statusText: 'Medium',
  },
  Success: {
    ...ChecklistStatuses.Completed,
    status: ChecklistStatuses.Completed.text,
    statusText: 'Success',
  },
  Info: {
    ...ChecklistStatuses.Info,
    status: ChecklistStatuses.Info.text,
    statusText: 'Info',
  },
};
const StatusBadge = ({ item, showDot = true, skipMap = false }) => {
  let mappedItem = item;
  if (!skipMap) {
    mappedItem = StatusMap[item.priority];
  }
  return (
    <ChecklistStatus
      showDot={showDot}
      item={mappedItem}
      style={{ left: 3, bottom: 0 }}
    />
  );
};

export default StatusBadge;
