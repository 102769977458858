import moment from 'moment/moment';
import TableActions from '../../commons/TableActions';
import Table from '../../GenericTable';
import MoreActions from '../../MoreActions';
import {
  LatestEngagementsTableColumns,
  ReportTileStyles,
  ReportTypes,
} from '../../reports/reports.constants';
import MaterialIcon from '../../commons/MaterialIcon';
import React, { useEffect, useState } from 'react';
import OrganizationService from '../../../services/organization.service';
import ReportOwner from '../../reports/ReportOwner';
import { useProfileContext } from '../../../contexts/profileContext';
import {
  paginationDefault,
  TABLE_RECORDS_LIMIT,
} from '../../../utils/constants';
import { changePaginationPage } from '../../../views/Deals/contacts/utils';
import TableSkeleton from '../../commons/TableSkeleton';
import { scrollToTop, overflowing } from '../../../utils/Utils';
import reportService from '../../../services/report.service';
import DeleteConfirmationModal from '../../modal/DeleteConfirmationModal';
import TooltipComponent from '../../lesson/Tooltip';
import {
  getReportName,
  PdfFileNames,
} from '../../reports/reports.helper.functions';
import { sortingTable } from '../../../utils/sortingTable';
import useResyncTreasuryReportModal from '../../../hooks/reports/modals/useResyncTreasuryReportModal';
import useResyncMerchantReportModal from '../../../hooks/reports/modals/useResyncMerchantReportModal';
import useIsTenant from '../../../hooks/useIsTenant';
import useResyncMerchantReportSVBModal from '../../../hooks/reports/modals/useResyncMerchantReportSVBModal';
import useResyncFraudReportModal from '../../../hooks/reports/modals/useResyncFraudReportModal';
import useResyncAccountStructureReportModal from '../../../hooks/reports/modals/useResyncAccountStructureReportModal';

const LatestEngagementsTable = ({
  organization,
  organizationId,
  handleEditEngagement,
  onSelectEngagement,
  setSuccessMessage,
  setErrorMessage,
  refreshCounts,
}) => {
  const [engagements, setEngagements] = useState([]);
  const [pagination, setPagination] = useState({});
  const { profileInfo } = useProfileContext();
  const [loading, setLoading] = useState(false);
  const { isComericaBank, isSVB } = useIsTenant();
  const [paginationPage, setPaginationPage] = useState({
    ...paginationDefault,
    limit: TABLE_RECORDS_LIMIT,
  });
  const [showDeleteReportModal, setShowDeleteReportModal] = useState(false);
  const [reportsToDelete, setReportsToDelete] = useState([]);
  const [selectedEngagement, setSelectedEngagement] = useState({});
  const [order, setOrder] = useState([['updatedAt', 'DESC']]);
  const [actionMenu, setActionMenu] = useState(null);

  const getReports = async () => {
    try {
      setLoading(true);
      const response = await OrganizationService.getReports(organizationId, {
        ...paginationPage,
        order,
      });
      setEngagements(response?.data?.data);
      setPagination(response.data.pagination);
    } catch (error) {
      console.error('Failed to fetch response:', error);
      setEngagements([]);
    } finally {
      setLoading(false);
    }
  };
  const {
    SyncTMReportModal,
    setShowModal,
    setOrganization,
    setReport: setResyncReport,
  } = useResyncTreasuryReportModal(
    () => {
      setShowModal(false);
      setSuccessMessage('Report refreshed successfully.');
      getReports();
    },
    () => {}
  );

  const {
    SyncMerchantReportModal,
    setShowModal: setShowMerchantReportModal,
    setOrganization: setMerchantOrg,
    setReport: setResyncMerchantReport,
  } = useResyncMerchantReportModal(
    () => {
      setShowModal(false);
      setSuccessMessage('Report refreshed successfully.');
      getReports();
    },
    () => {}
  );

  const {
    SyncFraudReportModal,
    setShowModal: setShowFraudReportModal,
    setOrganization: setFraudOrg,
    setReport: setResyncFraudReport,
  } = useResyncFraudReportModal(
    () => {
      setShowModal(false);
      setSuccessMessage('Report refreshed successfully.');
      getReports();
    },
    () => {}
  );

  const {
    SyncMerchantReportSVBModal,
    setShowModal: setShowMerchantReportSVBModal,
    setOrganization: setMerchantSVBOrg,
    setReport: setResyncMerchantSVBReport,
  } = useResyncMerchantReportSVBModal(
    () => {
      setShowModal(false);
      setSuccessMessage('Report refreshed successfully.');
      getReports();
    },
    () => {}
  );

  const {
    SyncAccountStructureReportModal,
    setShowModal: setShowASReportModal,
    setOrganization: setASOrg,
    setReport: setResyncASReport,
  } = useResyncAccountStructureReportModal(
    () => {
      setShowASReportModal(false);
      setSuccessMessage('Report refreshed successfully.');
      getReports();
    },
    () => {}
  );

  const resyncCoreData = (rpt) => {
    setResyncReport(rpt);
    setOrganization(organization);
    setShowModal(true);
  };

  const resyncMerchantCoreData = (rpt) => {
    setResyncMerchantReport(rpt);
    setMerchantOrg(organization);
    setShowMerchantReportModal(true);
  };

  const resyncMerchantSVBCoreData = (rpt) => {
    setResyncMerchantSVBReport(rpt);
    setMerchantSVBOrg(organization);
    setShowMerchantReportSVBModal(true);
  };

  const resyncASCoreData = (rpt) => {
    setResyncASReport(rpt);
    setASOrg(organization);
    setShowASReportModal(true);
  };

  const resyncFraudComericaCoreData = (rpt) => {
    setResyncFraudReport(rpt);
    setFraudOrg(organization);
    setShowFraudReportModal(true);
  };

  const handleDelete = (engagement) => {
    setSelectedEngagement(engagement);
    setReportsToDelete([{ ...engagement, title: getReportName(engagement) }]);
    setShowDeleteReportModal(true);
  };

  const handleConfirmDeleteReport = async () => {
    try {
      await reportService.deleteReport(selectedEngagement.reportId);
      overflowing();
      setSuccessMessage('Report Deleted');
      setShowDeleteReportModal(false);
      getReports();
      refreshCounts();
    } catch (e) {
      console.log(e);
    }
  };

  const tableActions = [
    {
      id: 1,
      title: 'Edit',
      icon: 'edit',
      onClick: (report) => {
        handleEditEngagement(report);
      },
    },
  ];

  const getReportDate = (eng) => {
    if (eng?.type === ReportTypes.Treasury) {
      return eng?.manualInput?.value2?.split('T')[0] || eng.date; // this is the field that holds statement date and analysis_date in cord data import
    }
    return eng.date;
  };

  const isCoreDataEngagement = (eng) => {
    if (!eng || !eng.type || !eng.manualInput) return false;

    const hasCoreData = (report) =>
      Object.keys(report?.coreData || {}).length > 0;
    const hasAggregateData = (report) =>
      Object.keys(report?.aggregate || {}).length > 0;

    const report = eng.manualInput || {};

    switch (eng.type) {
      case ReportTypes.Treasury:
        return hasCoreData(report) || report.isAuto;

      case ReportTypes.Merchant:
        return (isSVB || isComericaBank) && hasAggregateData(report);

      case ReportTypes.Fraud:
      case ReportTypes.AccountStructure:
        return isComericaBank && (hasCoreData(report) || report.isAuto);

      default:
        return false;
    }
  };

  const handleActionMenuToggle = (rowId) => {
    if (actionMenu === rowId) {
      setActionMenu(null);
    } else {
      setActionMenu(rowId);
    }
  };
  const [isOrgPrimaryOwner, setIsOrgPrimaryOwner] = useState(false);

  const getOrganizationOwners = async () => {
    const { data: owners } = await OrganizationService.getOwners(
      organizationId,
      {
        page: 1,
        limit: 10,
      }
    );
    if (owners.length) {
      const ownerIds = owners.map((own) => own.user_id);
      setIsOrgPrimaryOwner(
        ownerIds.includes(profileInfo?.id) ||
          ownerIds.includes(organization?.assigned_user_id)
      );
    } else {
      setIsOrgPrimaryOwner(true);
    }
  };
  const data =
    engagements && engagements.length > 0
      ? engagements.map((engagement, index) => {
          return {
            ...engagement,
            dataRow: [
              {
                key: 'content',
                label: 'content',
                component: (
                  <div className={`d-flex`}>
                    <div
                      className={`${
                        ReportTileStyles[engagement.type]?.styleClass
                      } report-tile-icon shadow`}
                      style={{ width: '24px', height: '24px' }}
                    >
                      <TooltipComponent
                        capitalizeText={false}
                        title={PdfFileNames[engagement.type]}
                      >
                        <MaterialIcon
                          icon={ReportTileStyles[engagement.type]?.icon}
                          size="fs-6"
                          clazz={'text-primary'}
                        />
                      </TooltipComponent>
                    </div>
                    <span className="text-black text-truncate fw-bold d-block ml-2">
                      {moment(getReportDate(engagement)).format('MMMM YYYY')}
                    </span>
                  </div>
                ),
              },
              {
                key: 'created_by',
                label: 'created_by',
                component: (
                  <div className="d-flex align-items-center gap-1">
                    <ReportOwner
                      report={engagement}
                      me={profileInfo}
                      onlyIcon={true}
                    />
                    {moment(engagement.createdAt).utc().fromNow()}
                  </div>
                ),
              },
              {
                key: 'last_updated',
                label: 'last_updated',
                component: (
                  <span>{moment(engagement.updatedAt).utc().fromNow()}</span>
                ),
              },
              {
                key: 'actions',
                label: 'actions',
                component: (
                  <div className="d-flex justify-content-end mr-4">
                    {engagement?.type !== ReportTypes.Merchant &&
                      isOrgPrimaryOwner && (
                        <TableActions
                          item={engagement}
                          actions={tableActions}
                        />
                      )}
                    <a
                      onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                      }}
                    >
                      <MoreActions
                        icon="more_vert"
                        menuPlacement="down"
                        stopPropagation={false}
                        show={actionMenu === engagement.reportId}
                        onToggle={() =>
                          handleActionMenuToggle(engagement.reportId)
                        }
                        items={[
                          ...(isCoreDataEngagement(engagement)
                            ? [
                                {
                                  id: 'download',
                                  icon: 'refresh',
                                  name: 'Refresh Data',
                                },
                              ]
                            : []),
                          {
                            id: 'remove',
                            icon: 'delete',
                            name: 'Delete Report',
                          },
                        ]}
                        onHandleRemove={(e) => {
                          e.stopPropagation();
                          handleDelete(engagement);
                        }}
                        onHandleDownload={(e) => {
                          e.stopPropagation();
                          if (engagement.type === ReportTypes.Treasury) {
                            resyncCoreData(engagement);
                          } else if (engagement.type === ReportTypes.Merchant) {
                            if (isComericaBank) {
                              resyncMerchantCoreData(engagement);
                            } else if (isSVB) {
                              resyncMerchantSVBCoreData(engagement);
                            }
                          } else if (engagement.type === ReportTypes.Fraud) {
                            if (isComericaBank) {
                              resyncFraudComericaCoreData(engagement);
                            }
                          } else if (
                            engagement.type === ReportTypes.AccountStructure
                          ) {
                            if (isComericaBank) {
                              resyncASCoreData(engagement);
                            }
                          }
                        }}
                        toggleClassName="w-auto p-0 h-auto"
                      />
                    </a>
                  </div>
                ),
              },
            ],
          };
        })
      : [];

  useEffect(() => {
    if (organizationId) {
      getReports();
    }
  }, [organizationId, paginationPage, order]);

  useEffect(() => {
    if (organizationId) {
      getOrganizationOwners();
    }
  }, [organizationId]);

  const sortTable = ({ name }) =>
    sortingTable({ name, order, setOrder: (val) => setOrder([val]) }, true);

  return (
    <>
      <SyncTMReportModal />
      <SyncMerchantReportModal />
      <SyncMerchantReportSVBModal />
      <SyncFraudReportModal />
      <SyncAccountStructureReportModal />
      <DeleteConfirmationModal
        showModal={showDeleteReportModal}
        setShowModal={setShowDeleteReportModal}
        setSelectedCategories={setReportsToDelete}
        event={handleConfirmDeleteReport}
        itemsConfirmation={reportsToDelete}
        heading="Delete Report"
        description="Are you sure you want to delete this Report?"
      />
      <div className="d-flex flex-column">
        <h5 className="mx-4 card-title d-flex align-items-center gap-1 mb-2">
          Latest Engagements
        </h5>
        {loading ? (
          <TableSkeleton cols={4} rows={10} />
        ) : (
          <div className="table-min-height">
            <Table
              columns={LatestEngagementsTableColumns}
              data={data}
              dataInDB={!!data?.length}
              usePagination={true}
              paginationInfo={pagination}
              showLoading={loading}
              emptyDataIcon="inventory_2"
              sortingTable={sortTable}
              sortingOrder={order}
              emptyDataText="No engagement reports"
              headClass="bg-gray-table-head border-top"
              onPageChange={(newPage) => {
                scrollToTop();
                changePaginationPage(newPage, setPaginationPage);
              }}
              onClick={onSelectEngagement}
            />
          </div>
        )}
      </div>
    </>
  );
};

export default LatestEngagementsTable;
