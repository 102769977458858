import React from 'react';
import { Card, CardBody } from 'reactstrap';
import { Pie } from 'react-chartjs-2';
import MaterialIcon from '../../../commons/MaterialIcon';
import DigitalDonutChart from '../DigitalDonutChart';
import { numbersWithComma } from '../../../../utils/Utils';

const options = {
  responsive: true,
  plugins: {
    datalabels: {
      display: false,
    },
    legend: {
      display: false,
    },
    tooltip: {
      enabled: false,
    },
  },
};

const pie_chart_data = {
  labels: ['label1', 'label2', 'label3'],
  datasets: [
    {
      data: [70, 15, 15],
      backgroundColor: ['#3475AF', '#798DC6', '#ADA7D9'],
      borderColor: ['#3475AF', '#798DC6', '#ADA7D9'],
      borderWidth: 1,
    },
  ],
};

const MerchantFeesWidget = ({ widgetConfig, whenPrinting }) => {
  const isAggregateView = widgetConfig?.aggregate === true;
  const aggregateOrIndividualObject = isAggregateView
    ? widgetConfig?.data?.aggregate
    : widgetConfig?.data;

  const getEnabledWidgets = (widgets) => {
    return widgets?.filter((wg) => wg.enabled);
  };
  return (
    <>
      <Card className="report-widget">
        <CardBody className="justify-content-center align-items-center d-flex">
          <div className="flex-fill w-100">
            <div className="d-flex flex-column text-left">
              <h5 className="mb-0 hide-heading">{widgetConfig.header}</h5>
              <p
                className={`py-2 mb-0 px-2 ${
                  whenPrinting ? 'fs-9' : 'font-size-sm2'
                } text-left`}
              >
                <span className="text-black">{widgetConfig.description}</span>
              </p>
              <CardBody className="d-flex mx-2 justify-content-between gap-3 widget-border-2 border-2 bg-white rpt-rounded px-4">
                <div className="w-25 d-flex justify-content-center align-items-center">
                  <MaterialIcon
                    size="font-90"
                    icon="savings"
                    clazz="text-primary"
                    strokeWidth="1"
                    style={{
                      paddingTop: '20px',
                    }}
                  />
                </div>
                <div className="w-50 d-flex flex-column justify-content-center gap-2">
                  <p
                    className={`mb-0 ${
                      whenPrinting ? 'fs-9' : 'font-size-sm'
                    } text-left font-weight-medium`}
                  >
                    <span className="text-black">
                      {aggregateOrIndividualObject?.nonBankFeesPercentage
                        ? Math.round(
                            (aggregateOrIndividualObject?.nonBankFeesPercentage /
                              aggregateOrIndividualObject.totalFees) *
                              100
                          ) + '%'
                        : '0%'}{' '}
                      {widgetConfig.bankFeePercentage}
                    </span>
                  </p>
                </div>
                <div className="w-25 d-flex justify-content-center align-items-center">
                  <div
                    style={{
                      width: whenPrinting ? '90px' : '130px',
                      height: whenPrinting ? '90px' : '130px',
                    }}
                  >
                    <Pie data={pie_chart_data} options={options} />
                  </div>
                </div>
              </CardBody>
            </div>
            <div className="mt-5 d-flex px-2 flex-column gap-6">
              {getEnabledWidgets(widgetConfig.feePercentageCardsData)?.map(
                (card, index) => (
                  <div key={index} className="d-flex gap-4">
                    <DigitalDonutChart
                      item={{
                        percentage: aggregateOrIndividualObject?.[card.key]
                          ? aggregateOrIndividualObject?.[card.key] + '%'
                          : '0%',
                        key: 'checks',
                        chart: {
                          data: [
                            aggregateOrIndividualObject?.[card.key]
                              ? aggregateOrIndividualObject?.[card.key]
                              : 0,
                            100 - aggregateOrIndividualObject?.[card.key],
                          ],
                          colors: card.chartColors,
                        },
                      }}
                      containerClass=""
                      maxWidth="140px"
                      cutout="80%"
                    />

                    <div
                      className={`ml-2 p-3 w-100 d-flex flex-column gap-1 justify-content-center rpt-rounded bg-white border-2 ${card.borderClass} `}
                    >
                      {card.description.map((desc, idx) => (
                        <p
                          key={idx}
                          className={`mb-0 ${
                            whenPrinting ? 'fs-9' : 'font-size-sm2'
                          } text-center font-weight-medium`}
                        >
                          <span className="text-black">
                            {idx === 0
                              ? `Approximately ${
                                  aggregateOrIndividualObject?.[card.key]
                                    ? `${numbersWithComma(
                                        aggregateOrIndividualObject?.[card.key],
                                        {
                                          maximumFractionDigits: 2,
                                        }
                                      )}%`
                                    : '0%'
                                }`
                              : ''}
                            {desc}
                          </span>
                        </p>
                      ))}
                    </div>
                  </div>
                )
              )}
            </div>
          </div>
        </CardBody>
      </Card>
    </>
  );
};

export default MerchantFeesWidget;
