import axios from './axiosInstance';
import authHeader from './auth-header';

class TenantService {
  getTenant() {
    return axios
      .get(`/auth/context/tenant`, { headers: authHeader() })
      .then((response) => {
        return response.data;
      });
  }

  getTenants(order, pagination, includeOwners, filter) {
    const { limit, page } = pagination;
    return axios
      .get(`/tenants`, {
        params: { order, limit, page, includeOwners, ...filter },
        headers: authHeader(),
      })
      .then((response) => {
        return response.data;
      });
  }

  getSingleTenant(tenantId) {
    return axios
      .get(`/tenants/${tenantId}`, {
        headers: authHeader(),
      })
      .then((response) => {
        return response.data;
      })
      .catch((error) => error);
  }

  createTenant(data) {
    return axios
      .post(`/tenants`, data, { headers: authHeader() })
      .then((response) => {
        return response.data;
      })
      .catch((error) => error);
  }

  updateTenant(data, tenantId) {
    const { id, ...rest } = data;

    return axios
      .put(`/tenants/${tenantId}`, rest, {
        headers: authHeader(),
      })
      .then((response) => {
        return response.data;
      })
      .catch((error) => error);
  }

  getTenantsQuizConfig(tenantId) {
    return axios
      .get(`/tenants/${tenantId}/config`, {
        headers: authHeader(),
      })
      .then((response) => {
        return response.data;
      });
  }

  getTenantConfig(tenantId) {
    return axios
      .get(`/tenants/${tenantId}/configs`, {
        headers: authHeader(),
      })
      .then((response) => {
        return response?.data?.data;
      })
      .catch(() => []);
  }

  updateTenantConfig(tenantId, data) {
    return axios
      .put(`/tenants/${tenantId}/configs`, data, {
        headers: authHeader(),
      })
      .then((response) => {
        return response?.data?.data;
      })
      .catch(() => []);
  }
}

export default new TenantService();
