import { useCallback, useEffect, useMemo, useState } from 'react';
import { scrollToBottomChat } from '../utils/Utils';
import LucideIcon from '../components/commons/LucideIcon';

function BackTop({ el, style }) {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const elContainer = document.getElementById(el);

    const handleScroll = () => {
      const scrollValue = elContainer?.scrollTop;
      const { clientHeight } = elContainer;
      const { scrollHeight } = elContainer;
      const isAtBottom =
        Math.round(scrollValue + clientHeight + 10) >= scrollHeight;
      setIsVisible(!isAtBottom);
    };

    elContainer?.addEventListener('scroll', handleScroll);

    handleScroll();
    return () => {
      elContainer.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <>
      {isVisible && (
        <a
          onClick={(e) => {
            e.preventDefault();
            scrollToBottomChat(el);
          }}
          style={{ ...style }}
          id="scroller"
          className="position-absolute z-index-99 d-flex align-items-center justify-content-center p-1 w-8 h-8 transition-all bg-black border btn-hover-white rounded-circle shadow cursor-pointer"
        >
          <LucideIcon icon="ArrowDown" color="white" />
        </a>
      )}
    </>
  );
}

const useBackTop = (el, style = { top: -35, right: 0 }) => {
  const BackTopCallBack = useCallback(
    () => <BackTop el={el} style={style} />,
    []
  );

  return useMemo(
    () => ({
      BackTop: BackTopCallBack,
    }),
    [BackTopCallBack]
  );
};

export default useBackTop;
