import { useContext } from 'react';

import IdfPrincipalOwnerAvatar from './IdfPrincipalOwnerAvatar';
import IdfAdditionalOwnersListAvatars from './IdfAdditionalOwnersListAvatars';
import AlertWrapper from '../../Alert/AlertWrapper';
import Alert from '../../Alert/Alert';
import { AlertMessageContext } from '../../../contexts/AlertMessageContext';
import './IdfAdditionalOwners.css';

const IdfOwnersHeader = (props) => {
  const { successMessage, setSuccessMessage, errorMessage, setErrorMessage } =
    useContext(AlertMessageContext);

  const { className = 'mr-3', animateOnHover = false } = props;

  return (
    <>
      <div
        style={{ height: 35 }}
        className={`idf-owners transition-all rounded-pill px-1 position-relative d-flex align-items-center ${className} ${
          animateOnHover ? 'animate-hover' : ''
        }`}
      >
        <IdfPrincipalOwnerAvatar
          mainOwner={props?.me}
          {...props}
          isClickable={props.isClickable}
        />

        <IdfAdditionalOwnersListAvatars
          errorMessage={errorMessage}
          setErrorMessage={setErrorMessage}
          successMessage={successMessage}
          setSuccessMessage={setSuccessMessage}
          allowDelete={props.allowDelete}
          activity={props?.activity}
          ownerOption={props?.ownerOption}
          refreshOwners={props?.refreshOwners}
          ownerData={props.ownerData}
          type={props.headerType}
          animateOnHover={animateOnHover}
          setOwnerData={props?.setOwnerData}
          mainOwner={props.me}
          maxOwners={props.maxOwners}
          isClickable={props.isClickable}
          onClick={props.onClick}
          preowners={props.listOwners}
          {...props}
        />
      </div>

      <AlertWrapper>
        <Alert
          message={errorMessage}
          setMessage={setErrorMessage}
          color="danger"
        />
        <Alert
          message={successMessage}
          setMessage={setSuccessMessage}
          color="success"
        />
      </AlertWrapper>
    </>
  );
};

export default IdfOwnersHeader;
