import axios from './axiosInstance';
import authHeader from './auth-header';

class EnvService {
  async getEnv() {
    const response = await axios.get(`/env`, {
      headers: {
        host: window.location.hostname,
        ...authHeader(),
      },
    });

    return response.data;
  }
}

export default new EnvService();
