import React, { useState } from 'react';
import MaterialIcon from '../commons/MaterialIcon';

const NachaDisclaimer = () => {
  const [showMore, setShowMore] = useState(false);
  return (
    <div className="position-absolute d-flex flex-column z-index-250 bg-soft-yellow p-3 top-0 left-0">
      <div className="d-flex align-items-start gap-2">
        <MaterialIcon
          icon="info"
          isLucide={false}
          size="fs-21"
          clazz="text-yellow"
          filled
        />
        <p className="text-gray-800 mb-0">
          <p className="font-weight-semi-bold mb-2">Nacha Disclaimer</p>
          Permission to use Nacha Operating Rules & Guidelines granted by the
          National Automated Clearing House Association (Nacha). Copyright ©
          Nacha. All Rights Reserved.{!showMore ? '...' : ''}{' '}
          {showMore ? (
            <>
              Nacha has not reviewed and is not responsible for the content on
              or generated by this website or service. IDA users should own the
              most current version of the official Nacha Operating Rules &
              Guidelines. To purchase the Nacha Operating Rules & Guidelines, go
              to{' '}
              <a href="https://nacha.org" target="_blank" rel="noreferrer">
                nacha.org
              </a>{' '}
              or contact your Payments Association.
            </>
          ) : null}
          <a
            className="mt-1 btn-block cursor-pointer btn-link"
            onClick={() => setShowMore(!showMore)}
          >
            {showMore ? 'Show less' : 'Show more'}
          </a>
        </p>
      </div>
    </div>
  );
};

export default NachaDisclaimer;
