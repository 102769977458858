import SubHeading from '../../components/subheading';
import React, { useState, useEffect } from 'react';
import CategorySection from '../Resources/category/CategorySection';
import {
  COURSES_COMMING_SOON,
  LESSONS_COMMING_SOON,
} from '../../utils/constants';
import useUrlSearchParams from '../../hooks/useUrlSearchParams';
import NoDataFound from '../../components/commons/NoDataFound';
import lessonService from '../../services/lesson.service';
import courseService from '../../services/course.service';
const _ = require('lodash');

const OrganizationTopics = ({ setRefresh }) => {
  const [loading, setLoading] = useState(true);
  const [courses, setCourses] = useState([]);
  const [lessons, setLessons] = useState([]);

  const searchParams = useUrlSearchParams();
  const viewType = searchParams.get('viewType');

  const getCategories = async () => {
    try {
      setLoading(true);
      const myOrgData = [
        lessonService.getLessons({
          page: 1,
          limit: 100,
          restrictBy: 'private',
          requireContent: false,
          status: 'published',
        }),
        courseService.getCourses({
          page: 1,
          limit: 100,
          restrictBy: 'private',
          requireContent: false,
          status: 'published',
        }),
      ];
      const lessonsCourses = await Promise.all(myOrgData);
      const lessons = lessonsCourses[0].data;
      const courses = lessonsCourses[1].data;
      // Filter for duplicates lessons/courses
      const uniqueLessons = _.uniqBy(lessons, 'id');
      const uniqueCourses = _.uniqBy(courses, 'id').map((course) => ({
        ...course,
        category: {
          title: 'My Organization',
        },
      }));

      setLessons(uniqueLessons);
      setCourses(uniqueCourses);
    } catch (e) {
      console.log(e);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getCategories();
  }, []);

  return (
    <div>
      <div className="px-3 position-relative page-header-divider">
        <SubHeading title={'My Organization'} headingStyle="m-0 p-0" />
      </div>
      <div className="mt-3 px-3">
        <>
          <CategorySection
            slug={''}
            viewType={viewType}
            title={`Courses`}
            data={courses}
            loading={loading}
            commingSoonText={COURSES_COMMING_SOON}
            sectionType="course"
            myOrganization={true}
            setRefresh={setRefresh}
          />
          <CategorySection
            slug={''}
            title={`Lessons`}
            viewType={viewType}
            data={lessons}
            loading={loading}
            commingSoonText={LESSONS_COMMING_SOON}
            sectionType="lesson"
            myOrganization={true}
            setRefresh={setRefresh}
          />
        </>

        {!loading && !lessons?.length && !courses.length && (
          <NoDataFound
            title={`You currently have no courses and lessons`}
            description=""
            icon="manage_search"
            titleStyle="font-normal font-size-sm2 text-gray-search"
            containerStyle="text-gray-search my-6 py-6"
          />
        )}
      </div>
    </div>
  );
};

export default OrganizationTopics;
