import React from 'react';
import { Card } from 'react-bootstrap';
import Skeleton from 'react-loading-skeleton';

import { statsStyles, statusText } from '../../../../utils/checklist.constants';
import { getLightColor } from '../../../../components/reports/reports.constants';
import LucideIcon from '../../../../components/commons/LucideIcon';
import ColorBorder from '../../../../components/commons/ColorBorder';

const StatusCard = ({
  part,
  icon,
  index,
  count,
  isLast,
  status,
  isFirst,
  customTitle,
  customFooter,
  customDescription,
}) => {
  const color = statsStyles?.[status]?.color;
  return (
    <div
      className={`col-md-3 
    pl-2 pr-2 ${isFirst ? 'pl-3' : isLast ? 'pr-3' : ''}
  `}
      key={index}
    >
      <Card
        className="position-relative shadow-none"
        style={{ borderRadius: '0.75rem !important' }}
      >
        <Card.Body style={{ borderRadius: '0.75rem' }}>
          <ColorBorder color={color} />
          <div className="d-flex align-items-center justify-content-between">
            <h5 className="text-gray-800" style={{ flexGrow: 1 }}>
              {customTitle || statusText[status]}
            </h5>
            <div
              className="p-2 rounded-circle d-flex align-items-center"
              style={{ background: getLightColor(color || '') }}
            >
              {icon}
            </div>
          </div>
          <p
            className="font-size-225em mb-0 font-weight-bold"
            style={{ color }}
          >
            {customDescription || `${count} (${part}%)`}
          </p>
        </Card.Body>
      </Card>
    </div>
  );
};

const Stats = ({
  completed,
  inProgress,
  pending,
  overdue,
  totalCount,
  className,
  isLoading,
}) => {
  const stats = {
    completed: completed ?? 0,
    inProgress: inProgress ?? 0,
    pending: pending ?? 0,
    overdue: overdue ?? 0,
  };

  const last = Object.entries(stats).length - 1;

  if (isLoading) {
    return (
      <div className={`row mx-0 mb-3 ${className}`}>
        {[0, 1, 2, 3].map((index) => (
          <StatusCard
            key={index}
            icon={<></>}
            index={index}
            isFirst={index === 0}
            isLast={index === last}
            customTitle={<Skeleton />}
            customFooter={<Skeleton />}
            customDescription={<Skeleton />}
          />
        ))}
      </div>
    );
  }

  return (
    <div className={`row mx-0 mb-3 ${className}`}>
      {Object.entries(stats).map(([status, count], index) => {
        let part = totalCount > 0 ? (count * 100) / totalCount : 0;
        part = part > 0 && part < 1 ? part.toFixed(2) : Math.round(part);

        if (!status) return <></>;

        return (
          <StatusCard
            key={index}
            index={index}
            isFirst={index === 0}
            isLast={index === last}
            count={count}
            part={part}
            status={status}
            icon={
              <LucideIcon
                size={22}
                icon={statsStyles?.[status]?.icon}
                color={statsStyles?.[status]?.color}
              />
            }
          />
        );
      })}
    </div>
  );
};
export default Stats;
