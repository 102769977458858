import React, { useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import Table from '../NewGenericTable';
import {
  calculateStageProgress,
  isToFixedNoRound,
  setDateFormat,
  TableAvatarGroupSize,
} from '../../utils/Utils';
import routes from '../../utils/routes.json';
import IdfOwnersHeader from '../idfComponents/idfAdditionalOwners/IdfOwnersHeader';
import userService from '../../services/user.service';
import Avatar from '../../components/Avatar';
import stageService from '../../services/stage.service';
import Skeleton from 'react-loading-skeleton';
import { ProgressBar } from 'react-bootstrap';
import TextOverflowTooltip from '../commons/TextOverflowTooltip';
import {
  CLOSED_LOST,
  CLOSED_WON,
} from '../../views/Deals/pipelines/Pipeline.constants';
import MaterialIcon from '../commons/MaterialIcon';
import { useModuleContext } from '../../contexts/moduleContext';
import ActivitiesHistory from '../ActivitiesHistory/ActivitiesHistory';
import stringConstants from '../../utils/stringConstants.json';
import Alert from '../Alert/Alert';
import AlertWrapper from '../Alert/AlertWrapper';
import Pagination from '../Pagination';
import TooltipComponent from '../lesson/Tooltip';
import { usePipelineBoardContext } from '../../contexts/PipelineBoardContext';

const constants = stringConstants.deals.contacts.profile;

const StageProgressBar = ({ stages = [], stage = {}, variant = 'primary' }) => {
  // get all the pipeline stages and then dynamic calculate the current stage
  const [perc, setPerc] = useState(0);
  const [loading, setLoading] = useState(false);
  const { stages: contextStages } = usePipelineBoardContext();

  const loadStageProgress = async () => {
    try {
      setLoading(true);
      if (stage?.name === CLOSED_WON || stage.name === CLOSED_LOST) {
        setPerc(100);
      } else {
        setPerc(
          calculateStageProgress(stages.length ? stages : contextStages, stage)
        );
      }
    } catch (e) {
      console.log(e);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    (stages.length || contextStages.length) && loadStageProgress();
  }, [stages, contextStages]);

  return (
    <>
      {loading ? (
        <Skeleton height="10" width={80} />
      ) : (
        <ProgressBar
          now={parseInt(perc)}
          className="w-100 rounded"
          variant={variant}
        />
      )}
    </>
  );
};
const DealTable = ({
  data = [],
  paginationInfo,
  onPageChange,
  onLimitChange,
  handleEdit,
  selectedCourses,
  setSelectedCourses,
  onClickRow,
  service,
  showLoading,
  tableLoading,
  onAddDeal,
  dataInDB,
  sortingTable,
  sortingOrder,
  selectedPipeline,
  viewType,
  DealStats,
}) => {
  const [selectAll, setSelectAll] = useState(false);
  const [me, setMe] = useState(null);
  const [stages, setStages] = useState([]);
  const history = useHistory();
  const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [currentDeal, setCurrentDeal] = useState({});
  const [fromParentConfig, setFromParentConfig] = useState({});

  useEffect(() => {
    getCurrentUser();
  }, []);

  const getCurrentUser = async () => {
    const me = await userService
      .getUserInfo()
      .catch((err) => console.error(err));

    setMe(me);
  };

  const { moduleMap } = useModuleContext();

  const loadStages = async () => {
    try {
      const data = await stageService.getStages(selectedPipeline?.id);
      setStages(data || []);
    } catch (e) {
      setStages([]);
    }
  };

  const responseActivity = (msg) => {
    switch (msg) {
      case constants.activityAdded:
        return setSuccessMessage(constants.activityAdded);
      case constants.updatedActivity:
        return setSuccessMessage(constants.updatedActivity);
      case constants.activityError:
        return setErrorMessage(constants.activityError);
      case constants.errorUpdatedActivity:
        return setErrorMessage(constants.errorUpdatedActivity);
      default:
        return false;
    }
  };

  useEffect(() => {
    if (selectedPipeline?.id && viewType === 'list') {
      loadStages();
    }
  }, [viewType, selectedPipeline]);

  const handleRowClick = (event) => {
    const rowData = event.data;
    history.push(`${routes.dealsPipeline}/${rowData.id}`);
  };

  const DealRenderer = (row) => {
    const item = row.data;
    const { id, name } = item;
    const [menuShow, setMenuShow] = useState(false);
    const handleClick = (e, ref) => {
      e.stopPropagation();
      e.preventDefault();
      const show = !menuShow;
      setMenuShow(show);
      setCurrentDeal(show ? item : {});
      setFromParentConfig({
        show,
        item,
        parent: true,
        setShow: setMenuShow,
        toggleRef: ref,
      });
    };
    useEffect(() => {
      const handleOutsideClick = (e) => {
        if (
          menuShow &&
          !e.target.closest('.deal-add-circle') &&
          !e.target.closest('.ag-row')
        ) {
          setMenuShow(false);
        }
      };
      document.addEventListener('mousedown', handleOutsideClick);
      return () => {
        document.removeEventListener('mousedown', handleOutsideClick);
      };
    }, [menuShow]);

    return (
      <div className="d-flex align-items-center justify-content-between">
        <div>
          <Link
            to={`${routes.dealsPipeline}/${id}`}
            className="text-black text-truncate font-weight-medium"
          >
            {name}
          </Link>
        </div>
        <div
          ref={(ref) => {
            if (!ref) return;
            ref.onclick = (e) => handleClick(e, ref, id);
          }}
          className={`deal-add-circle ${menuShow ? 'active' : ''}`}
        >
          <TooltipComponent title="Add">
            <a className="icon-hover-bg text-primary">
              <MaterialIcon icon="add_circle" symbols />
            </a>
          </TooltipComponent>
        </div>
      </div>
    );
  };

  const OrganizationRenderer = (row) => {
    const organization = row.data?.organization;
    return (
      <div className="d-flex gap-1 align-items-center">
        <Avatar
          user={organization}
          classModifiers="bg-primary-soft text-primary min-w-25"
          sizeIcon="fs-6"
          defaultSize="xs"
          type="organization"
        />
        {organization?.name}
      </div>
    );
  };

  const StagesRenderer = (row) => {
    // const item = row.data;
    const stage = row.data?.stage;
    return (
      <div className="d-flex align-items-center">
        <div className="flex-fill w-20 deal-progress-bar">
          <StageProgressBar
            variant={
              stage?.name === CLOSED_WON
                ? 'success'
                : stage?.name === CLOSED_LOST
                ? 'danger'
                : 'primary'
            }
            stages={stages}
            stage={stage}
          />
        </div>
        <span className="flex align-items-center gap-2 flex-fill w-50 pl-2">
          <TextOverflowTooltip text={stage?.name} />{' '}
          {stage?.name === CLOSED_WON && (
            <MaterialIcon
              icon="thumb_up"
              clazz="text-success position-relative"
              size="fs-6"
              style={{ top: 3 }}
            />
          )}
          {stage?.name === CLOSED_LOST && (
            <MaterialIcon
              icon="thumb_down"
              clazz="text-red position-relative"
              size="fs-6"
              style={{ top: 4 }}
            />
          )}
        </span>
      </div>
    );
  };

  const ContactPersonRenderer = (row) => {
    const contact = row.data?.contact;
    return (
      <>
        {contact?.first_name || contact?.last_name ? (
          <div className="d-flex gap-1 align-items-center">
            <Avatar
              defaultSize="xs"
              isMultiple
              user={contact}
              classModifiers="min-w-25"
              hovering=""
            />
            <span>
              {contact?.first_name} {contact?.last_name || ''}
            </span>
          </div>
        ) : (
          <span> -- </span>
        )}
      </>
    );
  };

  const OwnersRenderer = (row) => {
    const item = row.data;
    const { id, assigned_user, owners } = item;

    const isPrincipalOwner =
      me && item
        ? me?.role?.admin_access || assigned_user?.id === me?.id
        : false;

    return (
      <div className="position-relative z-index-99 h-100 d-flex align-items-center">
        <IdfOwnersHeader
          showToolTip
          mainOwner={assigned_user}
          service={service}
          serviceId={id}
          listOwners={owners}
          isClickable={false}
          onClick={(e) => {
            e?.preventDefault();
            e?.stopPropagation();
          }}
          defaultSize="xs"
          // maxOwners={0}
          {...TableAvatarGroupSize}
          isprincipalowner={isPrincipalOwner}
          small
          addBtnBgGray
        />
      </div>
    );
  };

  const columnDefs = [
    {
      field: 'title',
      orderBy: 'name',
      headerName: `${moduleMap.deal.singular} Name`,
      pinned: 'left',
      lockPosition: 'left',
      minWidth: 230,
      cellRenderer: DealRenderer,
    },
    {
      field: 'value',
      orderBy: 'amount',
      headerName: 'Amount',
      cellRenderer: ({ data }) => (
        <span>{isToFixedNoRound(data.amount, 2)}</span>
      ),
    },
    {
      field: 'organization',
      headerName: `${moduleMap.organization.singular} Name`,
      cellRenderer: OrganizationRenderer,
    },
    {
      field: 'stages',
      headerName: 'stage',
      minWidth: 250,
      cellRenderer: StagesRenderer,
    },
    {
      field: 'contactPerson',
      headerName: 'Contact Person',
      cellRenderer: ContactPersonRenderer,
    },
    {
      field: 'expectedCloseDate',
      orderBy: 'date_closed',
      headerName: 'Closing Date',
      cellRenderer: ({ data }) => (
        <span>
          {data?.date_closed
            ? setDateFormat(data?.date_closed, 'MM/DD/YYYY')
            : '--'}
        </span>
      ),
    },
    {
      field: 'owner',
      headerName: 'Owners',
      cellRenderer: OwnersRenderer,
    },
  ];

  return (
    <div className="deal-table">
      {Object.keys(currentDeal)?.length > 0 ? (
        <ActivitiesHistory
          icon=""
          organizationId={currentDeal?.organization?.id}
          response={responseActivity}
          dealId={currentDeal?.id}
          activities={currentDeal?.activities}
          organization={currentDeal?.organization}
          deal={currentDeal}
          owner={currentDeal?.assigned_user}
          fromParentConfig={fromParentConfig}
          setFromParentConfig={setFromParentConfig}
        />
      ) : null}
      <div className="table-responsive-md datatable-custom">
        <AlertWrapper>
          <Alert
            color="success"
            message={successMessage}
            setMessage={setSuccessMessage}
          />
          <Alert
            color="danger"
            message={errorMessage}
            setMessage={setErrorMessage}
          />
        </AlertWrapper>
        <div id="datatable_wrapper" className="dataTables_wrapper no-footer">
          <Table
            selectedData={selectedCourses}
            setSelectedData={setSelectedCourses}
            selectAll={selectAll}
            setSelectAll={setSelectAll}
            tableId="opportunities"
            columns={columnDefs}
            data={data}
            stickyColumn
            onHandleEdit={handleEdit}
            onClick={onClickRow}
            paginationInfo={{}}
            toggle={onAddDeal}
            emptyDataText={`No ${moduleMap?.deal?.plural} available yet`}
            title={moduleMap?.deal?.plural}
            handleRowClick={handleRowClick}
            dataInDB={dataInDB}
            sortingTable={sortingTable}
            sortingOrder={sortingOrder}
            noDataInDbValidation={true}
            tableLoading={tableLoading}
            showLoading={tableLoading}
            stickyFooter={true}
            stickyClass="opportunity-list-view-table"
          />
        </div>
      </div>
      <div className="border-top bg-gray-table-head">
        <div className="d-flex py-1">
          <ul className="px-3 mb-0 flex-fill">
            <DealStats />
          </ul>
          <div className="py-1 ml-auto">
            <Pagination
              showPerPage
              paginationInfo={paginationInfo}
              onPageChange={onPageChange}
              onLimitChange={onLimitChange}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default DealTable;
