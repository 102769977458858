import React, { useState } from 'react';
import { Card, CardBody, CardFooter, CardHeader } from 'reactstrap';
import { paginationDefault } from '../../../../utils/constants';
import LessonTranscript from '../LessonTranscript';
import IdfTooltip from '../../../../components/idfComponents/idfTooltip';
import Pagination from '../../../../components/Pagination';
import ReportSearchOptionDropdown from '../../../../components/commons/ReportSearchOptionDropdown';
import ButtonFilterDropdown from '../../../../components/commons/ButtonFilterDropdown';
import {
  changePaginationLimit,
  changePaginationPage,
} from '../../../Deals/contacts/utils';
import useFetchUsers from '../../../../hooks/fetch/useFetchUsers';
import useSearchOptionDropdown from '../../../../hooks/fetch/useSearchOptionDropdown';
import useFetchTeams from '../../../../hooks/fetch/useFetchTeams';

const TrainingLessonTranscript = ({ dashboard }) => {
  // Lesson Type
  const LessonsFiltersList = [
    {
      key: 'all',
      name: 'All',
      filter: '',
    },
    {
      key: 'selfStudy',
      name: 'Self Study',
      filter: 'selfStudy',
    },
    {
      key: 'assignment',
      name: 'Assignment',
      filter: 'assignment',
    },
  ];

  const [queryFilterLessonTypes, setQueryFilterLessonTypes] = useState({});
  const [filterSelected, setFilterSelected] = useState({});
  const [openFilter, setOpenFilter] = useState(false);
  const [filterTabs, setFilterTabs] = useState('filters');

  const defaultAllFilter = {
    key: 'all',
    name: 'All',
    filter: '',
  };

  const [pagination, setPagination] = useState(paginationDefault);
  const [paginationPage, setPaginationPage] = useState(paginationDefault);

  const {
    users,
    searchQueryUsers,
    setSearchQueryUsers,
    handleOnDoneUsers,
    handleAllSelectUsers,
    queryFilterUsers,
  } = useFetchUsers({ callback: toggleModify });
  const {
    teams,
    searchQueryTeam,
    setSearchQueryTeam,
    handleAllSelect,
    handleOnDoneTeams,
    queryFilterTeams,
  } = useFetchTeams({ callback: toggleModify });

  const {
    selectedItem: selectedItemUser,
    setSelectedItem: setSelectedItemUser,
    show: showUsers,
    setShow: setShowUsers,
  } = useSearchOptionDropdown();

  const {
    selectedItem: selectedItemTeam,
    setSelectedItem: setSelectedItemTeam,
    show: showTeams,
    setShow: setShowTeams,
  } = useSearchOptionDropdown();

  const handleFilterSelect = (item) => {
    setQueryFilterLessonTypes({
      filters: [item],
    });
    setFilterSelected(item);
    toggleModify();
  };

  function toggleModify() {
    setPaginationPage((prev) => ({ ...prev, page: 1 }));
  }

  return (
    <Card className="h-100 border-0 shadow-0">
      <CardHeader>
        <div className="d-flex justify-content-between align-items-center w-100">
          <IdfTooltip text={dashboard?.name} placement="bottom">
            <h4 className="card-title mb-0 text-hover-primary text-truncate">
              {dashboard?.name}
            </h4>
          </IdfTooltip>
          <div className="d-flex align-items-center mr-6 pr-9 gap-2">
            <ReportSearchOptionDropdown
              selectedData={selectedItemTeam}
              data={teams}
              show={showTeams}
              label={`Team`}
              scrollable="pr-3 text-left"
              setShow={setShowTeams}
              showCheckAll={false}
              setSelectedData={setSelectedItemTeam}
              search={true}
              maxLength={22}
              customKey={['name', 'id']}
              handleAllSelect={handleAllSelect}
              customStyle={{ width: '250px' }}
              setQueryFilters={() => {}}
              searchQuery={searchQueryTeam}
              setSearchQuery={setSearchQueryTeam}
              handleOnDone={(items) => {
                handleOnDoneTeams(items);
                toggleModify();
              }}
              controlledTitle={
                teams
                  ?.filter((i) => queryFilterTeams?.filters?.includes(i.id))
                  .map((i) => i.name)
                  .join(',') || ''
              }
            />
            <ReportSearchOptionDropdown
              selectedData={selectedItemUser}
              data={users}
              show={showUsers}
              label={`User`}
              scrollable="pr-3 text-left"
              setShow={setShowUsers}
              showCheckAll={false}
              setSelectedData={setSelectedItemUser}
              search={true}
              maxLength={22}
              customKey={['name', 'id']}
              handleAllSelect={handleAllSelectUsers}
              customStyle={{ width: '250px' }}
              setQueryFilters={() => {}}
              searchQuery={searchQueryUsers}
              setSearchQuery={setSearchQueryUsers}
              handleOnDone={(items) => {
                handleOnDoneUsers(items);
                toggleModify();
              }}
              controlledTitle={
                users
                  ?.filter((i) => queryFilterUsers?.filters?.includes(i.id))
                  .map((i) => i.name)
                  .join(',') || ''
              }
            />

            <ButtonFilterDropdown
              options={LessonsFiltersList}
              openFilter={openFilter}
              btnToggleStyle="py-2 btn-sm"
              setOpenFilter={setOpenFilter}
              dropmenuWidth="min-width-220"
              filterOptionSelected={filterSelected}
              filterSelected={filterSelected}
              filterTabs={filterTabs}
              handleFilterSelect={(e, item) => handleFilterSelect(item)}
              setFilterTabs={setFilterTabs}
              defaultSelection={defaultAllFilter}
            />
          </div>
        </div>
      </CardHeader>

      <CardBody className="p-0 flex-1 overflow-y-auto">
        <LessonTranscript
          queryFilter={queryFilterTeams}
          queryFilterUsers={queryFilterUsers}
          queryFilterLessonTypes={queryFilterLessonTypes}
          paginationPage={paginationPage}
          setPagination={setPagination}
          toggleModify={toggleModify}
        />
      </CardBody>

      <CardFooter className="border-top bg-gray-table-head p-2 border-bottom-0 border-left-0 border-right-0 rounded-0">
        <div className="d-flex align-items-center justify-content-end">
          <Pagination
            showPerPage
            showPages={false}
            paginationInfo={pagination}
            onPageChange={(newPage) => {
              changePaginationPage(newPage, setPaginationPage);
            }}
            onLimitChange={(perPage) => {
              changePaginationLimit(perPage, setPaginationPage);
            }}
          />
        </div>
      </CardFooter>
    </Card>
  );
};

export default TrainingLessonTranscript;
