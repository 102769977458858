import { useEffect, useState } from 'react';
import ArchiveService from '../../../services/archive.service';

const useFetchArchive = (id) => {
  const [archive, setArchive] = useState({});
  const [loading, setLoading] = useState(false);

  const fetchArchive = async () => {
    try {
      setLoading(true);
      const data = await ArchiveService.getArchive(id);
      setArchive(data);
    } catch (e) {
      console.log(e);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    id && fetchArchive();
  }, [id]);

  return {
    archive,
    setArchive,
    loading,
    setLoading,
    fetchArchive,
  };
};

export default useFetchArchive;
