import React, { useEffect, useState } from 'react';
import { Card, CardBody, CardFooter, CardHeader } from 'reactstrap';
import UserTranscriptLessonProgress from './UserTranscriptLessonProgress';
import { paginationDefault } from '../../../../../utils/constants';
import SingleSelectionDropdown from '../../../../../components/commons/SingleSelectionDropdown';
import userService from '../../../../../services/user.service';
import { NoUserSelected } from '../../../components/queries/NoUserSelected';
import { UserTranscriptProgress } from '../../UserTranscriptProgress';
import UserTranscriptStats from './UserTranscriptStats';
import IdfTooltip from '../../../../../components/idfComponents/idfTooltip';
import {
  changePaginationLimit,
  changePaginationPage,
} from '../../../../Deals/contacts/utils';
import Pagination from '../../../../../components/Pagination';

const TrainingUserTranscript = ({ dashboard }) => {
  const [selectedItem, setSelectedItem] = useState({});
  const [show, setShow] = useState(false);
  const [users, setUsers] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [queryFilter, setQueryFilter] = useState({
    filters: [],
  });

  const [pagination, setPagination] = useState(paginationDefault);
  const [paginationPage, setPaginationPage] = useState(paginationDefault);

  const getTeam = async () => {
    const { data } = await userService.getUsers(
      { status: 'active', search: searchQuery },
      { page: 1, limit: 10 }
    );
    setUsers(data.users);
  };

  useEffect(() => {
    getTeam();
  }, [searchQuery]);

  const handleOptionSelect = (item) => {
    if (item?.id) {
      setQueryFilter({
        filters: [
          { member: 'User.id', operator: 'equals', values: [item?.id] },
        ],
      });
    } else {
      setQueryFilter({
        filters: [],
      });
    }
    toggleModify();
  };

  const toggleModify = () => {
    setPaginationPage((prev) => ({ ...prev, page: 1 }));
  };

  return (
    <Card className="h-100 border-0 shadow-0">
      <CardHeader>
        <div className="d-flex justify-content-between align-items-center w-100">
          <IdfTooltip text={dashboard?.name} placement="bottom">
            <h4 className="card-title text-hover-primary mb-0 text-truncate">
              {dashboard?.name}
            </h4>
          </IdfTooltip>

          <div className="d-flex align-items-center gap-2">
            <SingleSelectionDropdown
              selectedData={selectedItem}
              data={users}
              show={show}
              label={`User`}
              scrollable="pr-3 text-left"
              setShow={setShow}
              showCheckAll={false}
              setSelectedData={setSelectedItem}
              search={true}
              maxLength={22}
              customKey={['name', 'id']}
              customStyle={{ width: '250px' }}
              searchQuery={searchQuery}
              setSearchQuery={setSearchQuery}
              handleOptionSelected={handleOptionSelect}
            />
          </div>
        </div>
      </CardHeader>

      <>
        {queryFilter?.filters?.length > 0 ? (
          <>
            <CardBody className="p-0 flex-1 overflow-y-auto">
              <div className="px-3 pt-3 d-flex flex-column overflow-x-hidden gap-3">
                <UserTranscriptProgress userFilters={queryFilter?.filters} />
                <UserTranscriptStats userFilters={queryFilter?.filters} />
              </div>

              <UserTranscriptLessonProgress
                userFilters={queryFilter?.filters}
                paginationPage={paginationPage}
                setPagination={setPagination}
              />
            </CardBody>

            <CardFooter className="border-top bg-gray-table-head p-2 border-bottom-0 border-left-0 border-right-0 rounded-0">
              <div className="d-flex align-items-center justify-content-end">
                <Pagination
                  showPerPage
                  showPages={false}
                  paginationInfo={pagination}
                  onPageChange={(newPage) => {
                    changePaginationPage(newPage, setPaginationPage);
                  }}
                  onLimitChange={(perPage) => {
                    changePaginationLimit(perPage, setPaginationPage);
                  }}
                />
              </div>
            </CardFooter>
          </>
        ) : (
          <NoUserSelected label="user" />
        )}
      </>
    </Card>
  );
};

export default TrainingUserTranscript;
