import React from 'react';

// these two could be with (s) so...
const ESSENTIAL = { bgColor: '#fae3cc', foreColor: '#EC4A0A' };
const ELECTIVE = { bgColor: '#f4e9c8', foreColor: '#DC6803' };

// default foundational
const DEFAULT = { bgColor: '#d4edda', foreColor: '#039855' };

// all label colors
const LABEL_COLORS = {
  advanced: { bgColor: '#e3d6f7', foreColor: '#7F56D9' },
  foundational: DEFAULT,
  elective: ELECTIVE,
  electives: ELECTIVE,
  essentials: ESSENTIAL,
  essential: ESSENTIAL,
};

const UNKNOWN = { bgColor: '#eaeff2', foreColor: '#737a82' };

const Label = ({ label, colorStyle }) => {
  return (
    <>
      <span
        style={{
          minWidth: 80,
          color: colorStyle?.foreColor,
          background: colorStyle?.bgColor,
        }}
        className="position-relative pl-3 rounded-pill badge pr-2 d-inline-flex align-items-center justify-content-center text-center text-capitalize font-weight-medium-"
      >
        <span
          className="position-absolute fs-1 rounded-circle"
          style={{
            left: '5px',
            bottom: '0',
          }}
        >
          {'.'}
        </span>
        <span>{label.toLowerCase()}</span>
      </span>
    </>
  );
};
const getColorForLabels = (labels) => {
  try {
    for (let i = 0; i < labels.length; i++) {
      const label = labels[i].replace(/\s+/g, '').toLowerCase(); // Remove quotes from the label
      if (LABEL_COLORS[label]) {
        return label;
      }
    }
    return '';
  } catch (error) {
    return '';
  }
};

const LessonLabel = ({ labels, unknownLabel = '' }) => {
  const label = labels?.length > 0 ? getColorForLabels(labels) : '';
  const colorStyle = (label && LABEL_COLORS[label]) || DEFAULT;

  return (
    <>
      <div className="d-flex align-items-center flex-wrap gap-1">
        {LABEL_COLORS[label] ? (
          <Label label={label} colorStyle={colorStyle} />
        ) : unknownLabel ? (
          <Label label={unknownLabel} colorStyle={UNKNOWN} />
        ) : (
          ''
        )}
      </div>
    </>
  );
};

export default LessonLabel;
