import MaterialIcon from './MaterialIcon';
import React from 'react';

const ValidationErrorText = ({ text, extraClass, iconExtraClass = 'fs-5' }) => {
  return (
    <p
      className={`text-danger d-flex mt-1 align-items-center gap-1 ${extraClass}`}
    >
      {' '}
      <MaterialIcon icon="error" size="fs-5" clazz={iconExtraClass} /> {text}
    </p>
  );
};

export default ValidationErrorText;
