import { ListGroup, ListGroupItem } from 'react-bootstrap';
import moment from 'moment';
import React from 'react';
import Search from '../manageUsers/Search';
import { MenuItemsKeysAndIcons } from './constants';
// import TooltipComponent from '../lesson/Tooltip';
import ContentLayout from './layout/ContentLayout';
import useHash from '../../hooks/useHash';
import useFetchChats from './hooks/useFetchChats';
import Skeleton from 'react-loading-skeleton';
import InfiniteScroll from 'react-infinite-scroll-component';
import useDeleteModal from '../reports/account-structure/modals/useDeleteModal';
import MoreActions from '../MoreActions';
import PageTitle from '../commons/PageTitle';
import NoDataFound from '../commons/NoDataFound';
import useRenameChatModal from './modals/RenameChat';
import ChatService from '../../services/chat.service';
import LucideIcon from '../commons/LucideIcon';
import IdfTooltip from '../idfComponents/idfTooltip';
import { DATE_CHAT_FORMAT } from '../../utils/Utils';

// const Bot = ({ item }) => {
//   const bot = item?.bot || {};
//   return (
//     <>
//       {Object.keys(bot).length ? (
//         <div className="d-flex rounded-pill border px-2 py-1 align-items-center gap-1">
//           {bot?.icon && <MaterialIcon icon={bot?.icon} />}{' '}
//           <span className="fs-9 font-weight-medium">{bot.name}</span>
//         </div>
//       ) : null}
//     </>
//   );
// };

const NoChatFound = ({ title = 'No chat history.' }) => {
  return (
    <NoDataFound
      icon="history"
      containerStyle="text-gray-search my-6 justify-content-center h-100 py-6"
      iconStyle="text-gray-search fs-1"
      titleStyle="font-normal font-size-sm2 text-gray-search"
      title={title}
    />
  );
};

const History = () => {
  const { updateHash } = useHash();
  const {
    chats,
    setChats,
    loading,
    hasMore,
    fetchNextChats,
    searchValue,
    setSearchValue,
  } = useFetchChats();

  const {
    DeleteModal,
    setData,
    setShowModal: setShowDeleteModal,
  } = useDeleteModal({
    heading: 'Delete Chat',
    description: 'Are you sure you want to delete this Chat?',
    successMsg: 'Chat deleted.',
    deleteCallback: async (data) => {
      try {
        await ChatService.deleteChat(data.chatId);
        // remove deleted item from list directly
        const updatedChats = chats.filter((ch) => ch.chatId !== data.chatId);
        setChats(updatedChats);
      } catch (e) {
        console.log(e);
      }
    },
  });

  const {
    RenameChatModal,
    setShowModal: setShowRenameChatModal,
    setChat,
  } = useRenameChatModal((updatedChat) => {
    const newChats = [...chats].map((cht) =>
      cht.chatId === updatedChat.chatId
        ? { ...cht, title: updatedChat.title }
        : cht
    );
    setChats(newChats);
  });

  return (
    <ContentLayout
      heading={MenuItemsKeysAndIcons.History}
      search={
        <Search
          classnames="p-0 col-sm-6 position-relative"
          searchPlaceholder={'Search your chat history'}
          loading={loading}
          searchValue={searchValue}
          onHandleChange={(e) => setSearchValue(e.target.value)}
        />
      }
    >
      <PageTitle page="Ida History" />
      <DeleteModal />
      <RenameChatModal />
      <ListGroup className="pb-3 border-top-0 mx-auto chat-home-window-size border-bottom-0 rounded-0">
        {loading && chats.length === 0 ? (
          <div className="py-3">
            <Skeleton count={3} width="100%" className="mb-2" />
          </div>
        ) : (
          <InfiniteScroll
            dataLength={chats.length}
            next={fetchNextChats}
            hasMore={hasMore}
            inverse={false}
            scrollableTarget="chatContentLayout"
            endMessage={
              chats.length >= 10 ? (
                <p style={{ textAlign: 'center' }}>No more chats</p>
              ) : (
                chats.length === 0 && <NoChatFound />
              )
            }
          >
            {searchValue && chats.length === 0 ? (
              <NoChatFound title="No chats found." />
            ) : (
              <>
                {chats.map((item, index) => (
                  <ListGroupItem
                    onClick={() => updateHash(`#history/${item.chatId}`)}
                    className={`px-0 bg-hover-gray border-left-0 border-right-0 rounded-0 ${
                      index === 0
                        ? 'border-top-0'
                        : index === chats.length - 1
                        ? 'border-bottom-0'
                        : ''
                    }`}
                    key={item.chatId}
                  >
                    <div className="history-item cursor-pointer px-2">
                      <h5>{item.title}</h5>
                      {/* <p className="font-size-sm2 text-clamp">{item.description}</p> */}
                      <div className="d-flex align-items-center justify-content-between">
                        <div className="d-flex align-items-center text-muted gap-3">
                          {/* <p className="fs-8 font-weight-medium gap-1 text-muted d-flex mb-0 align-items-center">
                        <MaterialIcon icon="visibility" size="fs-6" />
                        {item.views}
                      </p> */}

                          <IdfTooltip
                            text={moment(item?.createdAt).format(
                              DATE_CHAT_FORMAT
                            )}
                          >
                            <p className="fs-8 font-weight-medium gap-1 text-muted d-flex mb-0 align-items-center">
                              <LucideIcon icon="History" size={14} />
                              {moment(item.createdAt).fromNow()}
                            </p>
                          </IdfTooltip>
                        </div>
                        <div className="d-flex gap-1 align-items-center">
                          {/* <Bot item={item} /> */}
                          <MoreActions
                            useLucidIcon
                            items={[
                              {
                                id: 'edit',
                                icon: 'Pencil',
                                name: 'Rename',
                              },
                              {
                                id: 'remove',
                                icon: 'Trash2',
                                name: 'Delete',
                              },
                            ]}
                            onHandleRemove={(e) => {
                              e.stopPropagation();
                              e.preventDefault();
                              setData(item);
                              setShowDeleteModal(true);
                            }}
                            onHandleEdit={(e) => {
                              e.stopPropagation();
                              e.preventDefault();
                              setChat(item);
                              setShowRenameChatModal(true);
                            }}
                            toggleClassName="w-auto p-0 h-auto"
                          />
                        </div>
                      </div>
                    </div>
                  </ListGroupItem>
                ))}
              </>
            )}
          </InfiniteScroll>
        )}
      </ListGroup>
    </ContentLayout>
  );
};

export default History;
