import { useState, useEffect, useRef, Fragment } from 'react';
import { Dropdown } from 'react-bootstrap';

import './ActivitiesHistory.css';
import AddActivity from '../peopleProfile/contentFeed/AddActivity';
import stringConstants from '../../utils/stringConstants.json';
import RightPanelModal from '../modal/RightPanelModal';
import fieldService from '../../services/field.service';
import Loading from '../Loading';
import { groupBy } from 'lodash';
import {
  RIGHT_PANEL_WIDTH,
  isPermissionAllowed,
  overflowing,
} from '../../utils/Utils';
import { useProfileContext } from '../../contexts/profileContext';
import MaterialIcon from '../commons/MaterialIcon';
import Alert from '../Alert/Alert';
import AlertWrapper from '../Alert/AlertWrapper';
import TooltipComponent from '../lesson/Tooltip';
import useOutsideClick from '../../hooks/useOutsideClick';

const ActivitiesHistory = ({
  icon,
  organization,
  deal,
  organizationId,
  dealId,
  owner,
  response,
  activities = [],
  fromParentConfig = {},
  setFromParentConfig = () => {},
}) => {
  const constants = stringConstants.deals.contacts.profile;
  const isMounted = useRef(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const { profileInfo } = useProfileContext();
  const [showModalActivity, setShowModalActivity] = useState(false);
  const [title, setTitle] = useState(constants.withoutPlanned);
  const [currentFeed, setCurrentFeed] = useState({});
  const [isFieldsData, setIsFieldsData] = useState([]);
  const [btnType, setIsBtnType] = useState('');
  const [loading, setLoading] = useState(false);
  const [menuShow, setMenuShow] = useState(false);

  const groupBySection = (fieldsList) => {
    setIsFieldsData(groupBy(fieldsList, 'section'));
  };
  const getFields = async (item) => {
    setLoading(true);
    const fieldsData = await fieldService.getFields(item, {
      preferred: true,
    });
    groupBySection(fieldsData?.data);
    setLoading(false);
  };
  const confirm = (msg) => {
    setShowModalActivity(false);
    response(msg);
  };

  useEffect(() => {
    if (isMounted.current) {
      if (activities.length) {
        for (let i = 0; i < activities.length; i++) {
          const activity = activities[i];
          if (new Date(activity.start_date) < new Date()) {
            setTitle(constants.overdue);
            break;
          } else if (
            new Date(activity.start_date).getDay() === new Date().getDay()
          ) {
            if (new Date(activity.start_date) >= new Date()) {
              setTitle(constants.today);
              break;
            }
          } else if (i === activities.length - 1) {
            if (constants.today !== title) {
              setTitle(constants.planned);
              break;
            }
          }
        }
      }
    } else isMounted.current = true;
  }, [activities, isMounted.current]);

  const handleShow = (item) => {
    setMenuShow(false);
    getFields(item);
    setIsBtnType(`${item}`);
    setCurrentFeed({});
    setShowModalActivity(true);
    setFromParentConfig({
      ...fromParentConfig,
      show: false,
    });
  };
  const handleCloseModal = () => {
    setShowModalActivity(false);
    setIsFieldsData([]);
  };
  const loader = () => {
    if (loading) return <Loading />;
  };
  const permission = {
    collection: 'activities',
    action: 'create',
  };
  const menuRef = useRef();
  useOutsideClick(menuRef, () => {
    setFromParentConfig({
      ...fromParentConfig,
      show: false,
    });
  });

  useEffect(() => {
    if (Object.keys(fromParentConfig).length && fromParentConfig?.show) {
      const rect = fromParentConfig?.toggleRef?.getBoundingClientRect();
      menuRef.current.style.position = 'fixed';
      menuRef.current.style.opacity = 1;
      menuRef.current.style.transform = `translate3d(${rect.left + 10}px, ${
        rect.top + 20
      }px, 0px)`;
    }
  }, [fromParentConfig]);

  return (
    <Fragment>
      <AlertWrapper>
        <Alert
          message={errorMessage}
          setMessage={setErrorMessage}
          color="danger"
        />
        <Alert
          message={successMessage}
          setMessage={setSuccessMessage}
          color="success"
        />
      </AlertWrapper>
      <div className="activities-btn">
        <Dropdown
          show={fromParentConfig?.parent ? fromParentConfig?.show : menuShow}
          onToggle={
            fromParentConfig?.parent ? fromParentConfig?.setShow : setMenuShow
          }
        >
          {icon && (
            <Dropdown.Toggle
              id={`btn-${dealId}`}
              as="a"
              ref={(ref) => {
                if (!ref) return;
                ref.onclick = (e) => {
                  setMenuShow(!menuShow);
                  e.stopPropagation();
                };
              }}
              className={
                'dropdown-hide-arrow text-secondary cursor-pointer action-items bg-hover-gray position-relative'
              }
              style={{ border: 0 }}
            >
              <TooltipComponent title="Add">
                <a
                  className="position-relative icon-hover-bg text-primary"
                  style={{ top: -5 }}
                >
                  <MaterialIcon icon={icon} symbols />
                </a>
              </TooltipComponent>
            </Dropdown.Toggle>
          )}
          <Dropdown.Menu
            ref={menuRef}
            className={`modal-history-activities rounded py-0 z-index-250`}
          >
            <div className="max-w-200 min-width-200 px-0">
              {isPermissionAllowed(
                permission?.collection,
                permission.action
              ) ? (
                <>
                  <button
                    className="btn btn-light p-2_1 text-left d-flex align-items-center font-size-sm2 cursor-pointer bg-hover-gray-no-transparence btn-lg rounded-0 btn-block w-100"
                    onClick={() => {
                      handleShow('task');
                    }}
                  >
                    <MaterialIcon icon="task_alt" symbols size="fs-6" />
                    <span className="ml-2 font-weight-medium">
                      Create a Task
                    </span>
                  </button>
                  <button
                    className="btn btn-light p-2_1 mt-0 text-left d-flex align-items-center font-size-sm2 border-top-0 border-bottom-0 cursor-pointer bg-hover-gray-no-transparence btn-lg rounded-0 btn-block w-100"
                    onClick={() => {
                      handleShow('event');
                    }}
                  >
                    <MaterialIcon icon="event" symbols size="fs-6" />
                    <span className="ml-2 font-weight-medium">
                      Create an Event
                    </span>
                  </button>
                  <button
                    className="btn btn-light p-2_1 mt-0 text-left d-flex align-items-center font-size-sm2 cursor-pointer bg-hover-gray-no-transparence btn-lg btn-block rounded-0 w-100"
                    onClick={() => {
                      handleShow('call');
                    }}
                  >
                    <MaterialIcon icon="phone" symbols size="fs-6" />
                    <span className="ml-2 font-weight-medium">Log a Call</span>
                  </button>
                </>
              ) : (
                <div className="px-3">
                  <span className="font-weight-bold">No Access</span>
                </div>
              )}
            </div>
          </Dropdown.Menu>
        </Dropdown>
        <RightPanelModal
          showModal={showModalActivity}
          setShowModal={() => {
            overflowing();
            handleCloseModal();
          }}
          showOverlay={true}
          containerBgColor={'pb-0'}
          containerWidth={RIGHT_PANEL_WIDTH}
          containerPosition={'position-fixed'}
          headerBgColor="bg-gray-5"
          Title={
            <div className="d-flex py-2 align-items-center">
              <h3 className="mb-0 text-capitalize">
                {currentFeed?.id ? `Edit ${btnType}` : `Add ${btnType}`}
              </h3>
            </div>
          }
        >
          {loading ? (
            loader()
          ) : (
            <AddActivity
              organizationId={organizationId}
              dealId={dealId}
              contactIs={'organization'}
              getProfileInfo={profileInfo}
              profileRefresh={confirm}
              isModal={true}
              btnType={btnType}
              dataType={'deal'}
              allFields={isFieldsData}
              feedInfo={currentFeed}
              setErrorMessage={setErrorMessage}
              setSuccessMessage={setSuccessMessage}
              feedId={currentFeed?.id}
              activityData={currentFeed}
              closeModal={() => setShowModalActivity(false)}
              organization={organization}
              deal={deal}
              owner={owner}
            />
          )}
        </RightPanelModal>
      </div>
    </Fragment>
  );
};

export default ActivitiesHistory;
