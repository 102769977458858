import ReportDragDrop from '../../reports/ReportDragDrop';
import React, { useEffect, useState } from 'react';
import useGenerateAccountStructureReportModal from '../../reports/account-structure/modals/GenerateAccountStructureReport';
import {
  AccountStructureLookupFields,
  ActionTypes,
  ReportTypes,
} from '../../reports/reports.constants';
import {
  getCycleDate,
  getReportName,
  MapBalanceItems,
  PdfFileNames,
} from '../../reports/reports.helper.functions';
import Accounts from '../../reports/account-structure/pages/Accounts';
import { ListGroup, ListGroupItem } from 'reactstrap';
import AccountsPayables from '../../reports/account-structure/pages/AccountsPayables';
import AccountsReceivables from '../../reports/account-structure/pages/AccountsReceivables';
import SignersAndBankContacts from '../../reports/account-structure/pages/SignersAndBankContacts';
import WorkingCapitalDPO from '../../reports/account-structure/pages/WorkingCapitalDPO';
import WorkingCapitalDSO from '../../reports/account-structure/pages/WorkingCapitalDSO';
import BalanceTrends from '../../reports/account-structure/pages/BalanceTrends';
import {
  AccountSchematicReportSections,
  AccountSchematicReportSectionsWidgets,
  AccountStructureTabMap,
  AccountTypes,
  generateOneYearBalanceRecords,
} from '../../reports/account-structure/account.structure.constants';
import { usePagesContext } from '../../../contexts/pagesContext';
import {
  extractReportId,
  getNaicsWithTitle,
  splitCompanyName,
} from '../../../utils/Utils';
import { useProfileContext } from '../../../contexts/profileContext';
import Alert from '../../Alert/Alert';
import AlertWrapper from '../../Alert/AlertWrapper';
import NoDataFound from '../../commons/NoDataFound';
import {
  NO_REPORTS_AVAILABLE,
  NO_REPORTS_AVAILABLE_ICON,
} from '../../../utils/constants';
import ReportAction from '../../reports/ReportAction';
import OrganizationService from '../../../services/organization.service';
import ReportService from '../../../services/report.service';
import ReportBlocksSkeleton from '../../loaders/ReportBlocksSkeleton';
import ReportDropdownItem from '../../reports/ReportDropdownItem';
import moment from 'moment';
import ReportPDFWrapper from '../../reportbuilder/ReportPDFWrapper';
import ReportCover from '../../reports/ReportCover';
import ReportPagesAccountStructure from '../../reportbuilder/ReportPagesAccountStructure';
import FundsFlow from '../../reports/account-structure/pages/FundsFlow';
import _ from 'lodash';
import useLocalAccountStructureReport from '../../reports/account-structure/useLocalAccountStructureReport';
import useHash from '../../../hooks/useHash';
import DisclaimerWidget from '../../reportbuilder/widgets/horizontal/DisclaimerWidget';
import useIsTenant from '../../../hooks/useIsTenant';
import useFetchSubOrganizations from '../../../hooks/reports/useFetchSubOrganizations';
import FieldService from '../../../services/field.service';
import MetricsService from '../../../services/metrics.service';

const getReportPrettyDate = (rpt) => {
  return moment(rpt.reportDate).format('MMMM YYYY');
};

const AccountStructureReport = ({
  organization = {},
  readOnly,
  selectedTenant,
  currentTab,
  getProfileInfo,
}) => {
  const { hash, updateHash } = useHash();
  const { isComericaBank } = useIsTenant();
  const downloadOptions = [
    {
      id: 1,
      icon: 'picture_as_pdf',
      key: 'downloadAsPdf',
      name: 'PDF Download',
    },
  ];
  const { profileInfo } = useProfileContext();
  const [selectedRpt, setSelectedRpt] = useState({});
  const [loadingReport, setLoadingReport] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const { pageContext, setPageContext } = usePagesContext();
  const [startDownload, setStartDownload] = useState(false);
  const [, setIsEdited] = useState(false);
  const [, setLoaderInsights] = useState(false);
  const [, setInsightsData] = useState({});
  const [reports, setReports] = useState([]);
  const [loadingPastReports, setLoadingPastReports] = useState(false);

  const MapReportDropdownItem = (rpt) => {
    const rptObject = rpt.manualInput;
    const datePart = rpt.manualInput.ReportInfo;
    return {
      ...rptObject,
      key: rpt.reportId,
      reportId: rpt.reportId,
      customElement: (
        <ReportDropdownItem
          item={{
            ...datePart,
            key: rpt.reportId,
            reportId: rpt.reportId,
          }}
        />
      ),
      name: getReportName(rptObject),
      isManual: true,
      createdById: rpt.createdById,
      createdAt: rpt.createdAt,
      updatedAt: rpt.updatedAt,
      prettyDate: moment(datePart.reportDate).format('MMMM YYYY'),
    };
  };
  const { fetchSubOrganizationsPromise } = useFetchSubOrganizations(
    organization,
    false,
    12
  );
  const { getReport: getLocalStorageReport, clearReport } =
    useLocalAccountStructureReport();
  const localReportExisted = Object.keys(getLocalStorageReport()).length > 0;
  const {
    GenerateAccountStructureReportModal,
    setModalOrganization,
    setSelectedReport,
    setShowModal,
  } = useGenerateAccountStructureReportModal((newReport, type) => {
    setRptGenerated(true);
    clearReport();
    setLoadingReport(false);
    if (type === ActionTypes.REMOVE) {
      const newReports = [...reports].filter(
        (rpt) => rpt.key !== newReport.key
      );
      setReports(newReports);
      if (newReports.length) {
        const firstReport = newReports[0];
        setSelectedRpt(firstReport);
        setPageContext({
          ...pageContext,
          AccountStructureReport: {
            id: firstReport.reportId,
            ...firstReport,
          },
          AccountStructureReportModal: {
            id: firstReport.reportId,
            ...firstReport,
          },
        });
      } else {
        setSelectedRpt({});
        setDefaultReportContext();
        setRptGenerated(false);
      }
    } else if (type === ActionTypes.ADD) {
      setSuccessMessage('Report Created');
      const reportInfo = newReport.manualInput.ReportInfo;
      const newReports = [
        {
          ...newReport.manualInput,
          key: newReport.reportId,
          reportId: newReport.reportId,
          customElement: (
            <ReportDropdownItem
              item={{
                reportDate: reportInfo.reportDate,
                key: newReport.reportId,
                reportId: newReport.reportId,
              }}
            />
          ),
          name: getReportName(reportInfo),
          isManual: true,
          createdById: newReport.createdById,
          createdAt: newReport.createdAt,
          updatedAt: newReport.updatedAt,
          prettyDate: getReportPrettyDate(reportInfo),
        },
        ...reports,
      ];
      setReports(newReports);
      const firstReport = newReports[0];
      setSelectedRpt(firstReport);
      setPageContext({
        ...pageContext,
        AccountStructureReport: {
          id: newReport.reportId,
          ...newReport.manualInput,
        },
        AccountStructureReportModal: {
          id: newReport.reportId,
          ...newReport.manualInput,
        },
      });
    } else {
      setSuccessMessage('Report Saved');
      const reportInfo = newReport.manualInput.ReportInfo;
      if ('key' in selectedRpt) {
        const newReports = [
          ...reports.map((rpt) =>
            rpt.key === selectedRpt.key
              ? {
                  ...newReport.manualInput,
                  key: newReport.reportId,
                  reportId: newReport.reportId,
                  customElement: (
                    <ReportDropdownItem
                      item={{
                        reportDate: reportInfo.reportDate,
                        key: newReport.reportId,
                        reportId: newReport.reportId,
                      }}
                    />
                  ),
                  name: getReportName(reportInfo),
                  createdById: profileInfo.id,
                  updatedAt: new Date().toISOString(),
                  prettyDate: getReportPrettyDate(reportInfo),
                }
              : { ...rpt }
          ),
        ];
        setReports(newReports);
        setSelectedRpt(newReports.find((r) => r.key === selectedRpt.key));
        const updatedContext = {
          ...pageContext,
          AccountStructureReport: {
            id: newReport.reportId,
            ...newReport.manualInput,
          },
          AccountStructureReportModal: {
            id: newReport.reportId,
            ...newReport.manualInput,
          },
        };
        setPageContext(updatedContext);
      } else {
        const reportInfo = newReport.manualInput.ReportInfo;
        const newReports = [
          {
            ...newReport.manualInput,
            key: newReport.reportId,
            reportId: newReport.reportId,
            customElement: (
              <ReportDropdownItem
                item={{
                  reportDate: reportInfo.reportDate,
                  key: newReport.reportId,
                  reportId: newReport.reportId,
                }}
              />
            ),
            name: getReportName(reportInfo),
            isManual: true,
            createdById: newReport.createdById,
            createdAt: newReport.createdAt,
            updatedAt: newReport.updatedAt,
            prettyDate: getReportPrettyDate(reportInfo),
          },
          ...reports,
        ];
        setReports(newReports);
        const firstReport = newReports[0];
        setSelectedRpt(firstReport);
        setPageContext({
          ...pageContext,
          AccountStructureReport: {
            id: newReport.reportId,
            ...newReport.manualInput,
          },
          AccountStructureReportModal: {
            id: newReport.reportId,
            ...newReport.manualInput,
          },
        });
      }
    }
  });

  const [rptGenerated, setRptGenerated] = useState(false);
  const handleDraftClick = () => {
    const localReport = getLocalStorageReport();
    const firstReport = localReport.AccountStructureReportModal;
    const newData = {
      id: firstReport.id,
      mode: firstReport.mode,
      [AccountStructureTabMap.ReportInfo.key]:
        firstReport[AccountStructureTabMap.ReportInfo.key],
      [AccountStructureTabMap.ReportDetails.key]:
        firstReport[AccountStructureTabMap.ReportDetails.key],
      [AccountStructureTabMap.WorkingCapital.key]:
        firstReport[AccountStructureTabMap.WorkingCapital.key],
      [AccountStructureTabMap.Widgets.key]:
        firstReport[AccountStructureTabMap.Widgets.key],
      [AccountStructureTabMap.FundsFlow.key]:
        firstReport[AccountStructureTabMap.FundsFlow.key],
    };
    setPageContext({
      ...pageContext,
      AccountStructureReportModal: _.cloneDeep(newData),
    });
    setIsEdited(true);
    setShowModal(true);
  };
  const handleGenerateManualReport = () => {
    setShowModal(true);
    setDefaultReportContext();
    setSelectedReport({});
  };

  const getInsights = async (newNaics, callback) => {
    setLoaderInsights(true);
    try {
      const insights = await OrganizationService.getInsightsByOrganization(
        organization.id
      );
      setPageContext({
        ...pageContext,
        AccountStructureReport: {
          ...pageContext.AccountStructureReport,
          insightsData: insights,
        },
        AccountStructureReportModal: {
          ...pageContext.AccountStructureReportModal,
          insightsData: insights,
        },
      });
      setInsightsData(insights);
      callback(insights);
    } catch (e) {
      console.log(e);
    } finally {
      setLoadingReport(false);
      setLoaderInsights(false);
    }
  };

  const setDefaultReportContext = () => {
    const newReport = {
      [AccountStructureTabMap.ReportInfo.key]: {
        companyName: organization.name,
        reportDate: getCycleDate(new Date()),
        valueN: getNaicsWithTitle({}, organization),
        valueNaicsSic: getNaicsWithTitle({}, organization, true),
      },
      [AccountStructureTabMap.ReportDetails.key]: {
        accounts: [],
        authorizedSigners: [],
        authorizedUsers: [],
        balances: [],
        bankContacts: [],
      },
      [AccountStructureTabMap.WorkingCapital.key]: {
        isActive: false,
        data: {},
      },
      [AccountStructureTabMap.Widgets.key]: {
        ...AccountSchematicReportSectionsWidgets,
      },
    };
    setPageContext({
      ...pageContext,
      AccountStructureReport: newReport,
      AccountStructureReportModal: _.cloneDeep(newReport),
    });
  };

  const hasCoreDataReportCreated = (coreDataReport, oldReports) => {
    // since we are currently looking by month/year so...
    const dateFormat = 'MMMM YYYY';
    const analysisDate = moment(coreDataReport.date.split('T')[0]).format(
      dateFormat
    );
    const foundReport = oldReports.find((rpt) => {
      const rptDate = moment(rpt.date).format(dateFormat);
      return analysisDate === rptDate;
    });
    return foundReport !== undefined;
  };

  const getReports = async (dontSelect) => {
    if (!loadingPastReports) {
      setLoadingPastReports(true);
    }
    try {
      const requests = [
        OrganizationService.getReports(organization.id, {
          limit: 100,
          page: 1,
          type: ReportTypes.AccountStructure,
        }),
      ];

      // if tenant is comerica then get sub orgs
      if (isComericaBank) {
        requests.push(fetchSubOrganizationsPromise());
        requests.push(
          MetricsService.getOrganizationTreasuryMetricsData(organization.id)
        );
      }

      const responses = await Promise.all(requests);
      const { data: oldReports } = responses[0].data;
      let reportsLocal = oldReports
        .filter((rpt) => !!rpt.input || !!rpt.manualInput)
        .map(MapReportDropdownItem)
        .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));

      // means we need to call auto flow by fetching sub orgs
      if (responses.length > 1) {
        const subOrgs = responses[1].data;
        let coreDataTM = [];
        try {
          coreDataTM = responses[2].data.map(MapBalanceItems);
        } catch (e) {
          console.log(e);
        }
        const reportDate = coreDataTM.length
          ? moment(coreDataTM[0].analysisDate).toDate()
          : moment().toDate();
        const oneYearBalances = generateOneYearBalanceRecords(
          reportDate.toISOString()
        );
        if (coreDataTM.length) {
          oneYearBalances.forEach((cd) => {
            const cdMonth = coreDataTM.find((d) => d.month === cd.month);
            if (cdMonth) {
              cd.value = cdMonth.value;
            }
          });
        }
        // if report hasn't been created
        if (
          subOrgs.length &&
          !hasCoreDataReportCreated(
            { date: reportDate.toISOString() },
            oldReports
          )
        ) {
          // fetch sub org fields 1 by 1
          const splittedNameAndNumber = splitCompanyName(organization.name);
          // for current org set all below fields along with operatingAccountType = AccountTypes.Operating
          const operatingAccount = {
            id: organization.id,
            accountName: splittedNameAndNumber?.name || organization.name,
            accountNumber: splittedNameAndNumber?.number || '',
            accountType: '',
            operatingAccountType: AccountTypes.Operating,
            fraudServices: '',
            productServices: '',
            status: 'complete',
          };
          const accounts = [];
          for (const suborg of subOrgs) {
            const { data } = await FieldService.getSubOrganizationFields(
              suborg.subOrganizationId
            );
            const subAccount = {
              id: suborg.subOrganizationId,
              accountName: suborg.name,
              accountNumber: '',
              accountType: '',
              operatingAccountType: AccountTypes.SubAccount,
              fraudServices: '',
              productServices: '',
              status: 'complete',
            };
            // for account card and for all sub orgs operatingAccountType = AccountTypes.SubAccount
            // id, accountName, accountNumber, accountType,
            // fraudServices (comma separated string), productServices (comma separated string)
            if (data.length) {
              data.forEach((dt) => {
                if (dt.field.key === AccountStructureLookupFields.AccountType) {
                  subAccount.accountType = dt.value;
                } else if (
                  dt.field.key ===
                  AccountStructureLookupFields.MaskedAccountNumber
                ) {
                  subAccount.accountNumber = dt.value;
                } else if (
                  dt.field.key ===
                  AccountStructureLookupFields.ProductAndServices
                ) {
                  const fraudServices = dt.value
                    .filter((f) => f.metadata.type === 'fraud')
                    .map((f) => f.value)
                    .join(', ');
                  const productServices = dt.value
                    .filter((f) => f.metadata.type === 'product')
                    .map((f) => f.value)
                    .join(', ');
                  subAccount.fraudServices = fraudServices;
                  subAccount.productServices = productServices;
                }
              });
            }

            accounts.push(subAccount);
          }
          accounts.unshift(operatingAccount);
          const newReport = {
            [AccountStructureTabMap.ReportInfo.key]: {
              companyName: organization.name,
              reportDate: getCycleDate(reportDate),
              valueN: getNaicsWithTitle({}, organization),
              valueNaicsSic: getNaicsWithTitle({}, organization, true),
            },
            [AccountStructureTabMap.ReportDetails.key]: {
              accounts,
              authorizedSigners: [],
              authorizedUsers: [],
              balances: oneYearBalances,
              bankContacts: [],
            },
            [AccountStructureTabMap.WorkingCapital.key]: {
              isActive: false,
              data: {},
            },
            [AccountStructureTabMap.Widgets.key]: {
              ...AccountSchematicReportSectionsWidgets,
            },
          };
          const reportCreate = {
            name: organization.name,
            date: reportDate.toISOString(),
            type: ReportTypes.AccountStructure,
            manualInput: {
              insightsData: {},
              isAuto: true,
              coreData: {
                reportDate: reportDate.toISOString(),
              },
              ...newReport,
            },
          };
          const reportObjectCreated =
            await OrganizationService.createManualReport(
              organization.id,
              reportCreate
            );
          setLoadingReport(false);
          setSuccessMessage('Report Created');
          const newReports = [MapReportDropdownItem(reportObjectCreated)];
          // add these reports at first/latest to show in FE
          reportsLocal = [...newReports, ...reports];
        }
      }

      setReports(reportsLocal);

      if (!dontSelect) {
        if (reportsLocal.length) {
          const firstReport = hash?.includes('/new')
            ? {}
            : reportsLocal[0] || {};
          // if we have the reportId then get it
          if (firstReport?.key || hash?.includes('/id')) {
            // we got the id from url now we want to find that in our reports array and get full object
            const urlHashId = extractReportId(hash); // get the last index which has report id
            const reportFound = reportsLocal.find(
              (rp) => rp.reportId === urlHashId
            );
            if (reportFound) {
              setSelectedRpt(reportFound);
            } else {
              setSelectedRpt(firstReport);
            }
            getInsights(null, (insightsData) => {
              const data = {
                id: firstReport.key,
                updatedAt: firstReport.updatedAt,
                insightsData,
                ...firstReport,
              };
              setPageContext({
                ...pageContext,
                AccountStructureReport: data,
                AccountStructureReportModal: _.cloneDeep(data),
              });
              setRptGenerated(true);
            });
          } else {
            setSelectedRpt({});
            setDefaultReportContext();
            setRptGenerated(false);
          }
        } else {
          setSelectedRpt({});
          setDefaultReportContext();
          setRptGenerated(false);
        }
      }
    } catch (e) {
      console.log(e);
    } finally {
      setLoadingPastReports(false);
    }
  };

  useEffect(() => {
    if (organization?.id) {
      setModalOrganization(organization);
      getReports();
    }
  }, [organization]);

  const handleEditReport = () => {
    setIsEdited(true);
    setShowModal(true);
  };

  const handleCreateButtonReport = () => {
    setSelectedRpt({});
    setDefaultReportContext();
    setIsEdited(false);
    setRptGenerated(false);
  };

  const getReportById = async (selectedReport) => {
    setLoadingReport(true);
    try {
      if (selectedReport.key) {
        const data = await ReportService.getReport(selectedReport.key);
        const firstReport = data.manualInput;
        getInsights(null, (insightsData) => {
          const newData = {
            id: data.reportId,
            updatedAt: data.updatedAt,
            insightsData,
            ...firstReport,
          };
          setPageContext({
            ...pageContext,
            AccountStructureReport: newData,
            AccountStructureReportModal: _.cloneDeep(newData),
          });
          setRptGenerated(true);
          // when report is full loaded trigger the modal based on has url if it has
          if (hash?.includes('/edit')) {
            updateHash('');
            setShowModal(true);
          }
        });
      }
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    if (selectedRpt?.key || hash?.includes('/id')) {
      getReportById(selectedRpt);
    }
  }, [selectedRpt?.key]);

  useEffect(() => {
    // look hash url if it contains new open a add modal.
    if (hash?.includes('/new')) {
      setDefaultReportContext();
      setSelectedReport({});
    }
  }, [hash]);

  const ReportPageWrapper = ({ children }) => {
    return (
      <ListGroupItem
        className={`account-structure-display-item border-0 px-3 position-relative py-0 ${
          readOnly ? 'bg-transparent' : ''
        }`}
      >
        {children}
      </ListGroupItem>
    );
  };

  return (
    <>
      <AlertWrapper className="alert-position">
        <Alert
          color="success"
          message={successMessage}
          setMessage={setSuccessMessage}
          time={8000}
        />
        <Alert
          color="danger"
          message={errorMessage}
          setMessage={setErrorMessage}
          time={8000}
        />
      </AlertWrapper>
      <GenerateAccountStructureReportModal />
      {reports.length === 0 &&
        readOnly &&
        !loadingReport &&
        !loadingPastReports && (
          <NoDataFound
            icon={NO_REPORTS_AVAILABLE_ICON}
            iconRounded={true}
            containerStyle="my-6 py-6 text-gray-search"
            title={NO_REPORTS_AVAILABLE}
            titleStyle="text-gray-search font-normal font-size-sm2"
          />
        )}
      <ReportAction
        report={selectedRpt}
        readOnly={readOnly}
        reportType={ReportTypes.AccountStructure}
        pastReports={reports}
        selectedRpt={selectedRpt}
        profileInfo={profileInfo}
        organization={organization}
        rptGenerated={rptGenerated}
        loadingReport={loadingReport}
        reSync={isComericaBank === true}
        startDownload={startDownload}
        setSelectedRpt={(newReport) => {
          updateHash('');
          setSelectedRpt(newReport);
        }}
        downloadOptions={downloadOptions}
        setStartDownload={setStartDownload}
        handleEditReport={handleEditReport}
        handleManualReport={handleCreateButtonReport}
        loadingPastReports={loadingPastReports}
        linkConfig={{}}
        draftMode={localReportExisted}
        handleDraftClick={handleDraftClick}
      />
      {loadingReport || loadingPastReports ? (
        <ReportBlocksSkeleton />
      ) : (
        <>
          {rptGenerated && !loadingReport && selectedRpt?.key ? (
            <div className="p-0 w-100 overflow-x-auto">
              <ListGroup className="list-group-no-gutters d-flex flex-column w-100 gap-3 mt-2 mb-3 list-group-flush">
                <ReportPageWrapper>
                  <FundsFlow />
                </ReportPageWrapper>
                <ReportPageWrapper>
                  <Accounts />
                </ReportPageWrapper>
                <ReportPageWrapper>
                  <BalanceTrends hideHeading={false} />
                </ReportPageWrapper>
                {selectedRpt[AccountStructureTabMap.WorkingCapital.key]
                  .isActive && (
                  <ReportPageWrapper>
                    <WorkingCapitalDPO />
                  </ReportPageWrapper>
                )}
                <ReportPageWrapper>
                  <AccountsPayables />
                </ReportPageWrapper>
                {selectedRpt[AccountStructureTabMap.WorkingCapital.key]
                  .isActive ? (
                  <ReportPageWrapper>
                    <WorkingCapitalDSO />
                  </ReportPageWrapper>
                ) : null}
                {selectedRpt[AccountStructureTabMap.Widgets.key][
                  AccountSchematicReportSections.AccountsReceivable
                ]?.isActive ||
                selectedRpt[AccountStructureTabMap.Widgets.key][
                  AccountSchematicReportSections.AccountsReceivableAutomating
                ]?.isActive ? (
                  <ReportPageWrapper>
                    <AccountsReceivables />
                  </ReportPageWrapper>
                ) : null}
                <ReportPageWrapper>
                  <SignersAndBankContacts isPrinting={false} />
                </ReportPageWrapper>
                {selectedRpt[AccountStructureTabMap.Widgets.key][
                  AccountSchematicReportSections.Disclaimer
                ]?.isActive ? (
                  <ReportPageWrapper>
                    <DisclaimerWidget />
                  </ReportPageWrapper>
                ) : null}
              </ListGroup>
            </div>
          ) : (
            <>
              {!readOnly ? (
                <ReportDragDrop
                  file={null}
                  setFile={() => {}}
                  loader={false}
                  onRemoveFile={() => {}}
                  onLoadFile={() => {}}
                  handleGenerate={handleGenerateManualReport}
                  uploadIcon="edit_document"
                  fileUpload={`Enter data to generate ${
                    PdfFileNames[[ReportTypes.AccountStructure]]
                  } report.`}
                />
              ) : null}
            </>
          )}

          {startDownload && (
            <ReportPDFWrapper>
              <ReportCover
                name={selectedRpt?.ReportInfo?.companyName}
                date={selectedRpt?.ReportInfo?.reportDate}
                report={selectedRpt}
                type={ReportTypes.AccountStructure}
                organization={organization}
              />
              <ReportPagesAccountStructure
                report={selectedRpt}
                reportType={ReportTypes.AccountStructure}
              />
            </ReportPDFWrapper>
          )}
        </>
      )}
    </>
  );
};

export default AccountStructureReport;
