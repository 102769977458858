import React from 'react';
import { Card, CardBody } from 'reactstrap';
import { numbersWithComma } from '../../../../utils/Utils';
import DigitalDonutChart from '../DigitalDonutChart';

const getPercentageValue = (value, key) => {
  if (key === 'effectiveRate') {
    return value?.toFixed(2) + '%';
  } else if (key === 'authorizationToApprovalPercentage') {
    return Math.round(value) + '%';
  } else if (key === 'refundPercentage') {
    return Number(value)?.toFixed(3) + '%';
  }
  return value + '%';
};
const ControllableCostSavingsWidget = ({ widgetConfig, whenPrinting }) => {
  const isAggregateView = widgetConfig?.aggregate === true;
  const aggregateOrIndividualObject = isAggregateView
    ? widgetConfig?.data?.aggregate
    : widgetConfig?.data;
  return (
    <Card className="report-widget">
      <CardBody className="justify-content-center p-3 align-items-center d-flex">
        <div className="flex-fill w-100">
          <h5 className="text-left hide-heading">{widgetConfig.header}</h5>
          <p
            className={`mb-0 px-3 ${
              whenPrinting ? 'fs-9' : 'font-size-sm2'
            } text-left`}
          >
            <span className="text-black">{widgetConfig.description}</span>
          </p>

          <div className="mt-3 d-flex px-3 flex-column text-left">
            <h5>{widgetConfig.numbersHeading}</h5>
            <div className="d-grid grid-col-2 gap-4 fs-6 font-weight-bold text-muted">
              {widgetConfig?.transactions?.map((item) => (
                <CardBody
                  key={item.key}
                  className="d-flex justify-content-between widget-border-2 bg-white rpt-rounded"
                >
                  <div className="d-flex flex-column justify-content-center gap-1">
                    <span>{item.title}</span>
                    <span className="text-primary">
                      {item?.format === 'percentage'
                        ? getPercentageValue(
                            aggregateOrIndividualObject?.[item.key],
                            item.key
                          )
                        : item?.format === 'currency'
                        ? `$${numbersWithComma(
                            aggregateOrIndividualObject?.[item.key],
                            {
                              maximumFractionDigits: 2,
                            }
                          )}`
                        : numbersWithComma(
                            aggregateOrIndividualObject?.[item.key]
                          )}
                    </span>
                  </div>
                  <div className="d-flex flex-column justify-content-center">
                    <span>{item.type}</span>
                  </div>
                </CardBody>
              ))}
            </div>
          </div>

          <div className="mt-3 d-flex px-3 flex-column gap-2 text-left">
            <h5 className="mb-1">{widgetConfig.savingsOpportunity?.heading}</h5>
            <CardBody className="d-flex justify-content-between border-2 border-red bg-white rpt-rounded py-6">
              <div className="w-25 d-flex flex-column justify-content-center">
                {whenPrinting ? (
                  <div className="d-flex align-items-center w-100 gap-2 py-2">
                    <DigitalDonutChart
                      item={{
                        percentage:
                          aggregateOrIndividualObject?.ccisDollarsProcessedAtHigherRate
                            ? `${aggregateOrIndividualObject?.ccisDollarsProcessedAtHigherRate}%`
                            : '0%',
                        key: 'savings',
                        chart: {
                          data: aggregateOrIndividualObject?.ccisDollarsProcessedAtHigherRate
                            ? [
                                100 -
                                  aggregateOrIndividualObject?.ccisDollarsProcessedAtHigherRate,
                                100,
                              ]
                            : [0, 100],
                          colors:
                            widgetConfig.savingsOpportunity.chartData.colors,
                        },
                      }}
                      containerClass="d-flex flex-column flex-fill"
                      cutout="80%"
                    />
                  </div>
                ) : (
                  <div className="d-flex align-items-center w-100 justify-content-between gap-2 px-3">
                    <DigitalDonutChart
                      item={{
                        percentage:
                          aggregateOrIndividualObject?.ccisDollarsProcessedAtHigherRate
                            ? `${aggregateOrIndividualObject?.ccisDollarsProcessedAtHigherRate}%`
                            : '0%',
                        key: 'savings',
                        chart: {
                          data: aggregateOrIndividualObject?.ccisDollarsProcessedAtHigherRate
                            ? [
                                100 -
                                  aggregateOrIndividualObject?.ccisDollarsProcessedAtHigherRate,
                                100,
                              ]
                            : [0, 100],
                          colors:
                            widgetConfig.savingsOpportunity.chartData.colors,
                        },
                      }}
                      containerClass="d-flex flex-column flex-fill"
                      cutout="80%"
                    />
                  </div>
                )}
              </div>
              <div className="w-75 d-flex flex-column justify-content-center gap-2">
                <p
                  className={`mb-0 ${
                    whenPrinting ? 'fs-9' : 'font-size-sm2'
                  } text-left font-weight-medium`}
                >
                  <span className="text-black">
                    Out of the{' '}
                    <b className="font-size-sm">
                      $
                      {numbersWithComma(
                        aggregateOrIndividualObject?.totalDollarsProcessed,
                        {
                          maximumFractionDigits: 2,
                        }
                      )}
                    </b>{' '}
                    you processed,{' '}
                    <b className="font-size-sm">
                      $
                      {numbersWithComma(
                        aggregateOrIndividualObject?.ccisDollarsProcessedAtHigherRate,
                        {
                          maximumFractionDigits: 2,
                        }
                      )}
                    </b>{' '}
                    was processed at a higher rate.
                  </span>
                </p>
                <p
                  className={`mb-0 ${
                    whenPrinting ? 'fs-9' : 'font-size-sm2'
                  } text-left font-weight-medium`}
                >
                  <span className="text-black">
                    Optional Commercial Card savings opportunity of{' '}
                    <b className="font-size-sm text-danger">
                      $
                      {numbersWithComma(
                        aggregateOrIndividualObject?.ccisDollars,
                        {
                          maximumFractionDigits: 2,
                        }
                      )}
                    </b>{' '}
                    per year.
                  </span>
                </p>
              </div>
            </CardBody>
            {aggregateOrIndividualObject?.amexDisclosure && (
              <p
                className={`mb-0 mt-1 ${
                  whenPrinting ? 'fs-9' : 'font-size-sm2'
                } text-center`}
              >
                <span className="text-black">
                  {aggregateOrIndividualObject.amexDisclosure}
                </span>
              </p>
            )}
          </div>
        </div>
      </CardBody>
    </Card>
  );
};

export default ControllableCostSavingsWidget;
