import React from 'react';
import { Line } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  LineElement,
  PointElement,
  LinearScale,
  CategoryScale,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';

ChartJS.register(
  LineElement,
  PointElement,
  LinearScale,
  CategoryScale,
  Title,
  Tooltip,
  Legend
);

const OverviewLineChart = () => {
  const lineColor = '#397DF4';

  const salesData = [
    4500000, 4200000, 4300000, 3000000, 3500000, 5000000, 6500000, 8000000,
    7000000, 500000, 300000, 200000,
  ];

  const months = [
    'Mar 2024',
    'Apr 2024',
    'May 2024',
    'Jun 2024',
    'Jul 2024',
    'Aug 2024',
    'Sep 2024',
    'Oct 2024',
    'Nov 2024',
    'Dec 2024',
    'Jan 2025',
    'Feb 2025',
  ];

  const data = {
    labels: months,
    datasets: [
      {
        label: 'Monthly Sales',
        data: salesData,
        borderColor: lineColor,
        backgroundColor: lineColor,
        pointBorderColor: lineColor,
        pointBackgroundColor: 'white',
        pointRadius: 4,
        pointHoverRadius: 5,
        tension: 0.4,
      },
    ],
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      y: {
        beginAtZero: true,
        ticks: {
          callback: (value) =>
            value >= 1000000 ? `${value / 1000000}M` : value,
        },
        grid: {
          color: '#ddd',
        },
      },
      x: {
        ticks: {
          rotation: 0,
          autoSkip: true,
        },
      },
    },
    plugins: {
      legend: {
        display: true,
        position: 'bottom',
        labels: {
          color: lineColor,
          font: {
            size: 14,
          },
        },
      },
      datalabels: {
        display: false,
      },
    },
  };

  return (
    <div
      className="mx-auto w-100 d-flex justify-content-center align-items-center"
      style={{ height: '400px', overflow: 'hidden' }}
    >
      <Line data={data} options={options} />
    </div>
  );
};

export default OverviewLineChart;
