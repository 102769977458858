import React, { useState } from 'react';
import Table from '../../GenericTable';
import MaterialIcon from '../../commons/MaterialIcon';
import { Card, CardHeader } from 'reactstrap';
import ButtonFilterDropdown from '../../commons/ButtonFilterDropdown';

const FeedbackIcons = ({
  leftBgColor = 'bg-soft-alert-green',
  rightBgColor = 'bg-red-soft',
}) => {
  return (
    <div
      className={`d-flex align-items-center justify-content-between rounded-pill`}
    >
      <div
        className={`d-flex justify-content-start align-items-center pl-2 ${leftBgColor}`}
        style={{
          flex: 1,
          borderRadius: '50px 0 0 50px',
        }}
      >
        <MaterialIcon icon="thumb_down" />
      </div>
      <div
        className={`d-flex justify-content-end align-items-center pr-2 ${rightBgColor}`}
        style={{
          flex: 1,
          borderRadius: '0 50px 50px 0',
        }}
      >
        <MaterialIcon icon="thumb_up" />
      </div>
    </div>
  );
};

const ConversationalLog = () => {
  const data = [
    {
      date: '09-15-24',
      tenant: 'ABC Company',
      feedback: {
        thumbUp: true,
        thumbDown: false,
      },
      conversation: 'ACH File Format Requirements',
      botName: 'Nacha Rule Book',
      dataRow: [
        {
          key: 'date',
          component: <span>09-15-24</span>,
        },
        {
          key: 'tenant',
          component: <span>ABC Company</span>,
        },
        {
          key: 'feedback',
          component: (
            <FeedbackIcons
              leftBgColor="bg-soft-alert-green"
              rightBgColor="bg-red-soft"
            />
          ),
        },
        {
          key: 'conversation',
          component: <span>ACH File Format Requirements</span>,
        },
        {
          key: 'botName',
          component: <span>Nacha Rule Book</span>,
        },
      ],
    },
    {
      date: '09-10-24',
      tenant: 'XYZ Organization',
      feedback: {
        thumbUp: false,
        thumbDown: true,
      },
      conversation: 'ACH Payment Guidelines',
      botName: 'ACH Handbook',
      dataRow: [
        {
          key: 'date',
          component: <span>09-10-24</span>,
        },
        {
          key: 'tenant',
          component: <span>XYZ Organization</span>,
        },
        {
          key: 'feedback',
          component: (
            <FeedbackIcons
              leftBgColor="bg-soft-alert-green"
              rightBgColor="bg-red-soft"
            />
          ),
        },

        {
          key: 'conversation',
          component: <span>ACH Payment Guidelines</span>,
        },
        {
          key: 'botName',
          component: <span>ACH Handbook</span>,
        },
      ],
    },
  ];

  const columns = [
    {
      key: 'date',
      component: 'Date',
      width: '170px',
    },
    {
      key: 'tenant',
      component: 'Tenant',
      width: '200px',
    },
    {
      key: 'feedback',
      component: 'Feedback',
      width: '170px',
    },
    {
      key: 'conversation',
      component: 'Conversation',
      width: '245px',
    },
    {
      key: 'botName',
      component: 'Bot Name',
      width: '200px',
    },
  ];

  const [paginationInfo] = useState({
    currentPage: 1,
    pageSize: 10,
    totalItems: data.length,
  });
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [selectAll, setSelectAll] = useState(false);
  const [sortingTable] = useState('');
  const [sortingOrder] = useState('asc');
  const [dataInDB] = useState(true);
  const [permission] = useState(true);
  const [setShowCreateModal] = useState(false);

  const onPageChange = (page) => {
    console.log('Page changed to:', page);
  };

  const SechduleList = [
    { key: 'week', name: 'Last 7 days' },
    { key: 'month', name: 'Last month' },
  ];
  const ContentList = [{ key: 'content', name: 'Content' }];

  return (
    <Card className="overflow-x-hidden overflow-y-auto h-100">
      <CardHeader className="d-flex align-items-center justify-content-between">
        <div className="title">
          <h4 className="m-0">Conversational Log</h4>
        </div>
        <div className="d-flex gap-2">
          <ButtonFilterDropdown
            menuClass="rpt-history-dd-width max-h-300"
            options={SechduleList}
            icon="calendar_today"
            filterOptionSelected={SechduleList[0]}
            handleFilterSelect={() => {}}
          />
          <ButtonFilterDropdown
            menuClass="rpt-history-dd-width max-h-300"
            options={ContentList}
            filterOptionSelected={ContentList[0]}
            handleFilterSelect={() => {}}
          />
        </div>
      </CardHeader>
      <div className="table-responsive-md datatable-custom py-4 px-4">
        <Table
          checkbox={false}
          selectedData={selectedCategories}
          setSelectedData={setSelectedCategories}
          selectAll={selectAll}
          setSelectAll={setSelectAll}
          columns={columns}
          headClass="bg-gray-table-head border-top"
          data={data.map((item) => ({ ...item, dataRow: item.dataRow }))}
          paginationInfo={paginationInfo}
          onPageChange={onPageChange}
          toggle={() => setShowCreateModal(true)}
          emptyDataText="This record doesn't have any Conversational Log"
          title="Conversational Log"
          permission={permission}
          dataInDB={dataInDB}
          sortingTable={sortingTable}
          sortingOrder={sortingOrder}
          noDataInDbValidation={true}
          usePagination={false}
          actionPadding="h-100"
        />
      </div>
    </Card>
  );
};

export default ConversationalLog;
