import React from 'react';
import { useProfileContext } from '../../contexts/profileContext';

const Greetings = ({ margin = 'mb-4' }) => {
  const { profileInfo } = useProfileContext();

  return (
    <>
      <h1 className="text-truncate fs-3 text-capitalize font-weight-semi-bold text-left mb-1">
        {`Hello, ${profileInfo?.first_name || ''}!`}
      </h1>
      <h1
        className={`fs-4 text-left font-weight-medium text-black-50 ${margin}`}
      >
        How can I help you today?
      </h1>
    </>
  );
};

export default Greetings;
