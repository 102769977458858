import React, { useEffect, useState, useRef, Fragment } from 'react';

import ButtonIcon from '../../../components/commons/ButtonIcon';
import Board from '../../../components/deals/Board';
import {
  DEALS_LABEL_BUTTON,
  OWNER,
  SEARCH,
  paginationDefault,
} from '../../../utils/constants';
import dealService from '../../../services/deal.service';
import pipelineService from '../../../services/pipeline.services';
import AlertWrapper from '../../../components/Alert/AlertWrapper';
import Alert from '../../../components/Alert/Alert';
import DealList from './DealList';
import userService from '../../../services/user.service';
import { DataFilters } from '../../../components/DataFilters';
import AddDeal from '../../../components/peopleProfile/deals/AddDeal';
import stageService from '../../../services/stage.service';
import Skeleton from 'react-loading-skeleton';
import { usePipelineBoardContext } from '../../../contexts/PipelineBoardContext';
import moment from 'moment';
import TooltipComponent from '../../../components/lesson/Tooltip';
import {
  formatAbbreviated,
  isPermissionAllowed,
  numbersWithComma,
} from '../../../utils/Utils';
import FilterTabsButtonDropdown from '../../../components/commons/FilterTabsButtonDropdown';
import { usePagesContext } from '../../../contexts/pagesContext';
import { useModuleContext } from '../../../contexts/moduleContext';
import _ from 'lodash';
import SortButtonDropdown from '../../../components/commons/SortButtonDropdown';
import useDefaultFilter from '../../../hooks/useDefaultFilter';
import { ReportSkeletonLoader } from '../../Insights/ReportSkeletonLoader';
import { LOST, OPENED, WON } from './Pipeline.constants';
import MaterialIcon from '../../../components/commons/MaterialIcon';
import TextOverflowTooltip from '../../../components/commons/TextOverflowTooltip';
import PageTitle from '../../../components/commons/PageTitle';
import { LexoRank } from 'lexorank';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { TransitionGroup } from 'react-transition-group';
import Collapse from '@mui/material/Collapse';
import { changePaginationLimit, changePaginationPage } from '../contacts/utils';
import { Form } from 'react-bootstrap';
import MenuAccordion from '../../../components/commons/MenuAccordion';
import { isLucidIcon } from '../../../components/commons/IconDropdownSearch';

let DEALS_FILTER_OPTIONS_LIST = [
  { id: 14, key: OPENED, name: 'Open Deals' },
  { id: 15, key: 'closed', name: 'Closed Deals' },
  { id: 16, key: WON, name: 'Won Deals' },
  { id: 17, key: LOST, name: 'Lost Deals' },
  { id: 18, key: 'OneMonth', name: 'Deals created in this month' },
  { id: 19, key: 'ThreeMonths', name: 'More than 3 months old deals' },
];

const DEALS_VALUE_OPTIONS_LIST = [
  {
    id: 20,
    value: '1',
    title: '$0 - $10,000',
    range: { start: '0', end: '10000' },
  },
  {
    id: 21,
    value: '2',
    title: '$10,000 - $50,000',
    range: { start: '10000', end: '50000' },
  },
  {
    id: 22,
    value: '3',
    title: '$50,000 - $100,000',
    range: { start: '50000', end: '100000' },
  },
  {
    id: 23,
    value: '4',
    title: '$100,000 - $500,000',
    range: { start: '100000', end: '500000' },
  },
  {
    id: 24,
    value: '5',
    title: '$500,000 - $1,000,000',
    range: { start: '500000', end: '1000000' },
  },
  {
    id: 25,
    value: '6',
    title: '$1,000,000+',
    range: { start: '1000000', end: '100000000' },
  },
];

const OwnerShipFilter = {
  My: 'MyDeals',
  All: 'AllDeals',
};

const sortDefaultFilter = {
  id: 4,
  key: 'name',
  name: 'Name',
};

const BoardLoader = ({ count }) => {
  const [loaderCount] = useState(Array(count).fill(0));
  const ColumnLoader = () => {
    return (
      <div className="px-1 text-center pipeline-board-edit">
        <Skeleton
          count={6}
          height={80}
          className="my-2 d-block w-100 deal-col"
        />
      </div>
    );
  };
  return (
    <div className="d-flex justify-content-between flex-row w-100 parent-column">
      {loaderCount.map((_, index) => (
        <ColumnLoader key={index} />
      ))}
    </div>
  );
};

const Nav = ({ active = false, onClick, togglePipelineEdit }) => {
  return (
    <div className="mx-3">
      <ul
        className="nav nav-segment border bg-white p-0"
        id="leadsTab"
        role="tablist"
      >
        <li className="nav-item">
          <TooltipComponent title="Column view">
            <a
              className={`btn-sm btn rounded-left rounded-right-0 hover-icon d-flex align-items-center justify-content-center bg-hover-gray ${
                active ? 'bg-primary-soft fw-bold text-primary' : ''
              }`}
              id="pipeline-tab"
              data-toggle="tab"
              role="tab"
              aria-controls="pipeline"
              aria-selected="true"
              onClick={() => onClick(true)}
            >
              <MaterialIcon icon="view_week" />
            </a>
          </TooltipComponent>
        </li>
        <li className="nav-item">
          <TooltipComponent title="List view">
            <a
              className={`btn-sm btn rounded-left-0 rounded-right hover-icon d-flex align-items-center justify-content-center bg-hover-gray ${
                !active ? 'bg-primary-soft fw-bold text-primary' : ''
              }`}
              id="list-tab"
              data-toggle="tab"
              role="tab"
              aria-controls="list"
              aria-selected="false"
              onClick={() => onClick(false)}
            >
              <MaterialIcon icon="menu" />
            </a>
          </TooltipComponent>
        </li>
      </ul>
    </div>
  );
};

const PipelineItem = ({
  pipeline,
  index,
  selectedPipeline,
  handleSelectedPipeline,
}) => {
  const [isHovered, setIsHovered] = useState(false);
  return (
    <Draggable key={pipeline.id} draggableId={pipeline.id} index={index}>
      {(provided, snapshot) => (
        <li
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          className={`setting-item ${
            snapshot.isDragging
              ? 'shadow-lg bg-white rounded-lg'
              : 'bg-hover-gray'
          } `}
        >
          <a
            className={`cursor-pointer w-100 d-flex gap-0 pl-0 pr-1 align-items-center menu-hover-gray mb-0 fs-7 primary ${
              selectedPipeline?.key === pipeline.key
                ? 'active font-weight-medium bg-primary-soft'
                : 'text-black font-weight-normal'
            }`}
            onClick={() => handleSelectedPipeline(pipeline)}
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
          >
            <span style={{ visibility: isHovered ? '' : 'hidden' }}>
              <MaterialIcon
                icon="drag_indicator"
                clazz="text-gray-600 cursor-grab ml-n0 mt-1"
              />
            </span>
            <div className="d-flex align-items-center gap-1 ml-n0">
              <MaterialIcon
                size="fs-6"
                isLucide={isLucidIcon(pipeline?.icon)}
                icon={pipeline.icon}
                filled={false}
              />
              <TextOverflowTooltip
                textStyle="font-weight-medium text-truncate font-size-sm2 mb-0"
                text={pipeline.name}
                maxLength={25}
              />
            </div>
          </a>
        </li>
      )}
    </Draggable>
  );
};

const StatItem = ({ label, count, type, classNames }) => {
  return (
    <div className={`d-flex align-items-center gap-1 ${classNames || ''}`}>
      <label className="mb-0 fs-7 font-weight-normal">{label}</label>
      <span className="text-gray-400">•</span>
      <label className="mb-0 text-black">
        <span>
          {type === 'currency' ? '$' : ''}
          {count}
        </span>
      </label>
    </div>
  );
};

const Deals = () => {
  const { pageContext, setPageContext } = usePagesContext();
  const isMounted = useRef(false);
  const [active, setActive] = useState(
    pageContext?.PipelinePage?.selectedView ?? true
  );
  const [openDeal, setOpenDeal] = useState(false);
  const [showLoading, setShowLoading] = useState(false);
  const [tableLoading, setTableLoading] = useState(true);
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [allDeals, setAllDeals] = useState([]);
  const [filtersItems, setFiltersItems] = useState([]);
  const { moduleMap } = useModuleContext();

  const defaultOpportunitiesForOwner = [
    { id: 12, key: OwnerShipFilter.My, name: `My ${moduleMap.deal.plural}` },
    { id: 13, key: OwnerShipFilter.All, name: `All ${moduleMap.deal.plural}` },
  ];
  const defaultAllFilter = {
    key: OwnerShipFilter.My,
    name: `My ${moduleMap.deal.plural}`,
    filter: {},
  };
  const DEALS_SORT_OPTIONS_LIST = [
    { id: 4, name: 'Recent Activity', key: 'date_modified', suffix: 'asc' },
    { id: 3, name: 'Closing Date', key: 'date_closed', suffix: 'asc' },
    {
      id: 2,
      name: `${moduleMap.deal.singular} Value`,
      key: 'amount',
      suffix: 'asc',
    },
    {
      id: 1,
      name: `${moduleMap.deal.singular} Name`,
      key: 'name',
      suffix: 'asc',
    },
  ];

  const [sortFilterOption, setSortFilterOption] = useState(
    DEALS_SORT_OPTIONS_LIST
  );
  const [defaultFilter] = useDefaultFilter('deal');

  const [filterSelected, setFilterSelected] = useState(
    pageContext?.PipelinePage?.selectedFilter ?? defaultFilter
  );

  const [searchTerm, setSearchTerm] = useState({});
  const [dataInDB, setDataInDB] = useState(false);
  const [pagination, setPagination] = useState(paginationDefault);
  const [paginationData, setPaginationData] = useState(paginationDefault);
  const { selectedDealFilterKey } = pageContext?.PipelinePage || {};

  // Find the matching filter option based on `selectedDealFilterKey`
  const defaultFilters = selectedDealFilterKey
    ? DEALS_FILTER_OPTIONS_LIST?.find(
        (option) => option.key === selectedDealFilterKey
      )
    : {};

  const [addDealBtnLoading, setAddDealBtnLoading] = useState(false);
  const [infoDeals, setInfoDeals] = useState({});
  const [flagDeal, setFlagDeal] = useState([]);
  const [order, setOrder] = useState([]);
  const [filterSortSelected, setFilterSortSelected] = useState({});
  const [initialDeals, setInitialDeals] = useState({});
  const [listDeals, setListDeals] = useState(initialDeals);
  const [recentUpdatedDeals, setRecentUpdatedDeals] = useState([]);
  const [pipelineEdit, setPipelineEdit] = useState(false);
  const { stages, setStages } = usePipelineBoardContext();
  const [refreshBoardHeader, setRefreshBoardHeader] = useState(1);
  const [selectedStage, setSelectedStage] = useState({});
  const [openFilter, setOpenFilter] = useState(false);
  const [sort, setSort] = useState([]);
  const [openSortFilter, setOpenSortFilter] = useState(false);
  const [dealFilterTab, setDealFilterTab] = useState('filters');
  const [dealFilterOptionSelected, setDealFilterOptionSelected] = useState(
    pageContext?.PipelinePage?.dealFilterOptionSelected ?? defaultFilters
  );
  const [filterOptionSelected, setFilterOptionSelected] =
    useState(sortDefaultFilter);
  const [me, setMe] = useState(null);
  const [, setTitle] = useState({
    id: 14,
    key: OPENED,
    name: 'Open Deals',
  });
  const [pipelines, setPipelines] = useState([]);
  const [selectedPipeline, setSelectedPipeline] = useState({});
  const [loadingPipelines, setLoadingPipelines] = useState(false);
  const [selectedOwner, setSelectedOwner] = useState(
    pageContext?.PipelinePage?.selectedOwner ?? ''
  );
  const [ownershipFilter, setOwnershipFilter] = useState(
    pageContext?.PipelinePage?.ownershipFilter ?? defaultAllFilter
  );

  const [amountRangeSelected, setAmountRangeSelected] = useState({});
  const [probabilityRangeSelected, setProbabilityRangeSelected] = useState({
    min: 0,
    max: 100,
    all: false,
  });

  const [stats, setStats] = useState({
    MyOpportunities: [],
    AllOpportunities: [],
  });
  const [statsLoading, setStatsLoading] = useState(true);

  useEffect(() => {
    setFilterSelected(
      pageContext?.PipelinePage?.selectedFilter ?? defaultFilter
    );
  }, [defaultFilter]);

  const getStageByName = (name) => {
    return stages.find((stage) => stage?.name === name);
  };
  const ownershipOptions = [
    {
      label: `My ${moduleMap.deal.plural}`,
      filter: { done: false },
      key: OwnerShipFilter.My,
      name: 'My Opportunities',
      selected: true,
    },
    {
      label: `All ${moduleMap.deal.plural}`,
      filter: {},
      key: OwnerShipFilter.All,
      name: 'All Opportunities',
      selected: false,
    },
  ];

  useEffect(() => {
    (async () => {
      setListDeals({});
      const pipelineId = selectedPipeline?.id;
      if (pipelineId) {
        setShowLoading(true);

        const responses = await Promise.allSettled([
          stageService.getStages(pipelineId),
          pipelineService.getPipelineStageStatuses(),
        ]);
        const stages = responses[0]?.value || [];
        const getStages = {};
        stages?.forEach((stage) => {
          getStages[stage?.name] = {
            loading: true,
            id: stage?.id,
            pipelineId,
            pipelineStageStatusId: stage?.pipelineStageStatusId,
            stagePosition: stage?.position,
            rank: stage?.rank || LexoRank.max().toString(),
            name: stage?.name,
            title: stage?.name,
          };
        });
        setStages(stages);
        setInitialDeals(getStages);
        setShowLoading(false);
      }
      onGetUsers();
    })();
  }, [refreshBoardHeader, selectedPipeline]);

  useEffect(() => {
    (async () => {
      setLoadingPipelines(true);
      const { data } = await pipelineService.getPipelines();
      const updatedPipelines = data?.map((p) => ({ ...p, key: p.id }));
      const defaultPipeline =
        updatedPipelines?.find((d) => d?.isDefault) ||
        (updatedPipelines?.length && updatedPipelines[0]);
      setPipelines(updatedPipelines);
      const contextPipeline =
        pageContext?.PipelinePage?.selectedPipeline ?? defaultPipeline;
      setSelectedPipeline(contextPipeline || {});
      setLoadingPipelines(false);
    })();
    // clear context here to not mix with other deals date.
    if (pageContext?.DealDateClose) {
      delete pageContext.DealDateClose;
    }
  }, []);

  useEffect(() => {
    if (moduleMap.deal) {
      DEALS_FILTER_OPTIONS_LIST = DEALS_FILTER_OPTIONS_LIST.map((option) => ({
        ...option,
        name: option.name.replace(/Deals/i, _.startCase(moduleMap.deal.plural)),
      }));
    }
  }, [moduleMap.deal]);

  useEffect(() => {
    (async () => {
      const me = await userService
        .getUserInfo()
        .catch((err) => console.log(err));
      setMe(me);
    })();
  }, []);

  const fetchDeals = (deals) => {
    deals.forEach((item) => {
      getDeals(
        {
          ...item,
          name: item?.name,
          id: item?.id,
          stagePosition: item?.position || item?.stagePosition,
        },
        paginationDefault.page
      );
    });
  };

  useEffect(() => {
    if (active) {
      const dealsToFetch = !recentUpdatedDeals.length
        ? Object.values(initialDeals) // Fetch all deals if no recent deal update
        : Object.values(initialDeals).filter((deal) =>
            recentUpdatedDeals.includes(deal.id)
          ); // fetch only updated deals

      fetchDeals(dealsToFetch);
    } else onGetDeals(true);
  }, [active, paginationData, flagDeal, initialDeals]);

  useEffect(() => {
    const summary = [];
    let openDealsCount = 0;
    Object.keys(listDeals).forEach((key) => {
      if (listDeals[key]?.header?.total_amount) {
        summary.push(listDeals[key]?.header);
      }
      openDealsCount = openDealsCount + listDeals[key]?.pagination?.count;
      setInfoDeals({});
    });
  }, [listDeals]);

  async function onGetUsers() {
    const response = await userService
      .getUsers(
        {
          search: '',
          users: [],
          filters: '',
        },
        {}
      )
      .catch((err) => err);

    const { data } = response || {};

    const newFilterOptions = filtersItems.slice();
    newFilterOptions.push({
      id: 1,
      label: OWNER,
      name: 'assigned_user_id',
      options: data?.users,
      type: 'search',
    });

    setFiltersItems(newFilterOptions);
  }

  const filterID = (id, FList) => {
    return FList
      ? FList.includes(id)
        ? FList.filter((n) => n !== id)
        : [id, ...FList]
      : id;
  };

  const onHandleFilterOwnerr = (item, avatars = true) => {
    const prevFils = filterSelected.filter
      ? filterSelected.filter.assigned_user_id
      : null;
    setOpenFilter(false);

    if (item) setListDeals(initialDeals);

    const newFilterOptionSelected = {
      key: item.id,
      id: item.id,
      name: item?.id ? `${item?.first_name} ${item?.last_name}` : 'Filters',
      type: 'owner',
    };
    setDealFilterOptionSelected(newFilterOptionSelected);
    setFilterSelected({
      ...filterSelected,
      filter: {
        assigned_user_id: avatars ? filterID(item.id, prevFils) : item.id,
      },
    });

    setPageContext({
      ...pageContext,
      PipelinePage: {
        ...pageContext.PipelinePage,
        selectedDealFilterKey: OwnerShipFilter.My,
        dealFilterOptionSelected: newFilterOptionSelected,
        selectedOwner: item,
        selectedFilter: {
          ...filterSelected,
          filter: {
            assigned_user_id: avatars ? filterID(item.id, prevFils) : item.id,
          },
        },
      },
    });

    setPaginationData((prevState) => ({ ...prevState, page: 1 }));
    setSelectedOwner(item);
  };

  const onHandleFilterOwner = (item) => {
    onHandleFilterOwnerr(item, false);
  };

  useEffect(() => {
    (async () => {
      if (filterSelected.filter) {
        if (
          filterSelected.filter.assigned_user_id &&
          filterSelected.filter.assigned_user_id.length !== 0
        ) {
          const Len = filterSelected.filter.assigned_user_id.length;
          if (Len > 1) {
            setTitle({ key: 0, name: selectedOwner?.name });
          }
        } else if (
          filterSelected.filter.status ||
          filterSelected.filter.start_date
        ) {
          // dont liking it :| not breaking faizan implementation
          const filterStatus = filterSelected.filter.discriminator
            ? filterSelected.filter.discriminator
            : filterSelected.filter.status;

          const Title = DEALS_FILTER_OPTIONS_LIST.filter(
            (status) => status.key === filterStatus
          )[0];
          setTitle(Title);
        }
      }
    })();
  }, [filterSelected]);

  const cleanFilter = () => {
    // this is trash
    const { filter } = filterSelected || { filter: {} };
    const { discriminator, ...rest } = filter || {};
    return rest;
  };
  const onGetDeals = async (orderArray) => {
    try {
      const { data, pagination } = await pipelineService.getPipelineDeals(
        selectedPipeline.id,
        paginationData.page,
        paginationData.limit,
        cleanFilter(),
        orderArray?.length > 0 ? orderArray : sort
      );
      setDataInDB(Boolean(pagination?.count));
      if (pagination) setPagination(pagination);
      setAllDeals(data);
      setInfoDeals({});
    } catch (e) {
      console.log(e);
    } finally {
      setLoadingPipelines(false);
      setShowLoading(false);
      setTableLoading(false);
    }
  };

  const setNotification = async (notificationCode, description) => {
    const notificationsStatus = {
      success: setSuccessMessage,
      error: setErrorMessage,
    };

    notificationsStatus[notificationCode](description);
  };

  const getDeals = async (status, page, sortArray, dropped) => {
    const filters = cleanFilter();
    if (
      Object.keys(filters).length ||
      dealFilterOptionSelected?.key === OwnerShipFilter.All
    ) {
      const foundStage = getStageByName(status?.name);
      const sortArrayParam = sortArray?.length > 0 ? [sortArray] : null;

      try {
        const { data, pagination } = await pipelineService.getPipelineDeals(
          selectedPipeline?.id,
          page,
          1000,
          {
            ...filters,
            tenant_deal_stage_id: status.id,
          },
          sortArrayParam?.length > 0 ? sortArrayParam : sort
        );

        // Concatenate the new data with the existing data
        setListDeals((prev) => {
          const items = prev[status.name]?.items || [];
          return {
            ...prev,
            [status.name]: {
              stageId: status.id,
              loading: false,
              stagePosition: foundStage?.position || status.stagePosition,
              items: [...items, ...data], // Concatenate new data with existing data
              pagination,
              header: {
                ...status,
                tenant_deal_stage_id: status.id,
                total_amount: calculateTotalAmount([...items, ...data]), // Recalculate total amount
              },
            },
          };
        });
        setInfoDeals({});
      } catch (e) {
        console.log(e);
      } finally {
        setShowLoading(false);
        setTableLoading(false);
        setLoadingPipelines(false);
        setRecentUpdatedDeals([]);
      }
    }
  };

  //  Function to calculate the total amount
  const calculateTotalAmount = (deals) => {
    return deals.reduce((acc, dt) => {
      return {
        amount: (acc?.amount || 0) + dt.amount,
      };
    }, 0).amount;
  };

  const dataFilter = (search) => {
    setListDeals(initialDeals);
    setFilterSelected(search);
    setPaginationData((prevState) => ({ ...prevState, page: 1 }));
    setPageContext({
      ...pageContext,
      PipelinePage: {
        ...pageContext.PipelinePage,
      },
    });
  };

  useEffect(() => {
    if (isMounted.current) {
      const delayTime = setTimeout(() => {
        dataFilter(searchTerm);
      }, [1000]);
      return () => clearTimeout(delayTime);
    } else isMounted.current = true;
  }, [searchTerm]);

  const editPipeline = (e) => {
    e?.preventDefault();
    e?.stopPropagation();
    setPipelineEdit(!pipelineEdit);
  };

  const changeView = (value) => {
    if (active !== value) {
      setActive(value);
      setPaginationData(paginationDefault);
      setListDeals(initialDeals);

      setShowLoading(false);
      setTableLoading(false);
      setPageContext({
        ...pageContext,
        PipelinePage: {
          ...pageContext.PipelinePage,
          selectedView: !active, // true means column, false means table
        },
      });
    }
  };

  const refreshDeals = (type, page, load = false) => {
    if (load) getDeals(type, page);
    else {
      type.forEach((status) => {
        setListDeals((prev) => ({
          ...prev,
          [status.name]: {
            stageId: status.id,
            stagePosition: status.stagePosition,
            loading: true,
            items: [],
            pagination: page,
            header: [],
          },
        }));
        getDeals(status, paginationDefault.page);
      });
    }
  };

  const onAddDeal = async (stage) => {
    setAddDealBtnLoading(true);
    setOpenDeal(true);
    setAddDealBtnLoading(false);
  };

  const handleRefreshBoardHeader = () => {
    setTableLoading(true);
    setRefreshBoardHeader((prevState) => prevState + 1);
    setPipelineEdit(!pipelineEdit);
    setListDeals({});
  };

  const refreshBoard = (navigation) => {
    if (navigation?.sourceId || navigation?.destinationId) {
      const { sourceId, destinationId } = navigation;
      const uniqueIds =
        sourceId === destinationId ? [sourceId] : [sourceId, destinationId];

      setRecentUpdatedDeals(uniqueIds); // save unqiue updated deal ids

      // update the loading state only for deals that are now changed
      const updatedDeals = Object.keys(initialDeals).reduce((acc, key) => {
        const deal = initialDeals[key];

        if (uniqueIds.includes(deal.id)) {
          // for uniqueIds, update the deal completely with loading true
          acc[key] = { ...deal, loading: true };
        } else {
          // for non-uniqueIds, pick from listDeals and set loading to false
          acc[key] = { ...listDeals[key], loading: false } || {
            ...deal,
            loading: false,
          };
        }

        return acc;
      }, {});

      setListDeals(updatedDeals);
    } else {
      setListDeals(initialDeals);
    }

    setFlagDeal(!flagDeal);
  };

  const handleAddDeal = async (stage) => {
    setSelectedStage(stage);
    setAddDealBtnLoading(true);
    setOpenDeal((prev) => !prev);
    setAddDealBtnLoading(false);
  };

  const handleFilterSelect = (e, status) => {
    e.preventDefault();
    setOpenFilter(!openFilter);
    setListDeals(initialDeals);
    setSelectedOwner('');
    const { key } = status;
    setDealFilterOptionSelected(status);
    let filter = {};

    if (key === OwnerShipFilter.My) {
      filter = { assigned_user_id: me.id };
      setOwnershipFilter(ownershipOptions[0]);
      setPageContext({
        ...pageContext,
        PipelinePage: {
          ...pageContext.PipelinePage,
          ownershipFilter: ownershipOptions[0],
          selectedDealFilterKey: key,
          selectedFilter: { ...filterSelected, filter },
          dealFilterOptionSelected: status,
          selectedOwner: '',
        },
      });
    } else if (key === 'OneMonth') {
      const now = moment().toISOString();
      const startOfMonth = moment().startOf('month').toISOString();
      filter = {
        start_date: startOfMonth,
        end_date: now,
        discriminator: 'OneMonth',
      };
    } else if (key === 'ThreeMonths') {
      const startOfTime = moment(new Date(1970, 0, 1)).toISOString();
      const threeMonthsOld = moment().subtract(3, 'months').toISOString();
      filter = {
        start_date: startOfTime,
        end_date: threeMonthsOld,
        discriminator: 'ThreeMonths',
      };
    } else if (key === OwnerShipFilter.All) {
      setOwnershipFilter(ownershipOptions[1]);
      filter = {};
      setPageContext({
        ...pageContext,
        PipelinePage: {
          ...pageContext.PipelinePage,
          selectedDealFilterKey: key,
          selectedFilter: { ...filterSelected, filter },
          ownershipFilter: ownershipOptions[1],
          dealFilterOptionSelected: status,
          selectedOwner: '',
        },
      });
    } else {
      filter = { status: status.key };
    }

    setPaginationData((prevState) => ({ ...prevState, page: 1 }));
    setFilterSelected({
      ...filterSelected,
      filter,
    });

    if (key !== OwnerShipFilter.All && key !== OwnerShipFilter.My) {
      setPageContext({
        ...pageContext,
        PipelinePage: {
          ...pageContext.PipelinePage,
          selectedDealFilterKey: key,
          selectedFilter: { ...filterSelected, filter },
          dealFilterOptionSelected: status,
          selectedOwner: '',
        },
      });
    }
  };

  const sortTable = ({ name }) => {
    // Find the matching object in DEALS_SORT_OPTIONS_LIST
    const matchingOption = DEALS_SORT_OPTIONS_LIST.find(
      (option) => option.key === name.orderBy
    );

    if (matchingOption) {
      setSortFilterOption((prevSortFilterOption) => {
        const updatedOptions = prevSortFilterOption.map((item) => {
          if (item.key === name.orderBy) {
            const updatedItem = {
              ...item,
              suffix: name.clicked === 'ASC' ? 'desc' : 'asc',
            };
            return updatedItem;
          }
          return item;
        });
        return updatedOptions;
      });

      handleSelectedFilter(matchingOption);
    }
  };

  const handleSelectedFilter = (status) => {
    setListDeals([]);
    if (status) {
      setSortFilterOption((prevSortFilterOption) => {
        const updatedOptions = prevSortFilterOption.map((item) => {
          if (item.id === status?.id) {
            const updatedItem = {
              ...item,
              suffix: item.suffix === 'asc' ? 'desc' : 'asc',
            };
            setTableLoading(true);

            const orderArray = [updatedItem.key, updatedItem.suffix];
            stages?.map((item) => {
              return getDeals(item, 1, orderArray);
            });
            setOrder([item.key, item.suffix === 'asc' ? 'DESC' : 'ASC']);
            setSort([orderArray]);
            onGetDeals([orderArray]);
            setFilterOptionSelected(updatedItem); // Update filterOptionSelected with the updated item
            return updatedItem;
          }
          return item;
        });

        return updatedOptions;
      });
    } else {
      setSortFilterOption(DEALS_SORT_OPTIONS_LIST);
      setFilterOptionSelected(sortDefaultFilter);
      setOrder([]);
      setSort([]);
      setTableLoading(true);
      stages?.map((item) => {
        return getDeals(item, 1, []);
      });
      onGetDeals([]);
    }
  };

  const handleRemoveValueFilter = () => {
    setOpenFilter(!openFilter);
    setListDeals(initialDeals);
    const filter = { ...filterSelected };
    if (filter.filter?.amountRange) {
      delete filter.filter.amountRange;
    }
    if (filter.filter?.probabilityRange) {
      delete filter.filter.probabilityRange;
    }
    setFilterSelected(filter);
    setAmountRangeSelected({});
    setProbabilityRangeSelected({
      min: 0,
      max: 100,
      all: false,
    });
    setPaginationData((prevState) => ({ ...prevState, page: 1 }));
  };

  const handleSelectedValueFilter = () => {
    setOpenFilter(!openFilter);
    setListDeals(initialDeals);

    const formatNumber = (value) =>
      value !== null && value !== undefined ? Number(value) : undefined;

    let newFilter = {};

    const selectedOption = DEALS_VALUE_OPTIONS_LIST.find(
      (option) => option.value === amountRangeSelected
    );

    if (amountRangeSelected === '-1') {
      delete newFilter.amountRange;
    } else if (selectedOption) {
      newFilter = {
        amountRange: {
          start: formatNumber(selectedOption.range.start),
          end: formatNumber(selectedOption.range.end),
        },
      };
    }

    newFilter = {
      ...newFilter,
      probabilityRange: {
        start: formatNumber(probabilityRangeSelected.min),
        end: formatNumber(probabilityRangeSelected.max),
      },
    };

    // IDF-6538 fix.
    if (ownershipFilter.key === OwnerShipFilter.All && filterSelected.filter) {
      delete filterSelected.filter.assigned_user_id;
    }

    setFilterSelected({
      ...filterSelected,
      filter: {
        ...filterSelected.filter,
        ...newFilter,
      },
    });

    setPaginationData((prevState) => ({ ...prevState, page: 1 }));
  };

  const handleSelectedPipeline = (pipe) => {
    if (pipe?.id !== selectedPipeline?.id) {
      setSelectedPipeline(pipe);
      setPaginationData((prevState) => ({ ...prevState, page: 1 }));
      setPageContext({
        ...pageContext,
        PipelinePage: {
          selectedPipeline: pipe || {},
          selectedView: active, // true means column, false means table
        },
      });
    }
  };

  const defaultPipeline = pipelines?.find((item) => item?.isDefault);
  const otherPipelines = pipelines?.filter((item) => !item?.isDefault);
  const [, setSortedPipelines] = useState(
    defaultPipeline ? [defaultPipeline, ...otherPipelines] : otherPipelines
  );

  const updatePipelineRank = async (pipeline) => {
    try {
      await pipelineService.updatePipeline(pipeline?.id, {
        rank: pipeline.rank,
      });
      setSuccessMessage('Opportunity rearranged successfully.');
    } catch (err) {
      console.log(err);
      setErrorMessage(
        'Error in updating pipeline position. Please check console for details.'
      );
    }
  };

  const updatePipelineOrder = (fromIndex, toIndex) => {
    const updatedItems = [...pipelines];
    const [movedItem] = updatedItems.splice(fromIndex, 1);
    updatedItems.splice(toIndex, 0, movedItem);

    const previousRank =
      toIndex === 0
        ? LexoRank.min()
        : LexoRank.parse(updatedItems[toIndex - 1].rank);
    const nextRank =
      toIndex === updatedItems.length - 1
        ? LexoRank.max()
        : LexoRank.parse(updatedItems[toIndex + 1].rank);
    const newRank = previousRank.between(nextRank).toString();

    movedItem.rank = newRank;
    updatedItems.sort((a, b) => a.rank.localeCompare(b.rank));
    setSortedPipelines(updatedItems);
    updatePipelineRank(movedItem);
  };

  const onHandleDragEnd = (result) => {
    if (!result.destination) {
      return;
    }

    updatePipelineOrder(result.source.index, result.destination.index);
  };

  const getStats = async () => {
    setStatsLoading(true);

    const countRequests = [
      {
        key: 'MyOpportunities',
        apiCall: () =>
          pipelineService.getPipelineAverageByDealAmount(
            true,
            selectedPipeline.id
          ),
      },
      {
        key: 'AllOpportunities',
        apiCall: () =>
          pipelineService.getPipelineAverageByDealAmount(
            false,
            selectedPipeline.id
          ),
      },
    ];

    try {
      const responses = await Promise.allSettled(
        countRequests.map((request) => request.apiCall())
      );

      const updatedStats = {};

      countRequests.forEach((request, index) => {
        if (responses[index].status === 'fulfilled') {
          const responseValue = responses[index].value;
          updatedStats[request.key] = responseValue || [];
        } else {
          updatedStats[request.key] = [];
          console.error(
            `Error fetching ${request.key}:`,
            responses[index].reason
          );
        }
      });

      setStats(updatedStats);
    } catch (error) {
      console.error('Error fetching counts:', error);
    } finally {
      setStatsLoading(false);
    }
  };

  useEffect(() => {
    if (selectedPipeline.id) {
      getStats();
    }
  }, [selectedPipeline]);

  const DealStats = () => {
    const getOpportunitiesData = (data) => {
      const filteredData = data;

      const totalCount = filteredData?.reduce(
        (sum, item) => sum + item.count,
        0
      );
      const totalAmount = filteredData?.reduce(
        (sum, item) => sum + item.totalAmount,
        0
      );
      const averageAmount = filteredData?.reduce(
        (sum, item) => sum + item.averageAmount,
        0
      );

      return {
        totalCount: numbersWithComma(totalCount),
        totalAmount: formatAbbreviated(totalAmount),
        averageAmount: formatAbbreviated(averageAmount),
      };
    };

    const opportunities = getOpportunitiesData(
      ownershipFilter?.key === 'MyDeals'
        ? stats.MyOpportunities
        : stats.AllOpportunities
    );

    return (
      <div className="stats">
        {statsLoading ? (
          <ul className="w-100">
            <li className="flex-fill px-2">
              <Skeleton width={100} height={10} />
            </li>
          </ul>
        ) : (
          <ul>
            <li className="font-size-sm2">
              <StatItem count={opportunities.totalCount} label="Total" />
            </li>
            <li className="font-size-sm2">
              <StatItem
                count={opportunities.totalAmount}
                type="currency"
                label="Value"
              />
            </li>
          </ul>
        )}
      </div>
    );
  };

  const handleOwnershipSelect = (e, item) => {
    e.preventDefault();
    if (item.key !== ownershipFilter?.key) {
      const filterKey =
        item.key === OwnerShipFilter.My
          ? OwnerShipFilter.My
          : OwnerShipFilter.All;
      handleFilterSelect(
        e,
        defaultOpportunitiesForOwner.find((item) => item.key === filterKey)
      );
      setOpenFilter(false);
    }
  };

  const renderOwnershipOptions = () => {
    return (
      <>
        <div className="d-flex flex-column gap-2 mx-3 py-2">
          {ownershipOptions?.map((item) => (
            <Form.Check
              key={item.key}
              id={item.key}
              inline
              label={item.label}
              name={item.key}
              className={`large-radio fs-7`}
              type="radio"
              checked={item?.key === ownershipFilter?.key}
              onChange={(e) => handleOwnershipSelect(e, item)}
            />
          ))}
        </div>
      </>
    );
  };

  const renderOpportunities = () => {
    return (
      <>
        <div>
          {loadingPipelines ? (
            <ReportSkeletonLoader rows={5} height={30} />
          ) : (
            <DragDropContext
              onDragEnd={onHandleDragEnd}
              className="overflow-y-auto"
            >
              <Droppable droppableId="pipeline-main-list">
                {(provided, snapshot) => (
                  <ul
                    {...provided.droppableProps}
                    ref={provided.innerRef}
                    style={{ listStyleType: 'none' }}
                  >
                    <TransitionGroup appear={true}>
                      {pipelines
                        ?.sort((a, b) => a.rank.localeCompare(b.rank))
                        ?.map((item, index) => (
                          <Collapse key={item.id}>
                            <PipelineItem
                              pipeline={item}
                              selectedPipeline={selectedPipeline}
                              handleSelectedPipeline={handleSelectedPipeline}
                              index={index}
                              key={index}
                            />
                          </Collapse>
                        ))}
                      {provided.placeholder}
                    </TransitionGroup>
                  </ul>
                )}
              </Droppable>
            </DragDropContext>
          )}
        </div>
      </>
    );
  };

  const sidebarOptions = [
    {
      id: 1,
      label: moduleMap.deal.plural,
      icon: 'monetization_on',
      component: renderOpportunities(),
    },
    {
      id: 2,
      label: 'Ownership',
      icon: 'supervisor_account',
      component: renderOwnershipOptions(),
    },
  ];

  return (
    <>
      <AlertWrapper>
        <Alert
          color="danger"
          message={errorMessage}
          setMessage={setErrorMessage}
        />
        <Alert
          color="success"
          message={successMessage}
          setMessage={setSuccessMessage}
        />
      </AlertWrapper>

      <PageTitle
        page={selectedPipeline?.name}
        pageModule={moduleMap.deal.plural}
      />
      <div className="settings-layout position-relative">
        <div className="setting-sidebar opportunity-left-sidebar">
          <div className="d-flex flex-column p-3 overflow-x-hidden overflow-y-auto transparent-scroll-track">
            <MenuAccordion options={sidebarOptions} />
          </div>
        </div>
        <div className="settings-content">
          <div className="d-flex mb-0 pipline-options-btn bg-white py-2_1 px-3">
            <FilterTabsButtonDropdown
              options={DEALS_FILTER_OPTIONS_LIST}
              openFilter={openFilter}
              setOpenFilter={setOpenFilter}
              filterOptionSelected={dealFilterOptionSelected}
              filterSelected={filterSelected}
              filterTabs={dealFilterTab}
              handleFilterSelect={handleFilterSelect}
              onHandleFilterOwner={onHandleFilterOwner}
              setFilterOptionSelected={setDealFilterOptionSelected}
              setFilterSelected={setFilterSelected}
              setFilterTabs={setDealFilterTab}
              defaultSelection={defaultFilters}
              extraClasses={'dropdown-left'}
              selectedOwner={selectedOwner}
              setSelectedOwner={setSelectedOwner}
              valueFilterOptions={DEALS_VALUE_OPTIONS_LIST}
              amountRangeSelected={amountRangeSelected}
              setAmountRangeSelected={setAmountRangeSelected}
              probabilityRangeSelected={probabilityRangeSelected}
              setProbabilityRangeSelected={setProbabilityRangeSelected}
              handleSelectedValueFilter={handleSelectedValueFilter}
              handleRemoveValueFilter={handleRemoveValueFilter}
              module="deal"
            />
            <div className="ml-auto mr-3 btn-filter">
              <DataFilters
                filterSelected={filterSelected}
                setFilterSelected={setSearchTerm}
                searchPlaceholder={SEARCH}
                infoDeals={infoDeals}
                paginationPage={paginationData}
                setPaginationPage={setPaginationData}
                showSearch={false}
                variant
              >
                <div className="filter-right d-flex">
                  {active && (
                    <SortButtonDropdown
                      options={sortFilterOption}
                      openFilter={openSortFilter}
                      btnToggleStyle="ml-3 btn-sm p-2 rounded-circle"
                      setOpenFilter={setOpenSortFilter}
                      filterOptionSelected={filterOptionSelected}
                      filterSelected={filterSortSelected}
                      handleFilterSelect={handleSelectedFilter}
                      setFilterOptionSelected={setFilterOptionSelected}
                      setFilterSelected={setFilterSortSelected}
                      defaultSelection={defaultFilters}
                      showRemoveSort
                      showSortIcon
                    />
                  )}
                  <Nav
                    active={active}
                    onClick={changeView}
                    togglePipelineEdit={editPipeline}
                  />
                </div>
              </DataFilters>
            </div>
            {isPermissionAllowed('deals', 'create') && (
              <>
                {moduleMap.deal && (
                  <ButtonIcon
                    icon="add"
                    classnames="btn-sm border-0"
                    loading={addDealBtnLoading}
                    label={DEALS_LABEL_BUTTON.replace(
                      /Pipeline/g,
                      moduleMap.deal.singular
                    )}
                    onClick={() => handleAddDeal(undefined)}
                  />
                )}
              </>
            )}
            {openDeal && (
              <AddDeal
                className="btn-transparent border-0"
                setOpenDeal={setOpenDeal}
                openDeal={openDeal}
                initialDeals={initialDeals}
                pipeline={selectedPipeline}
                onGetDeals={refreshBoard}
                setErrorMessage={setErrorMessage}
                setSuccessMessage={setSuccessMessage}
                selectedStage={selectedStage}
              />
            )}
          </div>
          <div className={`tab-content ${active ? 'pt-2' : ''}`}>
            <div
              className={`tab-pane fade col-md-12 p-0 py-0 ${
                active && 'active show'
              }`}
            >
              {showLoading ? (
                <BoardLoader count={5} />
              ) : (
                <Board
                  onGetDeals={(type, id, stagePosition, page, dropped) => {
                    setListDeals((prev) => ({
                      ...prev,
                      [type]: {
                        stageId: id,
                        stagePosition,
                        loading: true,
                        items: [],
                        pagination: page,
                        header: [],
                      },
                    }));
                    getDeals({ name: type, id, stagePosition }, page, dropped);
                  }}
                  setNotification={setNotification}
                  listDeals={listDeals}
                  onClick={refreshDeals}
                  selectedPipeline={selectedPipeline}
                  editPipeline={pipelineEdit}
                  refreshBoard={refreshBoard}
                  refreshBoardHeader={handleRefreshBoardHeader}
                  onAddDeal={handleAddDeal}
                  viewType={active ? 'column' : 'list'}
                  DealStats={DealStats}
                />
              )}
            </div>
            <div
              className={`tab-pane fade col-12 p-0 ${!active && 'active show'}`}
            >
              <DealList
                allDeals={allDeals}
                pagination={pagination}
                dataInDB={dataInDB}
                viewType={active ? 'column' : 'list'}
                service={dealService}
                selectedPipeline={selectedPipeline}
                showLoading={showLoading}
                tableLoading={tableLoading}
                onPageChange={(newPage) =>
                  changePaginationPage(newPage, setPaginationData)
                }
                onLimitChange={(perPage) =>
                  changePaginationLimit(perPage, setPaginationData)
                }
                onAddDeal={onAddDeal}
                sortingTable={sortTable}
                sortingOrder={order}
                DealStats={DealStats}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Deals;
