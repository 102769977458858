import React, { useContext, useEffect } from 'react';
import { TenantContext } from '../../contexts/TenantContext';
import {
  isMatchInCommaSeparated,
  isPermissionAllowed,
} from '../../utils/Utils';
import { useProfileContext } from '../../contexts/profileContext';
import { useModuleContext } from '../../contexts/moduleContext';
import { useNewPermissionContext } from '../../contexts/newPermissionContext';
import { Link, useLocation } from 'react-router-dom';
import MaterialIcon from '../../components/commons/MaterialIcon';
const settingsValues = [
  {
    id: 5,
    title: 'Users and Controls',
    icon: 'group_add',
    label: 'users_and_controls',
    path: '/users',
    item: {
      action: 'view',
      collection: 'users',
    },
    requiredAdminAccess: true,
    requiredOwnerAccess: true,
  },
  {
    id: 8,
    title: 'Organization',
    icon: 'corporate_fare',
    label: 'branding',
    path: '/branding',
    requiredAdminAccess: true,
    requiredOwnerAccess: true,
  },
  {
    id: 4,
    title: 'Notifications',
    icon: 'notifications',
    label: 'notifications',
    path: '/notifications',
    requiredAdminAccess: false,
    requiredOwnerAccess: true,
    requiredAppAccess: true,
  },
  {
    id: 9,
    title: 'Products',
    icon: 'app_registration',
    label: 'products',
    path: '/products',
    requiredAdminAccess: false,
    requiredOwnerAccess: true,
  },
  {
    id: 7,
    title: 'Learn',
    icon: 'school',
    path: '/learn',
    label: 'learn',
    requiredAdminAccess: false,
    requiredOwnerAccess: true,
  },
  {
    id: 12,
    title: 'Stages',
    icon: 'view_carousel',
    path: '/stages',
    label: 'pipeline_and_stages',
    item: {
      action: 'view',
      collection: 'pipelines',
    },
    requiredAdminAccess: false,
    requiredOwnerAccess: true,
  },
  {
    id: 13,
    title: 'Fields',
    icon: 'edit_note',
    path: '/fields',
    label: 'fields',
    item: {
      action: 'view',
      collection: 'fields',
    },
    requiredAdminAccess: false,
    requiredOwnerAccess: true,
  },
  {
    id: 14,
    title: 'Checklists',
    icon: 'checklist',
    path: '/checklist-fields',
    label: 'checklist',
    item: {
      action: 'view',
      collection: 'checklists',
    },
    requiredAdminAccess: false,
    requiredOwnerAccess: true,
  },
  {
    id: 11,
    title: 'Integrations',
    icon: 'view_comfy_alt',
    path: '/integrations',
    label: 'integrations',
    requiredAdminAccess: false,
    requiredOwnerAccess: true,
  },
  {
    id: 10,
    title: 'Bulk Import',
    icon: 'download',
    path: '/bulk-import',
    label: 'data_mapper',
    item: {
      action: 'view',
      collection: 'contacts',
      tenantName: 'contacts',
    },
    requiredAdminAccess: false,
    requiredOwnerAccess: true,
    requiredAppAccess: true,
  },
];
function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}
const Settings = ({ children }) => {
  const { moduleMap } = useModuleContext();
  const { permissionChanges } = useNewPermissionContext();
  useEffect(() => {}, [moduleMap.deal]);
  const { tenant } = useContext(TenantContext);
  const settingFiltered = settingsValues.filter((setting) => {
    const settingsInput = 'settings_' + setting.label;
    const isUserLevel = permissionChanges[0]?.level === 'user';
    const isDataMapperSetting = setting?.label === 'data_mapper';
    return (
      !(isUserLevel && isDataMapperSetting) &&
      (!tenant.modules ||
        tenant.modules === '*' ||
        isMatchInCommaSeparated(tenant.modules, settingsInput))
    );
  });
  const location = useLocation();
  const isActive = (path) => {
    return location.pathname === `/settings${path}`;
  };
  const { profileInfo } = useProfileContext();
  return (
    <>
      <div className="setting-sidebar">
        <h3 className="pt-3 pb-2 px-3_1">Settings</h3>
        {moduleMap.deal && (
          <ul className="list-unstyled mb-0 transparent-scroll-track">
            {settingFiltered?.map((item) => {
              const isAdmin = profileInfo?.role?.admin_access;
              const isOwner = profileInfo?.role?.owner_access;
              const isAppAccess = profileInfo?.role?.app_access;

              const hasRequiredAdminAccess = item.requiredAdminAccess === true;
              const hasRequiredOwnerAccess = item.requiredOwnerAccess === true;
              const hasRequiredAppAccess = item.requiredAppAccess === true;
              // Move the definition to the top
              const isPermissionAllow = () =>
                item?.item &&
                isPermissionAllowed(item?.item.collection, item?.item.action);
              const shouldRender =
                (isAdmin && hasRequiredAdminAccess) ||
                (isOwner && hasRequiredOwnerAccess && !isAdmin) ||
                (!isAdmin && !isOwner && isAppAccess && isPermissionAllow()) ||
                hasRequiredAppAccess;
              return shouldRender ? (
                <li key={item.id}>
                  <Link
                    to={`/settings${item.path}`}
                    className={classNames(
                      'd-flex align-items-center gap-2 menu-hover-gray primary',
                      isActive(item.path) &&
                        'active fw-semibold bg-primary-soft'
                    )}
                  >
                    <MaterialIcon
                      icon={item.icon}
                      rounded
                      size="fs-3"
                      filled={isActive(item.path)}
                      clazz="text-black font-weight-normal"
                    />
                    <h5
                      className={`mb-0 fs-7 ${
                        isActive(item.path)
                          ? 'font-weight-medium'
                          : 'font-weight-normal'
                      }`}
                    >
                      {item.title}
                    </h5>
                  </Link>
                </li>
              ) : null;
            })}
          </ul>
        )}
      </div>
      <div className="container-fluid p-0 bg-white overflow-x-hidden settings-content">
        {children}
      </div>
    </>
  );
};

export default Settings;
