import React, { createContext, useContext, useState } from 'react';

const IdaContext = createContext({
  selectedChat: {},
  setSelectedChat: () => {},
  refreshHistory: 0,
  setRefreshHistory: () => {},
  newQuestion: {},
  setNewQuestion: () => {},
  prompt: '',
  setPrompt: () => {},
  mask: '',
  setMask: () => {},
  selectedModalChat: {},
  setSelectedModalChat: () => {},
  trigger: '',
  setTrigger: () => {},
  historyNewChat: '',
  setHistoryNewChat: () => {},
  knowledgeSource: '',
  setKnowledgeSource: () => {},
});
export const IdaContextProvider = (props) => {
  const [selectedChat, setSelectedChat] = useState({});
  const [selectedModalChat, setSelectedModalChat] = useState({});
  const [refreshHistory, setRefreshHistory] = useState(0);
  const [newQuestion, setNewQuestion] = useState({});
  const [prompt, setPrompt] = useState('');
  const [mask, setMask] = useState('');
  const [trigger, setTrigger] = useState('');
  const [historyNewChat, setHistoryNewChat] = useState({});
  const [knowledgeSource, setKnowledgeSource] = useState({});

  return (
    <IdaContext.Provider
      value={{
        selectedChat,
        setSelectedChat,
        refreshHistory,
        setRefreshHistory,
        newQuestion,
        setNewQuestion,
        prompt,
        setPrompt,
        mask,
        setMask,
        selectedModalChat,
        setSelectedModalChat,
        trigger,
        setTrigger,
        historyNewChat,
        setHistoryNewChat,
        knowledgeSource,
        setKnowledgeSource,
      }}
    >
      {props.children}
    </IdaContext.Provider>
  );
};

export const useIdaContext = () => {
  return useContext(IdaContext);
};
