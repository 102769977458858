import React from 'react';
import Avatar from '../Avatar';
import TextOverflowTooltip from './TextOverflowTooltip';
import Skeleton from 'react-loading-skeleton';

const RecentlyViewedList = ({
  list,
  onClick,
  loading,
  type,
  showAvatar = true,
  avatarSize = 'avatar-light',
  emptyTitle = 'No data.',
  onMoreClick,
}) => {
  return (
    <>
      <div className="bg-white rounded">
        {loading ? (
          <div className="pt-2 px-3">
            <Skeleton count={5} height="5" className="mb-3" />
          </div>
        ) : list?.data?.length > 0 ? (
          <>
            {list?.data?.map((item) => (
              <div
                key={item.id}
                onClick={() => onClick && onClick(item)}
                className="item-filter nav-link cursor-pointer px-3 py-2"
              >
                <div className="position-relative d-flex align-items-center gap-2">
                  {showAvatar && (
                    <Avatar
                      classModifiers="bg-primary-soft text-primary"
                      sizeIcon={avatarSize}
                      defaultSize="xs"
                      user={item}
                      type={type}
                    />
                  )}
                  <TextOverflowTooltip
                    maxLength={showAvatar ? 25 : 30}
                    text={item.name || item.title}
                    textStyle="mb-0 font-weight-medium font-size-sm2 text-clamp-single-line"
                  />
                </div>
              </div>
            ))}
            {onMoreClick && list?.pagination?.count > 5 && (
              <div className="m-2 mx-3">
                <div
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    onMoreClick();
                  }}
                  className="py-2 px-3 nav-link border bg-hover-gray transition cursor-pointer rounded-pill d-flex"
                >
                  <span className="fs-7 font-weight-medium text-center w-100">
                    View more...
                  </span>
                </div>
              </div>
            )}
          </>
        ) : (
          <p className="mb-0 text-center p-3 text-gray-search">{emptyTitle}</p>
        )}
      </div>
    </>
  );
};

export default RecentlyViewedList;
