import { useState, useEffect } from 'react';
import teamsService from '../../services/teams.service';
import { useDebounce } from '../useDebounce';

const useFetchTeams = ({ isActive = true, callback }) => {
  const [teams, setTeams] = useState();
  const [paginationTeams, setPaginationTeams] = useState({
    page: 1,
    limit: 100,
  });
  const [searchQueryTeam, setSearchQueryTeam] = useState();
  const [queryFilterTeams, setQueryFilterTeams] = useState({
    filters: [],
  });

  const debouncedSearch = useDebounce(searchQueryTeam, 500);

  const getTeams = async () => {
    const { data } = await teamsService.getTeams({
      page: paginationTeams.page,
      limit: paginationTeams.limit,
      filter: { search: debouncedSearch, isActive },
    });

    setTeams(data);
  };

  useEffect(() => {
    getTeams();
  }, [debouncedSearch, paginationTeams.page, isActive]);

  const handleAllSelect = () => {
    const allSelected = teams?.map((rpt) => rpt?.id);

    setQueryFilterTeams({
      filters: [...allSelected],
    });
    callback?.();
  };

  const handleOnDoneTeams = (items) => {
    setQueryFilterTeams({
      filters: [...items],
    });
  };

  return {
    teams,
    searchQueryTeam,
    setSearchQueryTeam,
    paginationTeams,
    setPaginationTeams,
    handleAllSelect,
    queryFilterTeams,
    setQueryFilterTeams,
    handleOnDoneTeams,
  };
};

export default useFetchTeams;
