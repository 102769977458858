import React, { useEffect, useState } from 'react';
import {
  AccountTypes,
  cleanAccountName,
  getOperatingAccountType,
} from './account.structure.constants';
import { Tree, TreeNode } from 'react-organizational-chart';
import AlertWrapper from '../../Alert/AlertWrapper';
import Alert from '../../Alert/Alert';
import MaterialIcon from '../../commons/MaterialIcon';

const AccountLabel = ({ account }) => {
  const operatingAccountType = getOperatingAccountType(account);
  const color = operatingAccountType.color;
  return (
    <div
      style={{ maxWidth: 180 }}
      className={`d-flex mx-auto position-relative hover-actions p-2_1 rounded-lg flex-column ${color}`}
    >
      <h6 className={`fs-8 mb-0 ${operatingAccountType.foreColor}`}>
        {cleanAccountName(account)}
      </h6>
    </div>
  );
};

const EmptyAccountLabel = () => {
  return (
    <div
      style={{ maxWidth: 180 }}
      className={`d-flex mx-auto border-dashed-gray position-relative hover-actions p-2_1 rounded-lg flex-column bg-gray-300 rounded-lg`}
    >
      <h6 className={`fs-8 mb-0 text-black-50`}>No Operating Account</h6>
    </div>
  );
};

const transformTreeNode = (s) => ({
  id: s.id,
  label: <AccountLabel account={s} />,
});

const FlowChart = ({ accounts }) => {
  const [localAccounts] = useState([...accounts]);
  const [operatingAccount, setOperatingAccount] = useState(null);
  const [standaloneAccounts, setStandaloneAccounts] = useState([]);
  const [naAccounts, setNaAccounts] = useState([]);
  const [errorMessage, setErrorMessage] = useState('');
  const [infoMessage, setInfoMessage] = useState('');

  useEffect(() => {
    if (accounts) {
      const accountsWithType = localAccounts.map((acnt) => ({
        ...acnt,
        operatingAccountType: getOperatingAccountType(acnt),
      }));

      const subAccounts = accountsWithType.filter(
        (f) => f.operatingAccountType.key === AccountTypes.SubAccount.key
      );
      const standalone = accountsWithType.filter(
        (f) => f.operatingAccountType.key === AccountTypes.Standalone.key
      );
      const nonAnalyzed = accountsWithType.filter(
        (f) => f.operatingAccountType.key === AccountTypes.NonAnalyzed.key
      );
      setStandaloneAccounts(standalone.map(transformTreeNode));
      setNaAccounts(nonAnalyzed.map(transformTreeNode));
      const account = accountsWithType.find(
        (act) => act.operatingAccountType?.key === AccountTypes.Operating.key
      );

      if (account) {
        setOperatingAccount({
          id: account.id,
          label: <AccountLabel account={account} />,
          children: subAccounts.map(transformTreeNode),
        });
      }
    }
  }, [localAccounts]);

  const renderTreeNodes = (node) => {
    return (
      <>
        {node?.children?.map((childNode) => (
          <TreeNode key={childNode.id} label={childNode.label} />
        ))}
      </>
    );
  };

  const linkColorHex = '#ccc';
  const nodeWidth = 180;

  return (
    <div className="position-relative">
      <AlertWrapper className="alert-position">
        <Alert
          color="danger"
          message={errorMessage}
          setMessage={setErrorMessage}
        />
        <Alert color="info" message={infoMessage} setMessage={setInfoMessage} />
      </AlertWrapper>
      <div className="flowchart-container">
        <div className="tree-wrapper overflow-x-auto">
          {operatingAccount ? (
            <Tree
              lineColor={linkColorHex}
              label={operatingAccount?.label || ''}
            >
              {renderTreeNodes(operatingAccount)}
            </Tree>
          ) : (
            <Tree lineColor={linkColorHex} label={<EmptyAccountLabel />}>
              <TreeNode
                label={
                  <a
                    onClick={(e) => {
                      e.preventDefault();
                      setInfoMessage(
                        'Please add operating account in reports Details tab.'
                      );
                    }}
                    className="rounded-circle cursor-pointer fs-8 border font-weight-semi-bold text-black-50 justify-content-center p-1 d-inline-flex align-items-center"
                  >
                    <MaterialIcon icon="info" />
                  </a>
                }
              />
            </Tree>
          )}
        </div>
        <br />
        <div className="d-flex justify-content-center gap-4 w-100 align-items-center">
          {standaloneAccounts.map((node) => (
            <div
              key={node.id}
              className="tree-wrapper"
              style={{ width: nodeWidth }}
            >
              <Tree key={node.id} label={node.label} />
            </div>
          ))}
        </div>
        <br />
        <div className="d-flex justify-content-center gap-4 w-100 align-items-center">
          {naAccounts.map((node) => (
            <div
              key={node.id}
              className="tree-wrapper"
              style={{ width: nodeWidth }}
            >
              <Tree key={node.id} label={node.label} />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default FlowChart;
