/* eslint-disable no-unused-vars */
import React, { useEffect, useState, useLayoutEffect } from 'react';
import { Card, CardBody, CardFooter, CardHeader } from 'reactstrap';

import { getTomorrowDate, FormattedValue } from '../../../../utils/Utils';
import GenericTable from '../../../../components/GenericTable';
import { ReportSkeletonLoader } from '../../ReportSkeletonLoader';
import DatePickerTraining from '../../../../components/dealsBreakdown/DatePickerTraining';
import ReportSearchOptionDropdown from '../../../../components/commons/ReportSearchOptionDropdown';
import OpportunitiesStats from './OpportunitiesStats';
import { productSalesSnapshotColumns } from '../../../../utils/opportunities.constants';
import pipelineService from '../../../../services/pipeline.services';
import productService from '../../../../services/product.service';
import userService from '../../../../services/user.service';
import { paginationDefault } from '../../../../utils/constants';
import Pagination from '../../../../components/Pagination';
import {
  changePaginationLimit,
  changePaginationPage,
} from '../../../Deals/contacts/utils';
import IdfTooltip from '../../../../components/idfComponents/idfTooltip';
import { StatItemColors } from '../../../../components/reports/reports.constants';
import TableFooterStats from '../../../../components/TableFooterStats';
import useIsTenant from '../../../../hooks/useIsTenant';

const StatsData = [
  {
    id: 'TotalSales',
    icon: 'DollarSign',
    color: StatItemColors.financial,
    iconColor: 'text-rpt-green',
    label: 'Total Sales',
    value: '424000',
    count: 424000,
    rounded: true,
    type: 'currency',
  },
  {
    id: 'TotalUnitsSold',
    icon: 'ShoppingCart',
    color: StatItemColors.volume,
    iconColor: 'text-rpt-blue',
    label: 'Units Sold',
    value: '7950',
    count: 7950,
    rounded: true,
    type: 'number',
  },
  {
    id: 'AverageTotalPrice',
    icon: 'ChartNoAxesColumnIncreasing',
    color: StatItemColors.performance,
    iconColor: 'rpt-orange-box-heading',
    label: 'Avg. Total Sales',
    value: '530',
    count: 530,
    type: 'currency',
  },
  {
    id: 'AverageSalesPrice',
    icon: 'Tag',
    color: StatItemColors.achievement,
    iconColor: 'text-rpt-gray',
    label: 'Avg. Sale Price',
    value: '61.69',
    count: 61.69,
    type: 'currency',
  },
];

const StatsDataFooter = [
  {
    id: 'TotalSales',
    icon: 'DollarSign',
    color: StatItemColors.financial,
    iconColor: 'text-rpt-green',
    label: 'Total Sales',
    value: '424000',
    count: 424000,
    rounded: true,
    type: 'currency',
  },
  {
    id: 'TotalUnitsSold',
    icon: 'ShoppingCart',
    color: StatItemColors.volume,
    iconColor: 'text-rpt-blue',
    label: 'Units Sold',
    value: '7950',
    count: 7950,
    rounded: true,
    type: 'number',
  },
  {
    id: 'AverageSalesPrice',
    icon: 'Tag',
    color: StatItemColors.achievement,
    iconColor: 'text-rpt-gray',
    label: 'Average Sale Price',
    value: '61.69',
    count: 61.69,
    type: 'currency',
  },
];

const ProductSalesSnapshot = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [order, setOrder] = useState();
  const [pagination, setPagination] = useState(paginationDefault);
  const [paginationPage, setPaginationPage] = useState(paginationDefault);

  const [show, setShow] = useState(false);
  const [pipelines, setPipelines] = useState([]);
  const [pipelineIds, setPipelinesIds] = useState([]);
  const [selectedPipelinesKeys, setSelectedPipelinesKeys] = useState([]);

  const [showUsers, setShowUsers] = useState(false);
  const [usersList, setUsersList] = useState([]);
  const [usersIds, setUserssIds] = useState([]);
  const [selectedUsersKeys, setSelectedUsersKeys] = useState([]);

  const [dateRange, setDateRange] = useState({
    startDate: new Date(1970, 0, 1),
    endDate: getTomorrowDate(),
  });

  const { isDemo } = useIsTenant();

  const mapMonthlySalesPerformance = [
    ...data?.map((item) => ({
      ...item,
      dataRow: [
        {
          key: 'name',
          component: <span>{item?.name}</span>,
        },
        {
          key: 'totalAmount',
          component: (
            <FormattedValue value={item?.totalAmount} type="currency" />
          ),
        },
        {
          key: 'totalQuantity',
          component: <FormattedValue value={item?.totalQuantity} />,
        },
        {
          key: 'averageAmount',
          component: (
            <FormattedValue value={item?.averageAmount} type="currency" />
          ),
        },
      ],
    })),
  ];

  const handleSort = (key) => {
    setOrder([[key?.name?.orderBy, key?.name?.clicked.toLowerCase()]]);
  };

  const getPipelines = async () => {
    const response = await pipelineService.getPipelines(1, 100);
    setPipelines(response?.data);
  };

  const getUsers = async (limit = 100) => {
    const response = await userService.getUsers(
      { status: 'active' },
      { page: 1, limit }
    );

    setUsersList(response?.data?.users);
  };

  const getProductSalesData = async () => {
    setLoading(true);
    const response = await productService.getProductSalesSnapshot({
      dateRange: {
        start: dateRange?.startDate,
        end: dateRange?.endDate,
      },
      pagination,
    });
    setData(response?.data);
    setLoading(false);
  };

  useEffect(() => {
    getProductSalesData();
  }, [dateRange.startDate, dateRange.endDate]);

  useEffect(() => {
    getPipelines();
    getUsers();
  }, []);

  return (
    <Card className="h-100 border-0 shadow-0">
      <CardHeader>
        <div className="d-flex justify-content-between align-items-center w-100">
          <IdfTooltip text="Product Sales Snapshot" placement="bottom">
            <h4 className="card-title text-hover-primary mb-0 text-truncate">
              Product Sales Snapshot
            </h4>
          </IdfTooltip>
          <div className="d-flex align-items-center mr-6 pr-9 gap-2">
            <DatePickerTraining
              range={dateRange}
              setRange={setDateRange}
              disablePastDate
              btnToggleStyle="mx-0 w-250"
            />

            {isDemo && (
              <>
                <ReportSearchOptionDropdown
                  search
                  maxLength={22}
                  show={show}
                  data={pipelines}
                  setShow={setShow}
                  showCheckAll={false}
                  label={`Pipelines`}
                  setQueryFilters={() => {}}
                  scrollable="pr-3 text-left"
                  handleOptionSelected={() => {}}
                  customStyle={{ width: '250px' }}
                  customKey={['name', 'id']}
                  selectedData={selectedPipelinesKeys}
                  handleAllSelect={() => setPipelinesIds('')}
                  setSelectedData={setSelectedPipelinesKeys}
                  handleOnDone={(items) => setPipelinesIds(items)}
                  controlledTitle={
                    pipelines
                      ?.filter((i) => pipelineIds?.includes(i.id))
                      .map((i) => i.name)
                      .join(',') || ''
                  }
                />

                <ReportSearchOptionDropdown
                  search
                  maxLength={22}
                  show={showUsers}
                  data={usersList}
                  setShow={setShowUsers}
                  showCheckAll={false}
                  label={`Users`}
                  setQueryFilters={() => {}}
                  scrollable="pr-3 text-left"
                  handleOptionSelected={() => {}}
                  customStyle={{ width: '220px' }}
                  customKey={['name', 'id']}
                  selectedData={selectedUsersKeys}
                  handleAllSelect={() => setUserssIds('')}
                  setSelectedData={setSelectedUsersKeys}
                  handleOnDone={(items) => setUserssIds(items)}
                  controlledTitle={
                    usersList
                      ?.filter((i) => usersIds?.includes(i.id))
                      .map((i) => i.name)
                      .join(',') || ''
                  }
                />
              </>
            )}
          </div>
        </div>
      </CardHeader>
      <CardBody className="p-0 flex-1 overflow-y-auto">
        <div className="px-2 pt-3">
          {isDemo && (
            <OpportunitiesStats stats={StatsData} isLoading={loading} />
          )}
        </div>
        {loading ? (
          <div className="w-100 d-block p-1">
            <ReportSkeletonLoader rows={15} height={15} />
          </div>
        ) : (
          <GenericTable
            checkbox={false}
            exportToCSV={true}
            usePagination={false}
            sortingTable={handleSort}
            tableData={data}
            headClass="bg-gray-table-head border-top"
            fileName="Product Sales Snapshot"
            noDataInDbValidation={true}
            columns={productSalesSnapshotColumns}
            columnsToExport={productSalesSnapshotColumns}
            data={mapMonthlySalesPerformance}
          />
        )}
      </CardBody>
      <CardFooter className="border-top bg-gray-table-head p-2 border-bottom-0 border-left-0 border-right-0 rounded-0">
        <div className="d-flex align-items-center justify-content-between">
          <ul className="px-3 mb-0 flex-fill">
            {isDemo && <TableFooterStats stats={StatsDataFooter} />}
          </ul>
          <Pagination
            showPerPage
            paginationInfo={pagination}
            onPageChange={(newPage) => {
              changePaginationPage(newPage, setPaginationPage);
            }}
            onLimitChange={(perPage) => {
              changePaginationLimit(perPage, setPaginationPage);
            }}
          />
        </div>
      </CardFooter>
    </Card>
  );
};

export default ProductSalesSnapshot;
