import axios from './axiosInstance';
import authHeader from './auth-header';

class MapService {
  async getGoogleAddress(address) {
    const response = await axios.get(
      `/providers/google/maps/place/autocomplete`,
      {
        params: {
          input: address,
        },
        headers: authHeader(),
      }
    );
    const locations = response.data.predictions.map((prediction) => ({
      ...prediction,
      name: prediction.description,
    }));

    return locations;
  }
}

export default new MapService();
