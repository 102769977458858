import routes from '../../../utils/routes.json';
import { PermissionsConstants } from '../../../utils/permissions.constants';

export const sidebarData = [
  {
    id: '1',
    title: 'Home',
    icon: 'dashboard',
    path: '/',
    permissions: { collection: 'dashboard', action: 'view' },
    adminAccess: false,
    ownerAccess: true,
  },
  {
    id: '7',
    title: 'Ida',
    path: routes.chat,
    adminAccess: false,
    ownerAccess: true,
    icon: 'auto_awesome',
    byModule: PermissionsConstants.Ida.Ida, // as this module doesnt need view/create actions so checking by tenant modules
  },
  {
    id: '2',
    title: 'Accounts',
    path: routes.caccounts,
    icon: 'corporate_fare',
    adminAccess: false,
    ownerAccess: true,
    byModule: [
      PermissionsConstants.CAccounts.Companies,
      PermissionsConstants.CAccounts.Contacts,
    ],
  },
  {
    id: '3',
    title: 'Pipeline',
    icon: 'monetization_on',
    path: routes.pipeline2,
    adminAccess: false,
    ownerAccess: true,
    permissions: { collection: 'deals', action: 'view' },
  },
  {
    id: '4',
    title: 'Activities',
    path: routes.Activities,
    icon: 'event_available',
    permissions: { collection: 'activities', action: 'view' },
    adminAccess: false,
    ownerAccess: true,
  },
  {
    id: '4',
    title: 'Checklists',
    path: routes.Checklist,
    icon: 'checklist',
    adminAccess: false,
    permissions: { collection: 'checklists', action: 'view' },
    ownerAccess: false,
    byModule: PermissionsConstants.Checklist.checklist,
  },
  {
    id: '5',
    title: 'Prospecting',
    path: routes.prospecting,
    adminAccess: false,
    ownerAccess: true,
    icon: 'person_search',
    permissions: { collection: 'prospects', action: 'view' },
    byModule: [
      PermissionsConstants.Resources.ProspectingCompany,
      PermissionsConstants.Resources.ProspectingPeople,
    ],
  },
  {
    id: '6',
    title: 'Reporting',
    path: routes.reporting,
    icon: 'analytics',
    permissions: { collection: 'reports', action: 'view' },
    adminAccess: false,
    ownerAccess: true,
  },
  {
    id: '8',
    title: 'Tenants',
    path: routes.tenant,
    adminAccess: true,
    ownerAccess: false,
    icon: 'corporate_fare',
  },
  {
    id: '9',
    title: 'Learn',
    icon: 'school',
    adminAccess: false,
    byModule: Object.values(PermissionsConstants.Learn),
    ownerAccess: true,
    submenu: false,
    path: routes.learnMain,
    items: [
      {
        id: '9.1',
        title: 'My Learning',
        adminAccess: false,
        ownerAccess: true,
        path: routes.favorites,
      },
      {
        id: '9.2',
        title: 'Self-Assessment',
        adminAccess: false,
        ownerAccess: true,
        path: routes.selfAssessment,
      },
      {
        id: '9.3',
        title: 'Explore',
        submenu: true,
        adminAccess: false,
        ownerAccess: true,
        items: [],
      },
      {
        id: '9.4',
        title: 'Custom',
        submenu: true,
        adminAccess: false,
        ownerAccess: true,
        items: [],
      },
    ],
  },
  {
    id: '10',
    title: 'Learn',
    path: '/training',
    adminAccess: true,
    ownerAccess: false,
    icon: 'school',
  },
  {
    id: '11',
    title: 'Ida',
    path: routes.ida,
    adminAccess: true,
    ownerAccess: false,
    icon: 'auto_awesome',
  },
];
