const getImageUrl = (urlString) => {
  try {
    const url = new URL(urlString);
    return url.origin + url.pathname;
  } catch (e) {
    return urlString;
  }
};
const useReportHeaderFooter = () => {
  const tenantLogo = getImageUrl(
    `${document.querySelector('.tenant-logo')?.src}?_v=${Date.now()}`
  );
  return { reportFooterImage: tenantLogo, reportHeaderWhiteLogo: tenantLogo };
};

export default useReportHeaderFooter;
