import React, { useState } from 'react';
import Home from '../components/chat/Home';
import SideBar from '../components/chat/SideBar';
import useHash from '../hooks/useHash';
import {
  MenuItemsKeys,
  MenuItemsKeysAndIcons,
} from '../components/chat/constants';
import TopHistory from '../components/chat/history/TopHistory';
import History from '../components/chat/History';
import KnowledgeDetails from '../components/chat/knowledge/Details';
import ChatThread from '../components/chat/chats/Thread';

import { Accordion } from 'react-bootstrap';
import { useLocation } from 'react-router-dom';
import Knowledge from '../components/chat/Knowledge';
import LucideIcon from '../components/commons/LucideIcon';
import { isModuleAllowed } from '../utils/Utils';
import { useTenantContext } from '../contexts/TenantContext';
import { PermissionsConstants } from '../utils/permissions.constants';

const Menu = ({ menuItems }) => {
  const { updateHash } = useHash();
  const [active, setActive] = useState(MenuItemsKeys.History);

  const handleToggle = (e, item) => {
    e.stopPropagation();
    if (active === item.id) {
      setActive(null);
    } else {
      setActive(item.id);
    }
  };

  const handleClick = (item) => {
    updateHash(item.path);
    setActive(item.id);
  };

  return (
    <ul className="mt-2 mb-0 px-3 list-unstyled d-flex flex-column transparent-scroll-track">
      {menuItems?.slice(2).map((item) => (
        <li
          key={item.id}
          className="reporting-filter-wrapper accordion-wrapper"
        >
          <Accordion activeKey={active}>
            <Accordion.Toggle
              onClick={() => handleClick(item)}
              eventKey={item.id}
              className={`${
                active === item.id
                  ? 'bg-accordion-active rounded-top rounded-bottom-0'
                  : 'bg-white'
              } reporting-filter-toggle accordion-toggle nav-link px-3 py-2 border-bottom cursor-pointer font-size-sm2 font-weight-medium text-black text-capitalize`}
            >
              <div className="d-flex align-items-center">
                <LucideIcon icon={item.icon} size={17} />
                <span className="ml-2 text-capitalize text-truncate py-0">
                  {item.title}
                </span>

                <span
                  className="ml-auto"
                  onClick={(e) => handleToggle(e, item)}
                >
                  <LucideIcon
                    icon={active === item.id ? 'ChevronUp' : 'ChevronDown'}
                  />
                </span>
              </div>
            </Accordion.Toggle>
            <Accordion.Collapse
              eventKey={item.id}
              className="prospect-wrapper-content"
            >
              <div className="py-0 overflow-x-hidden">
                {item.id === MenuItemsKeys.History && <TopHistory />}
              </div>
            </Accordion.Collapse>
          </Accordion>
        </li>
      ))}
    </ul>
  );
};

const getDetailComponentByKey = (module, id) => {
  switch (module) {
    case MenuItemsKeysAndIcons.History.title.toLowerCase():
    case MenuItemsKeysAndIcons.Chat.pathChats.toLowerCase():
      return <ChatThread id={id} />;
    case MenuItemsKeysAndIcons.Knowledge.title.toLowerCase():
      return <KnowledgeDetails id={id} />;
    default:
      return <div />;
  }
};

const Content = ({ menuItems }) => {
  const location = useLocation();
  const { hash } = useHash();
  if (!hash || !location.hash) {
    return <Home />;
  }

  const menuItem = menuItems?.find((menu) => hash === menu.path.slice(1));

  // means child pages
  if (hash.includes('/')) {
    // split the hash in order to pick relevant module and open it detail page
    const [module, id] = hash.split('/');
    return getDetailComponentByKey(module, id);
  }

  if (!menuItem) {
    return <Home />;
  }

  return menuItem.component || <Home />;
};

const Chat = () => {
  const { tenant } = useTenantContext();

  const isIdaKnowledgeAllowed = isModuleAllowed(
    tenant?.modules,
    PermissionsConstants.Ida.Knowledge
  );

  const MenuItems = [
    {
      ...MenuItemsKeysAndIcons.Chat,
      component: null,
    },
    {
      ...MenuItemsKeysAndIcons.Knowledge,
      component: <Knowledge />,
    },
    {
      ...MenuItemsKeysAndIcons.History,
      component: <History />,
    },
  ];

  const updatedMenuItems = MenuItems?.filter(
    (item) => item.title !== 'Knowledge' || isIdaKnowledgeAllowed
  );

  return (
    <div className="custom-layout chat-layout">
      <div className="custom-layout-sidebar chat-sidebar overflow-x-hidden">
        <SideBar isIdaKnowledgeAllowed={isIdaKnowledgeAllowed}>
          <Menu menuItems={MenuItems} />
        </SideBar>
      </div>
      <div className="custom-layout-content overflow-hidden bg-white">
        <Content menuItems={updatedMenuItems} />
      </div>
    </div>
  );
};

export default Chat;
