import React, { useState } from 'react';
import { Spinner } from 'reactstrap';
import { useHistory, useLocation } from 'react-router-dom';
import { PublicLayout } from '../layouts/PublicLayout';
import AuthService from '../services/auth.service';
import PageTitle from '../components/commons/PageTitle';
import AlertWrapper from '../components/Alert/AlertWrapper';
import Alert from '../components/Alert/Alert';
const msgTimeout = 4000;

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

export default function Reset() {
  const query = useQuery();
  const token = query.get('token');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const history = useHistory();
  const [success, setSuccess] = useState('');
  const [error, setError] = useState('');

  if (!token || token === '') {
    history.push('/login');
  }

  async function handleSubmit(event) {
    event.preventDefault();

    if (password !== confirmPassword) {
      setError('Password must match');
      setTimeout(() => {
        setError('');
      }, msgTimeout);
      return;
    }

    // validating password
    // show password strength meter
    // if password is weak, don't reset password

    try {
      setIsLoading(true);
      await AuthService.resetPassword(password, token);
      setSuccess('You have successfully updated your password.');
      setPassword('');
      setConfirmPassword('');
      history.push('/login?from=RESET');
    } catch (e) {
      setError('There was an error while updating your password.');
    } finally {
      setIsLoading(false);
    }
  }

  return (
    <PublicLayout title="Set New Password">
      <PageTitle page={'Reset Password'} pageModule="" />
      <div className="row justify-content-center">
        <div className="col-md-7 col-lg-5">
          <div className="card mb-5">
            <div className="card-body">
              <form className="js-validate" onSubmit={handleSubmit}>
                <div className="text-left">
                  <div className="mb-2">
                    <p>Enter new password in the fields below.</p>
                  </div>
                </div>

                <AlertWrapper>
                  <Alert message={error} setMessage={setError} color="danger">
                    <ul style={{ listStyle: 'none' }}>
                      <li>Minimum of 8 characters</li>
                      <li>At least 1 lower case character</li>
                      <li>At least 1 upper case character</li>
                      <li>At least 1 symbol</li>
                      <li>At least 1 number</li>
                      <li>Cannot be a recently used password</li>
                    </ul>
                  </Alert>
                  <Alert
                    message={success}
                    setMessage={setSuccess}
                    color="success"
                  />
                </AlertWrapper>
                <div className="js-form-message form-group">
                  <label
                    className="input-label"
                    htmlFor="resetPassword"
                    tabIndex="0"
                  >
                    New Password
                  </label>

                  <input
                    type="password"
                    className="form-control"
                    name="password"
                    id="resetPassword"
                    value={password}
                    onChange={(event) => setPassword(event.target.value)}
                    tabIndex="1"
                    placeholder="Enter new password"
                    aria-label="Enter your email address"
                    required
                  />
                </div>

                <div className="js-form-message mt-3 form-group">
                  <label
                    className="input-label"
                    htmlFor="resetConfirmPassword"
                    tabIndex="0"
                  >
                    Confirm New Password
                  </label>

                  <input
                    type="password"
                    className="form-control"
                    name="confirmPassword"
                    id="resetConfirmPassword"
                    value={confirmPassword}
                    onChange={(event) => setConfirmPassword(event.target.value)}
                    tabIndex="1"
                    placeholder="Confirm new password"
                    aria-label="Enter your email address"
                    required
                  />
                </div>

                <button
                  type="submit"
                  className="btn btn-md btn-block mt-3 font-size-sm2 font-weight-medium btn-primary"
                  disabled={isLoading}
                >
                  {isLoading ? (
                    <Spinner className="spinner-grow-xs" />
                  ) : (
                    'Change Password'
                  )}
                </button>

                <div className="text-center mt-2">
                  <button
                    onClick={() => history.push('/login')}
                    className="btn btn-block font-size-sm2 font-weight-medium btn-white text-black"
                  >
                    <i className="tio-chevron-left"></i> Return to Login
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </PublicLayout>
  );
}
