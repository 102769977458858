import React from 'react';
import GenericTable from '../../../../../components/GenericTable';
import Avatar from '../../../../../components/Avatar';
import { ShortDescription } from '../../../../../components/ShortDescription';
import { LessonDetailsColumns } from '../../../../../utils/learn.constants';

export const TeamsLessonReport = ({ results = [] }) => {
  const rows = results?.map((result, index) => {
    const user = {
      firstName: result?.first_name,
      lastName: result?.last_name,
      avatar: result?.avatar,
    };
    const name = `${result?.first_name} ${result?.last_name}`;
    const overDue = new Date(result?.last_attempted) > new Date(result?.dueAt);
    return {
      id: index,
      dataRow: [
        {
          key: 'user',
          component: (
            <div className="text-left font-weight-semi-bold">
              <Avatar defaultSize="xs" classModifiers="mr-2" user={user} />
              <span>{name}</span>
            </div>
          ),
        },
        {
          key: 'team',
          component: <div>{result?.team}</div>,
        },
        {
          key: 'lesson',
          component: (
            <div className="d-flex text-wrap font-weight-semi-bold align-items-baseline">
              {result?.lesson || '--'}
            </div>
          ),
        },

        {
          key: 'mins',
          component: result?.duration || '--',
        },
        {
          key: 'type',
          component: result?.uniqcountofusers || '--',
        },
        {
          key: 'dueDate',
          component:
            <ShortDescription content={result?.dueAt} limit={12} /> || '--',
        },
        {
          key: 'completed',
          component: result?.completed ? 'Yes' : '--',
        },
        {
          key: 'overdue',
          component: overDue ? 'Yes' : '--' || '--',
        },
        {
          key: 'inProgress',
          component: result?.status === 'in_progress' ? 'Yes' : '--' || '--',
        },
        {
          key: 'notStarted',
          component: result?.status === 'pending' ? 'Yes' : '--' || '--',
        },
        {
          key: 'timeTaken',
          component: result?.taken || '--',
        },
      ],
    };
  });

  const updatedRows = results?.map((item) => {
    const name = `${item?.first_name} ${item?.last_name}`;
    return {
      user: name,
      team: item?.team,
      lesson: item?.lesson,
      mins: item?.duration,
      dueDate: item?.dueAt,
      completed: item?.completed,
      overdue: '',
      inProgress: '',
      notStarted: '',
      timeTaken: item?.taken,
    };
  });

  return (
    <div>
      <GenericTable
        checkbox={false}
        exportToCSV={true}
        data={rows}
        tableData={updatedRows}
        fileName="LessonStatisticsReport"
        columns={LessonDetailsColumns}
        usePagination={false}
        noDataInDbValidation={true}
      />
    </div>
  );
};
