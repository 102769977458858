import AddNewButton from './AddNewButton';
import SectionWrapper from './SectionWrapper';
import { Col, Row } from 'reactstrap';
import ActionButtons from './tabs/ActionButtons';
import useAddNewAuthorizedSignerModal from './modals/AddNewAuthorizedSigner';
import React, { useEffect, useState } from 'react';
import NoDataFound from '../../commons/NoDataFound';
import NoDataFoundTitle from '../../fields/NoDataFoundTitle';
import useDeleteModal from './modals/useDeleteModal';
import { usePagesContext } from '../../../contexts/pagesContext';
import { AccountStructureTabMap } from './account.structure.constants';
import WhiteLabelName from './WhiteLabelName';

const AccountNumbers = ({ accounts }) => {
  const splitted = accounts.length > 0 ? accounts.split(', ') : [];
  return (
    <>
      {splitted.length > 0 ? (
        <>
          {splitted.map((nme) => (
            <WhiteLabelName key={nme}>{nme}</WhiteLabelName>
          ))}
        </>
      ) : null}
    </>
  );
};
const AuthorizedSigners = ({ report, setReport }) => {
  const { pageContext, setPageContext } = usePagesContext();
  const jsonKey = AccountStructureTabMap.ReportDetails.key;
  const [authSigners, setAuthSigners] = useState(
    pageContext?.AccountStructureReportModal[jsonKey]?.authorizedSigners || []
  );
  const [selectedAuthSigner, setSelectedAuthSigner] = useState({});
  const {
    DeleteModal,
    setData,
    setShowModal: setShowDeleteModal,
  } = useDeleteModal({
    description: 'Are you sure you want to delete this Authorized signer?',
    successMsg: 'Authorized signer deleted.',
    deleteCallback: async (signer) => {
      const newSigners = [...authSigners].filter((f) => f.id !== signer.id);
      setAuthSigners(newSigners);
    },
  });
  const handleEditAuthSigner = (signer) => {
    setSelectedAuthSigner(signer);
    setShowModal(true);
  };
  const handleDeleteAuthSigner = (signer) => {
    setData({
      ...signer,
      title: `${signer.accountNumber} (${signer?.names
        ?.map((m) => m.value)
        .join(', ')})`,
    });
    setShowDeleteModal(true);
  };
  const { AddNewAuthorizedSignerModal, setShowModal } =
    useAddNewAuthorizedSignerModal(
      selectedAuthSigner,
      authSigners,
      setAuthSigners
    );

  useEffect(() => {
    setPageContext({
      ...pageContext,
      AccountStructureReportModal: {
        ...pageContext.AccountStructureReportModal,
        [jsonKey]: {
          ...pageContext.AccountStructureReportModal[jsonKey],
          authorizedSigners: authSigners,
        },
      },
    });
  }, [authSigners]);
  return (
    <div className="w-100">
      <AddNewAuthorizedSignerModal />
      <DeleteModal />
      <div className="d-flex mb-2 justify-content-end align-items-center">
        <AddNewButton
          label="New User"
          onClick={() => {
            setSelectedAuthSigner({});
            setShowModal(true);
          }}
        />
      </div>
      <SectionWrapper columns={['Names', 'Account Numbers']}>
        {authSigners.length ? (
          <>
            {authSigners.map((signer) => (
              <Row
                key={signer.id}
                className="fs-7 bg-hover-gray-dark mx-0 py-2 px-0 border-bottom border-white"
              >
                <Col md={6} className="pl-2 align-self-center">
                  <div className="d-flex flex-wrap gap-1">
                    {signer.names.map((nme) => (
                      <WhiteLabelName key={nme.id}>{nme.value}</WhiteLabelName>
                    ))}
                  </div>
                </Col>
                <Col md={6} className="align-self-center">
                  <div className="d-flex align-items-center justify-content-between">
                    <div className="d-flex align-items-center gap-1 flex-wrap">
                      <AccountNumbers accounts={signer.accountNumber} />
                    </div>
                    <ActionButtons
                      onEdit={() => handleEditAuthSigner(signer)}
                      onDelete={() => handleDeleteAuthSigner(signer)}
                    />
                  </div>
                </Col>
              </Row>
            ))}
          </>
        ) : (
          <NoDataFound
            icon="groups"
            iconRounded={true}
            iconStyle="font-size-2em"
            containerStyle="text-gray-search mt-2 pt-2 mb-1"
            title={
              <NoDataFoundTitle
                clazz="fs-7 mb-0"
                str={`No authorized signers`}
              />
            }
          />
        )}
      </SectionWrapper>
    </div>
  );
};
export default AuthorizedSigners;
