import React from 'react';
import Skeleton from 'react-loading-skeleton';
import { ProgressBar } from 'react-bootstrap';

import MaterialIcon from '../../../../components/commons/MaterialIcon';
import TooltipComponent from '../../../../components/lesson/Tooltip';

export default function ChecklistsSplit({
  externalPercentage,
  internalPercentage,
  isLoading,
}) {
  const baseStyle = {
    height: 20,
    borderTopRightRadius: '0.75rem',
    borderBottomRightRadius: '0.75rem',
  };

  const baseExternalStyle = {
    height: 20,
    borderTopLeftRadius: '0.75rem',
    borderBottomLeftRadius: '0.75rem',
  };

  const conditionalStyle =
    internalPercentage === 0
      ? {
          borderTopLeftRadius: '0.75rem',
          borderBottomLeftRadius: '0.75rem',
        }
      : {};

  const conditionalExternalStyle =
    externalPercentage === 0
      ? {
          borderTopLeftRadius: '0.75rem',
          borderBottomLeftRadius: '0.75rem',
        }
      : {};

  const combinedStyle = { ...baseStyle, ...conditionalStyle };

  const externalStyle = { ...baseExternalStyle, ...conditionalExternalStyle };

  return (
    <div>
      <div className="split-progress-bar">
        <div className="d-flex align-items-center gap-2 w-100">
          {isLoading ? (
            <Skeleton width={30} height={30} />
          ) : (
            <MaterialIcon icon="earthquake" size="fs-2" clazz="text-primary" />
          )}
          <div className="w-100">
            <h5 className="text-muted fon-weight-light font-size-sm d-flex align-items-center gap-1 w-100">
              {isLoading ? (
                <Skeleton width={200} height={17} />
              ) : (
                <>
                  Checklists Split (All time){' '}
                  <TooltipComponent title="% of Checklist Items">
                    <span>
                      <MaterialIcon icon="info" />
                    </span>
                  </TooltipComponent>
                </>
              )}
            </h5>
            {isLoading ? (
              <Skeleton height={20} />
            ) : (
              <ProgressBar className={'progress-bar-2 w-100 rounded-lg mb-2'}>
                <ProgressBar
                  key={1}
                  style={externalStyle}
                  className="bg-gray-800"
                  now={internalPercentage}
                />
                <ProgressBar
                  key={2}
                  style={combinedStyle}
                  className="bg-gray-400"
                  now={externalPercentage}
                />
              </ProgressBar>
            )}
            <div
              className={`d-flex align-items-center justify-content-between w-100 ${
                isLoading ? 'mt-1' : 'mt-3'
              }`}
            >
              <div className="font-size-md">
                {isLoading ? (
                  <Skeleton width={130} />
                ) : (
                  <>
                    <div
                      className="bg-gray-800 rounded-circle d-inline-block mr-1"
                      style={{ width: '10px', height: '10px' }}
                    ></div>
                    Internal: {internalPercentage}%
                  </>
                )}
              </div>
              <div className="font-size-md">
                {isLoading ? (
                  <Skeleton width={130} />
                ) : (
                  <>
                    <div
                      className="bg-gray-400 rounded-circle d-inline-block mr-1"
                      style={{ width: '10px', height: '10px' }}
                    ></div>
                    External: {externalPercentage}%
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
