import { useState } from 'react';

const useSearchOptionDropdown = () => {
  const [selectedItem, setSelectedItem] = useState([]);
  const [show, setShow] = useState(false);

  return {
    selectedItem,
    setSelectedItem,
    show,
    setShow,
  };
};

export default useSearchOptionDropdown;
