import axios from './axiosInstance';
import authHeader from './auth-header';
import BaseRequestService from './baseRequest.service';

class ProductService extends BaseRequestService {
  async getProducts(queryFilter, { page = 1, limit = 10 }) {
    const { filter, ...restProps } = queryFilter || {};

    const params = {
      ...restProps,
      ...filter,
      page,
      limit,
    };

    return await this.get(
      `/products`,
      {
        params,
        headers: authHeader(),
      },
      { fullResponse: true }
    );
  }

  getProductsDeals(id, { page = 1, limit = 10 }) {
    const params = {
      page,
      limit,
    };

    return axios
      .get(`/products/${id}/deals`, {
        params,
        headers: authHeader(),
      })
      .then((response) => response);
  }

  saveProduct(product) {
    return axios
      .post(`/products`, product, { headers: authHeader() })
      .then((response) => response);
  }

  updateProduct(id, product) {
    return axios
      .put(`/products/${id}`, product, { headers: authHeader() })
      .then((response) => response);
  }

  deleteProduct(id) {
    return axios
      .delete(`/products/${id}`, { headers: authHeader() })
      .then((response) => response);
  }

  updateCustomField(fieldId, data) {
    return this.put(`/products/${fieldId}/fields`, data, {
      headers: authHeader(),
    });
  }

  getCustomField(id, { page, limit }) {
    const params = {
      page,
      limit,
    };
    return axios.get(`/products/${id}/fields`, {
      params,
      headers: authHeader(),
    });
  }

  deleteCustomField(id, fieldId) {
    return this.delete(`/products/${id}/fields/${fieldId}`, {
      headers: authHeader(),
    })
      .then((response) => {
        return response.data;
      })
      .catch((error) => error);
  }

  getProductSalesSnapshot({ dateRange, pagination }) {
    return this.post(
      '/products/aggregate/byName',
      { dateRange, pagination },
      { headers: authHeader() }
    );
  }
}

export default new ProductService();
