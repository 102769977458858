import React, { useEffect, useState } from 'react';
import { CardBody } from 'reactstrap';
import { Steps, Step } from 'react-step-builder';
import { useHistory } from 'react-router-dom';
import Nav from '../../components/lesson/nav';
import Page from '../../components/lesson/page';
import Hero from '../../components/lesson/hero';
import { API } from '../../services/api';
import lessonService from '../../services/lesson.service';
import { COMPLETED, QUIZ } from '../../utils/constants';
import PageTitle from '../../components/commons/PageTitle';
import { LessonLayout } from '../../components/lessonLayout';
import courseService from '../../services/course.service';
import useUrlSearchParams from '../../hooks/useUrlSearchParams';
import LessonSkeleton from '../../components/commons/LessonSkeleton';
import { scrollToTop } from '../../utils/Utils';

const CourseDetail = ({ setRefreshFav }) => {
  const api = new API();
  const searchParams = useUrlSearchParams();
  const id = searchParams.get('customViewId');
  const viewType = searchParams.get('viewType');
  const catTitle = searchParams.get('catTitle');

  const path = searchParams.get('path');
  const history = useHistory();
  const [currentLesson, setCurrentLesson] = useState({
    state: 'in_progress',
  });
  const [isLoading, setIsLoading] = useState(true);

  const [refresh, setRefresh] = useState(0);
  const [loading, setLoading] = useState(true);
  const [relatedLessons, setRltLessons] = useState([]);
  const [nextLessons, setNextLessons] = useState([]);
  const [getLessonId, setGetLessonId] = useState('');

  const getData = async (id) => {
    try {
      const [
        fullCourse,
        course,
        progressCourse = { status: 'pending', progress: 0 },
      ] = await Promise.all([
        courseService.getCourseById(id, { favorites: 'include' }),
        courseService.getCourseLessonsById(id),
        courseService.getCourseProgress(id, { self: true }).catch(() => {}),
      ]);

      if (progressCourse && course) {
        const { is_learning_path, lessons, quiz_id, ...restProps } = course;

        const lessonsProgress = await Promise.all(
          lessons.map(async (lesson) => {
            try {
              const resp = await lessonService.GetLessonTrackByLessonId(
                lesson.id,
                { self: true }
              );
              lesson.progress = resp?.progress || 0; // Assuming progress is retrieved from the response
            } catch (error) {
              // Handle the error here, you can log it or take other actions
              console.error(
                `Error fetching progress for lesson with ID ${lesson.id}:`,
                error
              );
              lesson.progress = 0; // Set a default value of 0 in case of an error
            }
            return lesson;
          })
        );
        const lessonsOrder = lessonsProgress.sort((next, prox) => {
          if (
            next.CourseLessonModel.position > prox.CourseLessonModel.position
          ) {
            return 1;
          }
          return -1;
        });
        const filteredLessons = lessonsOrder.filter(
          (lesson) => lesson.progress !== 100
        );
        if (!getLessonId && filteredLessons?.length === 0) {
          const { status, progress } = progressCourse;

          setCurrentLesson({
            state: status,
            progress,
            is_learning_path,
            quiz_id,
            category: !is_learning_path ? lessons[0]?.category : null,
            ...restProps,
            contents: fullCourse?.contents,
          });
          setIsLoading(false);
        } else {
          setNextLessons(filteredLessons);
          setGetLessonId(getLessonId || filteredLessons[0]?.id);
        }

        if (lessonsOrder.length > 0) {
          setRltLessons(lessonsOrder);
        }
        setLoading(false);
      } else {
        history.push('/');
      }
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    if (id) {
      // Using regular expression to match the UUID
      const match = id.match(
        /[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}/
      );

      // Extracting the matched UUID
      const courseId = match ? match[0] : null;
      getData(courseId);
    }
  }, [id]);

  const getLesson = async (id) => {
    setIsLoading(true);
    window.scrollTo(0, 0);
    const apiLesson = await api
      .GetLessonById(id)
      .catch((err) => console.log(err));

    if (!apiLesson) {
      return;
    }

    try {
      const resp = await lessonService.GetLessonTrackByLessonId(id, {
        self: true,
      });

      const { completed_at, status } = resp || {};

      let newPages = apiLesson?.pages?.slice();

      if (completed_at && status === COMPLETED) {
        newPages = apiLesson.pages.filter((page) => !page.type.includes(QUIZ));
      }

      setCurrentLesson({
        ...apiLesson,
        pages: newPages,
        lessonTrack: resp || {},
      });
    } catch (e) {
      // first time lesson progress comes null, so default/first page load
      const newPages = apiLesson.pages.slice();
      setCurrentLesson({
        ...apiLesson,
        pages: newPages,
        lessonTrack: {},
      });
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (getLessonId) {
      getLesson(getLessonId);
      scrollToTop();
    }
  }, [getLessonId]);

  useEffect(() => {
    if (refresh > 0) {
      getData(id);
    }
  }, [refresh]);

  const config = {
    navigation: {
      component: Nav, // a React component with special props provided automatically
      location: 'after', // or after
    },
  };

  const FirstStep = (props) => {
    return (
      <Hero
        points={currentLesson.max_points}
        {...props}
        setRefresh={setRefresh}
        setNextLessons={setNextLessons}
        handleRetakeCourse={handleRetakeCourse}
        course={id}
        getLessonId={getLessonId}
        setGetLessonId={setGetLessonId}
        nextLessons={nextLessons}
        setRefreshFav={setRefreshFav}
      />
    );
  };

  const handleRetakeCourse = async () => {
    const pl = {
      pageId: null,
    };
    await Promise.all(
      relatedLessons?.map(async (item) => {
        try {
          await api.TrackLesson(item?.id, pl);
          setGetLessonId('');
        } catch (err) {
          console.log(err);
        }
      })
    );
    setRefresh((prevState) => prevState + 1);
  };

  return (
    <div>
      {isLoading ? (
        <LessonSkeleton
          categoryTitle={
            catTitle ||
            currentLesson?.category?.title ||
            currentLesson?.contents?.title ||
            ''
          }
        />
      ) : (
        <>
          <PageTitle page={currentLesson?.title} pageModule="Learn" />
          <LessonLayout
            lesson={currentLesson}
            relatedLessons={relatedLessons}
            path={path}
            setGetLessonId={setGetLessonId}
            loading={loading}
            type={viewType}
            course={id}
            categoryTitle={catTitle}
            viewType="course"
            setRefresh={setRefreshFav}
          >
            <div className="card rounded-0 lesson-learn-card">
              <CardBody className="d-flex flex-column justify-content-between h-100 py-3 px-3 bg-primary-soft">
                <Steps config={config}>
                  <Step
                    title={currentLesson?.title}
                    lesson={currentLesson}
                    component={(props) => <FirstStep {...props} />}
                  />
                  {currentLesson.pages?.map((p, indx) => (
                    <>
                      <Step
                        key={indx}
                        lessonId={currentLesson?.id}
                        firstPage={currentLesson?.pages[0]}
                        title={p?.title}
                        component={(props) => (
                          <Page {...props} lesson={currentLesson} page={p} />
                        )}
                      />
                    </>
                  ))}
                  <Step
                    title={currentLesson?.title}
                    lesson={currentLesson}
                    component={(props) => (
                      <Hero
                        {...props}
                        isLastStep={true}
                        course={id}
                        category_id={currentLesson?.category_id}
                        nextLessons={nextLessons}
                        setGetLessonId={setGetLessonId}
                        setNextLessons={setNextLessons}
                        handleRetakeCourse={handleRetakeCourse}
                        getLessonId={getLessonId}
                        setRefresh={setRefresh}
                        type={viewType}
                        setRefreshFav={setRefreshFav}
                      />
                    )}
                  />
                </Steps>
              </CardBody>
            </div>
          </LessonLayout>
        </>
      )}
    </div>
  );
};

export default React.memo(CourseDetail);
