import React, { useEffect, useState } from 'react';
import { Card, CardBody, CardFooter, CardHeader } from 'reactstrap';
import LessonDetailsReport from './LessonDetailsReport';
import { paginationDefault } from '../../../../../utils/constants';
import teamsService from '../../../../../services/teams.service';
import ReportSearchOptionDropdown from '../../../../../components/commons/ReportSearchOptionDropdown';
import { cubeService } from '../../../../../services';
import IdfTooltip from '../../../../../components/idfComponents/idfTooltip';
import {
  changePaginationLimit,
  changePaginationPage,
} from '../../../../Deals/contacts/utils';
import Pagination from '../../../../../components/Pagination';
import TableSkeleton from '../../../../../components/commons/TableSkeleton';

const TrainingLessonDetails = ({ dashboard }) => {
  const [data, setData] = useState([]);
  const [teams, setTeams] = useState([]);
  const [csvBtnLoad, setShowCSVBtn] = useState(false);
  const [selectedItem, setSelectedItem] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [show, setShow] = useState(false);
  const [loading, setLoading] = useState(true);
  const [activeTab, setActiveTab] = useState(2);
  const [queryFilter, setQueryFilter] = useState({
    filters: [],
  });

  const [pagination, setPagination] = useState(paginationDefault);
  const [paginationPage, setPaginationPage] = useState(paginationDefault);

  const getTeam = async () => {
    const { data } = await teamsService.getTeams({
      page: 1,
      limit: 100,
      filter: { search: searchQuery, isActive: true },
    });
    setTeams(data);
  };

  useEffect(() => {
    getTeam();
  }, [searchQuery]);

  const handleOptionSelect = (event) => {
    const { value } = event.target;
    if (selectedItem?.length > 0) {
      setQueryFilter({
        filters: [...selectedItem],
      });
    } else {
      setQueryFilter((prevState = []) => {
        const isSelected = prevState.includes(value);
        const updatedSelectedData = isSelected
          ? prevState.filter((item) => item !== value)
          : [...prevState, value];
        return updatedSelectedData;
      });
    }
    toggleModify();
  };

  const handleAllSelect = () => {
    const allSelected = teams?.map((rpt) => rpt?.id);

    setQueryFilter({
      filters: [...allSelected],
    });
    toggleModify();
  };

  const toggleModify = () => {
    setPaginationPage((prev) => ({ ...prev, page: 1 }));
  };

  const getData = async () => {
    setLoading(true);
    try {
      const results = await cubeService.loadCustomAnalytics({
        type: 'LessonDetails',
        limit: paginationPage?.limit,
        page: paginationPage?.page,
        ...(queryFilter?.filters?.length > 0
          ? { teams: queryFilter?.filters }
          : {}),
      });
      setShowCSVBtn(results?.length > 0);
      setData(results);
      setPagination(paginationPage); // Todo:- will update with response pagination when API's will change
    } catch (e) {
      console.log(e);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getData();
  }, [paginationPage]);

  const loader = () => {
    if (loading) return <TableSkeleton cols={6} rows={10} />;
  };

  return (
    <Card className="h-100 border-0 shadow-0">
      <CardHeader>
        <div className="d-flex justify-content-between align-items-center w-100">
          <IdfTooltip text={dashboard?.name} placement="bottom">
            <h4 className="card-title text-hover-primary mb-0 text-truncate">
              {dashboard?.name}
            </h4>
          </IdfTooltip>
          <div
            className={`d-flex align-items-center gap-2 ${
              csvBtnLoad ? 'mr-6 pr-9' : ''
            }`}
          >
            <ReportSearchOptionDropdown
              selectedData={selectedItem}
              data={teams}
              show={show}
              label={`Team`}
              scrollable="pr-3 text-left"
              setShow={setShow}
              showCheckAll={false}
              setSelectedData={setSelectedItem}
              search={true}
              maxLength={22}
              customKey={['name', 'id']}
              handleAllSelect={handleAllSelect}
              customStyle={{ width: '250px' }}
              setQueryFilters={() => {}}
              searchQuery={searchQuery}
              setSearchQuery={setSearchQuery}
              handleOptionSelected={handleOptionSelect}
            />
          </div>
        </div>
      </CardHeader>

      <CardBody className="p-0 flex-1 overflow-y-auto">
        {loading ? (
          loader()
        ) : (
          <LessonDetailsReport
            data={data}
            activeTab={activeTab}
            setActiveTab={setActiveTab}
          />
        )}
      </CardBody>

      <CardFooter className="border-top bg-gray-table-head p-2 border-bottom-0 border-left-0 border-right-0 rounded-0">
        <div className="d-flex align-items-center justify-content-end">
          <Pagination
            showPerPage
            showPages={false}
            paginationInfo={pagination}
            onPageChange={(newPage) => {
              changePaginationPage(newPage, setPaginationPage);
            }}
            onLimitChange={(perPage) => {
              changePaginationLimit(perPage, setPaginationPage);
            }}
          />
        </div>
      </CardFooter>
    </Card>
  );
};

export default TrainingLessonDetails;
