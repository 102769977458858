import React from 'react';
import { Badge } from 'react-bootstrap';
import IdfTooltip from '../idfComponents/idfTooltip';

const Counter = ({ moduleName, active, count }) => {
  return (
    <>
      {count > 0 ? (
        <Badge
          style={{ width: 21, height: 21 }}
          className={`rounded-circle d-flex align-items-center justify-content-center ml-2 fs-9 p-1 ${
            active ? 'bg-transparent text-white' : 'bg-primary-soft'
          }`}
        >
          {count > 10 ? (
            <IdfTooltip text={`Total ${moduleName}: ${count}`}>
              <span>10+</span>
            </IdfTooltip>
          ) : (
            count
          )}
        </Badge>
      ) : null}
    </>
  );
};
const PillFilters = ({
  items,
  moduleName,
  currentFilter,
  onFilterChange,
  className,
  itemWidth = 100,
}) => {
  return (
    <div className="propescting-sidebar">
      <div
        className={`d-flex align-items-center prospecting-pills rounded-pill border ${className}`}
      >
        {items.map((fil) => (
          <a
            key={fil.id}
            style={{ minWidth: itemWidth }}
            onClick={(e) => {
              e.preventDefault();
              onFilterChange(fil);
            }}
            className={`py-1 px-2 ${
              currentFilter.id === fil.id
                ? 'pill-active text-white fw-bold'
                : ''
            } text-center fs-7 d-flex text-nowrap align-items-center justify-content-center cursor-pointer rounded-pill`}
          >
            {fil.label}
            <Counter
              active={currentFilter.id === fil.id}
              moduleName={moduleName}
              count={fil.count}
            />
          </a>
        ))}
      </div>
    </div>
  );
};

export default PillFilters;
