import { useEffect, useState } from 'react';
import { cubeService } from '../../../services';
import { TopLessonsColumns } from '../../../utils/learn.constants';
import GenericTable from '../../../components/GenericTable';
import TableSkeleton from '../../../components/commons/TableSkeleton';
import NoDataFound from '../../../components/commons/NoDataFound';

const TopLessons = ({
  setShowCSVBtn = () => {},
  lessonIds,
  categoryIds,
  paginationPage,
  setPagination,
  toggleModify,
}) => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [orderBy, setOrderBy] = useState({
    order: `"totalTimesCompleted" desc`,
  });
  const [order, setOrder] = useState(['', 'DESC']);

  const getLessonsStats = async () => {
    setLoading(true);

    try {
      const results = await cubeService.loadCustomAnalytics({
        type: 'TopLessons',
        orderBy: orderBy?.order,
        limit: paginationPage?.limit,
        page: paginationPage?.page,
        ...(lessonIds?.length > 0 ? { lessonIds } : {}),
        ...(categoryIds?.length > 0 ? { categoryIds } : {}),
      });
      setData(results);
      setShowCSVBtn(results?.length > 0);
      setPagination(paginationPage); // Todo:- will update with response pagination when API's will change
    } catch (e) {
      console.log(e);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getLessonsStats();
  }, [paginationPage]);

  const loader = () => {
    if (loading) return <TableSkeleton cols={6} rows={10} />;
  };

  const rows = data?.map((result, index) => {
    return {
      id: index,
      dataRow: [
        {
          key: 'lessonTitle',
          component: (
            <div className="d-flex text-wrap font-weight-semi-bold align-items-baseline">
              {result?.lessonTitle || '--'}
            </div>
          ),
        },
        {
          key: 'lessonCategory',
          component: result?.lessonCategory || '--',
        },
        {
          key: 'totalUsers',
          component: result?.totalUsers || '--',
        },
        {
          key: 'totalTimesCompleted',
          component: result?.totalTimesCompleted || '--',
        },
      ],
    };
  });

  const updatedRows = data?.map((item) => {
    return {
      lessonTitle: item?.lessonTitle,
      lessonCategory: item?.lessonCategory,
      totalUsers: item?.totalUsers,
      totalTimesCompleted: item?.totalTimesCompleted,
    };
  });

  const handleSortByData = (key) => {
    if (key?.name?.key === 'selfDirected') {
      setOrderBy({
        order: `${key?.name?.orderBy} ${
          key?.name?.clicked.toLowerCase() === 'desc' ? 'asc' : 'desc'
        }`,
      });
    } else {
      setOrderBy({
        order: `${key?.name?.orderBy} ${key?.name?.clicked.toLowerCase()}`,
      });
    }
    setOrder([key?.name?.orderBy, key?.name?.clicked]);
    toggleModify();
  };

  return (
    <div>
      {loading ? (
        loader()
      ) : (
        <div>
          {data?.length === 0 ? (
            <NoDataFound
              icon="manage_search"
              title="No data found"
              containerStyle="text-gray-search my-6 py-6"
              titleStyle="font-normal font-size-sm2 text-gray-search"
            />
          ) : (
            <GenericTable
              checkbox={false}
              exportToCSV={true}
              data={rows}
              tableData={updatedRows}
              fileName="TopLessons"
              columns={TopLessonsColumns}
              usePagination={false}
              noDataInDbValidation={true}
              sortingTable={handleSortByData}
              sortingOrder={order}
            />
          )}
        </div>
      )}
    </div>
  );
};

export default TopLessons;
