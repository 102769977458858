import React, { useEffect, useState } from 'react';
import AskAnything from './AskAnything';
import PageTitle from '../commons/PageTitle';
import Greetings from './Greetings';
import Disclaimer from './Disclaimer';
import { useIdaContext } from '../../contexts/IdaContext';
import NachaDisclaimer from './NachaDisclaimer';

const HomeWrapper = ({ children }) => {
  const { knowledgeSource, setKnowledgeSource } = useIdaContext();
  // temp solution until BE supports
  const [showNachaDisclaimer, setShowNachaDisclaimer] = useState(false);
  useEffect(() => {
    setShowNachaDisclaimer(
      knowledgeSource?.title?.toLowerCase().includes('nacha')
    );
  }, [knowledgeSource]);
  useEffect(() => {
    return () => {
      setKnowledgeSource('');
    };
  }, []);
  return (
    <div className="bg-white position-relative h-100 w-100">
      <div className="chat-home-container justify-content-center h-100 mx-auto">
        <Greetings />
        {children}
      </div>
      {showNachaDisclaimer ? <NachaDisclaimer /> : null}
      <Disclaimer style="position-absolute bottom-0 fs-7 abs-center" />
    </div>
  );
};
const Home = () => {
  return (
    <HomeWrapper>
      <PageTitle page="Ida" />
      <AskAnything />
    </HomeWrapper>
  );
};
export default Home;
