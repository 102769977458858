import { useEffect, useState } from 'react';
import ChatService from '../../../services/chat.service';

const useFetchChat = (id, loadFromContext = {}) => {
  const [chat, setChat] = useState({});
  const [loading, setLoading] = useState(false);

  const fetchChat = async (newChatId) => {
    // if context has the chat already then use that otherwise fetch new
    if (Object.keys(loadFromContext).length) {
      setChat(loadFromContext);
      return;
    }
    try {
      setLoading(true);
      const data = await ChatService.getChat(newChatId || id);
      setChat(data);
    } catch (e) {
      console.log(e);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    id && fetchChat();
  }, [id]);

  return {
    chat,
    setChat,
    loading,
    setLoading,
    fetchChat,
  };
};

export default useFetchChat;
