import React from 'react';
import PageTitle from '../../commons/PageTitle';
import LucideIcon from '../../commons/LucideIcon';
import { roundNumbers } from '../../../utils/Utils';
import Table from '../../GenericTable';
import TextOverflowTooltip from '../../commons/TextOverflowTooltip';
import TableRowHover from '../../commons/TableRowHover';
import StatCardList from '../shared/StatCardList';
import ProgressCard from '../shared/ProgressCard';
import DarkStatusBadge from '../../commons/DarkStatusBadge';

const Data = [
  {
    title: 'Strategic',
    icon: 'Award',
    totalCompanies: '22',
    reviewsDue: '17',
    reviewsCompletion: '77.3',
    variant: 'primary',
  },
  {
    title: 'Core',
    icon: 'CircleDot',
    totalCompanies: '38',
    reviewsDue: '25',
    reviewsCompletion: '65.8',
    variant: 'success',
  },
  {
    title: 'Transactional',
    icon: 'ChartBar',
    totalCompanies: '73',
    reviewsDue: '4',
    reviewsCompletion: '50',
    variant: 'warning',
  },
  {
    title: 'Prospect',
    icon: 'ChartScatter',
    totalCompanies: '48',
    reviewsDue: '0',
    reviewsCompletion: '100',
    variant: 'primary',
  },
];

const tableColumns = [
  {
    key: 'name',
    component: <span>Company Name</span>,
    classes: 'w-250px',
  },
  {
    key: 'client_tier',
    component: <span>Client Tier</span>,
  },
  {
    key: 'labels',
    component: <span>Tags</span>,
  },
  {
    key: 'mom_variance',
    component: <span>MoM Variance</span>,
  },
  {
    key: 'yoy_variance',
    component: <span>YoY Variance</span>,
  },
];

const rowData = [
  {
    name: 'Globex Corporation',
    tier: 'Strategic',
    labels: ['Integrated Payables', 'RTP', 'Commercial Card'],
    variance: -87.4,
    yoyVariance: -67,
  },
  {
    name: 'Becker & Associates',
    tier: 'Core',
    labels: ['Positive Pay', 'Merchant'],
    variance: 76.2,
    yoyVariance: 75,
  },
  {
    name: 'Aperture Science',
    tier: 'Transactional',
    labels: ['Lockbox', 'Commercial Card'],
    variance: -71.8,
    yoyVariance: -74,
  },
  {
    name: 'Initech Solutions',
    tier: 'Core',
    labels: ['Checks', 'Positive Pay'],
    variance: -63.5,
    yoyVariance: -65,
  },
  {
    name: 'Stark Industries',
    tier: 'Strategic',
    labels: ['ACH', 'RTP', 'Commercial Card'],
    variance: 57.9,
    yoyVariance: 55,
  },
];

const TagColor = {
  'Integrated Payables': 'green',
  RTP: 'red',
  'Positive Pay': 'blue',
  'Commercial Card': 'gray',
  ACH: 'yellow',
  Checks: 'magenta',
  Lockbox: 'teal',
  Merchant: 'indigo',
};

const PortfolioHome = () => {
  const rows = rowData?.map((item) => {
    return {
      ...item,
      dataRow: [
        {
          key: 'name',
          classes: 'px-0',
          component: (
            <div className="text-black text-truncate fw-bold d-flex align-items-center gap-2">
              <TextOverflowTooltip
                maxLength={25}
                textStyle="fw-bold text-truncate font-size-sm2 mb-0 z-index-100"
                text={item.name}
              />
            </div>
          ),
        },
        {
          key: 'client_tier',
          label: '',
          component: (
            <>
              <span>{item?.tier || '--'}</span>
            </>
          ),
        },
        {
          key: 'labels',
          label: '',
          component: (
            <div className="d-flex flex-wrap gap-1">
              {item?.labels
                ? item?.labels?.map((label) => {
                    return (
                      <DarkStatusBadge
                        key={label}
                        item={{
                          color: TagColor[label],
                          text: label,
                        }}
                      />
                    );
                  })
                : '--'}
            </div>
          ),
        },
        {
          key: 'mom_variance',
          label: '',
          component: (
            <>
              <span>
                {item?.variance !== undefined && item?.variance !== null
                  ? (() => {
                      const variance = item.variance;
                      const varianceClass =
                        variance > 0 ? 'text-green' : 'text-red';
                      const roundedVariance = roundNumbers(
                        variance,
                        'short',
                        2
                      );
                      return (
                        <p
                          className={`d-flex align-items-center mb-0 gap-1 font-weight-500 ${varianceClass}`}
                        >
                          <LucideIcon
                            icon={variance < 0 ? 'ChevronDown' : 'ChevronUp'}
                          />
                          {variance < 0
                            ? `${roundedVariance}%`
                            : `+${roundedVariance}%`}
                        </p>
                      );
                    })()
                  : '--'}
              </span>
            </>
          ),
        },
        {
          key: 'yoy_variance',
          label: '',
          component: (
            <>
              <TableRowHover zIndex="z-index-5" />
              <span>
                {item?.yoyVariance !== undefined && item?.yoyVariance !== null
                  ? (() => {
                      const variance = item.yoyVariance;
                      const varianceClass =
                        variance > 0 ? 'text-green' : 'text-red';
                      const roundedVariance = roundNumbers(
                        variance,
                        'short',
                        2
                      );
                      return (
                        <p
                          className={`d-flex align-items-center mb-0 gap-1 font-weight-500 ${varianceClass}`}
                        >
                          <LucideIcon
                            icon={variance < 0 ? 'ChevronDown' : 'ChevronUp'}
                          />
                          {variance < 0
                            ? `${roundedVariance}%`
                            : `+${roundedVariance}%`}
                        </p>
                      );
                    })()
                  : '--'}
              </span>
            </>
          ),
        },
      ],
    };
  });

  return (
    <div>
      <PageTitle page="Portfolio" />

      <div className="d-grid grid-col-2 gap-3 mb-3">
        {Data?.map((item) => (
          <ProgressCard key={item.id} item={item} />
        ))}
      </div>

      <StatCardList
        item={{
          icon: 'Activity',
          title: 'Fee Variances Month Over Month',
        }}
      >
        <div className="d-flex flex-column">
          <div className="table-min-height">
            <div className="table-responsive-md datatable-custom">
              <div
                id="datatable_wrapper"
                className="dataTables_wrapper no-footer h-auto"
              >
                <Table
                  data={rows}
                  columns={tableColumns}
                  dataInDB={rowData?.length}
                  showLoading={false}
                  emptyDataText="No recent fee variances"
                  headClass="bg-gray-table-head"
                />
              </div>
            </div>
          </div>
        </div>
      </StatCardList>
    </div>
  );
};

export default PortfolioHome;
