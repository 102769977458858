import { useEffect } from 'react';

export default function useFocusInput(inputRef, deps = []) {
  useEffect(() => {
    const timer = setTimeout(() => {
      if (inputRef.current) {
        inputRef.current.focus({
          cursor: 'end',
        });
      }
    }, 0);

    return () => clearTimeout(timer);
  }, deps);
}
