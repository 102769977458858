import { Dropdown, Form, FormControl } from 'react-bootstrap';
import ButtonIcon from './ButtonIcon';
import React, { useEffect, useRef, useState } from 'react';
import MaterialCheckbox from './MaterialCheckbox';
import useOutsideClickDropDown from '../../hooks/useOutsideClickDropDown';
import NoDataFound from './NoDataFound';
import NoDataFoundTitle from '../fields/NoDataFoundTitle';
import TextOverflowTooltip from './TextOverflowTooltip';

const ReportSearchOptionDropdown = ({
  data = [],
  show,
  label = 'options',
  setShow,
  selectedData,
  customClass,
  customKey = ['name', 'key'],
  setSelectedData,
  setQueryFilters,
  handleOptionSelected,
  handleAllSelect = (a, b) => {},
  search,
  maxLength,
  scrollable,
  dontClose,
  customStyle,
  handleOnDone,
  controlledTitle,
  setSearchQuery,
}) => {
  const [multiSelect, setMultiSelect] = useState([...selectedData]);
  const [internalSearchQuery, setInternalSearchQuery] = useState('');

  const renderDropdownTitle = () => {
    if (controlledTitle && controlledTitle?.length) {
      return <span className="text-truncate pr-3">{controlledTitle}</span>;
    }

    const selectedItem = selectedData;
    if (
      selectedData.length === 0 ||
      (selectedData.length === 1 && selectedData[0] === '')
    ) {
      return <span className="text-truncate pr-3">{`Select ${label}`}</span>;
    } else if (selectedItem && customKey?.length > 0) {
      const getName = data?.filter((item) => {
        return (
          <span className="text-truncate pr-3">
            {selectedItem?.some((selected) => item[customKey[1]] === selected)}
          </span>
        );
      });

      if (getName) {
        const names = getName.map((item) => item[customKey[0]]).join(', ');
        return <span className="text-truncate pr-3">{names}</span>;
      }
    } else {
      return (
        <span className="text-truncate pr-3">{`${selectedData.length} ${label} selected`}</span>
      );
    }
  };

  const menuRef = useRef(null);
  useOutsideClickDropDown(menuRef, show, setShow);
  const [selectAll, setSelectAll] = useState(false);

  useEffect(() => {
    setSelectAll(selectedData?.length === data.length);
    if (multiSelect?.length === data?.length) {
      setSelectAll(true);
    }
    setMultiSelect([...selectedData]);
  }, [selectedData, data]);

  const filteredData = () =>
    data
      .map((item) => ({
        ...item,
        isChecked: multiSelect.some(
          (selected) => selected[customKey[1]] === item[customKey[1]]
        ),
      }))
      ?.filter((item) =>
        item[customKey[0]]
          ?.toLowerCase()
          ?.includes(internalSearchQuery.toLowerCase())
      );

  const handleMultiSelect = (item = {}) => {
    setSelectAll(false);
    const itemKey = typeof item === 'object' ? item[customKey[1]] : item;
    const isSelected = selectedData.includes(itemKey);

    if (!isSelected) {
      // Add to multiSelect
      setMultiSelect([...multiSelect, item]);
      // Add to selectedData
      setSelectedData([...selectedData, itemKey]);
    } else {
      // Remove from multiSelect
      setMultiSelect(
        multiSelect.filter((selected) => {
          const selectedKey =
            typeof selected === 'object' ? selected[customKey[1]] : selected;
          return selectedKey !== itemKey;
        })
      );
      // Remove from selectedData
      setSelectedData(selectedData.filter((selected) => selected !== itemKey));
    }
  };

  const allSelected = data?.map((rpt) => rpt[customKey[1]]);

  const handleDone = () => {
    if (handleOnDone && typeof handleOnDone === 'function') {
      return handleOnDone?.(multiSelect);
    }

    if (multiSelect?.length > 0) {
      multiSelect?.forEach((item) => {
        const itemKey = typeof item === 'object' ? item[customKey[1]] : item;
        handleOptionSelected({
          target: { value: itemKey },
        });
      });
    } else {
      setSelectedData([]);
      setQueryFilters([]);
    }
    setShow(false);
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault();
    }
  };
  return (
    <Dropdown
      show={show}
      onToggle={setShow}
      className={customClass}
      style={customStyle}
    >
      <Dropdown.Toggle
        variant="white"
        as="div"
        className="w-100 text-left btn btn-white dropdown-toggle cursor-pointer custom-toggle-caret overflow-hidden pr-4"
        id="dropdown-basic"
      >
        <span className="d-inline-block w-100 overflow-hidden">
          {renderDropdownTitle()}
        </span>
      </Dropdown.Toggle>

      <Dropdown.Menu ref={menuRef} className="w-100 py-0 z-index-250">
        <div className="d-flex h-100 flex-column">
          <div
            onClick={() => {
              const checked = !selectAll;
              setSelectAll(checked);

              if (checked) {
                setSelectedData(allSelected);
                setMultiSelect([...filteredData()]);
              } else {
                setSelectedData([]);
                setMultiSelect([]);
              }
            }}
            className={`pt-3 border-bottom pl-1 pb-2 bg-hover-gray-no-transparence cursor-pointer d-flex justify-content-between ${scrollable}`}
          >
            <div className="d-flex align-items-center gap-1">
              <span>&nbsp;&nbsp;</span>
              <span className="font-size-sm2 font-weight-medium">
                Select All
              </span>
            </div>
            <MaterialCheckbox checked={selectAll} />
          </div>
          <Form className="p-0 flex-grow-1 menu-labels">
            {search && (
              <div className="p-3">
                <FormControl
                  value={internalSearchQuery}
                  placeholder={`Search ${label}`}
                  type="search"
                  onKeyDown={handleKeyDown}
                  onChange={(e) => {
                    setSearchQuery?.(e.target.value);
                    setInternalSearchQuery(e.target.value);
                  }}
                />
              </div>
            )}
            {filteredData()?.length > 0 ? (
              filteredData().map((rpt) => (
                <div
                  key={rpt[customKey[1]]}
                  onClick={() => {
                    handleMultiSelect(rpt);
                  }}
                  className="py-2 d-flex cursor-pointer bg-hover-gray-no-transparence align-items-center justify-content-between px-3"
                >
                  {maxLength ? (
                    <TextOverflowTooltip
                      textStyle="fs-7 font-weight-medium"
                      text={rpt[customKey[0]]}
                      maxLength={maxLength}
                    />
                  ) : (
                    <span className="fs-7 font-weight-medium">
                      {rpt[customKey[0]]}
                    </span>
                  )}
                  <MaterialCheckbox
                    checked={
                      rpt?.isChecked ||
                      selectedData?.includes(rpt[customKey[1]])
                    }
                  />
                </div>
              ))
            ) : (
              <NoDataFound
                icon="account_balance_wallet"
                iconRounded={true}
                iconStyle="font-size-2em"
                containerStyle="text-gray-search mt-2 pt-2 mb-1"
                title={
                  <NoDataFoundTitle
                    clazz="fs-7 mb-0"
                    str={`No ${label} found`}
                  />
                }
              />
            )}
          </Form>
          <div className="p-3 border-top">
            <ButtonIcon
              label="Done"
              color="primary"
              classnames="btn-block btn-sm"
              onclick={() => {
                selectAll
                  ? handleAllSelect(selectAll, selectAll ? allSelected : [])
                  : handleDone();
                setShow(false);
              }}
            />
          </div>
        </div>
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default ReportSearchOptionDropdown;
