import { useEffect, useState } from 'react';
import ArchiveService from '../../../services/archive.service';
import { useDebounce } from '../../../hooks/useDebounce';

const useFetchArchives = (auto = true, limit = 10, premiumKeyParams = {}) => {
  const [archives, setArchives] = useState([]);
  const [pagination, setPagination] = useState({
    currentPage: 1,
    totalPages: 1,
    count: 0,
  });
  const [paginationArchives, setPaginationArchives] = useState({
    currentPage: 1,
    totalPages: 1,
    count: 0,
  });
  const [loading, setLoading] = useState(true);
  const [loadingPremiumArchives, setLoadingPremiumArchives] = useState(true);
  const PremiumItem = (item) => ({
    ...item,
    isPrivate: true,
    isFavorite: false,
    isPremium: true,
    static: true,
  });
  const [hasMore, setHasMore] = useState(true);
  const [hasMoreArchive, setHasMoreArchive] = useState(true);
  const [searchValue, setSearchValue] = useState('');
  const debouncedSearchTerm = useDebounce(searchValue, 500);
  const [premiumArchives, setPremiumArchives] = useState([]);

  const fetchArchives = async (page = 1, title = searchValue) => {
    try {
      setLoading(true);
      const { data, pagination: archPagination } =
        await ArchiveService.getArchives({ page, limit, title });
      if (page === 1) {
        setArchives(data);
      } else {
        setArchives((prevChats) => [...prevChats, ...data]);
      }
      setPagination({
        currentPage: page,
        totalPages: archPagination.totalPages,
        count: archPagination.count,
      });
      setHasMore(page < archPagination.totalPages);
    } catch (e) {
      console.log(e);
    } finally {
      setLoading(false);
    }
  };

  const fetchPremiumArchives = async (page = 1, title = searchValue) => {
    try {
      setLoadingPremiumArchives(true);
      const { data, pagination: archPagination } =
        await ArchiveService.getArchives({
          title,
          limit,
          ...premiumKeyParams,
        });
      if (page === 1) {
        setPremiumArchives(data.map(PremiumItem));
      } else {
        setPremiumArchives((prevChats) =>
          [...prevChats, ...data].map(PremiumItem)
        );
      }
      setPaginationArchives({
        currentPage: page,
        totalPages: archPagination.totalPages,
        count: archPagination.count,
      });
      setHasMoreArchive(page < archPagination.totalPages);
    } catch (e) {
      console.log(e);
    } finally {
      setLoadingPremiumArchives(false);
    }
  };

  const fetchNextArchives = () => {
    if (!hasMore) return;
    if (pagination.currentPage < pagination.totalPages) {
      fetchArchives(pagination.currentPage + 1);
    }
  };

  const fetchNextPremiumArchives = () => {
    if (!hasMoreArchive) return;
    if (paginationArchives.currentPage < paginationArchives.totalPages) {
      fetchNextPremiumArchives(paginationArchives.currentPage + 1);
    }
  };

  useEffect(() => {
    if (debouncedSearchTerm.length === 0 || debouncedSearchTerm.length >= 3) {
      if (auto) {
        fetchArchives(1, debouncedSearchTerm);
        fetchPremiumArchives(1, debouncedSearchTerm);
      }
    }
  }, [debouncedSearchTerm]);

  return {
    archives,
    setArchives,
    loading,
    pagination,
    setPagination,
    searchValue,
    setSearchValue,
    fetchArchives,
    fetchNextArchives,
    hasMore,
    loadingPremiumArchives,
    setLoadingPremiumArchives,
    premiumArchives,
    setPremiumArchives,
    paginationArchives,
    setPaginationArchives,
    fetchPremiumArchives,
    fetchNextPremiumArchives,
    hasMoreArchive,
  };
};

export default useFetchArchives;
