import { Card, CardBody } from 'reactstrap';
import React from 'react';
import WidgetSourceBlock from '../../WidgetSourceBlock';
import MaterialIcon from '../../../commons/MaterialIcon';
import {
  formatNumberWithDecimalPoints,
  parseCurrency,
} from '../../../../utils/Utils';
import { UIEData } from '../../constants/widgetsConstants';

const ExpenseItem = ({
  item,
  whenPrinting,
  hasRightBorder,
  hasBottomBorder,
}) => {
  const borderClasses = `${hasRightBorder ? 'rpt-border-right' : ''} ${
    hasBottomBorder ? 'rpt-border-bottom' : ''
  }`;
  return (
    <div
      className={`${whenPrinting ? 'py-2 px-4' : 'py-2 px-4'} ${borderClasses}`}
    >
      <div>
        <div
          style={{ height: 32, width: 32 }}
          className={`rounded-circle d-flex align-items-center bg-primary-soft justify-content-center`}
        >
          <MaterialIcon
            size="font-size-xl"
            icon={item.icon}
            clazz="text-primary"
          />
        </div>
        <h6
          className={`mt-2 text-rpt-heading font-weight-bold ${
            whenPrinting ? 'fs-7 mb-0' : 'fs-6 mb-1'
          } mb-0`}
        >
          {item.percentage
            ? `${
                typeof item.value === 'string'
                  ? parseFloat(item.value || '0.00')?.toFixed(2)
                  : item?.value || '0.00'
              }%`
            : `${formatNumberWithDecimalPoints(parseCurrency(item.value), 2)}`}
        </h6>
        <p className="fs-9 mb-0 sub-text text-nowrap">{item.text}</p>
      </div>
    </div>
  );
};

const ExpenseGrid = ({ data = UIEData.expenses, whenPrinting }) => {
  return (
    <div className={`row rounded no-gutters ${whenPrinting ? 'p-2' : 'p-2'}`}>
      {data.map((item, index) => (
        <div
          key={item.id}
          className={`col-md-4 ${index % 3 === 2 ? 'last-col' : ''}`}
        >
          <ExpenseItem
            item={item}
            whenPrinting={whenPrinting}
            hasRightBorder={index % 3 !== 2}
            hasBottomBorder={index < data.length - 3}
          />
        </div>
      ))}
    </div>
  );
};
const UtilizationAndInterestExpenseWidget = ({
  widgetConfig,
  whenPrinting,
}) => {
  const expenses = widgetConfig?.data?.loanOverview?.expenses;
  return (
    <Card className="report-widget">
      <CardBody className="d-flex flex-column w-100">
        <h5 className="text-left d-flex mb-1">
          {widgetConfig.heading || 'Utilization and Interest Expense'}
        </h5>
        <div className="flex-fill">
          <div className="widget-extra-padding">
            <ExpenseGrid data={expenses} whenPrinting={whenPrinting} />
            {widgetConfig.source && (
              <WidgetSourceBlock text={widgetConfig.source} />
            )}
          </div>
        </div>
      </CardBody>
    </Card>
  );
};

export default UtilizationAndInterestExpenseWidget;
