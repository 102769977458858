/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import { Card, CardBody, CardFooter, CardHeader } from 'reactstrap';

import { getTomorrowDate, FormattedValue } from '../../../../utils/Utils';
import GenericTable from '../../../../components/GenericTable';
import { ReportSkeletonLoader } from '../../ReportSkeletonLoader';
import DatePickerTraining from '../../../../components/dealsBreakdown/DatePickerTraining';
import ReportSearchOptionDropdown from '../../../../components/commons/ReportSearchOptionDropdown';
import OpportunitiesStats from './OpportunitiesStats';
import { opportunitiesPerformanceSummaryColumns } from '../../../../utils/opportunities.constants';
import pipelineService from '../../../../services/pipeline.services';
import userService from '../../../../services/user.service';
import {
  changePaginationLimit,
  changePaginationPage,
} from '../../../Deals/contacts/utils';
import { paginationDefault } from '../../../../utils/constants';
import Pagination from '../../../../components/Pagination';
import OverviewBarChart from './OverviewBarChart';
import OverviewLineChart from './OverviewLineChart';
import IdfTooltip from '../../../../components/idfComponents/idfTooltip';
import { StatItemColors } from '../../../../components/reports/reports.constants';
import TableFooterStats from '../../../../components/TableFooterStats';

const StatsData = [
  {
    id: 'pipleline_value',
    icon: 'DollarSign',
    color: StatItemColors.financial,
    iconColor: 'text-rpt-green',
    label: 'Total Pipeline Value',
    value: '6096580',
    type: 'currency',
  },
  {
    id: 'active_deals',
    icon: 'Target',
    color: StatItemColors.volume,
    iconColor: 'text-rpt-blue',
    label: 'Active Deals',
    value: '335',
    type: 'number',
  },
  {
    id: 'win_rate',
    icon: 'Percent',
    color: StatItemColors.performance,
    iconColor: 'text-rpt-green',
    label: 'Average Win Rate',
    value: '55.2',
    type: 'percentage',
  },
  {
    id: 'average_cycle_time',
    icon: 'Clock4',
    color: StatItemColors.time,
    iconColor: 'rpt-orange-box-heading',
    label: 'Avg Cycle Time',
    value: '76',
    type: 'number',
  },
];

const StatsDataFooter = [
  {
    id: 'pipleline_value',
    icon: 'DollarSign',
    color: StatItemColors.financial,
    iconColor: 'text-rpt-green',
    label: 'Total Sales',
    value: '6096580',
    count: 6096580,
    rounded: true,
    type: 'currency',
  },
  {
    id: 'active_deals',
    icon: 'Target',
    color: StatItemColors.volume,
    iconColor: 'text-rpt-blue',
    label: 'No of Deals',
    value: '335',
    count: 335,
    type: 'number',
  },
  {
    id: 'win_rate',
    icon: 'Percent',
    color: StatItemColors.performance,
    iconColor: 'text-rpt-green',
    label: 'Win Rate %',
    value: '55.2',
    count: 55.2,
    type: 'percentage',
  },
];

const TableData = [
  {
    id: 1,
    name: 'Santa Clara',
    sales: 1339100,
    active_deals: 70,
    win_rate: 61.5,
  },
  {
    id: 2,
    name: 'Pete Zachariah',
    sales: 1321100,
    active_deals: 75,
    win_rate: 50,
  },
  {
    id: 3,
    name: 'Roger Terrence',
    sales: 1359000,
    active_deals: 80,
    win_rate: 45.5,
  },
  {
    id: 4,
    name: 'Tina Thomas',
    sales: 1118100,
    active_deals: 60,
    win_rate: 62.5,
  },
  {
    id: 5,
    name: 'Lazar Mathew',
    sales: 865600,
    active_deals: 50,
    win_rate: 55.6,
  },
];

const OpportunitiesPerformanceOverview = () => {
  const [data, setData] = useState(TableData);
  const [loading, setLoading] = useState(false);
  const [order, setOrder] = useState();
  const [pagination, setPagination] = useState(paginationDefault);
  const [paginationPage, setPaginationPage] = useState(paginationDefault);

  const [show, setShow] = useState(false);
  const [pipelines, setPipelines] = useState([]);
  const [pipelineIds, setPipelinesIds] = useState([]);
  const [selectedPipelinesKeys, setSelectedPipelinesKeys] = useState([]);

  const [showUsers, setShowUsers] = useState(false);
  const [usersList, setUsersList] = useState([]);
  const [usersIds, setUserssIds] = useState([]);
  const [selectedUsersKeys, setSelectedUsersKeys] = useState([]);

  const [dateRange, setDateRange] = useState({
    startDate: new Date(1970, 0, 1),
    endDate: getTomorrowDate(),
  });

  const mapMonthlySalesPerformance = [
    ...data?.map((item) => ({
      ...item,
      dataRow: [
        {
          key: 'name',
          component: <span>{item?.name}</span>,
        },
        {
          key: 'sales',
          component: <FormattedValue value={item?.sales} type="currency" />,
        },
        {
          key: 'total_deals',
          component: <FormattedValue value={item?.active_deals} />,
        },
        {
          key: 'win_rate',
          component: (
            <FormattedValue value={item?.win_rate} type="percentage" />
          ),
        },
      ],
    })),
  ];

  const handleSort = (key) => {
    // setOrder([[key?.name?.orderBy, key?.name?.clicked.toLowerCase()]]);
  };

  const getPipelines = async () => {
    const response = await pipelineService.getPipelines(1, 100);
    setPipelines(response?.data);
  };

  const getUsers = async (limit = 100) => {
    const response = await userService.getUsers(
      { status: 'active' },
      { page: 1, limit }
    );

    setUsersList(response?.data?.users);
  };

  useEffect(() => {
    getPipelines();
    getUsers();
  }, []);

  return (
    <Card className="h-100 border-0 shadow-0">
      <CardHeader>
        <div className="d-flex justify-content-between align-items-center w-100">
          <IdfTooltip text="Performance Overview" placement="bottom">
            <h4 className="card-title text-hover-primary mb-0 text-truncate">
              Performance Overview
            </h4>
          </IdfTooltip>
          <div className="d-flex align-items-center mr-6 pr-9 gap-2">
            <DatePickerTraining
              range={dateRange}
              setRange={setDateRange}
              disablePastDate
              btnToggleStyle="mx-0 w-250"
            />

            <ReportSearchOptionDropdown
              search
              maxLength={22}
              show={show}
              data={pipelines}
              setShow={setShow}
              showCheckAll={false}
              label={`Pipelines`}
              setQueryFilters={() => {}}
              scrollable="pr-3 text-left"
              handleOptionSelected={() => {}}
              customStyle={{ width: '250px' }}
              customKey={['name', 'id']}
              selectedData={selectedPipelinesKeys}
              handleAllSelect={() => setPipelinesIds('')}
              setSelectedData={setSelectedPipelinesKeys}
              handleOnDone={(items) => setPipelinesIds(items)}
              controlledTitle={
                pipelines
                  ?.filter((i) => pipelineIds?.includes(i.id))
                  .map((i) => i.name)
                  .join(',') || ''
              }
            />

            <ReportSearchOptionDropdown
              search
              maxLength={22}
              show={showUsers}
              data={usersList}
              setShow={setShowUsers}
              showCheckAll={false}
              label={`Users`}
              setQueryFilters={() => {}}
              scrollable="pr-3 text-left"
              handleOptionSelected={() => {}}
              customStyle={{ width: '220px' }}
              customKey={['name', 'id']}
              selectedData={selectedUsersKeys}
              handleAllSelect={() => setUserssIds('')}
              setSelectedData={setSelectedUsersKeys}
              handleOnDone={(items) => setUserssIds(items)}
              controlledTitle={
                usersList
                  ?.filter((i) => usersIds?.includes(i.id))
                  .map((i) => i.name)
                  .join(',') || ''
              }
            />
          </div>
        </div>
      </CardHeader>
      <CardBody className="p-0 flex-1 overflow-y-auto">
        <div className="px-2 pt-3">
          <OpportunitiesStats stats={StatsData} isLoading={loading} />
        </div>
        {loading ? (
          <div className="w-100 d-block p-1">
            <ReportSkeletonLoader rows={15} height={15} />
          </div>
        ) : (
          <div>
            <div className="d-flex flex-column gap-4 mx-4 my-3">
              <div>
                <h5 className="font-weight-semi-bold fs-6 card-title mb-4">
                  Monthly Sales Performance
                </h5>
                <OverviewLineChart />
              </div>

              <div>
                <h5 className="font-weight-semi-bold fs-6 card-title mb-4">
                  Top 5 Products by Sales
                </h5>
                <OverviewBarChart />
              </div>
            </div>

            <h5 className="font-weight-semi-bold fs-6 card-title mx-4 mb-3">
              Top 5 Sales Performers
            </h5>

            <GenericTable
              checkbox={false}
              exportToCSV={true}
              usePagination={false}
              sortingTable={handleSort}
              tableData={data}
              headClass="bg-gray-table-head border-top"
              fileName="Performance Overview"
              noDataInDbValidation={true}
              columns={opportunitiesPerformanceSummaryColumns}
              columnsToExport={opportunitiesPerformanceSummaryColumns}
              data={mapMonthlySalesPerformance}
            />
          </div>
        )}
      </CardBody>
      <CardFooter className="border-top bg-gray-table-head p-2 border-bottom-0 border-left-0 border-right-0 rounded-0">
        <div className="d-flex align-items-center justify-content-between">
          <ul className="px-3 mb-0 flex-fill">
            <TableFooterStats stats={StatsDataFooter} />
          </ul>
          <Pagination
            showPerPage
            paginationInfo={pagination}
            onPageChange={(newPage) => {
              changePaginationPage(newPage, setPaginationPage);
            }}
            onLimitChange={(perPage) => {
              changePaginationLimit(perPage, setPaginationPage);
            }}
          />
        </div>
      </CardFooter>
    </Card>
  );
};

export default OpportunitiesPerformanceOverview;
