import React, { useState } from 'react';
import Table from '../../../components/GenericTable';
import ButtonFilterDropdown from '../../../components/commons/ButtonFilterDropdown';
import { MetricCard } from '../../../components/chat/MetricCard';
import ButtonIcon from '../../../components/commons/ButtonIcon';

const Users = () => {
  const data = [
    {
      id: 1,
      dataRow: [
        {
          key: 'organization',
          component: <span>Core Bank</span>,
        },
        {
          key: 'type',
          component: <span>Financial Institution</span>,
        },
        {
          key: 'role',
          component: <span>Director, Education</span>,
        },
        {
          key: 'subscription',
          component: <span>Annual</span>,
        },
        {
          key: 'date',
          component: <span>09-15-24</span>,
        },
      ],
    },
    {
      id: 2,
      dataRow: [
        {
          key: 'organization',
          component: <span>Core Bank</span>,
        },
        {
          key: 'type',
          component: <span>Financial Institution</span>,
        },
        {
          key: 'role',
          component: <span>VP, Membership</span>,
        },
        {
          key: 'subscription',
          component: <span>Annual</span>,
        },
        {
          key: 'date',
          component: <span>09-15-24</span>,
        },
      ],
    },
    {
      id: 3,
      dataRow: [
        {
          key: 'organization',
          component: <span>Core Bank</span>,
        },
        {
          key: 'type',
          component: <span>Financial Institution</span>,
        },
        {
          key: 'role',
          component: <span>COO</span>,
        },
        {
          key: 'subscription',
          component: <span>Annual</span>,
        },
        {
          key: 'date',
          component: <span>09-15-24</span>,
        },
      ],
    },
    {
      id: 4,
      dataRow: [
        {
          key: 'organization',
          component: <span>Arvest</span>,
        },
        {
          key: 'type',
          component: <span>Financial Institution</span>,
        },
        {
          key: 'role',
          component: <span>Head of Learning</span>,
        },
        {
          key: 'subscription',
          component: <span>Monthly</span>,
        },
        {
          key: 'date',
          component: <span>09-15-24</span>,
        },
      ],
    },
    {
      id: 5,
      dataRow: [
        {
          key: 'organization',
          component: <span>Payment Solutions Inc.</span>,
        },
        {
          key: 'type',
          component: <span>Third-Party Sender</span>,
        },
        {
          key: 'role',
          component: <span>ACH Operations Manager</span>,
        },
        {
          key: 'subscription',
          component: <span>Annual</span>,
        },
        {
          key: 'date',
          component: <span>02-10-25</span>,
        },
      ],
    },
    {
      id: 6,
      dataRow: [
        {
          key: 'organization',
          component: <span>State Credit Union</span>,
        },
        {
          key: 'type',
          component: <span>Financial Institution</span>,
        },
        {
          key: 'role',
          component: <span>Compliance Officer</span>,
        },
        {
          key: 'subscription',
          component: <span>Annual</span>,
        },
        {
          key: 'date',
          component: <span>02-10-25</span>,
        },
      ],
    },
    {
      id: 7,
      dataRow: [
        {
          key: 'organization',
          component: <span>FinTech Innovators</span>,
        },
        {
          key: 'type',
          component: <span>Technology Provider</span>,
        },
        {
          key: 'role',
          component: <span>Product Manager</span>,
        },
        {
          key: 'subscription',
          component: <span>Monthly</span>,
        },
        {
          key: 'date',
          component: <span>02-18-25</span>,
        },
      ],
    },
    {
      id: 8,
      dataRow: [
        {
          key: 'organization',
          component: <span>Regional Bank Corp</span>,
        },
        {
          key: 'type',
          component: <span>Financial Institution</span>,
        },
        {
          key: 'role',
          component: <span>Treasury Management Head</span>,
        },
        {
          key: 'subscription',
          component: <span>Monthly</span>,
        },
        {
          key: 'date',
          component: <span>02-18-25</span>,
        },
      ],
    },
    {
      id: 8,
      dataRow: [
        {
          key: 'organization',
          component: <span>Payment Solutions Inc.</span>,
        },
        {
          key: 'type',
          component: <span>Technology Provider</span>,
        },
        {
          key: 'role',
          component: <span>Product Manager</span>,
        },
        {
          key: 'subscription',
          component: <span>Monthly</span>,
        },
        {
          key: 'date',
          component: <span>02-18-25</span>,
        },
      ],
    },
    {
      id: 10,
      dataRow: [
        {
          key: 'organization',
          component: <span>FinTech Innovators</span>,
        },
        {
          key: 'type',
          component: <span>Technology Provider</span>,
        },
        {
          key: 'role',
          component: <span>Product Manager</span>,
        },
        {
          key: 'subscription',
          component: <span>Monthly</span>,
        },
        {
          key: 'date',
          component: <span>02-18-25</span>,
        },
      ],
    },
  ];

  const columns = [
    {
      key: 'tenant',
      component: 'Tenant',
      width: '170px',
    },
    {
      key: 'type',
      component: 'Tenant Type',
      width: '170px',
    },
    {
      key: 'role',
      component: 'User Role',
      width: '170px',
    },
    {
      key: 'subscription',
      component: 'Subscription Type',
      width: '170px',
    },
    {
      key: 'date',
      component: 'Start Date',
      width: '170px',
    },
  ];
  const metricData = [
    {
      iconName: 'Building2',
      title: 'Companies (Tenants)',
      value: '8',
      class: 'total-tenants',
    },
    {
      iconName: 'Users',
      title: 'Users',
      value: '135',
      class: 'total-conversations',
    },
    {
      iconName: 'DollarSign',
      title: 'Total Sales',
      value: '$4,500',
      class: 'total-revenue',
    },
    {
      iconName: 'Users',
      title: 'Avg. Users per Tenant',
      value: '17',
      class: 'avg-users-tenant',
    },
  ];
  // Month filter options
  const monthOptions = [
    { key: '2025-01', name: 'January 2025', icon: 'calendar_month' },
    { key: '2025-02', name: 'February 2025', icon: 'calendar_month' },
    { key: '2025-03', name: 'March 2025', icon: 'calendar_month' },
  ];

  // Content filter options
  const contentFilterOptions = [
    { key: 'all', name: 'All Content Access', icon: 'filter_alt' },
    { key: 'nacha_rules', name: 'Nacha Rules', icon: 'article' },
    { key: 'api_docs', name: 'API Docs', icon: 'code' },
    { key: 'billing_info', name: 'Billing Info', icon: 'receipt' },
  ];

  // State for filters
  const [selectedMonth, setSelectedMonth] = useState(monthOptions[2]);
  const [selectedContentFilter, setSelectedContentFilter] = useState(
    contentFilterOptions[0]
  ); // Default to All Content

  const handleMonthSelect = (e, option) => {
    e.preventDefault();
    setSelectedMonth(option);
  };

  const handleContentFilterSelect = (e, option) => {
    e.preventDefault();
    setSelectedContentFilter(option);
  };

  const [paginationInfo] = useState({
    currentPage: 1,
    pageSize: 10,
    totalItems: data.length,
  });

  const [sortingTable] = useState('');
  const [sortingOrder] = useState('asc');
  const [dataInDB] = useState(true);
  const [permission] = useState(true);

  const onPageChange = (page) => {
    console.log('Page changed to:', page);
  };

  return (
    <div className="p-0">
      <header className="d-flex px-3 pt-3 mb-3 align-items-center justify-content-between">
        <h1>Tenant User Log</h1>
        <div className="d-flex align-items-center gap-2">
          <ButtonFilterDropdown
            buttonText="Select Month"
            options={monthOptions}
            handleFilterSelect={handleMonthSelect}
            filterOptionSelected={selectedMonth}
            icon="calendar_month"
            btnToggleStyle="btn-sm"
          />
          <ButtonFilterDropdown
            buttonText="Content Filter"
            options={contentFilterOptions}
            handleFilterSelect={handleContentFilterSelect}
            filterOptionSelected={selectedContentFilter}
            icon="filter_alt"
            btnToggleStyle="btn-sm"
          />
          <ButtonIcon icon="download" classnames="btn-sm" label="Export" />
        </div>
      </header>

      <div className="d-flex px-3 align-items-center gap-3 mb-3">
        {metricData.map((metric, index) => (
          <MetricCard
            key={index}
            iconName={metric.iconName}
            title={metric.title}
            value={metric.value}
            classType={metric.class}
          />
        ))}
      </div>

      <div>
        <Table
          checkbox={false}
          columns={columns}
          headClass="bg-gray-table-head border-top"
          data={data.map((item) => ({ ...item, dataRow: item.dataRow }))}
          paginationInfo={paginationInfo}
          onPageChange={onPageChange}
          emptyDataText="This record doesn't have any Users"
          title="Users"
          permission={permission}
          dataInDB={dataInDB}
          sortingTable={sortingTable}
          sortingOrder={sortingOrder}
          noDataInDbValidation={true}
          usePagination={false}
          actionPadding="h-100"
        />
      </div>
    </div>
  );
};

export default Users;
