import React, { useState, useEffect } from 'react';
import InputValidation from '../commons/InputValidation';
import Asterick from '../commons/Asterick';
import { useForm } from 'react-hook-form';
import { FormCheck } from 'react-bootstrap';
import ButtonIcon from '../commons/ButtonIcon';
import tenantService from '../../services/tenant.service';
import AlertWrapper from '../Alert/AlertWrapper';
import Alert from '../Alert/Alert';
import { FormGroup, Label } from 'reactstrap';

const defaultSmtpConfiguration = {
  SMTP_HOST: '',
  SMTP_PORT: '',
  SMTP_AUTH_USER: '',
  SMTP_AUTH_PASSWORD: '',
  SMTP_FROM: '',
  SMTP_FROM_EMAIL: '',
  SMTP_SECURE: false,
};
const requiredSMTPFields = [
  'SMTP_HOST',
  'SMTP_PORT',
  'SMTP_AUTH_USER',
  'SMTP_AUTH_PASSWORD',
];

const RequiredLabel = ({ label }) => {
  return (
    <Label htmlFor="" className="col-form-label d-flex gap-1">
      <Asterick /> <h5 className="mb-0">{label}</h5>
    </Label>
  );
};

const SMTPConfiguration = ({ tenantId, fromModal, onModalClose }) => {
  const {
    register,
    handleSubmit,
    clearErrors,
    setValue,
    formState: { errors },
  } = useForm({
    defaultValues: defaultSmtpConfiguration,
  });

  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');

  const [smtpConfiguration, setSmtpConfiguration] = useState(
    defaultSmtpConfiguration
  );
  const [savingSMTP, setSavingSMTP] = useState(false);
  const [resetSMTP, setResetSMTP] = useState(false);
  const [loadingSMTP, setLoadingSMTP] = useState(false);

  const transformSmtpConfig = (response) => {
    const smtpConfig = { ...defaultSmtpConfiguration };

    response.forEach((item) => {
      const key = item.config?.name;
      const value = item.value;

      if (key && Object.prototype.hasOwnProperty.call(smtpConfig, key)) {
        if (key === 'SMTP_SECURE') {
          smtpConfig[key] = value === 'true';
        } else if (key === 'SMTP_FROM') {
          const match = value.match(/^(.*)<([^>]+)>$/);
          if (match) {
            smtpConfig.SMTP_FROM = match[1].trim();
            smtpConfig.SMTP_FROM_EMAIL = match[2].trim();
          } else {
            smtpConfig.SMTP_FROM = value;
          }
        } else {
          smtpConfig[key] =
            value !== null && value !== ''
              ? value
              : defaultSmtpConfiguration[key];
        }
      }
    });

    return smtpConfig;
  };

  const getSmtpConfig = async (id) => {
    setLoadingSMTP(true);
    try {
      const response = await tenantService.getTenantConfig(id);
      const smtpConfig = transformSmtpConfig(response);

      setSmtpConfiguration(smtpConfig);

      setValue('SMTP_HOST', smtpConfig.SMTP_HOST || '');
      setValue('SMTP_PORT', smtpConfig.SMTP_PORT || '');
      setValue('SMTP_AUTH_USER', smtpConfig.SMTP_AUTH_USER || '');
      setValue('SMTP_AUTH_PASSWORD', smtpConfig.SMTP_AUTH_PASSWORD || '');
    } catch (error) {
      console.error('failed to fetch SMTP configuration:', error);
    } finally {
      setLoadingSMTP(false);
    }
  };

  useEffect(() => {
    if (tenantId) {
      getSmtpConfig(tenantId);
    }
  }, [tenantId]);

  const handleChangeSMTP = (e) => {
    const { value, name } = e.target;

    setSmtpConfiguration((prevConfig) => ({
      ...prevConfig,
      [name]: value,
    }));
    setValue(name, value);
    clearErrors(name);
  };

  const handleToggleSecure = (e) => {
    const { checked, name } = e.target;
    setSmtpConfiguration((prevConfig) => ({
      ...prevConfig,
      [name]: checked,
    }));
  };

  const saveSMTPConfig = async (smtpConfig) => {
    try {
      if (smtpConfig.SMTP_FROM && smtpConfig.SMTP_FROM_EMAIL) {
        smtpConfig.SMTP_FROM = `${smtpConfig.SMTP_FROM} <${smtpConfig.SMTP_FROM_EMAIL}>`;
      }
      delete smtpConfig.SMTP_FROM_EMAIL;
      const smtpConfigEntries = Object.entries(smtpConfig);
      const requestPayload = smtpConfigEntries.map(([key, value]) => ({
        name: key,
        value: typeof value === 'boolean' ? String(value) : value + '',
      }));
      await tenantService.updateTenantConfig(tenantId, requestPayload);
    } catch (error) {
      console.error('Failed to update SMTP configuration:', error);
    } finally {
      setSavingSMTP(false);
      setLoadingSMTP(false);
      setResetSMTP(false);
    }
  };

  const handleResetSMTPConfig = async () => {
    const smtpConfig = { ...defaultSmtpConfiguration };
    Object.keys(smtpConfig).forEach((key) => {
      smtpConfig[key] = key === 'SMTP_SECURE' ? false : '';
    });
    setResetSMTP(true);
    await saveSMTPConfig(smtpConfig);
    setSmtpConfiguration(defaultSmtpConfiguration);
    requiredSMTPFields.forEach((field) => {
      setValue(field, '');
      clearErrors(field);
    });
    setSuccessMessage('SMTP Configuration Reset.');
  };

  const handleSaveSMTPConfig = async () => {
    setSavingSMTP(true);
    const smtpConfig = {
      ...defaultSmtpConfiguration,
      ...JSON.parse(JSON.stringify(smtpConfiguration)),
    };
    await saveSMTPConfig(smtpConfig);
    setSuccessMessage('SMTP Configuration Saved.');
  };

  if (!tenantId) return null;

  const ModalWrapper = ({ content, footer }) => {
    return (
      <div className="d-flex flex-column h-100">
        <div
          className="cnt flex-1 p-3 overflow-y-hidden"
          style={{ maxHeight: 'calc(100% - 130px)' }}
        >
          {content}
        </div>
        <div className="p-3">{footer}</div>
      </div>
    );
  };

  const Content = () => {
    return (
      <>
        <div className="d-flex gap-1">
          <FormGroup className="flex-1">
            <RequiredLabel labelFor="SMTP_HOST" label="SMTP Host" />
            <InputValidation
              name="SMTP_HOST"
              autocomplete="off"
              placeholder="smtp.example.com"
              value={smtpConfiguration?.SMTP_HOST}
              validationConfig={{
                required: 'SMTP Host is required',
                inline: false,
                onChange: handleChangeSMTP,
              }}
              errors={errors}
              register={register}
              errorDisplay="mb-0"
            />
          </FormGroup>
          <FormGroup className="w-25">
            <RequiredLabel labelFor="SMTP_PORT" label="SMTP Port" />
            <InputValidation
              name="SMTP_PORT"
              placeholder="443"
              autocomplete="off"
              value={smtpConfiguration?.SMTP_PORT}
              validationConfig={{
                required: 'SMTP Port is required',
                inline: false,
                onChange: handleChangeSMTP,
                pattern: /^[0-9]+$/,
              }}
              errorMessage="SMTP Port must be a number"
              errors={errors}
              register={register}
              errorDisplay="mb-0"
            />
          </FormGroup>
        </div>
        <div className="d-flex gap-1">
          <FormGroup className="flex-1">
            <RequiredLabel labelFor="SMTP_AUTH_USER" label="Username" />
            <InputValidation
              name="SMTP_AUTH_USER"
              autocomplete="off"
              placeholder="email@example.com"
              value={smtpConfiguration?.SMTP_AUTH_USER}
              validationConfig={{
                required: 'SMTP Username is required',
                inline: false,
                onChange: handleChangeSMTP,
              }}
              errors={errors}
              register={register}
              errorDisplay="mb-0"
            />
          </FormGroup>
          <FormGroup className="flex-1">
            <RequiredLabel labelFor="SMTP_AUTH_PASSWORD" label="Password" />
            <InputValidation
              name="SMTP_AUTH_PASSWORD"
              fieldType="password"
              autocomplete="off"
              placeholder="Password"
              value={smtpConfiguration?.SMTP_AUTH_PASSWORD}
              validationConfig={{
                required: 'SMTP Password is required',
                onChange: handleChangeSMTP,
              }}
              errors={errors}
              register={register}
              errorDisplay="mb-0"
            />
          </FormGroup>
        </div>
        <FormGroup>
          <Label htmlFor="" className="col-form-label d-flex gap-1">
            <h5 className="mb-0">From Name</h5>
          </Label>
          <InputValidation
            name="SMTP_FROM"
            autocomplete="off"
            placeholder="Company Name"
            value={smtpConfiguration?.SMTP_FROM}
            validationConfig={{
              required: false,
              onChange: handleChangeSMTP,
            }}
            errors={errors}
            register={register}
          />
        </FormGroup>
        <FormGroup>
          <Label htmlFor="" className="col-form-label d-flex gap-1">
            <h5 className="mb-0">From Email Address</h5>
          </Label>
          <InputValidation
            name="SMTP_FROM_EMAIL"
            autocomplete="off"
            placeholder="noreply@company.com"
            value={smtpConfiguration?.SMTP_FROM_EMAIL}
            validationConfig={{
              required: false,
              onChange: handleChangeSMTP,
            }}
            errors={errors}
            register={register}
          />
        </FormGroup>
        <FormGroup className="mb-0 mt-3">
          <h5 className="mb-0">
            <FormCheck
              id="SMTP_SECURE"
              name="SMTP_SECURE"
              type="switch"
              checked={smtpConfiguration?.SMTP_SECURE}
              custom={true}
              label="Use Secure Connection (TLS)"
              onChange={handleToggleSecure}
              className="font-size-sm"
            />
          </h5>
        </FormGroup>
      </>
    );
  };

  const Controls = () => {
    return (
      <FormGroup className="mt-3">
        <div className="d-flex justify-content-end gap-2">
          {fromModal ? (
            <ButtonIcon
              disabled={loadingSMTP}
              label="Close"
              loading={resetSMTP}
              classnames="btn-sm"
              color="white"
              onclick={onModalClose}
            />
          ) : null}
          <ButtonIcon
            disabled={loadingSMTP}
            label="Reset"
            loading={resetSMTP}
            classnames="btn-sm"
            color="white"
            onclick={handleResetSMTPConfig}
          />
          <ButtonIcon
            label="Save Configuration"
            classnames="btn-sm"
            color="primary"
            disabled={loadingSMTP}
            loading={savingSMTP}
            onclick={handleSubmit(handleSaveSMTPConfig)}
          />
        </div>
      </FormGroup>
    );
  };
  return (
    <>
      <AlertWrapper className="alert-position">
        <Alert
          color="danger"
          message={errorMessage}
          setMessage={setErrorMessage}
        />
        <Alert
          color="success"
          message={successMessage}
          setMessage={setSuccessMessage}
        />
      </AlertWrapper>

      {fromModal ? (
        <ModalWrapper content={<Content />} footer={<Controls />} />
      ) : (
        <>
          <Content />
          <Controls />
        </>
      )}
    </>
  );
};

export default SMTPConfiguration;
