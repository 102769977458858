import axios from './axiosInstance';
import authHeader from './auth-header';
import BaseRequestService from './baseRequest.service';

class DealService extends BaseRequestService {
  async getDeals(queryFilter, { page = 1, limit = 10 }, search) {
    const { filter, ...restProps } = queryFilter || {};

    const params = {
      ...restProps,
      ...filter,
      page,
      limit,
      search,
    };

    return await this.get(
      `/deals`,
      {
        params,
        headers: authHeader(),
      },
      { fullResponse: true }
    );
  }

  async getDealById(id) {
    return this.get(
      `/deals/${id}`,
      {
        headers: authHeader(),
      },
      {}
    );
  }

  async createDeal(data) {
    return await this.post(
      `/deals`,
      data,
      { headers: authHeader() },
      { fullResponse: true }
    );
  }

  async deleteDeal(id) {
    return this.delete(
      `/deals/${id}`,
      { headers: authHeader() },
      { errorsRedirect: true, fullResponse: true }
    );
  }

  async updateDeal(id, data) {
    return await this.put(
      `/deals/${id}`,
      data,
      { headers: authHeader() },
      { fullResponse: true }
    );
  }

  async updateDealProducts(dealId, data) {
    return axios.put(`/deals/${dealId}/products`, data, {
      headers: authHeader(),
    });
  }

  deleteDealProduct(dealProductId) {
    return axios.delete(`/deals/products/${dealProductId}`, {
      headers: authHeader(),
    });
  }

  async getOwners(deal_id, { page = 1, limit = 5 }) {
    return await this.get(`/deals/${deal_id}/owners`, {
      headers: authHeader(),
      params: {
        page,
        limit,
      },
    });
  }

  async getFollowers(deal_id, { page = 1, limit = 5 }) {
    return await this.get(`/deals/${deal_id}/followers`, {
      headers: authHeader(),
      params: {
        page,
        limit,
      },
    });
  }

  addOwner(deal_id, user_id) {
    return axios
      .post(
        `/deals/${deal_id}/owners/${user_id}`,
        {},
        {
          headers: authHeader(),
        }
      )
      .then((response) => response.data);
  }

  async removeOwner(dealId, userId) {
    return await axios.delete(`/deals/${dealId}/owners/${userId}`, {
      headers: authHeader(),
    });
  }

  async checkFollowing(deal_id, user_id) {
    const result = await this.get(`/deals/${deal_id}/followers/${user_id}`, {
      headers: authHeader(),
    });

    return result?.isFollower;
  }

  async stopFollowing(deal_id, user_id) {
    return await this.delete(
      `/deals/${deal_id}/followers/${user_id}`,
      { headers: authHeader() },
      { fullResponse: true }
    );
  }

  async startFollowing(deal_id, user_id) {
    return await this.post(
      `/deals/${deal_id}/followers/${user_id}`,
      {},
      { headers: authHeader() },
      { fullResponse: true }
    );
  }

  async updateDealPosition(id, data) {
    return await this.put(
      `/deals/${id}/position`,
      data,
      { headers: authHeader() },
      { fullResponse: true }
    );
  }

  async getStatusSummary(pipelineId) {
    return await this.post(
      `/deals/aggregate/count/byPipelineStageAndResolution`,
      { pipelineId },
      { headers: authHeader() },
      { fullResponse: true }
    );
  }

  async getDealProducts(dealId, { page = 1, limit = 10 }) {
    return await this.get(`/deals/${dealId}/products`, {
      headers: authHeader(),
      params: {
        page,
        limit,
      },
    });
  }

  async getDealStageHistory(dealId, { page = 1, limit = 10 }) {
    return await this.get(`/deals/${dealId}/history/stages`, {
      headers: authHeader(),
      params: {
        page,
        limit,
      },
    });
  }

  updateCustomField(fieldId, data) {
    return this.put(`/deals/${fieldId}/fields`, data, {
      headers: authHeader(),
    });
  }

  getCustomField(contactId, { page, limit }) {
    const params = {
      page,
      limit,
    };
    return axios.get(`/deals/${contactId}/fields`, {
      params,
      headers: authHeader(),
    });
  }

  deleteCustomField(contactId, fieldId) {
    return this.delete(`/deals/${contactId}/fields/${fieldId}`, {
      headers: authHeader(),
    })
      .then((response) => {
        return response.data;
      })
      .catch((error) => error);
  }

  getDealsByUser(start, end, limit, page) {
    return this.post(
      '/deals/aggregate/byUser',
      {
        range: {
          start,
          end,
        },
        limit,
        page,
      },
      {
        headers: authHeader(),
      }
    );
  }
}

export default new DealService();
