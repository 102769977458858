import React, { useState, useEffect, useRef } from 'react';
import MaterialIcon from './MaterialIcon';
import { Accordion } from 'react-bootstrap';

const MenuAccordion = ({
  options,
  className = 'list-unstyled mb-0',
  wrapperClass = '',
  showCount = false,
  conditionalCount = {},
  defaultCollapsedIds = [],
  trackActiveKeys = false,
  specificWrapperClassByIds = [],
  specificWrapperClass = '',
  toggle,
}) => {
  const [activeKey, setActiveKey] = useState(
    options
      ?.filter((item) => !defaultCollapsedIds.includes(item.id))
      .map((item) => item.id)
  );

  const prevOptionsLength = useRef(options?.length);

  useEffect(() => {
    if (trackActiveKeys) {
      if (options?.length > prevOptionsLength.current) {
        // new options have been added, check which ones are not in defaultCollapsedIds
        const newIds = options
          .filter(
            (item) =>
              !defaultCollapsedIds.includes(item.id) &&
              !activeKey.includes(item.id)
          )
          .map((item) => item.id);
        setActiveKey((prevActiveKey) => [...prevActiveKey, ...newIds]);
      }
      prevOptionsLength.current = options?.length;
    }
  }, [options, defaultCollapsedIds, activeKey, trackActiveKeys]);

  const toggleAccordion = (id) => {
    setActiveKey((prevActiveKey) =>
      prevActiveKey.includes(id)
        ? prevActiveKey.filter((key) => key !== id)
        : [...prevActiveKey, id]
    );
  };

  const toggleCollapse = () => {
    toggle && toggle(); // for extra onClick functionality
  };

  return (
    <>
      <ul className={className}>
        {options?.map((item, index) => {
          const isCollapsed = !activeKey.includes(item.id);
          const isShowCount =
            showCount &&
            item.count &&
            (conditionalCount[item.id] ? isCollapsed : true);

          return (
            <li
              key={index}
              className={`accordion-wrapper ${wrapperClass} ${
                specificWrapperClassByIds.includes(item.id)
                  ? specificWrapperClass
                  : ''
              }`}
            >
              <Accordion
                activeKey={activeKey?.includes(item.id) ? item.id : null}
              >
                <Accordion.Toggle
                  onClick={() => toggleAccordion(item.id)}
                  eventKey={item.id}
                  className={`${
                    activeKey?.includes(item.id)
                      ? 'bg-accordion-active rounded-top rounded-bottom-0'
                      : 'bg-white'
                  }  accordion-toggle nav-link px-3 py-2 border-bottom cursor-pointer font-size-sm2 font-weight-medium text-black text-capitalize`}
                >
                  <div className="d-flex align-items-center">
                    <MaterialIcon
                      size="fs-20"
                      icon={item.icon}
                      clazz={item.iconClass}
                    />
                    <span className="ml-2 text-capitalize py-0 accordian-label">
                      {item.label}
                    </span>
                    {isShowCount ? (
                      <div
                        className={`ml-2 d-flex justify-content-center bg-primary align-items-center rounded-circle size-18`}
                      >
                        <span className={`fs-8 font-weight-normal text-white`}>
                          {item.count}
                        </span>
                      </div>
                    ) : null}
                    <MaterialIcon
                      clazz="ml-auto accordian-arrow-icon"
                      icon={
                        activeKey.includes(item.id)
                          ? 'keyboard_arrow_up'
                          : 'keyboard_arrow_down'
                      }
                    />
                  </div>
                </Accordion.Toggle>
                <Accordion.Collapse
                  eventKey={item.id}
                  className="accordion-content-wrapper"
                >
                  {activeKey?.includes(item.id) ? (
                    <div
                      className="py-0 overflow-x-hidden"
                      onClick={toggleCollapse}
                    >
                      {item.component}
                    </div>
                  ) : (
                    <div />
                  )}
                </Accordion.Collapse>
              </Accordion>
            </li>
          );
        })}
      </ul>
    </>
  );
};

export default MenuAccordion;
