import React, { useState } from 'react';
import { DateRangePicker } from 'react-date-range';
import { Dropdown } from 'react-bootstrap';
import LucideIcon from '../commons/LucideIcon';

const DatePickerTraining = ({
  range,
  setRange,
  btnToggleStyle = 'mr-2',
  toggle = () => {},
  months = 1,
  direction = 'horizontal',
  defaultStartDate = new Date(1970, 0, 1),
}) => {
  const [open, setOpen] = useState(false);
  const [tempRange, setTempRange] = useState(range);

  const handleToggle = (isOpen) => {
    setOpen(isOpen);
    if (isOpen) {
      setTempRange(range);
    } else {
      setRange(tempRange);
    }
  };

  const isDefaultDate =
    !range || range?.startDate?.getTime() === defaultStartDate.getTime();

  return (
    <>
      <div className="d-flex align-items-center gap-2">
        <Dropdown show={open} onToggle={handleToggle}>
          <Dropdown.Toggle
            variant="white"
            className={`btn btn-white font-size-sm dropdown-toggle ${btnToggleStyle}`}
            id="dropdown-basic"
          >
            <div className="d-flex align-items-center text-left w-100">
              <LucideIcon icon="Calendar" size={16} />
              <p className="ml-1 text-truncate text-capitalize mb-0 w-100">
                {isDefaultDate
                  ? 'All Time'
                  : `${range?.startDate?.toLocaleDateString('en-US', {
                      month: 'short',
                      day: '2-digit',
                      year: 'numeric',
                    })} - ${range?.endDate?.toLocaleDateString('en-US', {
                      month: 'short',
                      day: '2-digit',
                      year: 'numeric',
                    })}`}
              </p>
            </div>
          </Dropdown.Toggle>
          <Dropdown.Menu style={{ zIndex: 250 }} className="p-0 py-1 min-w-170">
            <DateRangePicker
              onChange={(item) => setRange(item.selection)}
              showSelectionPreview
              moveRangeOnFirstSelection={false}
              months={months}
              direction={direction}
              ranges={[
                { ...range, key: 'selection', color: 'var(--secondaryColor)' },
              ]}
            />

            <hr style={{ marginTop: 0, paddingTop: 0 }} />
            <div className="drp-buttons mb-3">
              <button
                className="cancelBtn btn btn-sm btn-white"
                type="button"
                onClick={() => {
                  setRange(tempRange);
                  setOpen(false);
                }}
              >
                Cancel
              </button>
              <button
                className="applyBtn btn btn-sm btn-primary"
                type="button"
                onClick={() => {
                  toggle();
                  setOpen(false);
                }}
              >
                Apply
              </button>
            </div>
          </Dropdown.Menu>
        </Dropdown>
      </div>
    </>
  );
};

export default DatePickerTraining;
