import { useEffect, useState } from 'react';

import IdfAdditionalOwnersListDropdown from './IdfAdditionalOwnersListDropdown';
import IdfAddOwner from './IdfAddOwner';
import IdfListAvatars from './IdfListAvatars';
import './IdfAdditionalOwners.css';
import { isPermissionAllowed } from '../../../utils/Utils';
import TooltipComponent from '../../lesson/Tooltip';

const IdfAdditionalOwnersListAvatars = ({
  service,
  serviceId,
  maxOwners = 2,
  mainOwner,
  refreshOwners,
  defaultSize = 'sm',
  withoutRequest = false,
  listOwners = [],
  setRefresOwners,
  activity,
  isprincipalowner: isPrincipalOwner,
  preowners: preOwners,
  small,
  ownerData,
  prevalue,
  setPreOwners = () => {},
  allowDelete = false,
  isClickable = true,
  ownerOption,
  type = {},
  setOwnerData,
  addBtnStyles = 'btn-ghost-primary',
  width = 32,
  height = 32,
  animateOnHover,
  ...props
}) => {
  const [owners, setOwners] = useState(listOwners);
  const [count, setCount] = useState(0);
  const [openAddOwner, setOpenAddOwner] = useState(false);

  useEffect(() => {
    if (prevalue && preOwners) {
      setCount(preOwners?.length || owners?.length);
      setOwners(preOwners);
    }
  }, [preOwners]);

  useEffect(() => {
    if (serviceId) onGetOwners();
    else setCount(listOwners?.length);
  }, [serviceId, refreshOwners]);
  const ownersService = async (pagination) => {
    return await service
      .getOwners(serviceId, pagination)
      .catch((err) => console.log(err));
  };

  const onGetOwners = async () => {
    const resp = await ownersService({ page: 1, limit: 20 });
    const { data } = resp || {};
    const filteredData = data?.filter((item) => {
      return item?.user?.status !== 'deactivated';
    });
    setOwners(filteredData);
    setCount(filteredData?.length);
  };

  const onAddPerson = (e) => {
    e.preventDefault();
    setOpenAddOwner(true);
    e.stopPropagation();
  };

  const onSetPreOwners = (values) => {
    setOwners(values);
    setPreOwners(values);
    setCount(values.length);
  };

  return (
    <>
      <IdfListAvatars
        users={owners}
        setUsers={onSetPreOwners}
        property="user"
        defaultSize={defaultSize}
        sizeIcon={`${props.sizeIcon} avatar-secondary`}
        isClickable={isClickable}
        maxUsers={maxOwners}
        allowDelete={allowDelete}
        onClick={props.onClick}
        mainOwner={mainOwner}
        width={width}
        height={height}
        animateOnHover={animateOnHover}
      />
      {count > maxOwners && (
        <IdfAdditionalOwnersListDropdown
          service={service}
          serviceId={serviceId}
          maxOwners={maxOwners}
          mainOwner={mainOwner}
          refreshOwners={refreshOwners}
          defaultSize={defaultSize}
          withoutRequest={withoutRequest}
          setRefresOwners={setRefresOwners}
          prevalue={prevalue}
          prevalueCount={count}
          animateOnHover={animateOnHover}
          preOwners={preOwners}
        />
      )}
      {isPermissionAllowed(type?.collection, type?.action) &&
      !withoutRequest ? (
        <IdfAddOwner
          openModal={openAddOwner}
          ownerOption={ownerOption}
          setOwnerData={setOwnerData}
          setOpenModal={setOpenAddOwner}
          service={service}
          activity={activity}
          ownerData={ownerData}
          serviceId={serviceId}
          onGetOwners={onGetOwners}
          mainOwner={mainOwner}
          setRefresOwners={setRefresOwners}
          owners={owners}
          setOwners={setOwners}
          onSetPreOwners={onSetPreOwners}
          prevalue={prevalue}
          {...props}
        >
          <div className="ml-2 add-owner">
            <TooltipComponent title="Add Owner">
              <button
                ref={(ref) => {
                  if (!ref) return;
                  ref.onclick = (e) => {
                    e.stopPropagation();
                    onAddPerson(e);
                  };
                }}
                className={`btn btn-icon rounded-circle bg-gray-2 ${addBtnStyles} bg-gray-3`}
                style={{
                  width,
                  height,
                }}
              >
                <i className="material-symbols-rounded fs-5">add</i>
              </button>
            </TooltipComponent>
          </div>
        </IdfAddOwner>
      ) : null}
    </>
  );
};

export default IdfAdditionalOwnersListAvatars;
