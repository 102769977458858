import moment from 'moment';
import Skeleton from 'react-loading-skeleton';
import { ProgressBar } from 'react-bootstrap';
import { Card, CardHeader } from 'reactstrap';
import React, { useState, useEffect } from 'react';

import Stats from './ChecklistsStats';
import StatsInfoCard from './StatsInfoCard';
import ChecklistsSplit from './ChecklistsSplit';
import { cubeService } from '../../../../services';
import { DATE_FORMAT } from '../../../../utils/Utils';
import GenericTable from '../../../../components/GenericTable';
import { ReportSkeletonLoader } from '../../ReportSkeletonLoader';
import ChecklistStatus from '../../../../components/checklist/ChecklistStatus';
import {
  checklistsOverviewColumns,
  statusText,
} from '../../../../utils/checklist.constants';

const ChecklistsOverview = () => {
  const [order, setOrder] = useState();
  const [split, setSplit] = useState(null);
  const [leader, setLeader] = useState('--');
  const [status, setStatus] = useState(null);
  const [loading, setLoading] = useState(false);
  const [checklists, setChecklists] = useState([]);
  const [loadingStats, setLoadingStats] = useState(false);
  const [avgCompletionTime, setAvgCompletionTime] = useState(null);
  const [fastestCompletionTime, setFastestCompletionTime] = useState(null);

  const mapChecklists = (item, i) => {
    let status = 'pending';
    if (item?.avgProgress === 100) {
      status = 'completed';
    } else if (item?.avgProgress > 0) {
      status = 'inProgress';
    }

    return {
      id: i,
      dataRow: [
        {
          key: 'CHECKLIST',
          component: <span className="fw-bold">{item?.title}</span>,
        },
        {
          key: 'ASSIGNED',
          component: `${item?.assignedCompaniesCount || 0} Companies`,
        },
        {
          key: 'STATUS',
          component: (
            <ChecklistStatus
              item={{
                status: statusText[status] || 'Pending',
                statusText: statusText[status] || 'Not Started',
              }}
            />
          ),
        },
        {
          key: 'LAST UPDATE',
          component: moment(item?.lastUpdate).format(DATE_FORMAT),
        },
        {
          key: 'PROGRESS',
          component: (
            <div
              className="d-flex align-items-center gap-1"
              style={{ width: '87%' }}
            >
              <ProgressBar
                style={{ height: 8, width: '85%' }}
                now={Math.round(item?.avgProgress || 0)}
                max={100}
                className={'progress-bar-2 checklist-progress rounded'}
              />{' '}
              <span style={{ width: '15%' }}>
                {Math.round(item?.avgProgress || 0)}%
              </span>
            </div>
          ),
        },
      ],
    };
  };

  const getChecklistsDetails = async () => {
    setLoading(true);
    const result = await cubeService.loadCustomAnalytics({
      type: 'ChecklistsDetails',
      orderBy: order,
    });

    setChecklists(result);
    const fastestCompletion = result.reduce((prev, curr) => {
      if (!curr?.fastestCompletionTime) {
        return prev;
      }

      if (curr?.fastestCompletionTime < prev) {
        return curr?.fastestCompletionTime;
      }

      return prev;
    }, Number.MAX_SAFE_INTEGER);

    // miliseconds in a day: 86400000
    setFastestCompletionTime(
      isNaN(fastestCompletion) ? 0 : Math.ceil(fastestCompletion / 86400000)
    );

    setLoading(false);
  };

  const getChecklistsStats = async () => {
    setLoadingStats(true);
    const [averageTimeToComplete, leaders, statuses, overdueStats, types] =
      await Promise.all([
        cubeService.loadCustomAnalytics({
          type: 'ChecklistsAverageTimeToComplete',
          startDate: moment().subtract(30, 'days'),
          endDate: moment(),
        }),
        cubeService.loadCustomAnalytics({
          type: 'ChecklistsLeaders',
          startDate: moment().subtract(30, 'days'),
          endDate: moment(),
          limit: 1,
        }),
        cubeService.loadCustomAnalytics({
          type: 'ChecklistsStatus',
        }),
        cubeService.loadCustomAnalytics({
          type: 'ChecklistsOverdue',
        }),
        cubeService.loadCustomAnalytics({
          type: 'ChecklistsTypes',
        }),
      ]);

    const avgMs = averageTimeToComplete?.[0]?.avgCompletionTime || 0;
    const avgDays = isNaN(avgMs) || !avgMs ? 0 : Math.floor(avgMs / 86400000);
    setAvgCompletionTime(avgDays === 1 ? '1 day' : `${avgDays} days`);

    const lead = leaders?.[0];

    if (lead && lead.length) {
      setLeader(
        `${lead?.firstName} ${lead?.lastName} (${
          lead?.completedChecklists
        } checklist${lead?.completedChecklists === '1' ? '' : 's'} completed)`
      );
    }

    const overdue = Number(overdueStats?.[0]?.count || 0);

    if (statuses.length) {
      setStatus({
        overdue,
        ...statuses.reduce(
          (prev, curr) => ({ ...prev, [curr.status]: Number(curr.count || 0) }),
          {}
        ),
      });
    }

    const internal = Number(types[0]?.count) || 0;
    const external = Number(types[1]?.count) || 0;

    const total = internal + external;

    const percentages =
      total > 0
        ? {
            internalPercentage: Math.floor((internal * 100) / total),
            externalPercentage: Math.round((external * 100) / total),
          }
        : { internalPercentage: 0, externalPercentage: 0 };

    setSplit(percentages);

    setLoadingStats(false);
  };

  const handleSort = (key) => {
    setOrder(`"${key?.name?.orderBy}" ${key?.name?.clicked?.toLowerCase()}`);
  };

  useEffect(() => {
    getChecklistsDetails();
  }, [order]);

  useEffect(() => {
    getChecklistsStats();
  }, []);

  return (
    <Card className={`overflow-x-hidden overflow-y-auto`}>
      <CardHeader>
        <div className="d-flex justify-content-between align-items-start w-100">
          <h4 className="card-title text-hover-primary mb-0 pt-2 pb-2">
            Checklists Overview
          </h4>
          <p className="mb-0 fw-bold pt-2 pb-2">
            {loading ? (
              <Skeleton />
            ) : (
              <>
                Total Active Checklists: <span>{checklists.length}</span>
              </>
            )}
          </p>
        </div>
      </CardHeader>
      <div className="py-3 px-2">
        <Stats
          {...{
            ...status,
            totalCount:
              status?.pending + status?.inProgress + status?.completed,
            className: 'px-1 pb-2',
            isLoading: loadingStats,
          }}
        />
        <ChecklistsSplit {...{ ...split, isLoading: loadingStats }} />
        <StatsInfoCard
          value={avgCompletionTime}
          isLoading={loadingStats}
          title="Average Completion Time (Last 30 days)"
        />
        <StatsInfoCard
          value={
            typeof fastestCompletionTime === 'number' &&
            !isNaN(fastestCompletionTime)
              ? fastestCompletionTime === 1
                ? `${fastestCompletionTime} day`
                : `${fastestCompletionTime} days`
              : '--'
          }
          isLoading={loadingStats}
          materialIcon="gauge"
          title="Fastest Completion Time (All time)"
          materialIconClazz="text-AInstituteSecondary"
        />
        <StatsInfoCard
          value={leader}
          materialIcon="person"
          isLoading={loadingStats}
          title="Checklists Leader (Last 30 days)"
          materialIconClazz="rpt-orange-box-heading"
        />
        <h4 className="text-hover-primary mb-0 pt-4 pb-2 px-4">
          Active Checklists
        </h4>
        {loading ? (
          <div className="w-100 d-block p-1">
            <ReportSkeletonLoader rows={30} height={15} />
          </div>
        ) : (
          <GenericTable
            checkbox={false}
            data={checklists.map(mapChecklists)}
            exportToCSV={false}
            columns={checklistsOverviewColumns}
            usePagination={false}
            noDataInDbValidation={true}
            sortingTable={handleSort}
          />
        )}
      </div>
    </Card>
  );
};

export default ChecklistsOverview;
