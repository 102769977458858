import React, { Fragment } from 'react';
import { PROSPECT_RIGHT_PANEL_WIDTH } from '../../../utils/Utils';
import RightPanelModal from '../../../components/modal/RightPanelModal';
import MaterialIcon from '../../../components/commons/MaterialIcon';
import { Card, CardBody } from 'reactstrap';
import { ProgressBar } from 'react-bootstrap';
import {
  ComposedChart,
  Line,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Label,
} from 'recharts';
import TopicIcon from '../../../components/commons/TopicIcon';

const transactions = [
  {
    id: '1',
    title: 'Transactions Summary',
    subTitle: 'Unmonetized Transactions',
    total: '$963,745,406',
    reports: [
      {
        id: '11',
        title: 'Merchant Services',
        amount: '$539,288,349',
        percentage: '56%',
        color: 'blue',
      },
      {
        id: '12',
        title: 'Corporate Credit Cards',
        amount: '$214,754,178',
        percentage: '22%',
        color: 'purple',
      },
      {
        id: '13',
        title: 'Commercial Loan Refinance',
        amount: '$91,844,622',
        percentage: '10%',
        color: 'black',
      },
      {
        id: '14',
        title: 'Consumer Credit Cards',
        amount: '$24,311,229',
        percentage: '3%',
        color: 'orange',
      },
      {
        id: '15',
        title: 'Others',
        amount: '$93,546,968',
        percentage: '9%',
        color: 'red',
      },
    ],
  },
  {
    id: '2',
    title: 'Target Audiences',
    subTitle: 'Target Accounts',
    total: '8,508',
    reports: [
      {
        id: '21',
        title: 'Commercial Lending',
        amount: '2,277',
        percentage: '27%',
        color: 'blue',
      },
      {
        id: '22',
        title: 'Merchant Services',
        amount: '893',
        percentage: '10%',
        color: 'purple',
      },
      {
        id: '23',
        title: 'Corporate Payroll Expense',
        amount: '859',
        percentage: '10%',
        color: 'black',
      },
      {
        id: '24',
        title: 'Commercial Loan Refinance',
        amount: '713',
        percentage: '8%',
        color: 'orange',
      },
      {
        id: '25',
        title: 'Others',
        amount: '3,766',
        percentage: '45%',
        color: 'red',
      },
    ],
  },
];

const targetAudiences = [
  {
    id: '1',
    title: 'Transactions Summary',
    subTitle: 'Unmonetized Transactions',
    total: '$96,374,540',
    reports: [
      {
        id: '11',
        title: 'Merchant Services',
        amount: '$53,928,835',
        percentage: '56%',
        color: 'blue',
      },
      {
        id: '12',
        title: 'Corporate Credit Cards',
        amount: '$21,475,418',
        percentage: '22%',
        color: 'purple',
      },
      {
        id: '13',
        title: 'Commercial Loan Refinance',
        amount: '$9,184,462',
        percentage: '10%',
        color: 'black',
      },
      {
        id: '14',
        title: 'Consumer Credit Cards',
        amount: '$2,431,123',
        percentage: '3%',
        color: 'orange',
      },
      {
        id: '15',
        title: 'Others',
        amount: '$9,354,697',
        percentage: '9%',
        color: 'red',
      },
    ],
  },
  {
    id: '2',
    title: 'Target Audiences',
    subTitle: 'Target Accounts',
    total: '851',
    reports: [
      {
        id: '21',
        title: 'Commercial Lending',
        amount: '228',
        percentage: '27%',
        color: 'blue',
      },
      {
        id: '22',
        title: 'Merchant Services',
        amount: '89',
        percentage: '10%',
        color: 'purple',
      },
      {
        id: '23',
        title: 'Corporate Payroll Expense',
        amount: '86',
        percentage: '10%',
        color: 'black',
      },
      {
        id: '24',
        title: 'Commercial Loan Refinance',
        amount: '71',
        percentage: '8%',
        color: 'orange',
      },
      {
        id: '25',
        title: 'Others',
        amount: '377',
        percentage: '45%',
        color: 'red',
      },
    ],
  },
];

const opportunitiesChartData = [
  {
    name: 'Merchant Services',
    Transactions: 400,
    Customers: 20,
  },
  {
    name: 'Deposit Leakage',
    Transactions: 170,
    Customers: 30,
  },
  {
    name: 'Investment Management',
    Transactions: 120,
    Customers: 40,
  },
  {
    name: 'Credit Cards',
    Transactions: 110,
    Customers: 60,
  },
  {
    name: 'Others',
    Transactions: 90,
    Customers: 80,
  },
];

const CustomXAxisTick = ({ x, y, payload }) => {
  const words = payload.value.split(' ');
  return (
    <g transform={`translate(${x + 25},${y + 10})`}>
      {words.map((word, index) => (
        <text
          key={index}
          x={0}
          y={index * 12} // Adjust spacing between lines
          textAnchor="middle"
          fill="#0A0A0A"
          fontSize={9}
        >
          {word}
        </text>
      ))}
    </g>
  );
};
const DemoBankInsights = ({
  children,
  showModal,
  setShowModal,
  detailPage = false,
}) => {
  const onClose = () => {
    setShowModal(false);
  };

  const data = detailPage ? targetAudiences : transactions;

  return (
    <>
      {children}
      <RightPanelModal
        showModal={showModal}
        setShowModal={onClose}
        showOverlay={!detailPage}
        containerPosition={!detailPage ? 'position-fixed' : 'position-absolute'}
        containerWidth={PROSPECT_RIGHT_PANEL_WIDTH}
        containerBgColor={!detailPage ? 'bg-gray-5' : 'bg-white'}
        headerBgColor="px-3"
        Title={
          <div className="d-flex py-2 align-items-center gap-2 scroll-item">
            <div className="nav-item scroll-item">
              <div
                className={`nav-icon p-2 rounded-circle insights cursor-pointer`}
              >
                <TopicIcon
                  icon="search_insights"
                  iconBg="insights"
                  filled={true}
                  iconSize="fs-5"
                />
              </div>
            </div>
            <p className="mb-0 text-black">Insights: Product Needs and Usage</p>
          </div>
        }
      >
        <div className="p-3 d-flex flex-column gap-3 text-left">
          <Fragment>
            {data?.map((item) => (
              <div key={item.id} className="w-100">
                <Card>
                  <CardBody className="p-2_2">
                    <h4 className="mb-2_1 fs-6 font-weight-medium">
                      {item.title}
                    </h4>
                    <p className="mb-0 fs-6">{item.total}</p>
                    <p className="mb-2_1 fs-7 text-muted">{item.subTitle}</p>

                    <div className="d-flex gap-1 mb-3 w-100">
                      {item.reports.map((report) => (
                        <ProgressBar
                          key={report.id}
                          now={100}
                          className="rounded"
                          variant={report.color}
                          style={{ width: report.percentage }}
                        />
                      ))}
                    </div>

                    <div className="d-flex flex-column gap-2">
                      {item.reports.map((report) => (
                        <div
                          key={report.id}
                          className="d-flex align-items-center justify-content-between"
                        >
                          <div className="d-flex align-items-center">
                            <MaterialIcon
                              icon="fiber_manual_record"
                              size="fs-3"
                              style={{ color: report.color }}
                            />
                            <p className="mb-0 pl-1 fs-7 font-weight-medium">
                              {report.title}
                            </p>
                          </div>
                          <div className="d-flex align-items-center gap-3">
                            <p className="mb-0 fs-7 font-weight-medium text-right">
                              {report.amount}
                            </p>
                            <p className="mb-0 fs-7 text-muted min-w-30 text-right">
                              {report.percentage}
                            </p>
                          </div>
                        </div>
                      ))}
                    </div>
                  </CardBody>
                </Card>
              </div>
            ))}
          </Fragment>
          <div className="w-100">
            <Card>
              <CardBody className="p-2_2">
                <h4 className="mb-2_1 fs-6 font-weight-medium">
                  Opportunities Categories
                </h4>

                <div className="mt-3 w-100">
                  <ComposedChart
                    width={415}
                    height={220}
                    margin={{ top: 20, right: 20, left: 20, bottom: 40 }}
                    data={opportunitiesChartData}
                  >
                    <CartesianGrid
                      stroke="#f5f5f5"
                      horizontal={true}
                      vertical={false}
                      strokeDasharray="3 3"
                    />
                    <XAxis
                      dataKey="name"
                      scale="band"
                      interval={0}
                      axisLine={false}
                      tickLine={false}
                      tick={<CustomXAxisTick />}
                    />

                    <YAxis
                      axisLine={false}
                      tickLine={false}
                      tick={{ fill: '#0A0A0A', fontSize: 11 }}
                      tickFormatter={(value) =>
                        value === 0 ? value : `${value} M`
                      }
                    >
                      <Label
                        value="Transaction Volume"
                        offset={-8}
                        angle={-90}
                        position="insideLeft"
                        style={{
                          fill: '#0A0A0A',
                          fontSize: 11,
                          textAnchor: 'middle',
                        }}
                      />
                    </YAxis>

                    <YAxis
                      axisLine={false}
                      tickLine={false}
                      tick={{ fill: '#0A0A0A', fontSize: 11 }}
                      tickFormatter={(value) =>
                        value === 0 ? value : `${value}`
                      }
                      orientation="right"
                      yAxisId="right"
                    >
                      <Label
                        value="Customers Count"
                        offset={15}
                        angle={90}
                        position="insideRight"
                        style={{
                          fill: '#0A0A0A',
                          fontSize: 11,
                          textAnchor: 'middle',
                        }}
                      />
                    </YAxis>

                    <Tooltip
                      formatter={(value, name) => {
                        if (name === 'Transactions') {
                          return [`${value}M`, 'Transactions'];
                        }
                        return [value, name];
                      }}
                      contentStyle={{
                        fontSize: 11,
                      }}
                    />
                    <Bar
                      dataKey="Transactions"
                      radius={[5, 5, 0, 0]}
                      barSize={30}
                      fill="#02336c"
                    />
                    <Line
                      type="monotone"
                      dataKey="Customers"
                      stroke="#1a5a28"
                      strokeWidth={2}
                      yAxisId="right"
                    />
                  </ComposedChart>
                </div>
              </CardBody>
            </Card>
          </div>
        </div>
      </RightPanelModal>
    </>
  );
};

export default DemoBankInsights;
