import React from 'react';
import { Card, Row } from 'react-bootstrap';
import { FormattedValue, sortObjectKeys } from '../../../utils/Utils';
import ChartDonutWidget from '../../reportbuilder/widgets/ChartDonutWidget';
import TextOverflowTooltip from '../../commons/TextOverflowTooltip';
import {
  cleanValue,
  formatCash,
  getColorForValue,
  NumberWithItemsText,
} from '../../reportbuilder/widgets/horizontal/EstimatedPayableReceiveableWidget';

const Data = [
  {
    title: 'TM Revenue YTD',
    value: '43849',
    type: 'currency',
  },
  {
    title: 'Average deposit volume YTD',
    value: '12829384',
    type: 'currency',
  },
];

const Data2 = [
  {
    title: 'TM Overview Payables',
    name: 'payables',
    total: 146,
    chartData: {
      labels: [' Checks: 12 items', ' ACH: 122 items', ' Wires: 12 items'],
      datasets: [
        {
          label: 'Items',
          data: [8, 70, 8],
          backgroundColor: [
            'rgba(255,43,44,255)',
            'rgba(1,183,91,255)',
            'rgba(255,217,80,255)',
          ],
        },
      ],
    },
  },
  {
    title: 'TM Overview Receivables',
    name: 'receivables',
    total: 22,
    chartData: {
      labels: [' Checks: 6 items', ' ACH: 12 items', ' Wires: 10 items'],
      datasets: [
        {
          label: 'Items',
          data: [0, 55, 45],
          backgroundColor: [
            'rgba(255,43,44,255)',
            'rgba(1,183,91,255)',
            'rgba(255,217,80,255)',
          ],
        },
      ],
    },
  },
];

const paymentMethodsUsed = {
  Checks: {
    key: 'Checks',
    value: 0,
    isEdited: false,
    itemValue: '12',
    isChecked: true,
  },
  ACH: {
    key: 'ACH',
    value: 0,
    isEdited: false,
    itemValue: '122',
    isChecked: true,
  },
  Wires: {
    key: 'Wires',
    value: 0,
    isEdited: false,
    itemValue: '12',
    isChecked: true,
  },
};

const Overview = () => {
  return (
    <div className="pt-3">
      <div className="d-grid grid-col-2 gap-3 mb-3">
        {Data?.map((item, index) => (
          <Card key={index} className="home-card report-widget h-100">
            <Card.Body className="p-3 flex-grow-1">
              <div className="d-flex flex-column gap-1">
                <div className="font-weight-medium text-center mb-0 sub-text fs-9">
                  {item.title}
                </div>
                <h2 className="mb-0 font-weight-bolder text-center text-rpt-heading fs-2 font-weight-semi-bold">
                  <FormattedValue value={item?.value} type={item.type} />
                </h2>
              </div>
            </Card.Body>
          </Card>
        ))}
      </div>

      <div className="d-grid grid-col-2 gap-3 mb-3">
        {Data2?.map((item, index) => (
          <Card key={index} className="home-card report-widget h-100">
            <Card.Body className="p-3 pb-5 flex-grow-1">
              <h5 className="text-left mb-1 d-flex w-100 align-items-center gap-1">
                {item.title}
              </h5>

              <Row className="pl-4 pr-2 position-relative flex-fill w-100 pt-2">
                <div className="mx-auto max-w-120 max-h-120 etpr-chart-align">
                  <ChartDonutWidget
                    data={{
                      ...item.chartData,
                      type: 'doughnut',
                      legendPosition: 'top',
                    }}
                    name={item.name}
                    legendPosition="top"
                    dataLabelsConfig={{ display: false }}
                    optionsConfig={{
                      legendDisplay: false,
                    }}
                    tooltip={{
                      callbacks: {
                        label: function (tooltipItem) {
                          return '';
                        },
                      },
                    }}
                  />
                </div>
                <div className="flex-grow-1 mb-3 pl-2">
                  {Object.entries(sortObjectKeys(paymentMethodsUsed) || {}).map(
                    (entry, index) => (
                      <div
                        key={index}
                        className="d-flex justify-content-between align-items-center font-size-sm2 pb-2"
                      >
                        <div className="d-flex gap-2 align-items-center">
                          <div
                            className="filled-circle"
                            style={{
                              backgroundColor: getColorForValue(
                                entry[1].key,
                                index
                              ),
                            }}
                          ></div>
                          <span
                            className={`fs-8 sub-text text-left font-weight-medium`}
                          >
                            <TextOverflowTooltip
                              text={entry[1].key}
                              textStyle="fs-8 sub-text text-left font-weight-medium text-wrap"
                              maxLength={8}
                            />
                          </span>
                        </div>
                        <span className="fs-8 sub-text">
                          {formatCash(
                            cleanValue(entry[1].itemValue),
                            0
                          )?.replace('$', '')}{' '}
                          {parseInt(cleanValue(entry[1].itemValue)) > 1
                            ? 'items'
                            : 'item'}
                        </span>
                      </div>
                    )
                  )}
                  <div className="chart-total-row d-flex justify-content-between align-items-center mt-3 font-weight-bold">
                    <p className="mb-1 text-left font-size-sm2">Total</p>
                    <NumberWithItemsText
                      item={formatCash(item.total, 0)?.replace('$', '')}
                    />
                  </div>
                </div>
              </Row>
            </Card.Body>
          </Card>
        ))}
      </div>

      <div className="d-grid grid-col-1">
        <Card className="home-card report-widget h-100">
          <Card.Body className="p-3 flex-grow-1">
            <div className="d-flex flex-column gap-1">
              <h5 className="text-left mb-1 d-flex w-100 align-items-center gap-1">
                Current Products
              </h5>
              <p className="mb-0 font-size-sm2 px-2">
                Positive Pay, Integrated Payables, Integrated Receivables, ACH
                Origination, RTP, Lockbox, Intra Day Reporting, ZBA
              </p>
            </div>
          </Card.Body>
        </Card>
      </div>
    </div>
  );
};

export default Overview;
