import authHeader from './auth-header';
import BaseRequestService from './baseRequest.service';

class DromoService extends BaseRequestService {
  async callback(body) {
    const response = await this.post(
      `/providers/dromo/callback`,
      body,
      {
        headers: authHeader(),
      },
      { fullResponse: true }
    );
    return response?.data;
  }
}

export default new DromoService();
