import React from 'react';
import Skeleton from 'react-loading-skeleton';

import MaterialIcon from '../../../../components/commons/MaterialIcon';

const StatsInfoCard = ({
  title = 'Info',
  value = '0',
  materialIcon = 'schedule',
  materialIconClazz = 'text-teal-blue',
  isLoading = false,
}) => {
  return (
    <div>
      <div className="split-progress-bar">
        <div className="d-flex align-items-center gap-2 w-100">
          {isLoading ? (
            <Skeleton width={30} height={30} />
          ) : (
            <MaterialIcon
              icon={materialIcon}
              size="fs-2"
              clazz={materialIconClazz}
            />
          )}
          <div className="w-100">
            <h5 className="text-muted fon-weight-light font-size-sm mb-1">
              {isLoading ? <Skeleton /> : title}
            </h5>
            <h2>{isLoading ? <Skeleton /> : value}</h2>
          </div>
        </div>
      </div>
    </div>
  );
};
export default StatsInfoCard;
