import React, { useRef, useState } from 'react';
import { Dropdown } from 'react-bootstrap';

import MaterialIcon from './MaterialIcon';
import Masonry from 'react-masonry-css';
import Search from '../manageUsers/Search';
import useOutsideClickDropDown from '../../hooks/useOutsideClickDropDown';
import { IconsMaterialToLucide } from './IconUtils';
import { icons } from 'lucide-react';

const IconComponent = ({ item, isLabel = false }) => {
  return (
    <>
      <MaterialIcon icon={item.name} clazz="font-size-xl2" />
      {isLabel && <span className="ml-2 font-size-sm">{item.label}</span>}
    </>
  );
};

export function isLucidIcon(icon) {
  if (!icon) return false;

  const mappedIcon = IconsMaterialToLucide[icon];

  if (!mappedIcon) {
    return false;
  }

  if (!icons[mappedIcon]) {
    return false;
  }

  return true;
}

const IconDropdownSearch = ({
  onSelect,
  options = [],
  isForm = false,
  children,
}) => {
  const dropdownRef = useRef(null);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [inputValue, setInputValue] = useState('');

  const onHandleChange = (e) => {
    const { value } = e.target;
    setInputValue(value);
    setIsMenuOpen(true);
  };

  const onHandleSelect = (item) => {
    onSelect(item);
    setInputValue('');
    setIsMenuOpen(false);
  };

  useOutsideClickDropDown(dropdownRef, isMenuOpen, setIsMenuOpen);

  return (
    <div>
      <Dropdown
        show={isMenuOpen}
        drop="down"
        className={isForm ? '' : 'position-absolute bottom-0 right-0'}
        onToggle={(isOpen, event, metadata) => {
          if (metadata.source !== 'select') {
            setIsMenuOpen(isOpen);
          }
        }}
      >
        {isForm ? (
          <Dropdown.Toggle
            as="div"
            className="tags-dropdown w-100 dropdown-input p-0"
            id="dropdown-basic"
          >
            <div className="bg-white border border-1 border-primary search-card rounded rounded-2">
              {children}
            </div>
          </Dropdown.Toggle>
        ) : (
          <Dropdown.Toggle
            as="div"
            className="tags-dropdown w-100 border rounded dropdown-input p-0"
            id="dropdown-basic"
          >
            <div>{children}</div>
          </Dropdown.Toggle>
        )}

        {!!options.length && (
          <>
            <Dropdown.Menu
              ref={dropdownRef}
              style={{ overflowX: 'hidden', minWidth: 250, height: 300 }}
              className={`w-100 basic-animation shadow-lg rounded overflow-x-hidden
                ${isForm ? 'p-0' : 'p-3'}`}
            >
              {!isForm && isMenuOpen && (
                <Search
                  onHandleChange={onHandleChange}
                  searchPlaceholder={'Search'}
                  classnames="px-0 mb-2 w-auto"
                />
              )}
              {isForm ? (
                <>
                  {options
                    ?.filter((item) => isLucidIcon(item.name)) // check if it's a valid Lucid icon
                    .map((item, index) => {
                      return (
                        <Dropdown.Item
                          as="div"
                          className="cursor-pointer d-flex align-items-center py-2 px-2 mb-0 border-bottom"
                          key={index}
                          onClick={onHandleSelect.bind(null, item)}
                        >
                          <IconComponent item={item} isLabel />
                        </Dropdown.Item>
                      );
                    })}
                </>
              ) : (
                <>
                  <Masonry breakpointCols={5} className="d-flex">
                    {options
                      ?.filter((icon) => isLucidIcon(icon.name)) // check if it's a valid Lucid icon
                      .filter(
                        (icon) =>
                          icon.label
                            .toLowerCase()
                            .includes(inputValue.toLowerCase()) // filter by input value
                      )
                      .map((item, index) => {
                        return (
                          <Dropdown.Item
                            as="div"
                            className="cursor-pointer p-1 text-center"
                            key={index}
                            onClick={onHandleSelect.bind(null, item)}
                          >
                            <IconComponent item={item} />
                          </Dropdown.Item>
                        );
                      })}
                  </Masonry>
                </>
              )}
            </Dropdown.Menu>
          </>
        )}
      </Dropdown>
    </div>
  );
};

export default IconDropdownSearch;
