import React, { useCallback, useEffect, useMemo, useState } from 'react';
import {
  getRandomUUID,
  overflowing,
  removeBodyScroll,
} from '../../../../utils/Utils';
import SimpleModalCreation from '../../../modal/SimpleModalCreation';
import { FormGroup, Label } from 'reactstrap';
import { FormControl } from 'react-bootstrap';
import ButtonFilterDropdown from '../../../commons/ButtonFilterDropdown';
import Alert from '../../../Alert/Alert';
import AlertWrapper from '../../../Alert/AlertWrapper';

const Roles = [
  { key: 'User', name: 'User' },
  { key: 'Admin', name: 'Admin' },
];
const AddNewAuthorizedUserModal = ({
  show,
  setShow,
  authSigners,
  selectedAuthSigner,
  setAuthSigners,
}) => {
  const [, setLoader] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const defaultAuthSignerObject = {
    id: getRandomUUID(),
    role: Roles[0],
    name: '',
    status: 'complete',
  };
  const [authSignerObject, setAuthSignerObject] = useState(
    defaultAuthSignerObject
  );

  useEffect(() => {
    if (show) {
      removeBodyScroll();
      if (selectedAuthSigner?.id) {
        setAuthSignerObject(selectedAuthSigner);
      }
    }
  }, [show]);

  const handleClose = () => {
    setShow(false);
    overflowing();
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setAuthSignerObject({ ...authSignerObject, [name]: value });
  };

  const handleSave = () => {
    try {
      handleClose();
      const signerExists = authSigners.some(
        (signer) => signer.id === authSignerObject.id
      );
      let updatedAuthSigners = [];
      if (!signerExists) {
        updatedAuthSigners = [...authSigners, authSignerObject];
      } else {
        updatedAuthSigners = authSigners.map((signer) =>
          signer.id === authSignerObject.id ? authSignerObject : signer
        );
      }
      setAuthSigners(updatedAuthSigners);
    } catch (e) {
      console.log(e);
    } finally {
      setLoader(false);
    }
  };
  return (
    <SimpleModalCreation
      modalTitle="Add Authorized User"
      modalHeaderClasses="align-items-start"
      open={show}
      bankTeam={false}
      saveButton="Save"
      bodyClassName="p-3"
      buttonsDisabled={!authSignerObject.name}
      isLoading={false}
      handleSubmit={handleSave}
      onHandleCloseModal={() => {
        overflowing();
        setShow(!show);
      }}
    >
      <AlertWrapper>
        <Alert
          message={errorMessage}
          setMessage={setErrorMessage}
          color="danger"
        />
      </AlertWrapper>
      <form autoComplete="off">
        <FormGroup>
          <Label>Name</Label>
          <FormControl
            as="input"
            autoFocus
            name="name"
            value={authSignerObject.name}
            placeholder="Enter name"
            className="mb-2 border-left-4 border-left-danger"
            onChange={(e) => handleInputChange(e)}
          />
        </FormGroup>
        <FormGroup>
          <Label>Role</Label>
          <ButtonFilterDropdown
            buttonText="Select Role"
            btnToggleStyle="text-truncate w-100 btn-h-sm input-global-height"
            options={Roles}
            filterOptionSelected={authSignerObject.role || Roles[0]}
            handleFilterSelect={(e, item) => {
              setAuthSignerObject({ ...authSignerObject, role: item });
            }}
          />
        </FormGroup>
      </form>
    </SimpleModalCreation>
  );
};

const useAddNewAuthorizedUserModal = (
  selectedAuthSigner,
  authSigners,
  setAuthSigners
) => {
  const [showModal, setShowModal] = useState(false);

  const AddNewAuthorizedUserModalCallback = useCallback(() => {
    return (
      <AddNewAuthorizedUserModal
        show={showModal}
        setShow={setShowModal}
        selectedAuthSigner={selectedAuthSigner}
        authSigners={authSigners}
        setAuthSigners={setAuthSigners}
      />
    );
  }, [
    showModal,
    setShowModal,
    selectedAuthSigner,
    authSigners,
    setAuthSigners,
  ]);

  return useMemo(
    () => ({
      setShowModal,
      AddNewAuthorizedUserModal: AddNewAuthorizedUserModalCallback,
    }),
    [setShowModal, AddNewAuthorizedUserModalCallback]
  );
};

export default useAddNewAuthorizedUserModal;
