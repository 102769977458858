import React, { useState } from 'react';
import OtherHome from '../../components/home/other';
import useHash from '../../hooks/useHash';
import { Accordion } from 'react-bootstrap';
import LucideIcon from '../../components/commons/LucideIcon';
import { useLocation } from 'react-router';
import OverviewHome from '../../components/home/overview';
import PortfolioHome from '../../components/home/portfolio';
import PipelinesHome from '../../components/home/pipelines';
import LeadsHome from '../../components/home/leads';
import HomeLayout from '../../components/home/shared/Layout';
import useIsTenant from '../../hooks/useIsTenant';
import { scrollToBottomChat } from '../../utils/Utils';
import EngagementReports from '../../components/home/shared/EngagementReports';
import ActivitiesHome from '../../components/home/activities';

const MenuItemsKeys = {
  Overview: 1,
  Portfolio: 2,
  Pipelines: 3,
  Leads: 4,
  Other: 5,
  Activities: 6,
};

const MenuItemsKeysAndIcons = {
  Overview: {
    id: MenuItemsKeys.Overview,
    path: '#overview',
    icon: 'Gauge',
    title: 'Overview',
  },
  Portfolio: {
    id: MenuItemsKeys.Portfolio,
    icon: 'Briefcase',
    title: 'Portfolio',
    path: '#portfolio',
  },
  Pipelines: {
    id: MenuItemsKeys.Pipelines,
    icon: 'GitBranch',
    path: '#pipelines',
    title: 'Pipelines',
  },
  Leads: {
    id: MenuItemsKeys.Leads,
    icon: 'Users',
    path: '#leads',
    title: 'Leads',
  },
  Activities: {
    id: MenuItemsKeys.Activities,
    icon: 'Activity',
    path: '#activities',
    title: 'Activities',
  },
  Other: {
    id: MenuItemsKeys.Other,
    icon: 'MoveHorizontal',
    title: 'Other',
    path: '#other',
  },
};

const MenuItems = [
  {
    ...MenuItemsKeysAndIcons.Overview,
    component: <OverviewHome />,
  },
  {
    ...MenuItemsKeysAndIcons.Leads,
    component: <LeadsHome />,
  },
  {
    ...MenuItemsKeysAndIcons.Portfolio,
    component: <PortfolioHome />,
  },
  {
    ...MenuItemsKeysAndIcons.Pipelines,
    component: <PipelinesHome />,
  },
  {
    ...MenuItemsKeysAndIcons.Activities,
    component: <ActivitiesHome />,
  },
  {
    ...MenuItemsKeysAndIcons.Other,
    component: <OtherHome />,
  },
];

const Menu = () => {
  const { hash, updateHash } = useHash();
  const { isDemo } = useIsTenant();
  const defaultDashboard = isDemo
    ? MenuItemsKeys.Overview
    : MenuItemsKeys.Other;
  const getMenuByHash = () => {
    if (!hash) {
      return defaultDashboard;
    }
    const menuItem = MenuItems.find((menu) => hash === menu.path.slice(1));
    if (!menuItem) {
      return defaultDashboard;
    }
    return menuItem.id;
  };
  const [active, setActive] = useState(getMenuByHash());

  const handleClick = (item) => {
    scrollToBottomChat('homeContent', false);
    updateHash(item.path);
    setActive(item.id);
  };

  return (
    <ul className="mt-2 mb-0 px-3 list-unstyled d-flex flex-column transparent-scroll-track">
      {MenuItems.map((item) => (
        <li
          key={item.id}
          className="reporting-filter-wrapper accordion-wrapper"
        >
          <Accordion activeKey={active}>
            <Accordion.Toggle
              onClick={() => handleClick(item)}
              eventKey={item.id}
              className={`${
                active === item.id ? 'bg-accordion-active' : 'bg-white'
              } reporting-filter-toggle rounded accordion-toggle nav-link px-3 py-2 border-bottom cursor-pointer font-size-sm2 font-weight-medium text-black text-capitalize`}
            >
              <div className="d-flex align-items-center">
                <LucideIcon icon={item.icon} size={17} />
                <span className="ml-2 text-capitalize text-truncate py-0">
                  {item.title}
                </span>
              </div>
            </Accordion.Toggle>
          </Accordion>
        </li>
      ))}
    </ul>
  );
};

const Content = () => {
  const location = useLocation();
  const { hash } = useHash();
  const { isDemo } = useIsTenant();
  const defaultDashboard = isDemo ? <OverviewHome /> : <OtherHome />;
  if (!hash || !location.hash) {
    return defaultDashboard;
  }
  const menuItem = MenuItems.find((menu) => hash === menu.path.slice(1));

  if (!menuItem) {
    return defaultDashboard;
  }

  return menuItem.component || defaultDashboard;
};
const Home = () => {
  const { isDemo } = useIsTenant();
  return (
    <>
      {isDemo ? (
        <div className="custom-layout chat-layout">
          <div className="custom-layout-sidebar pt-2 chat-sidebar overflow-x-hidden">
            <Menu />
            <EngagementReports />
          </div>
          <div
            id="homeContent"
            className="custom-layout-content smooth-scroll overflow-x-hidden overflow-y-auto"
          >
            <HomeLayout>
              <Content />
            </HomeLayout>
          </div>
        </div>
      ) : (
        <HomeLayout clazz="px-0">
          <OtherHome />
        </HomeLayout>
      )}
    </>
  );
};

export default Home;
