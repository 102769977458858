import React from 'react';
import { FormCheck } from 'react-bootstrap';
import ChecklistStatus from '../checklist/ChecklistStatus';

const ReportSectionIncludedLabel = ({
  section,
  reportPages,
  handleReportPageToggle,
}) => {
  return (
    <>
      <div className="d-flex align-items-center gap-2 ml-auto">
        <ChecklistStatus
          style={{
            bottom: 0,
            left: 3,
          }}
          item={{
            status: reportPages[section]?.enabled ? 'Included' : 'Excluded',
          }}
        />
        <FormCheck
          type="switch"
          custom={true}
          id={`${section}-toggle`}
          name={`${section}-toggle`}
          checked={reportPages[section]?.enabled}
          onChange={() => handleReportPageToggle(section)}
        />
      </div>
    </>
  );
};

export default ReportSectionIncludedLabel;
