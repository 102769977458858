import { useEffect, useState } from 'react';
import ChatService from '../../../services/chat.service';
import { useDebounce } from '../../../hooks/useDebounce';

const useFetchChats = (limit = 10) => {
  const [chats, setChats] = useState([]);
  const [pagination, setPagination] = useState({
    currentPage: 1,
    totalPages: 1,
    count: 0,
  });
  const [loading, setLoading] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const [searchValue, setSearchValue] = useState('');
  const debouncedSearchTerm = useDebounce(searchValue, 500);

  const fetchChats = async (page = 1, title = searchValue) => {
    try {
      setLoading(true);
      const { data, pagination: chatPagination } = await ChatService.getChats(
        page,
        limit,
        title
      );
      if (page === 1) {
        setChats(data);
      } else {
        setChats((prevChats) => [...prevChats, ...data]);
      }
      setPagination({
        currentPage: page,
        totalPages: chatPagination.totalPages,
        count: chatPagination.count,
      });
      setHasMore(page < chatPagination.totalPages);
    } catch (e) {
      console.log(e);
    } finally {
      setLoading(false);
    }
  };

  const fetchNextChats = () => {
    if (!hasMore) return;
    if (pagination.currentPage < pagination.totalPages) {
      fetchChats(pagination.currentPage + 1);
    }
  };

  useEffect(() => {
    if (debouncedSearchTerm.length === 0 || debouncedSearchTerm.length >= 3) {
      fetchChats(1, debouncedSearchTerm);
    }
  }, [debouncedSearchTerm]);

  return {
    chats,
    setChats,
    loading,
    pagination,
    setPagination,
    searchValue,
    setSearchValue,
    fetchChats,
    fetchNextChats,
    hasMore,
  };
};

export default useFetchChats;
