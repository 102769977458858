import React from 'react';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import MaterialIcon from './commons/MaterialIcon';

const DefaultAvatar = ({
  active,
  classModifiers,
  defaultSize = 'sm',
  sizeIcon = 'avatar-light',
  type,
  style,
  initials,
}) => {
  const renderAvatar = () => {
    return (
      <>
        {initials && type === 'contact' ? (
          <span>
            {(initials?.first_name || initials?.firstName)?.charAt(0)}
            {(initials?.last_name || initials?.lastName)?.charAt(0)}
          </span>
        ) : (
          <>
            {type === 'contact' ? (
              <AccountCircleIcon />
            ) : (
              <MaterialIcon
                size={sizeIcon}
                icon="corporate_fare"
                clazz={`d-flex align-items-center ${
                  sizeIcon || `avatar-icon-font-size-${defaultSize}`
                }`}
              />
            )}
          </>
        )}
      </>
    );
  };

  return (
    <div
      style={style}
      className={`avatar avatar-${defaultSize} avatar-circle ${classModifiers}`}
    >
      <span
        className={`avatar-initials ${
          sizeIcon?.includes('border-color-primary') ? '' : ''
        } ${sizeIcon || 'avatar-icon-font-size'}`}
      >
        {renderAvatar()}
      </span>
      {active && (
        <span
          className={`avatar-status avatar-${defaultSize}-status avatar-status-success`}
        />
      )}
    </div>
  );
};

DefaultAvatar.defaultProps = {
  user: {},
  active: false,
  classModifiers: '',
  type: 'contact',
};

export default DefaultAvatar;
