import React from 'react';
import { Skeleton } from '@mui/material';
import TableSkeleton from '../../../components/commons/TableSkeleton';
import { Card, CardBody } from 'reactstrap';
import useIsTenant from '../../../hooks/useIsTenant';

const Tab = () => {
  return (
    <Skeleton
      variant="rectangular"
      className="rounded-pill"
      width="151px"
      height={38}
    />
  );
};
const StatsItem = () => {
  return (
    <div className="w-100 border-2 border rounded px-3 pt-4 pb-5">
      <div className="d-flex align-items-start justify-content-between">
        <Skeleton variant="text" width={100} height={20} />
        <Skeleton variant="circular" width={40} height={40} />
      </div>
      <div className="w-100 d-flex align-items-center justify-content-between gap-3 pt-3">
        <div className="w-100">
          <Skeleton variant="text" width={'50%'} height={20} />
          <Skeleton variant="text" width={'75%'} height={20} />
        </div>
        <div className="w-100">
          <Skeleton variant="text" width={'50%'} height={20} />
          <Skeleton variant="text" width={'75%'} height={20} />
        </div>
        <div className="w-100">
          <Skeleton variant="text" width={'50%'} height={20} />
          <Skeleton variant="text" width={'75%'} height={20} />
        </div>
      </div>
    </div>
  );
};

const ReportItem = () => {
  return (
    <div className="w-100 d-flex flex-column gap-2 align-items-center">
      <Skeleton variant="circular" width={40} height={40} />
      <Skeleton variant="text" width={100} height={20} />
    </div>
  );
};

export default function HomeLoadingSkeleton({ clazz }) {
  const { isDemo } = useIsTenant();
  return (
    <div className="w-100 drop-disabled h-100">
      <div className="splitted-content-fluid mx-auto position-relative container-fluid content-with-insights overflow-hidden px-0 pt-0">
        <div className="page-header bg-white border-bottom organization-header pt-3 pb-2 px-4 mb-0">
          <div
            className="row mx-auto"
            style={{ maxWidth: 'var(--home-page-width)' }}
          >
            <div className="col-sm px-0 mb-2 mb-sm-0">
              <p className="fs-7 mb-0 d-flex align-items-center gap-2 font-weight-semi-bold pt-1"></p>
              <div className="d-flex gap-1 py-2 align-items-center flex-wrap">
                <div className="d-flex flex-column">
                  <Skeleton variant="text" width={120} height={39} />
                  <Skeleton variant="text" width={230} height={33} />
                  {!isDemo ? (
                    <div className="d-flex align-items-center mx-auto gap-2 py-2">
                      <Tab />
                      <Tab />
                      <Tab />
                      <Tab />
                    </div>
                  ) : null}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          className={`mx-auto ${clazz}`}
          style={{ maxWidth: 'var(--home-page-width)' }}
        >
          {!isDemo ? (
            <Card className="border flex-fill rounded my-3 shadow-0">
              <CardBody className="bg-white rounded">
                <div
                  style={{ maxWidth: 700 }}
                  className="d-flex align-items-center mx-auto justify-content-center gap-4"
                >
                  <ReportItem />
                  <ReportItem />
                  <ReportItem />
                  <ReportItem />
                  <ReportItem />
                </div>
              </CardBody>
            </Card>
          ) : (
            <div>&nbsp;</div>
          )}
          <div className="row mx-auto">
            <div className="col-lg-5 px-0">
              <Card className="shadow-0 h-100">
                <CardBody className="rounded-lg h-100 p-3">
                  <div className="pb-3 d-flex flex-column gap-3">
                    <StatsItem />
                    <StatsItem />
                    <StatsItem />
                  </div>
                </CardBody>
              </Card>
            </div>
            <div className="col-lg-7 pr-0">
              <Card className="shadow-0 h-100">
                <CardBody className="px-0 h-100 pt-3 pb-0">
                  <div className="px-3 pb-3">
                    <TableSkeleton cols={5} rows={10} />
                  </div>
                </CardBody>
              </Card>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
