import React, { useEffect, useState } from 'react';
import { Card } from 'react-bootstrap';
import stringConstants from '../../utils/stringConstants.json';
import { sortingTable } from '../../utils/sortingTable';
import routes from '../../utils/routes.json';
import NewTable from '../NewGenericTable';
import Table from '../GenericTable';
import TableSkeleton from '../commons/TableSkeleton';
import activityService from '../../services/activity.service';
import { dateWithoutTZ, isPermissionAllowed } from '../../utils/Utils';
import { Link } from 'react-router-dom';
import MaterialIcon from '../commons/MaterialIcon';
import TableActions from '../commons/TableActions';
import { ActivityDetail } from './activityDetail';
import OwnerAvatar from './OwnerAvatar';
import DeleteConfirmationModal from '../modal/DeleteConfirmationModal';
import ChecklistStatus from '../checklist/ChecklistStatus';
import TableRowHover from '../commons/TableRowHover';
import { useProfileContext } from '../../contexts/profileContext';
import TextOverflowTooltip from '../commons/TextOverflowTooltip';
const constants = stringConstants.allActivities;

const AllActivitiesTable = ({
  paginationPage,
  order,
  setActivatedTab,
  setOrder,
  pagination,
  showLoading,
  dataInDB,
  setPagination,
  selectedData = [],
  setSelectedData,
  setShowDeleteOrgModal,
  deleteResults,
  tabType,
  showDeleteOrgModal,
  setDeleteResults,
  handleClearSelection = () => {},
  selectAll,
  getData,
  cloneData,
  setCloneData,
  setErrorMessage,
  setSuccessMessage,
  allData,
  isFilterCheck,
  setSelectAll,
  handleEditActivity,
  getStats,
  fromCompanyDetailsPage = false, // if its true it means its loading from company detail page
}) => {
  const { profileInfo } = useProfileContext();
  const [isShow, setIsShow] = useState(false);
  const [showTooltip, setShowTooltip] = useState(true);
  const [activityObj, setActivityObj] = useState({});

  useEffect(() => {
    getData(tabType);
  }, [pagination?.page, pagination?.limit, order, isFilterCheck?.filter]);
  const changePaginationPage = (newPage) => {
    setPagination((prev) => ({ ...prev, page: newPage }));
  };

  useEffect(() => {
    paginationPage?.page === 1 && changePaginationPage(1);
  }, [paginationPage]);
  const activityDetail = async (content) => {
    const { data } = await activityService.getSingleActivity(
      content.id ? content.id : content
    );
    setActivityObj(data);
    setIsShow(true);
  };
  const markAsDone = async (id) => {
    try {
      await activityService.markAsCompleted(id);
      getData('task');
      setActivatedTab({
        task: 1,
      });
      setSuccessMessage(constants.completed);
    } catch (error) {
      setErrorMessage(constants.errorUpdatedActivity);
    }
  };

  const GetOwnersAction = (task) => {
    if (task) {
      return (
        <span>
          {task?.owners?.[0] ? (
            <div
              className="position-relative index-0-on-scroll"
              key={task.owners[0].id}
            >
              <OwnerAvatar
                hovering="index-0-on-scroll"
                item={task.owners[0]}
                isMultiple={true}
              />
            </div>
          ) : (
            <h5 className="mb-0 p-0">--</h5>
          )}
        </span>
      );
    }
  };
  const GetCreateByOwners = (task) => {
    if (task) {
      return (
        <div className="position-relative index-0-on-scroll">
          <OwnerAvatar item={task} hovering="index-0-on-scroll" />
        </div>
      );
    }
  };
  const getAction = (content) => {
    if (content?.organization) {
      return (
        <Link
          to={`${routes.companies}/${content?.organization?.id}/organization/profile`}
          className="text-black fw-normal d-inline-flex align-items-center gap-1"
        >
          {content?.organization && (
            <>
              <MaterialIcon icon="corporate_fare" />{' '}
              {content?.organization?.name}
            </>
          )}
        </Link>
      );
    } else if (content?.contact) {
      const name = `${content?.contact?.first_name} ${content?.contact?.last_name}`;
      return (
        <Link
          to={`${routes.contacts}/${content?.contact?.id}/profile`}
          className="text-black fw-normal d-inline-flex align-items-center gap-1"
        >
          {content && (
            <>
              <MaterialIcon icon="people" /> {name}
            </>
          )}
        </Link>
      );
    } else if (content?.deal) {
      return (
        <Link
          to={`${routes.dealsPipeline}/${content?.deal?.id}`}
          className="text-black fw-normal d-inline-flex align-items-center gap-1"
        >
          {content?.deal && (
            <>
              <MaterialIcon icon="monetization_on" /> {content?.deal?.name}
            </>
          )}
        </Link>
      );
    }
  };
  const tableActions = [
    {
      id: 1,
      title: 'Mark as done',
      icon: 'task_alt',
      permission: {
        collection: 'activities',
        action: 'edit',
      },
      onClick: (item) => {
        markAsDone(item.id);
      },
    },
    {
      id: 2,
      title: 'Edit',
      icon: 'edit',
      permission: {
        collection: 'activities',
        action: 'edit',
      },
      onClick: (e) => {
        setIsShow(false);
        handleEditActivity(e);
      },
    },
    {
      id: 3,
      title: 'Clone',
      permission: {
        collection: 'activities',
        action: 'create',
      },
      icon: 'content_copy',
      onClick: (e) => {
        setIsShow(false);
        setCloneData(true);
        handleEditActivity(e);
      },
    },
  ];

  const sortTable = ({ name }) => {
    if (name === 'action') return null;
    sortingTable({ name, order, setOrder });
  };
  const loader = () => {
    if (showLoading) return <TableSkeleton cols={6} rows={10} />;
  };

  const deleteActivities = async () => {
    try {
      await Promise.all(
        selectedData?.map((item) =>
          activityService.deleteActivity(
            selectedData.length > 0 ? item : deleteResults?.id
          )
        )
      );

      setSuccessMessage('Activities have been deleted.');
      handleClearSelection();
      setSelectedData([]);
      setActivatedTab({ all: 1 });
      getData('all');
    } catch (err) {
      console.error('Error deleting activities:', err);
      setErrorMessage(
        err?.message || 'An error occurred while deleting activities.'
      );
    }
  };

  const handleRowClick = (rowData) => {
    activityDetail(rowData);
  };

  const columns = [
    {
      key: 'name',
      orderBy: 'name',
      component: 'Activity Name ',
      width: '220px',
    },
    {
      key: 'type',
      orderBy: '',
      component: 'Type',
      width: '160px',
    },
    {
      key: 'created_by',
      orderBy: '',
      component: 'Created By',
      width: '160px',
    },
    {
      key: '',
      orderBy: '',
      component: 'Owner',
      width: '160px',
    },
    {
      key: 'end_date',
      orderBy: 'end_date',
      component: 'Due Date',
      width: '220px',
    },
    {
      key: 'done',
      orderBy: 'done',
      component: 'Status',
      width: '220px',
    },
    {
      key: 'priority',
      orderBy: 'priority',
      component: 'Priority',
      width: '220px',
    },
    {
      key: 'action',
      orderBy: '',
      component: '',
      width: '100px',
    },
  ];
  const data = allData?.map((activity) => ({
    ...activity,
    dataRow: [
      {
        key: 'name',
        component: (
          <div className="d-flex gap-1 align-items-center">
            <span className={`text-truncate text-wrap fw-bold`}>
              <TextOverflowTooltip
                maxLength={20}
                capitalizeText={false}
                text={activity?.name}
                textStyle="fw-bold mb-0"
              />
            </span>
          </div>
        ),
      },
      {
        key: 'type',
        component: <span className="text-capitalize">{activity?.type}</span>,
      },
      {
        key: 'created_by',
        component: GetCreateByOwners(activity),
      },
      {
        key: 'owner',
        component: GetOwnersAction(activity),
      },
      {
        key: 'end_date',
        component: <span>{dateWithoutTZ(activity?.start_date)}</span>,
      },
      {
        key: 'done',
        component: (
          <ChecklistStatus
            item={{ status: activity?.done ? 'Completed' : 'In Progress' }}
          />
        ),
      },
      {
        key: 'priority',
        component: (
          <span>
            {activity?.priority ? (
              <div className="d-flex text-red align-items-center gap-1">
                <MaterialIcon
                  filled={true}
                  icon="flag"
                  size="fs-flag-icon"
                  clazz="text-red ml-n1"
                />
                High
              </div>
            ) : (
              'Normal'
            )}
          </span>
        ),
      },
      {
        key: 'action',
        component: (
          <>
            {isPermissionAllowed('activities', 'edit') ||
            isPermissionAllowed('activities', 'create') ||
            profileInfo?.role?.owner_access ? (
              <TableRowHover
                onClick={(e) => {
                  const rect = e.target.getBoundingClientRect();
                  const x = e.clientX - rect.left;
                  if (x <= 50) {
                    setSelectedData((prevSelected) => {
                      if (!prevSelected.includes(activity.id)) {
                        return [...prevSelected, activity.id];
                      } else {
                        return prevSelected.filter(
                          (item) => item !== activity.id
                        );
                      }
                    });
                  } else {
                    activityDetail(activity);
                  }
                }}
              >
                <TableActions
                  item={{ ...activity, title: name }}
                  actions={tableActions}
                />
              </TableRowHover>
            ) : (
              ''
            )}
          </>
        ),
      },
    ],
  }));

  const ActivityPriorityRenderer = (row) => {
    const activity = row.data;
    return (
      <div onClick={() => handleRowClick(activity)}>
        {activity?.priority ? (
          <div className="d-flex text-red align-items-center gap-1">
            <MaterialIcon
              filled={true}
              icon="flag"
              size="fs-flag-icon"
              clazz="text-red ml-n1"
            />
            High
          </div>
        ) : (
          'Normal'
        )}
      </div>
    );
  };

  const ActionsRenderer = (row) => {
    const activity = row.data;
    return (
      <>
        {isPermissionAllowed('activities', 'edit') ||
        isPermissionAllowed('activities', 'create') ||
        profileInfo?.role?.owner_access ? (
          <div className="d-flex align-items-center h-100 pr-2">
            <TableActions
              item={{ ...activity, title: name }}
              actions={tableActions}
              className="d-flex justify-content-center align-items-center"
            />
          </div>
        ) : (
          ''
        )}
      </>
    );
  };

  const columnDefs = [
    {
      field: 'name',
      orderBy: 'name',
      headerName: 'Activity Name',
      pinned: 'left',
      lockPosition: 'left',
      cellRenderer: ({ data }) => (
        <p
          onClick={() => handleRowClick(data)}
          className="font-weight-medium text-truncate text-wrap fs-7_1 mb-0"
        >
          {data?.name}
        </p>
      ),
    },
    {
      field: 'description',
      orderBy: '',
      headerName: 'Description',
      cellRenderer: ({ data }) => (
        <p onClick={() => handleRowClick(data)} className="mb-0">
          {data?.description || '--'}
        </p>
      ),
    },
    {
      field: 'created_by',
      orderBy: '',
      headerName: 'Created By',
      cellRenderer: ({ data }) => (
        <div onClick={() => handleRowClick(data)}>
          {GetCreateByOwners(data)}
        </div>
      ),
    },
    {
      field: 'owners',
      orderBy: '',
      headerName: 'Owner',
      cellRenderer: ({ data }) => (
        <div onClick={() => handleRowClick(data)}>{GetOwnersAction(data)}</div>
      ),
    },
    {
      field: 'end_date',
      orderBy: 'end_date',
      headerName: 'Due Date',
      cellRenderer: ({ data }) => (
        <div onClick={() => handleRowClick(data)}>
          {dateWithoutTZ(data?.start_date)}
        </div>
      ),
    },
    {
      field: 'done',
      orderBy: 'done',
      headerName: 'Status',
      cellRenderer: ({ data }) => (
        <div onClick={() => handleRowClick(data)}>
          <ChecklistStatus
            item={{ status: data?.done ? 'Completed' : 'In Progress' }}
          />
        </div>
      ),
    },
    {
      field: 'priority',
      orderBy: 'priority',
      headerName: 'Priority',
      cellRenderer: ActivityPriorityRenderer,
    },
    {
      field: 'related_to',
      orderBy: '',
      headerName: 'Related To',
      cellRenderer: ({ data }) => (
        <div onClick={() => handleRowClick(data)} className="position-relative">
          {getAction(data)}
        </div>
      ),
    },
    {
      field: 'action',
      orderBy: '',
      headerName: 'Action',
      minWidth: 120,
      hide:
        !isPermissionAllowed('activities', 'edit') &&
        !isPermissionAllowed('activities', 'create'),
      cellRenderer: ActionsRenderer,
    },
  ];

  return (
    <div>
      <DeleteConfirmationModal
        showModal={showDeleteOrgModal}
        setShowModal={setShowDeleteOrgModal}
        handleCloseModal={handleClearSelection}
        event={deleteActivities}
        heading={constants?.delete.heading}
        description={constants?.delete.confirm}
      />

      {isShow && (
        <ActivityDetail
          activityDetail={activityDetail}
          isShow={isShow}
          setIsShow={setIsShow}
          data={activityObj}
          cloneData={cloneData}
          getActivityId={activityObj}
          markAsDone={markAsDone}
          tableActions={tableActions.slice(1)}
        />
      )}

      <Card className="mb-0 card-0">
        <Card.Body className="p-0">
          <div className="table-responsive-md datatable-custom">
            <div
              id="datatable_wrapper"
              className="dataTables_wrapper h-100 no-footer"
            >
              {!fromCompanyDetailsPage ? (
                <NewTable
                  checkbox
                  stickyColumn
                  tableId="activities"
                  columns={columnDefs}
                  data={allData}
                  setDeleteResults={setDeleteResults}
                  selectedData={selectedData}
                  setSelectedData={setSelectedData}
                  selectAll={selectAll}
                  setSelectAll={setSelectAll}
                  paginationInfo={{}}
                  actionPadding="h-100 full-h"
                  onPageChange={changePaginationPage}
                  emptyDataIcon="event_available"
                  emptyDataText="This record doesn't have any activities"
                  title="Task"
                  dataInDB={dataInDB}
                  showTooltip={showTooltip}
                  setShowTooltip={setShowTooltip}
                  sortingTable={sortTable}
                  sortingOrder={order}
                  onClick={activityDetail}
                  tableLoading={showLoading}
                  showLoading={showLoading}
                />
              ) : (
                <>
                  {showLoading ? (
                    loader()
                  ) : (
                    <>
                      <Table
                        className="activity-table"
                        columns={columns}
                        data={data}
                        headClass="bg-gray-table-head"
                        setDeleteResults={setDeleteResults}
                        selectedData={selectedData}
                        setSelectedData={setSelectedData}
                        selectAll={selectAll}
                        setSelectAll={setSelectAll}
                        paginationInfo={{}}
                        actionPadding="h-100 full-h"
                        onPageChange={changePaginationPage}
                        emptyDataIcon="event_available"
                        emptyDataText="This record doesn't have any activities"
                        title="Task"
                        dataInDB={dataInDB}
                        showTooltip={showTooltip}
                        setShowTooltip={setShowTooltip}
                        sortingTable={sortTable}
                        sortingOrder={order}
                        onClick={activityDetail}
                      />
                    </>
                  )}
                </>
              )}
            </div>
          </div>
        </Card.Body>
      </Card>
    </div>
  );
};

export default AllActivitiesTable;
