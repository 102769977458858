import React from 'react';
import WidgetSourceBlock from '../../WidgetSourceBlock';
import TextRoundBlock from '../../blocks/TextRoundBlock';
import { Card, CardBody } from 'reactstrap';
import MaterialIcon from '../../../commons/MaterialIcon';
import CutoutWidget from './CutoutWidget';
import { AFPSource } from '../../constants/widgetsConstants';

const ElectronicPaymentsSavingsWidget = ({ widgetConfig, whenPrinting }) => {
  return (
    <Card className="report-widget">
      <CardBody className="w-100 d-flex">
        <div className="flex-fill">
          <h5 className="text-left d-flex justify-content-between mb-1 d-flex align-items-center gap-1">
            {widgetConfig.heading}
          </h5>
          <div className="px-2">
            <div
              className={`d-flex justify-content-center gap-2 ${
                whenPrinting ? 'py-1' : 'py-2'
              }`}
            >
              {widgetConfig?.description?.map((desc) => (
                <div
                  key={desc}
                  className="d-flex px-1 gap-1 align-items-start justify-content-center"
                >
                  <span
                    style={{ top: 3 }}
                    className="rounded-circle position-relative"
                  >
                    <MaterialIcon
                      icon="circle"
                      clazz="text-red bg-red rounded-circle"
                      size="fs-10"
                    />
                  </span>
                  <p className={`mb-0 fs-9 sub-text text-left`}>
                    <span dangerouslySetInnerHTML={{ __html: desc }}></span>
                  </p>
                </div>
              ))}
            </div>
            <CutoutWidget
              widgetConfig={widgetConfig}
              whenPrinting={whenPrinting}
              cutoutConfig={{
                icon: 'finance',
                text: `Cost <br /> Comparison`,
                bg: 'report-widget-inner-section-soft bg-white',
                iconConfig: {
                  fg: 'text-primary',
                  bg: 'bg-primary-soft',
                  icon: 'finance',
                },
              }}
            >
              <div
                style={{ gap: whenPrinting ? 20 : 30 }}
                className={`d-flex align-items-center justify-content-center ${
                  whenPrinting ? 'py-0' : 'py-2'
                }`}
              >
                <TextRoundBlock
                  big="$2.98"
                  fill="border-none shadow-none border-0 flex-grow-1 w-50"
                  small="Cost Per <br /> Check"
                  color="text-rpt-heading text-danger"
                  centered={
                    whenPrinting ? 'py-0 text-center' : 'py-2 text-center'
                  }
                  smallStyle={whenPrinting ? 'fs-11' : 'fs-10'}
                  bg="#ffffff"
                />
                <MaterialIcon
                  icon="arrow_right_alt"
                  size="font-size-2xl"
                  clazz="text-rpt-heading"
                />
                <TextRoundBlock
                  big="$0.30"
                  fill="border-none shadow-none border-0 flex-grow-1 w-50"
                  color="rpt-green-box-heading"
                  small="Cost Per Card Transaction"
                  smallStyle={whenPrinting ? 'fs-11' : 'fs-10'}
                  centered={
                    whenPrinting ? 'py-0 text-center' : 'py-2 text-center'
                  }
                  bg="#ffffff"
                />
                <MaterialIcon
                  icon="arrow_right_alt"
                  size="font-size-2xl"
                  clazz="text-rpt-heading"
                />
                <TextRoundBlock
                  big="$0.25"
                  fill="border-none shadow-none border-0 flex-grow-1 w-50"
                  color="rpt-green-box-heading"
                  small="Cost Per ACH Transaction"
                  smallStyle={whenPrinting ? 'fs-11' : 'fs-10'}
                  centered={
                    whenPrinting ? 'py-0 text-center' : 'py-2 text-center'
                  }
                  bg="#ffffff"
                />
              </div>
            </CutoutWidget>
          </div>
          {widgetConfig.source && (
            <div className="mt-2">
              <WidgetSourceBlock text={AFPSource} />
            </div>
          )}
        </div>
      </CardBody>
    </Card>
  );
};

export default ElectronicPaymentsSavingsWidget;
