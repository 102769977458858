import axios from './axiosInstance';
import authHeader from './auth-header';

class FeedService {
  createNote(
    name,
    note,
    contactId = null,
    organizationId = null,
    dealId = null,
    activityId = null,
    organizationChecklistId = null,
    userTenantId = null,
    assigned_user_id = null
  ) {
    return axios
      .post(
        `/notes`,
        {
          name,
          note,
          contact_id: contactId,
          organization_id: organizationId,
          activity_id: activityId,
          deal_id: dealId,
          tenant_id: userTenantId,
          organizationChecklistId,
          assigned_user_id,
        },
        { headers: authHeader() }
      )
      .then((response) => {
        return response.data;
      });
  }

  getNote(
    {
      activityId,
      contactId,
      organizationId,
      dealId,
      organizationChecklistId,
      ...restProps
    },
    { page = 1, limit = 15 }
  ) {
    return axios
      .get(`/notes`, {
        params: {
          page,
          limit,
          contact_id: contactId,
          organization_id: organizationId,
          activity_id: activityId,
          deal_id: dealId,
          organizationChecklistId,
          ...restProps,
        },
        headers: authHeader(),
      })
      .then((response) => {
        return response.data;
      });
  }

  /**
   * Move away from this method and use createNote
   * or updateNoteComment
   * @deprecated
   */
  async updateNote(feedId, noteId, note) {
    if (!noteId) {
      const response = await axios.post(
        `/notes`,
        { note },
        { headers: authHeader() }
      );

      return response.data;
    } else {
      const response = await axios.post(
        `/notes/${noteId}`,
        { note },
        { headers: authHeader() }
      );

      return response.data;
    }
  }

  getFiles(
    { contactId, organizationId, dealId, activityId, type },
    { page = 1, limit = 10 }
  ) {
    const params = {
      contact_id: contactId,
      organization_id: organizationId,
      deal_id: dealId,
      activity_id: activityId,
      page,
      limit,
    };

    if (type) {
      params.belongs_to = type;
    }

    return axios
      .get(`/fileAssociations`, {
        headers: authHeader(),
        params,
      })
      .then((response) => {
        return response.data;
      });
  }

  addNotesComment(
    name,
    note,
    assigned_user_id,
    feedId,
    {
      activityId,
      contactId,
      organizationId,
      organizationChecklistId,
      dealId,
      ...restProps
    }
  ) {
    return axios
      .post(
        `/notes`,
        {
          organization_id: organizationId,
          deal_id: dealId,
          contact_id: contactId,
          assigned_user_id,
          activity_id: activityId,
          organizationChecklistId,
          name,
          note,
          parentId: feedId,
        },
        {
          headers: authHeader(),
        }
      )
      .then((response) => {
        return response.data;
      });
  }

  updateNotesComment(
    id,
    name,
    note,
    assigned_user_id,
    feedId,
    {
      activityId,
      contactId,
      organizationId,
      dealId,
      organizationChecklistId,
      ...restProps
    }
  ) {
    return axios
      .put(
        `/notes/${id}`,
        {
          organization_id: organizationId,
          deal_id: dealId,
          contact_id: contactId,
          assigned_user_id,
          activity_id: activityId,
          organizationChecklistId,
          name,
          note,
          parentId: feedId,
        },
        {
          headers: authHeader(),
        }
      )
      .then((response) => {
        return response.data;
      });
  }

  deleteNote(id) {
    return axios
      .delete(`/notes/${id}`, {
        headers: authHeader(),
      })
      .then((response) => {
        return response.data;
      })
      .catch((e) => console.log(e));
  }
}

export default new FeedService();
