import { useTenantContext } from '../contexts/TenantContext';
import { hexToHSL, isDisplayWelcomeScreen } from '../utils/Utils';
import { useEffect } from 'react';

function hexToRgb(hex) {
  // Convert hex to RGB
  const bigint = parseInt(hex.slice(1), 16);
  const r = (bigint >> 16) & 255;
  const g = (bigint >> 8) & 255;
  const b = bigint & 255;

  return { r, g, b };
}

function rgbToHex(r, g, b) {
  // Ensure each component is an integer and within range
  r = Math.max(0, Math.min(255, Math.round(r)));
  g = Math.max(0, Math.min(255, Math.round(g)));
  b = Math.max(0, Math.min(255, Math.round(b)));

  // Convert RGB to hex
  return (
    '#' +
    ((1 << 24) + (r << 16) + (g << 8) + b).toString(16).slice(1).toUpperCase()
  );
}

function adjustColorToGreen(rgb) {
  // Increase green component to make the color more greenish
  return {
    r: rgb.r * 0.4, // Lower the red component
    g: Math.min(255, rgb.g + 60), // Boost green component
    b: rgb.b * 0.4, // Lower the blue component
  };
}

function adjustColorToPink(rgb) {
  // Adjust red and blue components to make the color pinkish
  return {
    r: Math.min(255, rgb.r + 150), // Boost red component
    g: rgb.g * 0.5, // Lower green component
    b: Math.min(255, rgb.b + 50), // Boost blue component
  };
}

function getCustomComplementaryColors(hex) {
  const rgb = hexToRgb(hex);

  // Adjust to green shade
  const greenRgb = adjustColorToGreen(rgb);

  // Adjust to pink shade
  const pinkRgb = adjustColorToPink(rgb);

  // Convert back to hex
  return {
    greenShade: rgbToHex(greenRgb.r, greenRgb.g, greenRgb.b),
    pinkShade: rgbToHex(pinkRgb.r, pinkRgb.g, pinkRgb.b),
  };
}

const useTenantTheme = () => {
  const { tenant } = useTenantContext();

  const setTheme = () => {
    if (tenant?.colors) {
      document.documentElement.style.setProperty(
        '--primaryColor',
        tenant.colors.primaryColor
      );
      document.documentElement.style.setProperty(
        '--secondaryColor',
        tenant.colors.secondaryColor
      );
      const primaryHslCode = hexToHSL(tenant.colors.secondaryColor)?.colorCode;

      const avatarColors = getCustomComplementaryColors(
        tenant.colors.primaryColor
      );
      document.documentElement.style.setProperty(
        '--primaryColorHsl',
        primaryHslCode + ''
      );

      document.documentElement.style.setProperty(
        '--primaryColorRgb',
        `hsla(${primaryHslCode}, 100%, 5%, 1)`
      );
      document.documentElement.style.setProperty(
        '--primaryColorRgba',
        `hsla(${primaryHslCode}, 100%, 50%, 0.8)`
      );

      document.documentElement.style.setProperty(
        '--primaryAvatarColor',
        avatarColors.greenShade
      );

      document.documentElement.style.setProperty(
        '--secondaryAvatarColor',
        avatarColors.pinkShade
      );
    }
    if (isDisplayWelcomeScreen(tenant?.modules)) {
      document.documentElement.style.setProperty('--body-bg-color', `#ffffff`);
    }
  };

  useEffect(() => {
    setTheme();
  }, [tenant]);
};

export default useTenantTheme;
