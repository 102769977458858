import authHeader from './auth-header';
import BaseRequestService from './baseRequest.service';
const API_URL = process.env.REACT_APP_API_URL + '/api';

class TransactionService extends BaseRequestService {
  async getTransaction(transId) {
    const response = await this.get(
      `/transactions/${transId}`,
      {
        headers: authHeader(),
      },
      { fullResponse: true }
    );

    return response?.data;
  }

  async getTransactionStream(transId) {
    return fetch(`${API_URL}/transactions/${transId}/stream`, {
      method: 'GET',
      headers: { ...authHeader(), 'Content-Type': 'application/json' },
    });
  }
}
export default new TransactionService();
