import React, { useEffect, useState } from 'react';
import { Spinner } from 'reactstrap';
import { API } from '../../services/api';
import lessonService from '../../services/lesson.service';
import {
  ADD_TO_LESSON,
  REMOVE_FROM_FAVORITES,
  START_LESSON,
  START_NEW_LESSON,
  RETAKE_LABEL,
  NEXT_LABEL,
  FINISH_COURSES,
  RETAKE_COURSE,
  CONGRATULATIONS,
  CONTINUE_LESSON,
} from '../../utils/constants';
import TooltipComponent from './Tooltip';
import AlertWrapper from '../Alert/AlertWrapper';
import Alert from '../Alert/Alert';
import MaterialIcon from '../commons/MaterialIcon';
import TopicIcon from '../commons/TopicIcon';
import { categoriesDefaultInfo } from '../../views/Resources/category/constants/Category.constants';
import useUrlSearchParams from '../../hooks/useUrlSearchParams';
import { useHistory } from 'react-router-dom';
import { isModuleAllowed } from '../../utils/Utils';
import { useTenantContext } from '../../contexts/TenantContext';
import TextOverflowTooltip from '../commons/TextOverflowTooltip';

export default function Hero(props) {
  const {
    title,
    lesson: {
      id,
      pages,
      documents,
      max_points: maxPoints,
      icon,
      category,
      duration,
    } = {},
    jump,
    next,
    getLessonId,
    isLast,
    setNextLessons,
    setGetLessonId,
    setRefresh,
    handleRetakeCourse,
    nextLessons,
    lessons,
    course,
    setRefreshFav,
    isLastStep = false,
  } = props || {};
  const api = new API();
  const history = useHistory();
  const [label, setLabel] = useState(START_LESSON);
  const [completed, setCompleted] = useState(false);
  const [pageId, setPageId] = useState(null);
  const searchParams = useUrlSearchParams();
  const customId = searchParams.get('customViewId');
  const defaultType = searchParams?.get('viewType');
  const [favorite, setFavorite] = useState(false);
  const [points, setMaxpoints] = useState(maxPoints);
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [progress, setProgress] = useState(undefined);
  const [isLoading, setIsLoading] = useState(false);
  const pathType = category?.title?.replace(/ /g, '-').toLowerCase();
  const { tenant } = useTenantContext();
  const [favoriteInProgress, setFavoriteInProgress] = useState(false);
  const [render, setRender] = useState(0);

  const retakeLesson = async () => {
    setGetLessonId(id);
    const pl = {
      pageId: null,
    };

    const resp = await api
      .TrackLesson(id, pl) // TODO Why we have not added it in LessonService
      .catch((err) => console.log(err));

    if (resp) {
      jump(1);
      setRefresh((prevState) => prevState + 1);
    }
  };

  const goToNextLesson = async () => {
    if (nextLessons?.length > 0) {
      checkLessonProgressAndRedirect();
    } else {
      if (defaultType === 'my-learning') {
        history.push(`/learn?viewType=my-learning`);
      } else {
        if (defaultType === 'custom') {
          history.push(
            `/learn?viewType=${defaultType}&ref=${'my-organization'}`
          );
        } else {
          history.push(
            `/learn?id=${
              category?.id
            }&viewType=${defaultType}&path=${pathType}&ref=${category?.title}${
              defaultType ? `&type=${defaultType}` : ''
            }`
          );
        }
      }
    }
    setRefresh((prevState) => prevState + 1);
  };

  const checkLessonProgressAndRedirect = (first) => {
    let redirectLessonId = 0;

    if (nextLessons?.length > 0) {
      redirectLessonId = getLessonId || nextLessons[0]?.id;
    }
    if (redirectLessonId) {
      const availableInProgressLessons = nextLessons.filter(
        (item) => item.id !== redirectLessonId
      );
      setGetLessonId(availableInProgressLessons[0]?.id);
      setNextLessons(availableInProgressLessons);
    }
  };

  useEffect(() => {
    (async () => {
      if (progress === undefined) {
        return;
      }
      if (isLast() && progress?.status === 'completed') {
        setCompleted(true);
        return setLabel(START_NEW_LESSON);
      }

      if (progress?.page_id && progress?.status === 'in_progress') {
        if (isLast()) {
          setIsLoading(true);
          const resp = await lessonService
            .GetLessonTrackByLessonId(id, { self: true })
            .catch((err) => console.log(err));
          setIsLoading(false);
          setProgress(resp);
        }
        return setLabel(START_LESSON);
      }

      if (progress?.status === 'completed') {
        setCompleted(true);
        return setLabel(START_NEW_LESSON);
      }
    })();
  }, [progress]);

  useEffect(() => {
    if (id) {
      (async () => {
        // progress returns null....
        // setIsLoading(true);
        setTimeout(async () => {
          if (progress === null || progress) {
            return;
          }

          const resp = await lessonService
            .GetLessonTrackByLessonId(id, { self: true })
            .catch((err) => console.log(err));
          setIsLoading(false);
          setProgress(resp);
          if (!resp) {
            return;
          }
          const { page_id: trackPageId, attempts, points } = resp;

          setPageId(trackPageId);

          const lessonPoints =
            points || (attempts < maxPoints ? maxPoints - attempts : 0);

          setMaxpoints(lessonPoints);
        }, 1000);
      })();
    }
  }, []);

  useEffect(() => {
    setRender(render + 1);
  }, []);

  useEffect(() => {
    if (lessons?.length > 0) {
      if (
        lessons?.some(
          (lesson) =>
            lesson.id === id && lesson.preferences[0]?.isFavorite === true
        )
      ) {
        setFavorite(true);
      }
    }
  }, [lessons]);

  const onStartOrContinue = async () => {
    if (isLast() || progress?.status === 'completed') {
      // Done this because all pages comes completed but lesson progress 67 and in progress have to fix in backend
      const pl = {
        pageId: pages[pages?.length - 1]?.id,
      };
      setIsLoading(true);
      const resp = await api
        .TrackLesson(id, pl) // TODO Why we have not added it in LessonService
        .catch((err) => console.log(err));
      setProgress(resp);
      setIsLoading(false);
    } else if (pageId) {
      const jumpTo = pages.find((page) => page.id === pageId);
      if (jumpTo.order === pages.length) {
        jump(jumpTo.order + 1);
      } else {
        // + 1 skipping the current slide when you resume lesson, dont know why so removing it
        jump(jumpTo.order + 2); // i dunno why this works?
      }
    } else {
      next();
    }
    let redirectLessonId = 0;
    if (nextLessons?.length > 0) {
      redirectLessonId = getLessonId || nextLessons[0]?.id;
    }
    if (redirectLessonId) {
      const availableInProgressLessons = nextLessons.filter(
        (item) => item.id !== redirectLessonId
      );
      setNextLessons(availableInProgressLessons);
    }
  };

  async function onHandleFavorite(e) {
    e.preventDefault();
    setFavoriteInProgress(true);

    try {
      const favorite = await lessonService.PutFavoriteByLessonId({ id });

      if (favorite) {
        setFavorite(favorite.isFavorite);

        const message = favorite.isFavorite
          ? `The lesson has been added to favorites`
          : `The lesson has been removed from favorites`;

        setSuccessMessage(message);
      }
    } catch (error) {
      console.error('Error handling favorite action:', error);
      setErrorMessage(`An error occurred while updating the lesson favorites`);
    } finally {
      setFavoriteInProgress(false);
      setRefreshFav((prevState) => prevState + 1);
    }
  }

  const onDownload = async () => {
    try {
      const file = await lessonService.PdfLinkByLesson(documents);

      if (!file) {
        setErrorMessage('File not found');
        return;
      }

      const data = new Blob([file], { type: 'application/pdf' });
      const fileUrl = window.URL.createObjectURL(data);
      window.open(fileUrl);
    } catch (error) {
      setErrorMessage('File not found');
    }
  };

  const RetakeCourse = () => {
    setGetLessonId('');
    handleRetakeCourse();
  };

  const Loader = () => {
    return (
      <div className="mt-20 text-center">
        <Spinner color="primary" size="sm" className="spinner-grow-sm2" />
      </div>
    );
  };

  console.log('points', points);

  return (
    <>
      <AlertWrapper>
        <Alert
          message={errorMessage}
          setMessage={setErrorMessage}
          color="danger"
        />
        <Alert
          message={successMessage}
          setMessage={setSuccessMessage}
          color="success"
        />
      </AlertWrapper>

      <div className="position-relative active h-100 py-2">
        {isLoading ? (
          <Loader />
        ) : (
          <>
            <div className="d-flex flex-column justify-content-between h-100 w-100">
              <div className="flex-grow-1 align-items-start d-flex">
                <TextOverflowTooltip
                  textStyle="fs-5 card-title mb-0 px-0 text-left"
                  text={title || ''}
                  maxLength={60}
                />
              </div>
              <div className="flex-grow-1 align-items-center d-flex flex-column justify-content-center w-100">
                {isLastStep ? (
                  <div
                    className="h2 font-weight text-gray-500 mb-3"
                    data-uw-styling-context="true"
                  >
                    {CONGRATULATIONS}
                  </div>
                ) : (
                  ''
                )}
                {completed && course ? (
                  <button
                    type="button"
                    className="btn btn-primary btn-pill px-5 py-2"
                    onClick={goToNextLesson}
                  >
                    {nextLessons?.length > 0 ? NEXT_LABEL : FINISH_COURSES}
                  </button>
                ) : completed || nextLessons?.length === 0 ? (
                  <button
                    type="button"
                    className="btn btn-primary btn-pill px-5 py-2"
                    onClick={
                      nextLessons?.length === 0 ? RetakeCourse : retakeLesson
                    }
                  >
                    {nextLessons?.length === 0 ? RETAKE_COURSE : RETAKE_LABEL}
                  </button>
                ) : (
                  <button
                    type="button"
                    className="btn btn-primary btn-pill px-5 py-2"
                    onClick={onStartOrContinue}
                  >
                    {progress?.progress > 0 ? CONTINUE_LESSON : label}
                  </button>
                )}

                {/* {!completed && (
                  <button
                    type="button"
                    className="btn btn-primary btn-pill px-5 py-2"
                    onClick={onStartOrContinue}
                  >
                    {label}
                  </button>
                )}
                {course && completed && (
                  <button
                    type="button"
                    className="btn btn-primary btn-pill px-5 py-2"
                    onClick={goToNextLesson}
                  >
                    {nextLessons?.length > 0 ? NEXT_LABEL : FINISH_COURSES}
                  </button>
                )}
                {(nextLessons?.length === 0 || completed) && (
                  <button
                    type="button"
                    className="btn btn-primary btn-pill px-5 py-2"
                    onClick={
                      nextLessons?.length === 0 ? RetakeCourse : retakeLesson
                    }
                  >
                    {nextLessons?.length === 0 ? RETAKE_COURSE : RETAKE_LABEL}
                  </button>
                )} */}
              </div>
              <div className="flex-grow-1 align-items-end d-flex justify-content-between w-100">
                <span>
                  <TopicIcon
                    filled={false}
                    icon={
                      category?.icon ||
                      icon ||
                      categoriesDefaultInfo[category?.title] ||
                      'savings'
                    }
                    iconBg=""
                    iconClasses="text-primary"
                    iconSize="font-size-5em"
                  />
                </span>

                {customId !== id && (
                  <div className="d-flex align-items-center gap-2">
                    {documents && (
                      <TooltipComponent title="Download lessons">
                        <button
                          className="cursor-pointer btn btn-icon icon-ignore btn-icon-sm btn-sm rounded-circle"
                          onClick={onDownload}
                        >
                          <MaterialIcon icon="download_for_offline" />
                        </button>
                      </TooltipComponent>
                    )}

                    <div className="d-flex gap-1 align-items-center text-muted font-size-sm2">
                      <MaterialIcon icon="schedule" />
                      {`${duration ? Math.round(duration) : 0} min`}
                    </div>

                    <div className="d-none-">
                      {isModuleAllowed(
                        tenant.modules,
                        'learns_lesson_catalog'
                      ) ? (
                        favoriteInProgress ? (
                          <Spinner style={{ width: 16, height: 16 }} />
                        ) : (
                          <TooltipComponent
                            title={
                              favorite ? REMOVE_FROM_FAVORITES : ADD_TO_LESSON
                            }
                          >
                            <button
                              className="btn btn-icon btn-icon-sm icon-ignore btn-sm rounded-circle cursor-pointer"
                              data-original-title={
                                favorite ? REMOVE_FROM_FAVORITES : ADD_TO_LESSON
                              }
                              onClick={(e) => onHandleFavorite(e)}
                            >
                              <MaterialIcon
                                filled={favorite}
                                icon="favorite"
                                clazz="text-black"
                              />
                            </button>
                          </TooltipComponent>
                        )
                      ) : (
                        ''
                      )}
                    </div>
                  </div>
                )}
              </div>
            </div>
          </>
        )}
      </div>
    </>
  );
}
