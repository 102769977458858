import React from 'react';
import ChecklistStatus from '../checklist/ChecklistStatus';
import { ChecklistStatuses } from '../../utils/checklist.constants';
import { DRAFT } from '../../utils/constants';

// will use this component wherever we want to display color statuses
const StatusLabel = ({ status, style }) => {
  const statusClasses = {
    published: ChecklistStatuses.Completed.text,
    active: ChecklistStatuses.Completed.text,
    draft: ChecklistStatuses.NotStarted.text,
    deleted: ChecklistStatuses.Deleted.text,
    Yes: ChecklistStatuses.Completed.text,
    No: ChecklistStatuses.Deleted.text,
  };
  return (
    <ChecklistStatus
      item={{
        status: statusClasses[status || DRAFT],
        statusText: status || DRAFT,
      }}
      style={style}
    />
  );
};

export default StatusLabel;
