import React from 'react';
const DarkStatusBadge = ({ item = {}, fontSize = '' }) => {
  return (
    <div
      className={`badge px-2 dark-status label d-inline-flex align-items-center justify-content-center rounded-pill text-capitalize ${fontSize} ${item.color}`}
    >
      <span>{item.text}</span>
    </div>
  );
};
export default DarkStatusBadge;
