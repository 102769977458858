/* eslint-disable no-useless-escape */
import React, { useEffect, useRef, useState } from 'react';
import NoDataFoundTitle from '../fields/NoDataFoundTitle';
import NoDataFound from '../commons/NoDataFound';
import { Col, Input, Row } from 'reactstrap';
import ButtonIcon from '../commons/ButtonIcon';
import Alert from '../Alert/Alert';
import AlertWrapper from '../Alert/AlertWrapper';
import checklistService from '../../services/checklist.service';
import { Skeleton } from '@mui/material';
import { createBlobObject, isPermissionAllowed } from '../../utils/Utils';
import assetsService from '../../services/assets.service';
import { FILE_DOESNT_EXIST } from '../../utils/constants';
import DocumentConfirmationModal from '../modal/DocumentConfirmationModal';
import { ChecklistFieldsTabs } from '../../utils/checklist.constants';
import useWistiaEmbedPlayer from '../../hooks/useWistiaEmbedPlayer';
import useYoutubeEmbedPlayer from '../../hooks/useYoutubeEmbedPlayer';
const ChecklistItem = ({
  item,
  index,
  onUpload,
  onOpen,
  openLoading,
  clientPortalToken,
  onDownload,
  downloading,
}) => {
  const [file, setFile] = useState({});
  const [showFileModal, setShowFileModal] = useState(false);
  const [modelType, setModalType] = useState('');
  const ref = useRef(null);
  const [downloadAvailable, setDownloadAvailable] = useState(false);
  const [getVideoId, setGetVideoId] = useState('');
  const { WistiaEmbedModal, setShowModal } = useWistiaEmbedPlayer(
    getVideoId,
    false,
    true,
    item,
    'Done'
  );

  const { YoutubeEmbedModal, setVideoModalShow, setLoading } =
    useYoutubeEmbedPlayer(getVideoId, item, false, 'Done');

  const handleDownload = (item) => {
    try {
      onDownload(item);
      setDownloadAvailable(false);
    } catch (e) {
      console.log(e);
    }
  };

  const handleLoadFile = (e) => {
    const file = e.target.files[0];
    setFile({ file, name: file.name });
    setModalType(item?.action === 'signature' ? 'signature' : 'upload');
    setShowFileModal(true);
  };
  const uploadFile = () => {
    onUpload(item, file?.file);
    setShowFileModal(false);
  };
  const handleConfirmAck = (type) => {
    setShowFileModal(true);
    setModalType(type);
  };

  const handleModalConfirm = (modalType) => {
    setShowFileModal(false);
    modalType === 'ack' || modalType === 'watch' ? onOpen(item) : uploadFile();
  };

  useEffect(() => {
    if (!downloadAvailable) {
      setDownloadAvailable(!!item?.fileId || !!item?.videoURL);
    }
  }, []);

  const handlePlayVideo = (item) => {
    const url = item?.videoURL;
    if (url?.includes('medias')) {
      setShowModal(true);
    } else if (url?.includes('youtu')) {
      setVideoModalShow(true);
    }
    setDownloadAvailable(false);
  };

  useEffect(() => {
    const getVideoId = (url) => {
      if (url?.includes('medias')) {
        const match = url?.match(/\/medias\/([^\\/]+)$/);
        return match ? match[1] : null;
      } else if (url?.includes('youtu')) {
        const match = url?.match(/youtu\.be\/([^\/?]+)/);
        return match ? match[1] : null;
      }
      return null;
    };
    if (item?.videoURL) {
      setLoading(true);
      const videoId = getVideoId(item?.videoURL);
      setGetVideoId(videoId);
      setLoading(false);
    }
  }, [item?.videoURL]);

  return (
    <Row key={index} className="py-1 align-items-center mt-2">
      <WistiaEmbedModal />
      <YoutubeEmbedModal />
      <Col md={5}>
        <div className="d-flex align-items-center gap-1">
          <p className="mb-0">
            <span
              style={{ width: 28, height: 28 }}
              className="bg-gray-100 rounded-circle d-flex fs-8 border align-items-center justify-content-center font-weight-semi-bold"
            >
              {index + 1}
            </span>
          </p>
          <p className="mb-0" style={{ lineHeight: '20px' }}>
            {item.title}
          </p>
        </div>
      </Col>
      {(isPermissionAllowed('checklists', 'edit') ||
        clientPortalToken?.contact_id) && (
        <Col md={7} className="text-right align-items-end">
          {item?.action === 'watch' ? (
            <div
              className={`d-flex gap-1 justify-content-end align-items-center`}
            >
              <ButtonIcon
                icon="play_circle"
                classnames={`btn-xs fs-7 ${
                  item?.videoURL ? 'd-flex align-items-center' : 'd-none'
                }`}
                color="white"
                style={{ minWidth: 110 }}
                label="Play"
                iconClass="fs-5"
                loading={downloading.checklistItemId === item.checklistItemId}
                symbols={true}
                onclick={() => handlePlayVideo(item)}
              />

              <ButtonIcon
                filled={item?.status === 'watched'}
                tooltipTextFont={true}
                tooltip={
                  item?.status !== 'watched' && downloadAvailable
                    ? 'To complete this step, please access and review the associated file or video.'
                    : ''
                }
                rounded={item?.status === 'watched'}
                icon={
                  item?.status === 'watched' ? 'check_circle' : 'visibility'
                }
                iconClass="fs-5"
                color={item?.status === 'watched' ? 'success' : 'primary'}
                classnames={`btn-xs text-capitalize  d-flex align-items-center gap-1 justify-content-center ${
                  item?.status === 'watched' ? 'pe-none' : ''
                }`}
                label={item?.status === 'watched' ? 'Completed' : 'Acknowledge'}
                loading={openLoading?.checklistItemId === item?.checklistItemId}
                style={{ width: 150 }}
                onclick={() => handleConfirmAck('watch')}
                disabled={item?.status !== 'watched' && downloadAvailable}
              />
            </div>
          ) : (
            <div
              className={`d-flex gap-1 justify-content-end align-items-center`}
            >
              <ButtonIcon
                icon="download"
                classnames={`btn-xs fs-7 ${
                  item?.fileId || item?.progressFileId
                    ? 'd-flex align-items-center'
                    : 'd-none'
                }`}
                color="white"
                label="Download"
                iconClass="fs-5"
                loading={downloading.checklistItemId === item.checklistItemId}
                symbols={true}
                onclick={() => handleDownload(item)}
              />
              {item?.action !== 'acknowledge' ? (
                <label
                  htmlFor={`checklistFile`}
                  className="mb-0"
                  style={{ display: 'contents' }}
                >
                  <ButtonIcon
                    disabled={
                      !(
                        item?.status === 'uploaded' || item?.status === 'signed'
                      ) && downloadAvailable
                    }
                    tooltipTextFont={true}
                    tooltip={
                      !(
                        item?.status === 'uploaded' || item?.status === 'signed'
                      ) && downloadAvailable
                        ? 'To complete this step, please access and review the associated file or video.'
                        : ''
                    }
                    filled={
                      item?.status === 'uploaded' || item?.status === 'signed'
                    }
                    rounded={
                      item?.status === 'uploaded' || item?.status === 'signed'
                    }
                    iconClass="fs-5"
                    icon={
                      item?.status === 'uploaded' || item?.status === 'signed'
                        ? 'check_circle'
                        : item.action === 'upload'
                        ? 'file_upload'
                        : item?.action === 'signature'
                        ? 'draw'
                        : ''
                    }
                    color={
                      item?.status === 'uploaded' || item?.status === 'signed'
                        ? 'success'
                        : 'primary'
                    }
                    classnames={`btn-xs text-capitalize d-flex align-items-center gap-1 justify-content-center ${
                      item?.status === 'uploaded' || item?.status === 'signed'
                        ? 'pe-none '
                        : ''
                    }`}
                    loading={
                      openLoading?.checklistItemId === item?.checklistItemId
                    }
                    label={
                      item?.status === 'uploaded' || item?.status === 'signed'
                        ? 'Completed'
                        : item.action !== 'acknowledge'
                        ? 'Upload File'
                        : ''
                    }
                    style={{ width: 150 }}
                    onclick={(e) => {
                      ref.current.click();
                    }}
                  />
                </label>
              ) : (
                <ButtonIcon
                  filled={item?.status === 'acknowledged'}
                  tooltipTextFont={true}
                  tooltip={
                    item?.status !== 'acknowledged' && downloadAvailable
                      ? 'To complete this step, please access and review the associated file or video.'
                      : ''
                  }
                  rounded={item?.status === 'acknowledged'}
                  icon={
                    item?.status === 'acknowledged'
                      ? 'check_circle'
                      : 'visibility'
                  }
                  iconClass="fs-5"
                  color={
                    item?.status === 'acknowledged' ? 'success' : 'primary'
                  }
                  classnames={`btn-xs text-capitalize  d-flex align-items-center gap-1 justify-content-center ${
                    item?.status === 'acknowledged' ? 'pe-none' : ''
                  }`}
                  label={
                    item?.status === 'acknowledged'
                      ? 'Completed'
                      : item.action?.charAt(0).toUpperCase() +
                        item?.action?.slice(1)
                  }
                  loading={
                    openLoading?.checklistItemId === item?.checklistItemId
                  }
                  style={{ width: 150 }}
                  onclick={() => handleConfirmAck('ack')}
                  disabled={
                    item?.status !== 'acknowledged' && downloadAvailable
                  }
                />
              )}
              <Input
                type="file"
                name={file?.name}
                innerRef={ref}
                id={`checklistFile`}
                onClick={(e) => {
                  e.target.value = '';
                }}
                className="d-none"
                onChange={handleLoadFile}
              />
            </div>
          )}
        </Col>
      )}
      <DocumentConfirmationModal
        item={item}
        showModal={showFileModal}
        description={
          modelType === 'ack' ? (
            <p>
              Confirm if you have reviewed the required &ldquo;
              <b className="text-capitalize">{item?.title}</b>&ldquo; content.
            </p>
          ) : modelType === 'signature' ? (
            <p>
              Confirm if you have signed the required &ldquo;
              <b className="text-capitalize">{item?.title}</b>&ldquo; content.
            </p>
          ) : modelType === 'watch' ? (
            <p>
              Confirm if you have watched the required &ldquo;
              <b className="text-capitalize">{item?.title}</b>&ldquo; video.
            </p>
          ) : (
            <p>
              Confirm if you have completed the required &ldquo;
              <b className="text-capitalize">{item?.title}</b>&ldquo; content.
            </p>
          )
        }
        setShowModal={setShowFileModal}
        event={() => handleModalConfirm(modelType)}
      />
    </Row>
  );
};

const ChecklistItems = ({
  checklist = {},
  getChecklistProgress,
  clientPortalToken,
  getChecklistItems,
  clientPortal = false,
  setRefresh,
  setErrorMessage,
}) => {
  const [openLoading, setOpenLoading] = useState({});
  const [downloading, setDownloading] = useState({});
  const [successMessage, setSuccessMessage] = useState('');
  const [items, setItems] = useState([]);
  const [isItemsLoading, setIsItemsLoading] = useState(false);
  const [markAsDone] = useState(false);
  const [progress, setProgress] = useState([]);
  const getChecklistItemsData = async (id) => {
    setIsItemsLoading(true);
    try {
      const { data } = await checklistService.getCheckListItemsById(id, {
        limit: 100,
        page: 1,
      });
      const checklistItemsProgress =
        await checklistService.getOrgCheckListItemProgress({
          limit: 100,
          page: 1,
          organizationChecklistProgressId:
            getChecklistProgress?.organizationChecklistProgressId,
        });
      const progressIds = new Set(
        checklistItemsProgress?.data?.data.map((item) => item.checklistItemId)
      );
      const updatedItems = data?.data.map((item) => {
        if (progressIds.has(item.checklistItemId)) {
          const progressItem = checklistItemsProgress.data.data.find(
            (progress) => progress.checklistItemId === item.checklistItemId
          );
          return {
            ...item,
            status: progressItem.status,
            completedAt: progressItem.completedAt,
            organizationChecklistItemProgressId:
              progressItem?.organizationChecklistItemProgressId,
            progressFileId: progressItem?.fileId,
          };
        } else {
          return item;
        }
      });
      setProgress(checklistItemsProgress?.data?.data);
      setItems(updatedItems);
    } catch (e) {
      console.log(e);
    } finally {
      setIsItemsLoading(false);
    }
  };
  useEffect(() => {
    if (
      Object.keys(checklist)?.length > 0 &&
      getChecklistProgress?.organizationChecklistProgressId
    ) {
      getChecklistItemsData(checklist?.checklistId);
    }
  }, [
    checklist?.checklistId,
    getChecklistProgress?.organizationChecklistProgressId,
  ]);
  const onOpen = async (item) => {
    setOpenLoading(item);
    try {
      const progressObj = {
        status: item?.action === 'acknowledge' ? 'acknowledged' : 'watched',
        ...(item?.fileId ? { fileId: item?.fileId } : ''),
        checklistItemId: item?.checklistItemId,
        ...(clientPortalToken?.contact_id
          ? {
              completedByOrganizationChecklistContactId:
                checklist?.organizationChecklistContactId,
            }
          : ''),
      };
      await checklistService.createOrgCheckListItemProgress(
        item?.organizationChecklistItemProgressId,
        progressObj
      );
      setRefresh((prevState) => prevState + 1);
      if (!clientPortal) {
        getChecklistItems(checklist?.checklistId);
      }
      getChecklistItemsData(checklist?.checklistId);
    } catch (e) {
      console.log(e);
      setErrorMessage(e.response?.data?.errors[0]?.message);
    } finally {
      setOpenLoading({});
    }
  };
  const onUpload = async (item, file) => {
    setOpenLoading(item);
    try {
      const formBlob = await createBlobObject(file);
      const form = new FormData();
      if (clientPortalToken?.contact_id) {
        form.append('createdByAccountId', clientPortalToken?.contact_id);
      }

      form.append('isPublic', false);
      form.append('file', formBlob, file?.name);
      form.append(
        'organizationChecklistId',
        checklist?.organizationChecklistId
      );

      const fileData = await checklistService.createOrgCheckListFile(
        checklist?.organizationChecklistId,
        form
      );
      if (item?.checklistItemId && fileData?.data?.fileId) {
        const progressObj = {
          status:
            item?.action === 'upload'
              ? 'uploaded'
              : item?.action === 'signature'
              ? 'signed'
              : '',
          checklistItemId: item?.checklistItemId,
          fileId: fileData?.data?.fileId,
          ...(clientPortalToken?.contact_id
            ? {
                completedByOrganizationChecklistContactId:
                  checklist?.organizationChecklistContactId,
              }
            : ''),
        };

        await checklistService.createOrgCheckListItemProgress(
          item?.organizationChecklistItemProgressId,
          progressObj
        );
        setRefresh((prevState) => prevState + 1);
        getItemsProgress();
        if (!clientPortal) {
          getChecklistItems(checklist?.checklistId);
        }
        getChecklistItemsData(checklist?.checklistId);
      }
      setSuccessMessage('Document Successfully uploaded');
    } catch (e) {
      console.log(e);
      setErrorMessage(e.response?.data?.errors[0]?.message);
    } finally {
      setOpenLoading({});
    }
  };

  const getItemsProgress = async () => {
    try {
      const checklistItemsProgress =
        await checklistService.getOrgCheckListItemProgress({
          limit: 100,
          page: 1,
          organizationChecklistProgressId:
            getChecklistProgress?.organizationChecklistProgressId,
        });
      setProgress(checklistItemsProgress?.data?.data);
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    if (getChecklistProgress?.organizationChecklistProgressId) {
      getItemsProgress();
    }
  }, [getChecklistProgress?.organizationChecklistProgressId]);

  const onDownload = async (item) => {
    try {
      setDownloading(item);
      await assetsService
        .openFile(item?.progressFileId || item?.fileId)
        .catch((_) => {
          setErrorMessage(FILE_DOESNT_EXIST);
        });
    } finally {
      setDownloading({});
    }
  };
  const clientItems = items?.filter((item) => {
    return item?.type === ChecklistFieldsTabs.Client;
  });
  const internalItems = items?.filter((item) => {
    return item?.type === ChecklistFieldsTabs.Internal;
  });

  const getCompletedCount = (list) => {
    return list.filter((item) => !!item.completedAt).length;
  };
  return (
    <>
      <AlertWrapper className="alert-position">
        <Alert
          color="success"
          message={successMessage}
          setMessage={setSuccessMessage}
        />
      </AlertWrapper>
      {isItemsLoading ? (
        <div>
          <div className="d-flex align-items-center gap-2 w-100 mt-3">
            <Skeleton variant="circular" width={20} height={20} />
            <Skeleton variant="rounded" width={'100%'} height={15} />
          </div>
          <div className="d-flex align-items-center gap-2 w-100 mt-3">
            <Skeleton variant="circular" width={20} height={20} />
            <Skeleton variant="rounded" width={'100%'} height={15} />
          </div>
          <div className="d-flex align-items-center gap-2 w-100 mt-3 mb-4">
            <Skeleton variant="circular" width={20} height={20} />
            <Skeleton variant="rounded" width={'100%'} height={15} />
          </div>
        </div>
      ) : (
        <>
          {items?.length ? (
            <>
              {clientItems?.length > 0 && (
                <div className="py-2">
                  {!clientPortal && (
                    <h3 className="mb-0">
                      For Client ({getCompletedCount(clientItems)}/
                      {clientItems.length})
                    </h3>
                  )}
                  {clientItems?.map((item, index) => (
                    <ChecklistItem
                      key={index}
                      item={item}
                      progress={progress}
                      index={index}
                      onUpload={onUpload}
                      openLoading={openLoading}
                      clientPortalToken={clientPortalToken}
                      onOpen={onOpen}
                      onDownload={onDownload}
                      downloading={downloading}
                      markAsDone={markAsDone}
                    />
                  ))}
                </div>
              )}
              {!clientPortal && internalItems?.length > 0 && (
                <div className="py-2">
                  <h3 className="mb-0">
                    For Internal ({getCompletedCount(internalItems)}/
                    {internalItems.length})
                  </h3>
                  {internalItems?.map((item, index) => (
                    <ChecklistItem
                      key={index}
                      item={item}
                      progress={progress}
                      index={index}
                      onUpload={onUpload}
                      openLoading={openLoading}
                      onOpen={onOpen}
                      onDownload={onDownload}
                      downloading={downloading}
                      markAsDone={markAsDone}
                    />
                  ))}
                </div>
              )}
            </>
          ) : (
            <NoDataFound
              useLucidIcon
              icon="FilePenLine"
              iconSize="35"
              containerStyle="text-gray-search my-1 py-1"
              title={
                <NoDataFoundTitle clazz="fs-7" str={`No checklist items`} />
              }
            />
          )}
        </>
      )}
    </>
  );
};

export default ChecklistItems;
