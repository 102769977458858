import axios from './axiosInstance';
import authHeader from './auth-header';

class AuditService {
  async getAudits(
    { resourceId, parentResourceId, ...restProps },
    { page = 1, limit = 15 }
  ) {
    const response = await axios.get(`/audits`, {
      headers: authHeader(),
      params: {
        resourceId,
        parentResourceId,
        limit,
        page,
        ...restProps,
      },
    });

    return response.data;
  }

  async getAuditsWithAssociations(
    { resourceId, ...restProps },
    { page = 1, limit = 15 }
  ) {
    const response = await axios.get(`/auditsWithAssociations`, {
      headers: authHeader(),
      params: {
        resourceId,
        limit,
        page,
        ...restProps,
      },
    });

    return response.data;
  }
}

export default new AuditService();
