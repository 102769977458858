import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { QRCodeSVG } from 'qrcode.react';
import { useTenantContext } from '../../../../contexts/TenantContext';
import WidgetSourceBlock from '../../WidgetSourceBlock';
import MaterialIcon from '../../../commons/MaterialIcon';
import TenantThemeWrapperWidget from './TenantThemeWrapperWidget';
import ButtonIcon from '../../../commons/ButtonIcon';
import useWistiaEmbedPlayer from '../../../../hooks/useWistiaEmbedPlayer';
import { getVideoUrlWithTenant } from '../../../reports/reports.constants';
import { isLucidIcon } from '../../../commons/IconDropdownSearch';

const PlayButton = ({ whenPrinting, widgetConfig }) => {
  const styles = whenPrinting
    ? { position: 'relative', top: -2 }
    : { position: 'relative', top: -1 };
  return (
    <span
      className={`text-nowrap d-inline-flex align-items-center ${
        whenPrinting ? 'fs-11 gap-1-1' : 'fs-10 gap-1'
      }`}
      style={styles}
    >
      <span className="font-weight-medium">Play</span> |{' '}
      <span className="font-italic">
        {widgetConfig.videoDuration || '2 min'}
      </span>
    </span>
  );
};

const QRCodeWidget = ({ widgetConfig, whenPrinting }) => {
  const { tenant } = useTenantContext();
  const { setShowModal, WistiaEmbedModal } = useWistiaEmbedPlayer(
    widgetConfig.videoId,
    widgetConfig?.channel !== undefined
  );
  const qrcodeValue = widgetConfig?.channel
    ? widgetConfig?.channel
    : getVideoUrlWithTenant(tenant, widgetConfig.videoId);

  return (
    <TenantThemeWrapperWidget bodyStyles="d-flex flex-column position-relative border-0">
      <WistiaEmbedModal />
      <div
        className="d-flex flex-column justify-content-center"
        style={{
          minHeight: !whenPrinting ? 200 : 160,
        }}
      >
        {widgetConfig?.headingIcon && (
          <div className="d-flex align-items-start">
            <div className="bg-white p-2 mb-2 d-inline-block text-center rounded-lg">
              <MaterialIcon
                isLucide={isLucidIcon(widgetConfig.headingIcon)}
                icon={widgetConfig.headingIcon}
                size="font-size-2xl"
                clazz="text-primary"
              />
            </div>
          </div>
        )}
        <h5 className="text-left mb-1">{widgetConfig.heading}</h5>
        <Row
          className={`align-items-center flex-grow-1 widget-extra-padding justify-content-center mb-0 ${widgetConfig?.extraPadding}`}
        >
          <Col className="justify-content-center align-items-center">
            <div className="pl-2">
              {widgetConfig?.value && (
                <div className="d-flex justify-content-center mt-2 align-items-center">
                  <div className="bg-white p-2 mb-2 text-center rounded-lg">
                    <h2 className="text-success font-size-2xl mb-0">
                      {widgetConfig.value}
                    </h2>
                  </div>
                </div>
              )}
              <p
                className={`mb-0 ${
                  whenPrinting ? 'fs-9' : 'font-size-sm2'
                } text-left`}
              >
                <span
                  dangerouslySetInnerHTML={{ __html: widgetConfig.description }}
                />
              </p>
            </div>
            {widgetConfig.source && (
              <WidgetSourceBlock text={widgetConfig.source} />
            )}
          </Col>
          <Col
            md={3}
            className="align-items-center justify-content-center d-flex"
          >
            &nbsp;
          </Col>
          <div className="position-absolute d-flex flex-column align-items-center gap-0 abs-center-y right-10">
            <ButtonIcon
              icon=""
              color="primary"
              classnames="btn-xs cursor-default text-nowrap fs-11 font-italic no-hover block border-bottom-0 rounded-bottom-0"
              label="Scan to Watch"
              style={{
                width: whenPrinting ? 80 : 110,
                paddingLeft: whenPrinting ? '3px' : '',
                paddingRight: whenPrinting ? '3px' : '',
                borderRadius:
                  'var(--rpt-widget-border-radius) var(--rpt-widget-border-radius) 0 0',
              }}
            />
            <div
              style={{
                height: whenPrinting ? 80 : 110,
                width: whenPrinting ? 80 : 110,
                borderRadius:
                  '0 0 var(--rpt-widget-border-radius) var(--rpt-widget-border-radius)',
              }}
              className="bg-white justify-content-center d-flex align-items-center flex-column p-2 text-center"
            >
              <QRCodeSVG value={qrcodeValue} />
            </div>
            <ButtonIcon
              useLucidIcon={true}
              icon="CirclePlay"
              iconSize="18"
              color="primary"
              classnames={`btn-xs block rounded-pill text-nowrap mt-1 ${
                whenPrinting ? 'py-0 px-1' : 'px-2_1'
              }`}
              label={
                <PlayButton
                  whenPrinting={whenPrinting}
                  widgetConfig={widgetConfig}
                />
              }
              onclick={() => setShowModal(true)}
            />
          </div>
        </Row>
      </div>
    </TenantThemeWrapperWidget>
  );
};

export default QRCodeWidget;
