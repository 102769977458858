import { Card, CardBody } from 'reactstrap';
import React from 'react';
import WidgetSourceBlock from '../../WidgetSourceBlock';
import MaterialIcon from '../../../commons/MaterialIcon';

const ExporterItems = [
  'Consignment',
  'Open Account',
  'Documentary Collection',
  'Letters of Credit',
  'Cash-in-Advance',
]; // blue
const ImporterItems = [
  'Cash-in-Advance',
  'Letters of Credit',
  'Documentary Collection',
  'Open Account',
  'Consignment',
]; // purple

const ExportImportTags = ({ items, tagStyle }) => {
  return (
    <div className="d-flex justify-content-center my-3 align-items-center gap-2">
      {items.map((item) => (
        <div
          key={item}
          style={{
            background: tagStyle.bg,
            color: tagStyle.fc,
            border: `1px solid ${tagStyle.bg}`,
          }}
          className="rounded p-2 text-center fs-9 font-weight-semi-bold"
        >
          {item}
        </div>
      ))}
    </div>
  );
};

const InternationalBankingCrossBorderPaymentsWidget = ({
  widgetConfig,
  whenPrinting,
}) => {
  return (
    <Card className="report-widget">
      <CardBody className="d-flex align-items-center justify-content-center">
        <div className="flex-fill">
          <h5 className="text-left mb-1 d-flex align-items-center gap-1">
            {widgetConfig.heading}
          </h5>
          <p
            className={`${
              whenPrinting ? 'fs-9' : 'font-size-sm2'
            } mb-1 text-left`}
          >
            {widgetConfig.description}
          </p>
          <ExportImportTags
            items={ExporterItems}
            tagStyle={{ bg: '#E0F2FE', fc: '#026AA2' }}
          />
          <div className="d-flex align-items-center justify-content-between">
            <div className="text-center px-5">
              <h6>Least Secure</h6>
            </div>
            <div className="d-flex flex-fill gap-2 flex-column align-items-center">
              <div className="position-relative">
                <img src="/img/Vector%2078.png" className="w-100" />
                <div className="position-absolute w-100 abs-center-xy">
                  <p
                    style={{ color: '#026AA2' }}
                    className="fs-10 mb-1 d-flex align-items-center justify-content-center gap-2 font-weight-semi-bold text-center font-size-xl m-auto"
                  >
                    <div
                      className="p-1 rounded-circle d-flex align-items-center justify-content-center"
                      style={{ background: '#026AA2', color: '#fff' }}
                    >
                      <MaterialIcon icon="arrow_upward" />
                    </div>
                    Exporter
                  </p>
                </div>
              </div>
              <div className="position-relative">
                <img src="/img/Vector%2079.png" className="w-100" />
                <div className="position-absolute w-100 abs-center-xy">
                  <p
                    style={{ color: '#6941C6' }}
                    className="fs-10 mb-1 d-flex align-items-center justify-content-center gap-2 font-weight-semi-bold text-center font-size-xl m-auto"
                  >
                    <div
                      className="p-1 rounded-circle d-flex align-items-center justify-content-center"
                      style={{ background: '#6941C6', color: '#fff' }}
                    >
                      <MaterialIcon icon="arrow_downward" />
                    </div>
                    Importer
                  </p>
                </div>
              </div>
            </div>
            <div className="text-center px-5">
              <h6>Most Secure</h6>
            </div>
          </div>
          <ExportImportTags
            items={ImporterItems}
            tagStyle={{ bg: '#E9D7FE', fc: '#6941C6' }}
          />
          {widgetConfig?.source && (
            <WidgetSourceBlock text={widgetConfig.source} />
          )}
        </div>
      </CardBody>
    </Card>
  );
};

export default InternationalBankingCrossBorderPaymentsWidget;
