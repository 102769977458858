/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import { Card, CardBody, CardFooter, CardHeader } from 'reactstrap';

import OpportunitiesStats from './OpportunitiesStats';
import useIsTenant from '../../../../hooks/useIsTenant';
import Pagination from '../../../../components/Pagination';
import dealServices from '../../../../services/deal.service';
import teamsService from '../../../../services/teams.service';
import GenericTable from '../../../../components/GenericTable';
import { paginationDefault } from '../../../../utils/constants';
import { ReportSkeletonLoader } from '../../ReportSkeletonLoader';
import pipelineService from '../../../../services/pipeline.services';
import TableFooterStats from '../../../../components/TableFooterStats';
import IdfTooltip from '../../../../components/idfComponents/idfTooltip';
import { getTomorrowDate, FormattedValue } from '../../../../utils/Utils';
import { StatItemColors } from '../../../../components/reports/reports.constants';
import DatePickerTraining from '../../../../components/dealsBreakdown/DatePickerTraining';
import { individualSalesOverviewColumns } from '../../../../utils/opportunities.constants';
import ReportSearchOptionDropdown from '../../../../components/commons/ReportSearchOptionDropdown';
import {
  changePaginationLimit,
  changePaginationPage,
} from '../../../Deals/contacts/utils';

const StatsData = [
  {
    id: 'TotalSales',
    icon: 'DollarSign',
    color: StatItemColors.financial,
    iconColor: 'text-rpt-green',
    label: 'Total Sales',
    value: '6002900',
    count: 6002900,
    rounded: true,
    type: 'currency',
  },
  {
    id: 'NoOfDeals',
    icon: 'Briefcase',
    color: StatItemColors.volume,
    iconColor: 'text-rpt-blue',
    label: 'No of Deals',
    value: '335',
    count: 335,
    type: 'number',
  },
  {
    id: 'WonDeals',
    icon: 'Award',
    color: StatItemColors.achievement,
    iconColor: 'rpt-orange-box-heading',
    label: 'Won Deals',
    value: '183',
    count: 183,
    type: 'number',
  },
  {
    id: 'PercentageWon',
    icon: 'Percent',
    color: StatItemColors.performance,
    iconColor: 'text-rpt-green',
    label: 'Percentage Won',
    value: '55.1',
    count: 55.1,
    type: 'percentage',
  },
];

const StatsDataFooter = [
  {
    id: 'TotalSales',
    icon: 'DollarSign',
    color: StatItemColors.financial,
    iconColor: 'text-rpt-green',
    label: 'Sales',
    value: '6002900',
    count: 6002900,
    rounded: true,
    type: 'currency',
  },
  {
    id: 'NoOfDeals',
    icon: 'Briefcase',
    color: StatItemColors.volume,
    iconColor: 'text-rpt-blue',
    label: '# of Deals',
    value: '335',
    count: 335,
    type: 'number',
  },
  {
    id: 'WonDeals',
    icon: 'Award',
    color: StatItemColors.achievement,
    iconColor: 'rpt-orange-box-heading',
    label: 'Won Deals',
    value: '183',
    count: 183,
    type: 'number',
  },
  {
    id: 'PercentageWon',
    icon: 'Percent',
    color: StatItemColors.performance,
    iconColor: 'text-rpt-green',
    label: 'Won Rate %',
    value: '55.1',
    count: 55.1,
    type: 'percentage',
  },
];

const IndividualSalesOverview = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [order, setOrder] = useState();
  const [pagination, setPagination] = useState(paginationDefault);
  const [recordsCount, setRecordsCount] = useState(0);
  const [applyDateRange, setApplyDateRange] = useState(false);

  const { isDemo } = useIsTenant();

  const [show, setShow] = useState(false);
  const [pipelines, setPipelines] = useState([]);
  const [pipelineIds, setPipelinesIds] = useState([]);
  const [selectedPipelinesKeys, setSelectedPipelinesKeys] = useState([]);

  const [showTeams, setShowTeams] = useState(false);
  const [teamsList, setTeamsList] = useState([]);
  const [teamsIds, setTeamssIds] = useState([]);
  const [selectedTeamsKeys, setSelectedTeamsKeys] = useState([]);

  const [dateRange, setDateRange] = useState({
    startDate: new Date(1970, 0, 1),
    endDate: getTomorrowDate(),
  });

  const mapMonthlySalesPerformance = [
    ...data?.map((item) => ({
      ...item,
      dataRow: [
        {
          key: 'sales_person',
          component: (
            <span>
              {item?.firstName} {item?.lastName}
            </span>
          ),
        },
        {
          key: 'sales',
          component: (
            <FormattedValue value={item?.totalAmount} type="currency" />
          ),
        },
        {
          key: 'no_of_deal',
          component: <FormattedValue value={item?.count} />,
        },
        {
          key: 'won_deals',
          component: <FormattedValue value={item?.countOfWon} />,
        },
        {
          key: 'won_deals_percentage',
          component: (
            <FormattedValue value={item?.rateOfWon} type="percentage" />
          ),
        },
        {
          key: 'average_deal_value',
          component: (
            <FormattedValue value={item?.averageDealAmount} type="currency" />
          ),
        },
      ],
    })),
  ];

  const handleSort = (key) => {
    // setOrder([[key?.name?.orderBy, key?.name?.clicked.toLowerCase()]]);
  };

  const getDealsByUser = async () => {
    setLoading(true);
    const response = await dealServices.getDealsByUser(
      dateRange.startDate,
      dateRange.endDate,
      pagination.limit,
      pagination.page
    );
    setData(response.data);
    setLoading(false);
  };

  const getPipelines = async () => {
    const response = await pipelineService.getPipelines(1, 100);
    setPipelines(response?.data);
  };

  const getTeams = async (limit = 100) => {
    const response = await teamsService.getTeams({
      page: 1,
      limit,
      filter: { isActive: true },
    });

    setTeamsList(response?.data);
  };

  useEffect(() => {
    getTeams();
    getDealsByUser();
  }, []);

  useEffect(() => {
    getDealsByUser();

    if (applyDateRange) {
      setApplyDateRange(false);
    }
  }, [applyDateRange, JSON.stringify(pagination)]);

  return (
    <Card className="h-100 border-0 shadow-0">
      <CardHeader>
        <div className="d-flex justify-content-between align-items-center w-100">
          <IdfTooltip text="Individual Sales Overview" placement="bottom">
            <h4 className="card-title text-hover-primary mb-0 text-truncate">
              Individual Sales Overview
            </h4>
          </IdfTooltip>

          <div className="d-flex align-items-center mr-6 pr-9 gap-2">
            <DatePickerTraining
              range={dateRange}
              setRange={setDateRange}
              disablePastDate
              btnToggleStyle="mx-0 w-250"
              toggle={() => setApplyDateRange(true)}
            />

            {isDemo && (
              <>
                <ReportSearchOptionDropdown
                  search
                  maxLength={22}
                  show={show}
                  data={pipelines}
                  setShow={setShow}
                  showCheckAll={false}
                  label={`Pipelines`}
                  setQueryFilters={() => {}}
                  scrollable="pr-3 text-left"
                  handleOptionSelected={() => {}}
                  customStyle={{ width: '250px' }}
                  customKey={['name', 'id']}
                  selectedData={selectedPipelinesKeys}
                  handleAllSelect={() => setPipelinesIds('')}
                  setSelectedData={setSelectedPipelinesKeys}
                  handleOnDone={(items) => setPipelinesIds(items)}
                  controlledTitle={
                    pipelines
                      ?.filter((i) => pipelineIds?.includes(i.id))
                      .map((i) => i.name)
                      .join(',') || ''
                  }
                />

                <ReportSearchOptionDropdown
                  search
                  maxLength={22}
                  show={showTeams}
                  data={teamsList}
                  setShow={setShowTeams}
                  showCheckAll={false}
                  label={`Teams`}
                  setQueryFilters={() => {}}
                  scrollable="pr-3 text-left"
                  handleOptionSelected={() => {}}
                  customStyle={{ width: '220px' }}
                  customKey={['name', 'id']}
                  selectedData={selectedTeamsKeys}
                  handleAllSelect={() => setTeamssIds('')}
                  setSelectedData={setSelectedTeamsKeys}
                  handleOnDone={(items) => setTeamssIds(items)}
                  controlledTitle={
                    teamsList
                      ?.filter((i) => teamsIds?.includes(i.id))
                      .map((i) => i.name)
                      .join(',') || ''
                  }
                />
              </>
            )}
          </div>
        </div>
      </CardHeader>
      <CardBody className="p-0 flex-1 overflow-y-auto">
        {isDemo && (
          <div className="px-2 pt-3">
            <OpportunitiesStats stats={StatsData} />
          </div>
        )}
        {loading ? (
          <div className="w-100 d-block p-1">
            <ReportSkeletonLoader rows={15} height={15} />
          </div>
        ) : (
          <GenericTable
            checkbox={false}
            exportToCSV={true}
            usePagination={false}
            sortingTable={handleSort}
            tableData={data}
            headClass="bg-gray-table-head border-top"
            fileName="Individual Sales Overview"
            noDataInDbValidation={true}
            columns={individualSalesOverviewColumns}
            columnsToExport={individualSalesOverviewColumns}
            data={mapMonthlySalesPerformance}
          />
        )}
      </CardBody>
      <CardFooter className="border-top bg-gray-table-head p-2 border-bottom-0 border-left-0 border-right-0 rounded-0">
        <div className="d-flex justify-content-end w-100">
          {isDemo && (
            <ul className="pl-3 mb-0 flex-nowrap flex-fill">
              <TableFooterStats
                stats={[
                  ...StatsDataFooter,
                  {
                    id: 'avg',
                    count: 107615,
                    rounded: true,
                    type: 'currency',
                    label: 'Avg. Deal',
                  },
                ]}
              />
            </ul>
          )}
          <Pagination
            showPerPage
            paginationInfo={{
              ...pagination,
              totalPages: recordsCount / pagination.limit,
            }}
            onPageChange={(newPage) => {
              changePaginationPage(newPage, setPagination);
            }}
            onLimitChange={(perPage) => {
              changePaginationLimit(perPage, setPagination);
            }}
          />
        </div>
      </CardFooter>
    </Card>
  );
};

export default IndividualSalesOverview;
