import React, { useCallback, useMemo, useState } from 'react';
import { dateWithoutTZ, overflowing } from '../../../../utils/Utils';
import { Card, CardBody, CardHeader } from 'reactstrap';
import RightSlidingDrawer from '../../../modal/RightSlidingDrawer';
import MaterialIcon from '../../../commons/MaterialIcon';
import ChecklistStatus from '../../../checklist/ChecklistStatus';

const CreateCaseModal = ({ show, setShow, caseDetails }) => {
  const handleClose = () => {
    setShow(false);
    overflowing();
  };
  return (
    <RightSlidingDrawer
      open={show}
      containerWidth={450}
      modalClass="bg-white"
      toggleDrawer={handleClose}
    >
      <Card className="p-0 h-100 shadow-none border-0" style={{ width: 450 }}>
        <CardHeader className="justify-content-between bg-gray-5 gap-2 align-items-center">
          <div className="d-flex flex-column align-items-start">
            <h3 className="mb-0 d-flex align-items-center gap-2 text-capitalize">
              <MaterialIcon icon="description" clazz="position-relative" />
              <span className="position-relative">{caseDetails?.title}</span>
            </h3>
          </div>
          <div className="d-flex align-items-center gap-2">
            <a
              className="icon-hover-bg cursor-pointer"
              onClick={(e) => {
                e.preventDefault();
                handleClose();
              }}
            >
              <MaterialIcon icon="close" size="fs-5" rounded />
            </a>
          </div>
        </CardHeader>
        <CardBody className="h-100 smooth-scroll px-3 py-0 ask-ida-modal overflow-y-auto">
          <div className="d-flex py-3 px-2 gap-3 flex-column">
            <div>
              <h5 className="mb-0 text-uppercase fs-9">Details</h5>
              <p>{caseDetails?.description}</p>
            </div>
            <div>
              <h5 className="mb-0 text-uppercase fs-9">Date</h5>
              <p>{dateWithoutTZ(caseDetails?.date)}</p>
            </div>
            <div>
              <h5 className="mb-0 text-uppercase fs-9">Priority Level</h5>
              <p>
                <ChecklistStatus
                  item={{
                    status: caseDetails?.priorityLevel,
                  }}
                />
              </p>
            </div>
            <div>
              <h5 className="mb-0 text-uppercase fs-9">
                Expected Completion Date
              </h5>
              <p>
                {caseDetails?.completionDate
                  ? dateWithoutTZ(caseDetails?.completionDate)
                  : '--'}
              </p>
            </div>
            <div>
              <h5 className="mb-0 text-uppercase fs-9">Status</h5>
              <p>
                <ChecklistStatus
                  item={{
                    status: caseDetails?.done
                      ? 'Completed'
                      : caseDetails?.currentStatus,
                  }}
                />
              </p>
            </div>
            <div>
              <h5 className="mb-0 text-uppercase fs-9">Notes (Read-only)</h5>
              <p>{caseDetails?.notes}</p>
            </div>
          </div>
        </CardBody>
      </Card>
    </RightSlidingDrawer>
  );
};

const useCreateCaseModal = () => {
  const [showModal, setShowModal] = useState(false);
  const [caseDetails, setCaseDetails] = useState({});

  const CreateCaseModalCallback = useCallback(() => {
    return (
      <CreateCaseModal
        show={showModal}
        setShow={setShowModal}
        caseDetails={caseDetails}
      />
    );
  }, [showModal, setShowModal]);

  return useMemo(
    () => ({
      setShowModal,
      setCaseDetails,
      CreateCaseModal: CreateCaseModalCallback,
    }),
    [setShowModal, CreateCaseModalCallback]
  );
};

export default useCreateCaseModal;
