import { Card } from 'react-bootstrap';
import TooltipComponent from '../../components/lesson/Tooltip';
import { numberWithCommas } from '../../utils/Utils';
import { learnStyles } from '../../utils/learn.constants';
import IdfTooltip from '../../components/idfComponents/idfTooltip';
import LucideIcon from '../../components/commons/LucideIcon';
import React from 'react';
import { getLightColor } from '../../components/reports/reports.constants';
import ColorBorder from '../../components/commons/ColorBorder';

const InsightStatItem = ({
  value,
  heading,
  stat,
  subtext,
  skipRounding,
  hideAverage,
  tooltipData = {},
  headingsTitle = {},
  tooltipTitle,
}) => {
  const numberFormatted = subtext !== '%' ? numberWithCommas(stat) : stat;
  const color = learnStyles?.[value]?.color || learnStyles?.Default?.color;

  return (
    <div className="" key={value}>
      <Card
        className="position-relative shadow-none"
        style={{ borderRadius: '0.75rem !important' }}
      >
        <Card.Body className="pr-3" style={{ borderRadius: '0.75rem' }}>
          <ColorBorder color={color} />
          <div className="d-flex align-items-center justify-content-between">
            <IdfTooltip
              text={
                Object.keys(headingsTitle)?.length > 0
                  ? headingsTitle[heading]
                  : heading
              }
              placement="bottom"
            >
              <h5 className="text-gray-800 flex-grow-1 text-truncate">
                {Object.keys(headingsTitle)?.length > 0
                  ? headingsTitle[heading]
                  : heading}
              </h5>
            </IdfTooltip>

            {(Object?.keys(tooltipData)?.length > 0 && tooltipData[heading]) ||
            tooltipTitle ? (
              <TooltipComponent title={tooltipData[heading] || tooltipTitle}>
                <span className="ml-1">
                  <div
                    className="p-2 rounded-circle d-flex align-items-center"
                    style={{ background: getLightColor(color) }}
                  >
                    <LucideIcon
                      size={22}
                      icon={
                        learnStyles?.[value]?.icon || learnStyles?.Default?.icon
                      }
                      color={color}
                    />
                  </div>
                </span>
              </TooltipComponent>
            ) : (
              ''
            )}
          </div>

          <p
            className="font-size-225em mb-0 font-weight-bold"
            style={{ color }}
          >
            {skipRounding
              ? numberFormatted
              : isNaN(stat)
              ? 0
              : subtext !== '%'
              ? numberFormatted
              : Math.round(stat)}

            {subtext && <span>{subtext}</span>}
          </p>
          {!hideAverage && (
            <span className="text-muted font-weight-normal font-size-sm2">
              average
            </span>
          )}
        </Card.Body>
      </Card>
    </div>
  );
};

export default InsightStatItem;
