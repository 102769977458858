import { ChecklistStatuses } from '../../utils/checklist.constants';
import React from 'react';

const ChecklistStatus = ({
  item,
  showDot = true,
  fontSize,
  style = { left: 3, bottom: 1 },
}) => {
  const GreenItems = ['Completed', 'Included', 'Success'];
  const RedItems = ['Overdue', 'Excluded', 'Deleted', 'High'];
  return (
    <span
      className={`position-relative badge status-chk ${
        showDot ? 'pl-3 pr-2' : 'px-2'
      } h-max-content label d-inline-flex align-items-center justify-content-center rounded-pill text-capitalize ${fontSize} ${
        GreenItems.includes(item?.status)
          ? ChecklistStatuses.Completed.color
          : item?.status === 'Not Started'
          ? ChecklistStatuses.Pending.color
          : RedItems.includes(item?.status)
          ? ChecklistStatuses.Deleted.color
          : item?.status === 'Premium'
          ? ChecklistStatuses.Premium.color
          : item?.status === 'Info'
          ? ChecklistStatuses.Info.color
          : ChecklistStatuses.InProgress.color
      } `}
    >
      {showDot ? (
        <span
          className="position-absolute fs-1 rounded-circle"
          style={{
            ...style,
            color: GreenItems.includes(item?.status)
              ? 'var(--status-green-main)'
              : item?.status === 'Not Started'
              ? 'var(--status-gray-main)'
              : item?.status === 'In Progress'
              ? 'var(--status-yellow-main)'
              : RedItems.includes(item?.status)
              ? 'var(--status-red-main)'
              : item?.status === 'Premium'
              ? 'var(--status-premium-main)'
              : item?.status === 'Info'
              ? 'var(--status-blue-main)'
              : 'var(--status-yellow-main)',
          }}
        >
          .
        </span>
      ) : null}{' '}
      <span>{item?.statusText || item?.status}</span>
    </span>
  );
};
export default ChecklistStatus;
