import { useEffect, useState } from 'react';
import { cubeService } from '../../../services';
import { LessonTranscriptColumns } from '../../../utils/learn.constants';
import GenericTable from '../../../components/GenericTable';
import TableSkeleton from '../../../components/commons/TableSkeleton';
import NoDataFound from '../../../components/commons/NoDataFound';
import { setDateFormat, getFriendlyLessonType } from '../../../utils/Utils';

const LessonTranscript = ({
  setShowCSVBtn = () => {},
  queryFilter,
  queryFilterUsers,
  queryFilterLessonTypes,
  paginationPage,
  setPagination,
  toggleModify,
}) => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [orderBy, setOrderBy] = useState('');
  const [order, setOrder] = useState(['', 'ASC']);

  const getLessonsStats = async () => {
    setLoading(true);
    try {
      const results = await cubeService.loadCustomAnalytics({
        type: 'LessonTranscript',
        orderBy: orderBy?.order,
        limit: paginationPage?.limit,
        page: paginationPage?.page,
        ...(queryFilter?.filters?.length > 0
          ? { teamIds: queryFilter?.filters }
          : {}),
        ...(queryFilterUsers?.filters?.length > 0
          ? { userIds: queryFilterUsers?.filters }
          : {}),
        ...(queryFilterLessonTypes?.filters?.length > 0 &&
        queryFilterLessonTypes?.filters[0]?.filter
          ? { lessonType: queryFilterLessonTypes?.filters[0]?.filter }
          : {}),
      });
      setData(results);
      setShowCSVBtn(results?.length > 0);
      setPagination(paginationPage); // Todo:- will update with response pagination when API's will change
    } catch (e) {
      console.log(e);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getLessonsStats();
  }, [paginationPage]);

  const loader = () => {
    if (loading) return <TableSkeleton cols={6} rows={10} />;
  };

  const rows = data?.map((result, index) => {
    return {
      id: index,
      dataRow: [
        {
          key: 'lessonName',
          component: (
            <div className="d-flex text-wrap font-weight-semi-bold align-items-baseline">
              {result?.lessonName || '--'}
            </div>
          ),
        },
        {
          key: 'userName',
          component: result?.userName || '--',
        },
        {
          key: 'teamName',
          component: result?.teamName || '--',
        },
        {
          key: 'lessonType',
          component: result?.lessonType
            ? getFriendlyLessonType(result.lessonType)
            : '--',
        },
        {
          key: 'dateCompleted',
          component: result?.dateCompleted
            ? setDateFormat(result.dateCompleted, 'MM/DD/YYYY')
            : '--',
        },
        {
          key: 'courseDateCompleted',
          component: result?.courseDateCompleted
            ? setDateFormat(result.courseDateCompleted, 'MM/DD/YYYY')
            : '--',
        },
      ],
    };
  });

  const updatedRows = data?.map((item) => {
    return {
      lessonName: item?.lessonName,
      userName: item?.userName,
      teamName: item?.teamName,
      lessonType: item?.lessonType,
      dateCompleted: item?.dateCompleted,
      courseDateCompleted: item?.courseDateCompleted,
    };
  });

  const handleSortByData = (key) => {
    if (key?.name?.key === 'selfDirected') {
      setOrderBy({
        order: `${key?.name?.orderBy} ${
          key?.name?.clicked.toLowerCase() === 'desc' ? 'asc' : 'desc'
        }`,
      });
    } else {
      setOrderBy({
        order: `${key?.name?.orderBy} ${key?.name?.clicked.toLowerCase()}`,
      });
    }
    setOrder([key?.name?.orderBy, key?.name?.clicked]);
    toggleModify();
  };

  return (
    <div>
      {loading ? (
        loader()
      ) : (
        <div>
          {data?.length === 0 ? (
            <NoDataFound
              icon="manage_search"
              title="No data found"
              containerStyle="text-gray-search my-6 py-6"
              titleStyle="font-normal font-size-sm2 text-gray-search"
            />
          ) : (
            <GenericTable
              checkbox={false}
              exportToCSV={true}
              data={rows}
              tableData={updatedRows}
              fileName="LessonTranscript"
              columns={LessonTranscriptColumns}
              usePagination={false}
              noDataInDbValidation={true}
              sortingTable={handleSortByData}
              sortingOrder={order}
            />
          )}
        </div>
      )}
    </div>
  );
};

export default LessonTranscript;
