import authHeader from './auth-header';
import BaseRequestService from './baseRequest.service';

class StageService extends BaseRequestService {
  async getStages(pipelineId) {
    const params = { pipelineId };

    const response = await this.get(
      `/stages`,
      {
        params,
        headers: authHeader(),
      },
      { fullResponse: true }
    );
    return response?.data;
  }

  async getPipelineStages(pipelineId, limit = 40, page = 1) {
    const response = await this.get(`/pipelines/${pipelineId}/stages`, {
      params: {
        page,
        limit,
      },
      headers: authHeader(),
    });
    return response?.data;
  }

  async createStage(data) {
    const response = await this.post(
      `/stages`,
      data,
      { headers: authHeader() },
      { fullResponse: true }
    );
    return response?.data;
  }

  async updateStage(data, id) {
    const response = await this.put(
      `/stages/${id}`,
      data,
      { headers: authHeader() },
      { fullResponse: true }
    );
    return response?.data;
  }

  async deleteStage(id, transferId) {
    return await this.delete(
      `/stages/${id}`,
      {
        params: { transferId },
        headers: authHeader(),
      },
      { fullResponse: true }
    );
  }
}

export default new StageService();
