export const IconSizes = {
  'fs-1': 36,
  'fs-2': 28,
  'fs-3': 20,
  'fs-4': 22,
  'fs-5': 18,
  'fs-6': 14,
  'fs-7': 11,
  'fs-8': 10,
  'fs-9': 10,
  'fs-10': 9,
  'fs-18': 15,
  'fs-20': 17,
  'font-85': 85,
  'font-24': 24,
  'fs-flag-icon': 14,
  'font-size-sm': 12,
  'font-size-sm2': 13,
  'font-size-md': 14,
  'font-size-xl': 16,
  'font-size-2xl': 42,
  'font-size-3xl': 35,
  'font-size-4xl': 23,
  'font-size-em': 14,
  'font-size-2em': 28,
  'font-size-3em': 40,
  'font-size-4em': 52,
  'font-size-5em': 65,
  'font-size-lg': 18,
  'font-26': 26,
  'font-90': 90,
};

export const filledIconsList = [
  'flag',
  'circle',
  'star',
  'favorite',
  'check_circle',
  'add_circle',
];

export const IconsMaterialToLucide = {
  add: 'Plus',
  ChartBar: 'ChartBar',
  ChartScatter: 'ChartScatter',
  close: 'X',
  edit: 'Pencil',
  delete: 'Trash2',
  login: 'Key',
  lock: 'Lock',
  task_alt: 'Copy',
  save_as: 'SaveAll',
  event_list: 'ClipboardList',
  lab_profile: 'ClipboardList',
  save: 'Save',
  calendar_clock: 'CalendarClock',
  equal: 'Equal',
  menu: 'Menu',
  pending: 'CircleEllipsis',
  sic: 'RectangleEllipsis',
  export_notes: 'FileText',
  area_chart: 'ChartArea',
  photo_album: 'Album',
  merge_type: 'Merge',
  numbers: 'Hash',
  apps: 'Grip',
  gauge: 'Gauge',
  west: 'MoveLeft',
  grid_view: 'LayoutGrid',
  fax: 'Printer',
  expand_content: 'Maximize2',
  business_center: 'BriefcaseBusiness',
  arrow_circle_up: 'CircleArrowUp',
  arrow_circle_down: 'CircleArrowDown',
  heap_snapshot_large: 'Database',
  summarize: 'FileText',
  download_for_offline: 'Download',
  lightbulb: 'Lightbulb',
  thumb_up: 'ThumbsUp',
  thumb_down: 'ThumbsDown',
  subject: 'AlignLeft',
  warning: 'TriangleAlert',
  message: 'Mail',
  tv_signin: 'MonitorCheck',
  mail: 'Mail',
  calendar_month: 'Calendar',
  lock_open: 'Key',
  memory: 'Cpu',
  coffee: 'Coffee',
  price_check: 'Tag',
  contactless: 'Nfc',
  phone_in_talk: 'PhoneCall',
  location_on: 'MapPin',
  view_week: 'Columns3',
  swap_vert: 'ArrowUpDown',
  prompt_suggestion: 'CornerDownRight',
  attachment: 'Paperclip',
  note_stack: 'StickyNote',
  keyboard_tab: 'ArrowRightToLine',
  block: 'UserX',
  keyboard_tab_rtl: 'ArrowLeftFromLine',
  compare: 'Pin',
  task: 'CircleCheckBig',
  call: 'Phone',
  phone: 'Phone',
  help_outline: 'CircleHelp',
  east: 'MoveRight',
  open_with: 'Move',
  account_balance: 'Scale',
  people_outline: 'UserSearch',
  text_snippet: 'NotepadText',
  sticky_note_2: 'StickyNote',
  checklist_rtl: 'ListChecks',
  visibility: 'Eye',
  upload: 'Upload',
  inventory_2: 'Archive',
  open_in_new: 'ExternalLink',
  edit_document: 'FilePenLine',
  chart_data: 'ChartNoAxesCombined',
  help: 'CircleHelp',
  corporate_fare: 'Building2',
  apartment: 'Building',
  add_circle: 'CirclePlus',
  acute: 'Clock',
  schedule: 'Clock4',
  play_circle: 'CirclePlay',
  data_check: 'CircleCheckBig',
  sentiment_satisfied: 'Smile',
  savings: 'PiggyBank',
  local_police: 'Shield',
  crisis_alert: 'Bell',
  mystery: 'View',
  double_arrow: 'ChevronsRight',
  people: 'User',
  settings: 'Settings',
  supervisor_account: 'UserRound',
  setting: 'Settings',
  monetization_on: 'CircleDollarSign',
  radio_button_checked: 'CircleCheck',
  radio_button_unchecked: 'Circle',
  checklist: 'ListChecks',
  person_search: 'UserSearch',
  person: 'UserSearch',
  groups: 'Users',
  manage_search: 'FolderSearch',
  person_add: 'UserPlus',
  event: 'Calendar',
  account_tree: 'Network',
  currency_exchange: 'CircleDollarSign',
  gpp_maybe: 'ShieldAlert',
  bar_chart_4_bars: 'ChartColumnDecreasing',
  keyboard_arrow_up: 'ChevronUp',
  keyboard_arrow_down: 'ChevronDown',
  arrow_drop_down: 'ChevronDown',
  keyboard_command_key: 'Command',
  keyboard_backspace: 'MoveLeft',
  arrow_forward: 'ArrowRight',
  arrow_upward: 'ArrowUp',
  arrow_downward: 'ArrowDown',
  chevron_right: 'ChevronRight',
  hourglass_disabled: 'TimerOff',
  school: 'GraduationCap',
  file_copy: 'Files',
  history: 'History',
  chat_bubble: 'MessageSquare',
  auto_awesome: 'Sparkles',
  description: 'FileText',
  attach_file: 'Paperclip',
  payments: 'Banknote',
  edit_note: 'FilePenLine',
  analytics: 'ChartColumn',
  autorenew: 'RefreshCcw',
  track_changes: 'Target',
  swap_horiz: 'ArrowLeftRight',
  account_balance_wallet: 'Wallet',
  security: 'ShieldCheck',
  trending_up: 'TrendingUp',
  speed: 'Gauge',
  handshake: 'Handshake',
  zone_person_urgent: 'UserX',
  paid: 'DollarSign',
  deployed_code_account: 'Code',
  person_check: 'UserCheck',
  attach_email: 'Paperclip',
  contacts: 'Users',
  checkbook: 'BookCheck',
  partner_exchange: 'Handshake',
  circle: 'Circle',
  finance: 'Banknote',
  arrow_right_alt: 'ArrowRight',
  price_change: 'TrendingUp',
  refresh: 'RefreshCcw',
  credit_score: 'CreditCard',
  article: 'FileText',
  more_horiz: 'Ellipsis',
  notifications: 'Bell',
  more_vert: 'EllipsisVertical',
  app_registration: 'FileStack',
  view_carousel: 'GalleryHorizontal',
  view_comfy_alt: 'Blocks',
  search: 'Search',
  check: 'Check',
  content_copy: 'Files',
  add_chart: 'FileChartColumnIncreasing',
  download: 'CloudDownload',
  workspace_premium: 'Award',
  segment: 'AlignRight',
  folder: 'Folder',
  dashboard: 'LayoutDashboard',
  account_circle: 'User',
  alarm: 'Bell',
  alarm_add: 'BellPlus',
  alarm_off: 'BellOff',
  alarm_on: 'Bell',
  android: 'TabletSmartphone',
  announcement: 'Megaphone',
  arrow_back: 'ArrowLeft',
  arrow_back_ios: 'ArrowLeft',
  arrow_drop_down_circle: 'ArrowDownCircle',
  arrow_drop_up: 'ArrowUp',
  arrow_forward_ios: 'ArrowRight',
  assignment: 'FileText',
  assignment_ind: 'UserCheck',
  assignment_late: 'CircleAlert',
  assignment_return: 'SquareArrowDown',
  assignment_returned: 'SquareArrowDown',
  assignment_turned_in: 'CircleCheck',
  backup: 'CloudUpload',
  book: 'Book',
  bookmark: 'Star',
  bookmark_border: 'StarOff',
  border_color: 'Edit',
  brightness_1: 'Sun',
  brightness_2: 'Sun',
  brightness_3: 'Sun',
  brightness_4: 'Sun',
  brightness_5: 'Sun',
  brightness_6: 'Sun',
  brightness_7: 'Sun',
  brightness_auto: 'Sun',
  brightness_high: 'Sun',
  brightness_low: 'Sun',
  brightness_medium: 'Sun',
  broken_image: 'ImageOff',
  brush: 'Pencil',
  bug_report: 'Bug',
  build: 'Wrench',
  cached: 'RefreshCw',
  calendar_today: 'Calendar',
  call_end: 'PhoneOff',
  call_made: 'PhoneOutgoing',
  call_merge: 'PhoneIncoming',
  call_missed: 'PhoneMissed',
  call_missed_outgoing: 'PhoneMissed',
  call_received: 'PhoneIncoming',
  call_split: 'PhoneIncoming',
  camera: 'Camera',
  camera_alt: 'Camera',
  camera_enhance: 'Camera',
  cancel: 'X',
  card_giftcard: 'Gift',
  card_membership: 'Award',
  card_travel: 'BriefcaseBusiness',
  cast: 'Cast',
  cast_connected: 'Cast',
  category: 'Tag',
  center_focus_strong: 'Target',
  center_focus_weak: 'Target',
  change_history: 'History',
  chat: 'MessageCircle',
  chat_bubble_outline: 'MessageCircle',
  check_box: 'SquareCheck',
  check_box_outline_blank: 'Square',
  check_circle: 'CircleCheck',
  check_circle_outline: 'CircleCheck',
  chevron_left: 'ChevronLeft',
  child_care: 'Baby',
  child_friendly: 'Baby',
  chrome_reader_mode: 'Book',
  lock_reset: 'LockKeyhole',
  class: 'Book',
  clear: 'X',
  clear_all: 'Trash',
  closed_caption: 'Captions',
  cloud: 'Cloud',
  cloud_circle: 'Cloud',
  cloud_done: 'CloudCheck',
  person_cancel: 'UserX',
  cloud_download: 'CloudDownload',
  cloud_off: 'CloudOff',
  cloud_queue: 'CloudUpload',
  cloud_upload: 'CloudUpload',
  code: 'Code',
  collections: 'Image',
  collections_bookmark: 'ImageStar',
  color_lens: 'Palette',
  colorize: 'Palette',
  comment: 'MessageCircle',
  compare_arrows: 'Repeat',
  expand: 'ChevronsLeftRightEllipsis',
  compass: 'Compass',
  computer: 'DeviceDesktop',
  confirmation_number: 'Ticket',
  contact_mail: 'Mail',
  contact_phone: 'Phone',
  contact_support: 'Headset',
  content_cut: 'Scissors',
  content_paste: 'ClipboardCheck',
  control_camera: 'Camera',
  control_point: 'Plus',
  control_point_duplicate: 'Plus',
  copyright: 'Copyright',
  create: 'Pencil',
  create_new_folder: 'FolderPlus',
  credit_card: 'CreditCard',
  crop: 'Crop',
  crop_16_9: 'Crop',
  crop_3_2: 'Crop',
  crop_5_4: 'Crop',
  crop_7_5: 'Crop',
  crop_din: 'Crop',
  crop_free: 'Crop',
  crop_landscape: 'Crop',
  crop_original: 'Crop',
  crop_portrait: 'Crop',
  crop_rotate: 'RotateCw',
  crop_square: 'Crop',
  data_usage: 'ChartBar',
  date_range: 'Calendar',
  dehaze: 'Sun',
  delete_forever: 'Trash',
  delete_outline: 'Trash',
  delete_sweep: 'Trash',
  desktop_mac: 'DeviceDesktop',
  desktop_windows: 'DeviceDesktop',
  details: 'Info',
  developer_board: 'Cpu',
  developer_mode: 'Code',
  device_hub: 'DeviceHub',
  device_unknown: 'DeviceUnknown',
  devices: 'MonitorSmartphone',
  devices_other: 'DeviceUnknown',
  dialer_sip: 'Phone',
  dialpad: 'Phone',
  directions: 'Map',
  directions_bike: 'Bike',
  directions_boat: 'Boat',
  directions_bus: 'Bus',
  directions_car: 'Car',
  directions_ferry: 'Ship',
  directions_metro: 'Train',
  directions_railway: 'Train',
  directions_run: 'Run',
  directions_subway: 'Train',
  directions_train: 'Train',
  directions_transit: 'Train',
  directions_walk: 'Walk',
  disc_full: 'Disc',
  dns: 'Server',
  do_not_disturb: 'BellOff',
  do_not_disturb_alt: 'BellOff',
  do_not_disturb_off: 'BellOff',
  do_not_disturb_on: 'Bell',
  dock: 'DeviceDesktop',
  domain: 'Building2',
  domain_disabled: 'Ban',
  done: 'Check',
  done_all: 'CheckCheck',
  done_outline: 'Check',
  donut_large: 'ChartPie',
  donut_small: 'ChartPie',
  drafts: 'Mail',
  drag_handle: 'Grip',
  drag_indicator: 'GripVertical',
  drive_eta: 'Car',
  duo: 'Video',
  dvr: 'Video',
  edit_attributes: 'Pencil',
  edit_location: 'MapPin',
  eject: 'Triangle',
  email: 'Mail',
  email_open: 'MailOpen',
  enhanced_encryption: 'Lock',
  equalizer: 'SlidersVertical',
  error: 'CircleAlert',
  error_outline: 'CircleAlert',
  euro: 'Euro',
  euro_symbol: 'Euro',
  event_available: 'CalendarCheck',
  event_busy: 'CalendarX',
  event_note: 'Calendar',
  event_seat: 'Armchair',
  exit_to_app: 'LogOut',
  expand_less: 'ChevronUp',
  expand_more: 'ChevronDown',
  explicit: 'ReceiptEuro',
  explore: 'Map',
  exposure: 'Sun',
  exposure_neg_1: 'Sun',
  exposure_neg_2: 'Sun',
  exposure_plus_1: 'Sun',
  exposure_plus_2: 'Sun',
  exposure_zero: 'Sun',
  extension: 'Puzzle',
  face: 'Smile',
  fast_forward: 'FastForward',
  fast_rewind: 'Rewind',
  favorite: 'Heart',
  favorite_border: 'HeartOff',
  featured_play_list: 'ListMusic',
  featured_video: 'Video',
  feedback: 'MessageCircle',
  fiber_dvr: 'Video',
  fiber_manual_record: 'Dot',
  fiber_new: 'Newspaper',
  fiber_pin: 'MapPin',
  fiber_smart_record: 'Dot',
  file_download: 'Download',
  file_upload: 'Upload',
  upload_file: 'Upload',
  smart_display: 'Youtube',
  filter: 'Filter',
  filter_alt: 'Filter',
  filter_1: 'Filter',
  filter_2: 'Filter',
  filter_3: 'Filter',
  filter_4: 'Filter',
  filter_5: 'Filter',
  filter_6: 'Filter',
  filter_7: 'Filter',
  filter_8: 'Filter',
  filter_9: 'Filter',
  filter_9_plus: 'Filter',
  filter_b_and_w: 'Filter',
  filter_center_focus: 'Target',
  filter_drama: 'Filter',
  filter_frames: 'Filter',
  filter_hdr: 'Filter',
  filter_list: 'Filter',
  filter_none: 'Filter',
  filter_tilt_shift: 'Filter',
  filter_vintage: 'Filter',
  find_in_page: 'Search',
  find_replace: 'Search',
  fingerprint: 'Fingerprint',
  first_page: 'PageFirst',
  fitness_center: 'Dumbbell',
  flag: 'Flag',
  flare: 'Sun',
  flash_auto: 'Flash',
  flash_off: 'FlashOff',
  flash_on: 'Flash',
  flight: 'Airplane',
  flight_land: 'PlaneLanding',
  flight_takeoff: 'PlaneTakeoff',
  flip: 'RotateCw',
  flip_to_back: 'RotateCw',
  flip_to_front: 'RotateCw',
  folder_open: 'FolderOpen',
  folder_shared: 'FolderShared',
  folder_special: 'FolderStar',
  font_download: 'BookType',
  format_align_center: 'AlignCenter',
  format_align_justify: 'AlignJustify',
  format_align_left: 'AlignLeft',
  format_align_right: 'AlignRight',
  format_bold: 'Bold',
  format_clear: 'Eraser',
  format_color_fill: 'Palette',
  format_color_reset: 'Palette',
  format_color_text: 'Palette',
  format_indent_decrease: 'IndentDecrease',
  format_indent_increase: 'IndentIncrease',
  format_italic: 'Italic',
  format_line_spacing: 'LineHeight',
  format_list_bulleted: 'List',
  format_list_numbered: 'ListOrdered',
  format_paint: 'Palette',
  format_quote: 'Quote',
  format_shapes: 'Shapes',
  format_size: 'FontSize',
  format_strikethrough: 'Strikethrough',
  format_textdirection_l_to_r: 'TextDirectionLtr',
  format_textdirection_r_to_l: 'TextDirectionRtl',
  format_underlined: 'Underline',
  forum: 'MessageCircle',
  forward: 'ArrowRight',
  forward_10: 'FastForward',
  forward_30: 'FastForward',
  forward_5: 'FastForward',
  forward_to_inbox: 'MailCheck',
  foundation: 'Foundation',
  four_k: 'Tv',
  free_breakfast: 'Coffee',
  fullscreen: 'Maximize',
  fullscreen_exit: 'Minimize',
  functions: 'Function',
  g_translate: 'Languages',
  gamepad: 'Gamepad',
  games: 'Gamepad',
  gavel: 'Gavel',
  gesture: 'Hand',
  get_app: 'Download',
  gif: 'Image',
  golf_course: 'GolfBall',
  gps_fixed: 'MapPin',
  gps_not_fixed: 'MapPinOff',
  gps_off: 'MapPinOff',
  grade: 'Star',
  gradient: 'Gradient',
  grain: 'Grain',
  graphic_eq: 'AudioLines',
  grid_off: 'GridOff',
  grid_on: 'Grid',
  group: 'Users',
  group_add: 'UserPlus',
  group_work: 'Users',
  hd: 'Tv',
  hdr_off: 'SunOff',
  hdr_on: 'Sun',
  hdr_strong: 'Sun',
  hdr_weak: 'Sun',
  headset: 'Headset',
  headset_mic: 'Headset',
  healing: 'Heart',
  hearing: 'Ear',
  high_quality: 'Star',
  highlight: 'Highlighter',
  highlight_off: 'Eraser',
  home: 'House',
  home_work: 'House',
  horizontal_split: 'Grip',
  hot_tub: 'HotTub',
  hotel: 'Hotel',
  hourglass_empty: 'Hourglass',
  hourglass_full: 'Hourglass',
  house: 'House',
  how_to_reg: 'UserCheck',
  how_to_vote: 'Vote',
  http: 'Server',
  https: 'Lock',
  image: 'Image',
  image_aspect_ratio: 'Image',
  import_contacts: 'BookOpen',
  import_export: 'MoveHorizontal',
  important_devices: 'MonitorSmartphone',
  inbox: 'Mail',
  indeterminate_check_box: 'Square',
  info: 'Info',
  Info: 'Info',
  info_outline: 'Info',
  ios_share: 'Share',
  stacks: 'Layers',
  '3d_rotation': 'Rotate3d',
  accessibility_new: 'PersonStanding',
  accessible: 'Accessibility',
  mic: 'Mic',
  input: 'FileInput',
  insert_chart: 'ChartBar',
  insert_comment: 'MessageCircle',
  insert_drive_file: 'FileText',
  insert_emoticon: 'Smile',
  insert_invitation: 'Calendar',
  insert_link: 'Link',
  insert_photo: 'Image',
  invert_colors: 'Contrast',
  invert_colors_off: 'Contrast',
  iso: 'Iso',
  keyboard: 'Keyboard',
  keyboard_arrow_left: 'ArrowLeft',
  keyboard_arrow_right: 'ArrowRight',
  keyboard_capslock: 'CapsLock',
  keyboard_hide: 'KeyboardOff',
  keyboard_return: 'Enter',
  keyboard_voice: 'Microphone',
  arrow_left_alt: 'ArrowDownUp',
  kitchen: 'Kitchen',
  label: 'Tag',
  label_important: 'Tag',
  label_off: 'CircleSlash2',
  landscape: 'Image',
  language: 'Globe',
  laptop: 'DeviceLaptop',
  laptop_chromebook: 'DeviceLaptop',
  laptop_mac: 'DeviceLaptop',
  laptop_windows: 'DeviceLaptop',
  last_page: 'PageLast',
  launch: 'Rocket',
  layers: 'Layers',
  layers_clear: 'LayersOff',
  leaderboard: 'Trophy',
  leak_add: 'WaterPlus',
  leak_remove: 'WaterMinus',
  remove: 'Minus',
  menu_book: 'BookOpenText',
  legend_toggle: 'Legend',
  lens: 'Camera',
  library_add: 'BookPlus',
  library_books: 'Book',
  library_music: 'Music',
  link: 'Link',
  link_off: 'Link2',
  linked_camera: 'Camera',
  list: 'List',
  list_alt: 'List',
  live_help: 'Headset',
  live_tv: 'Tv',
  local_activity: 'MapPin',
  local_airport: 'Airplane',
  local_atm: 'Atm',
  local_bar: 'Glass',
  local_cafe: 'Coffee',
  local_car_wash: 'CarWash',
  local_convenience_store: 'Store',
  local_dining: 'Restaurant',
  local_drink: 'Glass',
  local_florist: 'Flower',
  local_gas_station: 'GasPump',
  local_grocery_store: 'Store',
  local_hospital: 'Hospital',
  local_hotel: 'Hotel',
  local_laundry_service: 'WashingMachine',
  local_library: 'Book',
  local_mall: 'Store',
  local_movies: 'Film',
  local_offer: 'Tag',
  local_parking: 'Parking',
  local_pharmacy: 'Pill',
  local_phone: 'Phone',
  local_pizza: 'Pizza',
  local_play: 'Play',
  local_post_office: 'Mail',
  local_printshop: 'Printer',
  local_see: 'Map',
  local_shipping: 'Truck',
  local_taxi: 'Taxi',
  location_city: 'Building',
  location_disabled: 'MapPinOff',
  location_off: 'MapPinOff',
  electric_bolt: 'Gauge',
  earthquake: 'Activity',
  unfold_more: 'ChevronsUpDown',
  draw: 'Brush',
  other_admission: 'ClipboardPaste',
  smart_toy: 'Robot',
  star: 'Star',
};
