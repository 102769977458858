import React, { useState } from 'react';
import AnimatedTabs from '../../components/commons/AnimatedTabs';
import PageTitle from '../../components/commons/PageTitle';
import { TabContent, TabPane } from 'reactstrap';
import Overview from './analytics/Overview';
import ConversationLog from './analytics/ConversationLog';
import Users from './analytics/Users';

const IdaAnalytics = () => {
  const tabsData = [
    {
      tabId: 1,
      title: 'Overview',
      component: <Overview />,
    },
    {
      tabId: 2,
      title: 'Conversation Log',
      component: <ConversationLog />,
    },
    {
      tabId: 3,
      title: 'Users',
      component: <Users />,
    },
  ];
  const [activeTab, setActiveTab] = useState(tabsData[0].tabId);

  const toggle = (tab) => {
    if (activeTab !== tab.tabId) {
      setActiveTab(tab.tabId);
    }
  };

  return (
    <>
      <PageTitle page={tabsData?.find((t) => t.tabId === activeTab)?.title} />
      <AnimatedTabs
        tabsData={tabsData}
        activeTab={activeTab}
        tabClasses="px-3 gap-3 pt-3"
        tabItemClasses="border-0 rounded text-black bg-hover-gray mb-0 p-2"
        tabActiveClass="bg-primary-soft-darker text-primary fw-bold"
        toggle={toggle}
        permissionCheck={true}
        borderClasses="bg-white border-bottom-0"
      />
      <TabContent>
        <TabPane className="position-relative">
          {tabsData.find((item) => item.tabId === activeTab)?.component}
        </TabPane>
      </TabContent>
    </>
  );
};

export default IdaAnalytics;
