import React, { useEffect, useState } from 'react';
import useHash from '../../../hooks/useHash';
import MoreActions from '../../MoreActions';
import IdfTooltip from '../../idfComponents/idfTooltip';
import useFetchChats from '../hooks/useFetchChats';
import { useIdaContext } from '../../../contexts/IdaContext';
import useDeleteModal from '../../reports/account-structure/modals/useDeleteModal';
import chatService from '../../../services/chat.service';
import Skeleton from 'react-loading-skeleton';
import NoDataFoundTitle from '../../fields/NoDataFoundTitle';
import NoDataFound from '../../commons/NoDataFound';
import useRenameChatModal from '../modals/RenameChat';
import { AnimatedText } from '../../../hooks/useAnimatedText';
import { getRandomUUID } from '../../../utils/Utils';

const TopHistory = () => {
  const { hash, updateHash } = useHash();
  const [, id] = hash?.split('/');
  const { chats, setChats, pagination, loading, fetchChats } = useFetchChats(5); // for left history we need only 5
  const [active, setActive] = useState(0);
  const [showMenu, setShowMenu] = useState(null);
  const {
    setSelectedChat,
    historyNewChat,
    setHistoryNewChat,
    setRefreshHistory,
    refreshHistory,
  } = useIdaContext();

  const NewChatKey = 'New Chat';
  const addNewHistoryChat = () => {
    const previousChats = [...chats];
    const previousNewChatIndex = previousChats.findIndex(
      (pc) => pc.title === NewChatKey
    );
    if (previousNewChatIndex > -1) {
      // update the existing 'New Chat'
      previousChats[previousNewChatIndex] = {
        title: historyNewChat.title,
        chatId: historyNewChat.chatId,
        animate: true,
      };
      setChats(previousChats.slice(0, 5));
    } else {
      const newChat = {
        title: NewChatKey,
        chatId: getRandomUUID(),
      };
      previousChats.unshift(newChat);
      setChats(previousChats.slice(0, 5));
    }
    setHistoryNewChat({});
  };

  useEffect(() => {
    // refresh left top 5 history if new chat is created.
    Object.keys(historyNewChat || {}).length > 0 &&
      !historyNewChat.rename &&
      addNewHistoryChat();
  }, [historyNewChat]);

  useEffect(() => {
    refreshHistory > 0 && fetchChats(1);
  }, [refreshHistory]);

  useEffect(() => {
    setActive(id ?? 0);
  }, [hash]);

  const handleToggleDropdown = (chatId) => {
    if (showMenu === chatId) {
      setShowMenu(null);
    } else {
      setShowMenu(chatId);
    }
  };

  const {
    DeleteModal,
    setData,
    setShowModal: setShowDeleteModal,
  } = useDeleteModal({
    heading: 'Delete Chat',
    description: 'Are you sure you want to delete this Chat?',
    successMsg: 'Chat deleted.',
    deleteCallback: async (data) => {
      try {
        await chatService.deleteChat(data.chatId);
        setRefreshHistory((prevState) => prevState + 1);
        // if the conversation thread is opened
        // take user to main home.
        if (id && data.chatId === id) {
          setSelectedChat({});
          updateHash('');
        }
      } catch (e) {
        console.log(e);
      }
    },
  });
  const {
    RenameChatModal,
    setShowModal: setShowRenameChatModal,
    setChat,
  } = useRenameChatModal((updatedChat) => {
    const newChats = [...chats].map((cht) =>
      cht.chatId === updatedChat.chatId
        ? { ...cht, title: updatedChat.title, animate: false }
        : cht
    );
    setChats(newChats);
    setHistoryNewChat({ ...updatedChat, animate: false, rename: true });
    setChat({ ...updatedChat, animate: false });
  });

  const actionItems = [
    {
      id: 'edit',
      icon: 'Pencil',
      name: 'Rename',
    },
    {
      id: 'remove',
      icon: 'Trash2',
      name: 'Delete',
    },
  ];

  const handleRemove = (historyItem) => {
    setData({ chatId: historyItem.chatId, title: historyItem.title });
    setShowDeleteModal(true);
  };

  const handleEditName = (historyItem) => {
    setChat({ chatId: historyItem.chatId, title: historyItem.title });
    setShowRenameChatModal(true);
  };

  return (
    <>
      <DeleteModal />
      <RenameChatModal />
      {loading ? (
        <div className="p-3">
          <Skeleton count={3} className="mb-2" width="100%" height="10" />
        </div>
      ) : (
        <div
          className={`chat-history-items bg-white ${
            chats?.length === 0
              ? 'align-items-center justify-content-center'
              : ''
          } rounded min-h-150`}
        >
          {chats?.length > 0 ? (
            <>
              {chats?.map((chat, index) => {
                let menuPlacement = 'bottom';
                if (chats.length > 1) {
                  if (chats.length === 2) {
                    menuPlacement = index === 0 ? 'bottom' : 'up';
                  } else if (chats.length === 3) {
                    menuPlacement = index === 2 ? 'up' : 'bottom';
                  } else if (chats.length === 5) {
                    menuPlacement = index >= 2 ? 'up' : 'bottom';
                  }
                }

                return (
                  <div
                    key={chat.chatId}
                    onClick={() => {
                      setSelectedChat(chat);
                      updateHash(`#chats/${chat.chatId}`);
                    }}
                    className={`chat-history-item pl-3_1 py-2 ${
                      active === chat.chatId ? 'active bg-primary-soft' : ''
                    }`}
                  >
                    <div className="history-item position-relative d-flex justify-content-between align-items-center">
                      <p className="mb-0 font-weight-medium text-black flex-1 font-size-sm2 text-clamp-single-line fade-out">
                        <>
                          {chat?.animate ? (
                            <AnimatedText text={chat.title} />
                          ) : (
                            chat.title?.replace('Title:', '')
                          )}
                        </>
                      </p>
                      <span
                        className="more-actions-wrapper"
                        onClick={() =>
                          handleToggleDropdown(showMenu ?? chat.chatId)
                        }
                      >
                        <IdfTooltip text="Options" placement="top">
                          <MoreActions
                            useLucidIcon
                            items={actionItems}
                            show={showMenu === chat.chatId}
                            stopPropagation={true}
                            menuPlacement={menuPlacement}
                            onToggle={(isOpen) => {
                              if (!isOpen && showMenu === chat.chatId) {
                                setShowMenu(showMenu === chat.chatId);
                              }
                            }}
                            onHandleRemove={(e) => {
                              e.stopPropagation();
                              handleRemove(chat);
                            }}
                            onHandleEdit={(e) => {
                              e.stopPropagation();
                              handleEditName(chat);
                            }}
                            toggleClassName="w-auto p-0 h-auto"
                          />
                        </IdfTooltip>
                      </span>
                    </div>
                  </div>
                );
              })}
              {pagination?.count > 5 && (
                <div className="m-2 mx-3">
                  <div
                    onClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      updateHash('history');
                    }}
                    className="py-2 px-3 nav-link border bg-hover-gray transition cursor-pointer rounded-pill d-flex"
                  >
                    <span className="fs-7 font-weight-medium text-center w-100">
                      View more...
                    </span>
                  </div>
                </div>
              )}
            </>
          ) : (
            <NoDataFound
              icon="history"
              iconRounded={true}
              iconStyle="font-size-em"
              containerStyle="text-gray-search justify-content-center align-items-center"
              title={
                <NoDataFoundTitle clazz="fs-7 mb-0" str="No chats found." />
              }
            />
          )}
        </div>
      )}
    </>
  );
};

export default TopHistory;
