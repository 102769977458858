/* eslint-disable no-unused-vars */
import ThreadLayout from '../layout/ThreadLayout';
import { InputDisplayType, MenuItemsKeysAndIcons } from '../constants';
import React, { useEffect, useRef, useState } from 'react';
// import ButtonIcon from '../../commons/ButtonIcon';
import Avatar from '../../Avatar';
import AskAnything from '../AskAnything';
import MoreActions from '../../MoreActions';
// import ShareOptions from '../ShareOptions';
import {
  DATE_CHAT_FORMAT,
  PROSPECT_RIGHT_PANEL_WIDTH,
  scrollToBottomChat,
} from '../../../utils/Utils';
import RightPanelModal from '../../modal/RightPanelModal';
import useFetchChatQuestions from '../hooks/useFetchChatQuestions';
import ThreadLayoutSkeleton from '../loaders/ThreadLayoutSkeleton';
import { useIdaContext } from '../../../contexts/IdaContext';
import Home from '../Home';
import useDeleteModal from '../../reports/account-structure/modals/useDeleteModal';
import useHash from '../../../hooks/useHash';
import ChatService from '../../../services/chat.service';
import useBackTop from '../../../hooks/useBackTop';
import PageTitle from '../../commons/PageTitle';
import useFetchChat from '../hooks/useFetchChat';
import Conversation from './Conversation';
import useChatFilesModal from '../modals/ChatFiles';
import { AnimatedText } from '../../../hooks/useAnimatedText';
import { Image } from 'react-bootstrap';
import IDABotImage from '../../../assets/svg/ai-advisor/ida.svg';
import useRenameChatModal from '../modals/RenameChat';
import LucideIcon from '../../commons/LucideIcon';
import IdfTooltip from '../../idfComponents/idfTooltip';
import { useProfileContext } from '../../../contexts/profileContext';
import moment from 'moment';
import Disclaimer from '../Disclaimer';

const SourcesItems = [
  {
    id: 1,
    title: 'X-rays',
    reference: ['https://google.com'],
  },
  {
    id: 2,
    title: 'How are X-rays Produced',
    reference: ['https://health.com'],
  },
  {
    id: 3,
    title: 'X-ray - NHS ',
    reference: ['https://nhs.org'],
  },
  {
    id: 4,
    title: 'X-ray - NHS ',
    reference: ['https://nhs.org'],
  },
];

// eslint-disable-next-line no-unused-vars
const Sources = ({ children }) => {
  const [show, setShow] = useState(false);
  return (
    <>
      {children}
      <RightPanelModal
        showModal={show}
        setShowModal={() => setShow(false)}
        showOverlay={true}
        containerBgColor={'pb-0'}
        containerWidth={PROSPECT_RIGHT_PANEL_WIDTH}
        containerPosition={'position-fixed'}
        headerBgColor="bg-gray-5"
        Title={
          <div className="d-flex py-2 align-items-center text-capitalize">
            <h3 className="mb-0">Sources</h3>
          </div>
        }
      >
        <div className="p-2 overflow-y-auto">
          <div className="d-flex flex-column gap-3 w-100">
            {SourcesItems.map((know) => (
              <div
                className="w-100 border cursor-pointer bg-gray-5 card-hover-shadow d-flex flex-column rounded p-2"
                key={know.id}
                onClick={know.onClick}
                style={{ width: 150, height: 80 }}
              >
                <h6 className="font-size-sm2">{know.title}</h6>
                <p className="mb-0 fs-8">{know.reference[0]}</p>
              </div>
            ))}
          </div>
        </div>
      </RightPanelModal>
    </>
  );
};

const OwnerAndDate = ({ chat }) => {
  const { profileInfo } = useProfileContext();

  return (
    <div className="d-flex align-items-center gap-2 pr-2">
      <IdfTooltip text={`${profileInfo?.first_name} ${profileInfo?.last_name}`}>
        <span className="d-flex">
          <Avatar
            user={profileInfo}
            classModifiers="border-0"
            defaultSize="xs"
            avatarBorder=""
          />
        </span>
      </IdfTooltip>
      <div className="fs-8 d-flex gap-1 align-items-center text-muted-100">
        <IdfTooltip text={moment(chat?.createdAt).format(DATE_CHAT_FORMAT)}>
          <span className="d-flex gap-1 align-items-center">
            <LucideIcon icon="Clock" size={16} />{' '}
            {moment(chat?.createdAt).fromNow()}
          </span>
        </IdfTooltip>
      </div>
    </div>
  );
};

const ActionControls = ({ id, chat, selectedChat, setCurrentChat }) => {
  const { setSelectedChat, setRefreshHistory } = useIdaContext();
  const { updateHash } = useHash();
  const actionItems = [
    {
      id: 'add',
      icon: 'File',
      name: 'Files',
    },
    {
      id: 'edit',
      icon: 'Pencil',
      name: 'Rename',
    },
    {
      type: 'divider',
    },
    {
      id: 'remove',
      icon: 'Trash2',
      name: 'Delete',
    },
  ];

  const {
    DeleteModal,
    setData,
    setShowModal: setShowDeleteModal,
  } = useDeleteModal({
    heading: 'Delete Chat',
    description: 'Are you sure you want to delete this Chat?',
    successMsg: 'Chat deleted.',
    deleteCallback: async () => {
      // if the conversation thread is opened
      // take user to main home.
      try {
        await ChatService.deleteChat(id);
        setRefreshHistory((prevState) => prevState + 1);
        setSelectedChat({});
        id && updateHash('');
      } catch (e) {
        console.log(e);
      }
    },
  });
  const {
    RenameChatModal,
    setShowModal: setShowRenameChatModal,
    setChat,
  } = useRenameChatModal((updatedChat) => {
    const newChat = { ...chat, title: updatedChat.title };
    setCurrentChat(newChat);
    setSelectedChat(newChat);
    setRefreshHistory((prevState) => prevState + 1);
  });
  const {
    FilesModal,
    setChatId,
    setShowModal: setShowFilesModal,
  } = useChatFilesModal();

  const handleRemove = () => {
    setData(chat || { chatId: id });
    setShowDeleteModal(true);
  };

  const handleEditName = () => {
    setChat({ chatId: chat.chatId, title: chat.title });
    setShowRenameChatModal(true);
  };

  const handleShowFilesModal = () => {
    setChatId(selectedChat?.chatId || id);
    setShowFilesModal(true);
  };

  return (
    <>
      <DeleteModal />
      <FilesModal />
      <RenameChatModal />
      <div className="d-flex align-items-center gap-1">
        <OwnerAndDate chat={chat} />
        <MoreActions
          useLucidIcon
          items={actionItems}
          onHandleRemove={(e) => {
            e.stopPropagation();
            handleRemove();
          }}
          onHandleEdit={(e) => {
            e.stopPropagation();
            handleEditName();
          }}
          onHandleAdd={handleShowFilesModal}
          toggleClassName="w-auto p-0 h-auto"
        />
      </div>
    </>
  );
};

const Thread = ({ id }) => {
  const { hash } = useHash();
  const { selectedChat, newQuestion, historyNewChat } = useIdaContext();
  const actualId =
    hash.includes('/new') && Object.keys(newQuestion || {}).length ? null : id;
  const { questions, setQuestions, loading } = useFetchChatQuestions(
    Object.keys(selectedChat || {}).length === 1 ? null : actualId
  );
  const { BackTop } = useBackTop('chatBox');
  const { chat, setChat: setCurrentChat } = useFetchChat(
    selectedChat?.chatId || actualId,
    Object.keys(selectedChat || {}).length === 1 ? {} : selectedChat
  );

  const latestQuestionRef = useRef(null);

  useEffect(() => {
    if (historyNewChat?.title || historyNewChat?.rename) {
      setCurrentChat(historyNewChat);
    }
  }, [historyNewChat]);

  useEffect(() => {
    // if new question is added, add right away in chat window
    if (Object.keys(newQuestion || {}).length) {
      setQuestions((prevState) => [
        ...prevState
          .filter((q) => q.chatQuestionId !== newQuestion.chatQuestionId)
          .map((q) => ({ ...q, isTemp: false })),
        { ...newQuestion, isTemp: true }, // Add newQuestion with isTemp: true
      ]);

      // this was conflicting with when you scroll up/down during streaming
      if (!newQuestion?.answer?.animate) {
        setTimeout(() => {
          latestQuestionRef.current?.scrollIntoView({
            behavior: 'smooth',
            block: 'start',
          });
        }, 10);
      }
    }
  }, [newQuestion]);

  useEffect(() => {
    if (!loading) {
      setTimeout(() => {
        // this could be improved.?
        const container = document.getElementById('chatBox');
        latestQuestionRef.current?.scrollIntoView({
          behavior: 'smooth',
          block: 'start',
        });
        if (container && latestQuestionRef.current) {
          const questionRect =
            latestQuestionRef.current.getBoundingClientRect();
          const containerRect = container.getBoundingClientRect();
          const extraPadding = 20;
          const scrollPosition =
            questionRect.top - containerRect.top - extraPadding;
          container.scrollBy({
            top: scrollPosition,
            behavior: 'smooth',
          });
        }
      }, 200);
    }
  }, [loading]);

  return (
    <>
      <PageTitle page={chat?.title || ''} />
      {loading ? (
        <ThreadLayoutSkeleton />
      ) : (
        <ThreadLayout
          heading={{
            ...MenuItemsKeysAndIcons.Thread,
            title: chat?.title || '',
          }}
          controls={
            <ActionControls
              id={id}
              chat={chat}
              selectedChat={selectedChat}
              setCurrentChat={setCurrentChat}
            />
          }
        >
          {questions.length || chat?.title ? (
            <>
              {questions.length === 0 ? (
                <div className="bg-white h-100 w-100">
                  <div className="chat-home-container align-items-center justify-content-center h-100 mx-auto">
                    <div
                      className="border d-flex align-items-center justify-content-center rounded-circle p-3"
                      style={{ width: 52, height: 52 }}
                    >
                      <Image src={IDABotImage} width={36} />
                    </div>
                    <AnimatedText
                      text="What can I help you with today?"
                      typingSpeed="30"
                    />
                  </div>
                </div>
              ) : (
                <div className="row pb-3 justify-content-center position-relative">
                  <Conversation
                    questions={questions}
                    latestQuestionRef={latestQuestionRef}
                    fromAskIdaModal={false}
                  />
                </div>
              )}
              <div className="chat-home-window-size mx-auto px-0 pt-2 pb-0 bottom-0 z-index-99 position-sticky">
                <BackTop />
                <div className="thread-chat-container">
                  <div className="position-relative">
                    <AskAnything
                      displayType={InputDisplayType.Thread}
                      chatId={id}
                      placeholder="Ask follow-up"
                    />
                  </div>
                </div>
              </div>
            </>
          ) : (
            <Home />
          )}
        </ThreadLayout>
      )}
    </>
  );
};

export default Thread;
