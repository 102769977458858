import authHeader from './auth-header';
import BaseRequestService from './baseRequest.service';

class MetricsServices extends BaseRequestService {
  getOrganizationTreasuryMetricsData(orgId, data = {}, cancelToken) {
    return this.post(`/organizations/${orgId}/metrics/lan`, data, {
      headers: authHeader(),
      cancelToken,
    });
  }

  getOrganizationMerchantMetricsData(orgId, data = {}, cancelToken) {
    return this.post(`/organizations/${orgId}/metrics/ein`, data, {
      headers: authHeader(),
      cancelToken,
    });
  }

  getSubOrganizationMerchantMetricsData(subOrgId, data = {}, cancelToken) {
    return this.post(`/subOrganizations/${subOrgId}/metrics/ein`, data, {
      headers: authHeader(),
      cancelToken,
    });
  }
}

export default new MetricsServices();
