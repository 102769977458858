import { useState, useEffect } from 'react';
import { cubeService } from '../../services';

const useFetchAssignmentReports = (filters) => {
  const [assignmentReports, setAssignmentReports] = useState();
  const [paginationAssignmentReports, setPaginationAssignmentReports] =
    useState({
      page: 1,
      limit: 25,
    });
  const [isLoadingFetchAssignmentReports, setIsLoadingFetchAssignmentReports] =
    useState(false);

  const fetchAssignmentReports = async (assignmentId) => {
    setIsLoadingFetchAssignmentReports(true);
    const results = await cubeService.loadCustomAnalytics({
      type: 'AssignmentReport',
      limit: paginationAssignmentReports.limit,
      userIds: filters?.userIds,
      teamIds: filters?.teamIds,
    });
    setAssignmentReports(results);
    setIsLoadingFetchAssignmentReports(false);
  };

  useEffect(() => {
    fetchAssignmentReports();
  }, [
    paginationAssignmentReports.page,
    paginationAssignmentReports.limit,
    filters?.userIds,
    filters?.teamIds,
  ]);
  return {
    assignmentReports,
    setAssignmentReports,
    paginationAssignmentReports,
    setPaginationAssignmentReports,
    fetchAssignmentReports,
    isLoadingFetchAssignmentReports,
  };
};

export default useFetchAssignmentReports;
