import { useEffect, useState } from 'react';
import userService from '../../services/user.service';
import { useDebounce } from '../useDebounce';

const useFetchUsers = ({ status = 'active', callback }) => {
  const [users, setUsers] = useState([]);
  const [searchQueryUsers, setSearchQueryUsers] = useState('');
  const [paginationUsers, setPaginationUsers] = useState({
    page: 1,
    limit: 10,
  });
  const [queryFilterUsers, setQueryFilterUsers] = useState({
    filters: [],
  });

  const debouncedSearch = useDebounce(searchQueryUsers, 500);

  const getUsers = async () => {
    const { data } = await userService.getUsers(
      { status, search: debouncedSearch },
      { page: paginationUsers.page, limit: paginationUsers.limit }
    );
    setUsers(data.users);
  };

  useEffect(() => {
    getUsers();
  }, [debouncedSearch, paginationUsers.page, status]);

  const handleAllSelectUsers = () => {
    const allSelected = users?.map((rpt) => rpt?.id);

    setQueryFilterUsers({
      filters: [...allSelected],
    });
    callback?.();
  };

  const handleOnDoneUsers = (items) => {
    setQueryFilterUsers({
      filters: [...items],
    });
  };

  return {
    users,
    searchQueryUsers,
    setSearchQueryUsers,
    paginationUsers,
    setPaginationUsers,
    queryFilterUsers,
    setQueryFilterUsers,
    handleOnDoneUsers,
    handleAllSelectUsers,
  };
};

export default useFetchUsers;
