import React from 'react';
import MaterialIcon from '../../commons/MaterialIcon';
import { numberWithCommas } from '../../../utils/Utils';
import ButtonFilterDropdown from '../../commons/ButtonFilterDropdown';
import { Card, CardHeader } from 'reactstrap';

const CardItem = ({ icon, clazz, title, numbers }) => {
  return (
    <div className="col-md-6 d-flex flex-column align-items-start mb-4 border rounded">
      <span className="my-2">
        <MaterialIcon icon={icon} size="large" clazz={clazz} />
      </span>
      <h5>{title}</h5>
      <p className="h4 font-weight-bold"> {numberWithCommas(numbers)} </p>
    </div>
  );
};

const Overview = () => {
  const topCompanies = [
    'FinTech Solutions Inc.',
    'Global Payments Co.',
    'Banking Partners Ltd.',
    'Digital Finance LLC',
    'Money Matters Corp.',
    'QuickPay Services',
    'Trusted Banking Solutions',
    'Secure Payments Ltd.',
    'NextGen Finance Group',
    'Innovative Funds LLC',
  ];

  const topQuestions = [
    'What are the ACH file format requirements?',
    'How can I verify a bank account?',
    'What is the standard processing time?',
    'How to handle payment disputes?',
    'What are the fees associated?',
    'Can I cancel a payment?',
    'How to update my bank details?',
    'What documents are needed for verification?',
    'How do ACH returns work?',
    'What are the daily limits on transactions?',
  ];
  const SechduleList = [
    { key: 'week', name: 'Last 7 days' },
    { key: 'month', name: 'Last month' },
  ];
  const ContentList = [{ key: 'content', name: 'Content' }];

  return (
    <Card className="overflow-x-hidden overflow-y-auto h-100">
      <CardHeader className="d-flex align-items-center justify-content-between">
        <div className="title">
          <h4 className="m-0">Overview</h4>
        </div>
        <div className="d-flex gap-2">
          <ButtonFilterDropdown
            menuClass="rpt-history-dd-width max-h-300"
            options={SechduleList}
            icon="calendar_today"
            filterOptionSelected={SechduleList[0]}
            handleFilterSelect={() => {}}
          />
          <ButtonFilterDropdown
            menuClass="rpt-history-dd-width max-h-300"
            options={ContentList}
            filterOptionSelected={ContentList[0]}
            handleFilterSelect={() => {}}
          />
        </div>
      </CardHeader>

      <div className="py-4 px-4">
        <div className="d-flex align-items-center justify-content-center gap-3">
          <CardItem
            icon="insert_chart"
            clazz="text-primary"
            title="Companies with Access"
            numbers={3433}
          />
          <CardItem
            icon="group"
            clazz="text-info"
            title="Users with Access"
            numbers={3433}
          />
        </div>
        <div className="d-flex align-items-center justify-content-center gap-3">
          <CardItem
            icon="contact_support"
            clazz="text-warning"
            title="Avg. Questions per Day"
            numbers={3433}
          />
          <CardItem
            icon="thumb_up"
            clazz="text-success"
            title="Positive feedback Rate"
            numbers={3433}
          />
        </div>
        <div className="d-flex align-items-start justify-content-center gap-3">
          <div className="col-md-6 d-flex flex-column align-items-start p-3 border rounded">
            <h5>Top Companies by Usage</h5>
            <ul className="list-unstyled w-100 text-start">
              {topCompanies.map((company, index) => (
                <li
                  key={index}
                  className={`py-2 ${
                    index !== topCompanies.length - 1 ? 'border-bottom' : ''
                  } text-start`}
                >
                  {index + 1}. {company}
                </li>
              ))}
            </ul>
          </div>
          <div className="col-md-6 d-flex flex-column align-items-start p-3 border rounded">
            <h5>Top Questions</h5>
            <ul className="list-unstyled w-100 text-start">
              {topQuestions.map((question, index) => (
                <li
                  key={index}
                  className={`py-2 ${
                    index !== topQuestions.length - 1 ? 'border-bottom' : ''
                  } text-start`}
                >
                  {index + 1}. {question}
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    </Card>
  );
};

export default Overview;
