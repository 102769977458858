import React, { useState, useEffect } from 'react';
import { Spinner } from 'reactstrap';
import { useHistory } from 'react-router-dom';
import AuthService from '../services/auth.service';
import TenantService from '../services/tenant.service';
import { useAppContext } from '../contexts/appContext';
import stringConstants from '../utils/stringConstants.json';
import { PublicLayout } from '../layouts/PublicLayout';
import { useProfileContext } from '../contexts/profileContext';
import { useTenantContext } from '../contexts/TenantContext';
import {
  searchParams,
  parseJwt,
  setIdfToken,
  getIdfToken,
  INDUSTRIES_STORAGE_KEY,
  TECHNOLOGIES_STORAGE_KEY,
  SIC_STORAGE_KEY,
  NAICS_STORAGE_KEY,
  isDisplayWelcomeScreen,
} from '../utils/Utils';
import PageTitle from '../components/commons/PageTitle';
import Skeleton from 'react-loading-skeleton';
import useUrlSearchParams from '../hooks/useUrlSearchParams';
import { useKeycloak } from '../contexts/KeycloakProvider';
import useEnvSettings from '../hooks/useEnvSettings';
import useIsCommonLogin from '../hooks/useIsCommonLogin';
import AlertWrapper from '../components/Alert/AlertWrapper';
import Alert from '../components/Alert/Alert';

const msgTimeout = 4000;
const constants = stringConstants.login;
const Messages = {
  RESET: 'You have successfully updated your password.',
};
const Login = () => {
  const history = useHistory();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const { userHasAuthenticated } = useAppContext();
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const [info, setInfo] = useState('');
  const [code, setCode] = useState('');
  const { isCommonLogin, isClientPortal } = useIsCommonLogin();
  const [isOTPEnabled, setIsOTPEnabled] = useState(false);
  const { search } = history.location;
  const { setProfileInfo } = useProfileContext();
  const { tenant, isLoadingTenantInfo } = useTenantContext();
  const [id, setId] = useState();
  const [accessToken, setAccessToken] = useState();
  const [expires, setExpires] = useState();
  const [refreshToken, setRefreshToken] = useState();
  const [idfToken, setidfTokenParsed] = useState();
  const [loading, setLoading] = useState(false);
  const query = useUrlSearchParams();
  const { count, setCount } = useTenantContext();
  const keycloak = useKeycloak();
  const { hideLoginInputs, hideForgetPassword } = useEnvSettings();
  const [domain, setDomain] = useState('');

  const getProviderLogin = () => {
    const clientId = 'identifee-app';
    const redirectUri = encodeURIComponent(
      `${window.location.origin}/callback`
    );
    const realm = tenant?.realm || 'identifee';
    // kc_idp_hint is the keycloak param to hint the provider
    const idpHint = tenant?.idp?.providers[0]?.alias || 'oidc';
    const authUrl = `${process.env.REACT_APP_IDP_URL}/realms/${realm}/protocol/openid-connect/auth?client_id=${clientId}&redirect_uri=${redirectUri}&response_type=code&scope=openid+profile&kc_idp_hint=${idpHint}`;
    return authUrl;
  };

  const onSSLogin = () => {
    // redirect to provider login
    window.location.href = getProviderLogin();
  };
  const getToken = () => {
    setLoading(true);
    try {
      const token = query.get('access_token') || null;
      if (token) {
        const tokenParse = parseJwt(token);
        const idfTokenParsed = {
          id: tokenParse.id,
          access_token: token,
          expires: tokenParse.exp * 1000,
          refresh_token: query.get('refresh_token'),
        };
        setAccessToken(idfTokenParsed.access_token);
        setExpires(idfTokenParsed.expires); // multiplying with thousand to make it miliseconds
        setRefreshToken(idfTokenParsed.refresh_token);
        setId(idfTokenParsed.id);
        setIdfToken(JSON.stringify(idfTokenParsed));
        setidfTokenParsed(idfTokenParsed);
        userHasAuthenticated(true);
        setProfileInfo({});
      } else {
        if (keycloak?.authenticated) {
          setidfTokenParsed(getIdfToken());
          if (getIdfToken()) {
            userHasAuthenticated(true);
            history.push('/');
          }
        }
      }
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };
  const showMessageOnRedirect = () => {
    const msgKey = query.get('from');
    if (msgKey) {
      setSuccess(Messages[msgKey]);
      history.replace({ search: '' });
    }
  };

  useEffect(() => {
    getToken();
    showMessageOnRedirect();
    localStorage.removeItem('tabsOpen');
    localStorage.removeItem(INDUSTRIES_STORAGE_KEY);
    localStorage.removeItem(TECHNOLOGIES_STORAGE_KEY);
    localStorage.removeItem(SIC_STORAGE_KEY);
    localStorage.removeItem(NAICS_STORAGE_KEY);
  }, []);
  const handleHost = () => {
    setLoading(true);
    try {
      if (isClientPortal) {
        history.push('/clientportal/login');
      }
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    handleHost();
    /**
     * this sucks but each one has its use case
     *
     * 1. login.example.com - is for a common tenant? login. User will be looked
     * up and redirected to their appropriate themed login.
     * 2. clientportal is for guest users. Rather than boot up a separate
     * project, we hardcode domain and redirect to appropriate component.
     */
  }, []);
  const getTenantInfo = (loginDetails) => {
    setLoading(true);
    const tokenParse = parseJwt(loginDetails.access_token);
    TenantService.getSingleTenant(tokenParse?.tenant_id)
      .then((resp) => {
        console.log(resp);
        if (resp?.domain) {
          window.open(
            `https://${resp.domain}/login?id=${tokenParse.id}&access_token=${loginDetails.access_token}&expires=${loginDetails.expires}&refresh_token=${loginDetails.refresh_token}`,
            '_self'
          );
        }
      })
      .catch((error) => {
        if (code) {
          setError(constants.invalidCodeMessage);
        } else {
          setError(error?.response?.data?.errors?.[0]?.message);
        }
        setTimeout(() => {
          setError('');
        }, msgTimeout);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsLoading(true);

    try {
      const response = await AuthService.login(
        isCommonLogin ? null : tenant?.id,
        email,
        password,
        code
      );

      if (response.access_token === 'otp_enabled') {
        setLoading(true);
        setIsOTPEnabled(true);
      } else {
        if (isCommonLogin) {
          getTenantInfo(response);
        } else {
          userHasAuthenticated(true);
          setProfileInfo({});
          const redirect_uri = searchParams(search, 'redirect_uri');
          if (redirect_uri) {
            history.push(redirect_uri);
          } else {
            if (isDisplayWelcomeScreen(tenant?.modules)) {
              history.push('/welcome');
            } else {
              setCount(2);
              history.push('/');
            }
          }
        }
      }
    } catch (error) {
      if (code) {
        setError(constants.invalidCodeMessage);
      } else {
        setError(
          `Please check your email address and password. If you still can't log in, contact your ${
            tenant?.name ? tenant?.name : ''
          } administrator.`
        );
      }
    } finally {
      setIsLoading(false);
      setLoading(false);
    }
  };

  const suffix = '.identifee.com';
  const handleUrlChange = (e) => {
    const { value } = e.target;
    const isDotPresent = value.endsWith('.');
    const isBackspace = e.nativeEvent.inputType === 'deleteContentBackward';
    if (!isBackspace && isDotPresent && !value.includes('.identifee')) {
      setDomain(value + 'identifee.com');
    } else {
      setDomain(value);
    }
  };

  const handleUrlClick = (e) => {
    if (!domain) {
      e.preventDefault();
      setError('Please enter domain.');
    }
  };
  const resendCode = async () => {
    await AuthService.login(tenant?.id, email, password);
    setCode('');
    setInfo('New code sent');
  };

  const renderLogin = () => (
    <>
      {loading || count === 0 ? (
        <div className="">
          <div className="mt-3">
            <Skeleton height={15} width={110} />
          </div>
          <div className="mt-1 mb-2">
            <Skeleton height={45} className="d-block w-100" />
          </div>
          <div className="mt-3">
            <Skeleton height={15} width={110} />
          </div>
          <div className="mt-1 mb-2">
            <Skeleton height={45} className="d-block w-100" />
          </div>
          <div className="mt-1">
            <Skeleton height={53} className="d-block w-100" />
          </div>
        </div>
      ) : (
        <form className="js-validate px-0" onSubmit={handleSubmit}>
          {!hideLoginInputs && (
            <>
              <div className="js-form-message form-group">
                <label className="input-label" htmlFor="signinSrEmail">
                  Email Address
                </label>
                <input
                  type="email"
                  className="form-control"
                  name="email"
                  id="signinSrEmail"
                  tabIndex="0"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  required
                  disabled={isOTPEnabled}
                />
              </div>
              <div className="js-form-message mt-3 form-group">
                <label className="input-label">
                  <span className="d-flex justify-content-between align-items-center">
                    Password
                  </span>
                </label>
                <div className="input-group input-group-merge">
                  <input
                    type="password"
                    className="js-toggle-password form-control"
                    name="password"
                    id="signupSrPassword"
                    tabIndex="0"
                    aria-label="8+ characters required"
                    required
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                  />
                  <div id="changePassTarget" className="input-group-append">
                    <a className="input-group-text">
                      <i
                        id="changePassIcon"
                        className="tio-visible-outlined"
                      ></i>
                    </a>
                  </div>
                </div>
              </div>
              {!hideForgetPassword && (
                <div className="w-100 mb-4">
                  <span
                    className="text-primary font-size-sm2 font-weight-medium cursor-pointer"
                    onClick={() => history.push('/request-password')}
                  >
                    Forgot your password?
                  </span>
                </div>
              )}
            </>
          )}
          <div>
            {!hideLoginInputs && (
              <button
                type="submit"
                className="btn btn-md btn-block font-weight-medium font-size-sm2 btn-primary"
                disabled={isLoading || isLoadingTenantInfo}
              >
                {isLoading ? <Spinner className="spinner-grow-xs" /> : 'Login'}
              </button>
            )}
            {tenant?.idp?.enabled === true && (
              <>
                {hideLoginInputs && (
                  <div className="text-center">
                    <div className="mb-2">
                      <p>To log into your account, use single sign on below.</p>
                    </div>
                  </div>
                )}
                <button
                  type="button"
                  className={`btn btn-md font-weight-medium mt-2 font-size-sm2 btn-block ${
                    hideLoginInputs ? 'btn-primary' : 'btn-white text-black'
                  }`}
                  onClick={onSSLogin}
                >
                  SSO Login
                </button>
              </>
            )}
          </div>
        </form>
      )}
    </>
  );

  const renderOTP = () => (
    <>
      {loading ? (
        <div className="">
          <div className="my-1  text-center">
            <Skeleton height={30} width={300} />
          </div>
          <div className="my-1 text-center mb-3">
            <Skeleton height={15} width={200} />
          </div>
          <div className="mt-3">
            <Skeleton height={15} width={110} />
          </div>
          <div className="mt-1 mb-2">
            <Skeleton height={45} width={370} />
          </div>
          <div className="mt-1">
            <Skeleton height={53} width={370} />
          </div>
          <div className="my-1 text-center mb-3">
            <Skeleton height={15} width={200} />
          </div>
        </div>
      ) : (
        <form className="js-validate" onSubmit={handleSubmit}>
          <div className="text-left">
            <div className="mb-3">
              <p className="mb-0">
                Enter the code from your email in the field below to continue.
              </p>
            </div>
          </div>
          <div className="js-form-message form-group">
            <label className="input-label">
              <span className="d-flex justify-content-between align-items-center">
                Enter Code
              </span>
            </label>

            <div className="input-group input-group-merge">
              <input
                type="text"
                className="js-toggle-password form-control"
                placeholder="Enter 6 Digit Code"
                name="otp_code"
                id="otp_code"
                maxLength="6"
                tabIndex="1"
                value={code}
                onChange={(e) =>
                  setCode(e.target.value.replace(/[a-zA-Z ]/g, ''))
                }
                required
              />
            </div>
          </div>

          <button
            type="submit"
            className="btn btn-md font-size-sm2 font-weight-medium btn-block btn-primary"
            disabled={isLoading || isLoadingTenantInfo}
          >
            {isLoading ? <Spinner /> : 'Verify'}
          </button>
          <div className="text-left font-size-sm2 mt-3">
            <p className="mb-0">
              {`Haven't received it? `}
              <a
                href="#!"
                className="text-primary font-weight-medium"
                onClick={resendCode}
              >
                Resend a new code.
              </a>
            </p>
          </div>
        </form>
      )}
    </>
  );

  if (id) {
    function impersonateFunction() {
      setIdfToken(
        JSON.stringify({
          id,
          access_token: accessToken,
          expires,
          refresh_token: refreshToken,
        })
      );
      userHasAuthenticated(true);
      setProfileInfo({});
    }
    impersonateFunction();
    history.push('/');
  } else {
    return (
      <>
        <AlertWrapper>
          <Alert
            message={error}
            setMessage={setError}
            color="warning"
            alertWidth={600}
            alertContentWidth={350}
          />
          <Alert message={info} setMessage={setInfo} color="info" />
          <Alert message={success} setMessage={setSuccess} color="success" />
        </AlertWrapper>
        {isCommonLogin ? (
          <>
            <PageTitle page="Enter Your Identifee URL" pageModule="" />
            <PublicLayout title="Enter Your Identifee URL">
              <div className="row justify-content-center">
                <div className="col-md-7 col-lg-5">
                  <div className="card">
                    <div className="card-body">
                      <p>
                        To go to your company’s login page, enter the custom
                        domain name.
                      </p>
                      <div className="js-form-message form-group">
                        <label className="input-label" htmlFor="signinSrEmail">
                          Custom Domain
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          name="url"
                          id="signinUrl"
                          tabIndex="0"
                          value={domain}
                          onChange={(e) => handleUrlChange(e)}
                          required
                        />
                      </div>
                      <p className="text-gray-dark">
                        https://
                        <span className="font-italic">
                          {domain && !domain.includes('.')
                            ? domain.substring(0, domain.length)
                            : (domain &&
                                domain.substring(0, domain.indexOf('.')) +
                                  suffix) ||
                              'domain'}
                        </span>
                        {domain && !domain.includes('.')
                          ? domain.substring(0, domain.indexOf('.')) + suffix
                          : domain.includes('.')
                          ? ''
                          : suffix}
                      </p>
                      <a
                        onClick={(e) => handleUrlClick(e)}
                        href={`https://${
                          domain && domain.includes(suffix)
                            ? domain.substring(0, domain.indexOf('.'))
                            : domain
                        }${suffix}`}
                        className="btn btn-md hover-link btn-block text-white font-weight-medium font-size-sm2 btn-primary"
                      >
                        Continue
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </PublicLayout>
          </>
        ) : (
          <>
            <PageTitle
              page={
                isOTPEnabled
                  ? 'Two-Factor Authentication'
                  : tenant?.idp?.enabled === true && hideLoginInputs
                  ? 'Welcome Back!'
                  : 'Login'
              }
              pageModule=""
            />
            {!idfToken ? (
              <PublicLayout
                title={
                  isOTPEnabled
                    ? 'Two-Factor Authentication'
                    : tenant?.idp?.enabled === true && hideLoginInputs
                    ? 'Welcome Back!'
                    : ''
                }
              >
                <div className="row justify-content-center">
                  <div className="col-md-7 col-lg-5">
                    <div className="card">
                      <div className="card-body">
                        {isOTPEnabled ? renderOTP() : renderLogin()}
                      </div>
                    </div>
                  </div>
                </div>
              </PublicLayout>
            ) : (
              ''
            )}
          </>
        )}
      </>
    );
  }
};

export default Login;
