import axios from './axiosInstance';
import authHeader from './auth-header';
import BaseRequestService from './baseRequest.service';
import { errorsRedirectHandler } from '../utils/Utils';
class TeamService extends BaseRequestService {
  async getTeams(query = {}) {
    const { page = 1, limit = 1000, self = true, order, filter = {} } = query;
    return axios
      .get(`/teams`, {
        params: {
          order,
          page,
          limit,
          search: filter?.search,
          self,
          isActive: filter?.isActive,
        },
        headers: authHeader(),
      })
      .then((response) => response.data)
      .catch((error) => error);
  }

  async CreateTeam(data) {
    return await axios.post('/teams', data, { headers: authHeader() });
  }

  async getTeamById(team_id) {
    return axios
      .get(`/teams/${team_id}`, { headers: authHeader() })
      .then((response) => response.data)
      .catch((error) => error);
  }

  async getTeamMembersById(
    team_id,
    { page = 1, limit = 10, order, isManager }
  ) {
    const params = {
      page,
      limit,
      order,
      isManager,
    };
    return axios
      .get(`/teams/${team_id}/members`, {
        params,
        headers: authHeader(),
      })
      .then((response) => response.data)
      .catch((error) => error);
  }

  async updateTeam(id, data) {
    return await axios
      .put(`/teams/${id}`, data, { headers: authHeader() })
      .then((response) => response.data)
      .catch((error) => error);
  }

  async deleteTeam(team_id) {
    return axios
      .delete(`/teams/${team_id}`, {
        headers: authHeader(),
      })
      .then((response) => response.data)
      .catch((error) => error);
  }

  async updateTeamMemberById(data, team_id) {
    return await this.put(`/teams/${team_id}/members`, data, {
      headers: authHeader(),
    }).catch(errorsRedirectHandler);
  }

  async setTeamManager(memberId) {
    return await this.put(
      `/teamMembers/${memberId}`,
      { isManager: true },
      {
        headers: authHeader(),
      }
    ).catch(errorsRedirectHandler);
  }

  async deleteTeamMember(memberId) {
    return await this.delete(`/teamMembers/${memberId}`, {
      headers: authHeader(),
    }).catch(errorsRedirectHandler);
  }
}

export default new TeamService();
