/* eslint-disable dot-notation */
/* eslint-disable no-unused-vars */
import React, { useEffect, useRef, useState } from 'react';
import {
  Card,
  Col,
  FormGroup,
  Label,
  // ModalFooter,
  Row,
  Spinner,
} from 'reactstrap';
import SimpleModal from '../modal/SimpleModal';
import stringConstants from '../../utils/stringConstants.json';
import {
  createBlobObject,
  emailRegex,
  getPatternErrorMessage,
  overflowing,
} from '../../utils/Utils';
import FormColor from '../siteSettings/FormColor';
import IdfTooltip from '../idfComponents/idfTooltip';
import { CHOOSE_IMAGE_FILE } from '../../utils/constants';
import DragDropUploadFile from '../commons/DragDropUploadFile';
import userService from '../../services/user.service';
import InputValidation from '../commons/InputValidation';
import Asterick from '../commons/Asterick';
import { useForm } from 'react-hook-form';
import ModulesToggles from '../TenantCheckBox';
import MaterialIcon from '../commons/MaterialIcon';
import ControllerValidation from '../commons/ControllerValidation';
import { CheckboxInput } from '../layouts/CardLayout';
import { FormCheck, InputGroup } from 'react-bootstrap';
import TooltipComponent from '../lesson/Tooltip';
import SMTPConfiguration from './SMTPConfiguration';
import ButtonFilterDropdown from '../commons/ButtonFilterDropdown';
import NewKindOfSearchDropdown from '../commons/NewKindOfSearchDropdown';
import CurrencyInput from 'react-currency-input-field';
import LucideIcon from '../commons/LucideIcon';
import useFetchArchives from '../chat/hooks/useFetchArchives';
import Skeleton from 'react-loading-skeleton';
import ButtonIcon from '../commons/ButtonIcon';
import ArchiveService from '../../services/archive.service';

const defaultIdpSettings = {
  hideLoginInputs: false,
  hideForgetPassword: false,
  disableProfileChanges: false,
  disablePasswordChanges: false,
};

const defaultSettings = {
  idp: {
    enabled: false,
    providers: [{ alias: 'oidc' }],
    settings: defaultIdpSettings,
  },
  organizations: { dataTab: true },
};

const IDP_SETTINGS_TOGGLES = [
  {
    id: 1,
    key: 'hideLoginInputs',
    text: 'Hide Login Inputs',
    description:
      'This will hide both email and password fields from login screen.',
  },
  {
    id: 2,
    key: 'hideForgetPassword',
    text: 'Hide Forget Password Link',
    description: 'This will hide forget password link from login screen.',
  },
  {
    id: 3,
    key: 'disableProfileChanges',
    text: 'Disable Profile Changes',
    description:
      'This will disable user profile update under profile settings.',
  },
  {
    id: 4,
    key: 'disablePasswordChanges',
    text: 'Disable Password Change',
    description:
      'This will disable ability for users to change their passwords in profile settings security area.',
  },
];

const componentKeys = {
  general: 'general',
  branding: 'branding',
  modules: 'modules',
  premium: 'premium-content',
  sso: 'single-sign-on',
  smtp: 'smtp-config',
};

const CreateTenantModal = ({
  setErrorMessage,
  setSuccessMessage,
  showLoading,
  showModal,
  setSelectedEditData,
  setShowModal,
  isLoading,
  handleUpdateTenant,
  data = [],
  selectedEditData,
  handleCreateTenant,
}) => {
  const defaultOptions = {
    owner: {
      email: '',
    },
    name: '',
    domain: '',
    description: '',
    use_logo: false,
    modules: [],
    logo: '',
    icon: '',
    type: 'owner',
    colors: {
      secondaryColor: '',
    },
  };
  const {
    register,
    handleSubmit,
    getFieldState,
    reset,
    setValue,
    control,
    formState: { errors },
  } = useForm({
    defaultValues: defaultOptions,
  });
  const constants = stringConstants.tenants;
  const [currentEventKey, setCurrentEventKey] = useState('general');
  const [logo, setLogo] = useState();
  const [icon, setIcon] = useState();
  const [tenantForm, setTenantForm] = useState(defaultOptions);
  const [iconId, setIconId] = useState();
  const [logoId, setLogoId] = useState();
  const [selectedCheckboxes, setSelectedCheckboxes] = useState([]);
  const [iconLoading, setIconLoading] = useState(false);
  const domainInputRef = useRef(null);
  const [errorsState, setErrorsState] = useState({});
  const [isTouched, setIsTouched] = useState(false);
  const [contentForm, setContentForm] = useState({
    selectedContent: '',
    licenseQuantity: '',
    numberOfUsers: '',
    selectedTenant: '',
  });
  const handleKeyDown = (event) => {
    if (event.getModifierState('CapsLock')) {
      event.preventDefault();
      setErrorMessage('Please Off your caps lock');
    }
  };
  const onLoadIcon = async (event) => {
    setIconLoading(true);
    const target = event.target.files[0];
    setIcon(target);
    let iconId = target?.id || '';
    if (target && target?.lastModified) {
      iconId = await onUploadLogo(target);
    }
    setTenantForm((prevForm) => ({
      ...prevForm,
      icon: iconId,
    }));
    setValue('icon', iconId);
    setIconId(iconId);
    setIconLoading(false);
  };
  const getEnvSettings = (currentSettings) => {
    if (!currentSettings?.idp?.settings) {
      currentSettings.idp = {
        ...currentSettings?.idp,
        settings: defaultIdpSettings,
      };
    }
    return currentSettings;
  };
  useEffect(() => {
    const tenantData = {
      name: selectedEditData.name,
      description: selectedEditData.description,
      modules: selectedEditData.modules?.split(',') || [],
      domain: selectedEditData.domain,
      use_logo: selectedEditData.use_logo || null,
      icon: selectedEditData.icon,
      logo: selectedEditData.logo,
      colors: selectedEditData.colors,
      settings: getEnvSettings(selectedEditData.settings || defaultSettings),
    };
    setTenantForm((prevForm) => ({
      ...prevForm,
      ...tenantData,
    }));
    setValue('name', selectedEditData.name);
    setValue('domain', selectedEditData.domain);
    setValue('use_logo', selectedEditData.use_logo);
    setValue('secondaryColor', selectedEditData.colors);
  }, [selectedEditData]);

  const handleImageState = () => {
    setLogo();
    setIcon();
  };
  const onLoadLogo = async (event) => {
    const target = event.target.files[0];
    setLogo(target);
    let logoId = target?.id || '';
    if (target && target?.lastModified) {
      logoId = await onUploadLogo(target);
    }
    setTenantForm((prevForm) => ({
      ...prevForm,
      logo: logoId,
    }));
    setValue('logo', logoId);
    setLogoId(logoId);
  };
  const onChangeColor = (value) => {
    const colorValue = {
      primaryColor: value?.secondaryColor,
      ...value,
      name: 'custom',
    };
    setValue('secondaryColor', colorValue);

    setTenantForm((prevForm) => ({
      ...prevForm,
      colors: colorValue,
    }));
  };
  const onUploadLogo = async (file) => {
    const form = new FormData();
    form.append('file', await createBlobObject(file), file.name);
    form.append('isPublic', true);
    const {
      data: {
        data: { id },
      },
    } = await userService.uploadAvatar(form);
    return id;
  };

  const handleChange = (e) => {
    const target = e.target;
    const inputValue = target.value;
    if (target.name !== 'email') {
      setTenantForm((prevForm) => ({
        ...prevForm,
        [target.name]: inputValue,
      }));
    } else {
      setTenantForm((prevForm) => ({
        ...prevForm,
        owner: {
          ...tenantForm?.owner,
          email: inputValue,
        },
      }));
    }
  };

  const getLogo = async (id) => {
    const response = await userService.getFile(id);
    return response?.data;
  };
  const getTenantImage = async () => {
    try {
      if (selectedEditData.logo) {
        const partnerLogo = await getLogo(selectedEditData.logo);

        if (partnerLogo) {
          const newPartnerLogo = {
            ...partnerLogo,
            name: partnerLogo?.filename_download,
            size: partnerLogo?.filesize,
          };
          setLogo(newPartnerLogo);
        }
        setValue('logo', partnerLogo.filename_download);
      }

      if (selectedEditData.icon) {
        const partnerIcon = await getLogo(selectedEditData.icon);

        if (partnerIcon) {
          setIcon({
            ...partnerIcon,
            name: partnerIcon?.filename_download,
            size: partnerIcon?.filesize,
          });
        }
      }
    } catch (error) {
      setErrorMessage(error.message);
    }
  };
  useEffect(() => {
    getTenantImage();
  }, [selectedEditData]);

  const handleAccessChange = (e) => {
    const target = e.target;
    if (target.name === 'use_logo' && target.checked) {
      setValue('use_logo', true);

      setTenantForm((prevForm) => ({
        ...prevForm,
        use_logo: true,
      }));
    } else {
      setValue('use_logo', '');

      setTenantForm((prevForm) => ({
        ...prevForm,
        use_logo: false,
      }));
    }
  };

  useEffect(() => {
    if (isTouched) {
      const updatedErrorsState = validateForm();
      setErrorsState(updatedErrorsState);
    }
  }, [tenantForm, selectedCheckboxes]);

  const validateForm = () => {
    const errorsMap = {};

    // General Info validation
    if (!tenantForm.name || !tenantForm.domain) {
      errorsMap['general'] = true;
    }
    // Validate email only when creating tenant
    if (!selectedEditData && !tenantForm.owner.email) {
      errorsMap['general'] = true;
    }

    // Branding validation
    if (
      !tenantForm.icon ||
      !tenantForm.logo ||
      !tenantForm.colors.secondaryColor
    ) {
      errorsMap['branding'] = true;
    }

    // Modules validation
    if (selectedCheckboxes.length === 0) {
      errorsMap['modules'] = true;
    }

    return errorsMap;
  };

  const handleFormSubmit = async () => {
    setIsTouched(true);

    const errorsMap = validateForm();
    setErrorsState(errorsMap);

    if (Object.keys(errorsMap).length > 0) {
      setErrorMessage('Please fill out all required fields.');
      return;
    }

    const tenantData = {
      ...tenantForm,
      type: 'owner',
      icon: iconId,
      modules: selectedCheckboxes,
      logo: logoId,
    };
    if (selectedEditData.id) {
      const update = await handleUpdateTenant(tenantData);
      if (update) {
        setTenantForm({});
        reset(defaultOptions);
        handleImageState();
      }
    } else {
      const create = await handleCreateTenant(tenantData);
      if (create) {
        setTenantForm({});
        reset(defaultOptions);
        handleImageState();
      } else {
        return overflowing();
      }
    }

    overflowing();
    closeModal();

    if (!errors) {
      setTenantForm({});
    }
  };

  const closeModal = () => {
    overflowing();
    setShowModal(false);
    setSelectedEditData('');
    setErrorsState({});
    setIsTouched(false);
    setTenantForm({
      name: '',
      domain: '',
      description: '',
      use_logo: false,
      modules: [],
      logo: '',
      icon: '',
      type: '',
      colors: '',
      email: '',
    });
    reset(defaultOptions);
    handleImageState();
  };

  const [premiumContentLoader, setPremiumContentLoader] = useState(false);
  useEffect(() => {
    if (Object?.keys(selectedEditData)?.length > 0) {
      setSelectedCheckboxes(selectedEditData?.modules);
    }
  }, [selectedEditData]);

  const onRemoveFile = () => {
    setValue('logo', '');
    setLogoId('');

    setTenantForm((prevForm) => ({
      ...prevForm,
      logo: '',
    }));
  };

  const handleEnableSSOLogin = (e) => {
    const { checked } = e.target;
    setTenantForm((prevForm) => ({
      ...prevForm,
      settings: {
        ...tenantForm.settings,
        idp: {
          ...tenantForm.settings.idp,
          enabled: checked,
          settings: !checked
            ? defaultIdpSettings
            : tenantForm.settings.idp.settings,
        },
      },
    }));
  };
  const handleSettingToggleChange = (e) => {
    const { name, checked } = e.target;

    setTenantForm((prevForm) => ({
      ...prevForm,
      settings: {
        ...tenantForm.settings,
        idp: {
          ...tenantForm.settings.idp,
          settings: {
            ...tenantForm.settings.idp.settings,
            [name]: checked,
          },
        },
      },
    }));
  };

  const menuItems = [
    { id: 'general', label: 'General', icon: 'ReceiptText' },
    { id: 'branding', label: 'Branding', icon: 'Airplay' },
    { id: 'modules', label: 'Modules', icon: 'Component' },
    ...(selectedEditData
      ? [
          {
            id: 'premium-content',
            label: 'Premium Content',
            icon: 'Sparkles',
          },
        ]
      : []),
    { id: 'single-sign-on', label: 'Single Sign-On', icon: 'Cable' },
    ...(selectedEditData
      ? [{ id: 'smtp-config', label: 'SMTP Configuration', icon: 'Mail' }]
      : []),
  ];

  const isSMTP = currentEventKey === componentKeys.smtp;
  const { loadingPremiumArchives, premiumArchives, fetchPremiumArchives } =
    useFetchArchives(false, 25);

  const handleCreatePremiumOrder = async () => {
    if (!contentForm.selectedContent) {
      setErrorMessage('Please select content.');
      return;
    }
    if (!contentForm.quantity) {
      setErrorMessage('Please input no of licences.');
      return;
    }
    const orderRequest = {
      tenantId: selectedEditData.id,
      order: {
        effectiveAt: new Date().toISOString(),
        quantity: Number(contentForm.quantity || '1'),
      },
      subscription: {
        planId: contentForm.selectedContent.plans[0].planId,
      },
    };
    try {
      setPremiumContentLoader(true);
      await ArchiveService.createOrder(orderRequest);
      setSuccessMessage('Premium Content added for tenant.');
    } catch (e) {
      console.log(e);
    } finally {
      setPremiumContentLoader(false);
    }
  };

  useEffect(() => {
    if (selectedEditData && currentEventKey === componentKeys.premium) {
      fetchPremiumArchives();
    }
  }, [selectedEditData, currentEventKey]);
  return (
    <>
      <SimpleModal
        modalTitle={
          selectedEditData?.id ? (
            <span>{selectedEditData?.title || selectedEditData?.name}</span>
          ) : (
            constants.create.addTenantModalTitle
          )
        }
        onHandleCloseModal={() => closeModal()}
        open={showModal}
        modalBodyClass={`custom-layout pipeline-board-edit-form p-0 ${
          currentEventKey === componentKeys.smtp ||
          currentEventKey === componentKeys.premium
            ? 'overflow-hidden'
            : 'overflow-y-auto'
        }`}
        allowCloseOutside={false}
        isLoading={showLoading}
        size="modal-xl"
        customModal="modal-fit modal-tenant"
        customFooter={null}
      >
        <span className="font-size-sm">{constants.create.textGroupName}</span>

        <div
          style={{ position: 'sticky', top: 0 }}
          className="custom-layout-sidebar no-br p-3 overflow-x-hidden -pt-2_1 d-flex flex-column gap-1"
        >
          {menuItems.map((item) => (
            <Card key={item.id} onClick={() => setCurrentEventKey(item.id)}>
              <div
                className={`px-3 py-3 border d-flex bg-hover-gray align-items-center gap-1 cursor-pointer rounded ${
                  currentEventKey === item.id
                    ? 'bg-accordion-active'
                    : 'bg-white'
                }`}
              >
                <LucideIcon
                  icon={item.icon}
                  size={18}
                  clazz={currentEventKey === item.id ? 'text-primary' : ''}
                />
                <h6
                  className={`mb-0 d-flex align-items-center gap-1 w-100 font-size-sm ${
                    currentEventKey === item.id
                      ? 'font-weight-semi-bold text-primary'
                      : 'font-weight-medium'
                  }`}
                >
                  {item.label}
                  {errorsState[item.id] && (
                    <IdfTooltip text="Invalid input detected in this section. Please ensure all fields are correctly filled.">
                      <LucideIcon
                        icon="TriangleAlert"
                        size="15"
                        color="red"
                        clazz="ml-auto"
                      />
                    </IdfTooltip>
                  )}
                </h6>
              </div>
            </Card>
          ))}
        </div>

        <div className="custom-layout-content d-flex border-left flex-column h-100 w-100 bg-white overflow-hidden">
          <div
            className={`flex-1 overflow-x-hidden ${
              currentEventKey === componentKeys.smtp ||
              currentEventKey === componentKeys.premium
                ? 'overflow-y-hidden'
                : 'overflow-y-auto'
            }`}
          >
            {currentEventKey === componentKeys.general && (
              <>
                <h6 className="px-3 pt-3 mt-2 mb-3 fs-6 font-weight-semi-bold">
                  Tenant&apos;s information
                </h6>
                <Row className="px-3">
                  <Col>
                    <FormGroup>
                      <Label htmlFor="" className="mt-0 col-form-label">
                        <h5 className="mb-0">
                          <Asterick /> Name
                        </h5>
                      </Label>
                      <InputValidation
                        name="name"
                        type="input"
                        placeholder="Enter Name"
                        value={tenantForm?.name}
                        validationConfig={{
                          required: true,
                          inline: false,
                          pattern: /^[A-Za-z\s]+$/,
                          onChange: handleChange,
                          validateSpaces: true, // this will notify user if he keeps adding spaces in start and dont write other text
                        }}
                        errors={errors}
                        register={register}
                        errorDisplay="mb-0"
                      />
                    </FormGroup>
                  </Col>
                  <Col className="pl-0">
                    <FormGroup>
                      <Label htmlFor="" className="col-form-label">
                        <h5 className="mb-0">
                          <Asterick /> Domain
                        </h5>
                      </Label>
                      <InputValidation
                        name="domain"
                        type="input"
                        fieldType="url"
                        placeholder="Enter Domain"
                        value={tenantForm?.domain}
                        onKeyDown={handleKeyDown}
                        ref={domainInputRef}
                        validationConfig={{
                          required: true,
                          inline: false,
                          onChange: handleChange,
                          validateSpaces: true,
                        }}
                        errorMessage={getPatternErrorMessage('URL')}
                        errors={errors}
                        register={register}
                        errorDisplay="mb-0"
                      />
                    </FormGroup>
                  </Col>
                </Row>

                <FormGroup className="px-3">
                  <Label htmlFor="" className="col-form-label">
                    <h5 className="mb-0">Description</h5>
                  </Label>
                  <InputValidation
                    name="description"
                    type="textarea"
                    placeholder="Enter Description"
                    value={tenantForm?.description}
                    validationConfig={{
                      required: false,
                      onChange: handleChange,
                      maxLength: {
                        value: 255,
                        message: 'Description cannot exceed 255 characters.',
                      },
                    }}
                    errors={errors}
                    register={register}
                    classNames="min-h-120"
                  />
                </FormGroup>

                {!selectedEditData ? (
                  <FormGroup className="px-3">
                    <Label htmlFor="" className="col-form-label">
                      <h5 className="mb-0">
                        <Asterick /> Owner Email
                      </h5>
                    </Label>
                    <InputValidation
                      name="email"
                      type="input"
                      placeholder="Enter Owner Email Address"
                      value={tenantForm.owner?.email}
                      errorMessage={getPatternErrorMessage('EMAIL')}
                      errors={errors}
                      register={register}
                      errorDisplay="mb-0"
                      validationConfig={{
                        required: !selectedEditData,
                        inline: false,
                        pattern: emailRegex,
                        onChange: !selectedEditData && handleChange,
                      }}
                      disabled={selectedEditData}
                    />
                  </FormGroup>
                ) : null}
              </>
            )}

            {currentEventKey === componentKeys.branding && (
              <>
                <h6 className="px-3 pt-3 mt-2 mb-3 fs-6 font-weight-semi-bold">{`Tenant's branding, logos, colors etc.`}</h6>
                <div className="border-bottom px-3 border-gray-300 pb-4">
                  <div className="d-flex row align-items-center justify-content-between">
                    <Col md={6}>
                      <h5>Icon</h5>
                    </Col>
                    <Col md={6}>
                      {iconLoading ? (
                        <Spinner />
                      ) : (
                        <ControllerValidation
                          name={'icon'}
                          errors={errors}
                          form={tenantForm}
                          errorDisplay="mb-0"
                          control={control}
                          validationConfig={{
                            required: `Icon is required.`,
                          }}
                          renderer={({ field }) => (
                            <DragDropUploadFile
                              {...field}
                              file={icon}
                              setFile={setIcon}
                              onLoadFile={onLoadIcon}
                              preview
                              isIcon={true}
                              logoId={iconId || tenantForm?.icon}
                              chooseFileText={CHOOSE_IMAGE_FILE}
                              name="icon"
                              containerHeight={60}
                              allowedFormat={['.png', '.jpeg', '.jpg']}
                              displayFormats={['.png', '.jpeg', '.jpg']}
                              errors={errors}
                              uploadOnDrop={true}
                              showUploadIcon={false}
                              validationConfig={{
                                required: true,
                                inline: false,
                              }}
                              fieldState={getFieldState('icon')}
                              register={register}
                              errorDisplay="mb-0"
                            />
                          )}
                        />
                      )}
                    </Col>
                  </div>
                  <div className="row d-flex align-items-center mt-4 justify-content-between">
                    <Col md={6}>
                      <h5 className="d-flex align-items-center">
                        <Asterick /> Logo&nbsp;
                        <IdfTooltip
                          placement="bottom"
                          text="A partner logo can be 280px wide and 80px tall. Please remove all extra white spaces around the logo before uploading."
                        >
                          <a className="cursor-help btn btn-icon btn-icon-sm icon-hover-bg">
                            <MaterialIcon icon="help_outline" rounded />
                          </a>
                        </IdfTooltip>
                      </h5>
                    </Col>
                    <Col md={6}>
                      <ControllerValidation
                        name={'logo'}
                        errors={errors}
                        form={tenantForm}
                        errorDisplay="mb-0"
                        control={control}
                        validationConfig={{
                          required: `Logo is required.`,
                        }}
                        renderer={({ field }) => (
                          <DragDropUploadFile
                            {...field}
                            file={logo}
                            setFile={setLogo}
                            onLoadFile={onLoadLogo}
                            name="logo"
                            classnames={'d-flex align-items-center'}
                            preview
                            logoId={logoId || tenantForm?.logo}
                            chooseFileText={CHOOSE_IMAGE_FILE}
                            containerHeight={85}
                            allowedFormat={['.png', '.jpeg', '.jpg']}
                            displayFormats={['.png', '.jpeg', '.jpg']}
                            uploadOnDrop={true}
                            showUploadIcon={false}
                            onRemoveFile={onRemoveFile}
                            errors={errors}
                            fieldState={getFieldState('logo')}
                            validationConfig={{
                              required: true,
                              inline: false,
                            }}
                            register={register}
                            errorDisplay="mb-0"
                          />
                        )}
                      />
                    </Col>
                  </div>
                </div>
                <div className="row px-3 d-flex align-items-center mt-4 justify-content-between">
                  <Col md={8}>
                    <h5>
                      <Asterick /> Use Logo Instead of Icon
                    </h5>
                  </Col>
                  <Col md={4} className="text-right">
                    <ControllerValidation
                      name={'use_logo'}
                      errors={errors}
                      form={tenantForm}
                      errorDisplay="mb-0"
                      control={control}
                      validationConfig={{
                        required: `Use logo is required.`,
                      }}
                      renderer={({ field }) => (
                        <CheckboxInput
                          {...field}
                          id={'useLogoInsteadOfIcon'}
                          name={'use_logo'}
                          fieldState={getFieldState('use_logo')}
                          validationConfig={{
                            required: true,
                            inline: false,
                          }}
                          value={tenantForm?.use_logo}
                          checked={tenantForm?.use_logo}
                          onChange={handleAccessChange}
                        />
                      )}
                    />
                  </Col>
                </div>
                <div className="row px-3 d-flex align-items-center mt-4 justify-content-between">
                  <Col md={8}>
                    <h5 className="d-flex align-items-center">
                      <Asterick /> Accent Color&nbsp;
                      <IdfTooltip
                        placement="bottom"
                        text="An accent color will be applied to all links, buttons."
                      >
                        <a className="cursor-help btn btn-icon btn-icon-sm icon-hover-bg">
                          <MaterialIcon icon="help_outline" rounded />
                        </a>
                      </IdfTooltip>
                    </h5>
                  </Col>
                  <Col md={4} className="text-right">
                    <ControllerValidation
                      name={'secondaryColor'}
                      errors={errors}
                      form={tenantForm}
                      errorDisplay="mb-0"
                      control={control}
                      validationConfig={{
                        required: `Color is required.`,
                      }}
                      renderer={({ field }) => (
                        <FormColor
                          name="secondaryColor"
                          fieldState={getFieldState('secondaryColor')}
                          validationConfig={{
                            required: true,
                            inline: false,
                          }}
                          value={
                            tenantForm?.colors?.secondaryColor ||
                            selectedEditData.colors?.secondaryColor
                          }
                          onChange={onChangeColor}
                        />
                      )}
                    />
                  </Col>
                </div>
              </>
            )}

            {currentEventKey === componentKeys.modules && (
              <>
                <h6 className="px-3 pt-3 mt-2 mb-3 fs-6 font-weight-semi-bold">{`Select which modules to turn on/off for the tenant.`}</h6>
                <div className="px-3">
                  <ModulesToggles
                    modulesData={selectedEditData}
                    selectedCheckboxes={selectedCheckboxes}
                    setSelectedCheckboxes={setSelectedCheckboxes}
                  />
                </div>
              </>
            )}

            {currentEventKey === componentKeys.premium && (
              <>
                <h6 className="px-3 pt-3 mt-2 mb-3 fs-6 font-weight-semi-bold">
                  Premium Content
                </h6>
                <div className="px-3 h-100">
                  {loadingPremiumArchives ? (
                    <Skeleton width="100%" height={10} />
                  ) : (
                    <div className="d-flex h-100 flex-column">
                      <div
                        className="flex-1 overflow-y-hidden d-flex gap-3"
                        style={{ maxHeight: 'calc(100% - 114px)' }}
                      >
                        <FormGroup>
                          <Label htmlFor="premiumContent">Select Content</Label>
                          <NewKindOfSearchDropdown
                            preData={premiumArchives}
                            dataReadKey="title"
                            dataReadId="archiveId"
                            placeholder="Select Content"
                            showAvatar={false}
                            showEmail={false}
                            readOnly={true}
                            onItemSelect={(item) => {
                              setContentForm((prev) => ({
                                ...prev,
                                selectedContent: item,
                              }));
                            }}
                            currentSelected={contentForm.selectedContent}
                            onInputSearch={null}
                            showAvatarButton={false}
                            optionsClass="min-w-250"
                            pillButton="py-2 px-3 min-w-250"
                          />
                        </FormGroup>
                        {contentForm.selectedContent && (
                          <FormGroup>
                            <Label htmlFor="price">No of Licenses</Label>
                            <CurrencyInput
                              name="quantity"
                              placeholder="0"
                              value={contentForm.quantity || ''}
                              className="form-control"
                              onValueChange={(value) => {
                                setContentForm((prev) => ({
                                  ...prev,
                                  quantity: value,
                                }));
                              }}
                            />
                          </FormGroup>
                        )}
                      </div>
                      <div className="d-flex justify-content-end gap-2">
                        <ButtonIcon
                          label="Close"
                          classnames="btn-sm"
                          color="white"
                          onclick={closeModal}
                        />
                        <ButtonIcon
                          label="Update"
                          classnames="btn-sm"
                          color="primary"
                          disabled={premiumContentLoader}
                          loading={premiumContentLoader}
                          onclick={handleCreatePremiumOrder}
                        />
                      </div>
                    </div>
                  )}
                </div>
              </>
            )}

            {currentEventKey === componentKeys.sso && (
              <>
                <h6 className="px-3 pt-3 mt-2 mb-3 fs-6 font-weight-semi-bold">{`Select which sections to turn on/off for the tenant for single sign on.`}</h6>
                <div
                  className={`px-3 ${
                    tenantForm?.settings?.idp?.enabled ? 'mb-2' : 'mb-0'
                  }`}
                >
                  <h5 className="mb-0">
                    <FormCheck
                      id="toggleEnableIdp"
                      name="toggleEnableIdp"
                      type="switch"
                      checked={tenantForm?.settings?.idp?.enabled}
                      custom={true}
                      label="Enable SSO Login"
                      onChange={handleEnableSSOLogin}
                      className="font-size-sm"
                    />
                  </h5>
                </div>
                {tenantForm?.settings?.idp?.enabled ? (
                  <div className="px-1">
                    {IDP_SETTINGS_TOGGLES.map((toggle) => (
                      <div
                        key={toggle.id}
                        className="d-flex align-items-center py-1"
                        style={{ paddingLeft: 12 }}
                      >
                        <FormCheck
                          id={toggle.key}
                          name={toggle.key}
                          type="checkbox"
                          checked={
                            tenantForm?.settings?.idp?.settings[toggle.key]
                          }
                          custom={true}
                          label={toggle.text}
                          onChange={handleSettingToggleChange}
                          className="font-size-sm2"
                        />
                        <a
                          className="icon-hover-bg d-flex align-items-center cursor-default"
                          style={{ left: -2 }}
                        >
                          <TooltipComponent title={toggle.description}>
                            <a className="cursor-help btn btn-icon btn-icon-sm icon-hover-bg">
                              <MaterialIcon icon="info" rounded />
                            </a>
                          </TooltipComponent>
                        </a>
                      </div>
                    ))}
                  </div>
                ) : null}
              </>
            )}
            {currentEventKey === componentKeys.smtp && (
              <>
                <h6 className="px-3 pt-3 mt-2 mb-0 fs-6 font-weight-semi-bold">{`Configure your email server settings for sending notifications.`}</h6>
                {selectedEditData && (
                  <SMTPConfiguration
                    tenantId={selectedEditData.id}
                    fromModal={true}
                    onModalClose={closeModal}
                  />
                )}
              </>
            )}
          </div>
          <div
            className={
              currentEventKey === componentKeys.smtp ||
              currentEventKey === componentKeys.premium
                ? 'd-none'
                : 'd-block'
            }
          >
            <div className="p-3">
              <div className="d-flex gap-2 justify-content-end">
                <button
                  className="btn btn-white btn-sm"
                  onClick={closeModal}
                  disabled={showLoading}
                >
                  Close
                </button>
                <button
                  type="button"
                  className="btn btn-primary btn-sm min-w-100 d-flex align-items-center justify-content-center"
                  onClick={handleSubmit(handleFormSubmit)}
                  disabled={showLoading || isSMTP}
                >
                  {showLoading ? (
                    <Spinner className="spinner-grow-xs" />
                  ) : (
                    <span>
                      {selectedEditData?.id
                        ? constants.edit.btnEditTenant
                        : constants.create.btnAddTenant}
                    </span>
                  )}
                </button>
              </div>
            </div>
          </div>
        </div>
      </SimpleModal>
    </>
  );
};

export default CreateTenantModal;
