import { useEffect, useRef } from 'react';

const useOnResize = (ref, callback) => {
  const observer = useRef(null);
  const previousHeightRef = useRef(null);

  useEffect(() => {
    if (!ref.current) return;

    observer.current = new ResizeObserver((entries) => {
      for (const entry of entries) {
        const currentHeight = entry.contentRect.height;
        const previousHeight = previousHeightRef.current ?? currentHeight;

        previousHeightRef.current = currentHeight;

        // eslint-disable-next-line n/no-callback-literal
        callback({ currentHeight, previousHeight });
      }
    });

    observer.current.observe(ref.current);

    return () => {
      if (observer.current) {
        observer.current.disconnect();
        observer.current = null;
      }
    };
  }, [ref, callback]);
};

export default useOnResize;
