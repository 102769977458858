import { Input, Spinner } from 'reactstrap';
import React, { useEffect, useState } from 'react';
import MaterialIcon from './MaterialIcon';
import TooltipComponent from '../lesson/Tooltip';
import ValidationErrorText from './ValidationErrorText';
import FunnyLoaderText from './FunnyLoaderText';
import DotDot from './DotDot';
import { ProgressBar } from 'react-bootstrap';
import {
  getCompanyNameAndStatementDate,
  processComericaBankStatement,
} from '../reports/mozilla.pdf.parser';

const pdfJS = require('pdfjs-dist');
pdfJS.GlobalWorkerOptions.workerSrc =
  'https://cdn.jsdelivr.net/npm/pdfjs-dist@3.11.174/build/pdf.worker.min.js';

const DragDropUploadFiles = ({
  files = [],
  setFiles,
  onLoadFile,
  isLoading,
  allowedFormat = '.pdf',
  chooseFileText,
  name = 'idfFiles',
  emptyContainerHeight = '150px',
  showUploadIcon = 'note_stack',
  onRemoveFile = () => {},
  fileUpload = true,
  customBtn,
  scrape, // if this is true, means we will scrape each file one by one and show progress
}) => {
  const [dragActive, setDragActive] = useState(false);
  const [uploadError, setUploadError] = useState('');
  const [previewFiles, setPreviewFiles] = useState([]);

  useEffect(() => {
    const allFilesProcessed = previewFiles.every(
      (file) => parseInt(file.progress) === 100
    );
    if (allFilesProcessed && previewFiles.length) {
      onLoadFile(previewFiles);
    }
  }, [previewFiles]);
  const handleDrag = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (e.type === 'dragenter' || e.type === 'dragover') {
      setDragActive(true);
    } else if (e.type === 'dragleave') {
      setDragActive(false);
    }
  };

  const scrapeOrSetFiles = async (droppedFiles) => {
    setUploadError('');
    if (droppedFiles.length) {
      const filesArray = Array.from(droppedFiles);
      setFiles(filesArray);
      if (scrape) {
        const processFile = async (file) => {
          return new Promise((resolve) => {
            const fr = new FileReader();
            fr.onload = async () => {
              const buffer = fr.result;
              const doc = await pdfJS.getDocument({ data: buffer }).promise;
              const totalPages = doc.numPages;
              let completedPages = 0;
              const scrapedData = [];

              for (let i = 1; i <= totalPages; i++) {
                const page = await doc.getPage(i);
                const content = await page.getTextContent();
                const pageText = content.items;
                scrapedData.push(pageText);
                completedPages++;
                const progress = (completedPages / totalPages) * 100;
                setPreviewFiles((prevFiles) =>
                  prevFiles.map((prevFile) =>
                    prevFile.name === file.name
                      ? {
                          ...prevFile,
                          progress: progress.toFixed(2),
                          scrapedData,
                        }
                      : prevFile
                  )
                );
              }

              // Resolve the promise after processing the file
              const nameAndDate = getCompanyNameAndStatementDate(
                scrapedData,
                {},
                false,
                false
              );
              const mapping = processComericaBankStatement(
                scrapedData,
                nameAndDate
              );
              setPreviewFiles((prevFiles) =>
                prevFiles.map((prevFile) =>
                  prevFile.name === file.name
                    ? {
                        ...prevFile,
                        mapping,
                      }
                    : prevFile
                )
              );
              resolve();
            };

            fr.readAsArrayBuffer(file);
          });
        };

        // Process files sequentially to update progress and scraped data for each file individually
        const processFilesSequentially = async () => {
          for (const file of filesArray) {
            await processFile(file);
          }
        };
        setFiles(droppedFiles);
        // Initialize progress and scraped data for each file
        setPreviewFiles(
          filesArray.map((file) => ({
            name: file.name,
            progress: 0,
            scrapedData: '',
          }))
        );

        // Start processing files sequentially
        processFilesSequentially();
      } else {
        setFiles(droppedFiles);
        setPreviewFiles(
          [...droppedFiles].map((s, index) => {
            return {
              name: s.name,
              id: index + 1,
            };
          })
        );
      }
    }
  };

  const handleDrop = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setDragActive(false);
    scrapeOrSetFiles(e.dataTransfer.files);
  };

  const handleLoadFile = (e) => {
    scrapeOrSetFiles(e.target.files);
  };

  const handleDelete = (file) => {
    // Remove the file from the processing queue
    setPreviewFiles((prevFiles) =>
      prevFiles.filter((prevFile) => prevFile.name !== file.name)
    );
  };
  return (
    <div
      onDragEnter={handleDrag}
      onDragLeave={handleDrag}
      onDragOver={handleDrag}
      onDrop={handleDrop}
      style={{
        height: (!files.length || !previewFiles.length) && emptyContainerHeight,
      }}
      className={`card rounded d-flex mb-0 ${
        !files.length || !previewFiles.length
          ? 'align-items-center justify-content-center bg-gray-200 p-2 rounded d-flex border-dashed-gray'
          : 'bg-white p-0 border-0 shadow-none mb-0'
      } ${dragActive ? 'bg-gray-300' : ''}`}
    >
      {files.length > 0 && (
        <div className="p-2">
          {isLoading && (
            <p className="text-left mb-1">
              <FunnyLoaderText
                autoPlay={6000}
                startingText="File process in progress"
                isFileProcessing={true}
              />
              <DotDot />
            </p>
          )}
          {isLoading && <Spinner className="spinner-grow-sm2" />}
          <div className="d-flex flex-column gap-2 align-items-start">
            {previewFiles?.map((file, index) => (
              <div key={index} className="card w-100">
                <div className="d-flex w-100 card-body px-3 pt-2 pb-0 justify-content-between align-items-center">
                  <div className="d-flex gap-1 align-items-center">
                    <MaterialIcon icon="description" />
                    <div className="text-left flex-grow-1">
                      <p className="pdf-card-title">{file?.name}</p>
                    </div>
                  </div>
                  <TooltipComponent title="Remove">
                    <a
                      href=""
                      className="icon-hover-bg"
                      onClick={(e) => {
                        e.preventDefault();
                        handleDelete(file);
                      }}
                    >
                      <MaterialIcon icon="delete" clazz="text-danger" />
                    </a>
                  </TooltipComponent>
                </div>
                <div className="d-flex align-items-center gap-2 justify-content-between px-3 pb-2">
                  <div
                    className="rpt-bg-dark-gray flex-grow-1"
                    style={{ borderRadius: 'var(--borderRadius)' }}
                  >
                    <ProgressBar
                      style={{ height: 6 }}
                      isChild={true}
                      now={parseInt(file.progress || 0)}
                      max={100}
                      className={'progress-bar-green'}
                    />
                  </div>
                  <div className="text-muted fs-8">
                    {parseInt(file.progress || 0)}%
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      )}

      {(!files.length || !previewFiles.length) && (
        <div className="text-center p-3">
          <div className="font-weight-semi-bold mb-0 form-label">
            {showUploadIcon && (
              <MaterialIcon
                icon={showUploadIcon}
                symbols={true}
                size="font-size-4xl"
              />
            )}
            {fileUpload === true ? (
              <p className="font-weight-normal">
                {' '}
                {chooseFileText} or{' '}
                <Input
                  type="file"
                  accept={allowedFormat}
                  multiple={true}
                  name={name}
                  id={`file-${name}`}
                  onClick={(e) => {
                    e.target.value = '';
                  }}
                  className="d-none"
                  onChange={handleLoadFile}
                />
                <label htmlFor={`file-${name}`} className="mb-0">
                  <a className="btn-link decoration-underline cursor-pointer text-primary">
                    {isLoading ? <Spinner /> : 'Browse'}
                  </a>
                </label>{' '}
                for files upload.{' '}
                <>
                  {allowedFormat?.includes('pdf') ? (
                    <p className="text-muted font-weight-medium font-size-xs py-1">
                      {allowedFormat.includes('doc')
                        ? 'Only .pdf, .doc and .xlsx files are accepted'
                        : 'Only .pdf files accepted'}
                    </p>
                  ) : (
                    <p className="text-muted font-weight-medium font-size-xs py-1">
                      Only .png/.jpeg files accepted
                    </p>
                  )}
                </>
                {customBtn}
              </p>
            ) : (
              <>
                <p>{fileUpload}</p>
                {customBtn}
              </>
            )}
          </div>
        </div>
      )}
      {uploadError && (
        <ValidationErrorText
          text={uploadError}
          extraClass="mb-0 font-weight-medium"
        />
      )}
    </div>
  );
};

export default DragDropUploadFiles;
