import React, { useCallback, useMemo, useState } from 'react';
import SimpleModalCreation from '../../modal/SimpleModalCreation';
import { overflowing } from '../../../utils/Utils';
import { useIdaContext } from '../../../contexts/IdaContext';
import MaterialIcon from '../../commons/MaterialIcon';

const NachaDisclaimerModal = ({
  show,
  setShow,
  selected,
  closeParentModal,
  selectKnowledge,
}) => {
  const { setKnowledgeSource } = useIdaContext();

  const handleCloseModal = () => {
    setShow(false);
    overflowing();
  };

  const handleSubmit = () => {
    closeParentModal();
    setKnowledgeSource(selected);
    selectKnowledge([selected]);
    handleCloseModal();
  };
  return (
    <SimpleModalCreation
      modalHeaderClasses="flex-fill"
      modalTitle="Nacha Disclaimer"
      saveButtonStyle="btn btn-primary btn-sm d-flex align-items-center"
      bodyClassName="mb-0 h-100 overflow-y-auto py-0 px-3"
      open={show}
      handleSubmit={handleSubmit}
      cancelButtonText="Decline"
      saveButton="Accept"
      footerStyle="border-0"
      onHandleCloseModal={handleCloseModal}
    >
      <div className="d-flex bg-soft-yellow rounded p-3 flex-column gap-3">
        <div className="d-flex align-items-start gap-2">
          <MaterialIcon
            icon="info"
            isLucide={false}
            size="fs-21"
            clazz="text-yellow"
            filled
          />
          <p className="text-gray-800 mb-0">
            Permission to use Nacha Operating Rules & Guidelines granted by the
            National Automated Clearing House Association (Nacha). Copyright ©
            Nacha. All Rights Reserved. Nacha has not reviewed and is not
            responsible for the content on or generated by this website or
            service. IDA users should own the most current version of the
            official Nacha Operating Rules & Guidelines. To purchase the Nacha
            Operating Rules & Guidelines, go to{' '}
            <a href="https://nacha.org" target="_blank" rel="noreferrer">
              nacha.org
            </a>{' '}
            or contact your Payments Association.
          </p>
        </div>
      </div>
    </SimpleModalCreation>
  );
};

const useNachaDisclaimerModal = (closeParentModal, selectKnowledge) => {
  const [show, setShow] = useState(false);
  const [selected, setSelected] = useState({});

  const NachaDisclaimerModalCallback = useCallback(() => {
    return (
      <NachaDisclaimerModal
        setShow={setShow}
        show={show}
        selected={selected}
        setSelected={setSelected}
        closeParentModal={closeParentModal}
        selectKnowledge={selectKnowledge}
      />
    );
  }, [show, setShow]);

  return useMemo(
    () => ({
      setShow,
      selected,
      setSelected,
      NachaDisclaimerModal: NachaDisclaimerModalCallback,
    }),
    [setShow, NachaDisclaimerModalCallback]
  );
};

export default useNachaDisclaimerModal;
