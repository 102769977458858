import React from 'react';
import { Collapse } from 'react-bootstrap';
import MaterialIcon from '../../../commons/MaterialIcon';

const Wrapper = ({
  title,
  icon,
  children,
  value = '',
  active = [],
  setActive,
  id,
  count,
}) => {
  const isActive = active?.includes(id);

  const toggleActive = () => {
    if (isActive) {
      setActive(active.filter((activeId) => activeId !== id));
    } else {
      setActive([...active, id]);
    }
  };

  return (
    <div
      className={`w-100 prospect-filter-wrapper accordion-wrapper ${
        isActive
          ? ''
          : `item-filter ${value?.trim() !== '' ? 'text-primary' : ''}`
      }`}
    >
      <div
        className={`d-flex cursor-pointer align-items-center accordion-toggle p-item-filter nav-link font-size-sm2 font-weight-medium px-3 py-2 ${
          isActive
            ? 'bg-accordion-active border-bottom rounded-top rounded-bottom-0 text-black'
            : 'rounded bg-white'
        }`}
        onClick={toggleActive}
      >
        <MaterialIcon
          icon={icon}
          size="fs-20"
          clazz={!isActive && value?.trim() !== '' ? 'fw-bold' : ''}
        />
        <div className="ml-2 d-flex align-items-center gap-1-8">
          <div>
            <span
              className={`text-capitalize py-0 ${
                !isActive && value?.trim() !== '' ? 'fw-bolder' : ''
              }`}
            >
              {title}
            </span>
            <br />
            {!isActive && value?.trim() !== '' && (
              <span className="fst-italic">{value}</span>
            )}
          </div>

          {count && count > 0 ? (
            <div
              className={`d-flex justify-content-center bg-primary align-items-center rounded-circle size-18`}
            >
              <span className={`fs-8 font-weight-normal text-white`}>
                {count}
              </span>
            </div>
          ) : null}
        </div>

        <MaterialIcon
          clazz="ml-auto"
          icon={isActive ? 'keyboard_arrow_up' : 'keyboard_arrow_down'}
        />
      </div>

      <Collapse in={isActive}>
        <div className={`p-2 px-3 accordion-content-wrapper`}>{children}</div>
      </Collapse>
    </div>
  );
};

export default Wrapper;
