import axios from './axiosInstance';
import authHeader from './auth-header';

class GroupService {
  async getRoles() {
    return axios
      .get(`/groups/hierarchy`, {
        params: { self: true },
        headers: authHeader(),
      })
      .then((response) => response.data)
      .catch((error) => error);
  }

  async CreateGroup({ name, parent_id, description, has_sibling_access }) {
    return await axios
      .post(
        `/groups`,
        { name, parent_id, description, has_sibling_access },
        { headers: authHeader() }
      )
      .then((response) => response.data)
      .catch((error) => error);
  }

  async getGroupById(group_id) {
    return axios
      .get(`/groups/${group_id}`, { headers: authHeader() })
      .then((response) => response.data)
      .catch((error) => error);
  }

  async updateGroup(data) {
    return await axios
      .put(`/groups/${data.id}`, data, { headers: authHeader() })
      .then((response) => response.data)
      .catch((error) => error);
  }

  async deleteGroup(role_id, transferId) {
    return axios
      .delete(`/groups/${role_id}`, {
        headers: authHeader(),
        params: {
          transferId,
        },
      })
      .then((response) => response.data)
      .catch((error) => error);
  }

  async removeUserFromGroup(data) {
    return axios
      .delete(`/groups/user`, {
        data,
        headers: authHeader(),
      })
      .then((response) => response.data)
      .catch((error) => error);
  }
}

export default new GroupService();
