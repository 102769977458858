import AddNewReportButton from './AddNewReportButton';
import Skeleton from 'react-loading-skeleton';
import { jsPDF } from 'jspdf';
import TooltipComponent from '../lesson/Tooltip';
import ReportOwner from './ReportOwner';
import MaterialIcon from '../commons/MaterialIcon';
import ButtonFilterDropdown from '../commons/ButtonFilterDropdown';
import ButtonIcon from '../commons/ButtonIcon';
import DownloadReportDropdown from './DownloadReportDropdown';
import React, { useEffect, useState } from 'react';
import {
  generatePDF,
  generatePNG,
  generateSlides,
  getCanvasElements,
  getPageDimensions,
  ReportFileType,
  ReportFileTypes,
  ReportTypes,
} from './reports.constants';
import moment from 'moment';
import { Dropdown, DropdownButton } from 'react-bootstrap';
import MoreActions from '../MoreActions';
import { Input } from 'reactstrap';
import {
  getReportPDFName,
  getReportPDFNameMerchant,
} from './reports.helper.functions';
import useDeleteFraudReportModal from '../../hooks/reports/modals/useDeleteFraudReportModal';
import useResyncMerchantReportModal from '../../hooks/reports/modals/useResyncMerchantReportModal';
import OrganizationService from '../../services/organization.service';
import PptxGenJS from 'pptxgenjs';
import JSZip from 'jszip';
import useResyncMerchantReportSVBModal from '../../hooks/reports/modals/useResyncMerchantReportSVBModal';
import useIsTenant from '../../hooks/useIsTenant';

const ReportAction = ({
  readOnly,
  pastReports,
  handleManualReport,
  rptGenerated,
  handleEditReport,
  loadingReport,
  loadingPastReports,
  selectedRpt,
  setSelectedRpt,
  selectedIndividualRpt = {},
  setSelectedIndividualRpt = () => {},
  handleClearIndividualReport = () => {},
  handleEditIndividualReport = () => {},
  profileInfo,
  report,
  startDownload,
  setStartDownload,
  downloadOptions,
  csvData,
  exportToCSV,
  reportType,
  linkConfig,
  draftMode,
  handleDraftClick,
  reSync,
  handleResyncClick,
  organization,
  subOrganizationConfig = {},
  setSuccessMessage = () => {},
  reportPdfTitle = {},
  isAggregate,
}) => {
  const { isSVB, isComericaBank } = useIsTenant();
  const {
    DeleteFraudReportModal,
    setReport,
    setShowModal: setShowDeleteModal,
  } = useDeleteFraudReportModal(
    () => {
      // just refresh no fetch core data and create
      subOrganizationConfig && subOrganizationConfig.refetchReports();
    },
    () => {}
  );
  const {
    SyncMerchantReportModal,
    setShowModal: setShowMerchantReportModal,
    setOrganization: setMerchantOrg,
    setReport: setResyncMerchantReport,
  } = useResyncMerchantReportModal(
    () => {
      setSuccessMessage('Report refreshed successfully.');
      // resync means again call core data to fetch and create
      subOrganizationConfig && subOrganizationConfig.refreshReports();
    },
    () => {}
  );
  const {
    SyncMerchantReportSVBModal,
    setShowModal: setShowMerchantReportSVBModal,
    setOrganization: setMerchantSVBOrg,
    setReport: setResyncMerchantSVBReport,
  } = useResyncMerchantReportSVBModal(
    () => {
      setSuccessMessage('Report refreshed successfully.');
      // resync means again call core data to fetch and create
      subOrganizationConfig && subOrganizationConfig.refreshReports();
    },
    () => {}
  );
  const isCoreData =
    Object.keys(report?.coreData || {}).length || report?.manualInput?.isAuto;

  const isMerchantReport =
    reportType === ReportTypes.MerchantV2 ||
    reportType === ReportTypes.Merchant ||
    reportType === ReportTypes.SvbBank;
  // new ui + core data integration
  const isMerchantReportCoreData =
    (reportType === ReportTypes.MerchantV2 && isCoreData) ||
    (reportType === ReportTypes.SvbBank && isCoreData);

  const [isOrgPrimaryOwner, setIsOrgPrimaryOwner] = useState(false);
  const [moreDownloadOption, setMoreDownloadOption] = useState(true);
  const [reportFileType, setReportFileType] = useState(ReportFileTypes[0]);

  const isSubOrgFlow = Object.keys(subOrganizationConfig).length > 0;

  const getOrganizationOwners = async () => {
    const { data: owners } = await OrganizationService.getOwners(
      organization.id,
      {
        page: 1,
        limit: 10,
      }
    );
    if (owners.length) {
      const ownerIds = [...owners.map((own) => own.user_id), profileInfo?.id];
      setIsOrgPrimaryOwner(
        ownerIds?.includes(profileInfo?.id) ||
          ownerIds.includes(organization?.assigned_user_id)
      );
    } else {
      setIsOrgPrimaryOwner(true);
    }
  };
  const DropdownSelectTitle = () => {
    return (
      <div className="d-flex align-items-center gap-1">
        <MaterialIcon rounded icon="corporate_fare" />
        <span>
          {subOrganizationConfig?.selected?.name ||
            subOrganizationConfig?.selectedParent?.name ||
            'Select Organization'}
        </span>
      </div>
    );
  };

  const onInputSearch = (e) => {
    const { value } = e.target || {};
    subOrganizationConfig.setSearch(value);
  };

  useEffect(() => {
    organization?.id && getOrganizationOwners();
  }, [organization, profileInfo]);

  const resetMoreDropdown = () => {
    setMoreDownloadOption(false);
  };

  const handleMoreDropdown = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setMoreDownloadOption((prev) => !prev);
  };

  const handleDownload = async () => {
    setStartDownload(true);

    setTimeout(async () => {
      const $canvas = document.getElementById('rptPdf');
      const dimensions = getPageDimensions($canvas);

      window.scrollTo(0, 0);
      const element = '#rptPdf > div.px-0';
      const canvases = await Promise.all(getCanvasElements(element));

      const fileName =
        reportType === ReportTypes.MerchantV2
          ? `${getReportPDFNameMerchant(
              reportType,
              reportPdfTitle,
              isAggregate
            )}`
          : `${getReportPDFName(
              reportType,
              selectedRpt?.ReportInfo || report
            )}`;

      try {
        if (reportFileType.key === ReportFileType.PPT) {
          const pptx = new PptxGenJS();
          generateSlides(pptx, canvases, dimensions, linkConfig);
          pptx.writeFile({ fileName: `${fileName}.pptx` });
        } else if (reportFileType.key === ReportFileType.PNG) {
          const zip = new JSZip();
          await generatePNG(zip, canvases, fileName);
        } else {
          // eslint-disable-next-line new-cap
          const pdf = new jsPDF('p', 'pt', [
            dimensions.HTML_Width,
            dimensions.pageHeight,
          ]);
          generatePDF(pdf, canvases, dimensions, linkConfig);
          pdf.save(`${fileName}.pdf`);
        }
      } catch (error) {
        console.error('Error during report download:', error);
      } finally {
        setStartDownload(false);
        resetMoreDropdown();
      }
    }, 100);
  };

  const FileTypeSelection = () => {
    return (
      <>
        {moreDownloadOption ? (
          <div className="px-2 pt-2 pb-1 w-100 d-flex align-items-center">
            {ReportFileTypes?.map((item, index) => (
              <div
                key={item.key}
                className={`w-50 p-01 fs-8 d-flex justify-content-center border-top border-bottom ${
                  reportFileType.key === item.key && 'bg-primary-soft'
                } ${index === 0 && 'rounded-left-pill border-left'} ${
                  index === ReportFileTypes?.length - 1 &&
                  'rounded-right-pill border-right'
                }`}
                onClick={() => setReportFileType(item)}
              >
                {item.name}
              </div>
            ))}
          </div>
        ) : null}
      </>
    );
  };

  return (
    <>
      <DeleteFraudReportModal />
      <SyncMerchantReportModal />
      <SyncMerchantReportSVBModal />
      {!readOnly && (
        <div
          className={`d-flex position-absolute align-items-center gap-1 report-controls ${
            readOnly ? 'end-0' : ''
          }`}
          style={{ top: readOnly ? -60 : -35 }}
        >
          {reportType !== ReportTypes.MerchantV2 &&
            reportType !== ReportTypes.SvbBank && (
              <AddNewReportButton
                readOnly={readOnly}
                onClick={handleManualReport}
                addView={!rptGenerated && !loadingReport && !readOnly}
              />
            )}
        </div>
      )}
      <div
        className={`d-flex align-items-center gap-1 w-100 report-controls ${
          pastReports.length > 0 && !readOnly ? 'pt-3' : ''
        } ${readOnly ? 'pb-0' : ''}`}
        style={{ top: readOnly ? -60 : -42 }}
      >
        {loadingPastReports ? (
          <div className="px-3 pt-3 d-flex flex-column w-100 gap-2">
            <div className="d-flex align-items-center justify-content-between w-100">
              <div>
                <Skeleton height={12} width={120} />
              </div>
              <div className="d-flex align-items-center ml-auto gap-1 mx-2">
                <Skeleton height={12} width={50} />
                <Skeleton height={12} width={80} />
                <Skeleton height={12} width={50} />
              </div>
            </div>
          </div>
        ) : (
          <div className="d-flex align-items-end pl-3 pr-2 pt-0 justify-content-between w-100">
            <div>
              <h1
                className={`mb-0 d-flex align-items-center gap-1 ${
                  readOnly ? 'font-size-lg' : 'font-size-em'
                }`}
              >
                <span>
                  {selectedIndividualRpt?.prettyDate || selectedRpt?.prettyDate}
                </span>
                {(selectedRpt?.prettyDate ||
                  selectedIndividualRpt?.prettyDate) &&
                  !readOnly && (
                    <TooltipComponent
                      placement="right"
                      capitalizeText={false}
                      title={
                        <ReportOwner
                          report={
                            Object.keys(selectedIndividualRpt).length
                              ? { ...selectedIndividualRpt, isAuto: true }
                              : selectedRpt
                          }
                          me={profileInfo}
                        />
                      }
                    >
                      <span className="d-flex">
                        <MaterialIcon icon="info" />
                      </span>
                    </TooltipComponent>
                  )}
              </h1>
            </div>
            <div className="d-flex align-items-center ml-auto gap-1 mx-2">
              {draftMode && !readOnly && (
                <ButtonIcon
                  icon="edit_square"
                  color="outline-primary"
                  label="1 Draft Report"
                  onclick={handleDraftClick}
                  classnames="btn-sm bg-white"
                />
              )}
              {rptGenerated &&
                !readOnly &&
                reSync &&
                isMerchantReportCoreData && (
                  <ButtonIcon
                    icon="refresh"
                    color="outline-primary"
                    label="Refresh"
                    onclick={() => handleResyncClick(report)}
                    classnames="btn-sm"
                  />
                )}
              {rptGenerated &&
                !readOnly &&
                !isMerchantReportCoreData &&
                isOrgPrimaryOwner && (
                  <ButtonIcon
                    icon="edit"
                    color="white"
                    label="Edit"
                    onclick={() => handleEditReport('0')}
                    classnames="btn-sm"
                  />
                )}
              {pastReports.length > 0 && !isMerchantReportCoreData && (
                <ButtonFilterDropdown
                  buttonText="Select Period"
                  icon="calendar_month"
                  menuClass="rpt-history-dd-width max-h-300"
                  btnToggleStyle="btn-h-sm"
                  options={pastReports}
                  customKeys={['key', 'prettyDate']}
                  filterOptionSelected={selectedRpt}
                  handleFilterSelect={(e, item) => {
                    setSelectedRpt(item);
                  }}
                />
              )}

              {readOnly ? (
                <>
                  {(rptGenerated || pastReports.length > 0) && (
                    <>
                      {rptGenerated && (
                        <DownloadReportDropdown
                          report={report}
                          selectedRpt={selectedRpt}
                          startDownload={startDownload}
                          setStartDownload={setStartDownload}
                          downloadOptions={downloadOptions}
                          csvData={csvData}
                          reportType={reportType}
                          exportToCSV={exportToCSV}
                          linkConfig={linkConfig}
                        />
                      )}
                    </>
                  )}
                </>
              ) : (
                <>
                  {isMerchantReport || isMerchantReportCoreData ? (
                    <>
                      {isMerchantReportCoreData && (
                        <div className="d-flex align-items-center gap-1">
                          {selectedRpt?.coreDataList?.length && (
                            <ButtonFilterDropdown
                              buttonText="Select Statement"
                              icon="list"
                              menuClass="rpt-history-dd-width max-h-300"
                              btnToggleStyle="btn-h-sm"
                              options={selectedRpt?.coreDataList?.sort(
                                (a, b) => moment(a.Month) - moment(b.Month)
                              )}
                              customKeys={['key', 'prettyDate']}
                              filterOptionSelected={selectedIndividualRpt}
                              handleFilterSelect={(e, item) => {
                                setSelectedIndividualRpt(item);
                              }}
                            />
                          )}
                          {pastReports.length > 0 && (
                            <ButtonFilterDropdown
                              buttonText="Select Period"
                              icon="date_range"
                              menuClass="rpt-history-dd-width max-h-300"
                              btnToggleStyle="btn-h-sm"
                              options={pastReports}
                              customKeys={['key', 'prettyDate']}
                              filterOptionSelected={selectedRpt}
                              handleFilterSelect={(e, item) => {
                                setSelectedRpt(item);
                              }}
                            />
                          )}
                          {isSubOrgFlow ? (
                            <>
                              <div
                                style={{ top: -36 }}
                                className="position-absolute right-0 mr-3"
                              >
                                {subOrganizationConfig.list.length > 0 ? (
                                  <DropdownButton
                                    id="dropdown-sub-org"
                                    variant="white"
                                    className="w-100"
                                    size="sm"
                                    title={<DropdownSelectTitle />}
                                  >
                                    <Dropdown.Item
                                      as="button"
                                      style={{ minWidth: '200px' }}
                                      className="font-weight-medium d-flex justify-content-between align-items-center pl-3"
                                      onClick={() =>
                                        subOrganizationConfig.onChangeParent(
                                          organization
                                        )
                                      }
                                    >
                                      <span>{organization?.name}</span>
                                      {subOrganizationConfig?.selectedParent
                                        ?.id === organization?.id &&
                                        !subOrganizationConfig.selected && (
                                          <MaterialIcon
                                            icon="check"
                                            clazz="fw-bold text-primary"
                                          />
                                        )}
                                    </Dropdown.Item>
                                    <Dropdown.ItemText className="p-3 py-2">
                                      <div className="border-top position-relative">
                                        <Input
                                          placeholder="Search"
                                          onClick={(e) => e.stopPropagation()}
                                          value={subOrganizationConfig.search}
                                          onChange={onInputSearch}
                                        />
                                        <MaterialIcon
                                          icon="search"
                                          clazz="position-absolute"
                                          style={{ right: 12, top: 12 }}
                                        />
                                      </div>
                                    </Dropdown.ItemText>
                                    {subOrganizationConfig.loading ? (
                                      <div className="p-3">
                                        <Skeleton height={10} />
                                        <Skeleton height={10} />
                                        <Skeleton height={10} />
                                        <Skeleton height={10} />
                                        <Skeleton height={10} />
                                        <Skeleton height={10} />
                                      </div>
                                    ) : (
                                      <>
                                        {subOrganizationConfig.list?.map(
                                          (sub) => (
                                            <Dropdown.Item
                                              onClick={() =>
                                                subOrganizationConfig.onChange(
                                                  sub
                                                )
                                              }
                                              className="pl-4 d-flex justify-content-between align-items-center"
                                              key={sub.subOrganizationId}
                                              as="button"
                                            >
                                              <span>{sub.name}</span>
                                              {subOrganizationConfig?.selected
                                                ?.subOrganizationId ===
                                                sub.subOrganizationId && (
                                                <MaterialIcon
                                                  icon="check"
                                                  clazz="fw-bold text-primary"
                                                />
                                              )}
                                            </Dropdown.Item>
                                          )
                                        )}
                                      </>
                                    )}
                                  </DropdownButton>
                                ) : null}
                              </div>
                            </>
                          ) : null}
                          {pastReports?.length > 0 && (
                            <a className={`btn btn-white p-1`}>
                              <MoreActions
                                loader={startDownload}
                                iconStyle="fs-4"
                                toggleClassName="w-moto p-0"
                                onToggle={resetMoreDropdown}
                                items={[
                                  ...(selectedIndividualRpt?.key &&
                                  isOrgPrimaryOwner
                                    ? [
                                        {
                                          id: 'edit',
                                          name: 'Edit',
                                          className: 'bg-hover-primary',
                                          icon: 'edit',
                                        },
                                      ]
                                    : []),
                                  ...(selectedIndividualRpt?.key && isCoreData
                                    ? [
                                        {
                                          id: 'add',
                                          name: 'Aggregate View',
                                          className: 'bg-hover-primary',
                                          icon: 'view_list',
                                        },
                                      ]
                                    : []),
                                  ...(!selectedIndividualRpt?.key && isCoreData
                                    ? [
                                        {
                                          id: 'open',
                                          name: 'Refresh Data',
                                          className: 'bg-hover-primary',
                                          icon: 'refresh',
                                        },
                                      ]
                                    : []),
                                  {
                                    id: 'download',
                                    name: 'Download',
                                    className: 'bg-hover-primary',
                                    icon: 'download',
                                    moreDropdown: (
                                      <span
                                        onClick={handleMoreDropdown}
                                        className="ml-auto d-flex align-items-center"
                                      >
                                        <MaterialIcon icon="arrow_drop_down" />
                                      </span>
                                    ),
                                    dropdownContent: <FileTypeSelection />,
                                  },
                                  ...(!selectedIndividualRpt?.key
                                    ? [
                                        {
                                          type: 'divider',
                                        },
                                        {
                                          id: 'remove',
                                          name: 'Delete Report',
                                          icon: 'delete',
                                        },
                                      ]
                                    : []),
                                ]}
                                onHandleEdit={() => {
                                  handleEditIndividualReport(
                                    selectedIndividualRpt
                                  );
                                }}
                                onHandleAdd={handleClearIndividualReport}
                                onHandleRemove={() => {
                                  setReport({
                                    ...report,
                                    date:
                                      report?.aggregate?.monthRange?.end ||
                                      report.reportDate,
                                  });
                                  setShowDeleteModal(true);
                                }}
                                onHandleOpen={() => {
                                  if (isSVB) {
                                    setResyncMerchantSVBReport({
                                      ...report,
                                      date:
                                        report?.aggregate?.monthRange?.end ||
                                        report.reportDate,
                                    });
                                    setMerchantSVBOrg(organization);
                                    setShowMerchantReportSVBModal(true);
                                  } else if (isComericaBank) {
                                    setResyncMerchantReport({
                                      ...report,
                                      date:
                                        report?.aggregate?.monthRange?.end ||
                                        report.reportDate,
                                    });
                                    setMerchantOrg(organization);
                                    setShowMerchantReportModal(true);
                                  }
                                }}
                                onHandleDownload={handleDownload}
                              />
                            </a>
                          )}
                        </div>
                      )}
                      {rptGenerated && readOnly && (
                        <DownloadReportDropdown
                          report={report}
                          startDownload={startDownload}
                          setStartDownload={setStartDownload}
                          downloadOptions={downloadOptions}
                          csvData={csvData}
                          reportType={reportType}
                          exportToCSV={exportToCSV}
                          linkConfig={linkConfig}
                        />
                      )}
                    </>
                  ) : null}
                </>
              )}
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default ReportAction;
