import { WidgetTypes } from '../constants/widgetsConstants';
import QRCodeWidget from './horizontal/QRCodeWidget';
import IconWidget from './horizontal/IconWidget';
import IconSetWidget from './horizontal/IconSetWidget';
import ElectronicPaymentsForGrowthWidget from './horizontal/ElectronicPaymentsForGrowthWidget';
import ImproveVendorRelationshipWidget from './horizontal/ImproveVendorRelationshipWidget';
import BusinessEmailCompromiseWidget from './horizontal/BusinessEmailCompromiseWidget';
import HoursSpentWeeklyWidget from './horizontal/HoursSpentWeeklyWidget';
import ElectronicPaymentsSavingsWidget from './horizontal/ElectronicPaymentsSavingsWidget';
import PaperToElectronicOpportunityWidget from './horizontal/PaperToElectronicOpportunityWidget';
import React from 'react';
import EstimatedPayableReceiveableWidget from './horizontal/EstimatedPayableReceiveableWidget';
import DPOWidget from './horizontal/DPOWidget';
import DSOWidget from './horizontal/DSOWidget';
import BalancesAtRiskWidget from './horizontal/BalancesAtRiskWidget';
import BalancesAndEarningsCreditWidget from './horizontal/BalancesAndEarningsCreditWidget';
import GlossaryWidget from './horizontal/GlossaryWidget';
import DisclaimerWidget from './horizontal/DisclaimerWidget';
import BlankWidget from './horizontal/BlankWidget';
import AutomatingAccountsReceivables from './horizontal/AutomatingAccountsReceivables';
import ButtonIcon from '../../commons/ButtonIcon';
import CheckPaymentsMoreRiskWidget from './horizontal/CheckPaymentsMoreRiskWidget';
import InternationalBankingCrossBorderPaymentsWidget from './horizontal/InternationalBankingCrossBorderPaymentsWidget';
import InternationalBankingPayingForeignSuppliersWidget from './horizontal/InternationalBankingPayingForeignSuppliersWidget';
import ImproveYourCashFlowWidget from './horizontal/ImproveYourCashFlowWidget';
import TheOneDayDifferenceWidget from './horizontal/TheOneDayDifferenceWidget';
import AccountsPayableReceivableWidget from './horizontal/AccountsPayableReceivableWidget';
import DPODSOpportunityToImproveWidget from './horizontal/DPODSOpportunityToImproveWidget';
import FactorsThatImpactDSODPOWidget from './horizontal/FactorsThatImpactDSODPOWidget';
import UtilizationAndInterestExpenseWidget from './horizontal/UtilizationAndInterestExpenseWidget';
import LineOfCreditWidget from './horizontal/LineOfCreditWidget';
import RightTreasuryManagementWidget from './horizontal/RightTreasuryManagementWidget';
import InstantPaymentsWidget from './horizontal/InstantPaymentsWidget';
import ImproveVendorRelationshipElectronicPaymentsWidget from './horizontal/ImproveVendorRelationshipElectronicPaymentsWidget';
import PaymentComparisonWidget from './horizontal/PaymentComparisonWidget';
import HoursSpentWeeklyReconcilingPaymentsWidget from './horizontal/HoursSpentWeeklyReconcilingPaymentsWidget';
import PaymentFraudContinuesWidget from './horizontal/PaymentFraudContinuesWidget';
import FraudSolutionsEmpowerWidget from './horizontal/FraudSolutionsEmpowerWidget';
import FraudBalancesAtRiskWidget from './horizontal/FraudBalancesAtRiskWidget';
import usePermission from '../../../hooks/usePermission';
import { PermissionsConstants } from '../../../utils/permissions.constants';
import PaymentFraudImpactsWidget from './horizontal/PaymentFraudImpactsWidget';
import VendorsCountWidget from './horizontal/VendorsCountWidget';
import PaymentStatsWidget from './horizontal/PaymentStatsWidget';
import YourBenefitsWidget from './horizontal/YourBenefitsWidget';
import VenderAnalysisPhasesWidget from './horizontal/VenderAnalysisPhasesWidget';
import AcceptanceConditionsWidget from './horizontal/AcceptanceConditionsWidget';
import ImproveVendorRelationshipsHorizontalWidget from './horizontal/ImproveVendorRelationshipsWidget';
import FuturePlansWidget from './horizontal/FuturePlansWidget';
import PaymentTrendsWidget from './horizontal/PaymentTrendsWidget';
import AmountProcessedWidget from './horizontal/AmountProcessedWidget';
import OverviewWidget from './horizontal/OverviewWidget';
import FeeBreakdownChartWidget from './horizontal/FeeBreakdownChartWidget';
import TeamContactsWidget from './horizontal/TeamContactsWidget';
import AllRightsReservedWidget from './horizontal/AllRightsReservedWidget';
import ControllableCostSavingsWidget from './horizontal/ControllableCostSavingsWidget';
import MerchantFeesWidget from './horizontal/MerchantFeesWidget';
import InterchangeSavingsWidget from './horizontal/InterchangeSavingsWidget';
import FeeChargebacksWidget from './horizontal/FeeChargebacksWidget';
import SvbGlossaryWidget from './horizontal/SvbGlossaryWidget';
import FreeFormTextWidget from './horizontal/FreeFormTextWidget';

const WidgetsMapping = {
  [WidgetTypes.IP]: InstantPaymentsWidget,
  [WidgetTypes.PF]: PaymentFraudContinuesWidget,
  [WidgetTypes.PC]: PaymentComparisonWidget,
  [WidgetTypes.ICF]: ImproveYourCashFlowWidget,
  [WidgetTypes.EPG]: ElectronicPaymentsForGrowthWidget,
  [WidgetTypes.IVR]: ImproveVendorRelationshipWidget,
  [WidgetTypes.APO]: AccountsPayableReceivableWidget,
  [WidgetTypes.BEC]: BusinessEmailCompromiseWidget,
  [WidgetTypes.HSW]: HoursSpentWeeklyWidget,
  [WidgetTypes.EPS]: ElectronicPaymentsSavingsWidget,
  [WidgetTypes.PEO]: PaperToElectronicOpportunityWidget,
  [WidgetTypes.DPO]: DPOWidget,
  [WidgetTypes.DSO]: DSOWidget,
  [WidgetTypes.BAR]: BalancesAtRiskWidget,
  [WidgetTypes.AAR]: AutomatingAccountsReceivables,
  [WidgetTypes.PFS]: InternationalBankingPayingForeignSuppliersWidget,
  [WidgetTypes.OTP]: DPODSOpportunityToImproveWidget,
  [WidgetTypes.FTI]: FactorsThatImpactDSODPOWidget,
  [WidgetTypes.UIE]: UtilizationAndInterestExpenseWidget,
  [WidgetTypes.LOC]: LineOfCreditWidget,
  [WidgetTypes.RTM]: RightTreasuryManagementWidget,
  [WidgetTypes.FSE]: FraudSolutionsEmpowerWidget,
  [WidgetTypes.PFIB]: PaymentFraudImpactsWidget,
  [WidgetTypes.HSWR]: HoursSpentWeeklyReconcilingPaymentsWidget,
  [WidgetTypes.BAEC]: BalancesAndEarningsCreditWidget,
  [WidgetTypes.FBAR]: FraudBalancesAtRiskWidget,
  [WidgetTypes.Icon]: IconWidget,
  [WidgetTypes.CBPR]: InternationalBankingCrossBorderPaymentsWidget,
  [WidgetTypes.CPMR]: CheckPaymentsMoreRiskWidget,
  [WidgetTypes.TODD]: TheOneDayDifferenceWidget,
  [WidgetTypes.GLOSS]: GlossaryWidget,
  [WidgetTypes.DISC]: DisclaimerWidget,
  [WidgetTypes.BLANK]: BlankWidget,
  [WidgetTypes.ESTPR]: EstimatedPayableReceiveableWidget,
  [WidgetTypes.IVREP]: ImproveVendorRelationshipElectronicPaymentsWidget,
  [WidgetTypes.QRCode]: QRCodeWidget,
  [WidgetTypes.IconSet]: IconSetWidget,
  [WidgetTypes.VCW]: VendorsCountWidget,
  [WidgetTypes.PSW]: PaymentStatsWidget,
  [WidgetTypes.YBW]: YourBenefitsWidget,
  [WidgetTypes.VAP]: VenderAnalysisPhasesWidget,
  [WidgetTypes.AC]: AcceptanceConditionsWidget,
  [WidgetTypes.IVREPH]: ImproveVendorRelationshipsHorizontalWidget,
  [WidgetTypes.FP]: FuturePlansWidget,
  [WidgetTypes.PT]: PaymentTrendsWidget,
  [WidgetTypes.APW]: AmountProcessedWidget,
  [WidgetTypes.OVW]: OverviewWidget,
  [WidgetTypes.FBCW]: FeeBreakdownChartWidget,
  [WidgetTypes.TCW]: TeamContactsWidget,
  [WidgetTypes.ARRW]: AllRightsReservedWidget,
  [WidgetTypes.CCSW]: ControllableCostSavingsWidget,
  [WidgetTypes.MFW]: MerchantFeesWidget,
  [WidgetTypes.ISW]: InterchangeSavingsWidget,
  [WidgetTypes.FCW]: FeeChargebacksWidget,
  [WidgetTypes.SVBGW]: SvbGlossaryWidget,
  [WidgetTypes.FREE]: FreeFormTextWidget,
};

const WaterMark = () => {
  return (
    <span
      style={{ left: 4, top: -12, letterSpacing: 0.5 }}
      className="position-absolute py-1 px-2 z-index-99 rounded text-black font-weight-semi-bold bg-yellow fs-10 text-uppercase"
    >
      WIDGET
    </span>
  );
};
const WidgetWrapper = ({
  widget,
  whenPrinting,
  actionButtons = {},
  selectedTenant,
  fromWidgetsLibrary,
}) => {
  const Widget = WidgetsMapping[widget.type];
  const { isAllowed: isWidgetLibraryAllowed } = usePermission(
    PermissionsConstants.Reports.WidgetsLibrary,
    'view',
    true
  );
  // only border yellow those that are static ones and put a label
  // hell lot of things to avoid :P
  const isStaticWidget =
    widget.action !== null &&
    !fromWidgetsLibrary &&
    widget.type !== WidgetTypes.BLANK &&
    !whenPrinting;

  return Widget ? (
    <div
      className={`position-relative hover-actions ${
        isStaticWidget ? 'border-yellow static-widget border-2' : ''
      }`}
      style={{ borderRadius: 'var(--rpt-widget-border-radius)' }}
    >
      {isStaticWidget && <WaterMark />}
      <Widget
        widget={widget}
        widgetConfig={widget.widgetConfig}
        whenPrinting={whenPrinting}
        selectedTenant={selectedTenant}
      />
      {widget?.action &&
        isWidgetLibraryAllowed &&
        widget.type !== WidgetTypes.BLANK && (
          <div className="position-absolute shadow-xl right-0 p-2 rounded bg-white action-items top-0 m-2 d-flex align-items-center gap-1">
            {widget?.action?.onDelete && actionButtons?.remove && (
              <ButtonIcon
                useLucidIcon
                iconSize="18"
                icon={actionButtons.remove.icon}
                label={actionButtons.remove.text}
                color="outline-danger"
                classnames="btn-xs"
                onclick={() => widget.action.onDelete(this, widget)}
              />
            )}
            <ButtonIcon
              useLucidIcon
              iconSize="18"
              icon={actionButtons.add.icon}
              label={actionButtons.add.text}
              classnames="btn-xs"
              onclick={widget.action.onAdd}
            />
          </div>
        )}
    </div>
  ) : null;
};

export default WidgetWrapper;
