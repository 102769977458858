import React, { useState } from 'react';
import Overview from './Overview';
import AnimatedTabs from '../../commons/AnimatedTabs';
import AccountHierarchy from './AccountHierarchy';

const tabs = [
  {
    tabId: 1,
    title: 'Overview',
  },
  {
    tabId: 2,
    title: 'Account Hierarchy',
  },
];

const CoreData = () => {
  const [activeTab, setActiveTab] = useState(1);

  const renderContent = () => {
    if (activeTab === 1) return <Overview />;
    else if (activeTab === 2) return <AccountHierarchy />;
  };

  return (
    <div className="mt-2">
      <AnimatedTabs
        showCount={true}
        tabClasses="px-0 gap-2 justify-content-center pt-0"
        tabItemClasses="border-0 font-size-sm2 px-2 rounded text-black bg-hover-gray mb-0 py-1"
        tabActiveClass="bg-primary-soft-darker text-primary fw-bold"
        tabsData={tabs}
        activeTab={activeTab}
        maxCount={99}
        toggle={(tab) => setActiveTab(tab.tabId)}
      />

      <div>{renderContent()}</div>
    </div>
  );
};

export default CoreData;
