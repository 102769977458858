import axios from './axiosInstance';
import authHeader from './auth-header';

class CourseService {
  getCourses(query = {}) {
    const { page = 1, limit = 10, ...rest } = query;

    return axios
      .get(`/courses`, {
        headers: authHeader(),
        params: {
          page,
          limit,
          ...rest,
        },
      })
      .then((response) => response.data)
      .catch((e) => console.log(e));
  }

  deleteCourses(courseId) {
    return axios
      .delete(`/courses/${courseId}`, {
        headers: authHeader(),
      })
      .then((response) => response.data);
  }

  updateCourse(id, data) {
    return axios
      .put(`/courses/${id}`, data, {
        headers: authHeader(),
      })
      .then((response) => response.data)
      .catch((e) => console.log(e));
  }

  saveCourse(course) {
    return axios
      .post(`/courses`, course, {
        headers: authHeader(),
      })
      .then((response) => response.data)
      .catch((e) => console.log(e));
  }

  getCourseLessonsById(id) {
    return axios
      .get(`/courses/${id}/lessons`, { headers: authHeader() })
      .then((response) => response.data)
      .catch((e) => console.log(e));
  }

  getCourseById(id, params = {}) {
    return axios
      .get(`/courses/${id}`, { headers: authHeader(), params })
      .then((response) => response.data)
      .catch((e) => console.log(e));
  }

  getCourseProgress(id, params = {}) {
    return axios
      .get(`/courses/${id}/progress`, {
        headers: authHeader(),
        params,
      })
      .then((response) => response.data);
  }

  updateCourseLessons(id, courseLesson) {
    return axios
      .put(
        `/courses/${id}/lessons`,
        {
          ...courseLesson,
        },
        {
          headers: authHeader(),
        }
      )
      .then((response) => response.data)
      .catch((e) => console.log(e));
  }

  putFavoriteCourseById(id) {
    return axios
      .put(
        `/courses/${id}/favorite`,
        { courseId: id },
        {
          headers: authHeader(),
        }
      )
      .then((response) => response.data)
      .catch((e) => console.log(e));
  }
}

export default new CourseService();
