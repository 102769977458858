import React from 'react';
import { PROSPECT_RIGHT_PANEL_WIDTH } from '../../../utils/Utils';
import RightPanelModal from '../../../components/modal/RightPanelModal';
import { Card, CardBody } from 'reactstrap';
import {
  ComposedChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Label,
} from 'recharts';
import TopicIcon from '../../../components/commons/TopicIcon';
import Table from '../../../components/GenericTable';
import TextOverflowTooltip from '../../../components/commons/TextOverflowTooltip';

const portfoliChartData = [
  {
    name: 'January',
    Revenue: 25,
  },
  {
    name: 'Feburary',
    Revenue: 28,
  },
  {
    name: 'March',
    Revenue: 24,
  },
  {
    name: 'April',
    Revenue: 29,
  },
  {
    name: 'May',
    Revenue: 30,
  },
  {
    name: 'June',
    Revenue: 26,
  },
  {
    name: 'July',
    Revenue: 17,
  },
];

const portfoliChartData2 = [
  {
    name: 'January',
    Revenue: 80,
  },
  {
    name: 'Feburary',
    Revenue: 78,
  },
  {
    name: 'March',
    Revenue: 86,
  },
  {
    name: 'April',
    Revenue: 78,
  },
  {
    name: 'May',
    Revenue: 83,
  },
  {
    name: 'June',
    Revenue: 80,
  },
  {
    name: 'July',
    Revenue: 82,
  },
];

const CustomXAxisTick = ({ x, y, payload }) => {
  const words = payload.value.split(' ');
  return (
    <g transform={`translate(${x + 25},${y + 10})`}>
      {words.map((word, index) => (
        <text
          key={index}
          x={0}
          y={index * 12} // Adjust spacing between lines
          textAnchor="middle"
          fill="#0A0A0A"
          fontSize={9}
        >
          {word}
        </text>
      ))}
    </g>
  );
};

const rowData = [
  {
    name: 'John Smith',
    customers: 11,
  },
  {
    name: 'Mary Johnson',
    customers: 8,
  },
  {
    name: 'Carl Lee',
    customers: 7,
  },
  {
    name: 'Billy Bing',
    customers: 7,
  },
  {
    name: 'Suzy Sanders',
    customers: 6,
  },
];

const tableColumns = [
  {
    key: 'name',
    component: <span>Name</span>,
    classes: 'w-250px',
  },
  {
    key: 'customers',
    component: <span>Total # of customers</span>,
  },
];

const DemoBankPortfolioAnalysis = ({
  children,
  showModal,
  setShowModal,
  detailPage = false,
}) => {
  const onClose = () => {
    setShowModal(false);
  };

  const rows = rowData?.map((item) => {
    return {
      ...item,
      dataRow: [
        {
          key: 'name',
          classes: 'px-0',
          component: (
            <div className="text-black text-truncate fw-bold d-flex align-items-center gap-2">
              <TextOverflowTooltip
                maxLength={25}
                textStyle="fw-bold text-truncate font-size-sm2 mb-0 z-index-100"
                text={item.name}
              />
            </div>
          ),
        },
        {
          key: 'customers',
          label: '',
          component: (
            <>
              <span>{item?.customers || '--'}</span>
            </>
          ),
        },
      ],
    };
  });

  return (
    <>
      {children}
      <RightPanelModal
        showModal={showModal}
        setShowModal={onClose}
        showOverlay={!detailPage}
        containerPosition={!detailPage ? 'position-fixed' : 'position-absolute'}
        containerWidth={PROSPECT_RIGHT_PANEL_WIDTH}
        containerBgColor={!detailPage ? 'bg-gray-5' : 'bg-white'}
        headerBgColor="px-3"
        Title={
          <div className="d-flex py-2 align-items-center gap-2 scroll-item">
            <div className="nav-item scroll-item">
              <div
                className={`nav-icon p-2 rounded-circle treasury cursor-pointer`}
              >
                <TopicIcon
                  icon="trip"
                  iconBg="treasury"
                  filled={true}
                  iconSize="fs-5"
                />
              </div>
            </div>
            <p className="mb-0 text-black">Portfolio Analysis</p>
          </div>
        }
      >
        <div className="p-3 d-flex flex-column gap-3 text-left">
          <div className="w-100">
            <Card>
              <CardBody className="p-2_2">
                <h4 className="mb-2_1 fs-6 font-weight-medium">
                  Total TM Revenue YTD (Last 6 months)
                </h4>

                <div className="mt-3 w-100">
                  <ComposedChart
                    width={415}
                    height={220}
                    margin={{ top: 20, right: 20, left: 20, bottom: 40 }}
                    data={portfoliChartData}
                  >
                    <CartesianGrid
                      stroke="#f5f5f5"
                      horizontal={true}
                      vertical={false}
                      strokeDasharray="3 3"
                    />
                    <XAxis
                      dataKey="name"
                      scale="band"
                      interval={0}
                      axisLine={false}
                      tickLine={false}
                      tick={<CustomXAxisTick />}
                    />

                    <YAxis
                      axisLine={false}
                      tickLine={false}
                      tick={{ fill: '#0A0A0A', fontSize: 11 }}
                      tickFormatter={(value) =>
                        value === 0 ? value : `${value} K`
                      }
                    >
                      <Label
                        value="TM Revenue in 100K"
                        offset={-8}
                        angle={-90}
                        position="insideLeft"
                        style={{
                          fill: '#0A0A0A',
                          fontSize: 11,
                          textAnchor: 'middle',
                        }}
                      />
                    </YAxis>

                    <Tooltip
                      formatter={(value, name) => {
                        if (name === 'Revenue') {
                          return [`${value}K`, 'Revenue'];
                        }
                        return [value, name];
                      }}
                      contentStyle={{
                        fontSize: 11,
                      }}
                    />
                    <Bar
                      dataKey="Revenue"
                      radius={[5, 5, 0, 0]}
                      barSize={30}
                      fill="#02336c"
                    />
                  </ComposedChart>
                </div>
              </CardBody>
            </Card>
          </div>

          <div className="w-100">
            <Card>
              <CardBody className="p-2_2">
                <h4 className="mb-2_1 fs-6 font-weight-medium">
                  Total Deposit Volume YTD (Last 6 months)
                </h4>

                <div className="mt-3 w-100">
                  <ComposedChart
                    width={415}
                    height={220}
                    margin={{ top: 20, right: 20, left: 20, bottom: 40 }}
                    data={portfoliChartData2}
                  >
                    <CartesianGrid
                      stroke="#f5f5f5"
                      horizontal={true}
                      vertical={false}
                      strokeDasharray="3 3"
                    />
                    <XAxis
                      dataKey="name"
                      scale="band"
                      interval={0}
                      axisLine={false}
                      tickLine={false}
                      tick={<CustomXAxisTick />}
                    />

                    <YAxis
                      axisLine={false}
                      tickLine={false}
                      tick={{ fill: '#0A0A0A', fontSize: 11 }}
                      tickFormatter={(value) =>
                        value === 0 ? value : `${value} M`
                      }
                    >
                      <Label
                        value="TM Revenue in 1M"
                        offset={-8}
                        angle={-90}
                        position="insideLeft"
                        style={{
                          fill: '#0A0A0A',
                          fontSize: 11,
                          textAnchor: 'middle',
                        }}
                      />
                    </YAxis>

                    <Tooltip
                      formatter={(value, name) => {
                        if (name === 'Revenue') {
                          return [`${value}M`, 'Revenue'];
                        }
                        return [value, name];
                      }}
                      contentStyle={{
                        fontSize: 11,
                      }}
                    />
                    <Bar
                      dataKey="Revenue"
                      radius={[5, 5, 0, 0]}
                      barSize={30}
                      fill="#02336c"
                    />
                  </ComposedChart>
                </div>
              </CardBody>
            </Card>
          </div>

          <div className="w-100">
            <Card>
              <CardBody className="p-0">
                <h4 className="mb-2_1 p-2_2 fs-6 font-weight-medium">
                  Customer by Relationship Manager
                </h4>

                <div className="w-100">
                  <div className="table-min-height">
                    <div className="table-responsive-md datatable-custom">
                      <div
                        id="datatable_wrapper"
                        className="dataTables_wrapper no-footer h-auto"
                      >
                        <Table
                          data={rows}
                          columns={tableColumns}
                          dataInDB={rowData?.length}
                          showLoading={false}
                          emptyDataText="No recent fee variances"
                          headClass="bg-gray-table-head"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </CardBody>
            </Card>
          </div>
        </div>
      </RightPanelModal>
    </>
  );
};

export default DemoBankPortfolioAnalysis;
